import Chart from './Chart';

import { Tooltip } from 'antd';
import { Info } from '@phosphor-icons/react';
import {
  BottomCard as Card,
  BottomCardInfo2,
  BottomCardHeader2,
  CardInfoValue2,
  ContentValuesCardBottom,
  ContentTextValue,
} from './styles';

export const BottomCardGraphic2 = ({
  data,
  fillcolor,
  value,
  value2,
  titleValue,
  titleValue2,
  label,
  openModal,
  helpText,
}) => {
  return (
    <Card
      onClick={() => {
        openModal(true);
      }}
      style={{ gap: '.5rem' }}
    >
      <BottomCardHeader2>
        <span>{label}</span>
        <Tooltip title={helpText}>
          <Info size="1rem" color="#EC6643"/>
        </Tooltip>
      </BottomCardHeader2>
      <ContentValuesCardBottom>
        <ContentTextValue>
          <span>{titleValue}</span>
          <strong>{value}</strong>
        </ContentTextValue>
        <ContentTextValue>
          <span>{titleValue2}</span>
          <strong>{value2}</strong>
        </ContentTextValue>
      </ContentValuesCardBottom>
      {/* <BottomCardInfo2>
        <CardInfoValue2>{value}</CardInfoValue2>
      </BottomCardInfo2> */}
      <Chart
        data={data
          .filter((item) => !Number.isNaN(item.value))
          .map((item) => item.value)}
        fillcolor={fillcolor}
        height={60}
      />
    </Card>
  );
};

import styled from 'styled-components';

export const MenuMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  .menuButton {
    all: unset;
    font-size: 1.5rem;
    color: ${(props) => props.theme.white};
    line-height: 0;
  }
`;

interface IPropsMenuMobileContent {
  isShowMenu: boolean;
}

export const MenuMobileContent = styled.div<IPropsMenuMobileContent>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: fixed;
  right: ${(props) => (props.isShowMenu ? '0' : '-100vw')};
  top: 0;
  background: linear-gradient(
    39deg,
    rgba(206, 19, 28, 1) 0%,
    rgba(44, 24, 25, 1) 20%,
    rgba(25, 24, 24, 1) 40%,
    rgba(24, 24, 24, 1) 60%,
    rgba(21, 38, 53, 1) 80%,
    rgba(1, 118, 225, 1) 100%
  );
  padding: 1rem;
  transition: 0.5s;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;

  .logoAndClose {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
`;
export const NavContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  height: 100%;
  max-width: 23.875rem;
  align-items: center;
  header {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    li {
      width: 100%;
      padding: 1rem;
      width: 100%;
      background: rgba(53, 53, 53, 0.15);
      text-align: center;
      border-radius: 8px;

      a {
        color: ${(props) => props.theme.white};
        font-weight: 500;
        font-size: 1.125rem;
        width: 100%;
      }
    }
  }
`;

interface IPropsButton {
  isLoginButton?: boolean;
}

export const Button = styled.button<IPropsButton>`
  all: unset;
  background-color: ${(props) =>
    props.isLoginButton ? '#1F262C' : props.theme.primary};
  color: ${(props) => props.theme.white};
  font-weight: 500;
  cursor: pointer;
  font-size: 1.125rem;
  line-height: 150%;
  padding: 0.75rem 1.75rem;
  border-radius: 8px;
`;

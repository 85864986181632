import { useEffect } from 'react';
import { useTitleAndUser } from '../../../hooks/userTitle';
import { CalculatorCompoundInterest } from './CalculatorCompoundInterest';

import { Container } from './styles';

export function Calculators() {
  //@ts-ignore
  const { setTitle } = useTitleAndUser();

  useEffect(() => {
    setTitle('Calculadora de Juros Compostos');
  }, []);
  return (
    <Container>
      <CalculatorCompoundInterest />
    </Container>
  );
}

import { PlayCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ContentChannelInfos, ContentVideo, ThumbContent } from './styles';

export function CardVideo({
  video,
  isTutorial = false,
  handleNewTutorial,
}: any) {
  return (
    <ContentVideo>
      <ThumbContent>
        <img src={video.thumbnail} />
      </ThumbContent>
      <ContentChannelInfos>
        <h4>{video.title}</h4>
        <span title={video.description}>{video.description}</span>
        <time>{moment(video.date).format('L')}</time>
        {!isTutorial ? (
          <Link to={`watch/?v=${video.video_id}`} className="link" style={{color:'#EC6643'}}>
            Assistir
            <PlayCircleFilled />
          </Link>
        ) : (
          <button style={{color:'#EC6643'}} onClick={() => handleNewTutorial(video)}>
            Assistir
            <PlayCircleFilled />
          </button>
        )}
      </ContentChannelInfos>
    </ContentVideo>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .table {
    background-color: ${(props) => props.theme.white};
    h2 {
      padding: 1rem;
      margin: 0;
    }
  }
  .active {
    background: transparent;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: ${(props) => props.theme.white};
    align-items: baseline;
    padding: 1rem;
    border-radius: 8px;
    gap: 1rem;

    .infoContent {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      label {
        display: flex;
        gap: 0.5rem;
        color: ${(props) => props.theme['gray-700']};
        input {
          width: auto;
        }
      }
      p {
        margin: 0;
        color: ${(props) => props.theme.black};
        font-weight: 500;
      }
    }

    .column {
      display: flex;
      flex-direction: column;
    }

    h1 {
      font-size: 1.5rem;
    }

    div {
      display: flex;
      flex-direction: column;

      position: relative;
      input {
        border: ${(props) => props.theme.border};
        border-radius: 8px;
        padding: 0.4rem;
        width: 230px;
        padding-left: 1.6rem;
        font-size: 1rem;
      }
      small {
        position: absolute;
        top: 37px;
        left: 5px;
        font-size: 1rem;
      }
    }
    /* button {
      padding: 0.2rem 1rem;
      background-color: ${(props) => props.theme.primary};
      color: ${(props) => props.theme.white};
      border: none;
      outline: none;
    } */
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .filter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    margin: 1rem 0;
    width: 100%;
    background: ${(props) => props.theme.white};
    padding: 1rem;
    border-radius: 8px;

    label {
      display: flex;
      align-items: center;
      gap: 5px;
      margin: 0;
      color: ${(props) => props.theme['gray-700']};
    }
  }
`;

export const ContentForm = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: start;
`;

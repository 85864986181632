import styled from 'styled-components';

export const MonthlyMessageContainer = styled.div`
  font-size: 1rem;
  color: ${(props) => props.theme['gray-700']};

  p {
    margin: 0;
    font-size: 1.5rem;

    a {
      color: ${(props) => props.theme.primary};
      :hover {
        filter: brightness(0.9);
        transition: filter 0.3s;
      }
    }
  }
`;

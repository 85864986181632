import axios from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Star } from '@phosphor-icons/react';
import { IPropsQuestionsFeedback } from '../../../../../../../../../utils/@types/questionsFeedback';
import { ContentInput, FormFeedbackUserContainer } from './styles';
import { useTitleAndUser } from '../../../../../../../../../hooks/userTitle';
import { ButtonComponent } from '../../../../../../../../../components/ButtonComponent';
import { administrationController } from '../../../../../../../../../utils/RoutesApi';
import { handleToast } from '../../../../../../../../../components/Toast';

interface IPropsFormFeedbackUser {
  questions: IPropsQuestionsFeedback[];
  onCloseFeedback: Function;
}

interface IPropsQuestionsResponse {
  id: number;
  user: number;
  question: number;
  rating_response?: number | null;
  text_response?: string | null;
}

const starts = [1, 2, 3, 4, 5];

export function FormFeedbackUser({
  questions,
  onCloseFeedback,
}: IPropsFormFeedbackUser) {
  //@ts-ignore
  const { user } = useTitleAndUser();
  const {
    handleSubmit,

    formState: { isSubmitting },
  } = useForm();
  const [questionsResponse, setQuestionsResponse] = useState<
    IPropsQuestionsResponse[]
  >([]);
  const [attStars, setAttStars] = useState(false);

  function handleAddResponse(
    response: IPropsQuestionsResponse,
    questionId: number,
    type: 'text' | 'ratting'
  ) {
    const findResponse = questionsResponse.findIndex(
      (question) => question.id === questionId
    );

    if (findResponse >= 0) {
      setQuestionsResponse((state) => {
        if (type === 'text') {
          state[findResponse].text_response = response.text_response;
          return state;
        }
        state[findResponse].rating_response = response.rating_response;
        return state;
      });
    } else {
      setQuestionsResponse([...questionsResponse, response]);
    }
  }

  function renderStart(response: IPropsQuestionsResponse, questionId: number) {
    const findResponse = questionsResponse.findIndex(
      (question) => question.id === questionId
    );

    if (findResponse !== -1) {
      const amountSelectedStarts = questionsResponse[findResponse]
        .rating_response
        ? questionsResponse[findResponse].rating_response
        : -1;

      return (
        <div className="starContent">
          {starts.map((star) => (
            <button
              key={star}
              type="button"
              onClick={() => {
                setAttStars(!attStars);
                handleAddResponse(
                  {
                    ...response,
                    rating_response: star,
                  },
                  questionId,
                  'ratting'
                );
              }}
            >
              <Star
                //@ts-ignore
                weight={star <= amountSelectedStarts ? 'fill' : 'regular'}
              />
            </button>
          ))}
        </div>
      );
    }

    return (
      <div className="starContent">
        {' '}
        {starts.map((star) => (
          <button
            key={star}
            type="button"
            onClick={() => {
              setAttStars(!attStars);

              handleAddResponse(
                {
                  ...response,
                  rating_response: star,
                },
                questionId,
                'ratting'
              );
            }}
          >
            <Star />
          </button>
        ))}
      </div>
    );
  }

  async function handleSubmitFeedbackUser(data: any) {
    data.question = questionsResponse.map((question) => {
      return {
        question_id: question.id,
        rating_response: question.rating_response,
        text_response: question.text_response,
      };
    });
    if (data.question.length !== questions.length) {
      return handleToast(true, 'Você precisa preencher todas as respostas');
    }
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}${administrationController.RESPONSE_USER_FEEDBACK}/`,
        data.question
      )
      .then((response) => {
        onCloseFeedback();
        handleToast(false, 'Feedback enviado com sucesso');
      })
      .catch((err) => {
     
      });
  }

  return (
    <FormFeedbackUserContainer
      onSubmit={handleSubmit(handleSubmitFeedbackUser)}
    >
      {questions.map((question) => (
        <ContentInput key={question.id}>
          <label>{question.question_text}</label>
          {question.question_type === 'text' ? (
            <textarea
              onChange={(e) =>
                handleAddResponse(
                  {
                    id: question.id,
                    user: user.id,
                    text_response: e.target.value,
                    question: question.id,
                    rating_response: null,
                  },
                  question.id,
                  'text'
                )
              }
            />
          ) : (
            renderStart(
              {
                id: question.id,
                user: user.id,
                text_response: null,
                question: question.id,
                rating_response: null,
              },
              question.id
            )
          )}
        </ContentInput>
      ))}
      <div>
        <ButtonComponent type="submit" disabled={isSubmitting}>
          Enviar feedback
        </ButtonComponent>
      </div>
    </FormFeedbackUserContainer>
  );
}

import { Heading } from '../../../../../components/DefaultStylesTypography';
import { DataLearnContent, LearnContainer } from './styles';

interface IPropsScreen {
  onShowRegisterScreen: () => void;
}

export function Learn({ onShowRegisterScreen }: IPropsScreen) {
  return (
    <LearnContainer>
      <DataLearnContent>
        <Heading>Aprenda com outros iBoxers!</Heading>
        <p>
          Uma rede social dentro do iBox6, onde você pode postar, curtir,
          comentar, seguir, tirar suas dúvidas ou iniciar tópicos do seu
          interesse. Além disso, você fica sabendo dos principais fatos
          relevantes sobre o mercado e participa de lives tira-dúvidas com
          nossos especialistas. Tudo isso em um ambiente seguro e colaborativo.
        </p>
        <button type="button" onClick={onShowRegisterScreen}>
          Acesse agora
        </button>
      </DataLearnContent>
    </LearnContainer>
  );
}

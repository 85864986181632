import TimeAgo from 'javascript-time-ago';
import pt from 'javascript-time-ago/locale/pt.json';
TimeAgo.addDefaultLocale(pt);

//export const api = "http://localhost:8000";
export const api = process.env.REACT_APP_API_URL;
//export const website = "http://127.0.0.1:3001";
export const website = process.env.SITE;
export const timeAgo = new TimeAgo('pt-br');

export const monthNames = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export const APP_NAME = 'Social';
export const IS_HANDHELD = 'ontouchstart' in document.documentElement;

// Routing
export const route = {
  login: '/login/',
  followers: (slug) => `/users/${slug}/followers/`,
  following: (slug) => `/users/${slug}/following/`,
  home: '/home/',
  index: '/',
  notifications: '/notifications/',
  postDetail: (postId) => `/post/${postId}/`,
  postLikes: (postId) => `/post/${postId}/likes/`,
  postDetailLikes: (postId) => `/post/${postId}/likes/`,
  profilePosts: (slug) => `/users/${slug}/`,
  profileLikes: (slug) => `/users/${slug}/likes/`,
  profileReplies: (slug) => `/users/${slug}/replies/`,
  recommendedPosts: '/recommended-posts/',
  recommendedUsers: '/recommended-users/',
  register: '/register/',
  search: '/search/',
  settings: '/settings/',
};

import styled from 'styled-components';

export const ContentForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: ${(props) => props.theme.white};
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid var(--Gray-gray-100, #D4D6DB);
  background: var(--Main-white, #FFF);
  padding-bottom: 16px;
`;

export const ContentInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0rem;
  
  background: var(--Main-white, #FFF);
  label {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 400;
    color: #303F4B;
  }

  input {
    all: unset;
    padding: 1rem;
    outline: 1px solid transparent;
    padding: 0.5rem;
    font-weight: 400;
    font-size: 0.875rem;

    color: #9196A1;
    border-bottom: 1px solid #9196A1;
    background: var(--Main-white, #FFF);

    &:focus {
      border-bottom: 3px solid var(--Gray-gray-600, #EC6643);
    }

    &:disabled {
      cursor: not-allowed;
      background: ${(props) => props.theme['white-300']};
    }
  }
`;
export const ContentImg = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    max-width: 10rem;
    height: 100%;
    max-height: 10rem;
    border-radius: 100%;
  }

  button {
    all: unset;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    color: ${(props) => props.theme['gray-300']};
    span {
      font-size: 1rem;
      text-decoration: underline;
      font-weight: 400;
      margin: 0;
    }
  }
`;

import moment from 'moment';
import ReactApexChart from 'react-apexcharts';
import { transformMaskCurrency } from '../../../../utils/transformMaskCurrency';

interface IPropsGraphicCalc {
  valuesGraphic: {
    interest: number[];
    mount: number[];
    totalInvest: number[];
    isGraphic: boolean;
  };
}

export function GraphicCalc({ valuesGraphic }: IPropsGraphicCalc) {
  const graphicSettings = {
    series: [
      {
        name: 'Total Acumulado',
        data: valuesGraphic.mount,
      },
      {
        name: 'Total Investido',
        data: valuesGraphic.totalInvest,
      },
      {
        name: 'Total Juros',
        data: valuesGraphic.interest,
      },
    ],
    options: {
      chart: {
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },

      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      yaxis: {
        labels: {
          formatter: function (value: any) {
            return transformMaskCurrency(value);
          },
        },
      },

      xaxis: {
        tickAmount: 15,
        labels: {
          hideOverlappingLabels: true,
        },
        categories: valuesGraphic.mount.map((item, index) =>
          moment()
            .add(index + 1, 'month')
            .calendar()
        ),
      },
    },
  };

  return (
    <ReactApexChart
      //@ts-ignore
      options={graphicSettings.options}
      series={graphicSettings.series}
      type="line"
      height={350}
    />
  );
}

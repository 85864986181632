import axios from 'axios';
import { useEffect, useState } from 'react';
//@ts-ignore
import Select from 'react-select';
import ReactLoading from 'react-loading';
import { financeRoutes } from '../../../../utils/RoutesApi';

import {
  AddNewActiveButton,
  CardSelectedActive,
  Container,
  Content,
  ContentCard,
  SelectActive,
} from './styles';
import { NewTableCompareAssets } from './components/NewTableCompareAssets';
import { CardSelectedGraphicActive } from './components/CardSelectedActive';
import { Plus } from '@phosphor-icons/react';
import './styles.css';

import { ModalDesigner } from '../../../../components/ModalDesigner';
import { AsideFiltersCompareAssets } from './components/AsideFiltersCompareAssets';
import { SideModal } from '../../../../components/SideModal';
import { baseURL } from '../../../../utils/baseURL';

export interface IPropsActivesLabel {
  label: string;
  value: string;
}

interface IPropsvalueGraphicData {
  Ano: number;
  value: number;
}

export interface IPropsGraphicData {
  consolidado: IPropsvalueGraphicData[];
  dividaLiquida: IPropsvalueGraphicData[];
  dividaLIquidaEbitdaArr: IPropsvalueGraphicData[];
  dyArr: IPropsvalueGraphicData[];
  ebitdaArr: IPropsvalueGraphicData[];
  individualizado: IPropsvalueGraphicData[];
  lucroLiquidoArr: IPropsvalueGraphicData[];
  roeArr: IPropsvalueGraphicData[];
  asset: string;
}

interface IPropsCompareAssets {
  isComponent?: boolean;
  defaultAsset?: IPropsActivesLabel;
}

interface IPropsDataCompany {
  nota: {
    codigo_fii: string;
    nome_fundo: string;
    nota_final: number;
    data_funcionamento_numerico: number;
    qtde_ativos: number;
    vacancia_fisica: number;
    dividendo_yeld: number;
    valor_patrimonial_por_cota: number;
    tipo_fii: string;
  };
  comparar: {
    ultimo_dy: number;
    ultimo_valor_patrimonial: number;
    ultimo_patrimonio_liquido: number;
    ultimo_p_vp: number;
    ultimo_vacancia_fisica: number;
    ultimo_qtde_ativos: number;
  };
}

export function CompareAssetsFiis({
  isComponent = false,
  defaultAsset,
}: IPropsCompareAssets) {
  const [actives, setActives] = useState<IPropsActivesLabel[]>([]);
  const [selectedFilters, setSelectedFilters] = useState({
    setor: '',
    tipo_gestao: '',
    tipofii: '',
  });
  const [loading, setLoading] = useState(false);
  const [selectedActives, setSelectedActives] = useState<IPropsActivesLabel[]>(
    () => {
      if (isComponent && defaultAsset) {
        return [defaultAsset];
      }
      return [];
    }
  );
  const [companiesInfos, setCompaniesInfos] = useState<IPropsDataCompany[]>([]);
  const [modalFilter, setModalFilter] = useState(false);
  const [showSelectedActive, setShowSelectedActive] = useState(false);

  useEffect(() => {
    getActivesWithNotes(selectedFilters);
  }, [selectedFilters]);

  useEffect(() => {
    handleGetActiveList();
  }, [selectedActives]);

  async function getActivesWithNotes(params: {}) {
    try {
      const response = await axios.get(
        `${baseURL}${financeRoutes.FINANCE_LIST_FIIS_WITH_RANK}`,
        {
          params: {
            ...params,
            page_size: 1000,
          },
        }
      );
      setActives(
        response.data.data.map((active: any) => {
          return {
            label: `${active.codigo_fii} - ${active.nome_fundo}`,
            value: active.codigo_fii,
          };
        })
      );
    } catch (error) {}
  }

  async function handleGetActiveList() {
    setLoading(true);

    for (var i = 0; selectedActives.length > i; i++) {
      let params = {
        nome_ou_codigo: `${selectedActives[i].value
          .replace(' ', '')
          .replace('.SA', '')}`,
      };
      await axios
        .get(`${baseURL}${financeRoutes.FINANCE_LIST_FIIS_WITH_RANK}`, {
          params,
        })
        .then((response) => {
          console.log(response.data);
          const vVp = response.data.comparar.ultimo_p_vp.toString().split('.');
          const vVpFormated = Number(`${vVp[0]}.${vVp[1].substring(0, 2)}`);
          const validateAsset = companiesInfos.map(
            (item) => item.nota.codigo_fii
          );

          if (!validateAsset.includes(selectedActives[i].value)) {
            setCompaniesInfos((state) => {
              return [
                ...state,
                {
                  comparar: {
                    ...response.data.comparar,
                    ultimo_p_vp: vVpFormated,
                  },
                  nota: response.data.data[0],
                },
              ];
            });
          }
        })

        .catch((err) => {});
    }
    setLoading(false);
  }

  function handleRemoveActive(asset: string) {
    const newSelectedActives = selectedActives.filter(
      (active) => active.value !== asset
    );
    setSelectedActives(newSelectedActives);
    const newCompaniesInfos = companiesInfos.filter(
      (active) => active.nota.codigo_fii !== asset
    );

    setCompaniesInfos(newCompaniesInfos);
  }

  return (
    <Container>
      {window.screen.width > 1100 && (
        <AsideFiltersCompareAssets
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
      )}
      <Content>
        <CardSelectedActive isComponent={isComponent}>
          <p>
            Para exibir os comparativos, é necessário que você selecione seus
            ativos para serem comparados.
          </p>
          {window.screen.width <= 1100 && (
            <div className="contentButtonFilter">
              <button
                className="filterButton"
                onClick={() => setModalFilter(true)}
              >
                Filtros
              </button>
            </div>
          )}

          {companiesInfos.length > 1 ? (
            <ContentCard>
              {companiesInfos.map((active) => (
                <CardSelectedGraphicActive
                  active={active}
                  key={active.nota.codigo_fii}
                  onRemoveItem={() =>
                    handleRemoveActive(active.nota.codigo_fii)
                  }
                />
              ))}
              <AddNewActiveButton onClick={() => setShowSelectedActive(true)}>
                <i>
                  <Plus weight="bold" />
                </i>
                <span>Adicione um novo ativo</span>
              </AddNewActiveButton>
            </ContentCard>
          ) : (
            <ContentCard>
              {companiesInfos.length > 0 ? (
                <CardSelectedGraphicActive
                  active={companiesInfos[0]}
                  onRemoveItem={() =>
                    handleRemoveActive(companiesInfos[0].nota.codigo_fii)
                  }
                />
              ) : (
                <SelectActive onClick={() => setShowSelectedActive(true)}>
                  Selecione o primeiro ativo
                </SelectActive>
              )}{' '}
              X{' '}
              <SelectActive onClick={() => setShowSelectedActive(true)}>
                {' '}
                Selecione o segundo ativo
              </SelectActive>
            </ContentCard>
          )}
        </CardSelectedActive>

        {companiesInfos.length >= 2 ? (
          loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <ReactLoading
                type="balls"
                color="var(--primary)"
                height={300}
                width={300}
              />
            </div>
          ) : (
            <div className="contentTable">
              <NewTableCompareAssets actives={companiesInfos} />
            </div>
          )
        ) : null}

        <ModalDesigner
          id="selectActive"
          isOpen={showSelectedActive}
          setState={setShowSelectedActive}
          size="sm"
        >
          <div style={{ height: '20rem', width: '100vw', maxWidth: '20rem' }}>
            <Select
              options={actives}
              placeholder="Busque o ativo"
              className="select"
              //@ts-ignore
              onChange={(e) => {
                // @ts-ignore
                setSelectedActives([...selectedActives, e]);
                setShowSelectedActive(false);
              }}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? '#0176E1' : 'transparent',
                  borderRadius: 8,
                }),
                container: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: ' 2px 2px 6px 0px #0000001A',
                  borderRadius: 8,
                }),
                input: (baseStyles) => ({
                  ...baseStyles,
                  color: '#808080',
                  fontSize: 16,
                }),
                placeholder: (base) => ({
                  ...base,
                }),
                valueContainer: (base) => ({
                  ...base,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 8,
                  paddingBottom: 8,
                }),
                indicatorSeparator: () => ({
                  display: 'none',
                }),

                dropdownIndicator: (base) => ({
                  ...base,
                  color: '#808080',
                }),
              }}
            />
          </div>
        </ModalDesigner>
      </Content>

      <SideModal id="sideFilter" isOpen={modalFilter} setState={setModalFilter}>
        <AsideFiltersCompareAssets
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
      </SideModal>
    </Container>
  );
}

import axios from 'axios';
import { useEffect, useState } from 'react';
import 'moment/locale/pt-br';

/// React router dom
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';

/// Css
import './index.css';
import './chart.css';
import './step.css';

/// Layout
import Nav from './layouts/nav';
import Footer from './layouts/Footer';
/// Dashboard
import { B3, financeRoutes } from '../utils/RoutesApi';
import { handleToast } from '../components/Toast';
import { useActives } from '../hooks/useActives';

import { useTitleAndUser } from '../hooks/userTitle';
import { Container, Content } from './IndexStyles';
import { useNewTheme } from '../hooks/useNewTheme';
import { routes } from './router';
import { PrivateRouter } from './authRoutes/PrivateRouter';
import { CountDownPlan } from './components/CountDownPlan';
import { LinkB3AllPages } from './pages/Business/components/LinkB3AllPages';

const Markup = () => {
  const { user, setTitle, title, setUser } = useTitleAndUser();
  const { setActives, setPercentage } = useActives();

  const { shortSidebar, isMobile } = useNewTheme();
  const location = useLocation();
  const history = useHistory();
  const getUser = JSON.parse(
    localStorage.getItem(process.env.REACT_APP_USER_VERSION)
  );
  const [att, setAtt] = useState(false);
  localStorage.removeItem('userDetails');

  useEffect(() => {
    setAtt(true);

    if (user) {
      if (location.pathname === '/page-recover-password') {
        history.push('/dashboard');
      }
    }
  }, [att, location]);

  useEffect(() => {
    window.scroll(0, 0);
    if (user.carteira === 'requisitado') {
      axios
        .get(`${process.env.REACT_APP_API_URL}${B3.VALIDATE_LINK_B3}`)
        .then((response) => {
          if (response.data.linkB3) {
            let newUser = {
              ...response.data.user,
              data_trial: user.data_trial,
            };

            if (newUser.profile.image) {
              const isValidProfileImage = !!newUser.profile.image.match(
                'https://storage.googleapis.com/ibox6-wordpress.appspot.com/'
              );
              if (!isValidProfileImage) {
                newUser.profile.image =
                  process.env.REACT_APP_API_URL + newUser.profile.image;
              }
            }
            if (newUser.profile.banner) {
              const isValidProfileBanner = !!newUser.profile.banner.match(
                'https://storage.googleapis.com/ibox6-wordpress.appspot.com/'
              );
              if (!isValidProfileBanner) {
                newUser.profile.banner =
                  process.env.REACT_APP_API_URL + newUser.profile.banner;
              }
            }

            const data = {
              ...getUser,
              user: newUser,
            };
            setUser(newUser);
            localStorage.setItem(
              process.env.REACT_APP_USER_VERSION,
              JSON.stringify(data)
            );

            handleToast(false, 'Vinculação com a B3 concluída');

            axios
              .get(
                `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_POSITION}`
              )
              .then(function (result) {
                setActives(result.data);
                let act = result.data.sumAcao.toFixed(2);
                let fii = result.data.sumsFII.toFixed(2);
                let rf = result.data.sumsEFT.toFixed(2);
                const total = Number(act) + Number(fii) + Number(rf);

                let actionPercentage =
                  result.data.sumAcao === 0
                    ? 0
                    : Number((result.data.sumAcao / total) * 100);
                actionPercentage = Number(actionPercentage.toFixed(2));

                let fiiPercentage =
                  result.data.sumsFII === 0
                    ? 0
                    : (result.data.sumsFII / total) * 100;

                fiiPercentage = Number(fiiPercentage.toFixed(2));
                let rfPercentage =
                  result.data.sumsEFT === 0
                    ? 0
                    : (result.data.sumsEFT / total) * 100;

                rfPercentage = Number(rfPercentage.toFixed(2));

                let newPercentage = {
                  fii: fiiPercentage !== NaN ? fiiPercentage : 0,
                  action: actionPercentage !== NaN ? actionPercentage : 0,
                  rendaFixa: rfPercentage !== NaN ? rfPercentage : 0,
                };
                setPercentage(newPercentage);
              })
              .catch(function (error) {});
          }
        })
        .catch((err) => {});
    }

    const userPath = location.pathname.replace('/users/', '');

    if (
      location.pathname === `/users/${userPath.replace('/', '')}/` ||
      location.pathname === `/users/${userPath.replace('/', '')}`
    ) {
      return setTitle(`${userPath.replace('/', '')}`);
    }
  }, [location]);

  let path = window.location.pathname;
  path = path.split('/');
  path = path[path.length - 1];

  let pagePath = path.split('-').includes('page');
// menu de navegação
  return (
    <> 
      <Container
        titlePage={title}
        shortSideBar={shortSidebar}
        isMobile={isMobile}
        isBlackScreen={window.location.pathname === '/admin-painel/dashboard'}
      >
        {!pagePath && <Nav />}
        <Content
          shortSideBar={shortSidebar}
          titlePage={title}
          isMobile={isMobile}
          isFullScreen={path === 'wallet'}
          isBlackScreen={window.location.pathname === '/admin-painel/dashboard'}
        >
          <div className="newContent">
            <LinkB3AllPages />
            <Switch>
              {routes.map((data, i) =>
                data.roles ? (
                  <PrivateRouter
                    key={i}
                    exact
                    path={`/${data.url}`}
                    component={data.component}
                    roles={data.roles}
                  />
                ) : (
                  <Route
                    key={i}
                    exact
                    path={`/${data.url}`}
                    component={data.component}
                  />
                )
              )}
            </Switch>
            <CountDownPlan />
          </div>
        </Content>
        <br />
        <section id="footer">{!pagePath && <Footer />}</section>
      </Container>
    </>
  );
};

export default Markup;

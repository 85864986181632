import styled, { css } from 'styled-components';

export const PaymentFormPlanContainer = styled.div`
  width: 100%;
  max-width: 800px;
  height: 100%;
  /* max-height: 536px; */
  background: ${(props) => props.theme.white};
  padding: 1.5rem 1rem;
  margin-top: 2.25rem;
  border-radius: 4px;

  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  align-items: center;
  gap: 2rem;

  font-family: 'Poppins', sans-serif;
  margin-bottom: 1rem;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    background: transparent;
    /* max-width: 99vw; */
    overflow: hidden;
  }
`;

export const PaymentFormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: space-between;
  height: 100%;

  h4 {
    color: #353840;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 30px;
    margin: 0;
  }
`;

export const ContentInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  input {
    border-radius: 8px;
    border: 1px solid #cfcfd1;
    padding: 0.75rem;
    font-size: 1rem;
    color: #656872;
    width: 100%;
  }

  .rowContentInput {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 5.937rem;
  }
`;

export const ContentInputRadio = styled.div`
  display: flex;

  gap: 1.812rem;
`;

interface IPropsRadioButton {
  selected: boolean;
}

export const InputRadioContainer = styled.div<IPropsRadioButton>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 1rem 0.75rem;

  width: 100%;
  border: 1px solid
    ${(props) => (props.selected ? props.theme.primary : '#656872')};

  cursor: pointer;
  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin: 0;
    color: ${(props) => (props.selected ? props.theme.primary : '#656872')};

    font-weight: 400;
    font-size: 0.75rem;
    svg {
      height: 1rem;
      width: 1rem;
    }
    input[type='radio'] {
      display: none;
    }
  }
`;

interface IPropsButton {
  variant?: 'secondary';
}

export const Button = styled.button<IPropsButton>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
  color: ${(props) => props.theme.white};
  background-color: ${(props) => props.theme.primary};
  border: 0;
  border-radius: 8px;
  outline: 0;
  font-weight: 500;
  font-size: 1.125rem;

  :hover {
    filter: brightness(0.9);
    transition: filter 0.3s;
  }

  ${(props) =>
    props.variant === 'secondary' &&
    css`
      border: 0.5px solid #656872;
      font-weight: 400;
      color: #656872;
      background: transparent;
    `}
`;

export const Divider = styled.div`
  width: 1px;
  height: 90%;
  background: #898989;
  @media (max-width: 480px) {
    width: 100%;
    height: 1px;
  }
`;

export const SelectedPlanContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  h4 {
    color: #353840;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 30px;
    margin-bottom: 2.25rem;
  }

  h3 {
    margin: 0;
    font-size: 2.25rem;
    font-weight: 600;
    line-height: 54px;
    display: flex;
    gap: 0.75rem;
    align-items: first baseline;
    color: #e99e00;

    span {
      font-size: 1.75rem;
      color: #ffad00;
      font-weight: 400;
      line-height: 48px;
    }
  }
`;

export const ListFunctionalitiesContent = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2.875rem;
`;

interface IPropsFunc {
  disabled: boolean;
}

export const FunctionalityContent = styled.div<IPropsFunc>`
  display: grid;
  grid-template-columns: 1.125rem 1fr;

  gap: 0.75rem;
  width: 100%;

  svg {
    color: ${(props) => (props.disabled ? '#A4A6AC' : props.theme.white)};
    width: 1.125rem;
    height: 1.125rem;
    padding: ${(props) => (props.disabled ? 0 : '0.1rem')};
    font-size: 1rem;

    ${(props) =>
      !props.disabled &&
      css`
        background-color: ${props.theme.primary};
        border-radius: 100%;
      `}
  }

  strong {
    font-weight: 500;
    font-size: 0.875rem;
    color: ${(props) => (props.disabled ? '#A4A6AC' : '#353840')};
  }
`;

export const AlertBoletoMessage = styled.p`
  font-weight: 400;
  font-size: 1rem;
  color: #656872;
  margin: 0;
`;

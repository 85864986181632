import { Container, Content } from './styles';

export function NoDataB3() {
  return (
    <Container>
      <Content>
        <header>
          <h1>Parabéns você já está vinculado!</h1>
        </header>
        <main>
          <p>
            Sua conta com a B3 já foi vinculada, porém ainda não recebemos seus
            dados, assim que tudo estiver certo poderá visualizar todos os
            dados.
          </p>
        </main>
      </Content>
    </Container>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2.5rem;

    img {
      max-width: 150px;
    }
  }

  main {
    display: flex;
    flex-direction: column;

    strong {
      color: #303030;
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    .warningContent {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
      background-color: rgba(240, 113, 44, 0.1);
      margin-bottom: 1rem;

      i {
        width: 2rem;
      }
      p {
        color: #d67e0b;
      }
    }

    ul {
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      li {
        font-size: 0.875rem;
        color: #454545;
        line-height: 171%;

        svg {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }

    p {
      font-size: 0.875rem;
      color: #454545;
      line-height: 171%;
    }

    .guia {
      color: ${(props) => props.theme.primary};
      margin-bottom: 2rem;
      margin-top: 1rem;
      transition: color 0.3s;
      font-weight: 500;
      font-size: 1rem;

      :hover {
        color: ${(props) => props.theme.primary};
      }

      svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }

  footer {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    a {
      width: 100%;
    }

    @media (max-width: 480px) {
      flex-direction: column;
      align-items: center;
    }
  }
`;

import axios from 'axios';
import moment from 'moment';
import { Trash, User } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTitleAndUser } from '../../../hooks/userTitle';
import { IPropsComment } from '../../../utils/@types/comment';
import { socialRoutes } from '../../../utils/RoutesApi';
import { ButtonComponent } from '../../ButtonComponent';
import { handleToast } from '../../Toast';
import { NewResponseComment } from './NewResponseComment';
import {
  Container,
  ContainerResponseComments,
  ContentShowComment,
  ContentUser,
} from './styles';

interface IPropsResponseComment {
  responseComment: IPropsComment;
  onDeleteResponseComment: (id: number) => void;
  onLikeResponseComment: (id: number) => void;
}
interface IComments {
  count: number;
  next: string;
  previous: string;
  results: IPropsComment[];
}

const baseURL = process.env.REACT_APP_API_URL;

export function ResponseComment({
  responseComment,
  onDeleteResponseComment,
  onLikeResponseComment,
}: IPropsResponseComment) {
  //@ts-ignore
  const { user } = useTitleAndUser();
  const { handleSubmit, setValue, register } = useForm();
  const [showResponseComments, setShowResponseComments] =
    useState<boolean>(false);

  const [responseComments, setResponseComments] = useState<IComments>({
    count: 0,
    next: '',
    previous: '',
    results: [],
  });

  useEffect(() => {
    handleGetResponseComments();
  }, []);

  function deleteResponseComment(id: string | number) {
    let newResponseComments = responseComments.results.filter(
      (comment) => comment.id !== id
    );
    setResponseComments({
      ...responseComments,
      results: newResponseComments,
    });
  }

  async function handleResponseComment(data: any) {
    // setLoading(true);
    if (!data.body) {
      return handleToast(true, 'Você não pode enviar uma postagem em branco');
    }

    let searchHashTags = data.body.split(' ');
    searchHashTags = searchHashTags.filter((hashtag: string) =>
      hashtag.includes('#')
    );

    searchHashTags = searchHashTags.toString();

    if (searchHashTags) {
      data.hashtag = searchHashTags;
    } else {
      data.hashtag = '';
    }

    data.is_reply = true;
    data.parent_id = responseComment.id;

    await axios
      .post(`${baseURL}${socialRoutes.SOCIAL_CREATE_POST}`, data)
      .then((response) => {
        setResponseComments((state) => {
          return { ...state, results: [response.data, ...state.results] };
        });
        setValue('body', '');
      })
      .catch((err) => {
        // setLoading(false);
        // handleToast(true, 'Erro ao publicar');
      });
    // setLoading(false);
  }
  async function handleGetResponseComments() {
    await axios
      .get(`${baseURL}/social/api/posts/comments/${responseComment.id}/posts/`)
      .then((response) => {
        setResponseComments(response.data);
      })
      .catch((err) => {});
  }
  async function handleNextComment(link: string) {
    await axios
      .get(link)
      .then((response) => {
        setResponseComments({
          ...response.data,
          results: [...responseComments.results, ...response.data.results],
        });
      })
      .catch((err) => {});
  }

  async function handleLikeComment(id: string | number) {
    const comment = responseComments.results.find(
      (comment) => comment.id === id
    );
    if (comment?.liked.includes(user.id)) {
      await axios
        .delete(
          `${process.env.REACT_APP_API_URL}/social/api/posts/${id}/likes/`
        )
        .then((response) => {
          const liked = responseComments.results.findIndex(
            (comment) => comment.id === id
          );
          let newComments = responseComments.results;
          newComments[liked].liked = newComments[liked].liked.splice(
            user.id,
            1
          );
          setResponseComments({
            ...responseComments,
            results: newComments,
          });
        })
        .catch((err) => {});
      return;
    }
    await axios
      .post(`${process.env.REACT_APP_API_URL}/social/api/posts/${id}/likes/`)
      .then((response) => {
        const liked = responseComments.results.findIndex(
          (comment) => comment.id === id
        );
        let newComments = responseComments.results;
        newComments[liked].liked = [...newComments[liked].liked, user.id];
        setResponseComments({
          ...responseComments,
          results: newComments,
        });
      })
      .catch((err) => {});
  }

  return (
    <Container>
      <header>
        <ContentUser>
          <div className="contentAvatar">
            {responseComment.author.profile.image ? (
              <img src={responseComment.author.profile.image} />
            ) : (
              <User />
            )}
          </div>
          <div className="dataUser">
            <strong>
              {responseComment.author.Name.split(' ')[0]}{' '}
              {responseComment.author.Name.split(' ')[1]}
            </strong>
          </div>
        </ContentUser>
        <div>
          <span>{moment(responseComment.created_at).fromNow()}</span>
          {responseComment.author.Name === user.Name && (
            <>
              {' '}
              <Trash
                size={20}
                className="iconDelete"
                onClick={() => onDeleteResponseComment(responseComment.id)}
              />
            </>
          )}
        </div>
      </header>
      <p>{responseComment.body}</p>

      <ContainerResponseComments>
        <ContentShowComment showResponse={showResponseComments}>
          <div style={{ display: 'flex', gap: '1rem' }}>
            <label onClick={() => onLikeResponseComment(responseComment.id)}>
              {responseComment.liked.length}{' '}
              {responseComment.liked.includes(user.id) ? 'Curtiu' : 'Curtir'}{' '}
            </label>
            <label
              htmlFor="response"
              onClick={() => setShowResponseComments(true)}
            >
              Responder
            </label>
          </div>
          <span onClick={() => setShowResponseComments(!showResponseComments)}>
            {showResponseComments ? 'Ocultar respostas' : 'Mostrar respostas'}
          </span>
        </ContentShowComment>
        {showResponseComments && (
          <>
            <form onSubmit={handleSubmit(handleResponseComment)}>
              <div>
                <div className="contentAvatar">
                  {user.profile.image ? (
                    <img src={user.profile.image} />
                  ) : (
                    <User />
                  )}
                </div>

                <textarea
                  {...register('body')}
                  placeholder={`Responder ${
                    responseComment.author.Name.split(' ')[0]
                  }`}
                  id="response"
                />
              </div>
              <div className="contentButton">
                <ButtonComponent variant="primary" type="submit">
                  Responder
                </ButtonComponent>
              </div>
            </form>
            {responseComments.results.map((response: IPropsComment) => (
              <NewResponseComment
                comment={response}
                key={response.id}
                deleteResponseComment={deleteResponseComment}
                onLinkedResponseComment={handleLikeComment}
              />
            ))}
            {responseComments.next && (
              <strong onClick={() => handleNextComment(responseComments.next)}>
                Ver mais
              </strong>
            )}
          </>
        )}
        {showResponseComments && <span className="response Finish" />}
      </ContainerResponseComments>
    </Container>
  );
}

import axios from 'axios';
import { useEffect, useState } from 'react';
import { useActives } from '../../../../../hooks/useActives';
import { useTitleAndUser } from '../../../../../hooks/userTitle';
import { IPropsPositionAll } from '../../../../../utils/@types/wallet';
import { financeRoutes } from '../../../../../utils/RoutesApi';
import { transformMaskCurrency } from '../../../../../utils/transformMaskCurrency';
import { TableLoader } from '../../../LoadingModels';
import { IPropsMattress } from './@types';
import { TableSelectedMattress } from './components/TableSelectedMattress';
import { Container } from './styles';

const header = [
  { label: 'Ativo', active: 'tickerSymbol' },
  { label: 'Adicionar/Remover' },

  { label: 'Total', active: 'updateValue' },
];

export interface IPropsAllPosition extends IPropsPositionAll {
  key: string;
}

const baseURL = process.env.REACT_APP_API_URL;

export function Mattress() {
  //@ts-ignore
  const { setTitle } = useTitleAndUser();
  //@ts-ignore
  const { showActive } = useActives();
  const [dataAllActive, setDataAllActive] = useState<IPropsAllPosition[]>([]);
  const [totalMattress, setTotalMattress] = useState<number>(0);
  const [loadingActives, setLoadingActives] = useState(false);

  useEffect(() => {
    setTitle('Reserva de Emergência');
    // getAllEmergencyReserve();
    getActives();
  }, []);

  async function getActives() {
    setLoadingActives(true);
    let arrMattress: IPropsMattress[] = [];
    await axios
      .get(`${baseURL}${financeRoutes.FINANCE_EMERGENCY_RESERVE}`, {
        params: {
          page_size: 100,
        },
      })
      .then((response) => {
        arrMattress = response.data.data;
      })
      .catch((err) => {});

    await axios
      .get(`${baseURL}${financeRoutes.FINANCE_POSITION}`)
      .then((response) => {
        let newData: IPropsAllPosition[] = response.data.positionsAll;
        let totalReduce = response.data.positionsAll.filter(
          //@ts-ignore
          (item) => item.reservadeemergencia
        );
        //@ts-ignore
        totalReduce = totalReduce.map((item) => item.updateValue);
        //@ts-ignore
        totalReduce = totalReduce.reduce((a, b) => a + b, 0);
        setTotalMattress(totalReduce);

        for (var mattress in arrMattress) {
          newData.map((item) => {
            if (item.tickerSymbol === arrMattress[mattress].ativo) {
              item.key = arrMattress[mattress].key;
            }
            return item;
          });
        }
        newData = newData.filter(
          (item) =>
            item.productTypeName === 'tesourodireto' ||
            item.productTypeName === 'rendafixa'
        );
        setDataAllActive(newData);
      })
      .catch((err) => {});
    setLoadingActives(false);
  }

  function handleUpdateMattress(id: string) {
    const newState = dataAllActive.map((item) => {
      if (item.tickerSymbol === id) {
        return {
          ...item,
          reservadeemergencia: !item.reservadeemergencia,
        };
      }
      return item;
    });

    setDataAllActive(newState);
    let newTotalMattress = newState.filter((item) => item.reservadeemergencia);

    setTotalMattress(() => {
      return newTotalMattress.reduce((a, b) => a + b.updateValue, 0);
    });
  }

  const maskTotalMattress = transformMaskCurrency(totalMattress);

  return (
    <Container>
      <p>
        Total na sua reserva de emergência{' '}
        <strong>{showActive ? maskTotalMattress : 'R$ ******'}</strong>
      </p>
      {loadingActives ? (
        <TableLoader />
      ) : (
        <TableSelectedMattress
          ASC={(newValue: IPropsAllPosition[]) => {
            setDataAllActive(newValue);
          }}
          DESC={(newValue: IPropsAllPosition[]) => {
            setDataAllActive(newValue);
          }}
          header={header}
          dataTable={dataAllActive}
          onSubmitUpdateMattress={(id) => handleUpdateMattress(id)}
        />
      )}
    </Container>
  );
}

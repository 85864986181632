import moment from 'moment';
import { IPropsParams } from '../..';
import { ContainerAsideFilters, ContentInput } from './styles';

interface IPropsAsideFilters {
  params: IPropsParams;
  setParams: Function;
}

const months = [
  {
    value: 1,
    label: 'Janeiro',
  },
  {
    value: 2,
    label: 'Fevereiro',
  },
  {
    value: 3,
    label: 'Março',
  },
  {
    value: 4,
    label: 'Abril',
  },
  {
    value: 5,
    label: 'Maio',
  },
  {
    value: 6,
    label: 'Junho',
  },
  {
    value: 7,
    label: 'Julho',
  },
  {
    value: 8,
    label: 'Agosto',
  },
  {
    value: 9,
    label: 'Setembro',
  },
  {
    value: 10,
    label: 'Outubro',
  },
  {
    value: 11,
    label: 'Novembro',
  },
  {
    value: 12,
    label: 'Dezembro',
  },
];

export function AsideFilters({ params, setParams }: IPropsAsideFilters) {
  return (
    <ContainerAsideFilters>
      <ContentInput>
        <label className='labelAno' htmlFor="Ano">Ano</label>
        <div className='selects'>
          
          <select
            className='ano'
            id="Ano"
            value={params.ano_datacom}
            onChange={(e) =>
              setParams({
                ...params,
                ano_datacom: e.target.value,
              })
            }
          >
            <option value="">Todos</option>
            <option value={moment().format('YYYY')}>
              {moment().format('YYYY')}
            </option>
            <option value={moment().add(1, 'year').format('YYYY')}>
              {moment().add(1, 'year').format('YYYY')}
            </option>
          </select>
        </div>
      </ContentInput>
      <ContentInput>
        <label className='labelMes' htmlFor="Mes">Mês</label>
        <div className='selects'>
          
          <select
            className='mes'
            id="Mes"
            value={params.mes_datacom}
            onChange={(e) =>
              setParams({
                ...params,
                mes_datacom: e.target.value,
                ano_datacom:
                  params.ano_datacom === ''
                    ? moment().format('YYYY')
                    : params.ano_datacom,
              })
            }
          >
            <option value="">Todos</option>
            {months.map((month) => (
              <option value={month.value} key={month.label}>
                {month.label}
              </option>
            ))}
          </select>
        </div>
      </ContentInput>
    </ContainerAsideFilters>
  );
}

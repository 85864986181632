import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme['card-shadow-2']};
  padding: 1rem;
  width: 100%;
  max-width: 25rem;
  border-radius: 4px;

  ul {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    li {
      flex-direction: row;
      align-items: center;
      color: ${(props) => props.theme['gray-700']};
      font-size: 1rem;
      list-style: inside;
    }
  }
`;

export const ContentButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  button {
    display: flex;
    justify-content: center;
  }
`;

import styled from 'styled-components';
import { css } from 'styled-components';

interface IPropsContainerInput {
  isSearch: boolean;
  isOutline: boolean;
  isError: boolean;
  roundedFull: boolean;
}

export const Container = styled.div<IPropsContainerInput>`
  display: flex;
  height: 40px;
  margin-left: -1.5%;
  margin-top: -10%;
  min-width: 460px;
  padding: var(--Spacing-8, 8px) 16px;
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-8, 8px);
  flex: 1 0 0;
  position: absolute;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid var(--Gray-gray-600, #6A717E);
  background: var(--Main-white, #FFF);
  z-index:3;

  .Lupa{
    width: 20px;
    height: 20px;
    position: relative;
    z-index: 2;
  }

  button {
    all: unset;
    display: ${(props) => (props.isSearch ? 'flex' : 'none')};
    color: black;
    cursor: pointer;
    color: ${(props) => props.theme['gray-700']};
    line-height: 0;
    font-size: 1.125rem;
    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  &:has(input:focus) {
    border-color: #EC6643;
  }

  &:has(input:disabled) {
    cursor: not-allowed;
    background: ${(props) => props.theme['white-300']};
    button {
      cursor: not-allowed;
    }
  }

  ${(props) =>
    props.isOutline &&
    css`
      border-color: ${props.theme['gray-200']};
    `}
  ${(props) =>
    props.isError &&
    css`
      border-color: ${props.theme['danger-300']};
    `}
`;

export const Input = styled.input`
  all: unset;
  width: 100%;
  color: ${(props) => props.theme['gray-400']};
  font-size: ${(props) => props.theme.spacing[16]};
  ::placeholder {
    color: ${(props) => props.theme['gray-100']};
  }
`;

export const FixContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .prefix {
    color: ${(props) => props.theme['gray-400']};
    font-size: ${(props) => props.theme.spacing[16]};
  }
`;

import { Carousel } from 'antd';

import styles from './styles.module.css';
import { ContentText } from './styles';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { financeRoutes } from '../../../../../utils/RoutesApi';
import { transformMaskCurrency } from '../../../../../utils/transformMaskCurrency';
import { useActivesWallet } from '../../hooks/ActivesHooks';
import { useActives } from '../../../../../hooks/useActives';

export function CarouselDashInfos() {
  const { totalInvestments, totalReserve } = useActivesWallet();
  // @ts-ignore
  const { showActive } = useActives();

  const [earningsReceived, setEarningsReceived] = useState(0);
  const [nextEarningsReceived, setNextEarningsReceived] = useState(0);

  useEffect(() => {
    getEarningReceived();
    getNextEarningReceived();
  }, []);
  async function getEarningReceived() {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_PROCEEDS_RECEIVED}`
      )
      .then((response) => {
        const totalReceived = response.data.data.total_periodos.reduce(
          //@ts-ignore
          (acc, item) => {
            return acc + item.total;
          },
          0
        );
        setEarningsReceived(totalReceived);
      })
      .catch((err) => {});
  }
  async function getNextEarningReceived() {
    await axios
      .get(`${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_PROCEEDS}`)
      .then((response) => {
        //@ts-ignore
        let reduceTotal = response.data.data.reduce(
          //@ts-ignore
          (acc, item) => item.total + acc,
          0
        );

        setNextEarningsReceived(reduceTotal);
      })
      .catch((err) => {});
  }

  const earningReceivedMask = transformMaskCurrency(earningsReceived);
  const nextEarningReceivedMask = transformMaskCurrency(nextEarningsReceived);

  return (
    <Carousel
      arrows
      autoplay
      autoplaySpeed={5000}
      className={styles.Container}
      draggable
      dotPosition="bottom"
      dots={{ className: styles.dots }}
    >
      <ContentText>
        <h3>{showActive ? totalInvestments : 'R$ *******'}</h3>
        <span>Total em investimentos</span>
      </ContentText>
      <ContentText>
        <h3>{showActive ? totalReserve : 'R$ *******'}</h3>
        <span>Reserva de emergência</span>
      </ContentText>
      <ContentText>
        <h3>{showActive ? nextEarningReceivedMask : 'R$ *******'}</h3>
        <span>Próximos proventos</span>
      </ContentText>
      <ContentText>
        <h3>{showActive ? earningReceivedMask : 'R$ *******'}</h3>
        <span>Total dos últimos 12 meses</span>
      </ContentText>
    </Carousel>
  );
}

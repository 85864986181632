import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';
import { IPropsPosition } from '../utils/@types/wallet';
import { financeRoutes } from '../utils/RoutesApi';

export const ActivesContext = createContext({});

interface IPropsActivesProvider {
  children: React.ReactNode;
}
interface IStateActives {
  novoSumAcao: number;
  novoSumFII: number;
  positionAcao: IPropsPosition[];
  positionFII: IPropsPosition[];
  positionsAll: IPropsPosition[];
  sumAcao: number;
  sumsEFT: number;
  sumsFII: number;
  rentabilidade: IProfitability[];
}
interface IProfitability {
  referenceDate: string;
  value: number;
}

interface IPercentage {
  fii: number;
  action: number;
  rendaFixa: number;
}

export const ActivesProvider = ({ children }: IPropsActivesProvider) => {
  //@ts-ignore
  const isShowActive = localStorage.getItem('isShowActive');
  const [actives, setActives] = useState<IStateActives>({
    novoSumAcao: 0,
    novoSumFII: 0,
    positionAcao: [],
    positionFII: [],
    positionsAll: [],
    sumAcao: 0,
    sumsEFT: 0,
    sumsFII: 0,
    rentabilidade: [],
  });
  const [percentage, setPercentage] = useState<IPercentage>({
    fii: 0,
    action: 0,
    rendaFixa: 0,
  });
  const [showActive, setShowActive] = useState<boolean>(false);
  const [loadingActive, setLoadingActive] = useState<boolean>(false);

  useEffect(() => {
    if (!isShowActive) {
      localStorage.setItem('isShowActive', 'false');
    } else {
      setShowActive(isShowActive !== 'false');
    }
  }, []);

  useEffect(() => {
    getActives();
  }, [location.pathname]);

  async function getActives() {
    setLoadingActive(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_POSITION}`)
      .then(function (result) {
        setActives(result.data);

        let act = result.data.sumAcao.toFixed(2);
        let fii = result.data.sumsFII.toFixed(2);
        let rf = result.data.sumsEFT.toFixed(2);

        const total = Number(act) + Number(fii) + Number(rf);

        let actionPercentage =
          result.data.sumAcao === 0
            ? 0
            : Number((result.data.sumAcao / total) * 100);
        actionPercentage = Number(actionPercentage.toFixed(2));

        let fiiPercentage =
          result.data.sumsFII === 0 ? 0 : (result.data.sumsFII / total) * 100;

        fiiPercentage = Number(fiiPercentage.toFixed(2));
        let rfPercentage =
          result.data.sumsEFT === 0 ? 0 : (result.data.sumsEFT / total) * 100;

        rfPercentage = Number(rfPercentage.toFixed(2));

        let newPercentage = {
          fii: fiiPercentage,
          action: actionPercentage,
          rendaFixa: rfPercentage,
        };

        setPercentage(newPercentage);
      })
      .catch(function (error) {});
    setLoadingActive(false);
  }

  return (
    <ActivesContext.Provider
      value={{
        actives,
        setActives,
        setPercentage,
        percentage,
        showActive,
        setShowActive,
        loadingActive,
      }}
    >
      {children}
    </ActivesContext.Provider>
  );
};

import styled from 'styled-components';

export const MotivationInvestContainer = styled.section`
  margin-top: 5rem;
  width: 100vw;
  height: 100%;
  background: #191819;
  margin-bottom: 5rem;
  overflow: hidden;
  position: relative;
  padding: 4rem 1rem;
  padding-top: 7rem;
  .blueColor {
    position: absolute;
    left: 0;
    bottom: 0;
    box-shadow: 50px 0px 200px 150px ${(props) => props.theme.primary};
  }
  .yellowColor {
    position: absolute;
    right: 0;
    top: 0;
    box-shadow: 10px 20px 200px 150px #ffc425;
  }
`;

export const MotivationInvestContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  width: 100%;
  max-width: 66.25rem;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  gap: 1rem;
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const MotivationInvestCol = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.white};
  position: relative;
  z-index: 1;
  text-align: start;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;

  header {
    display: flex;
    flex-direction: column;
    span {
      width: 3.1875rem;
      height: 0.875rem;
      border-radius: 17px;
      background: rgb(206, 19, 28);
      background: linear-gradient(
        39deg,
        rgba(206, 19, 28, 1) 0%,
        rgba(44, 24, 25, 1) 35%,
        rgba(24, 24, 24, 1) 46%,
        rgba(21, 38, 53, 1) 69%,
        rgba(1, 118, 225, 1) 100%
      );
    }
    h2 {
      font-weight: 500;
      font-size: 1.75rem;
      line-height: 150%;
      color: ${(props) => props.theme.white};
    }
  }
  p {
    font-weight: 300;
    font-size: 1.5rem;
    color: #e4e4e4;
    margin-bottom: 0;
  }
  button {
    all: unset;
    background: ${(props) => props.theme.primary};
    padding: 1.25rem;
    text-align: center;
    width: 100%;
    max-width: 13.125rem;
    border-radius: 8px;
    color: ${(props) => props.theme.white};
    font-weight: 500;
    font-size: 1.125rem;

    justify-content: flex-start;
  }

  @media (max-width: 768px) {
    header {
      h2 {
        font-size: 1.375rem;
        margin-top: 1.5rem;
        margin-bottom: 2.25rem;
      }
    }
    p {
      font-size: 1.125rem;
    }
  }
`;

export const InformationCol = styled.div`
  display: flex;
  flex-direction: column;

  header {
    margin-bottom: 1.5rem;
    h6 {
      color: ${(props) => props.theme.white};
      font-size: 1rem;
      font-weight: 600;
      text-align: end;
    }
  }
  p {
    font-weight: 500;
    color: ${(props) => props.theme.white};
    text-align: end;
    font-size: 0.875rem;
    line-height: 171%;

    strong {
      font-weight: 700;
    }
  }

  @media (max-width: 768px) {
    header {
      h6 {
        text-align: start;
      }
    }
    p {
      text-align: start;
    }
  }
`;

import styled from 'styled-components';

export const SimulationContainer = styled.section`
  margin-top: 10rem;
  width: 100vw;
  height: 100%;
  background: #191819;
  margin-bottom: 5rem;
  overflow: hidden;
  position: relative;
  padding: 8rem 1rem;
  .blueColor {
    position: absolute;
    left: 0;
    bottom: 0;
    box-shadow: 50px 0px 200px 150px ${(props) => props.theme.primary};
  }
  .redColor {
    position: absolute;
    right: 0;
    top: 0;
    box-shadow: 10px 20px 200px 150px red;
  }
`;

export const SimulationContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  width: 100%;
  max-width: 66.25rem;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  gap: 1rem;
  position: relative;
  z-index: 1;
  img {
    margin-left: auto;
  }

  .previewImg {
    width: 100%;
    max-width: 25rem;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;

    img {
      margin-left: 0;
    }
  }
  @media (max-width: 480px) {
    .previewImg {
      width: 100%;
      max-width: 22rem;
    }
  }
`;

export const SimulationCol = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.white};
  position: relative;
  z-index: 1;
  text-align: start;
  align-items: flex-start;

  header {
    display: flex;
    flex-direction: column;
    span {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 200%;
    }
    h2 {
      font-weight: 500;
      font-size: 1.75rem;
      line-height: 150%;
      color: ${(props) => props.theme.white};
    }
  }
  p {
    margin: 3rem 0;
    font-weight: 400;
    font-size: 1.125rem;
    color: #e4e4e4;
  }
  button {
    all: unset;
    background: ${(props) => props.theme.primary};
    padding: 1.25rem;
    text-align: center;
    width: 100%;
    max-width: 13.125rem;
    border-radius: 8px;
    color: ${(props) => props.theme.white};
    font-weight: 500;
    font-size: 1.125rem;

    justify-content: flex-start;
  }
  @media (max-width: 768px) {
    margin-top: 2rem;

    header {
      h2 {
        font-size: 1.125rem;
      }
    }
    p {
      font-size: 0.875rem;
    }
  }
`;

import { Progress, Tooltip } from 'antd';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { CaretDown, CaretUp, Eye, EyeSlash } from '@phosphor-icons/react';
import { useHistory } from 'react-router-dom';
import { IWalletProps } from '../../../../../../../utils/@types/wallet';
import { useActives } from '../../../../../../../hooks/useActives';
import { defineDisplayColor } from '../../../../DefineDisplayColor';
import { RenderConditionalObjective } from '../../../../../RenderConditionalObjective';
import { filterTableSorte } from '../../../../../../../utils/filterTableSort';
import { transformMaskCurrency } from '../../../../../../../utils/transformMaskCurrency';
import { ButtonComponent } from '../../../../../../../components/ButtonComponent';
import { GraphicNote } from '../../../../../../pages/Wallet/Tabs/AllActive/components/AllTable/GraphicNote';
import { CompanyNameContent } from './styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#FFF',
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const header = [
  { label: 'Ativo', active: 'tickerSymbol' },
  { label: 'Nota', active: 'nota' },
  { label: 'Quant.', active: 'equitiesQuantity' },
  { label: 'Patrimônio', active: 'updateValue' },
  { label: 'Cotação', active: 'closingPrice' },
  { label: 'Objetivo', active: 'ValorExp' },
  { label: 'Atual', active: 'percent' },
  { label: 'Diferença', active: 'gap' },
  { label: 'Preço médio', active: 'PrecoMedio' },
  { label: 'Rentabilidade', active: 'Rentabilidade' },
  { label: 'Lucro/Prejuízo', active: 'lucro' },
];

interface IPropsActionTable {
  walletValues: IWalletProps;
  setWalletValues: Function;
  filterBrokerage?: string;
  setRule: Function;
  setShowModalCreateRule: Function;
  setShowModalFilterColumnTable: Function;
  filterShowColumnTable: string[];
  filterTextSearchActive: string;
}

export function ActionsTable({
  walletValues,
  setWalletValues,
  setRule,
  setShowModalCreateRule,
  filterBrokerage,
  setShowModalFilterColumnTable,
  filterShowColumnTable,
  filterTextSearchActive,
}: IPropsActionTable) {
  //@ts-ignore
  const { showActive } = useActives();

  const [showAmountActives, setShowAmountActives] = useState<boolean>(false);
  const [showActiveName, setShowActiveName] = useState<boolean>(false);

  const showActiveColumnTable = filterShowColumnTable.includes('Ativo');
  const showNoteColumnTable = filterShowColumnTable.includes('Nota');
  const showAmountColumnTable = filterShowColumnTable.includes('Quant.');
  const showPatrimonyColumnTable = filterShowColumnTable.includes('Patrimônio');
  const showGoalColumnTable = filterShowColumnTable.includes('Objetivo');
  const showPriceColumnTable = filterShowColumnTable.includes('Cotação');
  const showCurrentColumnTable = filterShowColumnTable.includes('Atual');
  const showGapColumnTable = filterShowColumnTable.includes('Diferença');

  const showProfitColumnTable =
    filterShowColumnTable.includes('Lucro/Prejuízo');
  const showProfitabilityColumnTable =
    filterShowColumnTable.includes('Rentabilidade');
  const showAveragePriceColumnTable =
    filterShowColumnTable.includes('Preço médio');

  const ArrayFilteredAllTabe = walletValues?.positionAcao?.filter((item) => {
    if (
      item.tickerSymbol
        .toLowerCase()
        .includes(filterTextSearchActive.toLocaleLowerCase()) ||
      item.corporationName
        .toLowerCase()
        .includes(filterTextSearchActive.toLocaleLowerCase())
    ) {
      return item;
    }
  });

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
      <Table aria-label="customized table" stickyHeader>
        <TableHead>
          <TableRow>
            {header.map((item) => {
              if (filterShowColumnTable.includes(item.label)) {
                return (
                  <StyledTableCell key={item.label}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        fontSize: '0.95rem',
                      }}
                    >
                      {item.label}
                      {item.active && (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <CaretUp
                            size={14}
                            color="black"
                            onClick={() => {
                              setWalletValues({
                                ...walletValues,
                                //@ts-ignore
                                positionAcao: filterTableSorte({
                                  data: walletValues.positionAcao,
                                  dir: 'asc',
                                  //@ts-ignore
                                  key: item.active,
                                }),
                              });
                            }}
                          />
                          <CaretDown
                            size={14}
                            color="black"
                            onClick={() => {
                              setWalletValues({
                                ...walletValues,
                                //@ts-ignore
                                positionAcao: filterTableSorte({
                                  data: walletValues.positionAcao,
                                  dir: 'desc',
                                  //@ts-ignore
                                  key: item.active,
                                }),
                              });
                            }}
                          />
                        </div>
                      )}
                      {item.label === 'Quant.' &&
                        (showAmountActives ? (
                          <Eye
                            size={20}
                            color="black"
                            onClick={() =>
                              setShowAmountActives(!showAmountActives)
                            }
                          />
                        ) : (
                          <EyeSlash
                            size={20}
                            color="black"
                            onClick={() =>
                              setShowAmountActives(!showAmountActives)
                            }
                          />
                        ))}
                      {item.label === 'Ativo' &&
                        (showActiveName ? (
                          <Eye
                            size={20}
                            color="black"
                            onClick={() => setShowActiveName(!showActiveName)}
                          />
                        ) : (
                          <EyeSlash
                            size={20}
                            color="black"
                            onClick={() => setShowActiveName(!showActiveName)}
                          />
                        ))}
                    </div>
                  </StyledTableCell>
                );
              }
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {ArrayFilteredAllTabe &&ArrayFilteredAllTabe.length > 0 ? (
            ArrayFilteredAllTabe.map((item, index) => (
              <StyledTableRow key={index}>
                {showActiveColumnTable && (
                  <StyledTableCell className="reduce link">
                    <CompanyNameContent>
                      {showActiveName || showActive ? (
                        <>
                          <strong title={item.tickerSymbol}>
                            {item.tickerSymbol}
                          </strong>
                          <span title={item.corporationName}>
                            {' '}
                            {item.corporationName}
                          </span>
                        </>
                      ) : (
                        '*****'
                      )}
                    </CompanyNameContent>
                  </StyledTableCell>
                )}
                {showNoteColumnTable && (
                  <StyledTableCell style={{ width: '150px' }}>
                    <Tooltip
                      title={
                        item.nota <= 0
                          ? 'Nota não aplicável a esse ativo ou não encontrada'
                          : `${item.nota.toFixed(0)}%`
                      }
                    >
                      {item.nota <= 0 ? (
                        <>N/A</>
                      ) : (
                        <GraphicNote
                          noteValue={item.nota}
                          color={
                            item.nota <= 50
                              ? '#ff0000'
                              : item.nota <= 60
                              ? '#ffff00'
                              : '#0000ff'
                          }
                        />
                      )}
                    </Tooltip>
                  </StyledTableCell>
                )}

                {showAmountColumnTable && (
                  <StyledTableCell>
                    {showActive || showAmountActives
                      ? item.equitiesQuantity
                      : '****'}
                  </StyledTableCell>
                )}
                {showPatrimonyColumnTable && (
                  <StyledTableCell
                    className="reduce"
                    title={
                      showActive
                        ? transformMaskCurrency(item.updateValue)
                        : 'R$ ******'
                    }
                  >
                    {showActive
                      ? transformMaskCurrency(item.updateValue)
                      : 'R$ ******'}
                  </StyledTableCell>
                )}
                {showPriceColumnTable && (
                  <StyledTableCell>
                    {transformMaskCurrency(item.closingPrice)}
                  </StyledTableCell>
                )}
                {showGoalColumnTable && (
                  //@ts-ignore
                  <RenderConditionalObjective
                    active={item}
                    exceptions={item.Exception}
                    setRule={setRule}
                    setShowModalCreateRule={setShowModalCreateRule}
                    walletValues={walletValues}
                    action
                  />
                )}
                {showCurrentColumnTable && (
                  <StyledTableCell
                    style={{
                      color: defineDisplayColor({
                        item,
                        exceptions: item.Exception,
                        type: 'action',
                        //@ts-ignore
                        wallet: walletValues,
                        filterBrokerage: undefined,
                      }),
                    }}
                  >
                    {item.percent.toFixed(2)}%
                  </StyledTableCell>
                )}
                {showGapColumnTable && (
                  <StyledTableCell>{item?.gap?.toFixed(2)}%</StyledTableCell>
                )}
                {showAveragePriceColumnTable && (
                  <StyledTableCell
                    className="reduce"
                    title={
                      showActive
                        ? transformMaskCurrency(item.PrecoMedio)
                        : 'R$ ******'
                    }
                  >
                    {showActive
                      ? transformMaskCurrency(item.PrecoMedio)
                      : 'R$ ******'}
                  </StyledTableCell>
                )}
                {showProfitabilityColumnTable && (
                  <StyledTableCell
                    style={{
                      color: item.Rentabilidade < 0 ? 'red' : '#44bd32',
                    }}
                  >
                    {item?.Rentabilidade?.toFixed(2)}%
                  </StyledTableCell>
                )}

                {showProfitColumnTable && (
                  <StyledTableCell
                    className="reduce"
                    title={
                      showActive
                        ? transformMaskCurrency(item.lucro)
                        : 'R$ ******'
                    }
                    style={{
                      color: item.lucro < 0 ? 'red' : '#44bd32',
                    }}
                  >
                    {showActive
                      ? transformMaskCurrency(item.lucro)
                      : 'R$ ******'}
                  </StyledTableCell>
                )}
              </StyledTableRow>
            ))
          ) : (
            <h1 style={{ fontSize: '1rem', margin: '1rem 0.5rem' }}>
              Ainda não há dados
            </h1>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

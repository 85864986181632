import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 0,
    marginBottom: theme.spacing(2),
    width: '100%',
  },
}));

export default useStyles;

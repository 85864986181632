import React, { createContext, useEffect, useState } from 'react';
import { useActivesWallet } from '../hooks/ActivesHooks';
import { IPropsActiveWallet, IPropsGeneralActiveWallet } from '../@types';
import { handleToast } from '../../../../components/Toast';
import axios from 'axios';
import { walletRoutes } from '../../../../utils/RoutesApi';
import { IPropsException } from '../../../components/RenderConditionalObjective';
import { transformMaskCurrency } from '../../../../utils/transformMaskCurrency';

interface IPropsSimulationProvider {
  children: React.ReactNode;
}

interface IPropsSimulationContextValue {
  actions: IPropsActiveWallet[];
  fiis: IPropsActiveWallet[];
  all: IPropsGeneralActiveWallet[];
  selected: IPropsActiveWallet[];
  selectedFiis: IPropsActiveWallet[];
  selectedAll: IPropsActive[];
  valueAporte: number | string;
  sendAllAssets: 'all' | 'selected';
  totalAport: string;
  rowSelectionAction: any;
  rowSelectionFii: any;
  rowSelectionAll: any;
  setSendAllAssets: (sendAllAssets: 'all' | 'selected') => void;
  seValueAporte: (val: number) => void;
  setType: (type: 'action' | 'fii' | 'all' | 'broker') => void;
  submitSimulator: (data: any) => void;
  setResults: Function;
  type: 'action' | 'fii' | 'all' | 'broker';
  loading: boolean;
  results: IPropsActive[];
  updateValueTotal: number;
}

export const SimulationContext = createContext<IPropsSimulationContextValue>({
  actions: [],
  fiis: [],
  all: [],
  selected: [],
  selectedFiis: [],
  selectedAll: [],
  valueAporte: 0,
  sendAllAssets: 'all',
  totalAport: '',
  rowSelectionAction: {},
  rowSelectionFii: {},
  rowSelectionAll: {},
  seValueAporte: () => {},
  setType: () => {},
  setSendAllAssets: () => {},
  submitSimulator: () => {},
  type: 'all',
  results: [],
  loading: false,
  updateValueTotal: 0,
  setResults: () => {},
});

interface IPropsActive {
  Exception: IPropsException[];
  ValorExp: number;
  corporationName: string;
  novaQuantidade: number;
  aportarQtde: number;
  closingPrice: number;
  equitiesQuantity: number;
  novoupdateValue: number;
  percent: number;
  tickerSymbol: string;
  updateValue: number;
  key: number;
  target: number;
  newpercent: number;
  totalPercent: number;
  moneyAporte: number;
  finalPercent: number;
  productTypeName: string;
  porcentagem_recomendada: number;
}

export const SimulationProvider = ({ children }: IPropsSimulationProvider) => {
  const { fullWallet, typeWallet, selectedBrokerage } = useActivesWallet();
  const wallet = fullWallet;

  const [actions, setActions] = useState<IPropsActiveWallet[]>([]);
  const [fiis, setFiis] = useState<IPropsActiveWallet[]>([]);
  const [all, setAll] = useState<IPropsGeneralActiveWallet[]>([]);
  const [selected, setSelected] = useState<IPropsActiveWallet[]>([]);
  const [selectedFiis, setSelectedFiis] = useState<IPropsActiveWallet[]>([]);
  const [selectedAll, setSelectedAll] = useState<IPropsActive[]>([]);
  const [results, setResults] = useState<IPropsActive[]>([]);
  const [updateValueTotal, setUpdateValue] = useState(0);
  const [valueAporte, seValueAporte] = useState<number | string>(0);
  const [sendAllAssets, setSendAllAssets] = useState<'all' | 'selected'>(
    'selected'
  );
  const [type, setType] = useState<'action' | 'fii' | 'all' | 'broker'>('all');
  const [loading, setLoading] = useState<boolean>(false);
  const [totalAport, setTotalAport] = useState<string>('');

  useEffect(() => {
    if (wallet && wallet?.positionsAll?.length > 0) {
      if (sendAllAssets === 'all') {
        if (type === 'all') {
          let newItem = wallet?.positionsAll?.map((item) => {
            let returnItem = item;

            let isRuleReadyExist = returnItem.Exception.find(
              (ex) => ex.type === 'all'
            );

            if (isRuleReadyExist) {
              returnItem.ValorExp = isRuleReadyExist.value;
            } else {
              if (returnItem.productTypeName === 'acao') {
                returnItem.ValorExp = wallet?.objetivoAllAcao;
              } else if (returnItem?.productTypeName === 'fii') {
                returnItem.ValorExp = wallet?.objetivoAllFII;
              } else {
                returnItem.ValorExp = wallet?.objetivoAllRenda;
              }
            }
            if (typeWallet) {
              returnItem.ValorExp = item.porcentagem_recomendada;
            }
            returnItem.key = Math.floor(Math.random() * (99999 - 1)) + 1;

            return returnItem;
          });

          setAll(newItem);
        }

        if (type === 'broker') {
          let newItem = wallet?.positionsAll?.map((item) => {
            let returnItem = item;

            let isRuleReadyExist = returnItem.Exception.find(
              (ex) => ex.corretora === selectedBrokerage
            );
            if (isRuleReadyExist) {
              returnItem.ValorExp = isRuleReadyExist?.value;
            } else {
              if (returnItem.productTypeName === 'acao') {
                returnItem.ValorExp = wallet?.objetivoAllAcao;
              } else if (returnItem.productTypeName === 'fii') {
                returnItem.ValorExp = wallet?.objetivoAllFII;
              } else {
                returnItem.ValorExp = wallet?.objetivoAllRenda;
              }
            }
            if (typeWallet) {
              returnItem.ValorExp = item.porcentagem_recomendada;
            }
            returnItem.key = Math.floor(Math.random() * (99999 - 1)) + 1;

            return returnItem;
          });
          setAll(newItem);
        }

        if (type === 'fii') {
          let newItem = wallet?.positionFII.map((item) => {
            let returnItem = item;

            let isRuleReadyExist = returnItem.Exception.find(
              (ex) => ex.type === 'fii'
            );
            if (isRuleReadyExist) {
              returnItem.ValorExp = isRuleReadyExist.value;
            } else {
              returnItem.ValorExp = wallet?.objetivoFII;
            }
            returnItem.key = Math.floor(Math.random() * (99999 - 1)) + 1;

            return returnItem;
          });
          setFiis(newItem);
        }

        if (type === 'action') {
          let newItem = wallet?.positionAcao.map((item) => {
            let returnItem = item;

            let isRuleReadyExist = returnItem.Exception.find(
              (ex) => ex.type === 'action'
            );
            if (isRuleReadyExist) {
              returnItem.ValorExp = isRuleReadyExist.value;
            } else {
              returnItem.ValorExp = wallet?.objetivoAcao;
            }
            returnItem.key = Math.floor(Math.random() * (99999 - 1)) + 1;

            return returnItem;
          });

          setActions(newItem);
        }
      } else {
        if (type === 'all') {
          let newItem = wallet?.positionsAll.map((item) => {
            let returnItem = item;

            let isRuleReadyExist = returnItem.Exception.find(
              (ex) => ex.type === 'all'
            );
            if (isRuleReadyExist) {
              returnItem.ValorExp = isRuleReadyExist.value;
            } else {
              if (returnItem.productTypeName === 'acao') {
                returnItem.ValorExp = wallet?.objetivoAllAcao;
              } else if (returnItem.productTypeName === 'fii') {
                returnItem.ValorExp = wallet?.objetivoAllFII;
              } else {
                returnItem.ValorExp = wallet?.objetivoAllRenda;
              }
            }
            returnItem.key = Math.floor(Math.random() * (99999 - 1)) + 1;
            if (typeWallet) {
              returnItem.ValorExp = item.porcentagem_recomendada;
            }

            return returnItem;
          });

          newItem = newItem.filter((item) => item.percent < item.ValorExp);

          setAll(newItem);
        }
        if (type === 'action') {
          let newItem = wallet?.positionAcao.map((item) => {
            let returnItem = item;

            let isRuleReadyExist = returnItem.Exception.find(
              (ex) => ex.type === 'action'
            );
            if (isRuleReadyExist) {
              returnItem.ValorExp = isRuleReadyExist.value;
            } else {
              returnItem.ValorExp = wallet?.objetivoAcao;
            }
            returnItem.key = Math.floor(Math.random() * (99999 - 1)) + 1;

            return returnItem;
          });
          newItem = newItem.filter((item) => item.percent < item.ValorExp);

          setActions(newItem);
        }
        if (type === 'fii') {
          let newItem = wallet?.positionFII.map((item) => {
            let returnItem = item;

            let isRuleReadyExist = returnItem.Exception.find(
              (ex) => ex.type === 'fii'
            );
            if (isRuleReadyExist) {
              returnItem.ValorExp = isRuleReadyExist.value;
            } else {
              returnItem.ValorExp = wallet?.objetivoFII;
            }
            returnItem.key = Math.floor(Math.random() * (99999 - 1)) + 1;

            return returnItem;
          });
          newItem = newItem.filter((item) => item.percent < item.ValorExp);

          setFiis(newItem);
        }
        if (type === 'broker') {
          let newItem = wallet?.positionsAll.map((item) => {
            let returnItem = item;

            let isRuleReadyExist = returnItem.Exception.find(
              (ex) => ex.corretora === selectedBrokerage
            );
            if (isRuleReadyExist) {
              returnItem.ValorExp = isRuleReadyExist.value;
            } else {
              returnItem.ValorExp = wallet?.objetivoAll;
            }
            returnItem.key = Math.floor(Math.random() * (99999 - 1)) + 1;
            if (typeWallet) {
              returnItem.ValorExp = item.porcentagem_recomendada;
            }
            return returnItem;
          });
          newItem = newItem.filter((item) => item.percent < item.ValorExp);

          setAll(newItem);
        }
      }
    }
  }, [wallet, sendAllAssets, type, typeWallet]);

  useEffect(() => {
    rowSelectionAction.onChange(
      actions?.map((item) => item.key),
      actions
    );
    rowSelectionFii.onChange(
      fiis?.map((item) => item.key),
      fiis
    );
    rowSelectionAll.onChange(
      all?.map((item) => item.key),
      all
    );
  }, [fiis, actions, all, sendAllAssets, type]);

  const rowSelectionAction = {
    //@ts-ignore

    onChange: (selectedRowKeys, selectedRows) => {
      setSelected(selectedRows);
    },
    selectedRowKeys: selected?.map((item) => item.key),
  };
  const rowSelectionFii = {
    //@ts-ignore
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedFiis(selectedRows);
    },
    selectedRowKeys: selectedFiis?.map((item) => item.key),
  };
  const rowSelectionAll = {
    //@ts-ignore
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedAll(selectedRows);
    },
    selectedRowKeys: selectedAll?.map((item) => item.key),
  };

  async function submitSimulator(data: any) {
    setLoading(true);

    data.corretora = '';
    if (valueAporte) {
      data.aporte = valueAporte
        .toString()
        .replace(/\D/g, '')
        .replace(/(\d)(\d{2})$/, '$1.$2');
    }
    if (!data.aporte) {
      setLoading(false);
      return handleToast(true, 'Informe o valor do aporte');
    }
    if (type === 'action') {
      if (selected.length <= 0) {
        setLoading(false);

        return handleToast(true, 'Selecione pelo menos 1 ativo');
      }
      data.type = 'action';

      data.ativos = selected;
    } else if (type === 'fii') {
      if (selectedFiis.length <= 0) {
        setLoading(false);

        return handleToast(true, 'Selecione pelo menos 1 ativo');
      }
      data.ativos = selectedFiis;
      data.type = 'fii';
    }
    if (type === 'all' || type === 'broker') {
      data.type = 'all';
      data.corretora = '';

      if (selectedAll.length <= 0) {
        setLoading(false);
        return handleToast(true, 'Selecione pelo menos 1 ativo');
      }
      data.ativos = selectedAll;
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}${walletRoutes.WALLET_SIMULATOR_ALL_SUBMISSION}`,
          data
        )
        .then((response) => {
          let totalUpdateValue = 0;
          response.data.data.map((item: IPropsActive) => {
            totalUpdateValue = item.updateValue + totalUpdateValue;
          });
          setUpdateValue(totalUpdateValue);

          const newData = response.data.data.map((item: IPropsActive) => {
            return {
              ...item,
              moneyAporte: item.aportarQtde * item.closingPrice,
            };
          });

          const reduceTotalAport = newData.reduce(
            (acc: number, item: IPropsActive) => {
              return acc + item.moneyAporte;
            },
            0
          );
          const maskTotalAport = transformMaskCurrency(reduceTotalAport);
          setTotalAport(maskTotalAport);
          setLoading(false);
          setResults(newData);
          handleToast(false, 'Simulação concluída com sucesso');

          return;
        })
        .catch((err) => {
          handleToast(true, err.response.data.message);
          setLoading(false);

          return;
        });
      return;
    }

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}${walletRoutes.WALLET_SIMULATOR_ALL_SUBMISSION}`,
        data
      )
      .then((response) => {
        let totalUpdateValue = 0;
        response.data.data.map((item: IPropsActive) => {
          totalUpdateValue = item.updateValue + totalUpdateValue;
        });

        const reduceTotalAport = response.data.data
          .map((item: IPropsActive) => {
            return {
              ...item,
              moneyAporte: item.aportarQtde * item.closingPrice,
            };
          })
          .reduce((acc: number, item: IPropsActive) => {
            return acc + item.moneyAporte;
          }, 0);

        const maskTotalAport = transformMaskCurrency(reduceTotalAport);
        setTotalAport(maskTotalAport);
        setUpdateValue(totalUpdateValue);
        setResults(
          response.data.data.map((item: IPropsActive) => {
            return {
              ...item,
              moneyAporte: item.aportarQtde * item.closingPrice,
            };
          })
        );
        handleToast(false, 'Simulação concluída com sucesso');
        setLoading(false);

        return;
      })
      .catch((err) => {
        handleToast(true, err.response.message);

        setLoading(false);

        return;
      });
  }

  return (
    <SimulationContext.Provider
      value={{
        actions,
        all,
        fiis,
        rowSelectionAction,
        rowSelectionAll,
        rowSelectionFii,
        selected,
        selectedAll,
        selectedFiis,
        sendAllAssets,
        totalAport,
        valueAporte,
        setType,
        seValueAporte,
        setSendAllAssets,
        type,
        results,
        updateValueTotal,
        submitSimulator,
        loading,
        setResults,
      }}
    >
      {children}
    </SimulationContext.Provider>
  );
};

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
  position: relative;
  width: 100%;
  max-width: 227px;
  height: 100%;
  max-height: 96px;
  padding: 0.5rem;

  :before {
    content: '';
    height: 1px;
    width: 100%;
    background: linear-gradient(#44b4b8, #b8b544);
    position: absolute;
    top: 0;
    left: 0;
  }

  span {
    font: 500 0.875rem 'Poppins', sans-serif;
    color: #9ea6a1;
  }
  h3 {
    font: 700 1.5rem 'Poppins', sans-serif;
    display: flex;
    justify-content: center;
    margin: 1rem 0 1.5rem 0;
    color: #5fb49d;
  }
`;

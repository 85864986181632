import {
  SideChartBody,
  ToolTipContainer,
  BottomCardContainer,
  BottomCardHeader,
} from './styles';
import HelpText from '../HelpText';
const SideChart = ({
  label,
  helpText,
  openModal,
  children,
  path,
  className,
}) => {
  return (
    <BottomCardContainer className={className}>
      <BottomCardHeader>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          {path && (
            <svg
              width={24}
              height={24}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
            >
              <path fill="black" d={path} />
            </svg>
          )}
          <span>{label}</span>
        </div>

        <HelpText>
          <ToolTipContainer>{helpText}</ToolTipContainer>
        </HelpText>
      </BottomCardHeader>
      <SideChartBody onClick={() => openModal(true)}>{children}</SideChartBody>
    </BottomCardContainer>
  );
};

export default SideChart;

import styled from 'styled-components';

export const GraphicNoteFiiContainer = styled.div`
  margin-left: 2px;
  /* margin-bottom: 1.5rem; */
  margin-top: 1.5rem;
  margin-right: 10rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  h6 {
    position: absolute;
    margin-bottom: 30px;
    font-size: 0.75rem;
    font-weight: 500;
    color: ${(props) => props.theme['gray-500']};
    font-weight: 500;
    font-weight: 500;
    color: #40444c;
    font-family: Poppins;
    font-size: 14.511px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
`;

import styled from 'styled-components';
import bannerImg from '../../../../../assets/business/banner.svg';

export const LearnContainer = styled.div`
  display: grid;
  grid-template-columns: 1.8fr 1fr;
  background-image: url(${bannerImg});
  width: 100%;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 5rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding-top: 11rem;
  }
`;

export const DataLearnContent = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 82%,
    rgba(0, 0, 0, 0.7707457983193278) 93%,
    rgba(0, 0, 0, 0) 100%
  );

  padding: 2.25rem 2rem;

  h2 {
    color: ${(props) => props.theme.white};
    font-weight: 500;
    font-size: 2rem;
    line-height: 125%;
  }
  p {
    font-size: 1rem;
    font-weight: 500;
    line-height: 150%;
    color: ${(props) => props.theme.white};
    max-width: 29.125rem;
    margin: 1.5rem 0;
  }

  button {
    all: unset;
    background: ${(props) => props.theme.primary};
    padding: 1.25rem;
    text-align: center;
    width: 100%;
    max-width: 13.125rem;
    border-radius: 8px;
    color: ${(props) => props.theme.white};
    font-weight: 500;
    font-size: 1.125rem;

    justify-content: flex-start;
  }

  @media (max-width: 768px) {
    background: linear-gradient(
      360deg,
      rgba(0, 0, 0, 1) 82%,
      rgba(0, 0, 0, 0.7707457983193278) 93%,
      rgba(0, 0, 0, 0) 100%
    );

    padding-top: 4rem;

    h2 {
      font-size: 1.125rem;
    }

    p {
      max-width: none;
      font-size: 0.875rem;
    }
  }

  @media (max-width: 480px) {
    button {
      width: auto;
      max-width: none;
      padding: 1rem;
    }
  }
`;

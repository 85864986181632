import {
  DividendInformationCol,
  DividendInformationContainer,
  DividendInformationContent,
} from './styles';
import { Heading } from '../../../../../components/DefaultStylesTypography';
import { previewWallet } from '../../../../../Constants/images';

interface IPropsScreen {
  onShowRegisterScreen: () => void;
}

export function DividendInformation({ onShowRegisterScreen }: IPropsScreen) {
  return (
    <DividendInformationContainer>
      <div className="blueColor" />
      <div className="redColor" />
      <DividendInformationContent>
        <img src={previewWallet} alt="" className="previewImg" />
        <DividendInformationCol>
          <header>
            <span>Simplifique o seu caminho até a Liberdade Financeira</span>
            <Heading>
              Tudo que você precisa saber sobre seus dividendos!
            </Heading>
          </header>
          <p>
            No planejamento da Liberdade Financeira é fundamental saber se a sua
            Renda Passiva (a renda que vem dos seus investimentos sem você ter
            que trabalhar) está aumentando a cada mês, quando e quanto ela
            aumenta, e quanto irá aumentar nos próximos meses. O iBox6 traz
            todas essas informações para você e ainda mostra o SEU gráfico de
            dividendos.
          </p>
          <button type="button" onClick={onShowRegisterScreen}>
            Acesse agora
          </button>
        </DividendInformationCol>
      </DividendInformationContent>
    </DividendInformationContainer>
  );
}

import styled from 'styled-components';

export const CompanyNameContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  strong {
    font-size: 0.875rem;
    font-weight: 500;
    color: ${(props) => props.theme['gray-400']};
   
  }
  span {
    font-weight: 400;
    font-size: 0.625rem;
    color: ${(props) => props.theme['gray-400']};
    max-width: 25ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import {
  ArrowLeft,
  ArrowRight,
  CaretDown,
  CaretUp,
} from '@phosphor-icons/react';
import { IPropsTransactionsData } from '../../@types';
import { filterTableSorte } from '../../../../../../../utils/filterTableSort';
import { useActives } from '../../../../../../../hooks/useActives';
import { transformMaskCurrency } from '../../../../../../../utils/transformMaskCurrency';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontFamily: 'Poppins, sans-serif',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: 'Poppins, sans-serif',
  },
}));

const StyledBody = styled(TableBody)(
  ({ theme }) => `
  overflow-y: auto;
  max-height:500px;

`
);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface IPropsTableTransactions {
  header: {
    active?: string;
    label: string;
  }[];
  data: IPropsTransactionsData[];
  ASC: Function;
  DESC: Function;
  page: number;
  setPage: Function;
  countPages: number;
}

export function TableTransactions({
  data,
  header,
  ASC,
  DESC,
  countPages,
  page,
  setPage,
}: IPropsTableTransactions) {
  //@ts-ignore
  const { showActive } = useActives();

  function handleChangePage(event: React.ChangeEvent<unknown>, page: number) {
    setPage(page);
  }

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table" stickyHeader>
        <TableHead>
          <TableRow>
            {header.map((item) => (
              <StyledTableCell key={item.active}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    fontSize: '0.95rem',
                  }}
                >
                  {item.label}
                  {item.active && (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <CaretUp
                        size={14}
                        color="black"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          return ASC(
                            filterTableSorte({
                              data: data,
                              dir: 'asc',
                              //@ts-ignore
                              key: item.active,
                            })
                          );
                        }}
                      />
                      <CaretDown
                        size={14}
                        color="black"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          return DESC(
                            filterTableSorte({
                              data: data,
                              dir: 'desc',
                              //@ts-ignore
                              key: item.active,
                            })
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <StyledBody>
          {data?.map((item, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell>
                {moment(item.referenceDate).format('L')}
              </StyledTableCell>
              {showActive ? (
                <StyledTableCell>{item.tickerSymbol}</StyledTableCell>
              ) : (
                <StyledTableCell>*******</StyledTableCell>
              )}

              <StyledTableCell>{item.side}</StyledTableCell>
              <StyledTableCell>
                {transformMaskCurrency(Number(item.priceValue))}
              </StyledTableCell>
              <StyledTableCell>
                {showActive ? item.tradeQuantity : '****'}
              </StyledTableCell>
              <StyledTableCell>
                {showActive
                  ? transformMaskCurrency(Number(item.grossAmount))
                  : 'R$ *****'}
              </StyledTableCell>
              <StyledTableCell>
                {showActive
                  ? transformMaskCurrency(Number(item.emolumentos))
                  : 'R$ *****'}
              </StyledTableCell>
              <StyledTableCell>
                {transformMaskCurrency(Number(item.revenue))}
              </StyledTableCell>
              <StyledTableCell>
                {transformMaskCurrency(Number(item.cumBuyerPrice))}
              </StyledTableCell>

              <StyledTableCell>
                {item.dayTrade ? 'DayTrade' : 'Normal'}
              </StyledTableCell>

              {/* <StyledTableCell>{item.marketName}</StyledTableCell> */}
            </StyledTableRow>
          ))}
        </StyledBody>
      </Table>
      <Stack
        spacing={2}
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
        }}
      >
        <Pagination
          count={countPages}
          page={page}
          //@ts-ignore
          onChange={handleChangePage}
      
        />
      </Stack>
    </TableContainer>
  );
}

import styled from 'styled-components';
import { Card } from '../../../components/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;

  .loading {
    display: flex;
    justify-content: center;
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const Comments = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const BackToTop = styled.div`
  position: fixed;
  bottom: 10px;
  right: 10px;
  background: #fff;
  box-shadow: 0 0 0 2px ${(props) => props.theme.primary};
  border-radius: 100%;
  z-index: 100;
  cursor: pointer;
`;

//@ts-ignore
import ConfettiGenerator from 'confetti-js';

import { CongratulationsCreatingAccountContainer } from './styles';
import { useEffect } from 'react';
import { Check } from '@phosphor-icons/react';
import { Heading } from '../../../../../components/DefaultStylesTypography';

export function CongratulationsCreatingAccount() {
  useEffect(() => {
    const confettiSettings = {
      target: 'my-canvas',
      max: '80',
      size: '1',
      animate: true,
      props: ['circle', 'square', 'triangle', 'line'],
      colors: [
        [165, 104, 246],
        [230, 61, 135],
        [0, 199, 228],
        [253, 214, 126],
      ],
      clock: '25',
      rotate: false,
      width: '1920',
      height: '929',
      start_from_edge: true,
      respawn: true,
    };
    const confetti = new ConfettiGenerator(confettiSettings);

    confetti.render();

    return () => confetti.clear();
  }, []);
  return (
    <CongratulationsCreatingAccountContainer>
      <div style={{ position: 'absolute' }}>
        <canvas id="my-canvas"></canvas>
      </div>
      <Check weight="bold" />
      <header>
        <p>
          <Heading>Que legal!</Heading>
          Sua conta foi criada com sucesso e agora você faz parte da comunidade
          do iBox6!
        </p>
      </header>
      <p>
        Você tem 30 dias de acesso grátis para testar todas as nossas
        funcionalidades.*
      </p>
      <span>
        Se tiver alguma dúvida, nossa equipe de suporte está sempre pronta para
        ajudá-lo.
      </span>
    </CongratulationsCreatingAccountContainer>
  );
}

import moment from 'moment';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { maskValueCards } from '../../../../../components/ResumoAcao/components/maskValueCards';

interface IPropsGraphicCalc {
  valuesGraphic: {
    interest: number[];
    mount: number[];
    totalInvest: number[];
    isGraphic: boolean;
  };
  isLoading: boolean;
}

export function GraphicCalc({ valuesGraphic, isLoading }: IPropsGraphicCalc) {
  moment.locale('pt');

  const date = valuesGraphic.mount.map((item, index) =>
    moment()
      .add(index + 1, 'month')
      .format('MMM/YYYY')
  );

  const option = {
    legend: {
      bottom: 0,
    },
    tooltip: {
      trigger: 'axis',
      valueFormatter: (val: any) => maskValueCards(val.toFixed(0) ?? val),
      axisPointer: {
        axis: 'x',
      },
    },
    grid: {
      containLabel: true,
      height: '200px',
      width: '100%',
      left: '10px',
      top:'15px',
    },
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: 'none',
        },
        restore: {},
        saveAsImage: {},
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: date,

      axisLabel: {
        formatter: function (value: any, index: number) {
          return moment(value).format('MMM/YYYY');
        },
      },
      label: {
        show: true,
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: function (value: any, index: number) {
          return maskValueCards(value.toFixed(0));
        },
      },
    },

    series: [
      {
        name: 'Total Acumulado',
        type: 'line',
        symbol: 'none',
        sampling: 'lttb',
        itemStyle: {
          color: '#EC6643',
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
            {
              offset: 0,
              color: '#EC6643',
            },
            {
              offset: 1,
              color: '#fff',
            },
          ]),
        },
        data: valuesGraphic.mount,
      },

      {
        name: 'Total Juros',
        type: 'line',
        symbol: 'none',
        sampling: 'lttb',
        itemStyle: {
          color: '#FFA600',
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#FFA600',
            },
            {
              offset: 1,
              color: '#fce4b6',
            },
          ]),
        },
        data: valuesGraphic.interest,
      },
      {
        name: 'Total Investido',
        type: 'line',
        symbol: 'none',
        sampling: 'lttb',
        itemStyle: {
          color: '#38486B',
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
            {
              offset: 0,
              color: '#38486B',
            },
            {
              offset: 1,
              color: '#b3c7f5',
            },
          ]),
        },
        data: valuesGraphic.totalInvest,
      },
    ],
  };

  return isLoading ? (
    <span>Carregando</span>
  ) : (
    <ReactECharts option={option} notMerge={true} />
  );
}

import { Trash } from '@phosphor-icons/react';
import { useTitleAndUser } from '../../hooks/userTitle';
import { UserAvatar } from '../Avatar';
import { Container } from './styles';
import moment from 'moment';
import { ResponseComment } from './components/ResponseComment';
import { useState } from 'react';

export interface IPropsComment {
  userProfile: {
    display_name: string;
    id: number;
    profile: {
      banner: string;
      bio: string;
      image: string;
      location: string;
      website: string;
    };

    username: string;
  };
  comment: string;
  commentId: string;
  created: Date;
  onDeleteComment: (id: string) => void;
  videoId: number;
  isResponseComment?: boolean;
}

export function Comment({
  userProfile,
  comment,
  onDeleteComment,
  commentId,
  created,
  videoId,
  isResponseComment = false,
}: IPropsComment) {
  //@ts-ignore
  const { permissions } = useTitleAndUser();
  const [showResponseComment, setShowResponseComment] = useState(false);

  const isAdmin = permissions.includes('Admin');

  return (
    <Container>
      <UserAvatar
        //@ts-ignore
        user={userProfile}
      />

      <main>
        <strong>
          {userProfile.display_name}
          <span>{!isAdmin && moment(created).format('L')}</span>

          {isAdmin && (
            <>
              <span className="deleteAndDate">
                {moment(created).format('L')}
                <button
                  title="Apagar comentário"
                  type="button"
                  onClick={() => onDeleteComment(commentId)}
                >
                  <Trash size={20} />
                </button>
              </span>
            </>
          )}
        </strong>
        <p>{comment}</p>
        {!isResponseComment && (
          <button
            className="responseComment"
            onClick={() => setShowResponseComment(!showResponseComment)}
          >
            {showResponseComment ? 'Ocultar' : 'Responder'}
          </button>
        )}
        {showResponseComment && !isResponseComment && (
          <ResponseComment videoId={videoId} commentId={commentId} />
        )}
      </main>
    </Container>
  );
}

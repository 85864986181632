import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormContent = styled.form`
  display: flex;
  flex-direction: column;
  .inputTable {
    border: none;
    background: transparent;
    border-bottom: ${(props) => props.theme.border};
  }
  gap: 1rem; ;
`;

export const ShowTableDiv = styled.div`
  width: 100%;
  display: flex;
  background: ${(props) => props.theme.white};
  border-radius: 4px;
  box-shadow: ${(props) => props.theme['card-shadow-2']};
  padding: 0.75rem;
  color: ${(props) => props.theme['gray-700']};
  cursor: pointer;
`;

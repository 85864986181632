import styled from 'styled-components';

export const FinishedPlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.white};
  padding: 3.312rem;
  margin-top: 2.25rem;
  text-align: center;

  img {
    width: 18.187rem;
    height: 12.312rem;
  }

  h1 {
    margin-top: 3rem;
    margin-bottom: 0;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 36px;
    color: #353840;
  }

  p {
    margin-bottom: 3rem;
    margin-top: 1rem;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    width: 100%;
    max-width: 33.5rem;
    color: #353840;

    button {
      all: unset;
      color: ${(props) => props.theme.primary};
      cursor: pointer;
      font-weight: 500;

      :hover {
        filter: brightness(0.9);
        transition: filter 0.3s;
      }
    }
  }

  @media (max-width: 480px) {
    background: transparent;
  }
`;

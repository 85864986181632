import { useForm } from 'react-hook-form';
import { useTitleAndUser } from '../../../../hooks/userTitle';
import { Button, NewComment } from '../../../../videos/watchStyles';
import { UserAvatar } from '../../../Avatar';
import { ResponseCommentContainer } from './styles';
import { ChatCircle } from '@phosphor-icons/react';
import axios from 'axios';
import { socialRoutes, videosRoutes } from '../../../../utils/RoutesApi';
import { handleToast } from '../../../Toast';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Comment } from '../..';

interface IPropsResponseComment {
  videoId: number;
  commentId: string;
}

interface IPropsComment {
  created: Date;
  describe: string;
  id: number;
  reply: null | number | string;
  user: {
    display_name: string;
    id: number;
    profile: {
      banner: string;
      bio: string;
      image: string;
      location: string;
      website: string;
    };
    username: string;
  };
  video: number;
}

export function ResponseComment({ videoId, commentId }: IPropsResponseComment) {
  // @ts-ignore
  const { user } = useTitleAndUser();
  const { handleSubmit, register, setValue } = useForm();
  // @ts-ignore
  const userInfo = useSelector((state) => state.auth.auth.user.id);
  const [comments, setComments] = useState<IPropsComment[]>([]);

  useEffect(() => {
    getComment();
  }, []);

  async function getComment() {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}${videosRoutes.LIST_COMMENTS_VIDEO}`,
        {
          params: {
            video__id: videoId,
            comment_id: commentId,
          },
        }
      )
      .then((comment) => {
        setComments(comment.data);
      })
      .catch((err) => {});
  }

  function submitComment(data: any) {
    let newData = {
      describe: data.comment,
      video: videoId,
      user: userInfo,
      reply: commentId,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}${videosRoutes.NEW_COMMENT}`,
        newData
      )
      .then((response) => {
        handleToast(false, 'Comentário publicado com sucesso');

        let newCommentUser: IPropsComment = {
          describe: response.data.describe,
          id: response.data.id,
          user: {
            display_name: user.Name,
            username: user.username,
            id: user.id,
            profile: {
              banner: user.profile.banner,
              bio: user.profile.bio,
              image: user.profile.image,
              location: user.profile.location,
              website: user.profile.website,
            },
          },
          video: response.data.video,
          reply: commentId,
        } as IPropsComment;
        setComments([newCommentUser, ...comments]);
        setValue('comment', '');
      })
      .catch((err) => {
        handleToast(true, 'Erro ao publicar comentário');
      });
  }

  async function deleteComment(id: string) {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}${socialRoutes.DELETE_COMMENT_VIDEO(
          id.toString()
        )}`
      )
      .then((response) => {
        const newArrayComment = comments.filter(
          (comment) => comment.id !== Number(id)
        );
        setComments(newArrayComment);

        handleToast(false, 'Comentário deletado');
      })
      .catch((err) => {});
  }

  return (
    <ResponseCommentContainer>
      <NewComment>
        <UserAvatar user={user} height="50px" width="50px" />
        <form onSubmit={handleSubmit(submitComment)}>
          <textarea placeholder="Digite aqui..." {...register('comment')} />

          <Button color="#FFF" background="var(--primary)" type="submit">
            <ChatCircle size={24} color="#fff" />
            Responder
          </Button>
        </form>
      </NewComment>
      {comments.map((comment) => (
        <Comment
          key={comment.id}
          userProfile={comment.user}
          comment={comment.describe}
          onDeleteComment={deleteComment}
          commentId={comment.id.toString()}
          videoId={videoId}
          created={comment.created}
          isResponseComment
        />
      ))}
    </ResponseCommentContainer>
  );
}

import styled from 'styled-components';

export const CardSelectedActiveContainer = styled.div`
  border-radius: 12px;
  background: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme['shadow-1']};
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 8.125rem;
`;

export const RemoveActiveButton = styled.button`
  all: unset;
  background: ${(props) => props.theme['white-200']};
  color: ${(props) => props.theme['gray-400']};
  border-radius: 100%;

  line-height: 0;

  padding: 0.2rem;
  svg {
    width: 0.625rem;
    height: 0.625rem;
  }
  cursor: pointer;
`;

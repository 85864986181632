import { X } from '@phosphor-icons/react';
import { GraphicNote } from '../GraphicNote';
import { CardSelectedActiveContainer, RemoveActiveButton } from './styles';

interface IPropsCardSelectedGraphicActive {
  active: {
    cnpj: string;
    Assets: string;
    NomeEmpresa: string;
    setor: string;
    sub_setor: string;
    segmento: string;
    LucroLiquido: number;
    UltLucroLiquido: number;
    ROE: number;
    UltRoe: number;
    EBITDA: number;
    UltEBITDA: number;
    Dividendos: number;
    UltDividendos: number;
    DividaLiquida: number;
    UltDividaLiquida: number;
    DividaLiquidaEbitida: number;
    UltDividaLiquidaEbitda: number;
    Resiliencia: number;
    Governanca: number;
    Indices: number;
    total_chart: number;
    LOGO_BRAND: string;
    QtdeAcoesON: number;
    QtdeAcoesPN: number;
    QtdeAcoesTotal: number;
    PorcentagemON: number;
    PorcentagemPN: number;
    PorcentagemTotal: number;
    Ordinárias: number;
    Preferenciais: number;
  };
  onRemoveItem: () => void;
}

export function CardSelectedGraphicActive({
  active,
  onRemoveItem,
}: IPropsCardSelectedGraphicActive) {
  return (
    <CardSelectedActiveContainer>
      <div
        style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
      >
        <RemoveActiveButton title="Remover ativo" onClick={onRemoveItem}>
          {' '}
          <X weight="bold" />
        </RemoveActiveButton>
      </div>
      <GraphicNote active={active} />
      <span>{active.Assets}</span>
    </CardSelectedActiveContainer>
  );
}

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import moment from 'moment';
import { Container, HeaderDate } from './styles';
import { InfinityScroll } from '../../../components/InfinityScroll';

import { useTitleAndUser } from '../../../hooks/userTitle';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { useNewTheme } from '../../../hooks/useNewTheme';
import { EarningLoader } from '../../../jsx/components/LoadingModels';
import { transformMaskCurrency } from '../../../utils/transformMaskCurrency';
import { Heading } from '../../../components/DefaultStylesTypography';

export function ActionCalender() {
  const { isMobile } = useNewTheme();
  const history = useHistory();
  const { setTitle } = useTitleAndUser();
  const [values, setValues] = useState({
    count: 0,
    current: 0,
    data: [],
    links: { next: null, previous: null },
    total_pages: 1,
  });
  const [dataCom, setDataCom] = useState([]);
  const [payday, setPayDay] = useState([]);
  const [total, setTotal] = useState(0);
  const [newLoading, setNewLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showSelected, setShowSelected] = useState([]);
  const [isValidatSrollWindow, setIsValidatSrollWindow] = useState(false);


  useEffect(() => {
    handleGetListDividendsActions();
    setTitle('Calendário de Dividendos');
  }, []);
  useEffect(() => {
    function scrollPosition() {
      setIsValidatSrollWindow(window.scrollY >= 200);
    }

    window.addEventListener('scroll', scrollPosition);
    return () => {
      window.removeEventListener('scroll', scrollPosition);
    };
  }, [window.scroll]);

  async function handleGetListDividendsActions() {
    setNewLoading(false);
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/Finance/dividendo`)
      .then((response) => {
        setTotal(response.data.count);
        let ordValue = response.data.data.sort(function (a, b) {
          if (a.DataCom < b.DataCom) {
            return -1;
          } else {
            return true;
          }
        });

        let newDateOrdem = [];
        let newPayDay = [];
        if (ordValue.length > 0) {
          newDateOrdem = ordValue.map((item) => {
            return item.DataCom;
          });
        }

        if (newDateOrdem.length > 0) {
          let RemoveDuplicateItems = [...new Set(newDateOrdem)];

          setDataCom(RemoveDuplicateItems);
        }
        if (ordValue.length > 0) {
          newPayDay = ordValue.map((item) => {
            return item.Pagamento;
          });
        }
        if (newPayDay.length > 0) {
          let RemoveDuplicateItemsPayday = [...new Set(newPayDay)];
          setPayDay(RemoveDuplicateItemsPayday);
        }

        setValues(ordValue);
        setNewLoading(true);
      });
    setLoading(false);
  }
  function handleGetListDividendsActionsInfinity() {
    setNewLoading(false);
    let count = page + 1;
    setPage(count);
    axios
      .get(`${process.env.REACT_APP_API_URL}/Finance/dividendo/?page=${count}`)
      .then((response) => {
        let ordValue = response.data.data.sort(function (a, b) {
          if (a.DataCom < b.DataCom) {
            return -1;
          } else {
            return true;
          }
        });

        let newDateOrdem = [];
        let newPayDay = [];
        if (ordValue.length > 0) {
          newDateOrdem = ordValue.map((item) => {
            return item.DataCom;
          });
        }
        if (newDateOrdem.length > 0) {
          let RemoveDuplicateItems = [...new Set(newDateOrdem)];
          setDataCom([...dataCom, ...RemoveDuplicateItems]);
        }
        if (ordValue.length > 0) {
          newPayDay = ordValue.map((item) => {
            return item.Pagamento;
          });
        }
        if (newPayDay.length > 0) {
          let RemoveDuplicateItemsPayday = [...new Set(newPayDay)];
          setPayDay([...payday, ...RemoveDuplicateItemsPayday]);
        }

        setValues([...values, ...ordValue]);
        setNewLoading(true);
      });
  }

  const handleChange = (value) => {
    const valueIsAlreadyExist = showSelected.findIndex((val) => val === value);

    if (valueIsAlreadyExist === -1) {
      setShowSelected([...showSelected, value]);
    } else {
      const removeValueArray = showSelected.filter((item) => item !== value);

      setShowSelected(removeValueArray);
    }
  };
  function handleBackToTop() {
    window.scroll(0, 0);
  }

  return loading ? (
    <EarningLoader />
  ) : (
    <Container>
      {dataCom.map((data, i) => (
        <React.Fragment key={data}>
          <Box
            sx={
              showSelected.includes(data)
                ? {
                    height: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                  }
                : {
                    height: '40px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                  }
            }
            key={i}
            component="div"
          >
            <HeaderDate onClick={() => handleChange(data)}>
              <h3>{moment(data).format('LL')}</h3>
              <div>
                {showSelected.includes(data) ? (
                  <CaretUp size={24} />
                ) : (
                  <CaretDown size={24} />
                )}
              </div>
            </HeaderDate>
            {showSelected.includes(data) && (
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fit, minmax(20rem, 1fr))',
                  gap: '1rem',
                  width: '100%',
                }}
              >
                {values.length > 0 &&
                  values.map(
                    (item, index) =>
                      data === item.DataCom &&
                      (index === 1 ? (
                        <Grow
                          in={showSelected.includes(data)}
                          key={index}
                          className="cardShowValue"
                        >
                          <div
                            key={index}
                            onClick={() =>
                              history.push(
                                `/acao/${item.Asset.replace('.SA', '')}`
                              )
                            }
                          >
                            <Heading
                              as="h4"
                              variant="quaternary"
                              style={{ cursor: 'pointer' }}
                            >
                              {item.Asset.replace('.SA', '')}
                            </Heading>

                            <p>
                              Tipo: <strong>{item.Tipo}</strong>
                            </p>
                            <p>
                              Data de pagamento:{' '}
                              <strong>
                                {moment(item.Pagamento).format('L')}
                              </strong>
                            </p>
                            <p>
                              Rendimento:{' '}
                              <strong>
                                {(item.Rendimento * 100).toFixed(2)}%
                              </strong>
                            </p>

                            <p>
                              Total:{' '}
                              <strong>
                                {transformMaskCurrency(item.Valor)}
                              </strong>
                            </p>
                          </div>
                        </Grow>
                      ) : (
                        <Grow
                          in={showSelected.includes(data)}
                          style={{ transformOrigin: '0 0 0' }}
                          {...(showSelected ? { timeout: 1000 } : {})}
                          key={index}
                          className="cardShowValue"
                        >
                          <div
                            key={index}
                            onClick={() =>
                              history.push(
                                `/acao/${item.Asset.replace('.SA', '')}`
                              )
                            }
                          >
                            <Heading
                              as="h4"
                              variant="quaternary"
                              style={{ cursor: 'pointer' }}
                            >
                              {item.Asset.replace('.SA', '')}
                            </Heading>

                            <p>
                              Tipo: <strong>{item.Tipo}</strong>
                            </p>
                            <p>
                              Data de pagamento:{' '}
                              <strong>
                                {moment(item.Pagamento).format('L')}
                              </strong>
                            </p>
                            <p>
                              Rendimento:{' '}
                              <strong>
                                {(item.Rendimento * 100).toFixed(2)}%
                              </strong>
                            </p>

                            <p>
                              Total:{' '}
                              <strong>
                                {transformMaskCurrency(item.Valor)}
                              </strong>
                            </p>
                          </div>
                        </Grow>
                      ))
                  )}
              </Box>
            )}
          </Box>
          {!showSelected.includes(data) && <div />}
        </React.Fragment>
      ))}

      {values.length < total && (
        <div>
          <h3>Carregando...</h3>
        </div>
      )}
      {values.length === total && isValidatSrollWindow && (
        <>
          {isMobile && (
            <>
              <br />
            </>
          )}
          <div className="finalList" onClick={handleBackToTop}>
            <h3>Voltar ao topo</h3>
          </div>
        </>
      )}
      {newLoading && values.length < total && (
        <InfinityScroll
          fetchMore={() => handleGetListDividendsActionsInfinity()}
        />
      )}
    </Container>
  );
}

import { useEffect, useState } from 'react';

import ProfileInfo from '../components/ProfileInfo';

import { useTitleAndUser } from '../../hooks/userTitle';

const Settings = () => {
  const { user, setTitle } = useTitleAndUser();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTitle(user.Name.split(' ')[0]);
  }, []);

  return <ProfileInfo loading={loading} profileUser={user} />;
};

export default Settings;

import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactLoading from 'react-loading';

import { PlanMessageStatus } from '../../components/PlanMessageStatus';
import { TableInicialCustody } from '../../components/TableInicialCustody';
import { ScreenToSignIR } from '../ScreenToSignIR';
import { CardFormAndTable, CardInfo, Container } from './styles';
import { IPropsAccumulatedLoss, IPropsInicialCustody } from '../../@types';
import { IRPFRoutes, userRoutes } from '../../../../../utils/RoutesApi';
import { handleToast } from '../../../../../components/Toast';
import { Input } from '../../../../../components/Input';
import { ButtonDesign } from '../../../../../components/ButtonDesign';
import { LinkB3Card } from '../../../../../components/LinkB3Card';
import { useTitleAndUser } from '../../../../../hooks/userTitle';

const baseURL = process.env.REACT_APP_API_URL;

interface IPropsData {
  custodiainicial: IPropsInicialCustody[];
  prejuizoIR: IPropsAccumulatedLoss;
}

const headerTable = [
  {
    label: 'Ativo',
    active: 'tykeySymbol',
  },

  {
    label: 'Tipo de produto',
    active: 'productTypeName',
  },

  {
    label: 'Quantidade',
    active: 'equitiesQuantity',
  },

  {
    label: 'Preço médio',
    active: 'preco_medio',
  },

  {
    label: 'total',
  },
];

export function InicialCustody() {
  //@ts-ignore
  const { permissions, user } = useTitleAndUser();
  const { handleSubmit } = useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [dataInicialCustody, setDataInicialCustody] = useState<IPropsData>({
    custodiainicial: [],
    prejuizoIR: {
      PrejuizoAcumuladoAcao: 0,
      PrejuizoAcumuladoDayTrade: 0,
      PrejuizoAcumuladoFII: 0,
    },
  });

  useEffect(() => {
    getActivesInicialCustody();
  }, []);

  async function getActivesInicialCustody() {
    await axios
      .get(`${baseURL}${IRPFRoutes.INICIAL_CUSTODY}`)
      .then((response) => {
        const newData: IPropsData = {
          ...response.data,
          prejuizoIR: {
            PrejuizoAcumuladoAcao:
              response.data.prejuizoIR.PrejuizoAcumuladoAcao.toString().replace(
                '-',
                ''
              ),
            PrejuizoAcumuladoDayTrade:
              response.data.prejuizoIR.PrejuizoAcumuladoDayTrade.toString().replace(
                '-',
                ''
              ),
            PrejuizoAcumuladoFII:
              response.data.prejuizoIR.PrejuizoAcumuladoFII.toString().replace(
                '-',
                ''
              ),
          },
        };
        setDataInicialCustody(newData);
      })
      .catch((err) => {});
  }

  async function submit(data: any) {
    setLoading(true);

    data.custodiainicial = dataInicialCustody.custodiainicial;
    data.prejuizoinicialAcao =
      dataInicialCustody.prejuizoIR.PrejuizoAcumuladoAcao !== 0
        ? Number(
            unMaskEdit(
              dataInicialCustody.prejuizoIR.PrejuizoAcumuladoAcao.toString()
            )
          )
        : 0.0;
    data.prejuizoinicialDayTrade =
      dataInicialCustody.prejuizoIR.PrejuizoAcumuladoDayTrade !== 0
        ? Number(
            unMaskEdit(
              dataInicialCustody.prejuizoIR.PrejuizoAcumuladoDayTrade.toString()
            )
          )
        : 0.0;
    data.prejuizoinicialFII =
      dataInicialCustody.prejuizoIR.PrejuizoAcumuladoFII !== 0
        ? Number(
            unMaskEdit(
              dataInicialCustody.prejuizoIR.PrejuizoAcumuladoFII.toString()
            )
          )
        : 0.0;

    await axios
      .post(`${baseURL}${IRPFRoutes.UPDATE_CUSTODY}`, data)
      .then((response) => {
        handleToast(false, response.data.message);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  function unMaskEdit(val: string) {
    let newVal = val.replace(/\D/g, '');
    newVal = newVal.replace(/(\d)(\d{2})$/, '$1.$2');
    return newVal;
  }

  function handleChangeValue(
    type: 'action' | 'fii' | 'daytrade',
    value: number
  ) {
    let newValue: number | string = unMaskEdit(value.toString());

    if (type === 'action') {
      setDataInicialCustody({
        ...dataInicialCustody,
        prejuizoIR: {
          ...dataInicialCustody.prejuizoIR,
          PrejuizoAcumuladoAcao: newValue,
        },
      });
    }
    if (type === 'fii') {
      setDataInicialCustody({
        ...dataInicialCustody,
        prejuizoIR: {
          ...dataInicialCustody.prejuizoIR,
          PrejuizoAcumuladoFII: newValue,
        },
      });
    }
    if (type === 'daytrade') {
      setDataInicialCustody({
        ...dataInicialCustody,
        prejuizoIR: {
          ...dataInicialCustody.prejuizoIR,
          PrejuizoAcumuladoDayTrade: newValue,
        },
      });
    }
  }

  function handleEditPM(id: string, value: number) {
    let newValue: number | string = Number(unMaskEdit(value.toString()));

    const findActive = dataInicialCustody.custodiainicial.findIndex(
      (active) => active.tickerSymbol === id
    );
    let newData = dataInicialCustody.custodiainicial;

    newData[findActive].preco_medio = Number(newValue);

    setDataInicialCustody({
      ...dataInicialCustody,
      custodiainicial: newData,
    });
  }

  const isDisabled =
    loading === true && dataInicialCustody.custodiainicial.length < 1;

  return (
    <Container>
      {user.carteira === 'automatico' ? (
        <>
          <CardInfo>
            <p>
              Devido à sincronização da B3 ser apenas a partir de 2020, será
              necessário preencher as informações abaixo para aumentar a
              acuracidade das informações processadas em nosso sistema. Você
              pode encontrar essas informações na sua Declaração de Imposto de
              Renda de 2019/2020.
              <br />
              <br />
              Se não houver o preenchimento dessas informações as posições do
              dia 01/11/2019 serão consideradas como as posições iniciais.
            </p>
          </CardInfo>
          <CardFormAndTable onSubmit={handleSubmit(submit)}>
            <div className="rowContentInput">
              <label htmlFor="prejuizoinicialAcao">
                Prejuizo ação
                <Input
                  mask="currency"
                  type="text"
                  value={dataInicialCustody.prejuizoIR.PrejuizoAcumuladoAcao}
                  onChange={(e: any) =>
                    handleChangeValue('action', e.target.value)
                  }
                />
                <span>R$ -</span>
              </label>
              <label htmlFor="prejuizoinicialFII">
                Prejuizo FII
                <Input
                  mask="currency"
                  type="text"
                  value={dataInicialCustody.prejuizoIR.PrejuizoAcumuladoFII}
                  onChange={(e: any) =>
                    handleChangeValue('fii', e.target.value)
                  }
                />
                <span>R$ -</span>
              </label>
              <label htmlFor="prejuizoinicialDayTrade">
                Prejuizo DayTrade
                <Input
                  mask="currency"
                  type="text"
                  value={
                    dataInicialCustody.prejuizoIR.PrejuizoAcumuladoDayTrade
                  }
                  onChange={(e: any) =>
                    handleChangeValue('daytrade', e.target.value)
                  }
                />
                <span>R$ -</span>
              </label>
            </div>
            <TableInicialCustody
              data={dataInicialCustody.custodiainicial}
              ASC={(newData: IPropsInicialCustody[]) =>
                setDataInicialCustody({
                  ...dataInicialCustody,
                  custodiainicial: newData,
                })
              }
              DESC={(newData: IPropsInicialCustody[]) =>
                setDataInicialCustody({
                  ...dataInicialCustody,
                  custodiainicial: newData,
                })
              }
              header={headerTable}
              onEditPM={(id, value) => handleEditPM(id, value)}
            />
            <div>
              <ButtonDesign
                size="btn-small"
                type="submit"
                disabled={isDisabled}
              >
                {loading ? (
                  <ReactLoading
                    type="balls"
                    color="#FFF"
                    width="20px"
                    height="20px"
                  />
                ) : (
                  'Salvar'
                )}
              </ButtonDesign>
            </div>
          </CardFormAndTable>
        </>
      ) : (
        <div className="contentB3Card">
          <LinkB3Card />
        </div>
      )}
    </Container>
  );
}

import { useEffect, useState } from 'react';

import CarteiraConsolidadaService from '../../services/CarteiraData';
import { FatosContainer, FatoContainer, Flag } from './styles';
import { AiOutlineArrowRight } from 'react-icons/ai';
import moment from 'moment';
import { FatosRelevantesLoader } from '../../jsx/components/LoadingModels';

const ListFatosRelevantes = ({ slug, type }) => {
  const [loading, setLoading] = useState(false);
  const carteiraconsolidadasService = new CarteiraConsolidadaService();
  const formatDate = (dateStr) => moment(dateStr).format('DD/MM/YYYY');

  const [fatos, setFatos] = useState([]);
  useEffect(() => {
    getFatos();
  }, []);
  const getFatos = async () => {
    setLoading(true);
    if (type === 'FII') {
      const response = await carteiraconsolidadasService.getFatosRelevantesFII(
        slug
      );
      setFatos(response.results);
    } else {
      const response = await carteiraconsolidadasService.getFatosRelevantes(
        slug.replace('.SA', '')
      );

      setFatos(response.results);
    }
    setLoading(false);
  };

  return (
    <FatosContainer>
      {loading ? (
        <FatosRelevantesLoader />
      ) : (
        <>
          {fatos.length > 0 &&
            fatos.map((fato, index) => (
              <FatoContainer key={index}>
                <Flag>{fato.Asset}</Flag>
                <p target="_blank" href={fato.Download}>
                  {`${fato.News}`}
                </p>
                <span>Publicado em: {`${formatDate(fato.Data)}`}</span>
                <a target="_blank" href={fato.Download}>
                  Ver comunicado completo
                  <AiOutlineArrowRight
                    style={{ marginBottom: '2px', marginLeft: '5px' }}
                  />
                </a>
              </FatoContainer>
            ))}
          {fatos.length == 0 && (
            <div>
              Nada a exibir <br />
              <span style={{ fontSize: '15px' }}>
                Não há fatos relevantes sobre a empresa.
              </span>
            </div>
          )}
        </>
      )}
    </FatosContainer>
  );
};
export default ListFatosRelevantes;

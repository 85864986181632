import styled from 'styled-components';

export const FormRecoveryPasswordContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 28.125rem;

  p {
    color: #535862;
    /* Body/Small/Regular */
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 2rem;
  }

  .errorMessage {
    color: #f94361;

    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  .successMessage {
    color: #535862;
    /* Body/Base/Regular */
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    strong {
      color: #535862;
      /* Body/Base/Bold */
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
    }

    span {
      color: #757c8a;
      /* Body/Small/Regular */
      font-family: Poppins;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 145%;
    }
  }

  .textHelp {
    text-align: center;
    margin-bottom: 0;
    margin-top: 1rem;
    border: 1px solid ${(props) => props.theme['orange-500']};
    border-radius: 8px;
    padding: 0.5rem;
    height: 2.5rem;
    a {
      color: #ec6643;
      text-align: center;
      /* Highlight/Small/Bold */
      font-family: Poppins;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 1.05rem */
      letter-spacing: 0.01094rem;
      text-transform: uppercase;
    }
  }
  .registerText {
    margin-top: 1.5rem;
    color: #6a717e;

    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    a {
      color: ${(props) => props.theme['orange-500']};
    }
  }

  @media (max-width: 480px) {
    padding: 1rem;
  }
`;

import { SwitchAutoImport } from '../../../components/SwitchAutoImport';
import { useTitleAndUser } from '../../../hooks/userTitle';
import { Container, IsLinkAccountB3, IsNotLinkAccountB3 } from './styles';
import { LinkB3Card } from '../../../components/LinkB3Card';

export function LinkB3Page() {
  //@ts-ignore
  const { user } = useTitleAndUser();
  return (
    <Container>
      {user.carteira !== 'automatico' ? (
        <LinkB3Card />
      ) : (
        <IsLinkAccountB3>
          <header>
            <h1>Você já está conectado!</h1>
          </header>
          <main>
            <p>
              Parabéns, você já está conectado com a b3, basta mudar para a
              carteira automática para ter acesso aos dados sobre sua renda.
            </p>
            <strong>
              <SwitchAutoImport />
            </strong>
          </main>
        </IsLinkAccountB3>
      )}
    </Container>
  );
}

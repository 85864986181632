import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IPropsCampaignFacebook } from '../..';
import { transformMaskCurrency } from '../../../../../../utils/transformMaskCurrency';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#303030',
    color: 'white',
    fontFamily: 'Poppins, sans-serif',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: 'Poppins, sans-serif',
  },
}));

const StyledBody = styled(TableBody)(
  ({ theme }) => `
  overflow-y: auto;
  max-height:500px;

`
);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface IPropsTableCampaign {
  header: string[];
  body: IPropsCampaignFacebook[];
}

export function TableCampaign({ body, header }: IPropsTableCampaign) {
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 700, maxHeight: 500 }}
        aria-label="customized table"
      >
        <TableHead>
          <TableRow>
            {header.map((item) => (
              <StyledTableCell key={item}>{item}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <StyledBody>
          {body?.map((item) => (
            <StyledTableRow key={item.Campanha}>
              <StyledTableCell>{item.Campanha}</StyledTableCell>
              <StyledTableCell>
                {transformMaskCurrency(item.Gasto)}
              </StyledTableCell>
              <StyledTableCell>{item.Impressoes}</StyledTableCell>
              <StyledTableCell>{item.Clicks}</StyledTableCell>
              <StyledTableCell>
                {transformMaskCurrency(item.CPM)}
              </StyledTableCell>
              <StyledTableCell>{item.CTR.toFixed(2)}%</StyledTableCell>
              <StyledTableCell>
                {transformMaskCurrency(item.CPC)}
              </StyledTableCell>
              <StyledTableCell>
                {transformMaskCurrency(item.CPA)}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </StyledBody>
      </Table>
    </TableContainer>
  );
}

import { Link } from 'react-router-dom';
import { TrialMessageContainer } from './styles';

export function TrialMessage() {
  return (
    <TrialMessageContainer>
      <p>
        Para assinar um plano do imposto de renda você precisa ter a assinatura
        anual da plataforma Ibox. <br />
        <Link to="pagamentos">Você pode assinar clicando aqui!</Link>
      </p>
    </TrialMessageContainer>
  );
}

import React, { createContext, useState } from 'react';
import { themes } from '../utils/theme';

export const NewThemeContext = createContext({});

interface IPropsNewThemeProvider {
  children: React.ReactNode;
}

export const NewThemeContextProvider = ({
  children,
}: IPropsNewThemeProvider) => {
  let getTheme = localStorage.getItem('useTheme');
  const [shortSidebar, setShortSidebar] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [typeTheme, setTypeTheme] = useState<'light' | 'dark'>(
    //@ts-ignore
    getTheme ?? 'light'
  );

  const theme = themes[typeTheme];

  return (
    <NewThemeContext.Provider
      value={{
        shortSidebar,
        setShortSidebar,
        isMobile,
        setIsMobile,
        theme,

        typeTheme,
        setTypeTheme,
      }}
    >
      {children}
    </NewThemeContext.Provider>
  );
};

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import { Link } from 'react-router-dom';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import moment from 'moment';
import { IPropsIncomeTax } from '../../@types';
import { transformMaskCurrency } from '../../../../../utils/transformMaskCurrency';
import { useActives } from '../../../../../hooks/useActives';
import { filterTableSorte } from '../../../../../utils/filterTableSort';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontFamily: 'Poppins, sans-serif',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: 'Poppins, sans-serif',
  },
}));

const StyledBody = styled(TableBody)(
  ({ theme }) => `
  overflow-y: auto;
  max-height:500px;

`
);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface IPropsHeaderTable {
  label: string;
  active?: string;
}

interface IPropsTableIncomeTax {
  data: IPropsIncomeTax[];
  header: IPropsHeaderTable[];
  ASC: Function;
  DESC: Function;
  yearFilter: string;
  handleGenerationDarf: (value: IPropsIncomeTax) => void;
}

export function TableIncomeTax({
  data,
  header,
  ASC,
  DESC,
  yearFilter,
  handleGenerationDarf,
}: IPropsTableIncomeTax) {
  //@ts-ignore
  const { showActive } = useActives();
  function formatValueForCurrency(value: number): string {
    return transformMaskCurrency(value);
  }

  return (
    <TableContainer
      component={Paper}
      style={{ maxHeight: '500px', whiteSpace: 'nowrap' }}
    >
      <Table sx={{ minWidth: 700 }} aria-label="customized table" stickyHeader>
        <TableHead>
          <TableRow>
            {header.map((item) => (
              <StyledTableCell key={item.active}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    fontSize: '0.95rem',
                    width: '100%',
                  }}
                >
                  {item.label}
                  {item.active && (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <CaretUp
                        size={14}
                        color="black"
                        onClick={() => {
                          return ASC(
                            filterTableSorte({
                              data: data,
                              dir: 'asc',
                              //@ts-ignore
                              key: item.active,
                            })
                          );
                        }}
                      />
                      <CaretDown
                        size={14}
                        color="black"
                        onClick={() => {
                          return DESC(
                            filterTableSorte({
                              data: data,
                              dir: 'desc',
                              //@ts-ignore
                              key: item.active,
                            })
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>

        <StyledBody>
          {data
            ?.map((item, index) => {
              const validationDate = moment(item.ReferenceDate)
                .format('l')
                .split('/')[2];
              if (
                validationDate === yearFilter ||
                item.ReferenceDate.toString() === yearFilter
              ) {
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell>
                      {moment(item.ReferenceDate).format('MMMM  YYYY')}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        color: item.Revenue < 0 ? 'red' : 'black',
                      }}
                    >
                      {showActive
                        ? formatValueForCurrency(item.Revenue)
                        : 'R$ *******'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {showActive
                        ? formatValueForCurrency(item.GrossAmount)
                        : 'R$ ******'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {showActive
                        ? formatValueForCurrency(item.Hold)
                        : 'R$ ******'}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        color: item.PrejuizoAcumulado < 0 ? 'red' : 'black',
                      }}
                    >
                      {showActive
                        ? formatValueForCurrency(item.PrejuizoAcumulado)
                        : 'R$ ******'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {showActive
                        ? formatValueForCurrency(item.IR)
                        : 'R$ ******'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {showActive
                        ? formatValueForCurrency(item.IRCalculado)
                        : 'R$ ******'}
                    </StyledTableCell>
                    <StyledTableCell
                      onClick={() =>
                        item.IRCalculado > 0 && handleGenerationDarf(item)
                      }
                      className="linkDarf"
                    >
                      {item.IRCalculado > 0 ? 'Darf' : '-'}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              }
              return <></>;
            })
            .reverse()}
        </StyledBody>
      </Table>
      {data.length < 1 && (
        <h1 style={{ fontSize: '1rem', margin: '1rem 0.5rem' }}>
          Ainda não há dados ou base em processamento
        </h1>
      )}
    </TableContainer>
  );
}

import styled from 'styled-components';

export const ContentVideo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  box-shadow: 1px 1px 6px 0px #0000001a;
  border-radius: 8px;
  border: 1px solid var(--Gray-gray-100, #D4D6DB);
  background: var(--Main-white, #FFF);
  width: 240px;
  height: 275px;
  object-fit: cover;
  overflow: hidden;
  top: 10px;

  span {
    max-width: 15ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const ThumbContent = styled.div`
  display: flex;

  width: 100%;
  width: 240px;
  height: 139.98px;
  img {
    object-fit: cover;
    border-radius: 4px 4px 0 0;

    width: 100%;
    height: 100%;
  }
`;
export const ContentChannelInfos = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  width: 100%;

  h4 {
    font-size: 0.95rem;
    color: #6A717E;
    font-weight: 400;
    max-width: 20ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  time {
    font-weight: 400;
    font-size: 0.75rem;
    color: ${(props) => props.theme['gray-300']};
  }

  svg {
    width: 1rem;
    height: 1rem;
  }

  a {
    font-size: 1rem;
    color: ${(props) => props.theme.primary};
    margin-top: 1.5rem;
    background: ${(props) => '#38486B'};
    color: ${(props) => props.theme.white};
    border-radius: 38px;
    padding: 0.75rem;
    text-align: center;
  }

  button {
    all: unset;
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
    margin-top: 1.5rem;
    background: #EC6643;
    color: ${(props) => props.theme.white};
    border-radius: 38px;
    padding: 0.75rem;
    text-align: center;
  }
`;

import axios from 'axios';
import { EditPlanContainer } from './styles';
import { baseURL } from '../../../../../utils/baseURL';
import { userRoutes } from '../../../../../utils/RoutesApi';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IPropsPlan } from '../CreateNewPlan/components/CardPreviewPlan';
import { transformMaskCurrency } from '../../../../../utils/transformMaskCurrency';
import { PencilLine } from '@phosphor-icons/react';
import { ModalDesigner } from '../../../../../components/ModalDesigner';
import { CreateNewPlan } from '../CreateNewPlan';
import { handleToast } from '../../../../../components/Toast';
import { Switch } from 'antd';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontFamily: 'Poppins, sans-serif',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: 'Poppins, sans-serif',
  },
}));

const StyledBody = styled(TableBody)(
  ({ theme }) => `
  overflow-y: auto;
  max-height:500px;

`
);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const header = [
  '#',
  'Titulo/nome',
  'Tipo',
  'Valor',
  'Funcionalidades',
  'Editar',
  // 'Ativar/Desativar',
];

export function EditPlan() {
  const [plans, setPlans] = useState<IPropsPlan[]>([]);
  const [edit, setEdit] = useState<{ plan: IPropsPlan; isEdit: boolean }>({
    isEdit: false,
    // @ts-ignore
    plan: {},
  });
  useEffect(() => {
    listAllPlans();
  }, []);

  async function listAllPlans() {
    const response = await axios.get(`${baseURL}${userRoutes.PLAN_ROUTER_V3}`);
    setPlans(response.data);
  }

  function updateStatePlan(plan: IPropsPlan) {
    setEdit({
      isEdit: false,
      // @ts-ignore
      plan: {},
    });

    const findIndexPlan = plans.findIndex((p) => p.id === plan.id);
    if (findIndexPlan >= 0) {
      let newPlansList = plans.map((p, i) => {
        if (i === findIndexPlan) {
          return plan;
        }
        return p;
      });

      setPlans(newPlansList);
    }
  }

  async function handleEditPlanIsActive(is_active: boolean, planId: string) {
    let data = {
      is_active,
      id: planId,
    };
    try {
      const response = await axios.put(
        `${baseURL}/api/planosV3/${planId}/`,
        data
      );
      handleToast(false, 'Plano atualizado com sucesso');
      const newPlans = plans.map((p) => {
        if (p.id === planId) {
          return response.data;
        }
        return p;
      });
      setPlans(newPlans);
    } catch (err) {
      handleToast(true, 'Erro ao cadastrar plano');
    }
  }

  return (
    <EditPlanContainer>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              {header.map((item) => (
                <StyledTableCell key={item}>{item}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <StyledBody>
            {plans?.map((plan) => (
              <StyledTableRow key={plan.id}>
                <StyledTableCell>{plan.id}</StyledTableCell>
                <StyledTableCell>iBoxer {plan.title}</StyledTableCell>
                <StyledTableCell>{plan.type}</StyledTableCell>
                <StyledTableCell>
                  {transformMaskCurrency(plan.value)}
                </StyledTableCell>
                <StyledTableCell>
                  {plan.funcionalities.map((f) => (
                    <span key={f.label}>{f.label}, </span>
                  ))}
                </StyledTableCell>
                <StyledTableCell>
                  <button
                    className="editButton"
                    onClick={() =>
                      setEdit({
                        plan,
                        isEdit: true,
                      })
                    }
                  >
                    <PencilLine />
                  </button>
                </StyledTableCell>
                <StyledTableCell>
                  <Switch
                    checked={plan.is_active}
                    onClick={() =>
                      handleEditPlanIsActive(!plan.is_active, plan.id)
                    }
                    size="small"
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </StyledBody>
        </Table>
      </TableContainer>
      <ModalDesigner
        id="editarPlano"
        isOpen={edit.isEdit}
        // @ts-ignore
        setState={() => setEdit({ isEdit: false, plan: {} })}
        title="Editar plano"
        size="xl"
      >
        <CreateNewPlan
          editPlan={edit}
          onUpdate={(plan: IPropsPlan) => updateStatePlan(plan)}
        />
      </ModalDesigner>
    </EditPlanContainer>
  );
}

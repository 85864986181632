import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { ButtonComponent } from '../../../../../../../../components/ButtonComponent';
import { handleToast } from '../../../../../../../../components/Toast';
import { userRoutes } from '../../../../../../../../utils/RoutesApi';
import { FormatCurrencyStyle } from '../../../../../../FormatCurrencyStyle';
import { IPropsPlanIR } from '../@types';
import { Container } from './styles';
import { ModalDesigner } from '../../../../../../../../components/ModalDesigner';
import { whatsAppLinkApi } from '../../../../../../../../utils/apiWhatsapp';

export function CardPlanIR() {
  const [modalCancelPlanConfirmation, setModalCancelPlanConfirmation] =
    useState<boolean>(false);
  //@ts-ignore
  const [plan, setPlan] = useState<IPropsPlanIR>({});
  useEffect(() => {
    handleGetPlan();
  }, []);


  async function handleGetPlan() {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}${userRoutes.USER_PLAN_IR_SELECTED}`
      )
      .then((response) => {
        setPlan(response.data);
      })
      .catch((err) => {});
  }

  async function handleCancelPlanIR() {
    await axios
      .get(`${process.env.REACT_APP_API_URL}${userRoutes.USER_CANCEL_PLAN_IR}`)
      .then((response) => {
        handleToast(false, response.data.message);
        setModalCancelPlanConfirmation(false);
      })
      .catch((err) => {});
  }

  function renderStatusPlan() {
    if (plan.Status === 'paid' || plan.Status === 'pago') {
      return 'Pago';
    } else if (plan.Status === 'unpaid') {
      return 'Pendente';
    }
    return 'Cancelado';
  }
  function renderPaymentMethod() {
    if (plan.paymentMethod === 'boleto') {
      return 'Boleto';
    } else if (plan.paymentMethod === 'Fundador') {
      return 'Fundador';
    }
    return 'Cartão';
  }
  function redirectPaymentAPI(link: string) {
    window.open(link);
  }
  return plan.Status ? (
    <Container>
      <header>
        <h1>Configurações de assinatura</h1>
        <h6>Imposto de renda</h6>
      </header>
      <main>
        <header>
          <h3>Anual</h3>
          <span
            style={
              plan.Status === 'paid' || plan.Status === 'pago'
                ? { color: 'green', fontSize: '0.875rem', lineHeight: '1.5' }
                : { color: 'red', fontSize: '0.875rem', lineHeight: '1.5' }
            }
          >
            {renderStatusPlan()}
          </span>
        </header>
        <span>
          Valor do plano: <strong>{FormatCurrencyStyle(plan.Valor)}</strong>
        </span>
        <span>
          Tipo de pagamento: <strong>{renderPaymentMethod()}</strong>
        </span>
        {plan.currentPeriodStart && (
          <span>
            Ínicio:{' '}
            <strong>{moment(plan.currentPeriodStart).format('l')}</strong>
          </span>
        )}
        {plan.currentPeriodEnd && (
          <span>
            Término:{' '}
            <strong>{moment(plan.currentPeriodEnd).format('l')}</strong>
          </span>
        )}
      </main>
      <footer>
        <ButtonComponent
          onClick={() => redirectPaymentAPI(plan.manageUrl)}
          disabled={plan.paymentMethod === 'Fundador'}
        >
          Gestão de pagamento
        </ButtonComponent>
        <ButtonComponent
          variant="link"
          onClick={() => setModalCancelPlanConfirmation(true)}
          disabled={plan.paymentMethod === 'Fundador'}
        >
          Cancelar plano
        </ButtonComponent>
        <p>
          Qualquer situação de problemas de pagamento e/ou mudança de plano,
          entrar em contato com <a href={whatsAppLinkApi}>nosso suporte</a>.
        </p>
      </footer>

      <ModalDesigner
        isOpen={modalCancelPlanConfirmation}
        setState={setModalCancelPlanConfirmation}
        id="cancelar"
        size="sm"
        title="Realmente deseja cancelar o plano?"
      >
        <div>
          <p style={{ fontSize: '1rem', color: 'black' }}>
            <strong style={{ fontSize: '1rem', color: 'black' }}>
              Ahh... tem certeza?
            </strong>
            <br />
            <br />
            Estou ciente de que o cancelamento da assinatura do IBOX6 e que após
            este procedimento meu acesso estará ativo somente até o final do
            prazo de vigência do seu contrato e que para um acesso futuro
            deverei assinar novamente um plano.
          </p>
          <footer
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '1rem',
              flexWrap: 'wrap',
            }}
          >
            <ButtonComponent onClick={handleCancelPlanIR} variant="link">
              Sim
            </ButtonComponent>
            <ButtonComponent
              onClick={() => setModalCancelPlanConfirmation(false)}
            >
              Não
            </ButtonComponent>
          </footer>
        </div>
      </ModalDesigner>
    </Container>
  ) : (
    <></>
  );
}

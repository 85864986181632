import ReactApexChart from 'react-apexcharts';
import { IPropsRankActive } from '../../@types/listActiveType';
import {
  CardActiveRankMobileContainer,
  TextContent,
} from './CardActiveRankMobileStyles';
import { Tooltip } from 'antd';
import { cores } from '../../../../../utils/coresGraficos';
import { ApexOptions } from 'apexcharts';

interface IPropsCardActiveRank {
  active: IPropsRankActive;
  selectedActive: ({
    slug,
    type,
  }: {
    slug: string;
    type: '' | 'action' | 'fii';
  }) => void;
}

export function CardActiveRankMobile({
  active,
  selectedActive,
}: IPropsCardActiveRank) {
  const values = [
    active.LucroLiquido as number,
    active.EBITDA,
    active.Dividendos,
    active.DividaLiquida,
    active.DividaLiquidaEbitida,
    active.Resiliencia,
    active.Governanca,
    active.Indices,
  ];
  const labels = [
    'Lucro Líquido',
    'EBITDA',
    'Dividendos',
    'Dívida Líquida',
    'Dívida Líquida EBITDA',
    'Resiliência',
    'Governança',
    'Índices',
    '',
  ];
  const total = values.reduce((sum, value) => sum + (value || 0), 0);
  const restante = (total - 100 * values.length) * -1;
  const compare: {
    options: ApexOptions;
    series: ApexAxisChartSeries;
  } = {
    series: [
      active.LucroLiquido as any,
      active.EBITDA,
      active.Dividendos,
      active.DividaLiquida,
      active.DividaLiquidaEbitida,
      active.Resiliencia,
      active.Governanca,
      active.Indices,
      restante,
    ],
    options: {
      chart: {
        height: 150,
        type: 'donut',
      },
      stroke: { show: false },
      grid: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },
      legend: {
        show: false,
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          donut: {
            size: '65%',
            labels: {
              show: false,
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        onDatasetHover: {
          highlightDataSeries: false,
        },
        y: {
          formatter: (val: any) => {
            if (val > 100) return '';
            return `${val.toFixed(0)}%`;
          },
        },
      },
      labels,
      colors: [
        '#38486B',
        '#635185',
        '#99538E',
        '#CD5485',
        '#F45F6A',
        '#FF7D45',
        '#FFA600',
        '#52C41A ',
        '#E4E5E7',
      ],
    },
  };

  const mainAsset = active.Assets.split('|')
    .sort(
      (a: string, b: string) =>
        Number(a.replace(/[a-zA-Z]/g, '')) - Number(b.replace(/[a-zA-Z]/g, ''))
    )[0]
    .replace('.SA', '');
  return (
    <CardActiveRankMobileContainer
      onClick={() =>
        selectedActive({
          slug: mainAsset,
          type: 'action',
        })
      }
    >
      <TextContent>
        <h3>{mainAsset}</h3>
        <span title={active.NomeEmpresa}>{active.NomeEmpresa}</span>
      </TextContent>
      <div className="contentGraphicValue">
        <ReactApexChart
          //@ts-ignore
          options={compare.options}
          series={compare.series}
          type="donut"
          width={90}
        />
        <Tooltip title="Essa é a nota da empresa com base na média das nossas avaliações">
          <h6>{active.total_chart.toFixed(0)}%</h6>
        </Tooltip>
      </div>
    </CardActiveRankMobileContainer>
  );
}

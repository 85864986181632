import Home from './components/Dashboard/Home';
import { MinhaCarteira } from './components/Boltz/MyWallet/MinhaCarteira';
import Educacao from '../videos/Home';
import UploadVideos from '../videos/Upload';
import Channel from '../videos/Channel';
import MyChannel from '../videos/MyChannel';
import watch from '../videos/Watch';
import PostDetails from './components/AppsMenu/AppProfile/PostDetails';
import Login from './pages/Login';
import Settings from '../social/pages/Settings';
import PostLikes from '../social/pages/PostLikes/PostLikes';
import RecommendedPosts from '../social/pages/RecommendedPosts/RecommendedPosts';
import Register from './pages/Registration';
import { ActionCalender } from '../social/pages/ActionCalender';
import { FiiCalender } from '../social/pages/FiiCalender';
import { NewPassword } from './pages/NewPassword';
import { Calculators } from './components/Calculadora';
import { Pagamentos } from './components/Dashboard/Settings/Pagamentos';
import { EditProfile } from './components/Dashboard/Settings/Profile';
import { WalletAction } from './components/Boltz/MyWallet/Action';
import { WalletFii } from './components/Boltz/MyWallet/FII';
import { EarningsToReceive } from '../social/pages/Proventos';
import { UserPosts } from '../social/pages/UserPosts';
import { ViewPublication } from '../social/pages/ViewPublication';
import { Mattress } from './components/Boltz/MyWallet/Mattress';
import { CompareAssets } from './components/Dashboard/CompareAssets';
import { Transactions } from './components/Boltz/MyWallet/Transactions';
import { EditFiiAdmin } from './components/EditFiiAdmin';
import { SpecialThanksRegister } from './pages/SpecialThanksRegister';
import { Feedback } from './pages/admin-control/Feedback';
import { UserPainel } from './pages/admin-control/User';
import { Business } from './pages/Business';
import { DashboardAdmin } from './pages/admin-control/DashboardAdmin';
import { Simulators } from './pages/Simulators';
import { Checkout } from './pages/Sales/Checkout';
import { AdminPlans } from './pages/admin-control/AdminPlans';
import { FinishedPlan } from './components/Dashboard/Settings/Pagamentos/components/SignPlan/components/FormPlanStep/FinishedPlan';
import { Analysis } from './components/Dashboard/Analysis';
import { Wallet } from './pages/Wallet';
import { IncomeTaxPages } from './pages/IncomeTax';
import { Tutoriais } from './pages/Tutoriais';
import { RendaPassiva } from './pages/RendaPassiva';
import { EditIndices } from './pages/admin-control/EditIndices';

export const routes = [
  //admin pages
  { url: 'admin-painel/feedback', component: Feedback, roles: ['Admin'] },
  { url: 'admin-painel/user', component: UserPainel, roles: ['Admin'] },
  { url: 'admin-painel/indices', component: EditIndices, roles: ['Admin'] },
  { url: 'admin-plan', component: AdminPlans, roles: ['Admin'] },
  {
    url: 'checkout',
    component: Checkout,
    roles: ['General', 'Admin', 'Grátis'],
  },
  {
    url: 'finish-checkout',
    component: FinishedPlan,
    roles: ['General', 'Admin', 'Grátis'],
  },

  {
    url: 'admin-painel/dashboard',
    component: DashboardAdmin,
    roles: ['Admin'],
  },

  { url: '', component: Home, roles: ['General', 'Admin', 'Grátis'] },
  {
    url: 'dashboard?redirect=true',
    component: Home,
    roles: ['General', 'Admin', 'Grátis'],
  },
  { url: 'dashboard', component: Home, roles: ['General', 'Admin', 'Grátis'] },
  {
    url: 'dashboard/led',
    component: Home,
    roles: ['General', 'Admin', 'Grátis'],
  },
  {
    url: 'tutoriais',
    component: Tutoriais,
    roles: ['General', 'Admin', 'Grátis'],
  },
  {
    url: 'simuladores',
    component: Simulators,
    roles: ['General', 'Admin', 'Grátis'],
  },

  {
    url: 'cadastro-concluido',
    component: SpecialThanksRegister,
    roles: ['General', 'Admin', 'Grátis'],
  },
  { url: 'my-wallet', component: MinhaCarteira, roles: ['General', 'Admin'] },
  { url: 'wallet', component: Wallet, roles: ['General', 'Admin'] },
  { url: 'mattress', component: Mattress, roles: ['General', 'Admin'] },
  {
    url: 'carteira-acao',
    component: WalletAction,
    roles: ['General', 'Admin'],
  },
  { url: 'carteira-fii', component: WalletFii, roles: ['General', 'Admin'] },
  { url: 'Educacao', component: Educacao, roles: ['General', 'Admin'] },
  { url: 'uploads', component: UploadVideos, roles: ['Admin'] },
  { url: 'Channel', component: Channel, roles: ['Admin'] },
  { url: 'MyChannel', component: MyChannel, roles: ['Admin'] },
  { url: 'watch', component: watch, roles: ['General', 'Admin'] },
  { url: 'fii/edit/:id', component: EditFiiAdmin, roles: ['Admin'] },
  {
    url: 'analysis',
    component: Analysis,
    roles: ['General', 'Admin', 'Grátis'],
  },
  {
    url: 'compare-assets',
    component: CompareAssets,
    roles: ['General', 'Admin', 'Grátis'],
  },

  {
    url: 'post/:postId/likes/',
    component: PostLikes,
    roles: ['General', 'Admin'],
  },
  { url: 'app-profile', component: Settings, roles: ['General', 'Admin'] },
  {
    url: 'recommended-posts',
    component: RecommendedPosts,
    roles: ['General', 'Admin', 'Grátis'],
  },
  {
    url: 'user-post/:id',
    component: ViewPublication,
    roles: ['General', 'Admin'],
  },
  {
    url: 'user-posts/:slug',
    component: UserPosts,
    roles: ['General', 'Admin'],
  },

  { url: 'post-details', component: PostDetails, roles: ['General', 'Admin'] },
  {
    url: 'calculadora',
    component: Calculators,
    roles: ['General', 'Admin', 'Grátis'],
  },


  {
    url: 'imposto-renda',
    component: IncomeTaxPages,
    roles: ['IRPF', 'Admin'],
  },

  {
    url: 'transactions/:slug',
    component: Transactions,
    roles: ['General', 'Admin'],
  },
  {
    url: 'renda-passiva',
    component: RendaPassiva,
    roles: ['General', 'Admin', 'Grátis'],
  },
  {
    url: 'proventos',
    component: EarningsToReceive,
    roles: ['General', 'Admin', 'Grátis'],
  },
  {
    url: 'calendario-dividendos',
    component: ActionCalender,
    roles: ['General', 'Admin', 'Grátis'],
  },
  {
    url: 'renda',
    component: FiiCalender,
    roles: ['General', 'Admin', 'Grátis'],
  },
  //Settings
  { url: 'pagamentos', component: Pagamentos },
  {
    url: 'profile-edit',
    component: EditProfile,
    roles: ['General', 'Admin', 'IRPF', 'Grátis'],
  },

  /// pages
  { url: 'page-register', component: Register },
  { url: 'page-recover-password', component: NewPassword },
  { url: 'page-login', component: Login },

  { url: 'v', component: Business },
];

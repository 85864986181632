import {
  ChatCircleDots,
  Play,
  ThumbsUp,
  User,
  UserPlus,
} from '@phosphor-icons/react';
import { useHistory } from 'react-router-dom';
import { IPropsNotification } from '../../jsx/layouts/nav/Header/@types/notifications';

import { Container, ContentBodyNotification, ImgContainer } from './styles';

interface IPropsNotificationComponent {
  notification: IPropsNotification;
}

export function Notification({ notification }: IPropsNotificationComponent) {
  const history = useHistory();

  function handlePushUser() {
    if (notification.type === 5) {
      history.push(notification.link);
    } else if (notification.type === 4) {
      history.push(`/user-posts/${notification.from_user.slug}`);
    } else {
      history.push(`/user-post/${notification.post.id}`);
    }
  }

  return (
    <Container onClick={handlePushUser}>
      <ImgContainer>
        {notification.from_user.profile.image ? (
          <img src={notification.from_user.profile.image} />
        ) : (
          <User size={32} color="black" />
        )}
        <div className="iconContent">
          {notification.type === 2 ? (
            <ThumbsUp size={16} color="#fff" />
          ) : notification.type === 3 ? (
            <ChatCircleDots size={16} color="#fff" />
          ) : notification.type === 4 ? (
            <UserPlus size={16} color="#fff" />
          ) : notification.type === 5 ? (
            <Play color="white" size={16} />
          ) : (
            <></>
          )}
        </div>
      </ImgContainer>
      <ContentBodyNotification>
        {notification.type === 1 ? null : notification.type === 2 ? (
          <>
            <strong>{notification.from_user.Name.split(' ')[0]}</strong> curtiu
            sua pulicação:{' '}
            {notification.post.body.length > 50
              ? `${notification.post.body.substring(0, 50)}...`
              : `"${notification.post.body}"`}
          </>
        ) : notification.type === 3 ? (
          <>
            <strong>{notification.from_user.Name.split(' ')[0]}</strong>{' '}
            respondeu sua pulicação:{' '}
            {notification.post.body.length > 50
              ? `"${notification.post.body.substring(0, 50)}..."`
              : `"${notification.post.body}"`}
          </>
        ) : notification.type === 5 ? (
          <>
            <strong>{notification.from_user.Name.split(' ')[0]}</strong>{' '}
            {notification.message.split('comentou')[1].length > 50
              ? `comentou ${notification.message
                  .split('comentou')[1]
                  .substring(0, 50)}...`
              : `comentou ${notification.message.split('comentou')[1]}`}
          </>
        ) : (
          <>
            <strong>{notification.from_user.Name.split(' ')[0]}</strong> agora
            está te seguindo
          </>
        )}
      </ContentBodyNotification>
    </Container>
  );
}

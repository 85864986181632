import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { CardComponent } from '../../components/CardComponent';
import {
  Container,
  ContentCardAssetsRigths,
  ContentTab,
  RowContent,
} from './styles';
import { transformMaskCurrency } from '../../../../../utils/transformMaskCurrency';
import { financeRoutes } from '../../../../../utils/RoutesApi';
import { IPropsIncomeAnnual } from '../../@types';
import { IncomeTaxLoader } from '../../../../components/LoadingModels';
import { TabFilter } from '../../../../../components/TabFilter';
import { Heading } from '../../../../../components/DefaultStylesTypography';

interface IPropsConsolidado {
  action: string;
  fii: string;
  total: string;
}
interface IPropsConsolidatedEarnings {
  TotalDividendos: string;
  TotalRendimento: string;
  TotalJCP: string;
  TotalConsolidado: string;
}

const baseURL = process.env.REACT_APP_API_URL;

export function Dashboard() {
  const [loading, setLoading] = useState(false);
  function formatValueForCurrency(value: number): string {
    return transformMaskCurrency(value);
  }

  const [selectedFilterYear, setSelectedFilterYear] = useState(
    moment().subtract(1, 'year').format('YYYY')
  );
  const [totalConsolidado, setTotalConsolidado] = useState<IPropsConsolidado>({
    action: formatValueForCurrency(0),
    fii: formatValueForCurrency(0),
    total: formatValueForCurrency(0),
  });
  const [totalConsolidatedEarnings, setTotalConsolidatedEarnings] =
    useState<IPropsConsolidatedEarnings>({
      TotalDividendos: formatValueForCurrency(0),
      TotalJCP: formatValueForCurrency(0),
      TotalRendimento: formatValueForCurrency(0),
      TotalConsolidado: formatValueForCurrency(0),
    });

  useEffect(() => {
    getAssetsAndRights({ ano: selectedFilterYear });
    getConsolidatedEarnings({ ano: selectedFilterYear });
  }, [selectedFilterYear]);

  async function getAssetsAndRights(params: { ano: string }) {
    setLoading(true);
    await axios
      .get(`${baseURL}${financeRoutes.FINANCE_ASSETS_AND_RIGHTS}`, {
        params,
      })
      .then((response) => {
        let newData: IPropsIncomeAnnual[] = response.data.BenseDireitos;
        //////////Fiis/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        const filterFii = newData.filter(
          (item: IPropsIncomeAnnual) => item.Tipo === 'FII'
        );
        const totalFii = filterFii.reduce((acc, item) => {
          return acc + item.ValorTotalAtual;
        }, 0);

        //////////Ações/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        const filterAction = newData.filter(
          (item: IPropsIncomeAnnual) => item.Tipo === 'Ações'
        );
        const totalAction = filterAction.reduce((acc, item) => {
          return acc + item.ValorTotalAtual;
        }, 0);
        setTotalConsolidado({
          action: formatValueForCurrency(totalAction),
          fii: formatValueForCurrency(totalFii),
          total: formatValueForCurrency(totalAction + totalFii),
        });
      })
      .catch((err) => {});
    setLoading(false);
  }

  async function getConsolidatedEarnings(params: { ano: string }) {
    await axios
      .get(`${baseURL}${financeRoutes.FINANCE_INCOME_TAX_CONSOLIDATED}`, {
        params,
      })
      .then((response) => {
        setTotalConsolidatedEarnings({
          TotalDividendos: formatValueForCurrency(
            response.data.TotalDividendos
          ),
          TotalJCP: formatValueForCurrency(response.data.TotalJCP),
          TotalRendimento: formatValueForCurrency(
            response.data.TotalRendimento
          ),
          TotalConsolidado: formatValueForCurrency(
            response.data.TotalRendimento +
              response.data.TotalDividendos +
              response.data.TotalJCP
          ),
        });
      })
      .catch((err) => {});
  }

  return (
    <Container>
      {loading ? (
        <IncomeTaxLoader />
      ) : (
        <>
          <TabFilter>
            <ContentTab>
              <strong>Ano: </strong>
              <select
                value={selectedFilterYear}
                onChange={(e) => setSelectedFilterYear(e.target.value)}
              >
                {/* <option value={moment().format('YYYY')}>
                  {moment().format('YYYY')}
                </option> */}
                <option value={moment().subtract(1, 'year').format('YYYY')}>
                  {moment().subtract(1, 'year').format('YYYY')}
                </option>
              </select>
            </ContentTab>
          </TabFilter>

          <RowContent>
            <CardComponent>
              <header>
                <Heading as="h4" variant="quaternary" style={{color:'#303F4B',}}>
                  Bens e direitos - {selectedFilterYear}
                </Heading>
                <div>
                  <span>Total</span>
                  <strong>{totalConsolidado.total}</strong>
                </div>
              </header>
              <ContentCardAssetsRigths>
                <div>
                  <span>Ações</span>
                  <strong>{totalConsolidado.action}</strong>
                </div>
                <div>
                  <span>FIIs</span>
                  <strong>{totalConsolidado.fii}</strong>
                </div>
              </ContentCardAssetsRigths>
            </CardComponent>
            <CardComponent>
              <header>
                <Heading as="h4" variant="quaternary" style={{color:'#303F4B',}}>
                  Proventos recebidos - {selectedFilterYear}
                </Heading>
                <div>
                  <span>Total</span>
                  <strong>{totalConsolidatedEarnings.TotalConsolidado}</strong>
                </div>
              </header>
              <ContentCardAssetsRigths>
                <div>
                  <span>Dividendos</span>
                  <strong>{totalConsolidatedEarnings.TotalDividendos}</strong>
                </div>
                <div>
                  <span>Juros sobre Cap. Pró. (JCP)</span>
                  <strong>{totalConsolidatedEarnings.TotalJCP}</strong>
                </div>
                <div>
                  <span>Rendimentos</span>
                  <strong>{totalConsolidatedEarnings.TotalRendimento}</strong>
                </div>
              </ContentCardAssetsRigths>
            </CardComponent>
          </RowContent>
          <CardComponent>
            <div className="warning">
              <header>
                <Heading as="h1">Prezados assinantes!</Heading>
              </header>
              <p>
                É importante esclarecer que o iBox6 realiza a apuração do
                Imposto de Renda de Pessoa Física com base nas informações da
                B3, considerando apenas os valores do imposto pertinentes aos
                ativos de renda variável integrados em nossa plataforma.
                <br />
                <br />
                Contudo, é essencial conferir os dados declarados nos informes
                de rendimentos enviados pelos Escrituradores das Ações e pelas
                Administradoras dos Fundos Imobiliários, assim como nos
                relatórios dos bancos e corretoras. Isso assegura a precisão e a
                consistência das informações utilizadas no processo de apuração
                do IRPF.
                <br />
                <br />
                O iBox6 elabora os informes para o Imposto de Renda de Pessoa
                Física (IRPF) com base nos ativos declarados na B3. No entanto,
                para elaboração completa de sua declaração, recomendamos que
                você procure um profissional da área de contabilidade.
                <br />
                <br />
                Se ainda não tiver escritório de contabilidade contratado,
                sugerimos o escritório parceiro do iBox6, já especializado em
                investimentos no Brasil e nos EUA:{' '}
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send?phone=5541984765686&text=Ol%C3%A1,%20vim%20indicado%20pelo%20iBox6."
                >
                  Niw contabilidade
                </a>
              </p>
            </div>
          </CardComponent>
        </>
      )}
    </Container>
  );
}

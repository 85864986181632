import { useEffect, useState } from 'react';
import { Heading } from '../../../../../components/DefaultStylesTypography';
import { LinkAccountB3 } from '../../Settings/LinkAccountB3';
import { ButtonDesign } from '../../../../../components/ButtonDesign';
import { ModalDesigner } from '../../../../../components/ModalDesigner';
import imageFimdeAno from '../../../../../../src/assets/business/1x1.png';
// import imageBlack13 from '../../../../../../src/assets/business/black13.jpg';

export function TutorialIbox() {
  const [modalLinkAccountB3, setModalLinkAccountB3] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);

  useEffect(() => {
    const renderCount = parseInt(localStorage.getItem('renderCount') || '0', 10);
    const newRenderCount = renderCount + 1;
    localStorage.setItem('renderCount', newRenderCount.toString()); 

    if (newRenderCount % 5 === 0) {
      setShowTutorial(true);
    } else {
      setShowTutorial(false);
    }
  }, []);

  const handleSubscriptionRedirect = () => { 
    window.location.href = '/pagamentos'; // URL da página de assinaturas 
  };

  return (
    <>
      <ModalDesigner
        id="tutorial"
        isOpen={showTutorial}
        setState={setShowTutorial}
        size="lg"
      >
        <Heading
          as="h5"
          variant="sextiary"
          style={{ fontWeight: 900, fontSize: '1.5rem', color: '#373737', textAlign:'center',}}
        >
          Promoção de Final de Ano!
        </Heading>
        <p
          style={{
            textAlign:'center',
            fontWeight: 400,
            fontSize: '1.3rem',
            color: '#6C6C6C',
            lineHeight: '150%',
            marginTop: '0',
            marginBottom: 0,
          }}
        >
          {/* Preparamos um vídeo para lhe mostrar algumas funcionalidades da
          plataforma. Nossa equipe dedicou tempo e energia para destacar
          alguns dos recursos mais essenciais e úteis, a fim de proporcionar a
          você uma experiência incrível.
          Atualização */}

          <br/>Termine o ano com essa <strong>Oferta imperdível</strong> por tempo limitado!
        </p>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '1rem',
          }}
          >
          <ButtonDesign onClick={handleSubscriptionRedirect}> 
            QUERO DESCONTO DE 40% 
          </ButtonDesign>
          {/* <ButtonDesign onClick={() => setModalLinkAccountB3(true)}>
            VENHA SER IBOXER!
          </ButtonDesign> */}
        </div>
        <br/>
        <br/>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex'}}>
          <img src={imageFimdeAno} alt="Oferta Fim de Ano" style={{ width: '100%', height: 'auto', borderRadius: '8px', marginTop:'-3%', }} />{/*Fim de ano*/} 
          {/* <img src={imageBlack13} alt="Black Friday 13" style={{ width: '48%', height: 'auto', borderRadius: '8px' }} />  */}
        </div>
        </div>
      </ModalDesigner>

      <ModalDesigner
        title="Vincular conta B3"
        id="linkb3"
        isOpen={modalLinkAccountB3}
        setState={setModalLinkAccountB3}
        size="sm"
      >
        <LinkAccountB3 onCloseModal={setModalLinkAccountB3} />
      </ModalDesigner>
    </>
  );
}


import styled from "styled-components";

export const ContentDataHeader = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 1rem;
  p {
    margin: 0;
  }
`;

export const CardContentData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  border-top: 1px solid ${(props) => props.theme['primary-800']};
  min-width: 8rem;
  width: auto;
  box-shadow: 1px 1px 6px 0px #0000000d;

  padding: 0.5rem;

  p {
    margin: 0;
    font-size: 0.75rem;
    color: ${(props) => props.theme['gray-500']};
  }
  strong {
    font-weight: 600;
    font-size: 0.75rem;
    color: ${(props) => props.theme['gray-500']};
  }
`;
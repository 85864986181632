import {
  Check,
  CreditCard,
  FileText,
  MinusCircle,
  PencilLine,
} from '@phosphor-icons/react';

import {
  CheckoutPlanContainer,
  ContentData,
  Divider,
  CheckoutContent,
  FunctionalityContent,
  ListFunctionalitiesContent,
  SelectedPlanContent,
  CheckoutDataContent,
  ContentButton,
  ButtonPaymentMethods,
  ContentButtonConfirm,
} from './styles';
import { IPropsNewPlan } from '../../../..';
import { IDataForm } from '../..';
import { AlertBoletoMessage } from '../PaymentFormPlan/styles';
import { ButtonComponent } from '../../../../../../../../../../../components/ButtonComponent';
import { useFormContext } from 'react-hook-form';

interface IPropsCheckoutPlan {
  selectedPlan: IPropsNewPlan;
  onClearPlan: Function;
  defaultValueBackToScreen: IDataForm;
  backPaymentMethod: (step: number) => void;
}

export function Checkout({
  selectedPlan,
  onClearPlan,
  defaultValueBackToScreen,
  backPaymentMethod,
}: IPropsCheckoutPlan) {
  const {
    formState: { isSubmitting },
  } = useFormContext();
  return (
    <CheckoutPlanContainer>
      <CheckoutDataContent>
        <CheckoutContent>
          <h4>Endereço</h4>
          <ContentData>
            <p>
              {defaultValueBackToScreen.rua}, Número{' '}
              {defaultValueBackToScreen.numero}
            </p>
            <p>
              {defaultValueBackToScreen.bairro},{' '}
              {defaultValueBackToScreen.estado} -{' '}
              {defaultValueBackToScreen.zipcode}
            </p>
          </ContentData>
          <h4>Dados de pagamento</h4>
          <ContentData>
            {defaultValueBackToScreen.payment_method === 'credit_card' ? (
              <>
                <p>
                  <span>Número do cartão</span>
                  **** **** ****{' '}
                  {
                    defaultValueBackToScreen.card_number.split(' ')[
                      defaultValueBackToScreen.card_number.split(' ').length - 1
                    ]
                  }
                </p>
                <div />
                <p>
                  <span>Nome do cartão</span>
                  {defaultValueBackToScreen.card_holder_name.toUpperCase()}
                </p>
                <div />
                <p>
                  <span>CPF do titular</span>
                  {defaultValueBackToScreen.cpf_card}
                </p>
                <div className="rowContentInput">
                  <p>
                    <span>Validade</span>
                    {defaultValueBackToScreen.card_expiration_date}
                  </p>
                  <p>
                    <span>CVV</span>
                    ***
                  </p>
                </div>
              </>
            ) : (
              <AlertBoletoMessage>
                Ao escolher a opção de pagamento por boleto, gostaríamos de
                lembrá-lo que pode haver uma demora na compensação bancária. O
                prazo para a compensação do boleto é de até 3 dias úteis após o
                pagamento, o que pode causar um atraso na liberação do seu
                acesso aos recursos premium da plataforma.
              </AlertBoletoMessage>
            )}
          </ContentData>
          <h4>Método de pagamento</h4>
          <ContentButton>
            <ButtonPaymentMethods disabled type="button">
              {defaultValueBackToScreen.payment_method === 'boleto' ? (
                <>
                  <FileText /> Boleto Bancário
                </>
              ) : (
                <>
                  <CreditCard /> Cartão de crédito
                </>
              )}
            </ButtonPaymentMethods>
            <ButtonPaymentMethods
              variant="secondary"
              type="button"
              onClick={() => backPaymentMethod(1)}
            >
              <PencilLine /> Alterar
            </ButtonPaymentMethods>
          </ContentButton>
        </CheckoutContent>
        <Divider />
        <SelectedPlanContent>
          <h4>Plano selecionado</h4>
          <h3>
            iBox6 <span>{selectedPlan.title}</span>
          </h3>
          <ListFunctionalitiesContent>
            {selectedPlan.funcionalities.map((func) => (
              <li key={func.label}>
                <FunctionalityContent disabled={func.disabled}>
                  {func.disabled ? (
                    <MinusCircle weight="bold" />
                  ) : (
                    <Check weight="bold" />
                  )}
                  <strong>{func.label}</strong>
                </FunctionalityContent>
              </li>
            ))}
          </ListFunctionalitiesContent>
          <ButtonPaymentMethods
            variant="tertiary"
            type="button"
            onClick={() => onClearPlan()}
          >
            Escolher outro plano
          </ButtonPaymentMethods>
        </SelectedPlanContent>
      </CheckoutDataContent>
      <ContentButtonConfirm>
        <ButtonComponent
          style={{
            width: '100%',
            maxWidth: '22rem',
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '8px',
          }}
          type="submit"
          disabled={isSubmitting}
        >
          Confirmar
        </ButtonComponent>
      </ContentButtonConfirm>
    </CheckoutPlanContainer>
  );
}

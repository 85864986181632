import styled from 'styled-components';
import { FiEdit, FiPlus } from 'react-icons/fi';
import { MdDelete } from 'react-icons/md';

export const ManualImportContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  overflow: hidden;
  padding-right: 0.25rem;
  touch-action: pan-y;
`;

export const ContentTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-weight: 400;
      font-size: 1rem;
      color: #303F4B;
    }
    .buttonContent {
      display: flex;
      flex-direction: row;
      gap: 1.5rem;
      align-items: center;
      button {
        all: unset;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;

        cursor: pointer;
      }
      .addNewActiveButton {
        padding: 0.5rem 0.75rem;
        border-radius: 39px;
        background: #303F4B;
        color: #ECF0F3;
        font-weight: 400;
        font-size: 1rem;
      }

      .trashButton {
        background: #303F4B;
        border-radius: 100%;
        padding: 0.5rem;
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
`;

export const DeleteActive = styled(MdDelete)`
  font-size: 15px;
  cursor: pointer;
`;
export const EditActive = styled(FiEdit)`
  font-size: 15px;
  cursor: pointer;
`;
export const AddActive = styled(FiPlus)`
  font-size: 15px;
  cursor: pointer;
  color: ${(props) => props.theme.black};
`;

export const HeaderStyle = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.white};
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  justify-content: space-between;
  border-radius: 4px;
  box-shadow: ${(props) => props.theme['card-shadow-2']};

  .contentFilter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-left: 1rem;
  }
  div {
    display: flex;
    gap: 1rem;
    align-items: center;

    .divider {
      height: 30px;
      width: 1px;
      background: rgba(21, 21, 21, 0.3);
    }
    input {
      border: ${(props) => props.theme.border};
      border-radius: 4px;
      padding: 0.2rem 0.5rem;
    }

    p {
      font-size: 1rem;
      margin: 0;
      cursor: pointer;
      transition: color 0.3s;
      :hover {
        color: ${(props) => props.theme.primary};
      }
    }

    .activeFilter {
      color: ${(props) => props.theme.primary};
      font-weight: 700;
      border-bottom: 1px solid ${(props) => props.theme.primary};
    }

    button {
      border: none;
      outline: none;
      background-color: transparent;
      font-size: 1rem;
      transition: color 0.3s;

      :hover {
        color: ${(props) => props.theme.primary};
      }
    }
    button.delete {
      color: #ff6363;
    }
    button.delete:hover {
      color: red;
    }
  }

  @media (max-width: 577px) {
    flex-direction: column;
    align-items: start;

    .contentFilter {
      margin-left: 0;
      margin-top: 10px;
    }

    div {
      width: 100%;
      input {
        width: 100%;
      }

      button {
        display: flex;
        justify-content: flex-end;
        width: 120px;
      }
    }
  }
`;

export const DeleteAllActives = styled.div`
  display: flex;
  flex-direction: column;
  p {
    margin: 0;
  }
  .buttonContent {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
`;

const backgroundColorForType = {
  acao: '#FFB900',
  FII: '#006AAC',
  rendaFixa: '#FF8B4B',
};

interface IPropsTypeActiveContent {
  typeActive: 'acao' | 'FII' | 'rendaFixa';
}

export const TypeActiveContent = styled.span<IPropsTypeActiveContent>`
  padding: 0.25rem 0.5rem;
  border-radius: 41px;
  color: ${(props) => props.theme.white};
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  white-space: nowrap;
  background: ${(props) => backgroundColorForType[props.typeActive]};
`;

export const CompanyNameContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  strong {
    font-size: 0.875rem;
    font-weight: 500;
    color: ${(props) => props.theme['gray-400']};
    max-width: 15ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  span {
    font-weight: 400;
    font-size: 0.625rem;
    color: ${(props) => props.theme['gray-400']};
    max-width: 25ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
import styled, { css } from 'styled-components';

export const RegisterFinishContainer = styled.form`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;

  p {
    margin: 0;
    font-size: 0.875rem;
    color: ${(props) => props.theme['gray-700']};
    font-weight: 500;
  }

  .funciona {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

interface IPropsInputContent {
  isShort?: boolean;
  suffix?: boolean;
}

export const InputContent = styled.div<IPropsInputContent>`
  display: flex;
  flex-direction: column;

  label {
    color: ${(props) => props.theme['gray-700']};
    font-weight: 500;
    margin: 0;
  }
  input {
    padding: 0.5rem;
    border-radius: 4px;
    color: ${(props) => props.theme['gray-700']};
    border: ${(props) => props.theme.border};
    width: 100%;
    max-width: ${(props) => (props.isShort ? '30rem' : 'none')};
  }

  ${(props) =>
    props.suffix &&
    css`
      div {
        display: flex;

        align-items: center;
        position: relative;

        input {
          padding-right: 3rem;
        }

        svg {
          position: absolute;
          width: 1.5rem;
          height: 1.5rem;
          right: 0.5rem;
          cursor: pointer;
        }
      }
    `}
`;

export const ValidationPassword = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  flex-wrap: wrap;
`;

interface IPropsInfoValidation {
  error: boolean;
}
export const InfoValidationPassword = styled.li<IPropsInfoValidation>`
  list-style: inside;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  color: ${(props) => (!props.error ? props.theme.black : props.theme.success)};
  align-items: center;
  font-size: 0.75rem;
  align-items: center;
  text-decoration: ${(props) => props.error && 'line-through'};

  /* ::before {
    content: '*';
    display: flex;
    justify-content: center;
    align-items: center;
  } */

  span {
    width: 0.25rem;
    height: 0.25rem;
    background-color: ${(props) =>
      !props.error ? props.theme['gray-700'] : props.theme.success};
    border-radius: 999px;
  }

  /* svg {
    background: ${(props) =>
    !props.error ? props.theme.error : props.theme.success};
    border-radius: 100%;
    color: ${(props) => props.theme.white};
    font-size: 1.1rem;
    opacity: 0.8;
  } */
`;

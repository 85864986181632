import axios from 'axios';
import ReactLoading from 'react-loading';
import { useForm } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { useTitleAndUser } from '../../../../../../../../hooks/userTitle';
import cep from 'cep-promise';
import { ChangeEvent, useEffect, useState } from 'react';
import { ButtonComponent } from '../../../../../../../../components/ButtonComponent';
import { handleToast } from '../../../../../../../../components/Toast';
import { userRoutes } from '../../../../../../../../utils/RoutesApi';
import { Container, ContentRowInput } from './adressStyle';

interface IPropsAddressFormIR {
  setTabForm: Function;
}

export function AddressFormIR({ setTabForm }: IPropsAddressFormIR) {
  const getDataUser = JSON.parse(
    //@ts-ignore
    localStorage.getItem(process.env.REACT_APP_USER_VERSION)
  );
  //@ts-ignore
  const { user, setUser } = useTitleAndUser();
  const { register, handleSubmit, setValue } = useForm();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (user.zipcode) {
      setValue('zipcode', user.zipcode);
    }
    setValue('cidade', user.cidade);
    setValue('bairro', user.bairro);
    setValue('rua', user.rua);
    setValue('estado', user.estado);
    setValue('numero', user.numero);
  }, []);

  async function submit(data: any) {
    setLoading(true);
    if (!data.zipcode) {
      setLoading(false);
      return handleToast(true, 'Informe o cep');
    }
    data.zipcode = data.zipcode.replace(/\D/g, '');
    if (data.zipcode.length !== 8) {
      setLoading(false);
      return handleToast(true, 'Informe um cep valido');
    }
    if (!data.estado) {
      setLoading(false);
      return handleToast(true, 'Informe o seu estado');
    }
    if (!data.rua) {
      setLoading(false);
      return handleToast(true, 'Informe a sua rua');
    }
    if (!data.cidade) {
      setLoading(false);
      return handleToast(true, 'Informe sua cidade');
    }
    if (!data.bairro) {
      setLoading(false);
      return handleToast(true, 'Informe o bairro');
    }
    if (!data.numero) {
      setLoading(false);
      return handleToast(true, 'Informe o número da casa');
    }
    await axios
      .patch(
        `${process.env.REACT_APP_API_URL}${userRoutes.USER_UPDATE_PROFILE}`,
        data
      )
      .then((response) => {
        let res = {
          ...response.data,
          data_trial: getDataUser.dias_trial,
        };
        if (res.profile.image) {
          const isValidProfileImage = !!res.profile.image.match(
            'https://storage.googleapis.com/ibox6-wordpress.appspot.com/'
          );
          if (!isValidProfileImage) {
            res.profile.image =
              process.env.REACT_APP_API_URL + res.profile.image;
          }
        }
        if (res.profile.banner) {
          const isValidProfileBanner = !!res.profile.banner.match(
            'https://storage.googleapis.com/ibox6-wordpress.appspot.com/'
          );
          if (!isValidProfileBanner) {
            res.profile.banner =
              process.env.REACT_APP_API_URL + res.profile.banner;
          }
        }

        let obj = {
          ...getDataUser,

          user: res,
        };
        localStorage.setItem(
          //@ts-ignore
          process.env.REACT_APP_USER_VERSION,
          JSON.stringify(obj)
        );
        setUser(obj.user);
        handleToast(false, 'Atualizado com sucesso');
        setTabForm(2);
        return;
      })
      .catch((err) => {
        setLoading(false);
        return handleToast(true, err.response.data.response);
      });
  }

  async function onBlurCep(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;

    const replaceCep = value.replace(/[^0-9]/g, '');

    if (replaceCep?.length !== 8) {
      return;
    } else {
      cep(replaceCep).then((res) => {
        setValue('bairro', res.neighborhood);
        setValue('cidade', res.city);
        setValue('rua', res.street);
        setValue('estado', res.state);
      });
    }
  }

  return (
    <Container onSubmit={handleSubmit(submit)}>
      <header>
        <h1>Endereço</h1>
      </header>

      <ReactInputMask
        mask="99999-999"
        type="text"
        placeholder="cep: 12345-678"
        {...register('zipcode')}
        defaultValue={user ? user.zipcode : null}
        onBlur={onBlurCep}
      />
      <input
        type="text"
        {...register('cidade')}
        placeholder="Cidade ex: São Paulo"
      />
      <input type="text" {...register('rua')} placeholder="Rua" />
      <ContentRowInput>
        <input
          type="text"
          {...register('complemento')}
          placeholder="Complemento"
        />

        <input
          type="text"
          {...register('estado')}
          placeholder="UF ex: SP"
          className="miniInput"
        />

        <input
          type="text"
          {...register('numero')}
          placeholder="Número ex: 000"
          className="miniInput"
        />
      </ContentRowInput>

      <ButtonComponent
        disabled={loading}
        className="submitButton"
        type="submit"
      >
        {loading ? (
          <ReactLoading
            type="balls"
            color="#FFF"
            width="1.5rem"
            height="1.5rem"
          />
        ) : (
          'Enviar'
        )}
      </ButtonComponent>
    </Container>
  );
}

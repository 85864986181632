import styled from 'styled-components';

export const EditPlanContainer = styled.div`
  display: flex;
  flex-direction: column;

  .editButton {
    all: unset;
    width: 1.5rem;
    height: 1.5rem;
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;

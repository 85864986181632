import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .cardShowValue {
    background: ${(props) => props.theme.white};
    border-radius: 4px;
    padding: 1rem;
    width: 100%;
    /* max-width: 343px; */
    height: 100%;
    max-height: 210px;
    display: flex;
    flex-direction: column;
    box-shadow: ${(props) => props.theme['card-shadow-2']};

    h3 {
      margin: 0;
    }

    p {
      margin: 0;
      font: 400 1rem 'Poppins', sans-serif;
      line-height: 1.8;
      color: #a4a3ab;
    }
    strong {
      margin: 0;
      font: 400 1rem 'Poppins', sans-serif;
      line-height: 1.8;
      color: #1a1a1a;
    }
  }

  .contentFilter {
    display: flex;
    gap: 1rem;
    padding: 1rem 0.5rem;
    background: ${(props) => props.theme.white};
    border-radius: 8px;
    margin-bottom: 1rem;

    p {
      font-size: 16px;
      font-weight: bold;
      color: ${(props) => props.theme.black};
      cursor: pointer;
      margin: 0;
    }
    .activeFilter {
      color: ${(props) => props.theme.primary};
      border-bottom: 2px solid ${(props) => props.theme.primary};
    }
  }
  .finalList {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;

    h3 {
      font: 400 0.75rem 'Poppins', sans-serif;
      color: ${(props) => props.theme.primary};
      cursor: pointer;
      transition: filter 0.3s;
      :hover {
        filter: brightness(0.9);
      }
    }
  }
`;

export const HeaderDate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background: ${(props) => props.theme.white};
  border-radius: 4px;
  padding: 1rem;
  align-items: center;
  box-shadow: ${(props) => props.theme['card-shadow-2']};

  h3 {
    margin: 0;
    font: 500 1rem 'Poppins', sans-serif;
  }

  div {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    align-items: center;

    span {
      width: auto;
    }
    @media (max-width: 480px) {
      gap: 0;
    }
  }

  @media (max-width: 340px) {
    gap: 1rem;
  }

  /* @media (max-width: 480px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    div {
      justify-content: space-between;
      width: 100%;
    }
  } */
`;

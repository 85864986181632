import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';

const Chart = ({ data, fillcolor }) => {
  return (
    <Sparklines data={data} height={50}>
      <SparklinesLine color={fillcolor} style={{ color: fillcolor }} />
      <SparklinesSpots />
    </Sparklines>
  );
};

export default Chart;

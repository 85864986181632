import styled, { css } from 'styled-components';

interface IPropsButtonStyle {
  isOutline?: boolean;
  isSmall?: boolean;
  isTonal?: boolean;
  isText?: boolean;
}

export const ButtonStyle = styled.button<IPropsButtonStyle>`
  all: unset;
  display: inline-flex;
  /* height: 2.5rem; */
  padding: 0.5rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
  background: ${(props) => props.theme['orange-500']};
  border-radius: 8px;
  color: #f1f2f3;
  text-align: center;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.01094rem;
  text-transform: uppercase;
  border: 2px solid transparent;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  
  &:focus:not(:disabled) {
    border-color: ${(props) => props.theme['orange-700']};
  }
  &:hover:not(:disabled) {
    background: ${(props) => props.theme['orange-600']};
  }
  &:disabled {
    background: #d4d6db;
    cursor: not-allowed;
    color: #757c8a;
  }

  ${(props) =>
    props.isOutline &&
    css`
      background-color: transparent;
      border: 1px solid ${(props) => props.theme['orange-500']};
      color: ${(props) => props.theme['orange-500']};

      &:focus:not(:disabled) {
        border: 2px solid ${(props) => props.theme['orange-500']};
      }
      &:hover:not(:disabled) {
        background: ${(props) => props.theme['orange-50']};
      }
      &:disabled {
        background-color: transparent;
        border: 1px solid #a3a7b1;
        color: #757c8a;
        cursor: not-allowed;
      }
    `}
`;

import {
  Direitos,
  FooterCol,
  FooterContainer,
  FooterContent,
  FunctionalitiesFooter,
} from './styles';
import { Heading } from '../../../../../components/DefaultStylesTypography';
import { ArrowSquareOut } from '@phosphor-icons/react';
import { logoWhiteImg } from '../../../../../Constants/images';

export function Footer() {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterCol>
          <img src={logoWhiteImg} alt="" />
          <Heading>
            Você pode começar a investir hoje, sem pressão e sem medo!
          </Heading>
          <p>
            Pare de adiar sua independência financeira e comece hoje mesmo a
            investir. Fique livre para cuidar da sua vida, e deixe que a gente
            cuida dos seus investimentos.
          </p>
        </FooterCol>
        <FunctionalitiesFooter>
          <li style={{ fontWeight: 600 }}>Funcionalidades</li>
          <li>
            <a href="#comunidade">
              Comunidade <ArrowSquareOut weight="bold" />{' '}
            </a>
          </li>
          <li>
            <a href="#educacao">
              Educação <ArrowSquareOut weight="bold" />
            </a>
          </li>
          <li>
            <a href="#plataforma">
              Plataforma <ArrowSquareOut weight="bold" />
            </a>
          </li>
          {/* <li>
            <a href="">
              FAQ <ArrowSquareOut weight="bold" />
            </a>
          </li> */}
        </FunctionalitiesFooter>
        {/* <SocialMediaFooter>
          <Heading as="h6" variant="sextiary">
            Redes sociais
          </Heading>
        </SocialMediaFooter> */}
      </FooterContent>

      <Direitos>
        © Copyright 2023 - iBox6. Todos os direitos reservados.
      </Direitos>
    </FooterContainer>
  );
}

import { ChartBar, ChartLine, Info } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useNewTheme } from '../../../../../hooks/useNewTheme';
import { transformMaskCurrency } from '../../../../../utils/transformMaskCurrency';
import { Modal } from '../../../../Modal';
import { maskValueCards } from '../../maskValueCards';
// import ReactECharts from 'echarts-for-react';
// import * as echarts from 'echarts';

import {
  CardContentData,
  CardContentDataHeader,
  Container,
  ContentDataHeader,
  HeaderDividendosModal,
} from './styles';

interface IPropsData {
  Ano: number;
  value: number;
}

interface IPropsSeries {
  name: string;
  data: number[];
  type: 'line' | 'column' | 'area';
}

interface IPropsGraphicLucro {
  title: string;
  data: IPropsData[];
  fillcolor: string;
  state: boolean;
  setState: Function;
  type?: 'percent' | 'currency' | 'noEdit' | 'noEdit2';
  payoutData?: {
    asset: string;
    payout: number;
    total_dividendos: number;
    dy_12_meses: number;
    dy_percentual: number;
    valor: number;
  };
}

export function NewGraphicData({
  data,
  fillcolor,
  title,
  state,
  setState,
  type = 'currency',
  payoutData,
}: IPropsGraphicLucro) {
  //@ts-ignore
  const { isMobile } = useNewTheme();
  const [typeGraphic, setTypeGraphic] = useState<boolean>(false);
  const [periodDataGraphic, setPeriodDataGraphic] = useState<
    '5anos' | '10anos' | 'todos'
  >('todos');
  const [seriesColumn, setSeriesColumn] = useState<IPropsSeries[]>([]);
  const [seriesLine, setSeriesLine] = useState<IPropsSeries[]>([
    {
      data: [],
      name: '',
      type: 'line',
    },
  ]);
  const [labels, setLabels] = useState<number[]>([]);
  const [newData, setNewData] = useState<IPropsData[]>([]);
  const [changeChartData, setChangeChartData] = useState<boolean>(false);
  const [showValueGraphic, setShowValueGraphic] = useState<boolean>(false);

  useEffect(() => {
    let dataFilterNo0: IPropsData[] = data.filter((item) => item.value !== 0);
    if (periodDataGraphic === 'todos') {
      setNewData(dataFilterNo0);
    } else {
      let lastData = dataFilterNo0[dataFilterNo0.length - 1]?.Ano;
      let newFilter = dataFilterNo0.filter((item) => {
        if (periodDataGraphic === '5anos') {
          return item.Ano > lastData - 5;
        } else if (periodDataGraphic === '10anos') {
          return item.Ano > lastData - 10;
        }
      });
      newFilter = newFilter.sort((a, b) => a.Ano - b.Ano);
      setNewData(newFilter);
    }
  }, [periodDataGraphic]);

  useEffect(() => {
    setLabelsAndSeres();
  }, [newData]);

  function setLabelsAndSeres() {
    const dataArrGraphic = newData?.sort((a, b) => {
      if (a.Ano > b.Ano) return 1;
      if (a.Ano < b.Ano) return -1;
      return 0;
    });
    setSeriesColumn([
      {
        name: title,
        data: dataArrGraphic.map((item) => Number(item.value.toFixed(2))),
        type: 'column',
      },
    ]);
    setSeriesLine([
      {
        name: title,
        data: dataArrGraphic.map((item) => Number(item.value.toFixed(2))),
        type: 'area',
      },
    ]);

    setLabels(dataArrGraphic.map((item) => item.Ano));
    setChangeChartData(!changeChartData);
  }

  let configBar = {
    colors: [fillcolor],
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top',
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (value: number) {
          if (type === 'noEdit2') {
            return value.toFixed(2);
          } else if (type === 'noEdit') {
            return `${value.toFixed(2)}%`;
          } else if (type === 'percent') {
            return new Intl.NumberFormat('pt-BR', {
              style: 'percent',
            }).format(value);
          } else {
            return transformMaskCurrency(value);
          }
        },
      },
    },

    dataLabels: {
      enabled: showValueGraphic,
      offsetY: -10,
      style: {
        fontSize: '12px',
      },
      formatter: function (value: number) {
        if (type === 'noEdit2') {
          return `${value.toFixed(2)}`;
        } else if (type === 'noEdit') {
          return `${value.toFixed(2)}%`;
        } else if (type === 'percent') {
          return new Intl.NumberFormat('pt-BR', {
            style: 'percent',
          }).format(value);
        } else {
          return transformMaskCurrency(value);
        }
      },
    },

    stroke: {
      curve: 'straight',
    },
    labels: labels,
    yaxis: {
      labels: {
        formatter: function (value: number) {
          if (type === 'noEdit2') {
            return value.toFixed(2);
          } else if (type === 'noEdit') {
            return `${value.toFixed(2)}%`;
          } else if (type === 'percent') {
            return new Intl.NumberFormat('pt-BR', {
              style: 'percent',
            }).format(value);
          } else {
            return maskValueCards(value);
          }
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    legend: {
      horizontalAlign: 'left',
    },
    responsive: [
      {
        breakpoint: 1200,
        options: {
          chart: {
            width: '100%',
          },
        },
      },
    ],
  };

  let config = {
    colors: [fillcolor],

    dataLabels: {
      enabled: showValueGraphic,
      formatter: function (value: number) {
        if (type === 'noEdit2') {
          return value.toFixed(2);
        } else if (type === 'noEdit') {
          return `${value.toFixed(2)}%`;
        } else if (type === 'percent') {
          return new Intl.NumberFormat('pt-BR', {
            style: 'percent',
          }).format(value);
        } else {
          return transformMaskCurrency(value);
        }
      },
    },

    stroke: {
      curve: 'smooth',
    },
    fill: {
      type: 'gradient',

      gradient: {
        shade: 'light',
        type: 'vertical',

        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 0.6,
      },
    },
    labels: labels,
    tooltip: {
      y: {
        formatter: function (value: number) {
          if (type === 'noEdit2') {
            return value.toFixed(2);
          } else if (type === 'noEdit') {
            return `${value.toFixed(2)}%`;
          } else if (type === 'percent') {
            return new Intl.NumberFormat('pt-BR', {
              style: 'percent',
            }).format(value);
          } else {
            return transformMaskCurrency(value);
          }
        },
      },
    },

    yaxis: {
      labels: {
        formatter: function (value: number) {
          if (type === 'noEdit2') {
            return value.toFixed(2);
          } else if (type === 'noEdit') {
            return `${value.toFixed(2)}%`;
          } else if (type === 'percent') {
            return new Intl.NumberFormat('pt-BR', {
              style: 'percent',
            }).format(value);
          } else {
            return maskValueCards(value);
          }
        },
      },
    },
    legend: {
      horizontalAlign: 'left',
    },
    responsive: [
      {
        breakpoint: 1200,
        options: {
          chart: {
            width: '100%',
          },
        },
      },
    ],
  };
  let dataSortDesc = newData;
  dataSortDesc = dataSortDesc?.sort((a, b) => {
    if (a.value < b.value) return 1;
    if (a.value > b.value) return -1;
    return 0;
  });

  const lastValueMask =
    type === 'noEdit2'
      ? data[data.length - 1]?.value.toFixed(2)
      : type === 'percent'
      ? new Intl.NumberFormat('pt-BR', {
          style: 'percent',
        }).format(data[data.length - 1]?.value)
      : type === 'currency'
      ? maskValueCards(data[data.length - 1]?.value)
      : `${data[data.length - 1]?.value.toFixed(2)}%`;
  const highestValueMask =
    type === 'noEdit2'
      ? dataSortDesc[0]?.value.toFixed(2)
      : type === 'percent'
      ? new Intl.NumberFormat('pt-BR', {
          style: 'percent',
        }).format(dataSortDesc[0]?.value)
      : type === 'currency'
      ? maskValueCards(Number(dataSortDesc[0]?.value.toFixed(0)))
      : `${dataSortDesc[0]?.value.toFixed(2)}%`;
  const lowertValueMask =
    type === 'noEdit2'
      ? dataSortDesc[dataSortDesc.length - 1]?.value.toFixed(2)
      : type === 'percent'
      ? new Intl.NumberFormat('pt-BR', {
          style: 'percent',
        }).format(dataSortDesc[dataSortDesc.length - 1]?.value)
      : type === 'currency'
      ? maskValueCards(dataSortDesc[dataSortDesc.length - 1]?.value)
      : `${dataSortDesc[dataSortDesc.length - 1]?.value.toFixed(2)}%`;

  const averageValue = newData.reduce((acc, value) => {
    return acc + value.value;
  }, 0);

  const averageValueMask =
    type === 'noEdit2'
      ? (averageValue / newData.length).toFixed(2)
      : type === 'percent'
      ? new Intl.NumberFormat('pt-BR', {
          style: 'percent',
        }).format(averageValue / newData.length)
      : type === 'currency'
      ? maskValueCards(Number((averageValue / newData.length).toFixed(0)))
      : `${(averageValue / newData.length).toFixed(2)}%`;

  // const date = labels;

  // const option = {
  //   legend: {
  //     bottom: 17,
  //   },
  //   tooltip: {
  //     trigger: 'axis',
  //     valueFormatter: (val: any) => maskValueCards(val.toFixed(0) ?? val),
  //     axisPointer: {
  //       axis: 'x',
  //     },
  //   },
  //   grid: {
  //     // containLabel: true,
  //     height: '200px',
  //     // width: '100%',
  //     bottom: 60,
  //     right: '5%',
  //   },
  //   toolbox: {
  //     feature: {
  //       dataZoom: {
  //         yAxisIndex: 'none',
  //       },
  //       restore: {},
  //       saveAsImage: {},
  //     },
  //   },
  //   xAxis: {
  //     type: 'category',
  //     boundaryGap: false,
  //     data: date,

  //     label: {
  //       show: true,
  //     },
  //   },
  //   yAxis: {
  //     type: 'value',
  //     axisLabel: {
  //       formatter: function (value: any, index: number) {
  //         return maskValueCards(value.toFixed(0));
  //       },
  //     },
  //   },

  //   series: [
  //     {
  //       name: seriesLine[0].name,
  //       type: typeGraphic ? 'bar' : 'line',
  //       symbol: 'none',
  //       sampling: 'lttb',
  //       itemStyle: {
  //         color: fillcolor,
  //       },
  //       areaStyle: {
  //         color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
  //           {
  //             offset: 0,
  //             color: fillcolor,
  //           },
  //           {
  //             offset: 1,
  //             color: fillcolor,
  //           },
  //         ]),
  //       },

  //       data: seriesLine[0].data,
  //     },
  //   ],
  // };

  return (
    state && (
      <Modal id="vacancia" title={title} setState={setState} width="auto">
        {/* @ts-ignore */}
        <Container>
          {payoutData && (
            <HeaderDividendosModal>
              <p className="textPayout">
                A empresa está pagando {payoutData.payout.toFixed(2)}% do seu
                lucro líquido para os acionistas (Payout)
              </p>
              <div className="contentCards">
                <CardContentDataHeader>
                  <p>Último dividendo </p>
                  <strong>{transformMaskCurrency(payoutData.valor)}</strong>
                </CardContentDataHeader>
                <CardContentDataHeader>
                  <p>DY Últ. dividendo </p>
                  <strong>{payoutData.dy_percentual.toFixed(2)}%</strong>
                </CardContentDataHeader>
                <CardContentDataHeader>
                  <p>Div. por Ação </p>
                  <strong>
                    {transformMaskCurrency(payoutData.total_dividendos)}
                  </strong>
                  <span>Últ. 12 meses</span>
                </CardContentDataHeader>
                <CardContentDataHeader>
                  <p>Dividend Yield </p>
                  <strong>{payoutData.dy_12_meses.toFixed(2)}%</strong>
                  <span>Últ. 12 meses</span>
                </CardContentDataHeader>
              </div>

              {/* <p className="textWarning">
                <Info size={16} /> Os dados acima são referentes ao ano de 2022.
              </p> */}
            </HeaderDividendosModal>
          )}
          <ContentDataHeader>
            <CardContentData>
              <p>Média</p>
              <strong>{averageValueMask}</strong>
            </CardContentData>

            <CardContentData>
              <p>Atual</p>
              <strong>{lastValueMask}</strong>
            </CardContentData>

            <CardContentData>
              <p>Maior valor</p>
              <strong>{highestValueMask}</strong>
            </CardContentData>
            <CardContentData>
              <p>Menor valor</p>
              <strong>{lowertValueMask}</strong>
            </CardContentData>

            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
              <p
                onClick={() => setTypeGraphic(false)}
                title="Gráfico de linha"
                style={{
                  padding: '0.5rem',
                  border: `1px solid ${
                    typeGraphic ? 'rgba(21,21,21,0.3)' : 'var(--primary)'
                  }`,
                  borderRadius: '8px',
                  cursor: 'pointer',
                }}
              >
                {' '}
                <ChartLine size={24} color="var(--primary)" />
              </p>
              <p
                onClick={() => setTypeGraphic(true)}
                title="Gráfico de colunas"
                style={{
                  padding: '0.5rem',
                  border: `1px solid ${
                    typeGraphic ? 'var(--primary)' : 'rgba(21,21,21,0.3)'
                  }`,
                  borderRadius: '8px',
                  cursor: 'pointer',
                }}
              >
                <ChartBar size={24} color="var(--primary)" />
              </p>
              {!isMobile && (
                <select
                  value={periodDataGraphic}
                  //@ts-ignore
                  onChange={(e) => setPeriodDataGraphic(e.target.value)}
                  style={{
                    border: '1px solid rgba(21,21,21,0.3)',
                    borderRadius: '8px',
                    padding: '0.5rem',
                    maxWidth: '300px',
                  }}
                >
                  <option value={'todos'}>Máximo</option>
                  <option value={'5anos'}>5 Anos</option>
                  <option value={'10anos'}>10 Anos</option>
                </select>
              )}
            </div>

            {isMobile && (
              <select
                value={periodDataGraphic}
                //@ts-ignore
                onChange={(e) => setPeriodDataGraphic(e.target.value)}
                style={{
                  border: '1px solid rgba(21,21,21,0.3)',
                  borderRadius: '8px',
                  padding: '0.5rem',
                  width: '100%',
                }}
              >
                <option value={'todos'}>Máximo</option>
                <option value={'5anos'}>5 Anos</option>
                <option value={'10anos'}>10 Anos</option>
              </select>
            )}
          </ContentDataHeader>

          {/* <ReactECharts option={option} notMerge={true} /> */}

          {!typeGraphic && !changeChartData && showValueGraphic && (
            <ReactApexChart
              //@ts-ignore
              options={config}
              series={seriesLine}
              type="line"
              height={300}
              width={1000}
            />
          )}
          {!typeGraphic && changeChartData && !showValueGraphic && (
            <ReactApexChart
              //@ts-ignore
              options={config}
              series={seriesLine}
              type="line"
              height={300}
              width={1000}
            />
          )}
          {!typeGraphic && changeChartData && showValueGraphic && (
            <ReactApexChart
              //@ts-ignore
              options={config}
              series={seriesLine}
              type="line"
              height={300}
              width={1000}
            />
          )}
          {!typeGraphic && !changeChartData && !showValueGraphic && (
            <ReactApexChart
              //@ts-ignore
              options={config}
              series={seriesLine}
              type="line"
              height={300}
              width={1000}
            />
          )}

          {typeGraphic && !changeChartData && !showValueGraphic && (
            <ReactApexChart
              //@ts-ignore
              options={configBar}
              series={seriesColumn}
              type="line"
              height={300}
              width={1000}
            />
          )}
          {typeGraphic && changeChartData && showValueGraphic && (
            <ReactApexChart
              //@ts-ignore
              options={configBar}
              series={seriesColumn}
              type="line"
              height={300}
              width={1000}
            />
          )}
          {typeGraphic && !changeChartData && showValueGraphic && (
            <ReactApexChart
              //@ts-ignore
              options={configBar}
              series={seriesColumn}
              type="line"
              height={300}
              width={1000}
            />
          )}
          {typeGraphic && changeChartData && !showValueGraphic && (
            <ReactApexChart
              //@ts-ignore
              options={configBar}
              series={seriesColumn}
              type="line"
              height={300}
              width={1000}
            />
          )}
        </Container>
      </Modal>
    )
  );
}

import { useEffect, useState } from 'react';
import { Table as TableAntd } from 'antd';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Eye, EyeSlash } from '@phosphor-icons/react';
import { validateFloatNumber } from '../../../../../../components/Boltz/ValidateFloatNumber';
import { transformMaskCurrency } from '../../../../../../../utils/transformMaskCurrency';
import { Container, ContentFilters } from './styles';
import { useActives } from '../../../../../../../hooks/useActives';
import { useSimulation } from '../../../../hooks/SimulationHooks';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#FFF',
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const headerResults = [
  'Ativo',
  'Cotação',
  'Aporte',
  'Valor Aporte',
  'Qtd final',
  'Novo Patrimônio',
  'Objetivo',
  '%Simulada ',
];

interface IPropsFormAporte{
  inicialTypeAporte:'action' | 'fii' | 'all' | 'broker'
}

export function FormAporte({inicialTypeAporte}:IPropsFormAporte) {
  const {
    actions,
    all,
    fiis,
    results,
    rowSelectionAction,
    rowSelectionAll,
    rowSelectionFii,
    sendAllAssets,
    setSendAllAssets,
    setType,
    totalAport,
    type,
    updateValueTotal,
    setResults,
  } = useSimulation();
  //@ts-ignore
  const { showActive } = useActives();
  const [showAmountActives, setShowAmountActives] = useState(false);
  const [showActiveName, setShowActiveName] = useState(false);
  const [showActiveNameResults, setShowActiveNameResults] =
    useState<boolean>(false);

  useEffect(() => {
    setResults([]);
    setType(inicialTypeAporte)
  }, []);

  const header = [
    {
      title: (
        <>
          Ativo{' '}
          {showActiveName ? (
            <EyeSlash
              size={20}
              color="black"
              onClick={() => setShowActiveName(!showActiveName)}
            />
          ) : (
            <Eye
              size={20}
              color="black"
              onClick={() => setShowActiveName(!showActiveName)}
            />
          )}
        </>
      ),
      dataIndex: 'tickerSymbol',
      render: (value: string) => {
        if (showActive || showActiveName) {
          return value;
        } else {
          return '*****';
        }
      },
    },
    {
      title: (
        <>
          Quantidade{' '}
          {showAmountActives ? (
            <EyeSlash
              size={20}
              color="black"
              onClick={() => setShowAmountActives(!showAmountActives)}
            />
          ) : (
            <Eye
              size={20}
              color="black"
              onClick={() => setShowAmountActives(!showAmountActives)}
            />
          )}
        </>
      ),
      dataIndex: 'equitiesQuantity',
      render: (value: number) => {
        if (showActive || showAmountActives) {
          return validateFloatNumber(value.toFixed(2).replace('.', ','));
        } else {
          return '****';
        }
      },
    },
    {
      title: 'Patrimônio',
      dataIndex: 'updateValue',
      render: (text: number | string) => {
        if (showActive) {
          return transformMaskCurrency(Number(text.toString()));
        }
        return 'R$ ******';
      },
    },
    {
      title: 'Cotação',
      dataIndex: 'closingPrice',
      render: (text: number | string) =>
        transformMaskCurrency(Number(text.toString())),
    },
    {
      title: 'Objetivo',
      dataIndex: 'ValorExp',
      render: (text: number) => `${text?.toFixed(2)}%`,
    },
    {
      title: 'Atual',
      dataIndex: 'percent',
      render: (text: number) => `${text.toFixed(2)}%`,

      defaultSortOrder: 'ascend',
      //@ts-ignore
      sorter: (a, b) => a.percent - b.percent,
    },
  ];



  return (
    <Container>
      {results.length > 0 && (
        <>
          <br />
          <h5
            style={{
              background: '#FFF',
              width: '100%',
              padding: '1rem',
              borderRadius: '8px',
            }}
          >
            Dados da simulação
          </h5>
          <TableContainer component={Paper} sx={{ maxHeight: '300px' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  {headerResults.map((item) => (
                    <StyledTableCell key={item}>
                      {item}{' '}
                      {item === 'Qtd final' &&
                        (showAmountActives ? (
                          <Eye
                            size={20}
                            color="black"
                            onClick={() =>
                              setShowAmountActives(!showAmountActives)
                            }
                          />
                        ) : (
                          <EyeSlash
                            size={20}
                            color="black"
                            onClick={() =>
                              setShowAmountActives(!showAmountActives)
                            }
                          />
                        ))}
                      {item === 'Ativo' &&
                        (showActiveNameResults ? (
                          <Eye
                            size={20}
                            color="black"
                            onClick={() =>
                              setShowActiveNameResults(!showActiveNameResults)
                            }
                          />
                        ) : (
                          <EyeSlash
                            size={20}
                            color="black"
                            onClick={() =>
                              setShowActiveNameResults(!showActiveNameResults)
                            }
                          />
                        ))}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map((item) => (
                  <StyledTableRow key={item.key}>
                    <StyledTableCell
                      className="reduce"
                      style={item.aportarQtde !== 0 ? {} : { color: '#adacac' }}
                    >
                      <span
                        style={
                          item.aportarQtde !== 0 ? {} : { color: '#adacac' }
                        }
                      >
                        {showActiveNameResults || showActive
                          ? item.tickerSymbol
                          : '******'}
                      </span>
                      <br />
                      <strong title={item.corporationName}>
                        {item.corporationName
                          ? showActiveNameResults || showActive
                            ? item.corporationName
                            : '******'
                          : null}
                      </strong>
                    </StyledTableCell>

                    <StyledTableCell
                      style={item.aportarQtde !== 0 ? {} : { color: '#adacac' }}
                    >
                      {transformMaskCurrency(item.closingPrice)}
                    </StyledTableCell>
                    <StyledTableCell
                      style={item.aportarQtde !== 0 ? {} : { color: '#adacac' }}
                    >
                      <b>
                        {validateFloatNumber(
                          item.aportarQtde.toFixed(2).replace('.', ',')
                        )}
                      </b>
                    </StyledTableCell>
                    <StyledTableCell
                      style={item.aportarQtde !== 0 ? {} : { color: '#adacac' }}
                    >
                      <b> {transformMaskCurrency(item.moneyAporte)}</b>
                    </StyledTableCell>
                    <StyledTableCell
                      style={item.aportarQtde !== 0 ? {} : { color: '#adacac' }}
                    >
                      {showActive || showAmountActives
                        ? validateFloatNumber(
                            item.novaQuantidade.toFixed(2).replace('.', ',')
                          )
                        : '****'}
                    </StyledTableCell>
                    <StyledTableCell
                      className="reduce"
                      title={
                        showActive
                          ? transformMaskCurrency(item.updateValue)
                          : 'R$ *******'
                      }
                      style={item.aportarQtde !== 0 ? {} : { color: '#adacac' }}
                    >
                      {showActive
                        ? transformMaskCurrency(item.updateValue)
                        : 'R$ ******'}
                    </StyledTableCell>

                    <StyledTableCell
                      style={item.aportarQtde !== 0 ? {} : { color: '#adacac' }}
                    >
                      {item.target.toFixed(2)}%
                    </StyledTableCell>
                    <StyledTableCell
                      style={
                        Number(item.target.toFixed(2)) >
                        Number(item.newpercent.toFixed(2))
                          ? { background: '#edd1d1' }
                          : { background: '#d1f0d5' }
                      }
                    >
                      {item.newpercent.toFixed(2)}%
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
                <StyledTableRow>
                  <StyledTableCell colSpan={7}>
                    <b>Total aporte</b>
                  </StyledTableCell>
                  <StyledTableCell>
                    <b>{showActive ? totalAport : 'R$ *****'}</b>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell colSpan={7}>
                    <b>Total</b>
                  </StyledTableCell>
                  <StyledTableCell>
                    <b>
                      {showActive
                        ? transformMaskCurrency(updateValueTotal)
                        : 'R$ *****'}
                    </b>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      <ContentFilters>
        <div className="filter">
          <label>
            <input
              type="radio"
              name="sendAllAssets"
              value="selected"
              //@ts-ignore
              onChange={(e) => setSendAllAssets(e.target.value)}
              checked={sendAllAssets === 'selected'}
            />
            Ativos abaixo do objetivo
          </label>
          <label>
            <input
              type="radio"
              name="sendAllAssets"
              value="all"
              //@ts-ignore
              onChange={(e) => setSendAllAssets(e.target.value)}
              checked={sendAllAssets === 'all'}
            />
            Todos os ativos
          </label>
        </div>

        <div className="filter">
          <label>
            <input
              type="radio"
              name="selecteType"
              value="all"
              //@ts-ignore
              onChange={(e) => setType(e.target.value)}
              checked={type === 'all'}
            />
            Todos
          </label>
          <label>
            <input
              type="radio"
              name="selecteType"
              value="action"
              //@ts-ignore
              onChange={(e) => setType(e.target.value)}
              checked={type === 'action'}
            />
            Ação
          </label>
          <label>
            <input
              type="radio"
              name="selecteType"
              value="fii"
              //@ts-ignore
              onChange={(e) => setType(e.target.value)}
              checked={type === 'fii'}
            />
            FIIs
          </label>
        </div>
      </ContentFilters>

      {type === 'action' ? (
        actions.length !== 0 ? (
          <TableAntd
            rowSelection={{
              type: 'checkbox',
              ...rowSelectionAction,
            }}
            //@ts-ignore
            columns={header}
            dataSource={actions}
            pagination={false}
            scroll={{
              x: '100vh',
              y: '300px',
            }}
          />
        ) : (
          <h1 style={{ fontSize: '1rem', margin: '1rem 0.5rem' }}>
            Ainda não há dados
          </h1>
        )
      ) : type === 'fii' ? (
        fiis.length !== 0 ? (
          <TableAntd
            rowSelection={{
              type: 'checkbox',
              ...rowSelectionFii,
            }}
            //@ts-ignore
            columns={header}
            dataSource={fiis}
            pagination={false}
            scroll={{
              x: '100vh',
              y: '300px',
            }}
          />
        ) : (
          <h1 style={{ fontSize: '1rem', margin: '1rem 0.5rem' }}>
            Ainda não há dados
          </h1>
        )
      ) : all.length !== 0 ? (
        <TableAntd
          rowSelection={{
            type: 'checkbox',
            ...rowSelectionAll,
          }}
          //@ts-ignore
          columns={header}
          dataSource={all}
          pagination={false}
          scroll={{
            x: '100vh',
            y: '300px',
          }}
        />
      ) : (
        <h1 style={{ fontSize: '1rem', margin: '1rem 0.5rem' }}>
          Ainda não há dados
        </h1>
      )}
    </Container>
  );
}

import axios from 'axios';
import { useEffect, useState } from 'react';
import { ChartBar, ChartLineUp, Scales } from '@phosphor-icons/react';
import { Switch, Tooltip } from 'antd';
import { financeRoutes, walletRoutes } from '../../../../utils/RoutesApi';
import { useTitleAndUser } from '../../../../hooks/userTitle';
import { FormCreateRule } from './FormCreateRule';
import { PortfolioStrategy } from '../../Dashboard/PortfolioStrategy';
import { FormAporte } from './FormAporte';
import { CardCorretora } from '../../CardCorretora';
import { GraphicWalletActives } from './Graphic';
import { Rentabilidade } from './Rentabilidade';
import { useActives } from '../../../../hooks/useActives';
import { AllActivesTable } from './components/Tables/AllActivesTable';
import { TableLoader } from '../../LoadingModels';
import { TabComponent } from '../../../../components/TabComponent';
import { ButtonComponent } from '../../../../components/ButtonComponent';
import {
  Col,
  Container,
  ContainerBrokerages,
  ContentItemsFilterColumnTable,
  ContentTab,
  ContentTabCards,
  Row,
} from './MinhaCarteiraStyles';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { ModalDesigner } from '../../../../components/ModalDesigner';
import { baseURL } from '../../../../utils/baseURL';
import moment from 'moment';

export function MinhaCarteira() {
  let filterTable = localStorage.getItem('filterTableAll-v1.0.0');
  filterTable = JSON.parse(filterTable);
  const { percentage } = useActives();
  const { setTitle, user } = useTitleAndUser();
  const [tab, setTab] = useState(1);
  const [loadingWallet, setLoadingWallet] = useState(false);
  const [walletValues, setWalletValues] = useState({
    novoSumAcao: 0,
    novoSumFII: 0,
    positionAcao: [],
    positionFII: [],
    positionsAll: [],
    sumAcao: 0,
    sumsEFT: 0,
    sumsFII: 0,
    automatico: false,
    objetivoFII: 0,
    objetivoAcao: 0,
    objetivoAll: 0,
    rentabilidade: [],
  });
  const [showBrokerages, setShowBrokerages] = useState(false);
  const [brokerages, setBrokerages] = useState([]);
  const [showGraphic, setShowGraphic] = useState(false);
  const [filterBrokerage, setFilterBrokerage] = useState('');
  const [showModalCreateRule, setShowModalCreateRule] = useState(false);
  const [typeWallet, setTypeWallet] = useState(false);
  const [rule, setRule] = useState({});
  const [showModalFilterColumnTable, setShowModalFilterColumnTable] =
    useState(false);
  const [updateDataWallet, setUpdateDataWallet] = useState({
    last_updated_imposto_renda: null,
    last_updated_movimentos: null,
    last_updated_valores_carteiras: null,
  });
  const [filterShowColumnTable, setFilterShowColumnTable] = useState(() => {
    if (filterTable === null) {
      return [
        'Ativo',
        'Tipo',
        'Nota',
        'Quant.',
        'Patrimônio',
        'Objetivo',
        'Atual',
        'Diferença',
      ];
    } else {
      return filterTable;
    }
  });
  const arrColumnTableData = [
    'Ativo',
    'Tipo',
    'Nota',
    'Quant.',
    'Patrimônio',
    'Objetivo',
    'Atual',
    'Diferença',
    'Lucro/Prejuízo',
    'Rentabilidade',
    'Preço médio',
    'Cotação',
  ];
  useEffect(() => {
    setShowBrokerages(false);
    setShowGraphic(false);
    getStrategyRule();
  }, [tab]);

  useEffect(() => {
    setTitle('Todos os ativos');
    handleGetBrokerages();
    getUpdateStatusWallet();
  }, [user.automatico]);

  useEffect(() => {
    localStorage.setItem(
      'filterTableAll-v1.0.0',
      JSON.stringify(filterShowColumnTable)
    );
  }, [filterShowColumnTable]);

  useEffect(() => {
    if (filterBrokerage !== '') {
      componentDidMount({ corretora: filterBrokerage });
    } else {
      componentDidMount();
    }
  }, [filterBrokerage, user.automatico, tab, typeWallet]);

  async function getUpdateStatusWallet() {
    try {
      const response = await axios.get(
        `${baseURL}${financeRoutes.FINANCE_UPDATE_WALLET_DATE}`
      );
      setUpdateDataWallet(response.data);
    } catch (err) {}
  }

  async function updateStrategyRule() {
    const editRule = !typeWallet;
    const data = {
      PorcentagemRecomendada: editRule,
    };
    await axios
      .patch(
        `${process.env.REACT_APP_API_URL}${walletRoutes.WALLET_DEFINED_RULE_STRATEGY}`,
        data
      )
      .then((response) => {
        setTypeWallet(response.data.PorcentagemRecomendada);
      })
      .catch((err) => {});
  }
  async function getStrategyRule() {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}${walletRoutes.WALLET_DEFINED_RULE_STRATEGY}`
      )
      .then((response) => {
        setTypeWallet(response.data.PorcentagemRecomendada);
      })
      .catch((err) => {});
  }

  async function componentDidMount(params) {
    setLoadingWallet(true);

    await axios
      .get(
        `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_POSITION}`,
        {
          params,
        }
      )
      .then(function (result) {
        let newDataArrayAction = result.data.positionAcao.map((item) => {
          const isExceptionAction = item.Exception.find(
            (excp) => excp.type === 'action'
          );
          if (!isExceptionAction) {
            item.ValorExp = result.data.objetivoAcao;
            return item;
          }
          return item;
        });

        let newDataArrayFii = result.data.positionFII.map((item) => {
          if (!item.Exception) {
            item.ValorExp = result.data.objetivoFII;
            return item;
          }
          return item;
        });

        newDataArrayAction = newDataArrayAction.filter(
          (item) => !item.reservadeemergencia
        );
        newDataArrayFii = newDataArrayFii.filter(
          (item) => !item.reservadeemergencia
        );

        let allActivesPosition = result.data.positionsAll.map((item) => {
          const isExceptionAll = item.Exception.find(
            (excp) => excp.type === 'all'
          );
          const custo =
            Number(item.PrecoMedio.toFixed(2)) * item.equitiesQuantity;
          item.lucro = Number(item.updateValue.toFixed(2)) - custo;
          if (typeWallet) {
            item.ValorExp = item.porcentagem_recomendada;
            item.gap = item.ValorExp - item.percent;

            return item;
          } else if (!isExceptionAll) {
            if (item.productTypeName === 'acao') {
              item.ValorExp = result.data.objetivoAll;
              item.gap = item.ValorExp - item.percent;

              return item;
            } else if (item.productTypeName === 'fii') {
              item.ValorExp = result.data.objetivoAll;
              item.gap = item.ValorExp - item.percent;

              return item;
            } else {
              item.ValorExp = result.data.objetivoAll;
              item.gap = item.ValorExp - item.percent;

              return item;
            }
          }
          item.ValorExp = isExceptionAll.value;
          item.gap = item.ValorExp - item.percent;
          return item;
        });

        allActivesPosition = allActivesPosition.filter(
          (item) => !item.reservadeemergencia
        );

        setWalletValues({
          ...result.data,
          positionFII: newDataArrayFii,
          positionAcao: newDataArrayAction,
          positionsAll: allActivesPosition,
        });
      });
    setLoadingWallet(false);
  }

  async function handleGetBrokerages() {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_BROKERAGES}`
      )
      .then(function (result) {
        let newResult = result.data.result.filter(
          (item) => item.corretora !== ''
        );
        newResult = newResult.filter((item) => item.sumcorretora > 0);

        setBrokerages(newResult);
      })
      .catch((err) => {});
  }

  const totalValueBrokerages = brokerages.reduce(
    (acc, value) => acc + value.sumcorretora,
    0
  );

  function handleSelectedColumnTable(value) {
    const isValueAllReadyExist = filterShowColumnTable.find(
      (item) => item === value
    );
    if (isValueAllReadyExist) {
      setFilterShowColumnTable((state) => {
        return state.filter((item) => item !== value);
      });
    } else {
      setFilterShowColumnTable((state) => {
        return [...state, value];
      });
    }
  }

  return (
    <>
      <Container wallet={brokerages.length > 0}>
        <ContentTabCards>
          <TabComponent
            title="Ativos"
            icon={<ChartLineUp />}
            active={tab === 1}
            onClick={() => setTab(1)}
          />
          <TabComponent
            title="Estratégia"
            icon={<ChartBar />}
            active={tab === 2}
            onClick={() => setTab(2)}
          />
          <TabComponent
            title="Simular aporte"
            icon={<Scales />}
            onClick={() => setTab(3)}
            active={tab === 3}
          />
        </ContentTabCards>

        <ContentTab tab={tab}>
          <ButtonComponent
            variant="link"
            onClick={() => setShowBrokerages(!showBrokerages)}
          >
            {showBrokerages ? 'Esconder corretoras' : 'Exibir corretoras'}
          </ButtonComponent>
          <ButtonComponent
            variant="link"
            onClick={() => setShowGraphic(!showGraphic)}
          >
            Visualizar gráficos
          </ButtonComponent>
          <Tooltip
            title={
              typeWallet
                ? 'A estratégia a ser considerada é aquela que você definiu, com % para a Renda Fixa e Renda Variável'
                : 'O sistema vai considerar automaticamente a mesma % para todos os ativos da carteira (nesse caso você pode criar regras de exceção clicando no cadeado em cada ativo e modificando somente a % dele)'
            }
            color="rgba(21,21,21,0.8)"
            placement="bottom"
            overlayInnerStyle={{ borderRadius: 6 }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem',
              }}
            >
              <Switch
                checked={typeWallet}
                onClick={updateStrategyRule}
                checkedChildren="ON"
                unCheckedChildren="OFF"
              />
              <span
                style={{
                  fontSize: '1rem',
                  color: 'black',
                }}
              >
                Objetivo Recomendado
              </span>
            </div>
          </Tooltip>
        </ContentTab>
        {brokerages.length > 0 && (
          <ContainerBrokerages show={showBrokerages}>
            {brokerages.length > 1 && (
              <CardCorretora
                nameBroken="Todas as corretoras"
                patrimony={Number(totalValueBrokerages.toFixed(2))}
                onClick={() => setFilterBrokerage('')}
                active={filterBrokerage === ''}
              />
            )}
            {brokerages.map((item) => (
              <CardCorretora
                key={item.corretora}
                onClick={() =>
                  brokerages.length > 1 && setFilterBrokerage(item.corretora)
                }
                nameBroken={item.corretora}
                patrimony={item.sumcorretora}
                active={filterBrokerage === item.corretora}
              />
            ))}
          </ContainerBrokerages>
        )}

        {showGraphic ? (
          <>
            <Row>
              <Graphic
                totalPercent={percentage}
                title="Distribuição da carteira"
                active="total"
              />
              {walletValues.rentabilidade && (
                <Rentabilidade
                  title="Rentabilidade"
                  rentabilidade={walletValues.rentabilidade}
                />
              )}
            </Row>
            <br />
          </>
        ) : null}

        {tab === 1 && (
          <Col warning={walletValues.message !== ''}>
            {updateDataWallet.last_updated_valores_carteiras !== null && (
              <strong>
                Ultima atualização:{' '}
                {moment(updateDataWallet.last_updated_valores_carteiras).format(
                  'L'
                )}
              </strong>
            )}
            {loadingWallet ? (
              <TableLoader />
            ) : (
              <AllActivesTable
                setRule={setRule}
                setShowModalCreateRule={setShowModalCreateRule}
                setWalletValues={setWalletValues}
                walletValues={walletValues}
                filterBrokerage={filterBrokerage}
                filterShowColumnTable={filterShowColumnTable}
                setShowModalFilterColumnTable={setShowModalFilterColumnTable}
                typeWallet={typeWallet}
              />
            )}
          </Col>
        )}
        {tab === 2 && <PortfolioStrategy />}
        {tab === 3 && (
          <FormAporte
            type={filterBrokerage === '' ? 'all' : 'broker'}
            wallet={walletValues}
            filterBroker={filterBrokerage}
            typeWallet={typeWallet}
          />
        )}
      </Container>
      {showModalCreateRule && (
        <FormCreateRule
          rule={rule}
          setShowModalCreateRule={setShowModalCreateRule}
          showModalCreateRule={showModalCreateRule}
          update={() => {
            if (filterBrokerage !== '') {
              componentDidMount({ corretora: filterBrokerage });
            } else {
              componentDidMount();
            }
          }}
        />
      )}
      <ModalDesigner
        id="selecionarColunas"
        isOpen={showModalFilterColumnTable}
        setState={setShowModalFilterColumnTable}
        title="Editar campos da tabela"
        size="sm"
      >
        <ContentItemsFilterColumnTable>
          {arrColumnTableData.map((item) => (
            <label key={item}>
              <input
                type="checkbox"
                value={item}
                defaultChecked={filterShowColumnTable.includes(item)}
                onChange={(e) => handleSelectedColumnTable(e.target.value)}
              />
              {item}
            </label>
          ))}
        </ContentItemsFilterColumnTable>
      </ModalDesigner>
    </>
  );
}

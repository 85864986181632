export function editContractsData(periodo: string) {
  if (periodo === 'AteTresMeses') {
    periodo = '0 a 3 meses';

    return periodo;
  } else if (periodo === 'TresAteSeisMeses') {
    periodo = '3 a 6 meses';
    return periodo;
  } else if (periodo === 'SeisAteNoveMeses') {
    periodo = '6 a 9 meses';
    return periodo;
  } else if (periodo === 'NoveAteDozeMeses') {
    periodo = '9 a 12 meses';

    return periodo;
  } else if (periodo === 'DozeAteQuinzeMeses') {
    periodo = '12 a 15 meses';

    return periodo;
  } else if (periodo === 'QuinzeAteDezoitoMeses') {
    periodo = '15 a 18 meses';

    return periodo;
  } else if (periodo === 'DezoitoAteVinteUmMeses') {
    periodo = '18 a 21 meses';

    return periodo;
  } else if (periodo === 'VinteUmAteVinteQuatroMeses') {
    periodo = '21 a 24 meses';

    return periodo;
  } else if (periodo === 'VinteQuatroAteVinteSeteMeses') {
    periodo = '24 a 27 meses';

    return periodo;
  } else if (periodo === 'VinteSeteAteTrintaMeses') {
    periodo = '27 a 30 meses';

    return periodo;
  } else if (periodo === 'TrintaAteTrintaTresMeses') {
    periodo = '30 a 33 meses';

    return periodo;
  } else if (periodo === 'TrintaTresAteTrintaSeisMeses') {
    periodo = '33 a 36 meses';

    return periodo;
  } else if (periodo === 'AcimaTrintaSeisMeses') {
    periodo = 'acima de 36 meses';

    return periodo;
  } else if (periodo === 'PrazoIndeterminado') {
    periodo = 'Prazo indefinido';
  }
  return periodo;
}

import { useEffect, useState } from 'react';
import { Dropdown, Menu, Table as TableAntd, Tooltip } from 'antd';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Eye, EyeSlash, Info } from '@phosphor-icons/react';
import ReactLoading from 'react-loading';

import { walletRoutes } from '../../../../../utils/RoutesApi';
import { handleToast } from '../../../../../components/Toast';
import { Input } from '../../../../../components/Input';
import { ButtonLoading } from '../../../../../components/ButtonLoading';
import { GraphicWalletActives } from '../Graphic';
import { useActives } from '../../../../../hooks/useActives';
import { IPropsException } from '../../../../../utils/@types/wallet';
import { validateFloatNumber } from '../../ValidateFloatNumber';
import { transformMaskCurrency } from '../../../../../utils/transformMaskCurrency';
import { Container, ContentForm } from './styles';
import { ButtonDesign } from '../../../../../components/ButtonDesign';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#FFF',
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const headerResults = [
  'Ativo',
  'Cotação',
  'Aporte',
  'Valor Aporte',
  'Qtd final',
  'Novo Patrimônio',
  'Objetivo',
  '%Simulada ',
];

interface IPropsActive {
  Exception: IPropsException[];
  ValorExp: number;
  corporationName: string;
  novaQuantidade: number;
  aportarQtde: number;
  closingPrice: number;
  equitiesQuantity: number;
  novoupdateValue: number;
  percent: number;
  tickerSymbol: string;
  updateValue: number;
  key: number;
  target: number;
  newpercent: number;
  totalPercent: number;
  moneyAporte: number;
  finalPercent: number;
  productTypeName: string;
  porcentagem_recomendada: number;
}

interface IPropsWallet {
  novoSumAcao: number;
  novoSumFII: number;
  positionAcao: IPropsActive[];
  positionFII: IPropsActive[];
  positionsAll: IPropsActive[];
  sumAcao: number;
  sumsEFT: number;
  sumsFII: number;
  automatico: false;
  objetivoFII: number;
  objetivoAcao: number;
  objetivoAll: number;
  objetivoAllFII: number;
  objetivoAllRenda: number;
  objetivoAllAcao: number;
}

interface IPropsOthers {
  Exception: boolean;
  ValorExp: number;
  aportarQtde: number;
  closingPrice: number;
  corporationName: string;
  equitiesQuantity: number;
  novoupdateValue: number;
  percent: number;
  tickerSymbol: string;
  updateValue: number;
}

interface IPropsFormAporte {
  wallet: IPropsWallet;
  type: 'action' | 'fii' | 'all' | 'broker';
  othersAction?: IPropsOthers[];
  othersFii?: IPropsOthers[];
  showGraphic: boolean;
  filterBroker?: string;
  typeWallet?: boolean;
}

export function FormAporte({
  wallet,
  type,
  othersFii,
  othersAction,
  showGraphic,
  filterBroker,
  typeWallet = false,
}: IPropsFormAporte) {
  const { handleSubmit } = useForm();
  //@ts-ignore
  const { showActive } = useActives();

  const [actions, setActions] = useState<IPropsActive[]>([]);
  const [fiis, setFiis] = useState<IPropsActive[]>([]);
  const [all, setAll] = useState<IPropsActive[]>([]);
  const [selected, setSelected] = useState<IPropsActive[]>([]);
  const [selectedFiis, setSelectedFiis] = useState<IPropsActive[]>([]);
  const [selectedAll, setSelectedAll] = useState<IPropsActive[]>([]);
  const [results, setResults] = useState<IPropsActive[]>([]);
  const [updateValueTotal, setUpdateValue] = useState(0);
  const [valueAporte, seValueAporte] = useState<number | string>();
  const [sendAllAssets, setSendAllAssets] = useState<'all' | 'selected'>(
    'selected'
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [showAmountActives, setShowAmountActives] = useState(false);
  const [showActiveName, setShowActiveName] = useState(false);
  const [showActiveNameResults, setShowActiveNameResults] =
    useState<boolean>(false);
  const [totalAport, setTotalAport] = useState<string>('');

  useEffect(() => {
    if (wallet) {
      if (sendAllAssets === 'all') {
        if (type === 'all') {
          let newItem = wallet.positionsAll.map((item) => {
            let returnItem = item;

            let isRuleReadyExist = returnItem.Exception.find(
              (ex) => ex.type === 'all'
            );

            if (isRuleReadyExist) {
              returnItem.ValorExp = isRuleReadyExist.value;
            } else {
              if (returnItem.productTypeName === 'acao') {
                returnItem.ValorExp = wallet.objetivoAllAcao;
              } else if (returnItem.productTypeName === 'fii') {
                returnItem.ValorExp = wallet.objetivoAllFII;
              } else {
                returnItem.ValorExp = wallet.objetivoAllRenda;
              }
            }
            if (typeWallet) {
              returnItem.ValorExp = item.porcentagem_recomendada;
            }
            returnItem.key = Math.floor(Math.random() * (99999 - 1)) + 1;

            return returnItem;
          });

          setAll(newItem);
        }

        if (type === 'broker') {
          let newItem = wallet.positionsAll.map((item) => {
            let returnItem = item;

            let isRuleReadyExist = returnItem.Exception.find(
              (ex) => ex.corretora === filterBroker
            );
            if (isRuleReadyExist) {
              returnItem.ValorExp = isRuleReadyExist.value;
            } else {
              if (returnItem.productTypeName === 'acao') {
                returnItem.ValorExp = wallet.objetivoAllAcao;
              } else if (returnItem.productTypeName === 'fii') {
                returnItem.ValorExp = wallet.objetivoAllFII;
              } else {
                returnItem.ValorExp = wallet.objetivoAllRenda;
              }
            }
            if (typeWallet) {
              returnItem.ValorExp = item.porcentagem_recomendada;
            }
            returnItem.key = Math.floor(Math.random() * (99999 - 1)) + 1;

            return returnItem;
          });
          setAll(newItem);
        }

        if (type === 'fii') {
          let newItem = wallet.positionFII.map((item) => {
            let returnItem = item;

            let isRuleReadyExist = returnItem.Exception.find(
              (ex) => ex.type === 'fii'
            );
            if (isRuleReadyExist) {
              returnItem.ValorExp = isRuleReadyExist.value;
            } else {
              returnItem.ValorExp = wallet.objetivoFII;
            }
            returnItem.key = Math.floor(Math.random() * (99999 - 1)) + 1;

            return returnItem;
          });
          setFiis(newItem);
        }

        if (type === 'action') {
          let newItem = wallet.positionAcao.map((item) => {
            let returnItem = item;

            let isRuleReadyExist = returnItem.Exception.find(
              (ex) => ex.type === 'action'
            );
            if (isRuleReadyExist) {
              returnItem.ValorExp = isRuleReadyExist.value;
            } else {
              returnItem.ValorExp = wallet.objetivoAcao;
            }
            returnItem.key = Math.floor(Math.random() * (99999 - 1)) + 1;

            return returnItem;
          });

          setActions(newItem);
        }
      } else {
        if (type === 'all') {
          let newItem = wallet.positionsAll.map((item) => {
            let returnItem = item;

            let isRuleReadyExist = returnItem.Exception.find(
              (ex) => ex.type === 'all'
            );
            if (isRuleReadyExist) {
              returnItem.ValorExp = isRuleReadyExist.value;
            } else {
              if (returnItem.productTypeName === 'acao') {
                returnItem.ValorExp = wallet.objetivoAllAcao;
              } else if (returnItem.productTypeName === 'fii') {
                returnItem.ValorExp = wallet.objetivoAllFII;
              } else {
                returnItem.ValorExp = wallet.objetivoAllRenda;
              }
            }
            returnItem.key = Math.floor(Math.random() * (99999 - 1)) + 1;
            if (typeWallet) {
              returnItem.ValorExp = item.porcentagem_recomendada;
            }

            return returnItem;
          });

          newItem = newItem.filter((item) => item.percent < item.ValorExp);

          setAll(newItem);
        }
        if (type === 'action') {
          let newItem = wallet.positionAcao.map((item) => {
            let returnItem = item;

            let isRuleReadyExist = returnItem.Exception.find(
              (ex) => ex.type === 'action'
            );
            if (isRuleReadyExist) {
              returnItem.ValorExp = isRuleReadyExist.value;
            } else {
              returnItem.ValorExp = wallet.objetivoAcao;
            }
            returnItem.key = Math.floor(Math.random() * (99999 - 1)) + 1;

            return returnItem;
          });
          newItem = newItem.filter((item) => item.percent < item.ValorExp);

          setActions(newItem);
        }
        if (type === 'fii') {
          let newItem = wallet.positionFII.map((item) => {
            let returnItem = item;

            let isRuleReadyExist = returnItem.Exception.find(
              (ex) => ex.type === 'fii'
            );
            if (isRuleReadyExist) {
              returnItem.ValorExp = isRuleReadyExist.value;
            } else {
              returnItem.ValorExp = wallet.objetivoFII;
            }
            returnItem.key = Math.floor(Math.random() * (99999 - 1)) + 1;

            return returnItem;
          });
          newItem = newItem.filter((item) => item.percent < item.ValorExp);

          setFiis(newItem);
        }
        if (type === 'broker') {
          let newItem = wallet.positionsAll.map((item) => {
            let returnItem = item;

            let isRuleReadyExist = returnItem.Exception.find(
              (ex) => ex.corretora === filterBroker
            );
            if (isRuleReadyExist) {
              returnItem.ValorExp = isRuleReadyExist.value;
            } else {
              returnItem.ValorExp = wallet.objetivoAll;
            }
            returnItem.key = Math.floor(Math.random() * (99999 - 1)) + 1;
            if (typeWallet) {
              returnItem.ValorExp = item.porcentagem_recomendada;
            }
            return returnItem;
          });
          newItem = newItem.filter((item) => item.percent < item.ValorExp);

          setAll(newItem);
        }
      }
    }
  }, [wallet, sendAllAssets]);

  useEffect(() => {
    rowSelectionAction.onChange(
      actions.map((item) => item.key),
      actions
    );
    rowSelectionFii.onChange(
      fiis.map((item) => item.key),
      fiis
    );
    rowSelectionAll.onChange(
      all.map((item) => item.key),
      all
    );
  }, [fiis, actions, all, sendAllAssets]);

  const header = [
    {
      title: (
        <>
          Ativo{' '}
          {showActiveName ? (
            <EyeSlash
              size={20}
              color="black"
              onClick={() => setShowActiveName(!showActiveName)}
            />
          ) : (
            <Eye
              size={20}
              color="black"
              onClick={() => setShowActiveName(!showActiveName)}
            />
          )}
        </>
      ),
      dataIndex: 'tickerSymbol',
      render: (value: string) => {
        if (showActive || showActiveName) {
          return value;
        } else {
          return '*****';
        }
      },
    },
    {
      title: (
        <>
          Quantidade{' '}
          {showAmountActives ? (
            <EyeSlash
              size={20}
              color="black"
              onClick={() => setShowAmountActives(!showAmountActives)}
            />
          ) : (
            <Eye
              size={20}
              color="black"
              onClick={() => setShowAmountActives(!showAmountActives)}
            />
          )}
        </>
      ),
      dataIndex: 'equitiesQuantity',
      render: (value: number) => {
        if (showActive || showAmountActives) {
          return validateFloatNumber(value.toFixed(2).replace('.', ','));
        } else {
          return '****';
        }
      },
    },
    {
      title: 'Patrimônio',
      dataIndex: 'updateValue',
      render: (text: number | string) => {
        if (showActive) {
          return transformMaskCurrency(Number(text.toString()));
        }
        return 'R$ ******';
      },
    },
    {
      title: 'Cotação',
      dataIndex: 'closingPrice',
      render: (text: number | string) =>
        transformMaskCurrency(Number(text.toString())),
    },
    {
      title: 'Objetivo',
      dataIndex: 'ValorExp',
      render: (text: number) => `${text?.toFixed(2)}%`,
    },
    {
      title: 'Atual',
      dataIndex: 'percent',
      render: (text: number) => `${text.toFixed(2)}%`,

      defaultSortOrder: 'ascend',
      //@ts-ignore
      sorter: (a, b) => a.percent - b.percent,
    },
  ];

  const rowSelectionAction = {
    //@ts-ignore

    onChange: (selectedRowKeys, selectedRows) => {
      setSelected(selectedRows);
    },
    selectedRowKeys: selected.map((item) => item.key),
  };
  const rowSelectionFii = {
    //@ts-ignore
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedFiis(selectedRows);
    },
    selectedRowKeys: selectedFiis.map((item) => item.key),
  };
  const rowSelectionAll = {
    //@ts-ignore
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedAll(selectedRows);
    },
    selectedRowKeys: selectedAll.map((item) => item.key),
  };

  async function submit(data: any) {
    setLoading(true);

    data.corretora = '';
    if (valueAporte) {
      data.aporte = valueAporte
        .toString()
        .replace(/\D/g, '')
        .replace(/(\d)(\d{2})$/, '$1.$2');
    }
    if (!data.aporte) {
      setLoading(false);
      return handleToast(true, 'Informe o valor do aporte');
    }
    if (type === 'action') {
      if (selected.length <= 0) {
        setLoading(false);

        return handleToast(true, 'Selecione pelo menos 1 ativo');
      }
      data.type = 'action';

      data.ativos = selected;
    } else if (type === 'fii') {
      if (selectedFiis.length <= 0) {
        setLoading(false);

        return handleToast(true, 'Selecione pelo menos 1 ativo');
      }
      data.ativos = selectedFiis;
      data.type = 'fii';
    }
    if (type === 'all' || type === 'broker') {
      if (type === 'broker') {
        data.type = 'broker';
        data.corretora = filterBroker;
      } else {
        data.type = 'all';
        data.corretora = '';
      }
      if (selectedAll.length <= 0) {
        setLoading(false);
        return handleToast(true, 'Selecione pelo menos 1 ativo');
      }
      data.ativos = selectedAll;
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}${walletRoutes.WALLET_SIMULATOR_ALL_SUBMISSION}`,
          data
        )
        .then((response) => {
          let totalUpdateValue = 0;
          response.data.data.map((item: IPropsActive) => {
            totalUpdateValue = item.updateValue + totalUpdateValue;
          });
          setUpdateValue(totalUpdateValue);

          const newData = response.data.data.map((item: IPropsActive) => {
            return {
              ...item,
              moneyAporte: item.aportarQtde * item.closingPrice,
            };
          });

          const reduceTotalAport = newData.reduce(
            (acc: number, item: IPropsActive) => {
              return acc + item.moneyAporte;
            },
            0
          );
          const maskTotalAport = transformMaskCurrency(reduceTotalAport);
          setTotalAport(maskTotalAport);

          setResults(newData);
          handleToast(false, 'Simulação concluída com sucesso');
          setLoading(false);

          return;
        })
        .catch((err) => {
          handleToast(true, err.response.data.message);
          setLoading(false);

          return;
        });
      return;
    }

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}${walletRoutes.WALLET_SIMULATOR_ALL_SUBMISSION}`,
        data
      )
      .then((response) => {
        let totalUpdateValue = 0;
        response.data.data.map((item: IPropsActive) => {
          totalUpdateValue = item.updateValue + totalUpdateValue;
        });

        const reduceTotalAport = response.data.data
          .map((item: IPropsActive) => {
            return {
              ...item,
              moneyAporte: item.aportarQtde * item.closingPrice,
            };
          })
          .reduce((acc: number, item: IPropsActive) => {
            return acc + item.moneyAporte;
          }, 0);

        const maskTotalAport = transformMaskCurrency(reduceTotalAport);
        setTotalAport(maskTotalAport);
        setUpdateValue(totalUpdateValue);
        setResults(
          response.data.data.map((item: IPropsActive) => {
            return {
              ...item,
              moneyAporte: item.aportarQtde * item.closingPrice,
            };
          })
        );
        handleToast(false, 'Simulação concluída com sucesso');
        setLoading(false);

        return;
      })
      .catch((err) => {
        handleToast(true, err.response.message);

        setLoading(false);

        return;
      });
  }

  return (
    <Container>
      <ContentForm>
        <form onSubmit={handleSubmit(submit)}>
          <div className="column">
            {/* <Dropdown
              overlay={
                <Menu
                  style={{
                    maxWidth: '300px',
                  }}
                  items={[
                    {
                      key: '1',
                      label: (
                     
                      ),
                    },
                  ]}
                />
              }
              placement="bottom"
            > */}
            <Tooltip
              placement="bottom"
              title={
                <p style={{ margin: 0 }}>
                  A função simular aporte serve para balancear sua carteira
                  conforme sua estratégia.
                  <br />
                  Para simular aporte basta clicar nos ativos que deseja na
                  tabela abaixo, depois inserir o valor do aporte desejado e
                  clicar em simular
                </p>
              }
            >
              <h1>
                Simular aporte <Info size={24} color="black" />
              </h1>
            </Tooltip>
            {/* </Dropdown> */}

            <div>
              <label>Valor do aporte</label>
              <Input
                mask="currency"
                onChange={(e: any) => seValueAporte(e.target.value)}
              />
              <small>R$</small>
            </div>
            <br />
            <div style={{ width: '100%' }}>
              <ButtonDesign
                style={{ width: 'calc(100% - 2rem - 4px)', maxWidth: 'none' }}
                type="submit"
                disabled={loading}
                size="btn-small"
              >
                {loading ? (
                  <ReactLoading type="balls" width={25} height={25} />
                ) : (
                  <>Simular</>
                )}
              </ButtonDesign>
            </div>
          </div>
          <div className="infoContent">
            <p>
              O valor da simulação do aporte é estimado para distribuir a
              carteira. O cálculo é realizado a partir do ativo com menor
              porcentagem.
            </p>
          </div>
        </form>
        {showGraphic && (
          <GraphicWalletActives
            active={type}
            title="Distribuição por ativo"
            othersFii={othersFii ?? []}
            othersAction={othersAction ?? []}
          />
        )}
      </ContentForm>

      {results.length > 0 && (
        <>
          <br />
          <h5
            style={{
              background: '#FFF',
              width: '100%',
              padding: '1rem',
              borderRadius: '8px',
            }}
          >
            Dados da simulação
          </h5>
          <TableContainer component={Paper} sx={{ maxHeight: '300px' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  {headerResults.map((item) => (
                    <StyledTableCell key={item}>
                      {item}{' '}
                      {item === 'Qtd final' &&
                        (showAmountActives ? (
                          <Eye
                            size={20}
                            color="black"
                            onClick={() =>
                              setShowAmountActives(!showAmountActives)
                            }
                          />
                        ) : (
                          <EyeSlash
                            size={20}
                            color="black"
                            onClick={() =>
                              setShowAmountActives(!showAmountActives)
                            }
                          />
                        ))}
                      {item === 'Ativo' &&
                        (showActiveNameResults ? (
                          <Eye
                            size={20}
                            color="black"
                            onClick={() =>
                              setShowActiveNameResults(!showActiveNameResults)
                            }
                          />
                        ) : (
                          <EyeSlash
                            size={20}
                            color="black"
                            onClick={() =>
                              setShowActiveNameResults(!showActiveNameResults)
                            }
                          />
                        ))}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map((item) => (
                  <StyledTableRow key={item.key}>
                    <StyledTableCell
                      style={item.aportarQtde !== 0 ? {} : { color: '#adacac' }}
                    >
                      <span
                        style={
                          item.aportarQtde !== 0 ? {} : { color: '#adacac' }
                        }
                      >
                        {showActiveNameResults || showActive
                          ? item.tickerSymbol
                          : '******'}
                      </span>
                      <br />
                      <strong title={item.corporationName}>
                        {item.corporationName
                          ? showActiveNameResults || showActive
                            ? item.corporationName
                            : '******'
                          : null}
                      </strong>
                    </StyledTableCell>

                    <StyledTableCell
                      style={item.aportarQtde !== 0 ? {} : { color: '#adacac' }}
                    >
                      {transformMaskCurrency(item.closingPrice)}
                    </StyledTableCell>
                    <StyledTableCell
                      style={item.aportarQtde !== 0 ? {} : { color: '#adacac' }}
                    >
                      <b>
                        {validateFloatNumber(
                          item.aportarQtde.toFixed(2).replace('.', ',')
                        )}
                      </b>
                    </StyledTableCell>
                    <StyledTableCell
                      style={item.aportarQtde !== 0 ? {} : { color: '#adacac' }}
                    >
                      <b> {transformMaskCurrency(item.moneyAporte)}</b>
                    </StyledTableCell>
                    <StyledTableCell
                      style={item.aportarQtde !== 0 ? {} : { color: '#adacac' }}
                    >
                      {showActive || showAmountActives
                        ? validateFloatNumber(
                            item.novaQuantidade.toFixed(2).replace('.', ',')
                          )
                        : '****'}
                    </StyledTableCell>
                    <StyledTableCell
                      className="reduce"
                      title={
                        showActive
                          ? transformMaskCurrency(item.updateValue)
                          : 'R$ *******'
                      }
                      style={item.aportarQtde !== 0 ? {} : { color: '#adacac' }}
                    >
                      {showActive
                        ? transformMaskCurrency(item.updateValue)
                        : 'R$ ******'}
                    </StyledTableCell>

                    <StyledTableCell
                      style={item.aportarQtde !== 0 ? {} : { color: '#adacac' }}
                    >
                      {item.target.toFixed(2)}%
                    </StyledTableCell>
                    <StyledTableCell
                      style={
                        Number(item.target.toFixed(2)) >
                        Number(item.newpercent.toFixed(2))
                          ? { background: '#edd1d1' }
                          : { background: '#d1f0d5' }
                      }
                    >
                      {item.newpercent.toFixed(2)}%
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
                <StyledTableRow>
                  <StyledTableCell colSpan={7}>
                    <b>Total aporte</b>
                  </StyledTableCell>
                  <StyledTableCell>
                    <b>{showActive ? totalAport : 'R$ *****'}</b>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell colSpan={7}>
                    <b>Total</b>
                  </StyledTableCell>
                  <StyledTableCell>
                    <b>
                      {showActive
                        ? transformMaskCurrency(updateValueTotal)
                        : 'R$ *****'}
                    </b>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      <div className="filter">
        <label>
          <input
            type="radio"
            name="sendAllAssets"
            value="selected"
            //@ts-ignore
            onChange={(e) => setSendAllAssets(e.target.value)}
            checked={sendAllAssets === 'selected'}
          />
          Ativos abaixo do objetivo
        </label>
        <label>
          <input
            type="radio"
            name="sendAllAssets"
            value="all"
            //@ts-ignore
            onChange={(e) => setSendAllAssets(e.target.value)}
            checked={sendAllAssets === 'all'}
          />
          Todos os ativos
        </label>
      </div>

      {type === 'action' ? (
        actions.length !== 0 ? (
          <TableAntd
            rowSelection={{
              type: 'checkbox',
              ...rowSelectionAction,
            }}
            //@ts-ignore
            columns={header}
            dataSource={actions}
            pagination={false}
            scroll={{
              x: '100vh',
              y: '300px',
            }}
          />
        ) : (
          <h1 style={{ fontSize: '1rem', margin: '1rem 0.5rem' }}>
            Ainda não há dados
          </h1>
        )
      ) : type === 'fii' ? (
        fiis.length !== 0 ? (
          <TableAntd
            rowSelection={{
              type: 'checkbox',
              ...rowSelectionFii,
            }}
            //@ts-ignore
            columns={header}
            dataSource={fiis}
            pagination={false}
            scroll={{
              x: '100vh',
              y: '300px',
            }}
          />
        ) : (
          <h1 style={{ fontSize: '1rem', margin: '1rem 0.5rem' }}>
            Ainda não há dados
          </h1>
        )
      ) : all.length !== 0 ? (
        <TableAntd
          rowSelection={{
            type: 'checkbox',
            ...rowSelectionAll,
          }}
          //@ts-ignore
          columns={header}
          dataSource={all}
          pagination={false}
          scroll={{
            x: '100vh',
            y: '300px',
          }}
        />
      ) : (
        <h1 style={{ fontSize: '1rem', margin: '1rem 0.5rem' }}>
          Ainda não há dados
        </h1>
      )}
    </Container>
  );
}

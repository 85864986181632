import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 500px !important;

  *::-webkit-scrollbar {
    display: block !important;
  }

  * {
    scrollbar-width: thin;
    scrollbar-color: ${(props) => props.theme.primary} #e3e3e3;
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-track {
    background: #e3e3e3;
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.primary};
    border-radius: 20px;
    border: 3px solid #e3e3e3;
  }
  button {
    all: unset;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    font-weight: 400;
    font-size: 0.875rem;
    color: ${(props) => props.theme['primary-800']};
    cursor: pointer;
  }
`;

export const ContentItemsFilterColumnTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .recommendText {
    font-weight: 400;
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
    color: #07bd12;
    background: #def7ec;
    width: 100%;
    max-width: 7.5rem;
    border-radius: 29px;
  }

  .addMore {
    font-size: 0.875rem;
    font-weight: 400;
    color: ${(props) => props.theme['gray-300']};
  }

  .contentInput {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    label {
      display: flex;
      gap: 0.5rem;
      font-size: 0.875rem;
      color: ${(props) => props.theme['gray-500']};
      margin: 0;
      font-weight: 400;
    }
  }

  .important {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
    color: ${(props) => props.theme['gray-500']};
    line-height: 100%;
  }

  .warning {
    font-weight: 400;
    font-size: 0.75rem;
    color: ${(props) => props.theme['white-800']};
    padding: 0.75rem;
    border: 1px solid ${(props) => props.theme['white-800']};
    border-radius: 12px;
    margin: 0;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 35rem;
  background: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme['card-shadow-2']};
  border: 2px solid ${(props) => props.theme.success};
  padding: 1rem;
  border-radius: 4px;
  margin: 0 1rem;

  svg {
    font-size: 5rem;
    color: ${(props) => props.theme.success};
    padding: 0.25rem;
    border: 1px solid ${(props) => props.theme.success};
    border-radius: 100%;
  }
  h1 {
    color: ${(props) => props.theme['gray-700']};
    margin: 0;
    font-size: 2rem;
  }
  p {
    color: ${(props) => props.theme['gray-700']};
    font-size: 1rem;
    margin: 0;

    span {
      font-size: 1rem;
      font-weight: 500;
    }
  }

  a {
    margin-top: 0.5rem;
    color: ${(props) => props.theme.white};
    background: ${(props) => props.theme.success};
    transition: filter 0.3s;
    padding: 0.5rem;
    border-radius: 50px;

    :hover {
      filter: brightness(0.9);
    }
  }
`;

export const FuncionalidadesContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;

  font-size: 1rem;
  color: ${(props) => props.theme['gray-700']};
`;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import ReactLoading from 'react-loading';
import moment from 'moment';
import { Container, HeaderDate } from './styles';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { GraficoProxProventos } from './Components/GraficoProxProventos';
import { financeRoutes } from '../../../../../utils/RoutesApi';
import { transformMaskCurrency } from '../../../../../utils/transformMaskCurrency';
import { EarningLoader } from '../../../../components/LoadingModels';
import { LinkB3Page } from '../../../../../social/pages/LinkB3Page';
import { InfinityScroll } from '../../../../../components/InfinityScroll';
import { NoDataB3 } from '../../../../../social/pages/Components/NoDataB3';
import { useNewTheme } from '../../../../../hooks/useNewTheme';
import { useTitleAndUser } from '../../../../../hooks/userTitle';
import { useActives } from '../../../../../hooks/useActives';

interface IPropsDataValue {
  approvalDate: string;
  corporateActionType: string;
  eventValue: number;
  paymentDate: Date | null;
  periodPaymentDate: string;
  quantidade: number;
  referenceDate: Date;
  tickerSymbol: string;
  total: number;
}

interface IPropsValues {
  count: number;
  current: number;
  data: IPropsDataValue[];
  links: {
    next: null | string;
    previous: string | null;
  };
  total_pages: number;
}

export function ProximosProventos() {
  const history = useHistory();
  //@ts-ignore
  const { isMobile } = useNewTheme();
  //@ts-ignore
  const { user } = useTitleAndUser();
  //@ts-ignore
  const { showActive } = useActives();

  const [values, setValues] = useState<IPropsValues>({
    count: 0,
    current: 0,
    data: [],
    links: { next: null, previous: null },
    total_pages: 1,
  });
  const [newLoading, setNewLoading] = useState(false);
  const [dateHeader, setDateHeader] = useState<Date[]>([]);
  const [loading, setLoading] = useState(false);
  const [showSelected, setShowSelected] = useState<Date[]>([]);
  const [totalValue, setTotalValue] = useState({});
  const [isValidatSrollWindow, setIsValidatSrollWindow] =
    useState<boolean>(false);
  useEffect(() => {
    function scrollPosition() {
      setIsValidatSrollWindow(window.scrollY >= 200);
    }

    window.addEventListener('scroll', scrollPosition);
    return () => {
      window.removeEventListener('scroll', scrollPosition);
    };
  }, [window.scroll]);

  useEffect(() => {
    handleCalendarProceeds();
  }, []);

  useEffect(() => {
    let total = dateHeader.map((data) => {
      //@ts-ignore
      let newTotal = totalValue[data]
        //@ts-ignore
        .map((item) => {
          return item.value;
        });

      newTotal = newTotal.reduce((acc: number, item: number) => acc + item, 0);

      return { data: data, value: newTotal };
    });

    //@ts-ignore
    total = total.reduce(
      //@ts-ignore
      (obj, item) => ((obj[item.data] = item.value), obj),
      {}
    );

    total = Object.assign({}, total);
    setTotalValue(total);
  }, [dateHeader]);

  async function handleCalendarProceeds() {
    // setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_PROCEEDS}`)
      .then((response) => {
        let ordValue = response.data.data.sort(function (
          a: IPropsDataValue,
          b: IPropsDataValue
        ) {
          //@ts-ignore
          if (a.paymentDate < b.paymentDate) {
            return -1;
          } else {
            return true;
          }
        });

        let newDateOrdem = [];
        let newDateTotalValue;

        if (ordValue.length > 0) {
          newDateOrdem = ordValue.map((item: IPropsDataValue) => {
            return item.paymentDate;
          });
          newDateTotalValue = ordValue.map((item: IPropsDataValue) => {
            if (item.corporateActionType === 'JUROS SOBRE CAPITAL PRÓPRIO') {
              return {
                data: item.paymentDate,
                value: Number(item.total) * 0.85,
              };
            } else {
              return { data: item.paymentDate, value: item.total };
            }
          });
          //@ts-ignore
          const groupByData = newDateTotalValue.reduce((group, day) => {
            const { data } = day;
            group[data] = group[data] ?? [];
            group[data].push(day);
            return group;
          }, {});
          setTotalValue(groupByData);
        }

        if (newDateOrdem.length > 0) {
          //@ts-ignore
          let RemoveDuplicateItems = [...new Set(newDateOrdem)];

          setDateHeader(
            RemoveDuplicateItems.filter(
              (date) => moment(date) >= moment() || date === null
            )
          );
        }

        setValues({
          ...response.data,
          data: response.data.data
            .map((item: IPropsDataValue) => {
              return {
                ...item,
              };
            })
            .filter(
              (item: IPropsDataValue) => moment(item.paymentDate) >= moment()
            ),
        });
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      })
      .catch((err) => {});
  }

  async function handleCalendarProceedsInfinity(link: string) {
    setNewLoading(false);

    await axios
      .get(link)
      .then((response) => {
        let ordValue = response.data.data.sort(function (
          a: IPropsDataValue,
          b: IPropsDataValue
        ) {
          //@ts-ignore
          if (a.paymentDate < b.paymentDate) {
            return -1;
          } else {
            return true;
          }
        });

        let newDateOrdem = [];
        let newDateTotalValue;

        if (ordValue.length > 0) {
          newDateOrdem = ordValue.map((item: IPropsDataValue) => {
            return item.paymentDate;
          });
          newDateTotalValue = ordValue.map((item: IPropsDataValue) => {
            return { data: item.paymentDate, value: item.total };
          });
          //@ts-ignore
          const groupByData = newDateTotalValue.reduce((group, day) => {
            const { data } = day;
            group[data] = group[data] ?? [];
            group[data].push(day);
            return group;
          }, {});
          setTotalValue({ ...totalValue, groupByData });
        }

        if (newDateOrdem.length > 0) {
          //@ts-ignore
          let RemoveDuplicateItems = [...new Set(newDateOrdem)];
          setDateHeader([
            ...dateHeader,
            ...RemoveDuplicateItems.filter(
              (date) => moment(date) >= moment() || date === null
            ),
          ]);
        }
        setValues({
          ...response.data,
          data: [
            ...values.data,
            response.data.data.filter(
              (item: IPropsDataValue) => moment(item.paymentDate) >= moment()
            ),
          ],
        });

        setNewLoading(true);
      })
      .catch((err) => {});
  }

  const handleChange = (value: Date) => {
    const valueIsAlreadyExist = showSelected.findIndex((val) => val === value);

    if (valueIsAlreadyExist === -1) {
      setShowSelected([...showSelected, value]);
    } else {
      const removeValueArray = showSelected.filter((item) => item !== value);

      setShowSelected(removeValueArray);
    }
  };

  function handleBackToTop() {
    window.scroll(0, 0);
  }

  const totalValueToReceive = transformMaskCurrency(
    //@ts-ignore
    Object.entries(totalValue)
      .map((item) => item[1])
      //@ts-ignore
      .reduce((acc, value) => acc + value, 0)
  );

  return loading ? (
    <EarningLoader />
  ) : !user.automatico ? (
    <div style={{
      marginTop: '10%',
      position: 'absolute', 
      width: '150%',
      height: '45vh',
      textAlign:'center',
      backgroundColor: '#FCFCFD',
      borderBottom:'1px solid #D4D6DB',
      borderRight: '1px solid #D4D6DB', 
      borderLeft: '1px solid #D4D6DB', 
      borderRadius: '8px',
    }}>
      <LinkB3Page />
    </div>
  ) : values.data.length > 0 ? (
    <>
      <Container>
        {isMobile && (
          <HeaderDate>
            <h3>Total a receber</h3>
            <div>
              <span style={{ width: '100%' }}>
                {showActive ? totalValueToReceive : 'R$ ******'}
              </span>
            </div>
          </HeaderDate>
        )}
        <GraficoProxProventos proceeds={values.data} />
        {dateHeader.map((data, i) => (
          <React.Fragment key={i}>
            <Box
              sx={
                showSelected.includes(data)
                  ? {
                      height: 'auto',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '1rem',
                    }
                  : {
                      height: '40px',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '1rem',
                    }
              }
              key={i}
              component="div"
            >
              <HeaderDate onClick={() => handleChange(data)}>
                <h3>
                  {data === null
                    ? 'Data indefinida'
                    : moment(data).format('LL')}
                </h3>
                <div>
                  <span style={{ width: '100%' }}>
                    Total:{' '}
                    {showActive
                      ? //@ts-ignore
                        transformMaskCurrency(totalValue[data])
                      : 'R$ ******'}
                  </span>

                  {showSelected.includes(data) ? (
                    <CaretUp size={24} />
                  ) : (
                    <CaretDown size={24} />
                  )}
                </div>
              </HeaderDate>
              {showSelected.includes(data) && (
                <Box
                  sx={{
                    display: 'grid',
                    'grid-template-columns':
                      'repeat(auto-fit, minmax(16rem, 1fr))',
                    gap: '1rem',
                    width: '100%',
                  }}
                >
                  {values.data.map(
                    (item, index) =>
                      data === item.paymentDate &&
                      (index === 1 ? (
                        <Grow
                          in={showSelected.includes(data)}
                          className="cardShowValue"
                          key={index}
                        >
                          <div>
                            <h3 style={{ cursor: 'pointer' }}>
                              {showActive
                                ? item.tickerSymbol.replace('.SA', '')
                                : '*******'}
                            </h3>

                            <p className="typeFormated">
                              Tipo:
                              <strong>{item.corporateActionType}</strong>
                            </p>
                            <p>
                              Data de aprovação:{' '}
                              <strong>
                                {moment(item.approvalDate).format('l')}
                              </strong>
                            </p>
                            <p>
                              Quantidade de ativos:{' '}
                              <strong>
                                {showActive ? item.quantidade : '*****'}
                              </strong>
                            </p>

                            <p>
                              Valor unitário:{' '}
                              <strong>R$ {item.eventValue.toFixed(4)}</strong>
                            </p>
                            <p>
                              Total:{' '}
                              <strong>
                                {showActive
                                  ? transformMaskCurrency(item.total)
                                  : 'R$ *****'}
                              </strong>
                            </p>
                            {item.corporateActionType ===
                              'JUROS SOBRE CAPITAL PRÓPRIO' && (
                              <p>
                                V.Líquido(-15%):{' '}
                                <strong>
                                  {showActive
                                    ? transformMaskCurrency(item.total * 0.85)
                                    : 'R$ *******'}
                                </strong>
                              </p>
                            )}
                          </div>
                        </Grow>
                      ) : (
                        <Grow
                          in={showSelected.includes(data)}
                          style={{ transformOrigin: '0 0 0' }}
                          {...(showSelected ? { timeout: 1000 } : {})}
                          className="cardShowValue"
                          key={index}
                        >
                          <div>
                            <h3 style={{ cursor: 'pointer' }}>
                              {showActive
                                ? item.tickerSymbol.replace('.SA', '')
                                : '******'}
                            </h3>

                            <p className="typeFormated">
                              Tipo: <strong>{item.corporateActionType}</strong>
                            </p>
                            <p>
                              Data de aprovação:{' '}
                              <strong>
                                {moment(item.approvalDate).format('l')}
                              </strong>
                            </p>
                            <p>
                              Quantidade de ativos:{' '}
                              <strong>
                                {showActive ? item.quantidade : '*****'}
                              </strong>
                            </p>

                            <p>
                              Valor unitário:{' '}
                              <strong>R$ {item.eventValue.toFixed(4)}</strong>
                            </p>
                            <p>
                              Total:{' '}
                              <strong>
                                {showActive
                                  ? transformMaskCurrency(item.total)
                                  : 'R$ *******'}
                              </strong>
                            </p>
                            {item.corporateActionType ===
                              'JUROS SOBRE CAPITAL PRÓPRIO' && (
                              <p>
                                V.Líquido(-15%):{' '}
                                <strong>
                                  {showActive
                                    ? transformMaskCurrency(item.total * 0.85)
                                    : 'R$ *******'}
                                </strong>
                              </p>
                            )}
                          </div>
                        </Grow>
                      ))
                  )}
                </Box>
              )}
            </Box>
            {!showSelected.includes(data) && <div />}
          </React.Fragment>
        ))}

        {values.data.length === values.count && isValidatSrollWindow && (
          <>
            {isMobile && (
              <>
                <br />
                <br />
                <br />
              </>
            )}
            <div className="finalList" onClick={handleBackToTop}>
              <h3>Voltar ao topo</h3>
            </div>
          </>
        )}
        {newLoading && (
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            {' '}
            <ReactLoading color="var(--primary)" type="spin" />{' '}
          </div>
        )}
        {newLoading && values.data.length < values.count && (
          <InfinityScroll
            //@ts-ignore
            fetchMore={() =>
              //@ts-ignore
              handleCalendarProceedsInfinity(values.links.next)
            }
          />
        )}
      </Container>
    </>
  ) : (
    <div style={{
      marginTop: '10%',
      position: 'absolute', 
      width: '145%',
      textAlign:'center',
    }}>
      <NoDataB3 />
    </div>
  );
}

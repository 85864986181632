import axios from 'axios';
import { useEffect, useState } from 'react';
import { userRoutes } from '../../../../../../../../utils/RoutesApi';
import { IPropsPlans } from '../../../components/@types';
import { CardPlan } from '../../../components/CardPlan';
import { Container, ContentPlansList } from './plansStyle';

const baseURL = process.env.REACT_APP_API_URL;

interface IPropsPlansFormIR {
  updatePlan: () => void;
}

export function PlansFormIR({ updatePlan }: IPropsPlansFormIR) {
  const [plans, setPlans] = useState<IPropsPlans[]>([]);
  useEffect(() => {
    getPlans();
  }, []);

  async function getPlans() {
    await axios
      .get(`${baseURL}${userRoutes.PLANS_IR}`)
      .then((response) => {
        setPlans(response.data);
      })
      .catch((err) => {});
  }

  return (
    <Container>
      <ContentPlansList>
        {plans.map((plan) => (
          <CardPlan key={plan.id} plan={plan} updatePlan={() => updatePlan()} />
        ))}
      </ContentPlansList>
    </Container>
  );
}

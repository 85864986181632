import axios from 'axios';
import { useEffect, useState } from 'react';
//@ts-ignore
import Select from 'react-select';
import ReactLoading from 'react-loading';
import { financeRoutes } from '../../../../utils/RoutesApi';

import {
  AddNewActiveButton,
  CardSelectedActive,
  Container,
  Content,
  ContentCard,
  SelectActive,
} from './styles';
import { IPropsRankActive } from '../@types/listActiveType';
import { NewTableCompareAssets } from './components/NewTableCompareAssets';
import { CardSelectedGraphicActive } from './components/CardSelectedActive';
import { Plus } from '@phosphor-icons/react';
import './styles.css';

import { ModalDesigner } from '../../../../components/ModalDesigner';
import { AsideFiltersCompareAssets } from './components/AsideFiltersCompareAssets';
import { SideModal } from '../../../../components/SideModal';

export interface IPropsActivesLabel {
  label: string;
  value: string;
}

interface Active {
  Asset: string;
  Mercado: string;
  NomeAtivo: string;
  segmento: string;
  setor: string;
  sub_setor: string;
}

interface IPropsvalueGraphicData {
  Ano: number;
  value: number;
}

export interface IPropsGraphicData {
  consolidado: IPropsvalueGraphicData[];
  dividaLiquida: IPropsvalueGraphicData[];
  dividaLIquidaEbitdaArr: IPropsvalueGraphicData[];
  dyArr: IPropsvalueGraphicData[];
  ebitdaArr: IPropsvalueGraphicData[];
  individualizado: IPropsvalueGraphicData[];
  lucroLiquidoArr: IPropsvalueGraphicData[];
  roeArr: IPropsvalueGraphicData[];
  asset: string;
  segmento: string;
}

interface IPropsCompareAssets {
  isComponent?: boolean;
  defaultAsset?: IPropsActivesLabel;
}

interface IPropsFilters {
  sectors: string[];
  subSectors: string[];
  segments: string[];
}
interface IPropsFilterSelected {
  sector: string;
  subSector: string;
  segment: string;
}

export function CompareAssets({
  isComponent = false,
  defaultAsset,
}: IPropsCompareAssets) {
  const [actives, setActives] = useState<IPropsActivesLabel[]>([]);
  const [filters, setFilters] = useState<IPropsFilters>({
    sectors: [],
    subSectors: [],
    segments: [],
  });
  const [selectedFilters, setSelectedFilters] = useState<IPropsFilterSelected>({
    sector: '',
    subSector: '',
    segment: '',
  });
  const [loading, setLoading] = useState(false);
  const [selectedActives, setSelectedActives] = useState<IPropsActivesLabel[]>(
    () => {
      if (isComponent && defaultAsset) {
        return [defaultAsset];
      }
      return [];
    }
  );
  const [companiesInfos, setCompaniesInfos] = useState<IPropsRankActive[]>([]);
  const [modalFilter, setModalFilter] = useState(false);
  const [dataGraphicsCompanies, setDataGraphicsCompanies] = useState<
    IPropsGraphicData[]
  >([]);
  const [showSelectedActive, setShowSelectedActive] = useState(false);

  useEffect(() => {
    getListFilters();
  }, []);

  useEffect(() => {
    let params = {
      setor: '',
      sub_setor: '',
      segmento: '',
      search: 'ACAO',
    };
    if (selectedFilters.sector !== '') {
      params.setor = selectedFilters.sector;
    }
    if (selectedFilters.subSector !== '') {
      params.sub_setor = selectedFilters.subSector;
    }
    if (selectedFilters.segment !== '') {
      params.segmento = selectedFilters.segment;
    }
    handleGetListActive(params);
  }, [selectedFilters]);

  useEffect(() => {
    handleGetActiveList();
  }, [selectedActives]);

  async function handleGetActiveList() {
    setLoading(true);

    for (var i = 0; selectedActives.length > i; i++) {
      let data = {
        lucroliquido: 0,
        roe: 0,
        ebitda: 0,
        dividendos: 0,
        dividaliquida: 0,
        dividaebitda: 0,
        cnpjativo: '',
        setor: '',
        segmento: '',
        nome_empresa: `${selectedActives[i].value.replace(' ', '')}`,
        subsetor: '',
        segmento_listagem: '',
      };
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_RANK_ACTIVES_LIST}`,
          data
        )
        .then((response) => {
          const validateAsset = companiesInfos.map((item) => item.Assets);
          if (!validateAsset.includes(selectedActives[i].value)) {
            setCompaniesInfos((state) => {
              return [
                ...state,
                {
                  ...response.data.data[0],
                  Assets: selectedActives[i].value.replace(' ', ''),
                },
              ];
            });
          }
        })
        .catch((err) => {});

      await axios
        .get(
          `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_FUNDAMENTALIST}`,
          {
            params: {
              ativo: `${selectedActives[i].value.replace(' ', '')}`,
            },
          }
        )
        .then((response) => {
          const validateAsset = companiesInfos.map((item) => item.Assets);

          if (!validateAsset.includes(selectedActives[i].value)) {
            let newData = {
              ...response.data,
              asset: selectedActives[i].value.replace(' ', ''),
            };

            //@ts-ignore
            newData.lucroLiquidoArr = newData.consolidado.map((item) => {
              return { value: item.LucroLiquido, Ano: item.Ano };
            });
            newData.lucroLiquidoArr = newData.lucroLiquidoArr.filter(
              //@ts-ignore
              (item) => item.value !== 0
            );

            //@ts-ignore
            newData.ebitdaArr = newData.consolidado.map((item) => {
              return { Ano: item.Ano, value: item.EBITDA };
            });
            newData.ebitdaArr = newData.ebitdaArr.filter(
              //@ts-ignore
              (item) => item.value !== 0
            );
            //@ts-ignore
            newData.dividaLiquida = newData.consolidado.map((item) => {
              return { Ano: item.Ano, value: item.DividaLiquida };
            });
            newData.dividaLiquida = newData.dividaLiquida.filter(
              //@ts-ignore
              (item) => item.value !== 0
            );
            //@ts-ignore
            newData.dividaLIquidaEbitdaArr = newData.consolidado.map((item) => {
              return { Ano: item.Ano, value: item.DividaLIquidaEbitda };
            });
            newData.dividaLIquidaEbitdaArr =
              newData.dividaLIquidaEbitdaArr.filter(
                //@ts-ignore
                (item) => item.value !== 0
              );
            //@ts-ignore
            newData.roeArr = newData.consolidado.map((item) => {
              return { Ano: item.Ano, value: item.ROE * 100 };
            });
            //@ts-ignore
            newData.roeArr = newData.roeArr.filter((item) => item.value !== 0);
            //@ts-ignore
            newData.dyArr = newData.consolidado.map((item) => {
              return { Ano: item.Ano, value: item.DY };
            });
            //@ts-ignore
            newData.dyArr = newData.dyArr.filter((item) => item.value !== 0);
            newData.segmento = newData.consolidado[0].Segmento;
            setDataGraphicsCompanies((state) => {
              return [...state, newData];
            });
          }
        })
        .catch((err) => {});
    }
    setLoading(false);
  }

  async function getListFilters(setor?: string) {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_ALL_ACTIVES_COMPARE}`,
        {
          params: {
            search: 'ACAO',
            setor,
          },
        }
      )
      .then((response) => {
        let sectorsFilter = response.data.map((item: Active) => {
          return item.setor;
        });

        sectorsFilter = sectorsFilter.filter(
          (item: string | null) => item !== null
        );
        //@ts-ignore
        sectorsFilter = [...new Set(sectorsFilter)];

        let segmentsFilter = response.data.map((item: Active) => {
          return item.segmento;
        });

        segmentsFilter = segmentsFilter.filter(
          (item: string | null) => item !== null
        );
        //@ts-ignore
        segmentsFilter = [...new Set(segmentsFilter)];

        let subSectorsFilter = response.data.map((item: Active) => {
          return item.sub_setor;
        });

        subSectorsFilter = subSectorsFilter.filter(
          (item: string | null) => item !== null
        );
        //@ts-ignore
        subSectorsFilter = [...new Set(subSectorsFilter)];

        setFilters({
          sectors: sectorsFilter,
          segments: segmentsFilter,
          subSectors: subSectorsFilter,
        });
      })
      .catch((error) => {});
  }

  async function handleGetListActive(params: {
    setor: string;
    sub_setor: string;
    segmento: string;
    search: string;
  }) {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_ALL_ACTIVES_COMPARE}`,
        {
          params,
        }
      )
      .then((response) => {
        let actions = response.data;
        actions = actions.map(
          (action: { Asset: string; Mercado: string; NomeAtivo: string }) => {
            let newObjSelect = {
              value: action.Asset,
              label: `${action.Asset} - ${action.NomeAtivo}`,
            };
            return newObjSelect;
          }
        );

        setActives(actions);
      })
      .catch((error) => {});
  }

  function handleRemoveActive(asset: string) {
    const newSelectedActives = selectedActives.filter(
      (active) => active.value !== asset
    );
    setSelectedActives(newSelectedActives);
    const newCompaniesInfos = companiesInfos.filter(
      (active) => active.Assets !== asset
    );
    const newDataGraphicsCompanies = dataGraphicsCompanies.filter(
      (active) => active.asset !== asset
    );
    setDataGraphicsCompanies(newDataGraphicsCompanies);

    setCompaniesInfos(newCompaniesInfos);
  }

  return (
    <Container>
      {window.screen.width > 1100 && (
        <AsideFiltersCompareAssets
          filters={filters}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
      )}
      <Content>
        <CardSelectedActive isComponent={isComponent}>
          <p>
            Para exibir os comparativos, é necessário que você selecione seus
            ativos para serem comparados.
          </p>
          {window.screen.width <= 1100 && (
            <div className="contentButtonFilter">
              <button
                className="filterButton"
                onClick={() => setModalFilter(true)}
              >
                Filtros
              </button>
            </div>
          )}

          {companiesInfos.length > 1 ? (
            <ContentCard>
              {companiesInfos.map((active) => (
                <CardSelectedGraphicActive
                  active={active}
                  key={active.Assets}
                  onRemoveItem={() => handleRemoveActive(active.Assets)}
                />
              ))}
              <AddNewActiveButton onClick={() => setShowSelectedActive(true)}>
                <i>
                  <Plus weight="bold" />
                </i>
                <span>Adicione um novo ativo</span>
              </AddNewActiveButton>
            </ContentCard>
          ) : (
            <ContentCard>
              {companiesInfos.length > 0 ? (
                <CardSelectedGraphicActive
                  active={companiesInfos[0]}
                  onRemoveItem={() =>
                    handleRemoveActive(companiesInfos[0].Assets)
                  }
                />
              ) : (
                <SelectActive onClick={() => setShowSelectedActive(true)}>
                  Selecione o primeiro ativo
                </SelectActive>
              )}{' '}
              X{' '}
              <SelectActive onClick={() => setShowSelectedActive(true)}>
                {' '}
                Selecione o segundo ativo
              </SelectActive>
            </ContentCard>
          )}
        </CardSelectedActive>

        {companiesInfos.length >= 2 ? (
          loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <ReactLoading
                type="balls"
                color="var(--primary)"
                height={300}
                width={300}
              />
            </div>
          ) : (
            <div className="contentTable">
              <NewTableCompareAssets
                actives={companiesInfos}
                dataGraphics={dataGraphicsCompanies}
              />
            </div>
          )
        ) : null}

        <ModalDesigner
          id="selectActive"
          isOpen={showSelectedActive}
          setState={setShowSelectedActive}
          size="sm"
        >
          <div style={{ height: '20rem', width: '100vw', maxWidth: '20rem' }}>
            <Select
              options={actives}
              placeholder="Busque o ativo"
              className="select"
              //@ts-ignore
              onChange={(e) => {
                // @ts-ignore
                setSelectedActives([...selectedActives, e]);
                setShowSelectedActive(false);
              }}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? '#0176E1' : 'transparent',
                  borderRadius: 8,
                }),
                container: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: ' 2px 2px 6px 0px #0000001A',
                  borderRadius: 8,
                }),
                input: (baseStyles) => ({
                  ...baseStyles,
                  color: '#808080',
                  fontSize: 16,
                }),
                placeholder: (base) => ({
                  ...base,
                }),
                valueContainer: (base) => ({
                  ...base,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 8,
                  paddingBottom: 8,
                }),
                indicatorSeparator: () => ({
                  display: 'none',
                }),

                dropdownIndicator: (base) => ({
                  ...base,
                  color: '#808080',
                }),
              }}
            />
          </div>
        </ModalDesigner>
      </Content>

      <SideModal id="sideFilter" isOpen={modalFilter} setState={setModalFilter}>
        <AsideFiltersCompareAssets
          filters={filters}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
      </SideModal>
    </Container>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 1rem;

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentVideo = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  iframe {
    border-radius: 16px;
  }
`;

export const DataContentVideo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 1rem;

  .title {
    line-height: 43px;
    width: 100%;

    h1 {
      font-size: 2.25rem;
      font-family: 'Poppins', sans-serif;
    }
  }
  section {
    display: flex;
    flex-direction: column;

    header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .userContent {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;

        img {
          object-fit: cover;
          border-radius: 100%;
          width: 52px;
          height: 52px;
        }
        h4 {
          margin: 0;
        }
      }
      .contentButton {
        display: flex;
        flex-direction: row;
        gap: 1rem;
      }
    }

    .content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 1rem;

      div {
        p {
          width: 150px;
        }
      }
    }
  }

  @media (max-width: 480px) {
    margin-top: 3rem;
    section {
      header {
        .contentButton {
          flex-direction: column;
        }
      }

      .content {
        flex-direction: column;
      }
    }
  }
  @media (max-width: 325px) {
    section {
      header {
        flex-direction: column-reverse;
        align-items: start;
        .contentButton {
          flex-direction: row;
          margin-bottom: 10px;
          margin-right: 1rem;
        }
      }
    }
  }
`;

interface IPropsButton {
  color: string;
  background: string;
}

export const Button = styled.button<IPropsButton>`
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 0.875rem;
  padding: 0 1rem;
  height: 40px;
  color: ${(props) => props.color};
  background: ${(props) => props.background};
  border: none;
  outline: none;
  border-radius: 8px;
  transition: filter 0.3s;
  :hover {
    filter: brightness(0.9);
  }
`;

export const ListVideos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  z-index: 1;
  background-color: ${(props) => props.theme.white};
  padding: 0.5rem 1rem;
  border-radius: 4px;

  header {
    h3 {
      font: 300 2rem 'Poppins', sans-serif;
    }
  }

  @media (max-width: 480px) {
    background: transparent;
  }
`;

export const ContentVideoList = styled.a`
  display: flex;
  flex-direction: row;
  gap: 5px;

  img {
    object-fit: cover;
    width: 120px;
    max-height: 80px;
    border-radius: 8px;
  }

  main {
    display: flex;
    flex-direction: column;

    .description {
      max-width: 15ch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .view {
      font-weight: 500;
      color: #4d4d4d;
    }
  }

  @media (max-width: 480px) {
    main {
      .description {
        max-width: 20ch;
      }
    }
  }
`;

export const ContainerComments = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NewComment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 1rem;
  width: 100%;
  margin-bottom: 2rem;

  img {
    object-fit: cover;
    width: 60px;
    height: 60px;

    border-radius: 100%;
  }

  form {
    width: 100%;

    textarea {
      width: 100%;
      min-height: 80px;
      border: 1px solid #d9d9d9;
      padding: 0.5rem;
      margin-bottom: 0.5rem;
      resize: none;
      border-radius: 8px;
    }
  }
`;
export const NoAvatar = styled.div`
  border: 1px solid ${(props) => props.theme.primary};
  background-color: ${(props) => props.theme.white};
  border-radius: 100%;
  width: 100%;
  max-width: 50px;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  strong {
    /* position: absolute; */
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
    text-align: center;
    /* left: 16px;
    top: 6px; */
    color: ${(props) => props.theme.primary};
  }
`;

export const Comments = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

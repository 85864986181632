import styled from 'styled-components';

export const TutoriaisContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 8px;
  border: 1px solid var(--Gray-gray-100, #D4D6DB);
  background: var(--Main-white, #FFF);
  padding: 40px;
`;

export const RowDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-between;
`;

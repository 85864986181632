import { useEffect, useState } from 'react';
import {
  ButtonTab,
  HeaderPageContent,
  RendaPassivaContainer,
  TabContent,
} from './styles';
import { useTitleAndUser } from '../../../hooks/userTitle';
import { CalendarioDeDividendos } from './Tabs/CalendarioDeDividendos';
import { ProventosRecebidos } from './Tabs/ProventosRecebidos';
import { ProximosProventos } from './Tabs/ProximosProventos';

export function RendaPassiva() {
  // @ts-ignore
  const { setTitle } = useTitleAndUser();
  const [tab, setTab] = useState(1);

  useEffect(() => {
    setTitle('Renda passiva');
  }, []);

  return (
    <RendaPassivaContainer>
      <HeaderPageContent>
        
        <TabContent>
          <ButtonTab
            onClick={() => {
              setTab(1);
            }}
            isActive={tab === 1}
          >
            Calendário de dividendos
          </ButtonTab>
          <ButtonTab
            onClick={() => {
              setTab(2);
            }}
            isActive={tab === 2}
          >
            Proventos recebidos
          </ButtonTab>
          <ButtonTab
            onClick={() => {
              setTab(3);
            }}
            isActive={tab === 3}
          >
            Próximos proventos
          </ButtonTab>
        </TabContent>
        <div className='filtros'>
          {tab === 1 && window.screen.width > 760 && <span>Filtros</span>}
        </div>
      </HeaderPageContent>
      {tab === 1 && <CalendarioDeDividendos />}
      {tab === 2 && <ProventosRecebidos />}
      {tab === 3 && <ProximosProventos />}
    </RendaPassivaContainer>
  );
}

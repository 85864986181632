import styled from 'styled-components';

interface IPropsContainerFooter {
  shortSideBar: boolean;
  title: string;
  isMobile: boolean;
  isBlackScreen?: boolean;
}

export const Container = styled.footer<IPropsContainerFooter>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) =>
    props.isBlackScreen ? props.theme['gray-700'] : props.theme.white};
  padding: 0.5rem 1rem;
  justify-content: center;
  padding-left: ${(props) =>
    props.isMobile ? '0' : props.shortSideBar ? '17rem' : '5rem'};
  transition: all 1s;
  p {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: ${(props) =>
      props.isBlackScreen ? props.theme.white : 'rgb(21, 21, 21)'};
    margin: 0;

    button {
      outline: none;
      border: none;
      background: transparent;
      color: #EC6643;
    }
  }
  a {
    color: #EC6643;
  }
  span {
    color: ${(props) => (props.isBlackScreen ? props.theme.white : 'black')};
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  .impostoTitle {
    margin: 0;
    margin-top: 2rem;
    font: 700 2rem 'Poppins', sans-serif;
    color: ${(props) =>
      props.isBlackScreen ? props.theme.white : props.theme['gray-700']};
  }
`;

import axios from 'axios';
import moment from 'moment';
import { FilePdf } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TableIncomeTax } from '../../components/TableIncomeTax';
import { generatorPDFTable } from './generatorPDFTables';
import { Container, ContentTable, TabContent } from './styles';
import { DarfGeneration } from '../../components/DarfGeneration';
import { ContentWarning } from '../AnnualStatement/styles';
import { IPropsIncomeFinalValue, IPropsIncomeTax } from '../../@types';
import { TransactionsIncomeTaxLoader } from '../../../../components/LoadingModels';
import { TabFilter } from '../../../../../components/TabFilter';
import { ButtonComponent } from '../../../../../components/ButtonComponent';
import { ModalDesigner } from '../../../../../components/ModalDesigner';
import { financeRoutes } from '../../../../../utils/RoutesApi';

const baseURL = process.env.REACT_APP_API_URL;

interface IPropsdataIncomeTax {
  IRValueAssets: IPropsIncomeFinalValue[];
  IRValueDayTrade: IPropsIncomeFinalValue[];
  IRValueFII: IPropsIncomeFinalValue[];
  assetsIR: IPropsIncomeTax[];
  dayTradeIR: IPropsIncomeTax[];
  fiiIR: IPropsIncomeTax[];
}

interface IPropsDateFilter {
  fiiIR: string[];
  assetsIR: string[];
  dayTradeIR: string[];
  IRValueAssets: string[];
  IRValueDayTrade: string[];
  IRValueFII: string[];
}

const headers = [
  {
    label: 'Mês ref',
    active: 'ReferenceDate',
  },
  {
    label: 'Lucro Líquido',
    active: 'Revenue',
  },

  {
    label: 'Total de vendas',
    active: 'GrossAmount',
  },
  {
    label: 'Imposto retido',
    active: 'Hold',
  },
  {
    label: 'Prejuízo acumulado',
    active: 'PrejuizoAcumulado',
  },
  {
    label: 'Base de cálculo IR',
    active: 'IR',
  },
  {
    label: 'Imposto à pagar',
    active: 'IRCalculado',
  },
  {
    label: 'Aux. Darf',
  },
];

export function StatementTables() {
  const { register, watch, setValue } = useForm();
  //@ts-ignore
  const [tab, setTab] = useState(4);
  const [dataIncomeTax, setDataIncomeTax] = useState<IPropsdataIncomeTax>({
    assetsIR: [],
    dayTradeIR: [],
    fiiIR: [],
    IRValueAssets: [],
    IRValueDayTrade: [],
    IRValueFII: [],
  });
  const [filterYears, setFilterYears] = useState<IPropsDateFilter>({
    assetsIR: [],
    dayTradeIR: [],
    fiiIR: [],
    IRValueAssets: [],
    IRValueDayTrade: [],
    IRValueFII: [],
  });
  const [showModalDarf, setShowModalDarf] = useState<boolean>(false);
  //@ts-ignore
  const [transaction, setTransaction] = useState<IPropsIncomeTax>({});
  const [loading, setLoading] = useState(false);
  const assetsIRValueFilter = watch('assetsIR');
  const dayTradeIRValueFilter = watch('dayTradeIR');
  const fiiIRValueFilter = watch('fiiIR');

  useEffect(() => {
    getIncomeTax();
  }, []);

  async function getIncomeTax() {
    setLoading(true);
    await axios
      .get(`${baseURL}${financeRoutes.FINANCE_INCOME_TAX}`)
      .then((response) => {
        let newData: IPropsdataIncomeTax = response.data;
        newData.IRValueAssets = newData.IRValueAssets.sort(
          (a: IPropsIncomeFinalValue, b: IPropsIncomeFinalValue) => {
            //@ts-ignore
            if (a.ReferenceDate < b.ReferenceDate) return 1;
            if (a.ReferenceDate > b.ReferenceDate) return -1;
            return 0;
          }
        );
        newData.IRValueDayTrade = newData.IRValueDayTrade.sort(
          (a: IPropsIncomeFinalValue, b: IPropsIncomeFinalValue) => {
            //@ts-ignore
            if (a.ReferenceDate < b.ReferenceDate) return 1;
            if (a.ReferenceDate > b.ReferenceDate) return -1;
            return 0;
          }
        );
        newData.IRValueFII = newData.IRValueFII.sort(
          (a: IPropsIncomeFinalValue, b: IPropsIncomeFinalValue) => {
            //@ts-ignore
            if (a.ReferenceDate < b.ReferenceDate) return 1;
            if (a.ReferenceDate > b.ReferenceDate) return -1;
            return 0;
          }
        );
        newData.assetsIR = newData.assetsIR.sort(
          (a: IPropsIncomeTax, b: IPropsIncomeTax) => {
            //@ts-ignore
            if (a.ReferenceDate < b.ReferenceDate) return 1;
            if (a.ReferenceDate > b.ReferenceDate) return -1;
            return 0;
          }
        );
        newData.dayTradeIR = newData.dayTradeIR.sort(
          (a: IPropsIncomeTax, b: IPropsIncomeTax) => {
            //@ts-ignore
            if (a.ReferenceDate < b.ReferenceDate) return 1;
            if (a.ReferenceDate > b.ReferenceDate) return -1;
            return 0;
          }
        );
        newData.fiiIR = newData.fiiIR.sort(
          (a: IPropsIncomeTax, b: IPropsIncomeTax) => {
            //@ts-ignore
            if (a.ReferenceDate < b.ReferenceDate) return 1;
            if (a.ReferenceDate > b.ReferenceDate) return -1;
            return 0;
          }
        );

        const yearFilter = {
          fiiIR: [
            //@ts-ignore
            ...new Set(
              newData.fiiIR.map(
                (item) => moment(item.ReferenceDate).format('l').split('/')[2]
              )
            ),
          ],

          assetsIR: [
            //@ts-ignore
            ...new Set(
              newData.assetsIR.map(
                (item) => moment(item.ReferenceDate).format('l').split('/')[2]
              )
            ),
          ],
          dayTradeIR: [
            //@ts-ignore
            ...new Set(
              newData.dayTradeIR.map(
                (item) => moment(item.ReferenceDate).format('l').split('/')[2]
              )
            ),
          ],
          IRValueAssets: [
            //@ts-ignore
            ...new Set(
              newData.IRValueAssets.map((item) => item.ReferenceDate.toString())
            ),
          ],
          IRValueDayTrade: [
            //@ts-ignore
            ...new Set(
              newData.IRValueDayTrade.map((item) =>
                item.ReferenceDate.toString()
              )
            ),
          ],
          IRValueFII: [
            //@ts-ignore
            ...new Set(
              newData.IRValueFII.map((item) => item.ReferenceDate.toString())
            ),
          ],
        };
        setValue('fiiIR', yearFilter.fiiIR[0]);
        setValue('dayTradeIR', yearFilter.dayTradeIR[0]);
        setValue('assetsIR', yearFilter.assetsIR[0]);
        setFilterYears(yearFilter);

        setDataIncomeTax(response.data);
      })
      .catch((err) => {});
    setLoading(false);
  }

  function handleGeneratorPDF() {
    if (tab === 4) {
      generatorPDFTable({
        title: 'Ações - Operações normais',
        data: dataIncomeTax.assetsIR,
        year: assetsIRValueFilter,
      });
    } else if (tab === 5) {
      generatorPDFTable({
        title: 'Ações - Operações daytrade',
        data: dataIncomeTax.dayTradeIR,
        year: dayTradeIRValueFilter,
      });
    } else if (tab === 6) {
      generatorPDFTable({
        title: 'Fundo imobiliario',
        data: dataIncomeTax.fiiIR,
        year: fiiIRValueFilter,
      });
    }
  }

  return loading ? (
    <TransactionsIncomeTaxLoader />
  ) : (
    <Container>
      <TabFilter>
        <TabContent>
          <div className="buttonContent">
            <strong>Ano: </strong>
            {tab === 4 && (
              <select
                {...register('assetsIR')}
                defaultValue={filterYears.assetsIR[0]}
                value={assetsIRValueFilter}
              >
                {filterYears.assetsIR
                  // .filter((item) => item !== moment().format('YYYY'))
                  .map((item) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
              </select>
            )}
            {tab === 5 && (
              <select
                {...register('dayTradeIR')}
                defaultValue={filterYears.dayTradeIR[0]}
                value={dayTradeIRValueFilter}
              >
                {filterYears.dayTradeIR
                  // .filter((item) => item !== moment().format('YYYY'))
                  .map((item) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
              </select>
            )}
            {tab === 6 && (
              <select
                {...register('fiiIR')}
                defaultValue={filterYears.fiiIR[0]}
                value={fiiIRValueFilter}
              >
                {' '}
                {filterYears.fiiIR
                  // .filter((item) => item !== moment().format('YYYY'))
                  .map((item) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
              </select>
            )}
            <strong>Tipos de operações: </strong>
            <select
              value={tab}
              onChange={(e) => setTab(Number(e.target.value))}
            >
              <option value={4}>Ações - Operações Normais</option>
              <option value={5}>Ações - Operações Daytrade</option>
              <option value={6}>Fundo imobiliário</option>
            </select>
          </div>

          <ButtonComponent
            onClick={handleGeneratorPDF}
            style={{
              borderRadius: '48px',
              padding: '.5rem 3rem',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <FilePdf size="1.2rem" color="#FFF" /> Gerar PDF
          </ButtonComponent>
        </TabContent>
      </TabFilter>

      {tab === 4 && (
        <ContentTable>
          <TableIncomeTax
            yearFilter={
              assetsIRValueFilter
                ? assetsIRValueFilter
                : filterYears.assetsIR[0]
            }
            data={dataIncomeTax.assetsIR}
            ASC={(newData: IPropsIncomeTax[]) =>
              setDataIncomeTax({
                ...dataIncomeTax,
                assetsIR: newData,
              })
            }
            DESC={(newData: IPropsIncomeTax[]) =>
              setDataIncomeTax({
                ...dataIncomeTax,
                assetsIR: newData,
              })
            }
            header={headers}
            handleGenerationDarf={(transaction: IPropsIncomeTax) => {
              setShowModalDarf(true);
              setTransaction(transaction);
            }}
          />
        </ContentTable>
      )}
      {tab === 5 && (
        <ContentTable>
          <TableIncomeTax
            yearFilter={
              dayTradeIRValueFilter
                ? dayTradeIRValueFilter
                : filterYears.dayTradeIR[0]
            }
            data={dataIncomeTax.dayTradeIR}
            ASC={(newData: IPropsIncomeTax[]) =>
              setDataIncomeTax({
                ...dataIncomeTax,
                dayTradeIR: newData,
              })
            }
            DESC={(newData: IPropsIncomeTax[]) =>
              setDataIncomeTax({
                ...dataIncomeTax,
                dayTradeIR: newData,
              })
            }
            header={headers}
            handleGenerationDarf={(transaction: IPropsIncomeTax) => {
              setShowModalDarf(true);
              setTransaction(transaction);
            }}
          />
        </ContentTable>
      )}
      {tab === 6 && (
        <ContentTable>
          <TableIncomeTax
            yearFilter={
              fiiIRValueFilter ? fiiIRValueFilter : filterYears.fiiIR[0]
            }
            data={dataIncomeTax.fiiIR}
            ASC={(newData: IPropsIncomeTax[]) =>
              setDataIncomeTax({
                ...dataIncomeTax,
                fiiIR: newData,
              })
            }
            DESC={(newData: IPropsIncomeTax[]) =>
              setDataIncomeTax({
                ...dataIncomeTax,
                fiiIR: newData,
              })
            }
            header={headers}
            handleGenerationDarf={(transaction: IPropsIncomeTax) => {
              setShowModalDarf(true);
              setTransaction(transaction);
            }}
          />
        </ContentTable>
      )}
      <ContentWarning>
        As taxas apresentadas incluem somente as tarifas de liquidação,
        registro, termo/opções e emolumentos da B3. O Usuário é exclusivamente
        responsável pela conferência e validação das informações utilizadas na
        apuração do imposto devido, conforme Termos e Condições Gerais de Uso.
      </ContentWarning>
      <ModalDesigner
        isOpen={showModalDarf}
        setState={setShowModalDarf}
        id="darf"
        title="Auxílio DARF"
        size="xl"
      >
        <DarfGeneration transaction={transaction} />
      </ModalDesigner>
    </Container>
  );
}

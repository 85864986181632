import { Form, Popover } from 'antd';
import { handleToast } from '../../components/Toast';
import { Container, Content, AsideContent } from './NewPasswordStyles';
import { Eye, EyeClosed, Users } from '@phosphor-icons/react';
import api from '../../services/api';
import { useEffect, useState } from 'react';
import {
  InfoValidationPassword,
  ValidationPassword,
} from './RegistrationStyles';
import asideImg from '../../assets/loginAside.svg';
import logoImg from '../../assets/logo/logoOrange.png';
import { InputStyle } from '../../components/InputStyle';
import { ButtonStyle } from '../../components/ButtonStyle';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { showZodErrorToast } from '../../utils/zodToast';

interface IPropsNewPasswordRequest {
  password: string;
  confirmPassword: string;
}

const passwordSchema = z
  .string()
  .min(8, { message: 'A sua senha deve ter no mínimo 8 caracteres' })
  .regex(/[0-9]/, { message: 'Deve ter no mínimo 1 número' })
  .regex(/[a-zA-Z]/, { message: 'Deve ter no mínimo 1 letra' })
  .regex(/[@#$!%*?&]/, {
    message: 'Deve ter no mínimo 1 símbolo ex: @#$!%*?&',
  });

const schema = z
  .object({
    password: passwordSchema,
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    path: ['confirmPassword'], // localiza o erro em confirmPassword
    message: 'As senhas devem ser iguais',
  });

export function NewPassword() {
  const [viewPassword, setViewPassword] = useState<boolean>(false);
  const [viewPasswordConfirm, setViewPasswordConfirm] =
    useState<boolean>(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    showZodErrorToast(errors);
  }, [errors]);

  async function onLogin(data: IPropsNewPasswordRequest) {
    const token = new URLSearchParams(location.search).get('token');

    await api
      .post('/confirm_password_reset/', { password: data.password, token })
      .then((response) => {
        console.log('Response:', response); // Exibe a resposta completa
        handleToast(false, 'Senha alterada com sucesso');
        window.location.replace('/login');
      })
      .catch((err) => {
        console.log('Error:', err); // Exibe o erro completo
        const errorMessage = err.response?.data?.message;
        const errorPassword = err.response?.data?.password?.[0];

       
        if  (errorMessage === errorPassword ) {
          return handleToast(true, 'Link de Reincialização de Senha já utilizado!'); 
        }
        if (errorPassword === "A senha é muito parecida com username"){ 
          return handleToast(true, errorPassword);
        }   
      });
    // dispatch(loadingToggleAction(true));
    // dispatch(loginAction(data.email, data.password, props.history));
  }
  const password = watch('password');
  const validateLettersInPassword = !!password?.match(/[A-z]/);
  const validateNumberInPassword = !!password?.match(/[0-9]/);
  const validateSpecialCharactersInPassword = !!password?.match(
    /[!|@|#|$|%|^|&|*|(|)|-|_]/
  );
  return (
    <Container>
      <AsideContent>
        <img src={asideImg} />
      </AsideContent>

      <Content>
        <img src={logoImg} alt="logo" />

        <h2>Crie sua senha</h2>
        {/* @ts-ignore */}
        <form onSubmit={handleSubmit(onLogin)} autoComplete="off">
          <Popover
            content={
              <ValidationPassword>
                <InfoValidationPassword error={password?.length >= 8}>
                  <span />
                  Deve ter no mínimo 8 caracteres{' '}
                </InfoValidationPassword>
                <InfoValidationPassword error={validateNumberInPassword}>
                  <span />
                  Deve ter no mínimo 1 número
                </InfoValidationPassword>
                <InfoValidationPassword error={validateLettersInPassword}>
                  <span />
                  Deve ter no mínimo 1 letra
                </InfoValidationPassword>

                <InfoValidationPassword
                  error={validateSpecialCharactersInPassword}
                >
                  <span />
                  Deve ter no mínimo 1 símbolo ex: @#$!{' '}
                </InfoValidationPassword>
              </ValidationPassword>
            }
            title="Crie uma senha segura"
            trigger="focus"
          >
            <InputStyle
              suffix={
                viewPassword ? (
                  <Eye
                    onClick={() => setViewPassword(!viewPassword)}
                    size={20}
                    style={{ cursor: 'pointer' }}
                  />
                ) : (
                  <EyeClosed
                    onClick={() => setViewPassword(!viewPassword)}
                    size={20}
                    style={{ cursor: 'pointer' }}
                  />
                )
              }
              label="Nova senha *"
              errorMessage={
                errors.password !== undefined
                  ? (errors.password.message as string)
                  : null
              }
              isError={errors.password ? true : false}
            >
              <input
                type={viewPassword ? 'text' : 'password'}
                placeholder="**********"
                {...register('password')}
              />
            </InputStyle>
          </Popover>

          <InputStyle
            suffix={
              viewPasswordConfirm ? (
                <Eye
                  onClick={() => setViewPasswordConfirm(!viewPasswordConfirm)}
                  size={20}
                  style={{ cursor: 'pointer' }}
                />
              ) : (
                <EyeClosed
                  onClick={() => setViewPasswordConfirm(!viewPasswordConfirm)}
                  size={20}
                  style={{ cursor: 'pointer' }}
                />
              )
            }
            label="Repita a nova senha"
            errorMessage={
              errors.confirmPassword !== undefined
                ? (errors.confirmPassword.message as string)
                : null
            }
            isError={errors.confirmPassword ? true : false}
          >
            <input
              type={viewPasswordConfirm ? 'text' : 'password'}
              placeholder="**********"
              {...register('confirmPassword')}
            />
          </InputStyle>

          <ButtonStyle type="submit" title="Enviar" disabled={isSubmitting}>
            Enviar
          </ButtonStyle>
        </form>
      </Content>
    </Container>
  );
}

import { EducationCol, EducationContainer } from './styles';
import { Heading } from '../../../../../components/DefaultStylesTypography';
import { previewClass } from '../../../../../Constants/images';

interface IPropsScreen {
  onShowRegisterScreen: () => void;
}

export function Education({ onShowRegisterScreen }: IPropsScreen) {
  return (
    <EducationContainer id="educacao">
      <img src={previewClass} alt="" className="previewImg" />
      <EducationCol>
        <header>
          <span>Tenha acesso a plataforma de educação mais completa</span>
          <Heading>Tudo começa pelo conhecimento!</Heading>
        </header>
        <p>
          Acreditamos que o conhecimento é o melhor caminho para a liberdade, e
          por isso disponibilizamos 1 aula por semana, para te tornar um
          investidor cada vez melhor. A maioria das aulas são sobre análise de
          ações e Fiis de uma forma simples e fácil de entender, para que você
          possa escolher seus ativos de forma independente, sem ter que ficar
          perguntando ou copiando de alguém! Também temos aulas de Educação
          Financeira.
        </p>
        <button type="button" onClick={onShowRegisterScreen}>
          Acesse agora
        </button>
      </EducationCol>
    </EducationContainer>
  );
}

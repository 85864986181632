import styled from 'styled-components';

export const KnowPlatformContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  padding-top: 9rem;

  header {
    text-align: center;
    h1 {
      font-size: 2.25rem;
      font-weight: 500;
    }

    p {
      width: 100%;
      max-width: 38.937rem;
      font-size: 1rem;
      font-weight: 400;
      color: ${(props) => props.theme['gray-700']};
      line-height: 150%;
      margin-bottom: 5.375rem;
      margin-top: 1.75rem;
    }
  }

  iframe {
    border-radius: 16px;
    margin-bottom: 3rem;
  }
  button {
    all: unset;
    width: 100%;
    max-width: 13.125rem;
    padding: 1.25rem;
    background: ${(props) => props.theme.primary};
    color: ${(props) => props.theme.white};
    font-weight: 600;
    font-size: 1.125rem;
    text-align: center;
    border-radius: 8px;
  }

  @media (max-width: 768px) {
    header {
      h1 {
        font-size: 1.5rem;
      }
      p {
        font-size: 0.75rem;
        margin-bottom: 2.25rem;
        margin-top: 1.5rem;
      }
    }
  }
`;

import styled from "styled-components";

export const GraphicNewUsersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${(props) => props.theme["gray-600"]};
  padding: 1rem;
`;
import styled from 'styled-components';

export const BottomCard = styled.div`
  cursor: pointer;
  background: ${(props) => props.theme.white};
  border-radius: 4px;
  padding: 1rem 1.5rem;
  height: 100%;
  max-height: 11.25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: ${(props) => props.theme['card-shadow-2']};
  gap: 1rem;
  z-index: 2;
`;

export const BottomCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  span {
    font: 700 1rem 'Poppins', sans-serif;
    color: ${(props) => props.theme['gray-700']};
  }
`;

export const BottomCardInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 32px;
`;

export const CardInfoValue = styled.h3`
  margin: 0px;
  padding: 0px;
  font: 700 1.5rem 'Poppins', sans-serif;
  color: ${(props) => props.theme['gray-700']};
`;

export const MainAndBottonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;

  @media (max-width: 1100px) {
    flex-direction: column;
  }
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

export const BottomCardOnMainContainer = styled.div`
  @media (max-width: 1100px) {
    flex-direction: column;
    margin-top: 0px;
    div {
      width: 100%;
      margin: 0px;
    }
  }
  display: flex;
  align-items: center;
  gap: 1rem;
`;
export const SideCardAndBottom = styled.div`
  @media (max-width: 1100px) {
    margin-left: 0px;
    background-color: transparent;
    box-shadow: none;
  }
  margin-left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
`;
export const GlobalContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5%;
  width: 90%;
  gap: 1rem;
  margin-bottom: 1rem;
`;
export const BottomCardMargin = styled.div`
  @media (max-width: 1100px) {
    margin-top: 80px;
    background-color: transparent;
  }
  margin-top: 40px;
`;
export const ToolTipContainer = styled.div`
  width: 400px;
  text-align: justify;
  padding: 10px;
`;

export const ContentTab = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12.5rem, 1fr));
  gap: 1rem;
  width: 100%;
`;

export const ContentGraphics = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr); 
  gap: 1rem;
  width: 100%;
  overflow-x: auto;
`;



export const ContentGraphics2 = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
  gap: 1rem;
  width: 100%;
`;

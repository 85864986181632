import styled, { css } from 'styled-components';

interface IPropsIndexStyles {
  shortSideBar?: boolean;
  titlePage: string;
  isMobile?: boolean;
  isFullScreen?: boolean;
  isBlackScreen?: boolean;
}

const isIPhone = process.env.REACT_APP_TYPE === 'iPhone';

export const Container = styled.div<IPropsIndexStyles>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 100vh;

  background: ${(props) => props.isBlackScreen && props.theme['gray-800']};

  .editModal {
    background: black;
    .modal-body {
      padding: 0;
    }
  }
  .buttonToBackPage {
    position: fixed;
    background: ${(props) => props.theme.white};
    color: ${(props) => props.theme.primary};
    /* padding: 8px; */
    border-radius: 100%;
    bottom: ${(props) => (props.isMobile ? '1.5rem' : '0.5rem')};
    left: ${(props) =>
      props.isMobile ? '1rem' : !props.shortSideBar ? '5rem' : '17rem'};
    border: 1px solid ${(props) => props.theme.primary};
    padding: 2px;

    z-index: 100;
    cursor: pointer;
    transition: all 1s;
    :hover {
      filter: brightness(0.9);
    }
  }
  ${isIPhone &&
  css`
    padding-top: 2rem;
  `}
`;

export const Content = styled.div<IPropsIndexStyles>`
  padding-top: 7rem;
  padding-left: ${(props) =>
    props.isMobile ? '1rem' : props.shortSideBar ? '17rem' : '5rem'};
  padding-right: ${(props) => (props.isMobile ? '1rem' : '1.5rem')};
  transition: all 1s;
  .newContent {
    /* max-width: 77rem; */
    max-width: ${(props) => (props.isFullScreen ? 'none' : '66.25rem')};
    margin: 0 auto;
    position: relative;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .reduce {
    max-width: 15ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .contentB3Card {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
interface IPropsShow {
  show: boolean;
}

export const ContentFilters = styled.div<IPropsShow>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  opacity: ${(props) => (props.show ? 1 : 0)};
  max-height: ${(props) => (props.show ? '100%' : '0px')};

  transition: transform 0.3s, max-height 0.3s ease, height 0.5s,
    opacity 0.5s ease;
  transform: ${(props) => (props.show ? 'translateY(0)' : 'translateY(-10px)')};

  label {
    margin: 0;
    color: ${(props) => props.theme['gray-700']};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 198px;
    width: 100%;
    font-size: ${(props) => (props.show ? '0.75rem' : 0)};
  }
  .select {
    width: 100%;
    max-width: 198px;

    color: ${(props) => props.theme['gray-700']};
    display: ${(props) => (props.show ? 'flex' : 'none')};
    .css-yk16xz-control {
      width: 100%;
    }
  }
  input {
    padding: 0.5rem;
    border-radius: 4px;
    border: ${(props) => props.theme.border};
    color: ${(props) => props.theme['gray-700']};
    max-width: 198px;
    width: 100%;
    height: ${(props) => (props.show ? 'auto' : 0)};
  }
  .rowContent {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: wrap;
    align-items: flex-end;
  }
  .clearFilter {
    width: 100%;
    display: ${(props) => (props.show ? 'flex' : 'none')};

    justify-content: flex-end;
    padding: 0;
    border: none;
    background: transparent;
    color: ${(props) => props.theme['gray-700']};
    transition: color 0.3s;

    :hover {
      color: ${(props) => props.theme.primary};
    }
  }
`;

export const ContentTabFilter = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
  border-radius: 4px;
  padding: 0.5rem;

  box-shadow: ${(props) => props.theme['card-shadow-2']};
  transition: all 0.3s;

  header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 1rem;
  }
`;

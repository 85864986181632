import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  p {
    font-size: 1.2rem;
    color: ${(props) => props.theme['gray-700']};
    margin-bottom: 0;
    font-weight: 500;
  }
`;

export const defaultValueGraphic = {
  interest: [
    12, 28.096000000000004, 48.32479999999987, 72.70656000000008,
    101.29168000000027, 134.10031999999956, 171.17280000000028,
    212.53935999999976, 258.2403199999999, 308.3059199999998,
    362.77648000000045, 421.6822400000001, 485.0534400000006, 552.9303999999993,
    625.3534400000008, 702.3528000000006, 783.9688000000006, 870.241759999999,
    961.2019199999995, 1056.8896000000004, 1157.34512, 1262.6088,
    1372.7108800000005, 1487.69168, 1607.59152, 1732.4507200000007,
    1862.3096000000005, 1997.2084799999975, 2137.187679999999,
    2282.287519999998, 2432.5483200000017, 2588.010399999999, 2748.714079999998,
    2914.699679999998, 3086.017599999999, 3262.708160000002, 3444.811679999999,
    3632.368480000001, 3825.4289599999975, 4024.0334399999992,
    4228.222239999999, 4438.045760000001, 4653.554400000001, 4874.778399999999,
    5101.77824, 5334.5942399999985, 5573.26672, 5817.856159999999,
    6068.4028800000015, 6324.947200000002, 6587.5495999999985,
    6856.250400000004, 7131.0999999999985, 7412.148799999995, 7699.447200000002,
    7993.045599999998, 8292.994400000003, 8599.333919999997, 8912.124640000002,
    9231.416960000002, 9557.271359999999, 9889.728159999999, 10228.847840000002,
    10574.680800000002, 10927.277439999998, 11286.698239999998,
    11652.993599999994, 12026.213919999995, 12406.419679999999, 12793.67136,
    13188.019359999998, 13589.524159999994, 13998.23616, 14414.225919999997,
    14837.543840000006, 15268.240320000004, 15706.38592, 16152.041120000002,
    16605.25632, 17066.102080000004, 17534.6288, 18010.90704,
    18494.997280000003, 18986.96, 19486.85568, 19994.75488, 20510.708,
    21034.795679999996, 21567.0784, 22107.616640000007, 22656.48096,
    23213.731839999993, 23779.43983999999, 24353.675520000004,
    24936.509439999994, 25528.00207999999, 26128.224000000002,
    26737.245760000005, 27355.148, 27981.99119999999, 28617.845920000007,
    29262.79280000001, 29916.89232, 30580.225120000003, 31252.871839999993,
    31934.892959999997, 32626.369120000003, 33327.380959999995,
    34037.99904000001, 34758.304000000004, 35488.3664, 36228.27696,
    36978.106239999994, 37737.93488, 38507.83343999999, 39287.892640000005,
    40078.193119999996, 40878.815520000004, 41689.850560000006,
    42511.36880000001,
  ],

  isGraphic: true,
  mount: [
    2012, 2528.1, 3048.32, 3572.71, 4101.29, 4634.1, 5171.17, 5712.54, 6258.24,
    6808.31, 7362.78, 7921.68, 8485.05, 9052.93, 9625.35, 10202.35, 10783.97,
    11370.24, 11961.2, 12556.89, 13157.35, 13762.61, 14372.71, 14987.69,
    15607.59, 16232.45, 16862.309999999998, 17497.21, 18137.19, 18782.29,
    19432.55, 20088.01, 20748.71, 21414.7, 22086.02, 22762.71, 23444.81,
    24132.37, 24825.43, 25524.03, 26228.22, 26938.05, 27653.55, 28374.78,
    29101.78, 29834.59, 30573.27, 31317.86, 32068.4, 32824.95, 33587.55,
    34356.25, 35131.1, 35912.15, 36699.45, 37493.05, 38292.99, 39099.33,
    39912.12, 40731.42, 41557.27, 42389.73, 43228.85, 44074.68, 44927.28,
    45786.7, 46652.99, 47526.21, 48406.42, 49293.67, 50188.02, 51089.52,
    51998.24, 52914.23, 53837.54, 54768.24, 55706.39, 56652.04, 57605.26,
    58566.1, 59534.63, 60510.91, 61495, 62486.96, 63486.86, 64494.75, 65510.71,
    66534.8, 67567.08, 68607.62, 69656.48, 70713.73, 71779.44, 72853.68,
    73936.51, 75028, 76128.22, 77237.25, 78355.15, 79481.99, 80617.85, 81762.79,
    82916.89, 84080.23, 85252.87, 86434.89, 87626.37, 88827.38, 90038, 91258.3,
    92488.37, 93728.28, 94978.11, 96237.93, 97507.83, 98787.89, 100078.19,
    101378.82, 102689.85, 104011.37,
  ],
  total: 120,
  totalInvest: [
    2000, 2500, 3000, 3500, 4000, 4500, 5000, 5500, 6000, 6500, 7000, 7500,
    8000, 8500, 9000, 9500, 10000, 10500, 11000, 11500, 12000, 12500, 13000,
    13500, 14000, 14500, 15000, 15500, 16000, 16500, 17000, 17500, 18000, 18500,
    19000, 19500, 20000, 20500, 21000, 21500, 22000, 22500, 23000, 23500, 24000,
    24500, 25000, 25500, 26000, 26500, 27000, 27500, 28000, 28500, 29000, 29500,
    30000, 30500, 31000, 31500, 32000, 32500, 33000, 33500, 34000, 34500, 35000,
    35500, 36000, 36500, 37000, 37500, 38000, 38500, 39000, 39500, 40000, 40500,
    41000, 41500, 42000, 42500, 43000, 43500, 44000, 44500, 45000, 45500, 46000,
    46500, 47000, 47500, 48000, 48500, 49000, 49500, 50000, 50500, 51000, 51500,
    52000, 52500, 53000, 53500, 54000, 54500, 55000, 55500, 56000, 56500, 57000,
    57500, 58000, 58500, 59000, 59500, 60000, 60500, 61000, 61500,
  ],
};

import { ReactElement, ReactNode } from 'react';
import { InputStyleContainer, InputStyleContent } from './styles';

interface IPropsInputStyle {
  suffix?: ReactElement;
  pFix?: ReactElement;
  label: string;
  isError?: boolean;
  errorMessage?: string | null;
  children: ReactNode;
}

/**
 *
 * @param label Texto label para o input
 * @param isError Adiciona uma borda vermelha em volta do input
 * @param suffix Adiciona um html no fim do input
 * @param pFix Adiciona um html no inicio do input
 * @param children Adicione o seu input não precisa estilizar.
 */

export function InputStyle({
  label,
  suffix,
  pFix,
  isError = false,
  errorMessage = null,
  children,
}: IPropsInputStyle) {
  return (
    <InputStyleContainer isError={isError}>
      <label>{label}</label>
      <InputStyleContent>
        {pFix && pFix} {children}{' '}
        {suffix && <div className="suffix">{suffix}</div>}
      </InputStyleContent>
      {errorMessage !== null && (
        <span className="errorMessage">{errorMessage}</span>
      )}
    </InputStyleContainer>
  );
}

import React from 'react';
import './LoadingSpinner.css';
import { CubeLoading } from './CubeLoading';

export default function LoadingSpinner() {
  return (
    <>
      <div className="perspective">
        <CubeLoading />
      </div>
    </>
  );
}

export const defaultValueComment = {
  author: {
    Name: '',
    bairro: '',
    cidade: '',
    complemento: '',
    created: '',
    ddd: '',
    display_name: '',
    email: '',
    followers: [],
    following: [],
    id: 0,
    numero: '',
    profile: {
      banner: '',
      bio: '',
      image: '',
      location: '',
      website: '',
    },

    rua: '',
    slug: '',
    telefone: '',
    username: '',
    zipcode: '',
  },
  body: '',
  created_at: '',
  hashtag: '',
  id: 0,
  is_active: false,
  is_author: false,
  is_reply: false,
  liked: [],
  parent: null,
  reply_ids: [],
  repost_ids: [],
  responseComment: [],
}
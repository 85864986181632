import { Star } from '@phosphor-icons/react';
import { ColContent, HomeContainer } from './styles';
import { previewDashboard } from '../../../../../Constants/images';

interface IPropsScreen {
  onShowRegisterScreen: () => void;
}

export function Home({ onShowRegisterScreen }: IPropsScreen) {
  return (
    <HomeContainer>
      <ColContent>
        <span>
          <Star weight="fill" />
          Você cuida da sua vida e o iBox6 cuida dos seus investimentos.
        </span>
        <p>
          <strong>Agora investir ficou fácil demais!</strong> O iBox6 foi
          idealizado pensando em tudo o que você precisa para cuidar dos seus
          investimentos de uma forma fácil e segura, mesmo que ainda não saiba
          nada sobre o assunto.
        </p>
        <button type="button" onClick={onShowRegisterScreen}>
          Quero ser um iBoxer
        </button>
      </ColContent>
      <img src={previewDashboard} alt="" className="previewImg" />
    </HomeContainer>
  );
}

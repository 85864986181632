import { Heading } from '../../../../../components/DefaultStylesTypography';
import analysisImg from '../../../../../assets/business/preview-analytics.svg';
import {
  AssetReviewsCol,
  AssetReviewsContainer,
  AssetReviewsContent,
} from './styles';

interface IPropsScreen {
  onShowRegisterScreen: () => void;
}

export function AssetReviews({ onShowRegisterScreen }: IPropsScreen) {
  return (
    <AssetReviewsContainer>
      <div className="blueColor" />
      <div className="redColor" />
      <AssetReviewsContent>
        <AssetReviewsCol>
          <header>
            <span>
              Análise de Ações e FIIs de forma automatizada: O Sonho de todo
              Investidor!
            </span>
            <Heading>Economize o seu bem mais precioso: TEMPO.</Heading>
          </header>
          <p>
            Somos o único aplicativo do mercado que usa a Inteligência
            Artificial para fazer uma análise fundamentalista dos ativos, dando
            uma nota para cada Ação ou Fundo Imobiliário, e criando um ranking
            pronto para você! A análise é baseada nos critérios pré-definidos
            pelos nossos especialistas, que já têm anos de experiência. Seu
            único trabalho é escolher os melhores ativos para a sua carteira de
            investimentos. Fácil demais!
          </p>
          <button type="button" onClick={onShowRegisterScreen}>
            Acesse agora
          </button>
        </AssetReviewsCol>

        <img src={analysisImg} alt="" className="previewImg" />
      </AssetReviewsContent>
    </AssetReviewsContainer>
  );
}

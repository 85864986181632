import ContentLoader from 'react-content-loader';

export function ListCardsActivesRank() {
  return (
    <ContentLoader
      backgroundColor="#f0f0f0"
      foregroundColor="#dedede"
      speed={3}
      width="342"
      height="180"
    >
      <rect x="0" y="0" rx="4" ry="4" width="342" height="180" />
    </ContentLoader>
  );
}
export function LoadingDashAdminInfos() {
  return (
    <ContentLoader
      backgroundColor="#f0f0f0"
      foregroundColor="#dedede"
      speed={3}
      width="342"
      height="25"
    >
      <rect x="0" y="0" rx="4" ry="4" width="150" height="25" />
    </ContentLoader>
  );
}
export function TableLoader() {
  return (
    <ContentLoader
      backgroundColor="#f0f0f0"
      foregroundColor="#dedede"
      style={{ width: '100%', height: '550px' }}
      speed={3}
    >
      <rect x="51" y="45" rx="3" ry="3" width="906" height="17" />
      <circle cx="879" cy="123" r="11" />
      <circle cx="914" cy="123" r="11" />
      <rect x="104" y="115" rx="3" ry="3" width="141" height="15" />
      <rect x="305" y="114" rx="3" ry="3" width="299" height="15" />
      <rect x="661" y="114" rx="3" ry="3" width="141" height="15" />
      <rect x="55" y="155" rx="3" ry="3" width="897" height="2" />
      <circle cx="880" cy="184" r="11" />
      <circle cx="915" cy="184" r="11" />
      <rect x="105" y="176" rx="3" ry="3" width="141" height="15" />
      <rect x="306" y="175" rx="3" ry="3" width="299" height="15" />
      <rect x="662" y="175" rx="3" ry="3" width="141" height="15" />
      <rect x="56" y="216" rx="3" ry="3" width="897" height="2" />
      <circle cx="881" cy="242" r="11" />
      <circle cx="916" cy="242" r="11" />
      <rect x="106" y="234" rx="3" ry="3" width="141" height="15" />
      <rect x="307" y="233" rx="3" ry="3" width="299" height="15" />
      <rect x="663" y="233" rx="3" ry="3" width="141" height="15" />
      <rect x="57" y="274" rx="3" ry="3" width="897" height="2" />
      <circle cx="882" cy="303" r="11" />
      <circle cx="917" cy="303" r="11" />
      <rect x="107" y="295" rx="3" ry="3" width="141" height="15" />
      <rect x="308" y="294" rx="3" ry="3" width="299" height="15" />
      <rect x="664" y="294" rx="3" ry="3" width="141" height="15" />
      <rect x="58" y="335" rx="3" ry="3" width="897" height="2" />
      <circle cx="881" cy="363" r="11" />
      <circle cx="916" cy="363" r="11" />
      <rect x="106" y="355" rx="3" ry="3" width="141" height="15" />
      <rect x="307" y="354" rx="3" ry="3" width="299" height="15" />
      <rect x="663" y="354" rx="3" ry="3" width="141" height="15" />
      <rect x="57" y="395" rx="3" ry="3" width="897" height="2" />
      <circle cx="882" cy="424" r="11" />
      <circle cx="917" cy="424" r="11" />
      <rect x="107" y="416" rx="3" ry="3" width="141" height="15" />
      <rect x="308" y="415" rx="3" ry="3" width="299" height="15" />
      <rect x="664" y="415" rx="3" ry="3" width="141" height="15" />
      <rect x="55" y="453" rx="3" ry="3" width="897" height="2" />
      <rect x="51" y="49" rx="3" ry="3" width="2" height="465" />
      <rect x="955" y="49" rx="3" ry="3" width="2" height="465" />
      <circle cx="882" cy="484" r="11" />
      <circle cx="917" cy="484" r="11" />
      <rect x="107" y="476" rx="3" ry="3" width="141" height="15" />
      <rect x="308" y="475" rx="3" ry="3" width="299" height="15" />
      <rect x="664" y="475" rx="3" ry="3" width="141" height="15" />
      <rect x="55" y="513" rx="3" ry="3" width="897" height="2" />
      <rect x="52" y="80" rx="3" ry="3" width="906" height="17" />
      <rect x="53" y="57" rx="3" ry="3" width="68" height="33" />
      <rect x="222" y="54" rx="3" ry="3" width="149" height="33" />
      <rect x="544" y="55" rx="3" ry="3" width="137" height="33" />
      <rect x="782" y="56" rx="3" ry="3" width="72" height="33" />
      <rect x="933" y="54" rx="3" ry="3" width="24" height="33" />
    </ContentLoader>
  );
}

export function BarChartLoader() {
  return (
    <ContentLoader speed={3} style={{ width: '100%', height: '350px' }}>
      <rect x="0" y="160" rx="0" ry="0" width="50" height="40" />
      <rect x="60" y="145" rx="0" ry="0" width="50" height="55" />
      <rect x="120" y="126" rx="0" ry="0" width="50" height="74" />
      <rect x="180" y="80" rx="0" ry="0" width="50" height="120" />
      <rect x="240" y="142" rx="0" ry="0" width="50" height="58" />
      <rect x="300" y="160" rx="0" ry="0" width="50" height="40" />
      <rect x="360" y="145" rx="0" ry="0" width="50" height="55" />
      <rect x="420" y="126" rx="0" ry="0" width="50" height="74" />
      <rect x="480" y="80" rx="0" ry="0" width="50" height="120" />
    </ContentLoader>
  );
}
export function EarningReceivedLoader() {
  return (
    <ContentLoader
      speed={3}
      style={{ width: '100%', height: '100vh' }}
      backgroundColor="#f0f0f0"
      foregroundColor="#dedede"
    >
      {/* box-1 */}
      {/*bordas-começo */}
      <rect x="4" y="0" rx="3" ry="3" width="8" height="169" />
      <rect x="7" y="161" rx="3" ry="3" width="668" height="8" />
      <rect x="668" y="0" rx="3" ry="3" width="7" height="169" />
      <rect x="5" y="0" rx="3" ry="3" width="668" height="8" />
      {/* bordas-fim */}
      {/* header */}
      <rect x="24" y="24" rx="4" ry="4" width="150" height="20" />
      <rect x="502" y="24" rx="4" ry="4" width="150" height="20" />
      {/* header */}
      {/* input-content */}
      <rect x="24" y="56" rx="4" ry="4" width="50" height="20" />
      <rect x="24" y="80" rx="4" ry="4" width="190" height="20" />
      <rect x="240" y="56" rx="4" ry="4" width="50" height="20" />
      <rect x="240" y="80" rx="4" ry="4" width="190" height="20" />
      <rect x="460" y="80" rx="4" ry="4" width="190" height="20" />
      <rect x="460" y="120" rx="4" ry="4" width="190" height="20" />
      <rect x="240" y="120" rx="4" ry="4" width="190" height="20" />
      <rect x="24" y="120" rx="4" ry="4" width="190" height="20" />

      {/* input-content */}
      {/* box-1 */}
      {/* box-2 */}
      {/*bordas-começo */}
      <rect x="690" y="0" rx="3" ry="3" width="8" height="168" />
      <rect x="690" y="160" rx="3" ry="3" width="375" height="8" />
      <rect x="1053" y="0" rx="3" ry="3" width="7" height="168" />
      <rect x="690" y="0" rx="3" ry="3" width="375" height="8" />
      {/* bordas-fim */}
      {/* content */}
      <rect x="710" y="20" rx="4" ry="4" width="150" height="20" />
      <rect x="780" y="75" rx="4" ry="4" width="200" height="40" />

      <rect x="890" y="130" rx="4" ry="4" width="150" height="20" />
      {/* content */}
      {/* box-2 */}
      {/* box-3 gráfico*/}
      {/*bordas-começo */}
      <rect x="4" y="190" rx="3" ry="3" width="8" height="300" />
      <rect x="7" y="482" rx="3" ry="3" width="100%" height="8" />
      <rect x="1053" y="190" rx="3" ry="3" width="7" height="300" />
      <rect x="7" y="190" rx="3" ry="3" width="100%" height="8" />
      {/* bordas-fim */}
      {/* gráfico */}
      <rect x="30" y="400" rx="0" ry="0" width="50" height="40" />
      <rect x="90" y="385" rx="0" ry="0" width="50" height="55" />
      <rect x="150" y="365" rx="0" ry="0" width="50" height="74" />
      <rect x="210" y="320" rx="0" ry="0" width="50" height="120" />
      <rect x="270" y="382" rx="0" ry="0" width="50" height="58" />
      <rect x="330" y="400" rx="0" ry="0" width="50" height="40" />
      <rect x="390" y="385" rx="0" ry="0" width="50" height="55" />
      <rect x="450" y="365" rx="0" ry="0" width="50" height="74" />
      <rect x="510" y="320" rx="0" ry="0" width="50" height="120" />
      <rect x="570" y="382" rx="0" ry="0" width="50" height="58" />
      <rect x="630" y="400" rx="0" ry="0" width="50" height="40" />
      <rect x="690" y="385" rx="0" ry="0" width="50" height="55" />
      <rect x="750" y="365" rx="0" ry="0" width="50" height="74" />
      <rect x="810" y="320" rx="0" ry="0" width="50" height="120" />
      <rect x="870" y="382" rx="0" ry="0" width="50" height="58" />
      <rect x="930" y="400" rx="0" ry="0" width="50" height="40" />
      <rect x="990" y="385" rx="0" ry="0" width="50" height="55" />

      {/* gráfico */}
      {/* box-3 gráfico*/}
    </ContentLoader>
  );
}
export function EarningLoader() {
  return (
    <ContentLoader
      speed={3}
      style={{ width: '100%', height: '100vh' }}
      backgroundColor="#f0f0f0"
      foregroundColor="#dedede"
    >
      <rect x="0" y="0" rx="4" ry="4" width="1050" height="50" />
      <rect x="0" y="60" rx="4" ry="4" width="1050" height="50" />
      <rect x="0" y="120" rx="4" ry="4" width="1050" height="50" />
      <rect x="0" y="180" rx="4" ry="4" width="1050" height="50" />
      <rect x="0" y="240" rx="4" ry="4" width="1050" height="50" />
      <rect x="0" y="300" rx="4" ry="4" width="1050" height="50" />
      <rect x="0" y="360" rx="4" ry="4" width="1050" height="50" />
      <rect x="0" y="420" rx="4" ry="4" width="1050" height="50" />
    </ContentLoader>
  );
}

export function IncomeTaxLoader() {
  return (
    <ContentLoader
      speed={3}
      style={{ width: '100%', height: '200px' }}
      backgroundColor="#f0f0f0"
      foregroundColor="#dedede"
    >
      <rect x="0" y="0" rx="4" ry="4" width="1060" height="50" />
      {/* box-1 */}
      {/* bordas */}
      <rect x="0" y="60" rx="4" ry="4" width="522" height="8" />
      <rect x="0" y="185" rx="4" ry="4" width="522" height="8" />
      <rect x="0" y="60" rx="4" ry="4" width="8" height="130" />
      <rect x="516" y="60" rx="4" ry="4" width="8" height="130" />
      {/* bordas */}
      {/* content */}
      <rect x="20" y="80" rx="4" ry="4" width="150" height="20" />

      <rect x="20" y="120" rx="4" ry="4" width="150" height="25" />
      <rect x="20" y="150" rx="4" ry="4" width="200" height="20" />

      <rect x="300" y="120" rx="4" ry="4" width="150" height="25" />
      <rect x="300" y="150" rx="4" ry="4" width="200" height="20" />

      {/* content */}
      {/* box-1 */}
      {/* box-2 */}
      {/* bordas */}
      <rect x="530" y="60" rx="4" ry="4" width="522" height="8" />
      <rect x="530" y="185" rx="4" ry="4" width="522" height="8" />
      <rect x="530" y="60" rx="4" ry="4" width="8" height="130" />
      <rect x="1048" y="60" rx="4" ry="4" width="8" height="130" />
      {/* bordas */}
      {/* content */}
      <rect x="550" y="80" rx="4" ry="4" width="150" height="20" />

      <rect x="550" y="120" rx="4" ry="4" width="150" height="25" />
      <rect x="550" y="150" rx="4" ry="4" width="200" height="20" />

      <rect x="835" y="120" rx="4" ry="4" width="150" height="25" />
      <rect x="835" y="150" rx="4" ry="4" width="200" height="20" />

      {/* content */}
      {/* box-2 */}
    </ContentLoader>
  );
}
export function IncomeTaxReportLoader() {
  return (
    <ContentLoader
      speed={3}
      style={{ width: '100%', height: '100vh' }}
      backgroundColor="#f0f0f0"
      foregroundColor="#dedede"
    >
      <rect x="0" y="0" rx="4" ry="4" width="1060" height="50" />
      {/* header */}

      <rect x="430" y="60" rx="4" ry="4" width="200" height="30" />
      <rect x="400" y="100" rx="4" ry="4" width="260" height="30" />
      <rect x="400" y="140" rx="4" ry="4" width="260" height="30" />

      {/* header */}
      {/* box-1 */}
      {/* bordas */}
      <rect x="0" y="180" rx="4" ry="4" width="1060" height="8" />
      <rect x="0" y="325" rx="4" ry="4" width="1060" height="8" />
      <rect x="0" y="180" rx="4" ry="4" width="8" height="150" />
      <rect x="1053" y="180" rx="4" ry="4" width="8" height="150" />
      {/* bordas */}
      {/* content */}
      <rect x="20" y="200" rx="4" ry="4" width="80" height="20" />
      <rect x="120" y="200" rx="4" ry="4" width="429" height="20" />

      <rect x="20" y="230" rx="4" ry="4" width="120" height="20" />
      <rect x="150" y="230" rx="4" ry="4" width="400" height="20" />

      <rect x="20" y="260" rx="4" ry="4" width="120" height="20" />
      <rect x="150" y="260" rx="4" ry="4" width="400" height="20" />
      <rect x="20" y="290" rx="4" ry="4" width="120" height="20" />
      <rect x="150" y="290" rx="4" ry="4" width="400" height="20" />

      {/* content */}
      {/* box-1 */}

      {/* box-2 */}
      {/* bordas */}
      <rect x="0" y="350" rx="4" ry="4" width="1060" height="8" />
      <rect x="0" y="495" rx="4" ry="4" width="1060" height="8" />
      <rect x="0" y="350" rx="4" ry="4" width="8" height="150" />
      <rect x="1053" y="350" rx="4" ry="4" width="8" height="150" />
      {/* bordas */}
      {/* content */}
      <rect x="20" y="370" rx="4" ry="4" width="80" height="20" />
      <rect x="120" y="370" rx="4" ry="4" width="429" height="20" />

      <rect x="20" y="400" rx="4" ry="4" width="120" height="20" />
      <rect x="150" y="400" rx="4" ry="4" width="400" height="20" />

      <rect x="20" y="430" rx="4" ry="4" width="120" height="20" />
      <rect x="150" y="430" rx="4" ry="4" width="400" height="20" />
      <rect x="20" y="460" rx="4" ry="4" width="120" height="20" />
      <rect x="150" y="460" rx="4" ry="4" width="400" height="20" />

      {/* content */}
      {/* box-2 */}

      {/* box-3 */}
      {/* bordas */}
      <rect x="0" y="520" rx="4" ry="4" width="1060" height="8" />
      <rect x="0" y="665" rx="4" ry="4" width="1060" height="8" />
      <rect x="0" y="520" rx="4" ry="4" width="8" height="150" />
      <rect x="1053" y="520" rx="4" ry="4" width="8" height="150" />
      {/* bordas */}
      {/* content */}
      <rect x="20" y="540" rx="4" ry="4" width="80" height="20" />
      <rect x="120" y="540" rx="4" ry="4" width="429" height="20" />

      <rect x="20" y="570" rx="4" ry="4" width="120" height="20" />
      <rect x="150" y="570" rx="4" ry="4" width="400" height="20" />

      <rect x="20" y="600" rx="4" ry="4" width="120" height="20" />
      <rect x="150" y="600" rx="4" ry="4" width="400" height="20" />
      <rect x="20" y="630" rx="4" ry="4" width="120" height="20" />
      <rect x="150" y="630" rx="4" ry="4" width="400" height="20" />

      {/* content */}
      {/* box-3 */}
    </ContentLoader>
  );
}

export function TransactionsIncomeTaxLoader() {
  return (
    <ContentLoader
      backgroundColor="#f0f0f0"
      foregroundColor="#dedede"
      style={{ width: '100%', height: '550px' }}
      speed={3}
    >
      <rect x="0" y="0" rx="4" ry="4" width="1060" height="35" />

      <rect x="51" y="45" rx="3" ry="3" width="906" height="17" />
      <circle cx="879" cy="123" r="11" />
      <circle cx="914" cy="123" r="11" />
      <rect x="104" y="115" rx="3" ry="3" width="141" height="15" />
      <rect x="305" y="114" rx="3" ry="3" width="299" height="15" />
      <rect x="661" y="114" rx="3" ry="3" width="141" height="15" />
      <rect x="55" y="155" rx="3" ry="3" width="897" height="2" />
      <circle cx="880" cy="184" r="11" />
      <circle cx="915" cy="184" r="11" />
      <rect x="105" y="176" rx="3" ry="3" width="141" height="15" />
      <rect x="306" y="175" rx="3" ry="3" width="299" height="15" />
      <rect x="662" y="175" rx="3" ry="3" width="141" height="15" />
      <rect x="56" y="216" rx="3" ry="3" width="897" height="2" />
      <circle cx="881" cy="242" r="11" />
      <circle cx="916" cy="242" r="11" />
      <rect x="106" y="234" rx="3" ry="3" width="141" height="15" />
      <rect x="307" y="233" rx="3" ry="3" width="299" height="15" />
      <rect x="663" y="233" rx="3" ry="3" width="141" height="15" />
      <rect x="57" y="274" rx="3" ry="3" width="897" height="2" />
      <circle cx="882" cy="303" r="11" />
      <circle cx="917" cy="303" r="11" />
      <rect x="107" y="295" rx="3" ry="3" width="141" height="15" />
      <rect x="308" y="294" rx="3" ry="3" width="299" height="15" />
      <rect x="664" y="294" rx="3" ry="3" width="141" height="15" />
      <rect x="58" y="335" rx="3" ry="3" width="897" height="2" />
      <circle cx="881" cy="363" r="11" />
      <circle cx="916" cy="363" r="11" />
      <rect x="106" y="355" rx="3" ry="3" width="141" height="15" />
      <rect x="307" y="354" rx="3" ry="3" width="299" height="15" />
      <rect x="663" y="354" rx="3" ry="3" width="141" height="15" />
      <rect x="57" y="395" rx="3" ry="3" width="897" height="2" />
      <circle cx="882" cy="424" r="11" />
      <circle cx="917" cy="424" r="11" />
      <rect x="107" y="416" rx="3" ry="3" width="141" height="15" />
      <rect x="308" y="415" rx="3" ry="3" width="299" height="15" />
      <rect x="664" y="415" rx="3" ry="3" width="141" height="15" />
      <rect x="55" y="453" rx="3" ry="3" width="897" height="2" />
      <rect x="51" y="49" rx="3" ry="3" width="2" height="465" />
      <rect x="955" y="49" rx="3" ry="3" width="2" height="465" />
      <circle cx="882" cy="484" r="11" />
      <circle cx="917" cy="484" r="11" />
      <rect x="107" y="476" rx="3" ry="3" width="141" height="15" />
      <rect x="308" y="475" rx="3" ry="3" width="299" height="15" />
      <rect x="664" y="475" rx="3" ry="3" width="141" height="15" />
      <rect x="55" y="513" rx="3" ry="3" width="897" height="2" />
      <rect x="52" y="80" rx="3" ry="3" width="906" height="17" />
      <rect x="53" y="57" rx="3" ry="3" width="68" height="33" />
      <rect x="222" y="54" rx="3" ry="3" width="149" height="33" />
      <rect x="544" y="55" rx="3" ry="3" width="137" height="33" />
      <rect x="782" y="56" rx="3" ry="3" width="72" height="33" />
      <rect x="933" y="54" rx="3" ry="3" width="24" height="33" />
    </ContentLoader>
  );
}

export function EducationLoader() {
  return (
    <ContentLoader
      speed={3}
      style={{ width: '100%', height: '100vh' }}
      backgroundColor="#f0f0f0"
      foregroundColor="#dedede"
    >
      <rect x="0" y="0" rx="4" ry="4" width="1060" height="200" />
      <rect x="0" y="210" rx="4" ry="4" width="1060" height="50" />
      <rect x="0" y="270" rx="4" ry="4" width="1060" height="30" />
      {/* video-1 */}
      <rect x="0" y="320" rx="4" ry="4" width="230" height="180" />
      <rect x="0" y="510" rx="4" ry="4" width="230" height="30" />
      <rect x="0" y="550" rx="4" ry="4" width="230" height="15" />
      <rect x="0" y="570" rx="4" ry="4" width="230" height="15" />
      <rect x="0" y="595" rx="4" ry="4" width="230" height="40" />

      {/* video-1 */}
      {/* video-2 */}
      <rect x="245" y="320" rx="4" ry="4" width="260" height="180" />
      <rect x="245" y="510" rx="4" ry="4" width="260" height="30" />
      <rect x="245" y="550" rx="4" ry="4" width="260" height="15" />
      <rect x="245" y="570" rx="4" ry="4" width="260" height="15" />
      <rect x="245" y="595" rx="4" ry="4" width="260" height="40" />

      {/* video-2 */}
      {/* video-4 */}
      <rect x="520" y="320" rx="4" ry="4" width="260" height="180" />
      <rect x="520" y="510" rx="4" ry="4" width="260" height="30" />
      <rect x="520" y="550" rx="4" ry="4" width="260" height="15" />
      <rect x="520" y="570" rx="4" ry="4" width="260" height="15" />
      <rect x="520" y="595" rx="4" ry="4" width="260" height="40" />

      {/* video-4 */}
      {/* video-4 */}
      <rect x="800" y="320" rx="4" ry="4" width="260" height="180" />
      <rect x="800" y="510" rx="4" ry="4" width="260" height="30" />
      <rect x="800" y="550" rx="4" ry="4" width="260" height="15" />
      <rect x="800" y="570" rx="4" ry="4" width="260" height="15" />
      <rect x="800" y="595" rx="4" ry="4" width="260" height="40" />

      {/* video-4 */}
    </ContentLoader>
  );
}
export function FeedLoader() {
  return (
    <ContentLoader
      speed={3}
      style={{ width: '100%', height: '100vh' }}
      backgroundColor="#f0f0f0"
      foregroundColor="#dedede"
    >
      <rect x="0" y="0" rx="4" ry="4" width="1060" height="200" />
      <rect x="0" y="210" rx="4" ry="4" width="1060" height="50" />
      <rect x="0" y="270" rx="4" ry="4" width="1060" height="200" />

      {/* comentário-1 */}
      <rect x="48" y="500" width="88" height="6" rx="3" />
      <rect x="48" y="518" width="52" height="6" rx="3" />
      <rect x="0" y="556" width="620" height="6" rx="3" />
      <rect x="0" y="572" width="480" height="6" rx="3" />
      <rect x="0" y="588" width="278" height="6" rx="3" />
      <circle cx="20" cy="512" r="20" />

      {/* comentário-1 */}
      {/* comentário-1 */}
      <rect x="48" y="650" width="88" height="6" rx="3" />
      <rect x="48" y="670" width="52" height="6" rx="3" />
      <rect x="0" y="710" width="620" height="6" rx="3" />
      <rect x="0" y="727" width="480" height="6" rx="3" />
      <rect x="0" y="745" width="278" height="6" rx="3" />
      <circle cx="20" cy="662" r="20" />

      {/* comentário-1 */}
    </ContentLoader>
  );
}

export function AnalysisFiiLoader() {
  return (
    <ContentLoader
      speed={3}
      style={{ width: '100%', height: '100vh' }}
      backgroundColor="#f0f0f0"
      foregroundColor="#dedede"
    >
      {/* coluna-1 */}
      <rect x="0" y="0" rx="4" ry="4" width="805" height="8" />
      <rect x="0" y="0" rx="4" ry="4" width="8" height="405" />
      <rect x="0" y="400" rx="4" ry="4" width="805" height="8" />
      <rect x="798" y="0" rx="4" ry="4" width="8" height="405" />
      <rect x="24" y="24" rx="4" ry="4" width="80" height="80" />
      <rect x="610" y="20" rx="999" ry="999" width="140" height="140" />
      <rect x="760" y="24" rx="4" ry="4" width="10" height="30" />
      <rect x="24" y="120" rx="4" ry="4" width="120" height="20" />
      <rect x="24" y="145" rx="4" ry="4" width="170" height="30" />
      <rect x="210" y="120" rx="4" ry="4" width="120" height="20" />
      <rect x="210" y="145" rx="4" ry="4" width="170" height="30" />
      <rect x="24" y="180" rx="4" ry="4" width="120" height="20" />
      <rect x="24" y="207" rx="4" ry="4" width="170" height="30" />
      <rect x="24" y="243" rx="4" ry="4" width="120" height="20" />
      <rect x="24" y="270" rx="4" ry="4" width="170" height="30" />
      <rect x="24" y="308" rx="4" ry="4" width="120" height="20" />
      <rect x="24" y="335" rx="4" ry="4" width="170" height="30" />
      <rect x="210" y="308" rx="4" ry="4" width="120" height="20" />
      <rect x="210" y="335" rx="4" ry="4" width="170" height="30" />
      <rect x="398" y="308" rx="4" ry="4" width="120" height="20" />
      <rect x="398" y="335" rx="4" ry="4" width="170" height="30" />
      {/* coluna-1 */}
      {/* coluna-2 */}
      <rect x="820" y="0" rx="4" ry="4" width="235" height="8" />
      <rect x="820" y="0" rx="4" ry="4" width="8" height="405" />
      <rect x="820" y="400" rx="4" ry="4" width="235" height="8" />
      <rect x="1048" y="0" rx="4" ry="4" width="8" height="405" />

      <rect x="840" y="24" rx="4" ry="4" width="100" height="20" />
      <rect x="840" y="50" rx="4" ry="4" width="150" height="30" />
      <rect x="840" y="88" rx="4" ry="4" width="100" height="20" />
      <rect x="840" y="115" rx="4" ry="4" width="150" height="30" />
      <rect x="840" y="150" rx="4" ry="4" width="100" height="20" />
      <rect x="840" y="175" rx="4" ry="4" width="150" height="30" />

      <rect x="840" y="210" rx="4" ry="4" width="100" height="20" />
      <rect x="840" y="236" rx="4" ry="4" width="150" height="30" />
      <rect x="840" y="272" rx="4" ry="4" width="100" height="20" />
      <rect x="840" y="298" rx="4" ry="4" width="150" height="30" />
      <rect x="840" y="335" rx="4" ry="4" width="100" height="20" />
      <rect x="840" y="360" rx="4" ry="4" width="150" height="30" />
      {/* coluna-2 */}

      {/* linha-1 */}
      <rect x="0" y="420" rx="4" ry="4" width="1060" height="8" />
      <rect x="0" y="420" rx="4" ry="4" width="8" height="200" />
      <rect x="0" y="615" rx="4" ry="4" width="1060" height="8" />
      <rect x="1052" y="420" rx="4" ry="4" width="8" height="200" />
      <rect x="555" y="420" rx="4" ry="4" width="8" height="200" />
      <rect x="24" y="440" rx="4" ry="4" width="100" height="20" />
      <rect x="580" y="440" rx="4" ry="4" width="100" height="20" />

      <rect x="100" y="500" rx="4" ry="4" width="100" height="20" />
      <rect x="100" y="530" rx="4" ry="4" width="150" height="30" />
      <rect x="270" y="500" rx="4" ry="4" width="100" height="20" />
      <rect x="270" y="530" rx="4" ry="4" width="150" height="30" />
      <rect x="650" y="500" rx="4" ry="4" width="100" height="20" />
      <rect x="650" y="530" rx="4" ry="4" width="150" height="30" />
      <rect x="820" y="500" rx="4" ry="4" width="100" height="20" />
      <rect x="820" y="530" rx="4" ry="4" width="150" height="30" />

      {/* linha-1 */}
    </ContentLoader>
  );
}

export function AnalysisActionLoader() {
  return (
    <ContentLoader
      speed={3}
      style={{ width: '100%', height: '100vh' }}
      backgroundColor="#f0f0f0"
      foregroundColor="#dedede"
    >
      {/* linha-1 */}
      <rect x="0" y="0" rx="4" ry="4" width="1060" height="8" />
      <rect x="0" y="0" rx="4" ry="4" width="8" height="224" />
      <rect x="0" y="220" rx="4" ry="4" width="1060" height="8" />
      <rect x="1052" y="0" rx="4" ry="4" width="8" height="224" />

      <rect x="24" y="24" rx="4" ry="4" width="120" height="20" />
      <rect x="24" y="50" rx="20" ry="20" width="60" height="30" />
      <rect x="90" y="50" rx="20" ry="20" width="60" height="30" />

      <rect x="24" y="90" rx="4" ry="4" width="140" height="120" />

      <rect x="320" y="24" rx="4" ry="4" width="120" height="20" />
      <rect x="320" y="50" rx="4" ry="4" width="160" height="30" />
      <rect x="320" y="90" rx="4" ry="4" width="120" height="20" />
      <rect x="320" y="116" rx="4" ry="4" width="160" height="30" />
      <rect x="320" y="155" rx="4" ry="4" width="120" height="20" />
      <rect x="320" y="180" rx="4" ry="4" width="160" height="30" />

      <rect x="580" y="24" rx="4" ry="4" width="120" height="20" />
      <rect x="580" y="50" rx="4" ry="4" width="160" height="30" />
      <rect x="580" y="90" rx="4" ry="4" width="120" height="20" />
      <rect x="580" y="116" rx="4" ry="4" width="160" height="30" />
      <rect x="580" y="155" rx="4" ry="4" width="120" height="20" />
      <rect x="580" y="180" rx="4" ry="4" width="160" height="30" />

      <rect x="850" y="35" rx="100" ry="100" width="160" height="160" />
      <rect x="1025" y="20" rx="4" ry="4" width="10" height="40" />
      {/* linha-1 */}
      {/* linha-2 */}
      {/* Card-1 */}
      <rect x="0" y="240" rx="4" ry="4" width="330" height="8" />
      <rect x="0" y="240" rx="4" ry="4" width="8" height="164" />
      <rect x="0" y="400" rx="4" ry="4" width="330" height="8" />
      <rect x="322" y="240" rx="4" ry="4" width="8" height="164" />
      <rect x="24" y="260" rx="4" ry="4" width="120" height="20" />
      <rect x="24" y="290" rx="4" ry="4" width="160" height="30" />
      <rect
        x="24"
        y="330"
        rx="4"
        ry="4"
        width="260"
        height="60"
        clipPath="polygon(35% 50%, 27% 65%, 16% 36%, 0 100%, 7% 100%, 17% 57%, 26% 81%, 35% 67%, 52% 26%, 77% 100%, 83% 100%, 53% 6%)"
      />
      {/* Card-1 */}
      {/* Card-2 */}
      <rect x="363" y="240" rx="4" ry="4" width="330" height="8" />
      <rect x="363" y="240" rx="4" ry="4" width="8" height="164" />
      <rect x="363" y="400" rx="4" ry="4" width="330" height="8" />
      <rect x="685" y="240" rx="4" ry="4" width="8" height="164" />
      <rect x="390" y="260" rx="4" ry="4" width="120" height="20" />
      <rect x="390" y="290" rx="4" ry="4" width="160" height="30" />
      <rect
        x="390"
        y="330"
        rx="4"
        ry="4"
        width="260"
        height="60"
        clipPath="polygon(35% 50%, 27% 65%, 16% 36%, 0 100%, 7% 100%, 17% 57%, 26% 81%, 35% 67%, 52% 26%, 77% 100%, 83% 100%, 53% 6%)"
      />
      {/* Card-2 */}
      {/* Card-3 */}
      <rect x="723" y="240" rx="4" ry="4" width="330" height="8" />
      <rect x="723" y="240" rx="4" ry="4" width="8" height="164" />
      <rect x="723" y="400" rx="4" ry="4" width="330" height="8" />
      <rect x="1045" y="240" rx="4" ry="4" width="8" height="164" />
      <rect x="750" y="260" rx="4" ry="4" width="120" height="20" />
      <rect x="750" y="290" rx="4" ry="4" width="160" height="30" />
      <rect
        x="750"
        y="330"
        rx="4"
        ry="4"
        width="260"
        height="60"
        clipPath="polygon(35% 50%, 27% 65%, 16% 36%, 0 100%, 7% 100%, 17% 57%, 26% 81%, 35% 67%, 52% 26%, 77% 100%, 83% 100%, 53% 6%)"
      />
      {/* Card-3 */}

      {/* linha-2 */}
      {/* linha-3 */}
      {/* Card-1 */}
      <rect x="0" y="420" rx="4" ry="4" width="330" height="8" />
      <rect x="0" y="420" rx="4" ry="4" width="8" height="164" />
      <rect x="0" y="580" rx="4" ry="4" width="330" height="8" />
      <rect x="322" y="420" rx="4" ry="4" width="8" height="164" />
      <rect x="24" y="441" rx="4" ry="4" width="120" height="20" />
      <rect x="24" y="470" rx="4" ry="4" width="160" height="30" />
      <rect
        x="24"
        y="510"
        rx="4"
        ry="4"
        width="260"
        height="60"
        clipPath="polygon(35% 50%, 27% 65%, 16% 36%, 0 100%, 7% 100%, 17% 57%, 26% 81%, 35% 67%, 52% 26%, 77% 100%, 83% 100%, 53% 6%)"
      />
      {/* Card-1 */}
      {/* Card-2 */}
      <rect x="363" y="420" rx="4" ry="4" width="330" height="8" />
      <rect x="363" y="420" rx="4" ry="4" width="8" height="164" />
      <rect x="363" y="580" rx="4" ry="4" width="330" height="8" />
      <rect x="685" y="420" rx="4" ry="4" width="8" height="164" />
      <rect x="390" y="441" rx="4" ry="4" width="120" height="20" />
      <rect x="390" y="470" rx="4" ry="4" width="160" height="30" />
      <rect
        x="390"
        y="510"
        rx="4"
        ry="4"
        width="260"
        height="60"
        clipPath="polygon(35% 50%, 27% 65%, 16% 36%, 0 100%, 7% 100%, 17% 57%, 26% 81%, 35% 67%, 52% 26%, 77% 100%, 83% 100%, 53% 6%)"
      />
      {/* Card-2 */}
      {/* Card-3 */}
      <rect x="723" y="420" rx="4" ry="4" width="330" height="8" />
      <rect x="723" y="420" rx="4" ry="4" width="8" height="164" />
      <rect x="723" y="580" rx="4" ry="4" width="330" height="8" />
      <rect x="1045" y="420" rx="4" ry="4" width="8" height="164" />
      <rect x="750" y="441" rx="4" ry="4" width="120" height="20" />
      <rect x="750" y="470" rx="4" ry="4" width="160" height="30" />
      <rect
        x="750"
        y="510"
        rx="4"
        ry="4"
        width="260"
        height="60"
        clipPath="polygon(35% 50%, 27% 65%, 16% 36%, 0 100%, 7% 100%, 17% 57%, 26% 81%, 35% 67%, 52% 26%, 77% 100%, 83% 100%, 53% 6%)"
      />
      {/* Card-3 */}

      {/* linha-3 */}
    </ContentLoader>
  );
}

export function FatosRelevantesLoader() {
  return (
    <ContentLoader
      speed={3}
      style={{ width: '100%', height: '100vh' }}
      backgroundColor="#f0f0f0"
      foregroundColor="#dedede"
    >
      {/* box-1 */}
      <rect x="100" y="0" rx="15" ry="15" width="70" height="30" />
      <rect x="100" y="40" rx="4" ry="4" width="200" height="25" />
      <rect x="100" y="80" rx="4" ry="4" width="180" height="15" />
      <rect x="100" y="100" rx="4" ry="4" width="200" height="30" />
      {/* box-1 */}
      {/* box-2 */}
      <rect x="620" y="0" rx="15" ry="15" width="70" height="30" />
      <rect x="620" y="40" rx="4" ry="4" width="200" height="25" />
      <rect x="620" y="80" rx="4" ry="4" width="180" height="15" />
      <rect x="620" y="100" rx="4" ry="4" width="200" height="30" />
      {/* box-2 */}
      {/* box-3 */}
      <rect x="100" y="170" rx="15" ry="15" width="70" height="30" />
      <rect x="100" y="210" rx="4" ry="4" width="200" height="25" />
      <rect x="100" y="250" rx="4" ry="4" width="180" height="15" />
      <rect x="100" y="270" rx="4" ry="4" width="200" height="30" />
      {/* box-3 */}
      {/* box-4 */}
      <rect x="620" y="170" rx="15" ry="15" width="70" height="30" />
      <rect x="620" y="210" rx="4" ry="4" width="200" height="25" />
      <rect x="620" y="250" rx="4" ry="4" width="180" height="15" />
      <rect x="620" y="270" rx="4" ry="4" width="200" height="30" />
      {/* box-4 */}
      {/* box-5 */}
      <rect x="100" y="350" rx="15" ry="15" width="70" height="30" />
      <rect x="100" y="390" rx="4" ry="4" width="200" height="25" />
      <rect x="100" y="430" rx="4" ry="4" width="180" height="15" />
      <rect x="100" y="450" rx="4" ry="4" width="200" height="30" />
      {/* box-5 */}
      {/* box-6 */}
      <rect x="620" y="350" rx="15" ry="15" width="70" height="30" />
      <rect x="620" y="390" rx="4" ry="4" width="200" height="25" />
      <rect x="620" y="430" rx="4" ry="4" width="180" height="15" />
      <rect x="620" y="450" rx="4" ry="4" width="200" height="30" />
      {/* box-6 */}
    </ContentLoader>
  );
}

export function CardGraphicLoader() {
  return (
    <ContentLoader
      speed={3}
      style={{ width: '100%', height: '100vh' }}
      backgroundColor="#f0f0f0"
      foregroundColor="#dedede"
    >
      {/* box-1 */}
      <rect x="0" y="0" rx="4" ry="4" width="292" height="145" />
    </ContentLoader>
  );
}

export function AdminDashLoader() {
  return (
    <ContentLoader
      speed={3}
      style={{ width: '100%', height: '100vh' }}
      backgroundColor="#404040"
      foregroundColor="#dededef0"
    >
      {/* title */}
      <rect x="0" y="0" rx="15" ry="15" width="170" height="30" />
      {/* title */}
      {/* box-1 */}
      {/* cards*/}
      <rect x="0" y="45" rx="4" ry="4" width="340" height="70" />
      <rect x="360" y="45" rx="4" ry="4" width="340" height="70" />
      <rect x="720" y="45" rx="4" ry="4" width="340" height="70" />
      <rect x="0" y="130" rx="4" ry="4" width="340" height="70" />
      <rect x="360" y="130" rx="4" ry="4" width="340" height="70" />
      <rect x="720" y="130" rx="4" ry="4" width="340" height="70" />
      {/* cards */}
      {/* box-1 */}
      {/* title */}
      <rect x="0" y="215" rx="15" ry="15" width="170" height="30" />
      {/* title */}
      {/* box-2 */}
      {/* cards*/}
      <rect x="0" y="260" rx="4" ry="4" width="340" height="70" />
      <rect x="360" y="260" rx="4" ry="4" width="340" height="70" />
      <rect x="720" y="260" rx="4" ry="4" width="340" height="70" />
      <rect x="0" y="345" rx="4" ry="4" width="340" height="70" />
      <rect x="360" y="345" rx="4" ry="4" width="340" height="70" />
      <rect x="720" y="345" rx="4" ry="4" width="340" height="70" />
      {/* cards */}
      {/* box-2 */}
      {/* title */}
      <rect x="0" y="430" rx="15" ry="15" width="170" height="30" />
      {/* title */}
      {/* box-3 */}
      {/* cards*/}
      <rect x="0" y="475" rx="4" ry="4" width="340" height="70" />
      <rect x="360" y="475" rx="4" ry="4" width="340" height="70" />
      <rect x="720" y="475" rx="4" ry="4" width="340" height="70" />
      <rect x="0" y="560" rx="4" ry="4" width="340" height="70" />
      <rect x="360" y="560" rx="4" ry="4" width="340" height="70" />
      <rect x="720" y="560" rx="4" ry="4" width="340" height="70" />
      {/* cards */}
      {/* box-3 */}
    </ContentLoader>
  );
}

import styled from 'styled-components';
import { Card } from '../../../components/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .loading {
    display: flex;
    justify-content: center;
  }
`;

export const BannerContent = styled.img`
  width: 100%;
  max-width: 1235px;
  cursor: pointer;
  margin-bottom: 10px;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

interface IPropsTabContent {
  selected: string;
}

export const TabContent = styled.div<IPropsTabContent>`
  background: ${(props) => props.theme.white};
  display: flex;
  position: relative;
  z-index: 1;
  border-radius: 8px;
  width: 100%;
  min-width: 300px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 12px;
    height: 3px; /* width of the entire scrollbar */
  }

  span {
    padding: 0.625rem 1.5rem;
    margin: 0;
    cursor: pointer;
    white-space: nowrap;
  }

  .selected {
    background: #fff;
    font: 500 1rem 'Poppins', sans-serif;
    color: #EC6643;
    border-top: 3px solid #EC6643;
    border-radius: ${(props) => (props.selected === '' ? '8px 0 0 8px' : '0')};
  }
`;

export const CardZ1 = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  gap: 1rem;

  .row {
    display: flex;
    flex-direction: row !important;
    gap: 10px;
    width: 100%;
    padding-left: 1rem;
    span {
      width: auto;
      padding: 0;
      cursor: pointer;
    }

    .selected {
      color: ${(props) => props.theme.primary};
      font-weight: bold;
      border-bottom: 2px solid ${(props) => props.theme.primary};
    }
  }
`;

export const RowComment = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: start;

  .buttonInteraction {
    display: flex;
    align-items: center;
    gap: 10px;
    color: ${(props) => props.theme.white};
    background: transparent;
    color: ${(props) => props.theme.primary};
    border: none;
    border-bottom: 1px solid ${(props) => props.theme.primary};
    outline: none;
    padding: 0;
    border-radius: 0;
    transition: filter 0.3s;

    :hover {
      filter: brightness(0.9);
    }
  }

  @media (max-width: 400px) {
    width: 100%;
  }
  @media (max-width: 360px) {
    flex-direction: column;
  }
`;

export const RowButtonComment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 400px) {
    /* flex-direction: column; */
    gap: 10px;
    width: 100%;
    /* button {
      width: 100%;
      justify-content: center;
    } */
  }
`;

export const NewComment = styled.form`
  width: 100%;

  textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #c9c9c9;
    border-radius: 8px;
    height: 120px;
    resize: none;
    color: black;
  }

  .attentionMessage {
    font-size: 0.875rem;
    color: ${(props) => props.theme['gray-700']};
    margin: 0;

    a {
      color: ${(props) => props.theme.primary};
      :hover {
        filter: brightness(0.9);
        transition: filter 0.3s;
      }
    }
  }
`;

export const Comments = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const BackToTop = styled.div`
  position: fixed;
  bottom: 10px;
  right: 10px;
  background: ${(props) => props.theme.white};
  box-shadow: 0 0 0 2px ${(props) => props.theme.primary};
  border-radius: 100%;
  z-index: 100;
  cursor: pointer;

  @media (max-width: 480px) {
    bottom: 30px;
  }
`;

export const WarningNewMessage = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: start;
  background: #303F4B ;
  border-radius: 8px;

  border: 1px solid var(--Gray-gray-100, #D4D6DB);

  padding: 0.5rem 0.75rem;
  margin-bottom: 0.75rem;
  p {
    margin: 0;
    font-size: 0.75rem;
    color: #ECF0F3;
    font-weight: 400;
  }
`;

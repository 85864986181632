import { ChangeEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Input } from '../../../../../../components/Input';
import { transformMaskCurrency } from '../../../../../../utils/transformMaskCurrency';
import { Card, ContainerCalculate } from './styles';
import { ButtonDesign } from '../../../../../../components/ButtonDesign';

interface IPropsCalculate {
  initialValue: number;
  initialPercent: number;
  interestType: 'year' | 'monthly';
}

export function CalculateFinancialIndependence({
  initialValue,
  initialPercent,
  interestType,
}: IPropsCalculate) {
  let initialValueAmount = initialValue.toString();
  initialValueAmount = initialValueAmount.replace(/\D/g, '');
  initialValueAmount = initialValueAmount.replace(/(\d)(\d{2})$/, '$1,$2');
  initialValueAmount = initialValueAmount.replace(/(?=(\d{3})+(\D))\B/g, '.');

  const [amount, setAmount] = useState<number | string>(initialValueAmount);
  const [finalIncome, setFinalIncome] = useState<number>(0);

  const { handleSubmit, register, setValue } = useForm();
  useEffect(() => {
    setValue('juros', initialPercent);
  }, [initialPercent]);

  function submit(data: any) {
    data.total = Number(
      amount
        .toString()
        .replace(/\D/g, '')
        .replace(/(\d)(\d{2})$/, '$1.$2')
    );
    if (data.typePeriodInterest === 'year') {
      let taxJuros = data.juros / 100;

      let a = 1 + taxJuros;
      let potencial = 1 / 12;
      let b = Math.pow(a, potencial);
      b = b - 1;
      b = b * 100;

      data.juros = parseFloat(b.toFixed(4) ?? 0);

      data.total = ((data.total * data.juros) / 100).toFixed(2);
    } else {
      data.juros = 100 / data.juros;
      data.total = (data.total / data.juros).toFixed(2);
    }

    setFinalIncome(data.total);
  }

  const rendaMask = transformMaskCurrency(finalIncome);

  function onChangeSetMount(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    setAmount(value);
  }

  return (
    <ContainerCalculate>
      {amount !== 0 && (
        <Card>
          <span>
            Sua renda mensal será de: <strong>{rendaMask}</strong>
          </span>
          <br />

          <form onSubmit={handleSubmit(submit)}>
            <div>
              <label htmlFor="mount">Montante</label>
              <Input
                mask="currency"
                id="mount"
                onChange={onChangeSetMount}
                value={amount}
              />
            </div>
            <div className="onPeriod maskPercent">
              <label>Taxa de juros dos seus rendimentos</label>
              <input
                type="number"
                step="0.0001"
                placeholder="ex: 2"
                {...register('juros')}
              />
              <select
                defaultValue={interestType}
                {...register('typePeriodInterest')}
              >
                <option value="year">Anual</option>
                <option value="monthly">Mensal</option>
              </select>
            </div>
            <ButtonDesign
              style={{ maxWidth: 'none', width: 'calc(100% - 2rem - 4px)' }}
              type="submit"
            >
              Calcular
            </ButtonDesign>
          </form>
        </Card>
      )}
    </ContainerCalculate>
  );
}

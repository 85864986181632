import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.white};
  padding: 2rem;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-left: 1px solid var(--Gray-gray-100, #D4D6DB);
  border-right: 1px solid var(--Gray-gray-100, #D4D6DB);
  border-bottom: 1px solid var(--Gray-gray-100, #D4D6DB);

  .row {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 auto;
    gap: 1rem;
    border: 1px solid ${(props) => props.theme['gray-n-300']};
    border-radius: 16px;
    padding: 1.5rem;
    label {
      margin-bottom: 3px;
      color: ${(props) => props.theme['gray-n-700']};
      font-weight: 400;
      font-size: 1.125rem;
    }
    .maskPercent {
      position: relative;
      ::before {
        content: '%';
        position: absolute;
        right: 1.3rem;
        bottom: 0.5rem;
        color: ${(props) => props.theme['text-body']};
        font-family: 'Poppins', sans-serif;
        font-size: 1rem;
      }
    }
    .inputContent {
      display: flex;
      flex-direction: column;
      padding: 0;
      width: 100%;

      .taxaAnual{
          border-bottom: 3px solid #EC6643;
      }
      input {
        all: unset;
        padding: 8px;
        border-bottom: 2px solid ${(props) => props.theme['gray-n-300']};
        font-size: 16px;
        color: ${(props) => props.theme.black};
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type='number'] {
        -moz-appearance: textfield;
      }

      label {
        font-weight: 500;
        font-size: 1.125rem;
        color: ${(props) => props.theme['gray-600']};
      }

      @media (max-width: 480px) {
        width: 100%;
        input {
          width: 100%;
        }
      }
    }
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
`;

export const Content = styled.div`
  padding: 1rem;

  width: 100%;

  p {
    margin-top: 1.5rem;
    font-size: 1.25rem;
    color: ${(props) => props.theme['gray-n-600']};
  }

  @media (max-width: 480px) {
    p {
      font-size: 0.875rem;
    }
  }
`;

export const WarningText = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  background-color: #f0f9ff;
  padding: 0.75rem;
  border-radius: 12px;
  svg {
    width: 2rem;
    height: 2rem;
    color: #0093ef;
  }
  p {
    margin: 0;
    color: ${(props) => props.theme['gray-n-600']};
    font-size: 1.25rem;
  }
`;

import styled from 'styled-components';

export const AdminPlansContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
`;

export const TabContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  border-radius: 8px;

  background: ${(props) => props.theme.white};
  width: 100%;
`;

interface IPropsButtonTab {
  isActive: boolean;
}

export const ButtonTab = styled.button<IPropsButtonTab>`
  all: unset;
  padding: 1rem;
  color: ${(props) =>
    props.isActive ? props.theme.white : props.theme['primary-800']};
  background: ${(props) =>
    props.isActive ? props.theme['primary-800'] : 'transparent'};
  font-weight: 500;
  font-size: 1rem;
  :first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`;

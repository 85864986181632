import { ButtonHTMLAttributes, ReactNode } from 'react';
import { ButtonContainer } from './styles';

interface IPropsButtonDesign extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  br?: 0 | 4 | 8 | 'full';
  size?: 'btn-default' | 'btn-medium' | 'btn-small';
  isOutline?: boolean;
}

/**
 *
 * @param children Conteúdo que vai dentro do botão
 * @param br Border Radius 0 = 0px | 4 = 4px | 8 = 8px | full = bordar totalmente arredondadas
 * @param isOutline Deixar o botão com background transparente e com uma borda azul com o mesmo tom do texto
 *
 */

export function ButtonDesign({
  children,
  size = 'btn-default',
  br = 8,
  isOutline = false,
  ...props
}: IPropsButtonDesign) {
  return (
    <ButtonContainer isOutline={isOutline} br={br} size={size} {...props}>
      {children}
    </ButtonContainer>
  );
}

import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Popover, Tooltip } from 'antd';
import validator from 'validator';
import ReactLoading from 'react-loading';
import ReactInputMask from 'react-input-mask';
import { useForm } from 'react-hook-form';
import { Eye, EyeClosed } from '@phosphor-icons/react';
import {
  loadingToggleAction,
  loginAction,
} from '../../store/actions/AuthActions';
// image
import { handleToast } from '../../components/Toast';
import api from '../../services/api';
import {
  AsideContent,
  Container,
  ContainerInput,
  Content,
  ContentForm,
  ContentInput,
  InfoValidationPassword,
  ValidationPassword,
} from './RegistrationStyles';
import asideImg from '../../assets/loginAside.svg';
import { ButtonStyle } from '../../components/ButtonStyle';
import { InputStyle } from '../../components/InputStyle';
import logoImg from '../../assets/logo/logoOrange.png';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { showZodErrorToast } from '../../utils/zodToast';

const formatPhoneNumber = (value) => value.replace(/[\s()-]/g, '');

const schema = z.object({
  Name: z.string().min(3, { message: 'Informe seu nome' }),
  cel: z
    .string()
    .regex(/^\(\d{2}\) \d{5}-\d{4}$/, {
      message: 'Informe o numero do seu telefone',
    })
    .refine((value) => formatPhoneNumber(value).length === 11, {
      message: 'O número de telefone deve conter exatamente 11 dígitos.',
    }),
  email: z.string().email({ message: 'E-mail inválido' }),
  password: z
    .string()
    .min(8, { message: 'A sua senha deve ter no mínimo 8 caracteres' })
    .regex(/[0-9]/, { message: 'Deve ter no mínimo 1 número' })
    .regex(/[a-zA-Z]/, { message: 'Deve ter no mínimo 1 letra' })
    .regex(/[@#$!%*?&]/, {
      message: 'Deve ter no mínimo 1 símbolo ex: @#$!%*?&',
    }),
});

export default function Register() {
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [typeLoading, setTypeLoading] = useState('Register');
  const [viewPassword, setViewPassword] = useState(false);

  const dispatch = useDispatch();
  const watchPasswords = watch('password');
  const validateLettersInPassword = !!watchPasswords?.match(/[A-z]/);
  const validateNumberInPassword = !!watchPasswords?.match(/[0-9]/);
  const validateSpecialCharactersInPassword = !!watchPasswords?.match(
    /[!|@|#|$|%|^|&|*|(|)|-|_]/
  );

  const paramsTraffic = window.location.search
    .substr(1)
    .split('&')
    .reduce((queryParams, param) => {
      const [key, value] = param.split('=');
      queryParams[key] = value;
      return queryParams;
    }, {});
    const handleFocus = (event) => {
      const input = event.target;
      const label = input.previousElementSibling;
      const placeholder = input.nextElementSibling;
      label.classList.add('active');
      placeholder.classList.add('visible');
    };
    
    const handleBlur = (event) => {
      const input = event.target;
      const label = input.previousElementSibling;
      const placeholder = input.nextElementSibling;
      if (!input.value) {
        label.classList.remove('active');
        placeholder.classList.remove('visible');
      }
    };

  useEffect(() => {
    showZodErrorToast(errors);
  }, [errors]);
  function handleValidate(_data) {
    // const emailValid = validator.isEmail(_data.email);
    // if (!_data.Name) {
    //   return handleToast(true, 'Informe o nome completo');
    // }
    // if (!_data.cel) {
    //   return handleToast(true, 'Informe o celular');
    // }
    // if (emailValid === false) {
    //   return handleToast(true, 'Informe um email valido');
    // }
    // if (!_data.password) {
    //   return handleToast(true, 'Informe uma senha');
    // }
    // if (_data.password.length < 8) {
    //   return handleToast(true, 'a senha deve conter pelo menos 8 caracteres');
    // }
    // if (!_data.termsofuse) {
    //   return handleToast(
    //     true,
    //     'Você precisa aceitar os termos de uso da plataforma'
    //   );
    // }
  }

  async function onSignUp(data) {
    setLoading(true);
    setTypeLoading('Register');
    data.email = data.email.replace(/ /g, '');

    data.termsofuse = true;

    if (handleValidate(data)) {
      setLoading(false);
      return;
    }

    data.username = data.email;
    data.cel = data.cel.replace(/\D/g, '');
    data.ddd = data.cel.toString().substring(0, 2);
    data.telefone = data.cel.toString().substring(2);
    data.email = data.email.toLowerCase();
    data.step = 1;

    if (paramsTraffic.utm_campaign) {
      data.utm_campaign = paramsTraffic.utm_campaign;
    } else {
      data.utm_campaign = '';
    }
    if (paramsTraffic.utm_medium) {
      data.utm_medium = paramsTraffic.utm_medium;
    } else {
      data.utm_medium = '';
    }
    if (paramsTraffic.utm_source) {
      data.utm_source = paramsTraffic.utm_source;
    } else {
      data.utm_source = '';
    }
    await api
      .post('/auth/register/', data)
      .then((response) => {
        setTypeLoading('Login');
        handleToast(false, 'Criado com sucesso');

        dispatch(loadingToggleAction(true));
        dispatch(
          loginAction({
            email: data.email,
            password: data.password,
            history,
            register: true,
          })
        );
        return;
      })
      .catch((err) => {
        setLoading(false);
        return handleToast(true, err.response.data.message);
      });
  }

  return (
    <Container>
      <AsideContent>
        <img src={asideImg} />
      </AsideContent>

      <Content>
        <ContentForm>
          <img src={logoImg} alt="logo" />
          <header>
            <h2>Crie sua conta</h2>
          </header>

          <form onSubmit={handleSubmit(onSignUp)} autoComplete="off">
            <ContainerInput>
              <InputStyle
                label=""
                isError={errors.Name ? true : false}
                errorMessage={errors?.Name?.message}
              >
                <ContentInput>
                  <label htmlFor="name">Nome *</label>
                  <input
                    type="text"
                    id="name"
                    placeholder="Insira seu nome"
                    {...register('Name')}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                  <span></span>
                </ContentInput>
              </InputStyle>
              <InputStyle
                label=""
                isError={errors.cel ? true : false}
                errorMessage={errors?.cel?.message}
              >
                <ContentInput>
                  <label htmlFor="cel">Telefone *</label>
                  <ReactInputMask
                    type="text"
                    id="cel"
                    mask="(99) 99999-9999"
                    placeholder=" "
                    {...register('cel')}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                  <span></span>
                </ContentInput>
              </InputStyle>
              <InputStyle
                label=""
                isError={errors.email ? true : false}
                errorMessage={errors?.email?.message}
              >
                <ContentInput>
                  <label htmlFor="email">E-mail *</label>
                  <input
                    type="text"
                    id="email"
                    placeholder="Insira seu e-mail"
                    {...register('email')}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                  <span></span>
                </ContentInput>
              </InputStyle>
              <InputStyle
                label=""
                suffix={
                  <i className='verSenha'>
                    {viewPassword ? (
                      <Eye
                        onClick={() => setViewPassword(!viewPassword)}
                        size={25}
                        style={{ 
                          cursor: 'pointer',
                          marginTop: '15%',
                         }}
                      />
                    ) : (
                      <EyeClosed
                        onClick={() => setViewPassword(!viewPassword)}
                        size={25}
                        style={{ cursor: 'pointer',
                          marginTop: '15%',
                         }}
                      />
                    )}
                  </i>
                }
                isError={errors.password ? true : false}
                errorMessage={errors?.password?.message}
              >
                <Popover
                  content={
                    <ValidationPassword>
                      <InfoValidationPassword
                        error={watchPasswords?.length >= 8}
                      >
                        <span />
                        Deve ter no mínimo 8 caracteres{' '}
                      </InfoValidationPassword>
                      <InfoValidationPassword error={validateNumberInPassword}>
                        <span />
                        Deve ter no mínimo 1 número
                      </InfoValidationPassword>
                      <InfoValidationPassword error={validateLettersInPassword}>
                        <span />
                        Deve ter no mínimo 1 letra
                      </InfoValidationPassword>

                      <InfoValidationPassword
                        error={validateSpecialCharactersInPassword}
                      >
                        <span />
                        Deve ter no mínimo 1 símbolo ex: @#$!{' '}
                      </InfoValidationPassword>
                    </ValidationPassword>
                  }
                  title="Crie uma senha segura"
                  trigger="focus"
                >
                  <ContentInput>
                    <label className='labelSenha' htmlFor="password">Senha *</label>
                    <input
                      className='inputSenha'
                      type={viewPassword ? 'text' : 'password'}
                      id="password"
                      placeholder="Insira sua senha"
                      {...register('password')}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                    <span></span>
                  </ContentInput>
                </Popover>
              </InputStyle>
              
                <input type="checkbox" id="agreeTerms" name="agreeTerms"/>
                <div className="useOfTerms">
                  <a className='spanTerms'>
                    Ao criar sua conta você concorda com os{' '}
                    <a
                      className='linkUseOfTerms'
                      href="https://ibox6.com.br/politica-de-privacidade/"
                      target="_blank"
                    >
                      termos de uso
                    </a>{' '}
                  </a>
                </div>
              
            </ContainerInput>

            {loading ? (
              <ButtonStyle disabled={loading} color="#fff" type="submit">
                <ReactLoading
                  type="balls"
                  color="#FFF"
                  width="25px"
                  height="25px"
                />
              </ButtonStyle>
            ) : (
              <ButtonStyle disabled={loading} color="#fff" type="submit">
                CRIE SUA CONTA
              </ButtonStyle>
            )}
            <ContentInput>
              <div
                className="useOfTerms"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <a className='buttonLogin'>
                  Já é cadastrado? <a href="login">Entrar</a>{' '}
                </a>
              </div>
            </ContentInput>
          </form>
        </ContentForm>
      </Content>
    </Container>
  );
}
import ReactApexChart from 'react-apexcharts';

interface IPropsImoveis {
  imovels: {
    Area: number;
    Endereco: string;
    Estado: string;
    ImovelVacancia: string;
    Mes: string;
    Nome: string;
    NumUnidades: number;
    PercentInadimplencia: number;
    PercentReceitasFII: number;
    PercentVacancia: number;
    competencia: string;
  }[];
}

export function GraphicImoveis({ imovels }: IPropsImoveis) {
  const newDataImovels = imovels.map((imovel) => {
    return {
      estado: imovel.Estado,
      unidades: imovel.NumUnidades,
    };
  });
  const groupByCategory = newDataImovels.reduce((group, product) => {
    const { estado } = product;
    //@ts-ignore
    group[estado] = group[estado] ?? [];
    //@ts-ignore
    group[estado].push(product);
    return group;
  }, {});
  let removeRepeatedItems = newDataImovels.map((item) => item.estado);
  //@ts-ignore
  removeRepeatedItems = [...new Set(removeRepeatedItems)];


  const dataGraphic = {
    //@ts-ignore
    series: removeRepeatedItems.map((data) => groupByCategory[data].length),
    options: {
      labels: removeRepeatedItems.map((data) => data),
      chart: {
        type: 'donut',
      },
      legend: {
        position: 'bottom',
      },
      // colors:['#F44336', '#E91E63'],
      

      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                label: 'Ativos',
                formatter: () => newDataImovels.length,
              },
            },
          },
        },
      },
    },
  };

  return (
    <ReactApexChart
      width={
        window.screen.width <= 390
          ? 280
          : window.screen.width <= 480
          ? 350
          : 500
      }
      //@ts-ignore
      options={dataGraphic.options}
      series={dataGraphic.series}
      type="donut"
    />
  );
}

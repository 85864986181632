import { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { useParams } from 'react-router-dom';
import { IPropsUser } from '../../../utils/@types/user';

import { Comment } from '../../../components/Comment';
import { useTitleAndUser } from '../../../hooks/userTitle';
import axios from 'axios';
import { IPropsComment } from '../../../utils/@types/comment';

import LoadingSpinner from '../../../jsx/components/Finance/LoadingSpinner';
import { InfinityScroll } from '../../../components/InfinityScroll';
import { CardProfileUser } from '../../../jsx/components/CardProfileUser';
import { ArrowUp } from '@phosphor-icons/react';
import { BackToTop, Col, Comments, Container } from './styles';

interface IComment {
  count: number;
  next: string;
  previous: string;
  results: IPropsComment[];
  responseComments: IPropsComment[];
}

export function UserPosts() {
  //@ts-ignore
  const { user, setTitle } = useTitleAndUser();
  //@ts-ignore
  const { slug } = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [newLoading, setNewLoading] = useState<boolean>(false);
  //@ts-ignore
  const [profileUser, setProfileUser] = useState<IPropsUser>({});

  const [comments, setComments] = useState<IComment>({
    count: 0,
    next: '',
    previous: '',
    results: [],
    responseComments: [],
  });
  // const dispatch = useDispatch();
  useEffect(() => {
    if (profileUser.slug) {
      handleCommentsUser(profileUser.slug);
      setTitle(profileUser.Name.split(' ')[0]);
    }
  }, [profileUser]);
  useEffect(() => {
    handleUserProfile();
  }, []);
  async function handleUserProfile() {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/${slug}`)
      .then((response) => {
        setProfileUser(response.data);
      })
      .catch((err) => {});
    setLoading(false);
  }

  async function handleCommentsUser(slug: string) {
    setLoading(true);
    setNewLoading(false);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/social/api/posts/profile/${slug}/posts/`
      )
      .then((response) => {
        let responseComments = response.data.results.filter(
          (comment: IPropsComment) => comment.parent !== null
        );

        let res = response.data.results.filter(
          (comment: IPropsComment) => comment.parent === null
        );

        res = res.map((resp: IPropsComment) => {
          return {
            ...resp,
            responseComment: responseComments.filter(
              (item: IPropsComment) => item.parent.id === resp.id
            ),
          };
        });

        setComments({
          ...response.data,
          results: res,
          responseComments: responseComments,
        });

        setLoading(false);
      })
      .catch((err) => {});
    setLoading(false);
    setNewLoading(true);
  }

  async function handleInfinityComments(link: string) {
    setNewLoading(false);
    await axios
      .get(link)
      .then((response) => {
        let responseComments = response.data.results.filter(
          (comment: IPropsComment) => comment.parent !== null
        );

        let res = response.data.results.filter(
          (comment: IPropsComment) => comment.parent === null
        );

        res = res.map((resp: IPropsComment) => {
          return {
            ...resp,
            responseComment: [
              ...responseComments.filter(
                (item: IPropsComment) => item.parent.id === resp.id
              ),
              ...comments.responseComments.filter(
                (item) => item.parent.id === resp.id
              ),
            ],
          };
        });

        setComments({
          ...response.data,
          results: [...comments.results, ...res],
          responseComments: [...comments.responseComments, ...responseComments],
        });
      })
      .catch((err) => {});

    setNewLoading(true);
  }

  function handleDeleteComment(id: number | string) {
    let newCommentsList = comments.results.filter(
      (comment) => comment.id !== id
    );
    setComments({
      ...comments,
      results: newCommentsList,
    });
  }

  async function handleLikeComment(id: string | number) {
    const comment = comments.results.find((comment) => comment.id === id);
    if (comment?.liked.includes(user.id)) {
      await axios
        .delete(
          `${process.env.REACT_APP_API_URL}/social/api/posts/${id}/likes/`
        )
        .then((response) => {
          const liked = comments.results.findIndex(
            (comment) => comment.id === id
          );
          let newComments = comments.results;
          newComments[liked].liked = newComments[liked].liked.splice(
            user.id,
            1
          );
          setComments({
            ...comments,
            results: newComments,
          });
        })
        .catch((err) => {});
      return;
    }
    await axios
      .post(`${process.env.REACT_APP_API_URL}/social/api/posts/${id}/likes/`)
      .then((response) => {
        const liked = comments.results.findIndex(
          (comment) => comment.id === id
        );
        let newComments = comments.results;
        newComments[liked].liked = [...newComments[liked].liked, user.id];
        setComments({
          ...comments,
          results: newComments,
        });
      })
      .catch((err) => {});
  }

  return (
    <>
      <Container>
        <Col>
          {profileUser.slug && <CardProfileUser userProfile={profileUser} />}

          {loading ? (
            <LoadingSpinner />
          ) : (
            <Comments>
              {comments.results.length > 0 &&
                comments.results.map((comment, index) => (
                  <Comment
                    key={index}
                    //@ts-ignore
                    comment={comment}
                    handleLikeComment={handleLikeComment}
                    deleteComment={(id) => handleDeleteComment(id)}
                  />
                ))}
            </Comments>
          )}
          {!newLoading && (
            <div className="loading">
              <ReactLoading
                type="spokes"
                color="var(--primary)"
                width="50px"
                height="50px"
              />
            </div>
          )}
          {newLoading &&
            comments.results.length + comments.responseComments.length <
              comments.count && (
              <InfinityScroll
                fetchMore={() => handleInfinityComments(comments.next)}
              />
            )}
        </Col>
      </Container>

      {/* <BackToTop
        onClick={() => window.scroll(0, 0)}
        title="Voltar ao topo da página"
      >
        <ArrowUp size={32} color="var(--primary)" />
      </BackToTop> */}
    </>
  );
}

import { useRef, useState } from 'react';
import moment from 'moment';
import { Camera, User } from '@phosphor-icons/react';
import { IPropsUser } from '../../../utils/@types/user';
import { BannerUser, Container, UserContainer, UserContent } from './styles';
import bannerDefaultImg from '../../../assets/background/banner-comunidade.png';
import { useTitleAndUser } from '../../../hooks/userTitle';
import axios from 'axios';
import { userRoutes } from '../../../utils/RoutesApi';
import FollowButton from '../../../social/components/FollowButton';
import { Link } from 'react-router-dom';

interface IPropsCardUser {
  userProfile: IPropsUser;
}

export function CardProfileUser({ userProfile }: IPropsCardUser) {
  //@ts-ignore
  const { user, setUser } = useTitleAndUser();
  const getDataUser = JSON.parse(
    //@ts-ignore
    localStorage.getItem(process.env.REACT_APP_USER_VERSION)
  );
  const bannerRef = useRef();
  const imageRef = useRef();
  const [attUser, setAttUser] = useState<boolean>(false);

  const handleChangeBannerProfile = (event: any) => {
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append('banner', image, image.name);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}${userRoutes.USER_UPDATE_IMAGE}`,
        formData
      )
      .then((response) => {
        let res = response.data;
        if (res) {
          const includeImage =
            res.image &&
            res.image.match(
              'https://storage.googleapis.com/ibox6-wordpress.appspot.com/'
            );

          const isIncludeImage = !!includeImage;

          const includeBanner =
            res.banner &&
            res.banner.match(
              'https://storage.googleapis.com/ibox6-wordpress.appspot.com/'
            );

          const isIncludeBanner = !!includeBanner;

          if (isIncludeImage) {
            let obj = getDataUser;
            obj.user.profile = res;
            localStorage.setItem(
              //@ts-ignore
              process.env.REACT_APP_USER_VERSION,
              JSON.stringify(obj)
            );
            setUser(obj.user);
          }

          if (isIncludeBanner) {
            let obj = getDataUser;
            obj.user.profile = res;
            localStorage.setItem(
              //@ts-ignore
              process.env.REACT_APP_USER_VERSION,
              JSON.stringify(obj)
            );
            setUser(obj.user);
          }

          if (isIncludeBanner || isIncludeImage) {
            return;
          }

          let obj = getDataUser;
          obj.user.profile = res;
          localStorage.setItem(
            //@ts-ignore
            process.env.REACT_APP_USER_VERSION,
            JSON.stringify(obj)
          );
          setUser(obj.user);
        }
      })
      .catch((err) => {});
  };

  const handleChangeImageProfile = (event: any) => {
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append('image', image, image.name);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}${userRoutes.USER_UPDATE_IMAGE}`,
        formData
      )
      .then((response) => {
        let res = response.data;
        if (res) {
          const includeImage =
            res.image &&
            res.image.match(
              'https://storage.googleapis.com/ibox6-wordpress.appspot.com/'
            );

          const isIncludeImage = !!includeImage;

          const includeBanner =
            res.banner &&
            res.banner.match(
              'https://storage.googleapis.com/ibox6-wordpress.appspot.com/'
            );

          const isIncludeBanner = !!includeBanner;

          if (isIncludeImage) {
            let obj = getDataUser;
            obj.user.profile = res;
            localStorage.setItem(
              //@ts-ignore
              process.env.REACT_APP_USER_VERSION,
              JSON.stringify(obj)
            );
            setUser(obj.user);
          }

          if (isIncludeBanner) {
            let obj = getDataUser;
            obj.user.profile = res;
            localStorage.setItem(
              //@ts-ignore
              process.env.REACT_APP_USER_VERSION,
              JSON.stringify(obj)
            );
            setUser(obj.user);
          }

          if (isIncludeBanner || isIncludeImage) {
            return;
          }

          let obj = getDataUser;
          obj.user.profile = res;

          localStorage.setItem(
            //@ts-ignore
            process.env.REACT_APP_USER_VERSION,
            JSON.stringify(obj)
          );
          setUser(obj.user);
        }
      })
      .catch((err) => {});
  };

  const handleEditAvatar = () => {
    //@ts-ignore
    imageRef.current.click();
  };

  const handleEditBanner = () => {
    //@ts-ignore
    bannerRef.current.click();
  };

  return (
    <Container
      banner={
        userProfile.profile.banner !== null && userProfile.profile.banner !== ''
      }
    >
      {user.id === userProfile.id && (
        <div
          className="editBanner"
          title="Editar banner"
          onClick={handleEditBanner}
        >
          <Camera size={24} />
          <input
            hidden
            onChange={(event) => handleChangeBannerProfile(event)}
            //@ts-ignore
            ref={bannerRef}
            type="file"
          />
        </div>
      )}
      {userProfile.profile.banner !== null &&
        userProfile.profile.banner !== '' && (
          <BannerUser src={userProfile.profile.banner} />
        )}
      <UserContainer>
        {user.id === userProfile.id && (
          <div className="editProfile" title="Editar foto de perfil">
            <Camera size={24} onClick={handleEditAvatar} />
            <input
              hidden
              onChange={(event) => handleChangeImageProfile(event)}
              //@ts-ignore
              ref={imageRef}
              type="file"
            />
          </div>
        )}
        {userProfile.profile.image !== null &&
        userProfile.profile.image !== '' ? (
          <img src={userProfile.profile.image} />
        ) : (
          <div className="defaultUser">
            <User />
          </div>
        )}

        <UserContent
          banner={
            userProfile.profile.banner !== null &&
            userProfile.profile.banner !== ''
          }
        >
          <main>
            <h2>{userProfile.Name}</h2>
            {userProfile.id === user.id && (
              <Link to="/profile-edit">Editar perfil</Link>
            )}
          </main>
          <time dateTime={userProfile.created}>
            Entrou em {moment(userProfile.created).format('ll')}
          </time>
        </UserContent>
      </UserContainer>
      {!userProfile.profile.banner && (
        <img
          src={bannerDefaultImg}
          alt="logo IBox 6"
          className="bannerDefault"
        />
      )}
      {user.id !== userProfile.id && (
        <FollowButton
          attUser={attUser}
          setAttUser={setAttUser}
          userSlug={userProfile}
          className="follow"
        />
      )}
    </Container>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  gap: var(--Spacing-32, 32px);
  width: 100%;
  margin-left: 0;

  .ant-carousel .slick-prev {
    left: 0;
    z-index: 1;
  }
  .ant-carousel .slick-next {
    right: 0;
  }
`;

export const ContentCards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  width: 100%;
  margin-left: 0;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import {
  Check,
  CreditCard,
  FileText,
  MinusCircle,
} from '@phosphor-icons/react';

import {
  PaymentFormPlanContainer,
  Button,
  ContentInputs,
  Divider,
  PaymentFormContent,
  FunctionalityContent,
  ListFunctionalitiesContent,
  SelectedPlanContent,
  InputRadioContainer,
  ContentInputRadio,
  AlertBoletoMessage,
} from './styles';
import { IPropsNewPlan } from '../../../..';
import { IDataForm } from '../..';

interface IPropsAddressFormPlan {
  selectedPlan: IPropsNewPlan;
  onClearPlan: Function;
  defaultValueBackToScreen: IDataForm;
}

export function PaymentFormPlan({
  selectedPlan,
  onClearPlan,
  defaultValueBackToScreen,
}: IPropsAddressFormPlan) {
  const {
    register,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useFormContext();

  const paymentMethod = watch('payment_method');
  const isCreditCard = paymentMethod === 'credit_card';
  useEffect(() => {
    setValue('payment_method', 'credit_card');
  }, []);
  return (
    <PaymentFormPlanContainer>
      <PaymentFormContent>
        <h4>Forma de pagamento</h4>
        <ContentInputs>
          <ContentInputRadio>
            <InputRadioContainer selected={isCreditCard}>
              <label htmlFor="credit_card">
                <CreditCard />
                Cartão de crédito
                <input
                  type="radio"
                  {...register('payment_method')}
                  name="payment_method"
                  id="credit_card"
                  value="credit_card"
                  // hidden
                />
              </label>
            </InputRadioContainer>
            <InputRadioContainer selected={!isCreditCard}>
              <label htmlFor="boleto">
                <FileText /> Boleto Bancário
                <input
                  type="radio"
                  {...register('payment_method')}
                  name="payment_method"
                  id="boleto"
                  value="boleto"
                  // hidden
                />
              </label>
            </InputRadioContainer>
          </ContentInputRadio>
          {isCreditCard && (
            <>
              <ReactInputMask
                mask="9999 9999 9999 9999"
                placeholder="Número do cartão"
                {...register('card_number')}
              />
              <input
                type="text"
                placeholder="Nome no cartão"
                {...register('card_holder_name')}
              />
              <ReactInputMask
                mask="999.999.999-99"
                placeholder="CPF"
                {...register('cpf_card')}
              />
              <div className="rowContentInput">
                <ReactInputMask
                  mask="99/99"
                  type="text"
                  {...register('card_expiration_date')}
                  placeholder="Data de validade"
                />
                <input
                  type="text"
                  placeholder="CVV"
                  {...register('security_code')}
                />
              </div>
            </>
          )}
          {!isCreditCard && (
            <AlertBoletoMessage>
              Ao escolher a opção de pagamento por boleto, gostaríamos de
              lembrá-lo que pode haver uma demora na compensação bancária. O
              prazo para a compensação do boleto é de até 3 dias úteis após o
              pagamento, o que pode causar um atraso na liberação do seu acesso
              aos recursos premium da plataforma.
            </AlertBoletoMessage>
          )}
          {/* <input type="text" placeholder="Cidade" {...register('cidade')} /> */}
        </ContentInputs>
        <Button type="submit" disabled={isSubmitting}>
          Continuar
        </Button>
      </PaymentFormContent>
      <Divider />
      <SelectedPlanContent>
        <h4>Plano selecionado</h4>
        <h3>
          iBox6 <span>{selectedPlan.title}</span>
        </h3>
        <ListFunctionalitiesContent>
          {selectedPlan.funcionalities.map((func) => (
            <li key={func.label}>
              <FunctionalityContent disabled={func.disabled}>
                {func.disabled ? (
                  <MinusCircle weight="bold" />
                ) : (
                  <Check weight="bold" />
                )}
                <strong>{func.label}</strong>
              </FunctionalityContent>
            </li>
          ))}
        </ListFunctionalitiesContent>
        <Button type="button" variant="secondary" onClick={() => onClearPlan()}>
          Escolher outro plano
        </Button>
      </SelectedPlanContent>
    </PaymentFormPlanContainer>
  );
}

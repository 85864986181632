import styled from 'styled-components';

export const WalletContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  align-items: center;

  img {
    margin-left: auto;
    width: 100%;
    max-width: 25rem;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;

    img {
      margin-left: 0;
    }
  }
  @media (max-width: 480px) {
    .previewImg {
      width: 100%;
      max-width: 22rem;
    }
  }
`;

export const WalletCol = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;
  z-index: 1;
  text-align: start;
  align-items: flex-start;

  header {
    display: flex;
    flex-direction: column;
    span {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 200%;
      color: ${(props) => props.theme.primary};
    }
    h2 {
      font-weight: 500;
      font-size: 1.75rem;
      line-height: 150%;
      color: ${(props) => props.theme['gray-700']};
    }
  }
  p {
    margin: 3rem 0;
    font-weight: 400;
    font-size: 1.125rem;
    color: ${(props) => props.theme['gray-700']};
  }
  button {
    all: unset;
    background: ${(props) => props.theme.primary};
    padding: 1.25rem;
    text-align: center;
    width: 100%;
    max-width: 13.125rem;
    border-radius: 8px;
    color: ${(props) => props.theme.white};
    font-weight: 500;
    font-size: 1.125rem;

    justify-content: flex-start;
  }

  @media (max-width: 768px) {
    margin-top: 2rem;

    header {
      h2 {
        font-size: 1.125rem;
      }
    }
    p {
      font-size: 0.875rem;
    }
  }
`;

import PropTypes from 'prop-types';
import React from 'react';


import { APP_NAME } from '../../constants';

const PageTitle = ({ title }) => (
  <>
  </>
);

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default PageTitle;

import styled from 'styled-components';

export const ContainerNewPasswordForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

interface IPropsContentInput {
  suffix?: boolean;
}

export const ContentInput = styled.div<IPropsContentInput>`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  width: 100%;
  input {
    background: transparent;
    padding: 0.5rem;
    border-radius: 4px;
    border: ${(props) => props.theme.border};
    color: ${(props) => props.theme['gray-700']};
    padding-right: ${(props) => (props.suffix ? '3rem' : '0.5rem')};
    width: 100%;
  }

  i {
    position: absolute;
    right: 0.5rem;
    display: flex;
  }
`;

export const ValidationPassword = styled.ul`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;
`;

interface IPropsInfoValidation {
  error: boolean;
}
export const InfoValidationPassword = styled.li<IPropsInfoValidation>`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  color: ${(props) => props.theme['gray-700']};
  align-items: center;
  font-size: 0.75rem;

  svg {
    background: ${(props) =>
      !props.error ? props.theme.error : props.theme.success};
    border-radius: 100%;
    font-size: 1.1rem;
    opacity: 0.8;
    color: ${(props) => props.theme.white};
  }
`;

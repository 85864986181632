import ReactApexChart from 'react-apexcharts';

interface IPropsGraphicNote {
  noteValue: number;
  color: string;
}

export function GraphicNote({ noteValue, color }: IPropsGraphicNote) {
  const data = {
    series: [noteValue],
    options: {
      chart: {
        type: 'radialBar',
      },
      colors: [color],
      plotOptions: {
        radialBar: {
          hollow: {
            size: '40%',
          },
          dataLabels: {
            // show: false,
            name: {
              show: false,
            },
            value: {
              formatter: (val: number) => `${val.toFixed(0)}%`,
              fontSize: '12px',
              offsetY: 5,
            },
          },
        },
        grid: {
          padding: {
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
          },
        },
      },
    },
  };

  return (
    <ReactApexChart
      // @ts-ignore
      options={data.options}
      series={data.series}
      type="radialBar"
      height={100}
      width={50}
    />
  );
}

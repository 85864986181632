import { Brokerages } from '../../components/Brokerages/intex';
import { FormAporte } from './components/FormAporte';
import { SimulationContainer } from './styles';

interface IPropsSimulation{
  inicialTypeAporte:'action' | 'fii' | 'all' | 'broker'
}

export function Simulation({inicialTypeAporte}:IPropsSimulation) {
  return (
    <SimulationContainer>
      <Brokerages />
      <FormAporte inicialTypeAporte={inicialTypeAporte}/>
    </SimulationContainer>
  );
}

import styled from 'styled-components';

export const GraphicAccessForPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${(props) => props.theme['gray-600']};
  color: white;
  padding: 1rem;
`;

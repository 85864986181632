import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import ReactInputMask from 'react-input-mask';

import { IPropsVacancia } from '../../utils/@types';
import { ButtonDesign } from '../../../../../components/ButtonDesign';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontFamily: 'Poppins, sans-serif',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: 'Poppins, sans-serif',
  },
}));

const StyledBody = styled(TableBody)(
  ({ theme }) => `
  overflow-y: auto;
  max-height:500px;

`
);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const header = [
  { label: 'Data' },
  { label: 'Ocupação' },
  { label: 'Vacância Financeira' },

  { label: 'Vacância Fisica' },
];

interface IPropsTableVacancia {
  data: IPropsVacancia[];
  editVacancia: (date: string, value: number | string, type: string) => void;
  editData: Function;
  slug: string;
}

export function TableVacancia({
  data,
  editVacancia,
  editData,
  slug,
}: IPropsTableVacancia) {
  return (
    <TableContainer component={Paper} style={{}}>
      <Table
        sx={{ minWidth: 700, maxHeight: 500 }}
        aria-label="customized table"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            {header.map((item) => (
              <StyledTableCell key={item.label}>{item.label}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <StyledBody>
          {data.map((item, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell>
                <ReactInputMask
                  mask="99-9999"
                  defaultValue={moment(item.DataVancancia)
                    .format('MM-YYYY')
                    .toString()}
                  onChange={(e) =>
                    editVacancia(item.DataVancancia, e.target.value, 'date')
                  }
                  className="inputTable"
                />
                {/* {moment(item.DataVancancia).format('MM/YYYY')} */}
              </StyledTableCell>
              <StyledTableCell>
                <input
                  type="number"
                  step="0.01"
                  className="inputTable"
                  defaultValue={item.ValorPercOcupacao}
                  onChange={(e) =>
                    editVacancia(
                      item.DataVancancia,
                      Number(e.target.value),
                      'ocupacao'
                    )
                  }
                />
                %
              </StyledTableCell>
              <StyledTableCell>
                <input
                  type="number"
                  step="0.01"
                  className="inputTable"
                  defaultValue={item.ValorPercVacanciaFinanceira}
                  onChange={(e) =>
                    editVacancia(
                      item.DataVancancia,
                      Number(e.target.value),
                      'financeira'
                    )
                  }
                />
                %
              </StyledTableCell>
              <StyledTableCell>
                <input
                  type="number"
                  step="0.01"
                  className="inputTable"
                  defaultValue={item.ValorPercVacanciaFisica}
                  onChange={(e) =>
                    editVacancia(
                      item.DataVancancia,
                      Number(e.target.value),
                      'fisica'
                    )
                  }
                />
                %
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </StyledBody>
      </Table>
      <div style={{ padding: '0.5rem' }}>
        <ButtonDesign
          type="button"
          size="btn-small"
          onClick={() =>
            editData([
              ...data,
              {
                DataVancancia: moment().format('YYYY-MM'),
                ValorPercOcupacao: 0,
                ValorPercVacanciaFinanceira: 0,
                ValorPercVacanciaFisica: 0,
                Editado: false,
                competencia_CNPJ: `${moment().format('YYYY-MM')}-${slug}`,
              },
            ])
          }
        >
          Adicionar campo
        </ButtonDesign>
      </div>
    </TableContainer>
  );
}

import styled, { css } from 'styled-components';

interface IPropsCard {
  typePlan: 'Anual' | 'Mensal' | 'Free';
}

export const CardPlanContainer = styled.div<IPropsCard>`
  display: flex;
  flex-direction: column;
  position: relative;

  width: 100%;
  min-width: 21.25rem;
  max-width: 21.25rem;
  height: 100%;

  border-radius: 8px;
  background: ${(props) => props.theme.white};

  border: ${(props) =>
    props.typePlan === 'Free'
      ? `1px solid ${props.theme['white-300']}`
      : `2px solid ${props.theme['primary-800']}`};
`;

export const TypePlan = styled.div<IPropsCard>`
  display: flex;
  justify-content: flex-end;
  width: 7rem;
  height: 6rem;
  padding-top: 0.5rem;
  padding-right: 0.25rem;
  clip-path: polygon(100% 0, 0 0, 100% 69%);
  background: ${(props) =>
    props.typePlan === 'Free'
      ? props.theme['white-300']
      : props.theme['primary-800']};

  position: absolute;
  right: -1px;
  top: -1px;
  border-radius: 8px;
  span {
    font-size: 0.875rem;
    color: ${(props) =>
      props.typePlan === 'Free' ? props.theme['gray-400'] : props.theme.white};
    font-weight: 500;
    line-height: 21px;
  }
`;

export const HeaderCard = styled.header`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  margin: 1.5rem auto 0;
  width: 100%;

  h3 {
    margin: 0;
    font-size: 2.25rem;
    font-weight: 600;
    line-height: 54px;
    display: flex;
    gap: 0.75rem;
    align-items: first baseline;
    color: #e99e00;

    span {
      font-size: 1.75rem;
      color: #ffad00;
      font-weight: 400;
      line-height: 48px;
    }
  }

  .textFree {
    font-weight: 400;
    font-size: 0.75rem;
    color: #252525;
    line-height: 18px;
    margin: 0;
  }
`;

export const PriceContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    font-weight: 600;
    font-size: 3rem;
    color: #353840;
    line-height: 100%;
    margin: 0;
    span {
      font-size: 0.75rem;
      font-weight: 400;
    }
  }
  p {
    margin: 0;
    font-size: 0.75rem;
    font-weight: 400;
    color: #5e5e5e;
  }
`;

export const InformationText = styled.div`
  display: flex;
  justify-content: center;
  background: #f8b700;
  width: 100%;
  /* max-width: 21.25rem; */
  padding: 0.25rem;
  position: relative;

  h2 {
    font-weight: 600;
    font-size: 1.312rem;
    color: ${(props) => props.theme.white};
    margin: 0;
  }
`;

export const ListFunctionalitiesContent = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 1rem;
  margin-top: 2.25rem;
`;

interface IPropsFunc {
  disabled: boolean;
}

export const FunctionalityContent = styled.div<IPropsFunc>`
  display: grid;
  grid-template-columns: 1.125rem 1fr;

  gap: 0.75rem;
  width: 100%;

  svg {
    color: ${(props) => (props.disabled ? '#A4A6AC' : props.theme.white)};
    width: 1.125rem;
    height: 1.125rem;
    padding: ${(props) => (props.disabled ? 0 : '0.1rem')};
    font-size: 1rem;

    ${(props) =>
      !props.disabled &&
      css`
        background-color: ${props.theme.primary};
        border-radius: 100%;
      `}
  }

  strong {
    font-weight: 500;
    font-size: 0.875rem;
    color: ${(props) => (props.disabled ? '#A4A6AC' : '#353840')};
  }
`;

interface IPropsButtonSelect {
  currentPlan: boolean;
}

export const ButtonSelect = styled.button<IPropsButtonSelect>`
  padding: 0.5rem;
  /* topo | direita | inferior | esquerda */
  margin: 2.25rem 1rem 1rem;
  background: ${(props) =>
    props.currentPlan ? props.theme.white : props.theme['primary-800']};
  color: ${(props) =>
    props.currentPlan ? props.theme.primary : props.theme.white};
  border: ${(props) =>
    props.currentPlan ? `1px solid ${props.theme.primary}` : 0};
  border-radius: 8px;
  font-weight: 500;
  font-size: 1.5rem;
  text-align: center;
  :hover {
    filter: brightness(0.9);
    transition: filter 0.3s;
  }
`;

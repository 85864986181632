import React, { createContext, useEffect, useState } from 'react';
import { useActives } from '../../../../hooks/useActives';
import axios from 'axios';
import { baseURL } from '../../../../utils/baseURL';
import { walletRoutes } from '../../../../utils/RoutesApi';

interface IPropsStrategyContextProvider {
  children: React.ReactNode;
}

interface IPropsStrategyContext {
  action: number;
  fiis: number;
  rendaFixa: number;
  total: number;
  checkIfItIsBalanced: boolean;
  handleChangeActionPercentage: (percent: number) => void;
  handleChangeFiiPercentage: (percent: number) => void;
  handleChangeRendaFixaPercentage: (percent: number) => void;
  handleGetConfigStrategyWallet: () => void;
}

export const StrategyContext = createContext<IPropsStrategyContext>({
  action: 0,
  fiis: 0,
  checkIfItIsBalanced: false,
  rendaFixa: 0,
  total: 0,
  handleChangeActionPercentage: () => {},
  handleChangeFiiPercentage: () => {},
  handleChangeRendaFixaPercentage: () => {},
  handleGetConfigStrategyWallet: () => {},
});

export const StrategyContextProvider = ({
  children,
}: IPropsStrategyContextProvider) => {
  // @ts-ignore
  const { percentage } = useActives();
  const [action, setAction] = useState(0);
  const [fiis, setFiis] = useState(0);
  const [rendaFixa, setRendaFixa] = useState(0);
  const [total, setTotal] = useState(0);
  const [checkIfItIsBalanced, setCheckIfItIsBalanced] = useState(false);

  useEffect(() => {
    handleGetConfigStrategyWallet();
  }, []);
  useEffect(() => {
    setCheckIfItIsBalanced(
      percentage.action >= action &&
        percentage.fii >= fiis &&
        percentage.rendaFixa >= rendaFixa
    );
  }, [action, fiis, rendaFixa]);

  function handleGetConfigStrategyWallet() {
    axios
      .get(`${baseURL}/api${walletRoutes.WALLET_GET_CONFIG_STRATEGY}`)
      .then((response) => {
        let data = response.data.data;
        setFiis(data.PorcentagemFII);
        setAction(data.PorcentagemAcoes);
        setRendaFixa(data.PorcentagemRendaVariavel);
        setTotal(
          data.PorcentagemFII +
            data.PorcentagemAcoes +
            data.PorcentagemRendaVariavel
        );
      })
      .catch((err) => {});
  }

  function handleChangeActionPercentage(percentagem: number) {
    if (fiis + rendaFixa + percentagem > 100) {
      setAction(100 - rendaFixa - fiis);
      setTotal(100);
    } else {
      setAction(percentagem);
      setTotal(fiis + rendaFixa + percentagem);
    }
  }
  function handleChangeFiiPercentage(percentagem: number) {
    if (action + rendaFixa + percentagem > 100) {
      setFiis(100 - rendaFixa - action);
      setTotal(100);
    } else {
      setFiis(percentagem);
      setTotal(rendaFixa + action + percentagem);
    }
  }
  function handleChangeRendaFixaPercentage(percentagem: number) {
    if (fiis + action + percentagem > 100) {
      setRendaFixa(100 - action - fiis);
      setTotal(100);
    } else {
      setRendaFixa(percentagem);
      setTotal(fiis + action + percentagem);
    }
  }

  return (
    <StrategyContext.Provider
      value={{
        action,
        checkIfItIsBalanced,
        fiis,
        rendaFixa,
        total,
        handleChangeActionPercentage,
        handleChangeFiiPercentage,
        handleChangeRendaFixaPercentage,
        handleGetConfigStrategyWallet,
      }}
    >
      {children}
    </StrategyContext.Provider>
  );
};

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .bodyNewModal {
    padding: 1rem;
    select {
      margin-left: 1rem !important;
      border: 1px solid red !important;
    }
  }
`;

import { useActives } from '../../../hooks/useActives';

import { formatedAllValueCurrency } from '../../../utils/formatedAllvalueCurrency';
import { transformMaskCurrency } from '../../../utils/transformMaskCurrency';
import { Container } from './styles';

interface IPropsCardCorretora {
  nameBroken: string;
  patrimony: number;
  onClick?: () => void;
  active: boolean;
}

export function CardCorretora({
  nameBroken,
  patrimony,
  onClick,
  active = false,
}: IPropsCardCorretora) {
  //@ts-ignore
  const { showActive } = useActives();
  let newPatrimony = patrimony.toString().split('.');
  newPatrimony[1] ? null : (newPatrimony[1] = '00');

  const patrimonyValue = `${newPatrimony[0]}.${newPatrimony[1]}`;

  const formatedMoney = formatedAllValueCurrency(patrimonyValue);
  return (
    <Container
      value={patrimony.toString().length}
      onClick={onClick}
      active={active}
    >
      <header>
        <h3 title={nameBroken}>{nameBroken}</h3>
      </header>
      <main
        title={showActive ? transformMaskCurrency(patrimony) : 'R$ *******'}
      >
        <h2>{showActive ? formatedMoney : 'R$ *******'}</h2>
      </main>
    </Container>
  );
}

import { Tooltip } from 'antd';
import axios from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import ReactLoading from 'react-loading';
import { ButtonComponent } from '../../../../../../../../../components/ButtonComponent';
import { handleToast } from '../../../../../../../../../components/Toast';
import { userRoutes } from '../../../../../../../../../utils/RoutesApi';
import { ContentButtonForm, FormOfPayment, RowContentInput } from './styles';

const baseURL = process.env.REACT_APP_API_URL;

interface IPropsFormRegisterPlan {
  planId: string;
  setModalSelectedPlan: Function;
  updatePlan: Function;
}

export function FormRegisterPlan({
  planId,
  setModalSelectedPlan,
  updatePlan,
}: IPropsFormRegisterPlan) {
  const { register, handleSubmit, watch } = useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const paymentForm = watch('payment_method');

  async function submit(data: any) {
    setLoading(true);
    data.assinatura_id = planId;

    if (!data.payment_method) {
      return handleToast(true, 'Informe o metodo de pagamento');
    }
    if (data.payment_method === 'boleto') {
      data.card_number = '';
      data.security_code = '';
      data.card_holder_name = '';
      data.card_expiration_date = '';
    }
    if (data.payment_method === 'credit_card') {
      if (!data.card_number) {
        return handleToast(true, 'Informe o número do cartão');
      }
      data.card_number = data.card_number.replace(/\D/g, '');
      if (data.card_number.length !== 16) {
        return handleToast(true, 'Informe um número de cartão valido');
      }
      if (!data.card_holder_name) {
        return handleToast(true, 'Informe o nome no cartão');
      }
      if (!data.cpf_card) {
        return handleToast(true, 'Informe o cpf do proprietário do cartão');
      }
      data.cpf_card = data.cpf_card.replace(/\D/g, '');
      if (data.cpf_card.length !== 11) {
        return handleToast(true, 'Informe um cpf valido');
      }
      if (!data.card_expiration_date) {
        return handleToast(true, 'Informe a data de expiração do cartão');
      }
      if (!data.security_code) {
        return handleToast(true, 'Informe o CVV do cartão');
      }
      data.security_code = data.security_code.replace(/\D/g, '');
      if (data.security_code.length < 3) {
        return handleToast(true, 'Informe um CVV valido');
      }
    }

    await axios
      .post(`${baseURL}${userRoutes.PLANS_IR_REGISTER}`, data)
      .then((response) => {
        setLoading(false);
        setModalSelectedPlan(false);
        if (data.payment_method === 'boleto') {
          window.open(response.data.boleto_url);
          updatePlan();
        }
        return handleToast(false, response.data.message);
      })
      .catch((err) => {
        setLoading(false);
        handleToast(true, err.response.data.message);
      });
  }

  return (
    <FormOfPayment onSubmit={handleSubmit(submit)}>
      <p>Você precisara vincular sua conta da b3 para fazer a apuração</p>
      <select defaultValue="" {...register('payment_method')}>
        <option value="" disabled>
          Selecionar forma de pagamento
        </option>
        <option value="boleto">Boleto</option>
        <option value="credit_card">Cartão de crédito</option>
      </select>

      {paymentForm === 'credit_card' && (
        <>
          <ReactInputMask
            mask="9999 9999 9999 9999"
            type="text"
            placeholder="Número do cartão ex: 9999 9999 9999 9999"
            {...register('card_number')}
          />

          <input
            type="text"
            placeholder="Nome no cartão ex: José S. Soares"
            {...register('card_holder_name')}
          />

          <ReactInputMask
            mask="999.999.999-99"
            type="text"
            placeholder="CPF titular do cartão ex: 999.999.999-99"
            {...register('cpf_card')}
          />

          <RowContentInput>
            <ReactInputMask
              mask="99/99"
              type="text"
              placeholder="Data de expiração 12/27"
              {...register('card_expiration_date')}
            />

            <ReactInputMask
              mask="9999"
              type="text"
              placeholder="CVV ex: 123"
              {...register('security_code')}
            />
          </RowContentInput>

          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Tooltip
              placement="top"
              title={
                <p>
                  O número CVV é representado pelos três últimos dígitos no
                  verso do seu cartão. Em cartões American Express, o CVV é um
                  número de 4 dígitos na frente do cartão.
                </p>
              }
            >
              <strong>O que é o CVV?</strong>
            </Tooltip>
          </div>
        </>
      )}

      <ContentButtonForm>
        <ButtonComponent
          type="button"
          onClick={() => setModalSelectedPlan(false)}
          variant="link"
        >
          Cancelar
        </ButtonComponent>
        <ButtonComponent type="submit" disabled={loading}>
          {loading ? (
            <ReactLoading
              type="balls"
              color="#FFF"
              width="1.5rem"
              height="1.5rem"
            />
          ) : (
            'Enviar'
          )}
        </ButtonComponent>
      </ContentButtonForm>
    </FormOfPayment>
  );
}

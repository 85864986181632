import { formatedAllValueCurrency } from '../../../../../utils/formatedAllvalueCurrency';
import { Container } from './styles';

interface IPropsCardDetailedReport {
  title: string;
  value: number;
}

export function CardDetailedReport({ title, value }: IPropsCardDetailedReport) {
  const newValue = formatedAllValueCurrency(value);
  function newValueTitle() {
    if (title === 'Dividendo') {
      return 'Dividendo';
    } else if (title === 'Juros Sobre Capital Próprio') {
      return 'Juros sobre Cap. Pró. (JCP)';
    } else if (title === 'Rendimento') {
      return 'Rendimento';
    }
    return;
  }
  const newTitle = newValueTitle();

  return (
    <Container>
      <span>{newTitle}</span>
      <h3>{newValue}</h3>
    </Container>
  );
}

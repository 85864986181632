import styled from 'styled-components';

export const ContentText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h3 {
    margin: 0;
    font-weight: 500;
    font-size: 2.25rem;
    color: #303F4B;
  }
  span {
    font-weight: 400;
    font-size: 0.75rem;
    color: #303F4B;
  }
`;

interface IPropsParams {
  dy_range: number;
  nome_administrador: string;
  setor: string;
  tipo_gestao: string;
  qtde_ativos_range: number;
  valor_patrimonial_range: number;
  patrimonio_liquido_range: number;
  p_vp_range: number;
  vacancia_range: number;
  nome_ou_codigo: string;
  tipofii: string;
  page?: number;
}

export  function validateParamsFiiSearch({
  dy_range,
  nome_administrador,
  nome_ou_codigo,
  p_vp_range,
  patrimonio_liquido_range,
  qtde_ativos_range,
  setor,
  tipo_gestao,
  tipofii,
  vacancia_range,
  valor_patrimonial_range,
  page,
}: IPropsParams) {
  let newParams: IPropsParams = {} as IPropsParams;



  if (page) {
    newParams.page = page;
  }
  if (dy_range !== 0) {
    newParams.dy_range = dy_range;
  }
  if (nome_administrador !== '') {
    newParams.nome_administrador = nome_administrador;
  }
  if (nome_ou_codigo !== '') {
    newParams.nome_ou_codigo = nome_ou_codigo;
  }
  if (p_vp_range !== 0) {
    newParams.p_vp_range = p_vp_range;
  }
  if (patrimonio_liquido_range !== 0) {
    newParams.patrimonio_liquido_range = patrimonio_liquido_range;
  }
  if (qtde_ativos_range !== 0) {
    newParams.qtde_ativos_range = qtde_ativos_range;
  }
  if (setor !== '') {
    newParams.setor = setor;
  }
  if (tipo_gestao !== '') {
    newParams.tipo_gestao = tipo_gestao;
  }
  if (tipofii !== '') {
    newParams.tipofii = tipofii;
  }
  if (vacancia_range !== 0) {
    newParams.vacancia_range = vacancia_range;
  }

  if (valor_patrimonial_range !== 0) {
    newParams.valor_patrimonial_range = valor_patrimonial_range;
  }

  return newParams;
}

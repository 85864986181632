import { Heading } from '../../../../../components/DefaultStylesTypography';
import { KnowPlatformContainer } from './styles';

interface IPropsScreen {
  onShowRegisterScreen: () => void;
}

export function KnowPlatform({ onShowRegisterScreen }: IPropsScreen) {
  return (
    <KnowPlatformContainer id="plataforma">
      <header>
        <Heading as="h1">Conheça nossa plataforma</Heading>
        <p>
          Chega de gastar tempo, energia e dinheiro cuidando dos investimentos.
          Olha só tudo que o iBox6 pode fazer por você:
        </p>
      </header>
      <iframe
        src="https://player-vz-447732c4-8c0.tv.pandavideo.com.br/embed/?v=41fd2922-e917-4d85-a28e-a57d6c0ac5d7"
        height={
          window.screen.width <= 480
            ? '300px'
            : window.screen.width <= 768
            ? '400px'
            : '600px'
        }
        width="100%"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      ></iframe>
      <button type="button" onClick={onShowRegisterScreen}>
        Acesse agora
      </button>
    </KnowPlatformContainer>
  );
}

import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import ReactInputMask from 'react-input-mask';
//@ts-ignore
import Select from 'react-select';
import { downloadExcel } from 'react-export-table-to-excel';
import { useTitleAndUser } from '../../../../../hooks/userTitle';
import { financeRoutes, userRoutes } from '../../../../../utils/RoutesApi';
import { IPropsTransactionsData } from './@types';
import { TableTransactions } from './components/TableTransactions';
import { Container, ContentFilters, ContentTabFilter } from './styles';
import { ScreenToSignIR } from '../IncomeTax/pages/ScreenToSignIR';
import { PlanMessageStatus } from '../IncomeTax/components/PlanMessageStatus';
import { IPropsPlanIR } from '../../../Dashboard/Settings/Pagamentos/SelectedPlan/components/@types';
import { LinkB3Card } from '../../../../../components/LinkB3Card';
import { ButtonComponent } from '../../../../../components/ButtonComponent';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { TableLoader } from '../../../LoadingModels';

const baseURL = process.env.REACT_APP_API_URL;

interface IPropsData {
  count: number;
  current: number;
  data: IPropsTransactionsData[];
  links: {
    next: string | null;
    previous: string | null;
  };
  total_pages: number;
}

interface IPropsSelect {
  value: string;
  label: string;
}

interface IPropsDataFilters {
  Ativos: IPropsSelect[];
  Corretoras: IPropsSelect[];
  Movimentos: IPropsSelect[];
  TipoMercado: IPropsSelect[];
  operacao: IPropsSelect[];
  tipo_ativo: IPropsSelect[];
}

interface IPropsFilterSelected {
  ativo: string;
  corretora: string;
  tipo_negociacao: string;
  mercado: string;
  operacao: '1' | '2' | '';
  tipo_ativo: '1' | '2' | '';
}

const headerTable = [
  {
    active: 'referenceDate',
    label: 'Data',
  },
  {
    active: 'tickerSymbol',
    label: 'Ativo',
  },

  {
    active: 'side',
    label: 'Tipo',
  },
  {
    active: 'priceValue',
    label: 'Preço Un.',
  },
  {
    active: 'tradeQuantity',
    label: 'Qtde',
  },
  {
    active: 'grossAmount',
    label: 'Total',
  },
  {
    active: 'emolumentos',
    label: 'Taxas',
  },
  {
    active: 'revenue',
    label: 'Lucro.',
  },
  {
    active: 'cumBuyerPrice',
    label: 'Preço médio',
  },
  {
    label: 'Operação',
  },
];

export function Transactions() {
  //@ts-ignore
  const { slug } = useParams();
  const { register, setValue } = useForm();
  //@ts-ignore
  const { setTitle, permissions, user } = useTitleAndUser();
  const [selectDateFilter, setSelectDateFilter] = useState(() => {
    if (slug !== '0' && slug !== undefined) {
      return {
        start: `${slug.substring(0, 2)}/${slug.substring(2, 6)}`,
        end: `${slug.substring(0, 2)}/${slug.substring(2, 6)}`,
      };
    }
    return {
      start: '',
      end: '',
    };
  });
  //@ts-ignore
  const [plan, setPlan] = useState<IPropsPlanIR>({});
  const [showFilters, setShowFilters] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [dataTransactions, setDataTransactions] = useState<IPropsData>({
    count: 0,
    current: 0,
    data: [],
    links: {
      next: null,
      previous: null,
    },
    total_pages: 0,
  });
  const [dataFilters, setDataFilters] = useState<IPropsDataFilters>({
    Ativos: [],
    Corretoras: [],
    Movimentos: [],
    TipoMercado: [],
    operacao: [],
    tipo_ativo: [],
  });
  const [filtersSelecteds, setFiltersSelecteds] =
    useState<IPropsFilterSelected>({
      ativo: '',
      corretora: '',
      tipo_negociacao: '',
      mercado: '',
      operacao: '',
      tipo_ativo: '',
    });

  useEffect(() => {
    let params: IPropsFilterSelected | {} = {};
    if (filtersSelecteds.ativo !== '') {
      params = {
        ...params,
        ativo: filtersSelecteds.ativo,
      };
    }
    if (filtersSelecteds.corretora !== '') {
      params = {
        ...params,
        corretora: filtersSelecteds.corretora,
      };
    }
    if (filtersSelecteds.mercado !== '') {
      params = {
        ...params,
        mercado: filtersSelecteds.mercado,
      };
    }
    if (filtersSelecteds.tipo_negociacao !== '') {
      params = {
        ...params,
        tipo_negociacao: filtersSelecteds.tipo_negociacao,
      };
    }
    if (filtersSelecteds.operacao !== '') {
      params = {
        ...params,
        operacao: filtersSelecteds.operacao,
      };
    }
    if (filtersSelecteds.tipo_ativo !== '') {
      params = {
        ...params,
        tipo_ativo: filtersSelecteds.tipo_ativo,
      };
    }

    params = {
      ...params,
      page_size: 100,
      page,
    };

    if (
      selectDateFilter.end !== '' &&
      selectDateFilter.start !== '' &&
      selectDateFilter.end.replace(/\D/g, '').length === 6 &&
      selectDateFilter.start.replace(/\D/g, '').length === 6
    ) {
      let start = selectDateFilter.start.split('/');
      let end = selectDateFilter.end.split('/');

      getTransactions({
        periodo_de: `${start[1]}${start[0]}`,
        periodo_ate: `${end[1]}${end[0]}`,
        ...params,
      });
    } else if (selectDateFilter.end === '' && selectDateFilter.start === '') {
      getTransactions(params);
    }
  }, [selectDateFilter, filtersSelecteds, page]);

  useEffect(() => {
    setTitle('Negociação de ativos');
    // getTransactions();
    getTransactionsFilters();
    handleGetPlan();
  }, []);

  async function handleGetPlan() {
    await axios
      .get(`${baseURL}${userRoutes.USER_PLAN_IR_SELECTED}`)
      .then((response) => {
        setPlan(response.data);
      })
      .catch((err) => {
      });
  }

  async function getTransactions(params: object | null = null) {
    setLoading(true);
    await axios
      .get(`${baseURL}${financeRoutes.FINANCE_TRANSACTIONS_ACTIVES}`, {
        params,
      })
      .then((response) => {
        setDataTransactions(response.data);
      })
      .catch((err) => {
    
      });
    setLoading(false);
  }
  async function getTransactionsFilters() {
    await axios
      .get(`${baseURL}${financeRoutes.FINANCE_TRANSACTIONS_ACTIVES_FILTERS}`)
      .then((response) => {
        let newData = response.data;
        //@ts-ignore
        newData.Ativos = newData.Ativos.map((ativo) => {
          return {
            value: ativo.ativo,
            label: ativo.nome ? `${ativo.ativo} - ${ativo.nome}` : ativo.ativo,
          };
        });
        //@ts-ignore
        newData.Corretoras = newData.Corretoras.map((corretora) => {
          return {
            value: corretora.corretora,
            label: corretora.corretora,
          };
        });
        //@ts-ignore
        newData.Movimentos = newData.Movimentos.map((movimento) => {
          return {
            value: movimento.movimento,
            label: movimento.movimento,
          };
        });
        //@ts-ignore
        newData.TipoMercado = newData.TipoMercado.map((tipomercado) => {
          return {
            value: tipomercado.tipomercado,
            label: tipomercado.tipomercado,
          };
        });
        (newData.operacao = [
          {
            label: 'Operação Normal',
            value: '1',
          },
          {
            label: 'DayTrade',
            value: '2',
          },
        ]),
          (newData.tipo_ativo = [
            {
              label: 'Ação',
              value: '1',
            },
            {
              label: 'Fundo imobiliário',
              value: '2',
            },
          ]),
          setDataFilters(newData);
      })
      .catch((err) => {
    
      });
  }

  const returnSelectValue = (id: string, arr: IPropsSelect[]) => {
    if (id) {
      let obj = arr?.map((item) => {
        if (item?.value === id) {
          return {
            value: item.value,
            label: item.label,
          };
        }
      });
      return obj;
    } else {
      return null;
    }
  };

  const headerXls = [
    'Data',
    'Ativo',
    'Tipo',
    'Preço Un.',
    'Qtde',
    'Total',
    'Taxas',
    'Lucro',
    'Preço médio',
    'Operação',
  ];

  const body = dataTransactions.data.map((item) => {
    return [
      moment(item.referenceDate).format('l'),
      item.tickerSymbol,
      item.side,
      Number(item.priceValue).toFixed(2),
      item.tradeQuantity,
      Number(item.grossAmount).toFixed(2),
      Number(item.emolumentos).toFixed(2),
      Number(item.revenue).toFixed(2),

      Number(item.cumBuyerPrice).toFixed(2),
      item.dayTrade ? 'DayTrade' : 'Normal',
    ];
  });
  function handleDownloadExcel() {
    downloadExcel({
      fileName: `Transações IR`,
      sheet: 'react-export-table-to-excel',
      tablePayload: {
        header: headerXls,
        // accept two different data structures
        body: body,
      },
    });
  }
  const isAdmin = permissions.includes('Admin');
  const isPlan = permissions.includes('IRPF');
  return isAdmin || isPlan || (plan.Status && plan.Status !== 'canceled') ? (
    <Container>
      {plan.Status !== 'paid' && !isAdmin && !isPlan ? (
        <PlanMessageStatus plan={plan} />
      ) : user.carteira === 'automatico' ? (
        loading ? (
          <TableLoader />
        ) : (
          <>
            <ContentTabFilter>
              <header>
                <ButtonComponent
                  variant="link"
                  type="button"
                  style={{ paddingLeft: '0.5rem' }}
                  onClick={() => setShowFilters(!showFilters)}
                >
                  {showFilters ? 'Esconder filtros' : 'Exibir filtros'}
                </ButtonComponent>
                <ButtonComponent type="button" onClick={handleDownloadExcel}>
                  Baixar excel
                </ButtonComponent>
              </header>

              <ContentFilters show={showFilters}>
                <div className="rowContent">
                  <label>
                    De:{' '}
                    <ReactInputMask
                      mask="99/9999"
                      placeholder={`Ex: ${moment()
                        .subtract(12, 'months')
                        .format('MM/YYYY')}`}
                      {...register('start')}
                      className="inputDate"
                      defaultValue={selectDateFilter.start}
                      onChange={(e) => {
                        if (e.target.value.replace(/\D/g, '').length === 6) {
                          setSelectDateFilter({
                            ...selectDateFilter,
                            start: e.target.value,
                          });
                        }
                        setPage(1);
                      }}
                    />
                  </label>
                  <label>
                    Até:{' '}
                    <ReactInputMask
                      mask="99/9999"
                      placeholder={`Ex: ${moment().format('MM/YYYY')}`}
                      className="inputDate"
                      {...register('end')}
                      defaultValue={selectDateFilter.end}
                      onChange={(e) => {
                        if (e.target.value.replace(/\D/g, '').length === 6) {
                          setSelectDateFilter({
                            ...selectDateFilter,
                            end: e.target.value,
                          });
                        }
                        setPage(1);
                      }}
                    />
                  </label>
                  <Select
                    options={dataFilters.Ativos}
                    className="select"
                    //@ts-ignore
                    onChange={(e) => {
                      setFiltersSelecteds({
                        ...filtersSelecteds,
                        // @ts-ignore
                        ativo: e.value,
                      });
                      setPage(1);
                    }}
                    value={returnSelectValue(
                      filtersSelecteds.ativo,
                      dataFilters.Ativos
                    )}
                    placeholder="Ativos"
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? '#0176E1' : 'transparent',
                        borderRadius: 8,
                      }),
                      container: (baseStyles) => ({
                        ...baseStyles,
                        boxShadow: ' 2px 2px 6px 0px #0000001A',
                        borderRadius: 8,
                      }),
                      input: (baseStyles) => ({
                        ...baseStyles,
                        color: '#808080',
                        fontSize: 16,
                      }),
                      placeholder: (base) => ({
                        ...base,
                      }),
                      valueContainer: (base) => ({
                        ...base,
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 8,
                        paddingBottom: 8,
                      }),
                      indicatorSeparator: () => ({
                        display: 'none',
                      }),
          
                      dropdownIndicator: (base) => ({
                        ...base,
                        color: '#808080',
                      }),
                    }}
                  />
                </div>
                <div className="rowContent">
                  <Select
                    options={dataFilters.Movimentos}
                    className="select"
                    //@ts-ignore
                    onChange={(e) => {
                      setFiltersSelecteds({
                        ...filtersSelecteds,
                        // @ts-ignore
                        tipo_negociacao: e.value,
                      });
                      setPage(1);
                    }}
                    value={returnSelectValue(
                      filtersSelecteds.tipo_negociacao,
                      dataFilters.Movimentos
                    )}
                    placeholder="Movimentos"
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? '#0176E1' : 'transparent',
                        borderRadius: 8,
                      }),
                      container: (baseStyles) => ({
                        ...baseStyles,
                        boxShadow: ' 2px 2px 6px 0px #0000001A',
                        borderRadius: 8,
                      }),
                      input: (baseStyles) => ({
                        ...baseStyles,
                        color: '#808080',
                        fontSize: 16,
                      }),
                      placeholder: (base) => ({
                        ...base,
                      }),
                      valueContainer: (base) => ({
                        ...base,
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 8,
                        paddingBottom: 8,
                      }),
                      indicatorSeparator: () => ({
                        display: 'none',
                      }),
          
                      dropdownIndicator: (base) => ({
                        ...base,
                        color: '#808080',
                      }),
                    }}
                  />
                  <Select
                    options={dataFilters.operacao}
                    className="select"
                    //@ts-ignore
                    onChange={(e) => {
                      setFiltersSelecteds({
                        ...filtersSelecteds,
                        // @ts-ignore
                        operacao: e.value,
                      });
                      setPage(1);
                    }}
                    value={returnSelectValue(
                      filtersSelecteds.operacao,
                      dataFilters.operacao
                    )}
                    placeholder="Operação"
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? '#0176E1' : 'transparent',
                        borderRadius: 8,
                      }),
                      container: (baseStyles) => ({
                        ...baseStyles,
                        boxShadow: ' 2px 2px 6px 0px #0000001A',
                        borderRadius: 8,
                      }),
                      input: (baseStyles) => ({
                        ...baseStyles,
                        color: '#808080',
                        fontSize: 16,
                      }),
                      placeholder: (base) => ({
                        ...base,
                      }),
                      valueContainer: (base) => ({
                        ...base,
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 8,
                        paddingBottom: 8,
                      }),
                      indicatorSeparator: () => ({
                        display: 'none',
                      }),
          
                      dropdownIndicator: (base) => ({
                        ...base,
                        color: '#808080',
                      }),
                    }}
                  />
                  <Select
                    options={dataFilters.tipo_ativo}
                    className="select"
                    //@ts-ignore
                    onChange={(e) => {
                      setFiltersSelecteds({
                        ...filtersSelecteds,
                        // @ts-ignore
                        tipo_ativo: e.value,
                      });
                      setPage(1);
                    }}
                    value={returnSelectValue(
                      filtersSelecteds.tipo_ativo,
                      dataFilters.tipo_ativo
                    )}
                    placeholder="Tipo de ativo"
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? '#0176E1' : 'transparent',
                        borderRadius: 8,
                      }),
                      container: (baseStyles) => ({
                        ...baseStyles,
                        boxShadow: ' 2px 2px 6px 0px #0000001A',
                        borderRadius: 8,
                      }),
                      input: (baseStyles) => ({
                        ...baseStyles,
                        color: '#808080',
                        fontSize: 16,
                      }),
                      placeholder: (base) => ({
                        ...base,
                      }),
                      valueContainer: (base) => ({
                        ...base,
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 8,
                        paddingBottom: 8,
                      }),
                      indicatorSeparator: () => ({
                        display: 'none',
                      }),
          
                      dropdownIndicator: (base) => ({
                        ...base,
                        color: '#808080',
                      }),
                    }}
                  />
                </div>

                <button
                  type="button"
                  onClick={() => {
                    setFiltersSelecteds({
                      ativo: '',
                      corretora: '',
                      mercado: '',
                      tipo_negociacao: '',
                      operacao: '',
                      tipo_ativo: '',
                    });
                    setSelectDateFilter({
                      end: '',
                      start: '',
                    });
                    setPage(1);
                    setValue('end', '');
                    setValue('start', '');
                  }}
                  className="clearFilter"
                >
                  Limpar filtros
                </button>
              </ContentFilters>
            </ContentTabFilter>

            <TableTransactions
              ASC={(newData: IPropsTransactionsData[]) =>
                setDataTransactions({
                  ...dataTransactions,
                  data: newData,
                })
              }
              DESC={(newData: IPropsTransactionsData[]) =>
                setDataTransactions({
                  ...dataTransactions,
                  data: newData,
                })
              }
              data={dataTransactions.data}
              header={headerTable}
              countPages={dataTransactions.total_pages}
              page={page}
              setPage={(newPage: number) => setPage(newPage)}
            />
          </>
        )
      ) : (
        <div className="contentB3Card">
          <LinkB3Card />
        </div>
      )}
    </Container>
  ) : (
    <ScreenToSignIR updatePlan={handleGetPlan} />
  );
}

import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { ButtonComponent } from '../../../components/ButtonComponent';
import { useTitleAndUser } from '../../../hooks/userTitle';
import { financeRoutes } from '../../../utils/RoutesApi';
import { TableVacancia } from './components/TableVacancia';
import { Container, FormContent, ShowTableDiv } from './styles';
import { editContractsData } from './utils/editContracts';
import { TableContracts } from './components/TableContracts';
import {
  IPropsContracts,
  IPropsDividendos,
  IPropsImoveis,
  IPropsVacancia,
} from './utils/@types';
import { TableDividendos } from './components/TableDividendos';
import { handleToast } from '../../../components/Toast';

const baseURL = process.env.REACT_APP_API_URL;

export function EditFiiAdmin() {
  const { handleSubmit } = useForm();
  //@ts-ignore
  const { setTitle } = useTitleAndUser();
  //@ts-ignore
  const slug = location.pathname.replace('/fii/edit/', '');
  const [vacanciaData, setVacanciaData] = useState<IPropsVacancia[]>([]);
  const [showTableVacancia, setShowTableVacancia] = useState<boolean>(false);
  const [showTableContracts, setShowTableContracts] = useState<boolean>(false);
  const [contracts, setContracts] = useState<IPropsContracts[]>([]);
  const [dividendos, setDividendos] = useState<IPropsDividendos[]>([]);
  const [imoveisData, setImoveisData] = useState<IPropsImoveis[]>([]);

  useEffect(() => {
    setTitle(`Editar dados ${slug}`);
    getVacancia();
    getContractsPeriod();
    getDividendos();
    getImoveis();
  }, []);

  async function getVacancia() {
    await axios
      .get(`${baseURL}${financeRoutes.FINANCE_VACANCIA_CONSOLIDATED}`, {
        params: { search: slug },
      })
      .then((response) => {
        let newData = response.data.map((item: IPropsVacancia) => {
          item.ValorPercOcupacao = Number(
            (item.ValorPercOcupacao * 100).toFixed(2)
          );

          item.ValorPercVacanciaFinanceira = Number(
            (item.ValorPercVacanciaFinanceira * 100).toFixed(2)
          );

          item.ValorPercVacanciaFisica = Number(
            (item.ValorPercVacanciaFisica * 100).toFixed(2)
          );
          item.Editado = false;
          return item;
        });

        newData = newData.sort((a: IPropsVacancia, b: IPropsVacancia) => {
          if (a.DataVancancia > b.DataVancancia) {
            return -1;
          } else {
            return true;
          }
        });

        setVacanciaData(newData);
      })
      .catch((err) => {});
  }
  function handleChangeEditVacancia(
    date: string,
    value: number | string,
    type: string
  ) {
    let newDataVacancia = vacanciaData;
    const findIndexVacancia = vacanciaData.findIndex(
      (vacancia) => vacancia.DataVancancia === date
    );

    if (type === 'date') {
      newDataVacancia[findIndexVacancia].DataVancancia = `${
        value.toString().split('-')[1]
      }-${value.toString().split('-')[0]}`;
      newDataVacancia[findIndexVacancia].competencia_CNPJ = `${
        value.toString().split('-')[1]
      }-${value.toString().split('-')[0]}-${slug}`;
    } else if (type === 'ocupacao') {
      newDataVacancia[findIndexVacancia].ValorPercOcupacao = Number(value);
    } else if (type === 'fisica') {
      newDataVacancia[findIndexVacancia].ValorPercVacanciaFisica =
        Number(value);
    } else {
      newDataVacancia[findIndexVacancia].ValorPercVacanciaFinanceira =
        Number(value);
    }
    newDataVacancia[findIndexVacancia].Editado = true;
    setVacanciaData(newDataVacancia);
  }
  function handleChangeEditContract(date: string, value: number) {
    let newDataContracts = contracts;
    const findIndexContract = contracts.findIndex(
      (contract) => contract.periodo === date
    );
    if (findIndexContract > -1) {
      newDataContracts[findIndexContract].valor = value;

      newDataContracts[findIndexContract].isEdit = true;
      setContracts(newDataContracts);
    }
  }
  function handleChangeEditDividendo(
    date: string,
    value: number,
    type: string
  ) {
    let newDataDividendos = dividendos;
    const findIndexDividendos = dividendos.findIndex(
      (contract) => contract.data_pagamento === date
    );
    if (findIndexDividendos > -1) {
      if (type === 'dy') {
        newDataDividendos[findIndexDividendos].dy = value;

        newDataDividendos[findIndexDividendos].isEdit = true;
      }
      if (type === 'rendimento') {
        newDataDividendos[findIndexDividendos].rendimento = value;

        newDataDividendos[findIndexDividendos].isEdit = true;
      }
      setDividendos(newDataDividendos);
    }
  }

  async function getImoveis() {
    axios
      .get(`${baseURL}${financeRoutes.FINANCE_IMOVEIS}`, {
        params: {
          codigo_fii: slug,
        },
      })
      .then((response) => {
        setImoveisData(response.data);
      })
      .catch((err) => {});
  }

  async function getContractsPeriod() {
    await axios
      .get(`${baseURL}${financeRoutes.FINANCE_DISTRATOS}`, {
        params: {
          search: slug,
        },
      })
      .then((response) => {
        //@ts-ignore
        let comp = response.data.map((item) => {
          return item;
        })[0];
        let newData: IPropsContracts[] = response.data.filter(
          //@ts-ignore
          (item) =>
            item.competencia === comp.competencia &&
            item.tipo === '@percentReceitaImovel'
        );

        //@ts-ignore
        newData = newData.map((item) => {
          item.periodo = editContractsData(item.periodo);
          item.valor = Number((item.valor * 100).toFixed(2));
          return item;
        });

        setContracts(newData);
      })
      .catch((err) => {});
  }

  async function getDividendos() {
    await axios
      .get(`${baseURL}${financeRoutes.FINANCE_HISTORY_YIELD_FII}`, {
        params: {
          search: slug,
        },
      })
      .then((response) => {
        let newDataDividendos = response.data.data.sort(
          (a: IPropsDividendos, b: IPropsDividendos) => {
            if (a.data_pagamento > b.data_pagamento) {
              return -1;
            } else {
              return true;
            }
          }
        );
        newDataDividendos = newDataDividendos.map((item: IPropsDividendos) => {
          item.dy = Number(item.dy.toFixed(2));
          item.rendimento = Number(item.rendimento.toFixed(2));

          return item;
        });
        setDividendos(newDataDividendos);
      })
      .catch((err) => {});
  }

  async function submitVacancia(data: IPropsVacancia) {
    const filterIsEdit = vacanciaData.filter((item) => item.Editado);

    for await (const vacancia of filterIsEdit) {
      data = vacancia;
      data.ValorPercOcupacao = data.ValorPercOcupacao / 100;
      data.ValorPercVacanciaFinanceira = data.ValorPercVacanciaFinanceira / 100;
      data.ValorPercVacanciaFisica = data.ValorPercVacanciaFisica / 100;
      axios
        .put(
          `${baseURL}${financeRoutes.FINANCE_VACANCIA_CONSOLIDATED_UPDATE(
            vacancia.competencia_CNPJ
          )}`,
          data
        )
        .then((response) => {
          handleToast(false, 'Vacância atualizada com sucesso');
        })
        .catch((err) => {});
    }
  }

  function submitContract(data: any) {
    console.log(data);
  }
  function submitDividendo(data: any) {
    console.log(data);
  }


  return (
    <Container>
      {/* <Box style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}> */}
      <ShowTableDiv onClick={() => setShowTableVacancia(!showTableVacancia)}>
        Vacância
      </ShowTableDiv>
      <br />
      {/* <Box>
          <Fade in={showTableVacancia}> */}
      {/* @ts-ignore */}
      <FormContent onSubmit={handleSubmit(submitVacancia)}>
        <TableVacancia
          data={vacanciaData}
          editVacancia={(date, value, type) =>
            handleChangeEditVacancia(date, value, type)
          }
          editData={setVacanciaData}
          slug={slug}
        />
        <div>
          <ButtonComponent type="submit">Salvar</ButtonComponent>
        </div>
      </FormContent>
      <br />
      {/* </Fade>
        </Box>
      </Box> */}
      {/* <Box style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}> */}
      <ShowTableDiv onClick={() => setShowTableContracts(!showTableContracts)}>
        Contratos
      </ShowTableDiv>
      <br />
      {/* <Box>
          <Fade in={showTableContracts}> */}
      <FormContent onSubmit={handleSubmit(submitContract)}>
        <TableContracts
          data={contracts}
          editContract={(date, value) => handleChangeEditContract(date, value)}
        />
        <div>
          <ButtonComponent type="submit">Salvar</ButtonComponent>
        </div>
      </FormContent>
      {/* </Fade>
        </Box>
      </Box> */}
      <br />
      {/* </Fade>
        </Box>
      </Box> */}
      {/* <Box style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}> */}
      <ShowTableDiv onClick={() => setShowTableContracts(!showTableContracts)}>
        Dividendos
      </ShowTableDiv>
      <br />
      {/* <Box>
          <Fade in={showTableContracts}> */}
      <FormContent onSubmit={handleSubmit(submitDividendo)}>
        <TableDividendos
          data={dividendos}
          editDividendo={(date, value, type) =>
            handleChangeEditDividendo(date, value, type)
          }
        />
        <div>
          <ButtonComponent type="submit">Salvar</ButtonComponent>
        </div>
      </FormContent>
      {/* </Fade>
        </Box>
      </Box> */}
    </Container>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .row-content {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .row-input {
    display: flex;
    flex-direction: row;
    gap: 10px;
    /* @media (max-width: 991px) {
      flex-direction: column;
    }
    @media (max-width: 766px) {
      flex-direction: row;
    }
    @media (max-width: 365px) {
      flex-direction: column;
    } */
  }

  .checkbox {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;

    strong {
      margin: 0;
    }
    label {
      display: flex;
      align-items: center;
      margin: 0 !important;
    }
  }
`;

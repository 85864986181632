import styled from 'styled-components';

export const HomeContainer = styled.section`
  margin-top: 9rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .previewImg {
    width: 100%;
    max-width: 25rem;
  }

  @media (max-width: 480px) {
    .previewImg {
      width: 100%;
      max-width: 22rem;
    }
  }
`;

export const ColContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 1rem;

  span {
    display: flex;
    align-items: center;
    font-size: 0.87rem;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 1.5rem;
    gap: 0.5rem;
    color: ${(props) => props.theme.primary};

    svg {
      width: 1rem;
      height: 1rem;
      padding: 0.2rem;
      background-color: ${(props) => props.theme.primary};
      color: ${(props) => props.theme.white};
      border-radius: 100%;
    }
  }

  p {
    font-weight: 500;
    font-size: 1.375rem;
    line-height: 150%;
    color: ${(props) => props.theme['gray-700']};
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    strong {
      font-weight: 600;
      font-size: 1.75rem;
      margin-bottom: 0.5rem;
    }
  }

  button {
    all: unset;
    padding: 1.25rem 1.5rem;
    background: ${(props) => props.theme.primary};
    color: ${(props) => props.theme.white};
    font-weight: 600;
    font-size: 1.125rem;

    border-radius: 8px;
    width: 100%;
    max-width: 13.125rem;
    text-align: center;
    margin-top: 3rem;
  }

  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
    span {
      flex-direction: column;
    }
    p {
      font-size: 1.125rem;
      strong {
        font-size: 1.5rem;
      }
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: start;
  width: 90%;
  background: #fff;
  border-radius: 8px;
  border: 2px solid var(--Gray-gray-100, #D4D6DB);
  background: var(--Main-white, #FFF);
  margin-top: 5.75%;
  padding-top: 100px;
  padding-bottom: 50px;

`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 1440px;

  justify-content: right;
  align-items: baseline;
  .contentTable {
    display: flex;
    width: 100%;
    justify-content: center;

    border-radius: 8px;
    height: 100%;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const ContentCards = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
`;
interface IPropsCardIsComponent {
  isComponent: boolean;
}
export const CardSelectedActive = styled.div<IPropsCardIsComponent>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;

  width: 100%;
  background: ${(props) => props.theme.white};
  /* overflow: hidden; */

  p {
    font-size: 1rem;
    font-weight: 400;
    color: ${(props) => props.theme['gray-500']};
    text-align: center;
    width: 100%;
    max-width: 47.75rem;
  }
  .contentButtonFilter {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .filterButton {
      all: unset;
      padding: 0.75rem 2rem;
      border-radius: 32px;
      background: #f1f8ff;
      color: ${(props) => props.theme['primary-800']};
      font-weight: 400;
      font-size: 1rem;
    }
  }
`;

export const ContentCard = styled.div`
  overflow-x: auto;
  /* width: 100%; */
  max-width: 100%;
  display: flex;
  gap: 1rem;
  padding: 1rem 0.5rem;
  align-items: center;
  margin: 0 auto;

  ::-webkit-scrollbar {
    height: 5px; /* width of the entire scrollbar */
  }

  ::-webkit-scrollbar-track {
    background-color: ${(props) =>
      props.theme['primary-100']}; /* color of the tracking area */
  }

  ::-webkit-scrollbar-thumb {
    /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid ${(props) => props.theme['primary-800']}; /* creates padding around scroll thumb */
  }
`;

export const SelectActive = styled.button`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #EC6643;
  border-style: dashed;
  border-radius: 12px;
  width: 100%;
  max-width: 8.125rem;
  height: 100%;
  max-height: 6.875rem;
  padding: 2.5rem 0.625rem;
  z-index: 2;

  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  color: ${(props) => props.theme['gray-300']};
`;

export const ContentFilters = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-top: 1rem;

  .selectFilter {
    padding: 0.5rem;
    border: ${(props) => props.theme.border};

    border-radius: 4px;
    width: 100%;
    /* max-width: 20.7rem; */
    height: auto;
  }
`;

export const AddNewActiveButton = styled.button`
  all: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: ${(props) => props.theme['shadow-1']}!important;
  border-radius: 12px;
  padding: 1rem;
  justify-content: flex-end;
  height: 6.75rem;
  width: 6rem;
  min-width: 6rem;
  cursor: pointer;

  :focus {
    box-shadow: ${(props) => props.theme['shadow-1']}!important;
  }

  i {
    background: ${(props) => props.theme['primary-800']};
    color: ${(props) => props.theme.white};
    border-radius: 100%;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
  }

  span {
    color: ${(props) => props.theme['gray-300']};
    font-weight: 500;
    font-size: 0.75rem;
    text-align: center;
    margin-top: 1rem;
  }
`;

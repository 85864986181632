import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 1rem;
  align-items: start;
  width: 100%;
  main {
    width: 100%;
    max-width: 700px;

    flex-wrap: wrap;
    .responseComment {
      all: unset;
      color: ${(props) => props.theme['primary-800']};
      font-weight: 500;
      cursor: pointer;
    }
    strong {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .deleteAndDate {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;

        button {
          border: none;
          outline: none;
          padding: 0;
          svg {
            cursor: pointer;
            transition: color 0.3s;
            :hover {
              color: red;
            }
          }
        }
      }
    }
  }
`;

export const Avatar = styled.div`
  img {
    border-radius: 100%;
    object-fit: cover;
    width: 50px;
    height: 50px;
  }
`;

export const NoAvatarComment = styled.div`
  display: flex;
  position: relative;
  background: ${(props) => props.theme.white};
  border-radius: 100%;
  width: 50px;
  height: 50px;
  border: 1px solid ${(props) => props.theme.primary};

  align-items: center;
  justify-content: center;
  strong {
    font-size: 1.5rem;
    color: ${(props) => props.theme.primary};
  }
`;

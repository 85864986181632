import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;

  header {
    text-align: center;
    > h1 {
      font-size: 1.5rem;
      color: ${(props) => props.theme['gray-700']};
      font-weight: 700;
      margin: 0;
    }
  }
`;

export const ContentPlansList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
`;

export const CardNotice = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    background: ${(props) => props.theme.white};
    box-shadow: ${(props) => props.theme['card-shadow-2']};
    padding: 1rem;
    border-radius: 4px;
    max-width: 25rem;
    font: 700 1.5rem 'Poppins', sans-serif;
  }
`;

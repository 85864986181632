import {
  ChartBar,
  ChartLine,
  GraduationCap,
  MagnifyingGlass,
  ShareNetwork,
  UsersThree,
  Wallet,
  Coins,
} from '@phosphor-icons/react';

export interface IPropsCardInformation {
  id: number;
  infoBox: string;
  title: string;
  description: string;
  icon: JSX.Element;
  isCenter?: Boolean;
}

export const InfoBoxData: IPropsCardInformation[] = [
  {
    id: 1,
    infoBox: 'Carteira inteligente',
    title: 'Balancear a carteira virou brincadeira de criança!',
    description:
      'Que tal ter uma carteira de investimentos inteligente, onde você apenas define as porcentagens de cada ativo e ela te informa quais e quantos você terá que comprar a cada mês? Acabaram as suas dúvidas sobre qual ativo comprar em cada aporte.',
    icon: <Wallet weight="fill" />,
  },
  {
    id: 2,
    infoBox: 'Integração automática com a B3',
    title: 'Tudo acontece de forma automática!',
    description:
      'Você pode sincronizar a sua carteira com a B3 (a bolsa de valores do Brasil), e ver os seus ativos serem incluídos nos seus investimentos automaticamente, de forma rápida e muito segura. Além disso, você pode acrescentar qualquer ativo de forma manual. Se preferir!',
    icon: <ShareNetwork weight="fill" />,
  },
  {
    id: 3,
    infoBox: 'Análise automatizada de Ações e FIIs',
    title: 'Horas de estudo transformadas em alguns segundos!',
    description:
      'Cada fundamento analisado pela nossa Inteligência Artificial recebe uma nota e, por fim, tem-se uma nota final para o ativo, e um ranking prontinho para você. Assim, fica mais fácil para você escolher qual ação ou FII comprar ou vender, pois você já tem todas as informações necessárias em um só lugar.',
    icon: <MagnifyingGlass weight="fill" />,
  },
  {
    id: 4,
    infoBox: 'Educação',
    title: 'Tudo começa pelo conhecimento!',
    description:
      'Liberdade vem através do conhecimento, por isso oferecemos 1 aula por semana para melhorar suas habilidades em investimentos. A maioria aborda análise de ações e Fiis de forma clara e objetiva. Assim, você pode tomar decisões sozinho, sem depender de outros. Além disso, temos aulas de Educação Financeira.',
    icon: <GraduationCap weight="fill" />,
  },
  {
    id: 5,
    isCenter: true,
    infoBox: 'Integração automática com a B3',
    title: 'Tudo acontece de forma automática!',
    description:
      'Você pode sincronizar a sua carteira com a B3 (a bolsa de valores do Brasil), e ver os seus ativos serem incluídos nos seus investimentos automaticamente, de forma rápida e muito segura. Além disso, você pode acrescentar qualquer ativo de forma manual. Se preferir!',
    icon: <ShareNetwork weight="fill" />,
  },
  {
    id: 6,
    infoBox: 'Simuladores',
    title: 'Calcule os passos para a sua Liberdade Financeira!',
    description:
      'Para chegarmos a qualquer lugar, é muito importante vislumbrarmos antes o destino e calcular o caminho. Quanto você vai precisar poupar a cada mês? Por quanto tempo? O iBox6 calcula de forma automática para você.',
    icon: <ChartLine weight="fill" />,
  },
  {
    id: 7,
    infoBox: 'Comunidade',
    title: 'Aprenda com outros iBoxers!',
    description:
      'Uma rede social dentro do iBox6, onde você pode postar, curtir, comentar, seguir, tirar suas dúvidas ou iniciar tópicos do seu interesse. Além disso, você fica sabendo dos principais fatos relevantes sobre o mercado e participa de lives tira-dúvidas com nossos especialistas. Tudo isso em um ambiente seguro e colaborativo.',
    icon: <UsersThree weight="fill" />,
  },
  {
    id: 8,
    infoBox: 'Área de Renda Passiva',
    title: 'Acabou a confusão com os dividendos!',
    description:
      'Chegar à Independência Financeira é possível com uma renda passiva, que pode ser aumentada com um planejamento de dividendos. Nossa área de Renda Passiva oferece informações precisas sobre dividendos recebidos em sua carteira, ações que irão pagar dividendos nos próximos meses e muito mais, de maneira simples.',
    icon: <ChartBar weight="fill" />,
  },
  {
    id: 9,
    infoBox: 'Imposto de Renda',
    title: 'Descomplicamos a declaração de IR!',
    description:
      'Chega de dúvidas e confusão na hora de declarar o IR dos seus investimentos. O iBox6 fornece de forma automática todos os relatórios que você precisa para fazer a declaração anual dos seus investimentos. Você nunca mais vai ter que ficar procurando aquele monte de informações!',
    icon: <Coins weight="fill" />,
  },
];

import './styles.css';
import { ModalDesigner } from '../ModalDesigner';

export default function ModalChart(props) {
  var { children, title, setState, state } = props;

  return (
    <ModalDesigner
      isOpen={state}
      setState={setState}
      id={title.replace(' ', '')}
      title={title}
      size="xl"
    >
      {children}
    </ModalDesigner>
  );
}

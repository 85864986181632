export function editValueFilterDivida(val: number) {
  if (val === 0) {
    return -50000000;
  }
  if (val === 25) {
    return -10000000;
  }
  if (val === 50) {
    return 0;
  }
  if (val === 75) {
    return 10000000;
  }

  if (val === 100) {
    return 30000000;
  }
}

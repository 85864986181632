import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: row;
  min-width: 1078px;
  position: relative;

  .molde {
    position: absolute;
    top: 7rem;
    left: 10rem;
    color: ${(props) => props.theme.error};
    opacity: 0.3;
    font-size: 10rem;
    transform: rotate(340deg);
    /* z-index: -1; */
  }
`;

export const ColContent = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.black};
  width: 100%;
  position: relative;
  z-index: 1;
  .contentGov {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 0.5rem;
    border-bottom: 1px solid ${(props) => props.theme.black};

    img {
      width: 4rem;
      height: 4rem;
    }
    .data {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      h1,
      h2,
      h6 {
        margin: 0;
        color: ${(props) => props.theme['gray-700']};
      }
      h2 {
        font-size: 1.5rem;
        font-weight: 700;
      }
      h6 {
        font-weight: 500;
      }
      strong {
        font-weight: 700;
        color: ${(props) => props.theme['gray-700']};
      }

      h1 {
        font-weight: 700;
      }
    }
  }
  .userNameContent {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${(props) => props.theme.black};
    header {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      margin-bottom: 0.5rem;

      h3 {
        margin: 0;
        font-size: 1.4rem;
        font-weight: 700;
        color: ${(props) => props.theme['gray-700']};
      }
      span {
        color: ${(props) => props.theme['gray-700']};
      }
    }
    .inputContent {
      width: 100%;
      display: flex;
      flex-direction: row;
      input {
        width: 100%;
        border: none;
        font-size: 1rem;
        color: ${(props) => props.theme['gray-700']};
        background: transparent;
      }
    }
  }

  .warning {
    border-top: 1px solid ${(props) => props.theme.black};
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      margin: 0;
      font-weight: 700;
      color: ${(props) => props.theme['gray-700']};
      font-size: 1.5rem;
    }
    p {
      color: ${(props) => props.theme['gray-700']};
      font-size: 1rem;
    }
  }

  .contentInfo {
    display: flex;

    justify-content: space-between;
    width: 100%;
    p {
      display: flex;
      gap: 0.5rem;
      margin: 0;
      font-size: 0.75rem;
      color: ${(props) => props.theme['gray-700']};
      border-right: 1px solid ${(props) => props.theme.black};
      border-bottom: 1px solid ${(props) => props.theme.black};
      width: 100%;

      h1 {
        font-size: 1.5rem;
        font-weight: 700;
        color: ${(props) => props.theme['gray-700']};
      }
    }
    input {
      width: 100%;
      border: none;
      border-bottom: 1px solid ${(props) => props.theme.black};
      padding-left: 0.5rem;
      font-size: 1rem;
      color: ${(props) => props.theme['gray-700']};
      background: transparent;
    }
  }
  .contentInfoFinish {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    p {
      display: flex;
      gap: 0.5rem;
      margin: 0;
      font-size: 0.75rem;
      color: ${(props) => props.theme['gray-700']};
      width: 100%;

      h1 {
        font-size: 1.5rem;
        font-weight: 700;
        color: ${(props) => props.theme['gray-700']};
      }
    }
    input {
      width: 100%;
      border: none;
      padding-left: 0.5rem;
      font-size: 1rem;
      height: 100%;
      color: ${(props) => props.theme['gray-700']};
      background: transparent;
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const HeaderUpload = styled.header`
  display: flex;

  h3 {
    font: 400 1.2rem 'Poppins', sans-serif;
  }
`;

export const ContainerVideos = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const ContentVideo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Thumb = styled.div`
  width: 100%;
  height: 100%;

  img {
    max-width: 200px;
    border-radius: 8px 8px 0 0;
  }
`;

export const BodyContentVideo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
interface IPropsButton {
  title: string;
}
export const ContentButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: ${(props) => props.theme.border};
  border-radius: 40px;
  padding: 5px;
  gap: 10px;
`;

export const Button = styled.button<IPropsButton>`
  border: none;
  background: none;
  outline: none;
  font: 400 1rem 'Poppins', sans-serif;
  transition: color 0.3s;

  :hover {
    color: ${(props) =>
      props.title === 'Deletar' ? 'red' : (props) => props.theme.primary};
  }
`;

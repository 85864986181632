import styled from 'styled-components';

export const Card = styled.div`
  background: ${(props) => props.theme.white};
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0 0.5rem 1rem rgba(21, 21, 21, 0.1);
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  padding: 0.5rem 0;
  background-color: ${(props) =>
    props.outline ? 'transparent' : props.background};
  color: ${(props) => props.color};
  border: ${(props) => (props.outline ? `1px solid ${props.color}` : 'none')};
  outline: none;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 8px;
  width: 100%;
  transition: filter 0.3s;
  align-items: center;

  :hover {
    filter: brightness(0.9);
  }

  :disabled {
    background: ${(props) =>
      props.outline
        ? 'transparent'
        : props.backgroundDisabled
        ? props.backgroundDisabled
        : '#1b0c45'};
    cursor: no-drop;
    border: ${(props) =>
      props.outline ? `1px solid ${props.backgroundDisabled}` : 'none'};
  }
`;

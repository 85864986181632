import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 6px;
  border: 1px solid var(--Gray-gray-100, #D4D6DB);
  background: var(--Main-white, #FFF);
`;

export const RowContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12.5rem, 1fr));
  gap: 1rem;
  width: 100%;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const ContentTab = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0rem;
  justify-content: space-between;
  align-items: center;
  select {
    font-size: 1rem;
    color: #303F4B;
    border: none;
    border-bottom: 1px solid #EC6643;
    border-radius: 0px;
    padding: 0.5rem;
    top: -10%;
  }
  strong {
    color: #303F4B;
    font-size: 1rem;
    top: 0px;
  }
`;

export const ContentCardAssetsRigths = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid #EC6643;
  div {
    display: flex;
    flex-direction: column;

    span {
      font-size: 0.75rem;
      font-weight: 400;
      color: #6A717E;
    }
    strong {
      font-size: 1.5rem;
      font-weight: 700;
      color: #303F4B;
    }
  }
`;

import { HTMLAttributes } from 'react';

import { useActives } from '../../../hooks/useActives';
import { Container } from './styles';

import { transformMaskCurrency } from '../../../utils/transformMaskCurrency';
import { useTitleAndUser } from '../../../hooks/userTitle';
import logo from '../../../assets/home/patrimonio.svg';

interface IPropsCardHomeData extends HTMLAttributes<HTMLDivElement> {
  title: string;
  value: number | string;
  image?: string;
  isVideo?: boolean;
  isReceived?: boolean;
  diff?: number;
}

export function CardHomeData({
  title,
  value,
  image,
  isVideo = false,
  diff = 0,
  ...props
}: IPropsCardHomeData) {
  //@ts-ignore
  const { showActive } = useActives();
  //@ts-ignore
  const { permissions } = useTitleAndUser();
  const isBlocked = permissions.includes('Grátis');
  const isAdmin = permissions.includes('Admin');

  const maskValue = !isVideo && transformMaskCurrency(Number(value));

  return (
    <Container
      isBlocked={!isAdmin && isBlocked}
      {...props}
      title={
        !isAdmin &&
        isBlocked &&
        'Você precisa ter uma assinatura para visualizar os dados'
      }
      >
      <span>{title}</span>
      <img className='imagem' src={image || logo} alt={title} style={{ width: '20px', height: '20px' }} />
      {isVideo ? (
        <h1
          style={{
            color:'#40444C',
          }}
        >
          {value !== '-% ' ? <>{value}</> : '-'}
        </h1>
      ) : (
        <h1>{showActive ? maskValue : 'R$ *******'}</h1>
      )}
    </Container>
  );
}

import { useState } from 'react';
import PropTypes from 'prop-types';

import { ButtonComponent } from '../../../components/ButtonComponent';

// Local
import CircularProgress from '../CircularProgress';
import useUI from '../../hooks/useUI';

import { key } from '../../redux/user';
import api, { descriptor } from '../../api';
import { useTitleAndUser } from '../../../hooks/userTitle';
import axios from 'axios';
import { userRoutes } from '../../../utils/RoutesApi';

const baseURL = process.env.REACT_APP_API_URL;

const FollowButton = ({ className, userSlug, attUser, setAttUser }) => {
  const getDataUser = JSON.parse(
    localStorage.getItem(process.env.REACT_APP_USER_VERSION)
  );
  const { user, setUser } = useTitleAndUser();
  const [loading, setLoading] = useState(false);

  var checkFollow = user.following.includes(userSlug.id);
  let newUser = user;

  async function handleFollow() {
    setAttUser(true);
    if (checkFollow) {
      await axios
        .delete(`${baseURL}${userRoutes.REMOVE_FOLLOW(userSlug.slug)}`)
        .then((response) => {
          newUser.following = newUser.following.filter(
            (item, index) => item !== userSlug.id
          );

          let obj = {
            ...getDataUser,

            user: newUser,
          };

          setUser(obj.user);
          setAttUser(false);
          localStorage.setItem(
            process.env.REACT_APP_USER_VERSION,
            JSON.stringify(obj)
          );
          return;
        })
        .catch((error) => {});
    } else {
      await axios
        .post(`${baseURL}${userRoutes.ADD_NEW_FOLLOW(userSlug.slug)}`)
        .then((response) => {
          if (newUser.following.lenght > 0) {
            newUser.following = [...newUser.following, userSlug.id];
            let obj = {
              ...getDataUser,

              user: newUser,
            };
            setUser(obj.user);
            localStorage.setItem(
              process.env.REACT_APP_USER_VERSION,
              JSON.stringify(obj)
            );
            setAttUser(false);
            return;
          } else {
            newUser.following = [userSlug.id];
            let obj = {
              ...getDataUser,
              user: newUser,
            };
            setUser(obj.user);
            setAttUser(false);
            localStorage.setItem(
              process.env.REACT_APP_USER_VERSION,
              JSON.stringify(obj)
            );
            return;
          }
        })
        .catch((err) => {});
    }
  }

  return (
    <ButtonComponent
      className={className}
      disabled={loading}
      edge="end"
      onClick={handleFollow}
      variant="link"
    >
      {checkFollow ? 'Deixar de seguir' : 'Seguir'}
      {loading && <CircularProgress />}
    </ButtonComponent>
  );
};

export default FollowButton;

import { ReactNode, useEffect, useState } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useTitleAndUser } from '../../hooks/userTitle';
import { RegisterFinish } from '../../components/Forms/RegisterFinish';
import { UseOfTerms } from '../../components/Forms/UseOfTerms';
import { RedirectTextPremiumPlan } from '../components/RedirectTextPremiumPlan';
import './styles.css';
import { FeedbackCard } from './components/FeedbackCard';
import { ModalDesigner } from '../../components/ModalDesigner';
import { FinishAccountForm } from './components/FinishAccountForm';

interface IPropsPrivateRouter extends RouteProps {
  roles: string[];
  children: ReactNode;
}

export function PrivateRouter({
  children,
  roles,
  ...props
}: IPropsPrivateRouter) {
  //@ts-ignore
  const { permissions, user } = useTitleAndUser();

  const userHasRequiredRole =
    permissions.filter((permission: string) => roles.includes(permission))
      .length > 0;

  const paramsTraffic = window.location.search
    .substr(1)
    .split('&')
    .reduce((queryParams, param) => {
      const [key, value] = param.split('=');
      //@ts-ignore
      queryParams[key] = value;
      return queryParams;
    }, {});

  const [modalPremium, setModalPremium] = useState(
    //@ts-ignore
    paramsTraffic.redirect === 'true'
  );

  const [showModalFinishRegister, setShowModalRegisterFinish] = useState(
    user.cpf.length === 16
  );
  const route = window.location.pathname;
  const needCPFRoutes = [
    '/wallet',
    '/imposto-renda',
    '/Educacao',
    '/recommended-posts',
  ];
  const [needCPF, setNeedCPF] = useState(
    needCPFRoutes.includes(route) && user.cpf === 'new cpf'
  );

  const [showModalUseOfTerms, setShowModalUseOfTerms] = useState(() => {
    if (user.termsofuse) {
      return false;
    } else {
      return true;
    }
  });
  const isLead = localStorage.getItem('isLead');
  const isLeadJSON = isLead ? JSON.parse(isLead) : [];

  useEffect(() => {
    if (!userHasRequiredRole) {
      setModalPremium(true);
    }
  }, []);
  useEffect(() => {
    if (user.cpf.length === 16) {
      setShowModalRegisterFinish(true);
    }
    if (user.cpf !== 'new cpf') {
      setNeedCPF(false);
    }
  }, [user, showModalFinishRegister]);

  if (route === '/dashboard/led' && isLeadJSON.includes(user.email)) {
    return <Redirect to={{ pathname: '/dashboard' }} />;
  }

  return (
    <>
      {userHasRequiredRole ? (
        <Route
          {...props}
          //@ts-ignore
          render={children}
        />
      ) : (
        <Redirect to={{ pathname: '/dashboard?redirect=true' }} />
      )}

      <FeedbackCard />
      <ModalDesigner
        title="Funcionalidade premium"
        id="premium"
        isOpen={modalPremium}
        setState={setModalPremium}
        size="md"
      >
        <RedirectTextPremiumPlan />
      </ModalDesigner>

      <ModalDesigner
        title="Temos de uso iBox6"
        id="termos"
        isOpen={showModalUseOfTerms}
        setState={setShowModalUseOfTerms}
        size="xl"
      >
        <UseOfTerms onCloseModal={() => setShowModalUseOfTerms(false)} />
      </ModalDesigner>

      <Modal
        className="fade"
        show={showModalFinishRegister}
        onHide={() => setShowModalRegisterFinish(false)}
      >
        <Modal.Header>
          <Modal.Title>Finalizar cadastro</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ height: 'auto' }}>
          <RegisterFinish
            onCloseModal={() => setShowModalRegisterFinish(false)}
          />
        </Modal.Body>
      </Modal>
      <ModalDesigner
        title="Finalize seu cadastro"
        id="cadFinish"
        isOpen={needCPF}
        setState={() => window.history.back()}
        size="sm"
      >
        <FinishAccountForm />
      </ModalDesigner>
    </>
  );
}

interface IPropsFilterTableSorte {
  data: any[];
  dir: 'asc' | 'ASC' | 'desc' | 'DESC';
  key: 'string' | null;
}

export const filterTableSorte = ({
  data,
  dir = 'ASC',
  key = null,
}: IPropsFilterTableSorte) => {
  if (data) {
    const firtsElement = key ? data[0][key] : data[0];
    const isNumber = !isNaN(firtsElement);
    const isAsc = dir.toUpperCase() === 'ASC';

    if (isNumber) {
      //@ts-ignore
      return data.sort((a, b) => {
        const x = key ? a[key] : a;
        const y = key ? b[key] : b;

        if (isAsc) return x - y;
        if (!isAsc) return y - x;
      });
    }
    //@ts-ignore
    return data.sort((a, b) => {
      const x = key ? a[key] : a;
      const y = key ? b[key] : b;

      if (isAsc) return x.localeCompare(y);
      if (!isAsc) return y.localeCompare(x);
    });
  }
};

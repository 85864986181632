import { transformMaskCurrency } from "./transformMaskCurrency";

export function formatedAllValueCurrency(inputValue: number | string) {
  let value = inputValue.toString();

  let valueSplit = value.split('.');
  valueSplit[1] ? null : (valueSplit[1] = '00');
  if (valueSplit[1]?.length === 1) {
    if (value.length < 6) {
      return transformMaskCurrency(Number(inputValue));
    } else if (value.length >= 6 && value.length <= 8) {
      if (value.length === 6) {
        return `R$ ${value.substring(0, 1)}.${value.substring(1, 3)}k`;
      } else if (value.length === 7) {
        return `R$ ${value.substring(0, 2)}.${value.substring(2, 4)}k`;
      } else {
        return `R$ ${value.substring(0, 3)}.${value.substring(3, 5)}k`;
      }
    } else if (value.length >= 9 && value.length <= 11) {
      if (value.length === 9) {
        return `R$ ${value.substring(0, 1)}.${value.substring(1, 3)}mi`;
      } else if (value.length === 10) {
        return `R$ ${value.substring(0, 2)}.${value.substring(2, 4)}mi`;
      } else {
        return `R$ ${value.substring(0, 3)}.${value.substring(3, 5)}mi`;
      }
    } else if (value.length >= 12 && value.length <= 14) {
      if (value.length === 12) {
        return `R$ ${value.substring(0, 1)}.${value.substring(1, 3)}bi`;
      } else if (value.length === 13) {
        return `R$ ${value.substring(0, 2)}.${value.substring(2, 4)}bi`;
      } else {
        return `R$ ${value.substring(0, 3)}.${value.substring(3, 5)}bi`;
      }
    }
  } else if (valueSplit[1]?.length === 2) {
    if (value.length < 7) {
      return transformMaskCurrency(Number(inputValue));
    } else if (value.length >= 7 && value.length <= 9) {
      if (value.length === 7) {
        return `R$ ${value.substring(0, 1)}.${value.substring(1, 3)}k`;
      } else if (value.length === 8) {
        return `R$ ${value.substring(0, 2)}.${value.substring(2, 4)}k`;
      } else {
        return `R$ ${value.substring(0, 3)}.${value.substring(3, 5)}k`;
      }
    } else if (value.length >= 10 && value.length <= 12) {
      if (value.length === 10) {
        return `R$ ${value.substring(0, 1)}.${value.substring(1, 3)}mi`;
      } else if (value.length === 11) {
        return `R$ ${value.substring(0, 2)}.${value.substring(2, 4)}mi`;
      } else {
        return `R$ ${value.substring(0, 3)}.${value.substring(3, 5)}mi`;
      }
    } else if (value.length >= 13 && value.length <= 15) {
      if (value.length === 13) {
        return `R$ ${value.substring(0, 1)}.${value.substring(1, 3)}bi`;
      } else if (value.length === 14) {
        return `R$ ${value.substring(0, 2)}.${value.substring(2, 4)}bi`;
      } else {
        return `R$ ${value.substring(0, 3)}.${value.substring(3, 5)}bi`;
      }
    }
  }

  return;
}

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: 1rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
  padding: 1rem;
  border-radius: 4px;
  box-shadow: ${(props) => props.theme['card-shadow-2']};
`;

export const ContentCalendar = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

export const DataCalendar = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;

  div {
    border-left: 3px solid ${(props) => props.theme.primary};
    padding-left: 10px;
    width: 100%;
    max-width: 300px;

    p {
      margin-bottom: 10px;

      strong {
        color: ${(props) => props.theme.black};
      }
    }
  }
`;

export const TabFilter = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  background-color: ${(props) => props.theme.white};
  padding: 0.625rem 1.5rem;
  border-radius: 4px;
  box-shadow: ${(props) => props.theme['card-shadow-2']};

  header {
    display: flex;
    justify-content: space-between;
    span {
      transition: 0.3s;
      cursor: pointer;
      :hover {
        color: red;
      }
    }
  }
  .contentInputDate {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    align-items: flex-end;
    gap: 1rem;
    width: 100%;
    .select {
      .css-yk16xz-control {
        width: 100%;
      }
    }
    label {
      margin: 0;

      .inputDate {
        padding: 0.5rem;
        border: ${(props) => props.theme.border};
        border-radius: 4px;
        outline: none;
        width: 100%;

        :focus {
          box-shadow: 0 0 0 2px #2684ff;
        }
      }
    }

    @media (max-width: 410px) {
      flex-direction: column;

      label {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }
    }
  }
  .selectedsFilter {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    .select {
      width: 100%;
      max-width: 198px;

      .css-yk16xz-control {
        width: 100%;
      }
    }
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.white};
  border-radius: 8px;
  padding: 1rem;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 375px;
  gap: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h5 {
    margin: 0;
    font: 700 1.5rem 'Poppins', sans-serif;
    color: ${(props) => props.theme['gray-700']};
  }

  h6 {
    margin: 0;
    font: 500 1rem 'Poppins', sans-serif;
    color: ${(props) => props.theme['gray-700']};
    margin: 0;
  }
  .reduce {
    max-width: 20ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .titleContent {
    display: flex;
    flex-direction: column;
    /* gap: 1rem; */
    text-align: center;
    justify-content: center;
  }
`;

export const ContentTab = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;

  .contentFilter {
    display: flex;
    gap: 0.5rem;

    strong {
      color: ${(props) => props.theme['gray-700']};
      font-size: 1rem;
      align-items: center;
      display: flex;
      margin: 0;
    }

    select {
      border-radius: 4px;
      border: ${(props) => props.theme.border};
      color: ${(props) => props.theme['gray-700']};
      padding: 0.5rem;
    }
  }
`;

export const ContentAssetsAndRights = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
  max-height: 500px;
`;
export const ContentWarning = styled.p`
  border-radius: 4px;
  font-size: 1rem;
  line-height: 130%;
  color: ${(props) => props.theme['gray-700']};
  background-color: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme['card-shadow-2']};
  padding: 1rem;
  margin: 0;
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ContentAssetNotes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  h3 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 500;
    border-bottom: ${(props) => props.theme.border};
    line-height: 140%;
  }

  p {
    margin: 0;
    color: ${(props) => props.theme['gray-700']};
    line-height: 130%;
    font-size: 1rem;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-left: 1rem;
    li {
      list-style: outside;
      color: ${(props) => props.theme['gray-700']};
      font-size: 1rem;
      line-height: 130%;
    }
  }
`;

import {
  IPropsException,
  IPropsPosition,
  IWalletProps,
} from '../../../../utils/@types/wallet';

interface IPropsDisplayColor {
  type: 'fii' | 'action' | 'all' | 'broker';
  wallet: IWalletProps;
  item: IPropsPosition;
  exceptions: IPropsException[];
  filterBrokerage?: string;
  typeWallet?: boolean;
}

export function defineDisplayColor({
  exceptions,
  wallet,
  type,
  item,
  filterBrokerage,
  typeWallet = false,
}: IPropsDisplayColor) {
  if (type === 'all') {
    const isAllReadyExistException = exceptions.find(
      (exception) => exception.type === type
    );

    if (typeWallet) {
      if (item.percent < item.porcentagem_recomendada) {
        return 'red';
      } else {
        return '#44bd32';
      }
    }

    if (isAllReadyExistException) {
      if (item.percent < isAllReadyExistException.value) {
        return 'red';
      } else {
        return '#44bd32';
      }
    } else if (item.productTypeName === 'fii') {
      if (item.percent < wallet.objetivoAllFII) {
        return 'red';
      } else {
        return '#44bd32';
      }
    } else if (item.productTypeName === 'acao') {
      if (item.percent < wallet.objetivoAllAcao) {
        return 'red';
      } else {
        return '#44bd32';
      }
    } else {
      if (item.percent < wallet.objetivoAllRenda) {
        return 'red';
      } else {
        return '#44bd32';
      }
    }
  }
  if (type === 'action') {
    const isAllReadyExistException = exceptions.find(
      (exception) => exception.type === type
    );
    if (!isAllReadyExistException) {
      if (item.percent < wallet.objetivoAcao) {
        return 'red';
      } else {
        return '#44bd32';
      }
    } else {
      if (item.percent < isAllReadyExistException.value) {
        return 'red';
      } else {
        return '#44bd32';
      }
    }
  }
  if (type === 'fii') {
    const isAllReadyExistException = exceptions.find(
      (exception) => exception.type === type
    );
    if (!isAllReadyExistException) {
      if (item.percent < wallet.objetivoFII) {
        return 'red';
      } else {
        return '#44bd32';
      }
    } else {
      if (item.percent < isAllReadyExistException.value) {
        return 'red';
      } else {
        return '#44bd32';
      }
    }
  }
  if (filterBrokerage) {
    const isAllReadyExistException = exceptions.find(
      (exception) => exception.corretora === filterBrokerage
    );
    if (!isAllReadyExistException) {
      if (item.percent < wallet.objetivoAll) {
        return 'red';
      } else {
        return '#44bd32';
      }
    } else {
      if (item.percent < isAllReadyExistException.value) {
        return 'red';
      } else {
        return '#44bd32';
      }
    }
  }
  return '#FFFFFF';
}

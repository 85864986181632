import axios from 'axios';
import { useEffect, useState } from 'react';
import { Switch } from 'antd';
import { LinkAccountB3 } from '../LinkAccountB3';
import { useTitleAndUser } from '../../../../../hooks/userTitle';
import { B3, financeRoutes } from '../../../../../utils/RoutesApi';
import { useActives } from '../../../../../hooks/useActives';
import { handleToast } from '../../../../../components/Toast';
import {
  ButtonDeleteAccount,
  CardContentHeader,
  Col,
  ColContent,
  Container,
  LinkButton,
  RowContent,
  RowContentForm,
  RowHeader,
  BannerContent,
  Header,
} from './styles';
import { ModalDesigner } from '../../../../../components/ModalDesigner';
import { EditUserInfoForm } from './components/EditUserInfoForm';
import { EditAddressForm } from './components/EditAddressFrom';
import { ChangePassword } from './components/ChangePassword';
import { DeleteAccount } from './DeleteAccount';
import BFBanner from '../../../../../assets/business/BannerFimdeAno.png';

export function EditProfile() {
  //@ts-ignore
  const { user, setUser, setTitle } = useTitleAndUser();
  //@ts-ignore
  const { setActives, setPercentage } = useActives();
  const [checked, setChecked] = useState<boolean>(user.automatico);
  const [modalLinkAccountB3, setModalLinkAccountB3] = useState<boolean>(false);
  const [showModalDeleteAccount, setShowModalDeleteAccount] = useState(false);

  //@ts-ignore
  const getDataUser = JSON.parse(
    //@ts-ignore
    localStorage.getItem(process.env.REACT_APP_USER_VERSION)
  );

  useEffect(() => {
    setTitle('Editar perfil');
  }, []);

  async function handleChangeTheStateOfTheWallet(isAutomatic: boolean) {
    let data = {
      automatico: isAutomatic,
    };

    await axios
      .post(`${process.env.REACT_APP_API_URL}${B3.WALLET_AUTOMATIC}`, data)
      .then(async (response) => {
        setChecked(isAutomatic);

        let res = {
          ...response.data.user,
          data_trial: getDataUser.dias_trial,
          step: 3,
        };
        if (res) {
          let isIncludeImage = false;
          if (res.profile.image) {
            const includeImage = res.profile.image.match(
              'https://storage.googleapis.com/ibox6-wordpress.appspot.com/'
            );

            isIncludeImage = !!includeImage;
          }

          let isIncludeBanner = false;

          if (res.profile.bannet) {
            const includeBanner = res.profile.banner.match(
              'https://storage.googleapis.com/ibox6-wordpress.appspot.com/'
            );

            isIncludeBanner = !!includeBanner;
          }

          if (isIncludeImage || isIncludeBanner) {
            let obj = {
              ...getDataUser,

              user: res,
            };
            localStorage.setItem(
              //@ts-ignore
              process.env.REACT_APP_USER_VERSION,
              JSON.stringify(obj)
            );
            setUser(obj.user);
            handleToast(false, response.data.message);

            await axios
              .get(
                `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_POSITION}`
              )
              .then(function (result) {
                setActives(result.data);
                let act = result.data.sumAcao.toFixed(2);
                let fii = result.data.sumsFII.toFixed(2);
                let rf = result.data.sumsEFT.toFixed(2);
                const total = Number(act) + Number(fii) + Number(rf);

                let actionPercentage =
                  result.data.sumAcao === 0
                    ? 0
                    : Number((result.data.sumAcao / total) * 100);
                actionPercentage = Number(actionPercentage.toFixed(2));

                let fiiPercentage =
                  result.data.sumsFII === 0
                    ? 0
                    : (result.data.sumsFII / total) * 100;

                fiiPercentage = Number(fiiPercentage.toFixed(2));
                let rfPercentage =
                  result.data.sumsEFT === 0
                    ? 0
                    : (result.data.sumsEFT / total) * 100;

                rfPercentage = Number(rfPercentage.toFixed(2));

                let newPercentage = {
                  fii: fiiPercentage,
                  action: actionPercentage,
                  rendaFixa: rfPercentage,
                };
                setPercentage(newPercentage);
              })
              .catch(function (error) {});

            return;
          }

          if (res.profile.image) {
            res.profile.image =
              process.env.REACT_APP_API_URL +
              res.profile.image.replace(`${process.env.REACT_APP_API_URL}`, '');
          }
          if (res.profile.banner) {
            res.profile.banner =
              process.env.REACT_APP_API_URL +
              res.profile.banner.replace(
                `${process.env.REACT_APP_API_URL}`,
                ''
              );
          }

          let obj = {
            ...getDataUser,

            user: res,
          };
          localStorage.setItem(
            //@ts-ignore
            process.env.REACT_APP_USER_VERSION,
            JSON.stringify(obj)
          );
          setUser(obj.user);
          handleToast(false, response.data.message);

          await axios
            .get(
              `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_POSITION}`
            )
            .then(function (result) {
              setActives(result.data);
              let act = result.data.sumAcao.toFixed(2);
              let fii = result.data.sumsFII.toFixed(2);
              let rf = result.data.sumsEFT.toFixed(2);
              const total = Number(act) + Number(fii) + Number(rf);

              let actionPercentage =
                result.data.sumAcao === 0
                  ? 0
                  : Number((result.data.sumAcao / total) * 100);
              actionPercentage = Number(actionPercentage.toFixed(2));

              let fiiPercentage =
                result.data.sumsFII === 0
                  ? 0
                  : (result.data.sumsFII / total) * 100;

              fiiPercentage = Number(fiiPercentage.toFixed(2));
              let rfPercentage =
                result.data.sumsEFT === 0
                  ? 0
                  : (result.data.sumsEFT / total) * 100;

              rfPercentage = Number(rfPercentage.toFixed(2));

              let newPercentage = {
                fii: fiiPercentage,
                action: actionPercentage,
                rendaFixa: rfPercentage,
              };
              setPercentage(newPercentage);
            })
            .catch(function (error) {});

          return;
        }
      })
      .catch((err) => {});
  }

  return (
    <Header>
    <BannerContent
      src={BFBanner}
      onClick={() => {
        window.open('/pagamentos');
      }}
    />
    <Container>
      <RowHeader>
        <CardContentHeader>
          <header>
            <h1>Importação automática</h1>
            {user.carteira === 'automatico' && (
              <Switch
                checked={checked}
                onClick={() => handleChangeTheStateOfTheWallet(!checked)}
                checkedChildren="ON"
                unCheckedChildren="OFF"
              />
            )}
          </header>
          <p>
            Quando estiver ativa seus dados serão importados da sua carteira B3
            automaticamente.
          </p>
          <div className="contentButton">
            <LinkButton
              onClick={() =>
                window.open('https://ibox6.com.br/conexao-com-a-b3/')
              }
              type="button"
            >
              Como conectar minha carteira B3
            </LinkButton>
            {user.carteira !== 'automatico' && (
              <LinkButton
                type="button"
                onClick={() => setModalLinkAccountB3(true)}
              >
                Vincular B3
              </LinkButton>
            )}
          </div>
        </CardContentHeader>
      </RowHeader>

      <RowContentForm>
        <EditUserInfoForm />
        <EditAddressForm />
        <div>
          <ChangePassword />
          <br />
          <CardContentHeader>
            <header>
              <h1>Desativar conta</h1>
            </header>
            <p>Sua conta ficara inativa até você ativa-lá novamente.</p>
            <ButtonDeleteAccount
              type="button"
              onClick={() => setShowModalDeleteAccount(true)}
            >
              Desativar conta
            </ButtonDeleteAccount>
          </CardContentHeader>
        </div>
      </RowContentForm>

      <ModalDesigner
        id="desativarConta"
        isOpen={showModalDeleteAccount}
        setState={setShowModalDeleteAccount}
        title="Desativar conta"
        size="md"
      >
        <DeleteAccount onCloseModal={setShowModalDeleteAccount} />
      </ModalDesigner>
      <ModalDesigner
        id="linkModal"
        isOpen={modalLinkAccountB3}
        setState={setModalLinkAccountB3}
        title="Vincular conta B3"
        size="sm"
      >
        <LinkAccountB3 onCloseModal={setModalLinkAccountB3} />
      </ModalDesigner>
    </Container>
    </Header>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.white};
  border-radius: 8px;
  position: relative;
  z-index: 1;
  padding: 1rem;

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
    align-items: flex-start;

    .infoContent {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      span {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0;
        font: 400 0.75rem 'Poppins', sans-serif;
        color: #5e666c;
        gap: 0.3rem;
      }
    }
  }

  .dividerResponse {
    width: 100%;
    height: 1px;
    background: #5e666c;
    display: flex;
    justify-content: center;
  }

  .responseCommentContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-left: 2rem;

    footer {
      width: 100%;
      justify-content: flex-end;
    }
  }
  .contentAvatar {
    width: 3.375rem;
    height: 3.375rem;
    font-size: 2rem;

    background: ${(props) => props.theme['gray-100']};
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      object-fit: cover;
      border-radius: 100%;
      width: 3.375rem;
      height: 3.375rem;
      background: ${(props) => props.theme.black};
    }
  }
  .iconDelete {
    cursor: pointer;
    transition: color 0.3s;
    :hover {
      color: red;
    }
  }

  main {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    p {
      margin: 0;
      font: 400 1rem 'Poppins', sans-serif;
      color: ${(props) => props.theme['gray-700']};
      span {
        font: 400 1rem 'Poppins', sans-serif;
        color: ${(props) => props.theme['gray-700']};
      }

      h1 {
        font-size: 1.5rem;
        color: ${(props) => props.theme['gray-700']};
        margin: 0;
      }
      strong {
        font-size: 1rem;
        font-weight: 600;
        color: ${(props) => props.theme['gray-700']};
      }

      a {
        color: ${(props) => props.theme.primary};
        font-size: 1rem;
        transition: filter 0.3s;
        :hover {
          filter: brightness(0.9);
        }
      }
    }

    .more {
      color: ${(props) => props.theme.primary};
      font-weight: 500;
      font-size: 1rem;
      cursor: pointer;
    }

    .actions {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;

      span {
        display: flex;
        gap: 5px;
        align-items: center;
      }
    }

    .buttonContent {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      div {
        display: flex;
        flex-direction: row;
      }

      button {
        font: 400 1rem 'Poppins', sans-serif;
      }

      span {
        font-size: 0.875rem;
        font-weight: 400;
        color: #5e666c;
        text-decoration: underline;
        cursor: pointer;
      }

      @media (max-width: 415px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .commentAnswers {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: start;

      .contentResComment {
        display: flex;
        flex-direction: column;
        background-color: #f3f3f3;
        padding: 0.5rem;
        border-radius: 4px;
      }
    }
  }

  footer {
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 1rem;
    width: 100%;

    margin-top: 1rem;

    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      textarea {
        padding: 0.5rem;
        width: 100%;
        border: ${(props) => props.theme.border};
        border-radius: 8px;
        min-height: 60px;
        max-height: 60px;
        resize: none;
        margin-bottom: 0.5rem;
        color: black;
      }
    }
  }
`;

export const UserContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
`;

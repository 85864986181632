import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme['card-shadow-2']};
  width: 100%;
  max-width: 30rem;
  gap: 1rem;

  header > h1 {
    font-size: 1.5rem;
    color: ${(props) => props.theme['gray-700']};
    font-weight: 700;
    margin: 0;
  }

  input {
    border: ${(props) => props.theme.border};
    border-radius: 4px;
    padding: 0.5rem;
    color: ${(props) => props.theme.black};
    width: 100%;
  }
  .submitButton {
    display: flex;
    justify-content: center;
  }
`;

export const ContentRowInput = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
  gap: 1rem;
  .miniInput {
    width: 100%;
    /* max-width: 5rem; */
  }
`;

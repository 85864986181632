import { ChangeEvent, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import cep from 'cep-promise';
import { Check, MinusCircle } from '@phosphor-icons/react';
import { useTitleAndUser } from '../../../../../../../../../../../hooks/userTitle';
import {
  AddressFormPlanContainer,
  Button,
  ContentInputs,
  Divider,
  FormAddressContent,
  FunctionalityContent,
  ListFunctionalitiesContent,
  SelectedPlanContent,
} from './styles';
import { IPropsNewPlan } from '../../../..';
import { IDataForm } from '../..';

interface IPropsAddressFormPlan {
  selectedPlan: IPropsNewPlan;
  onClearPlan: Function;
  defaultValueBackToScreen: IDataForm;
}

export function AddressFormPlan({
  selectedPlan,
  onClearPlan,
  defaultValueBackToScreen,
}: IPropsAddressFormPlan) {
  //@ts-ignore
  const { user } = useTitleAndUser();
  const {
    register,
    setValue,
    formState: { isSubmitting },
  } = useFormContext();

  useEffect(() => {
    if (defaultValueBackToScreen.zipcode) {
      setValue('zipcode', defaultValueBackToScreen.zipcode);

      setValue('cidade', defaultValueBackToScreen.cidade);
      setValue('bairro', defaultValueBackToScreen.bairro);
      setValue('estado', defaultValueBackToScreen.estado);
      setValue('numero', defaultValueBackToScreen.numero);

      setValue('rua', defaultValueBackToScreen.rua);
    } else if (user) {
      if (user.zipcode) {
        setValue('zipcode', user.zipcode);
      }
      setValue('cidade', user.cidade);
      setValue('bairro', user.bairro);
      setValue('estado', user.estado);
      setValue('numero', user.numero);

      setValue('rua', user.rua);
    }
  }, []);

  async function onBlurCep(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;

    const replaceCep = value.replace(/[^0-9]/g, '');

    if (replaceCep?.length !== 8) {
      return;
    } else {
      cep(replaceCep).then((res) => {
        setValue('bairro', res.neighborhood);
        setValue('cidade', res.city);
        setValue('rua', res.street);
        setValue('estado', res.state);
      });
    }
  }

  return (
    <AddressFormPlanContainer>
      <FormAddressContent>
        <h4>Endereço</h4>
        <ContentInputs>
          <ReactInputMask
            mask="99999-999"
            type="text"
            placeholder="Digite seu cep"
            {...register('zipcode')}
            defaultValue={
              defaultValueBackToScreen.zipcode
                ? defaultValueBackToScreen.zipcode
                : user
                ? user.zipcode
                : null
            }
            onBlur={onBlurCep}
          />
          <input type="text" placeholder="Estado" {...register('estado')} />
          <input type="text" placeholder="Cidade" {...register('cidade')} />
          <input type="text" placeholder="Bairro" {...register('bairro')} />
          <div className="rowContentInput">
            <input type="text" placeholder="Rua" {...register('rua')} />
            <input type="text" placeholder="N°" {...register('numero')} />
          </div>
        </ContentInputs>
        <Button type="submit" disabled={isSubmitting}>
          Continuar
        </Button>
      </FormAddressContent>
      <Divider />
      <SelectedPlanContent>
        <h4>Plano selecionado</h4>
        <h3>
          iBox6 <span>{selectedPlan.title}</span>
        </h3>
        <ListFunctionalitiesContent>
          {selectedPlan.funcionalities.map((func) => (
            <li key={func.label}>
              <FunctionalityContent disabled={func.disabled}>
                {func.disabled ? (
                  <MinusCircle weight="bold" />
                ) : (
                  <Check weight="bold" />
                )}
                <strong>{func.label}</strong>
              </FunctionalityContent>
            </li>
          ))}
        </ListFunctionalitiesContent>
        <Button type="button" variant="secondary" onClick={() => onClearPlan()}>
          Escolher outro plano
        </Button>
      </SelectedPlanContent>
    </AddressFormPlanContainer>
  );
}

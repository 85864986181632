import { applyMiddleware, combineReducers, compose,createStore,} from 'redux';
import { configureStore , getDefaultMiddleware} from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { AuthReducer } from './reducers/AuthReducer';
import postSlice from '../social/redux/post';
import uiReducer from '../social/redux/ui';
import userSlice from '../social/redux/user';
import profileSlice from '../social/redux/profile';
import recommendedSlice from '../social/redux/recommended';
import searchSlice from '../social/redux/search';
import notificationsSlice from '../social/redux/notifications';
const middleware = applyMiddleware(thunk);

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    notifications: notificationsSlice,
    recommended: recommendedSlice,
    post: postSlice,
    search: searchSlice,
    profile: profileSlice,
    ui:uiReducer,
    auth: AuthReducer,
    user: userSlice,
    //  post: post,
	
});


const rootReducer = (state, action) => {
  if (action.type === 'user/logout') {
    // eslint-disable-next-line no-param-reassign
    state = {};
  }
  return reducers(state, action);
};
const customizedMiddleware = getDefaultMiddleware({
  immutableCheck: false,
  serializableCheck: false,
});
//const store = createStore(rootReducers);
const store = configureStore({
  reducer: rootReducer,
  middleware: customizedMiddleware,
});

export default store;
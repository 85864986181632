import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { IPropsInicialCustody } from '../../@types';
import { transformMaskCurrency } from '../../../../../utils/transformMaskCurrency';
import { filterTableSorte } from '../../../../../utils/filterTableSort';
import { Input } from '../../../../../components/Input';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontFamily: 'Poppins, sans-serif',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: 'Poppins, sans-serif',
  },
}));

const StyledBody = styled(TableBody)(
  ({ theme }) => `
  overflow-y: auto;
  max-height:500px;

`
);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface IPropsHeaderTable {
  label: string;
  active?: string;
}

interface IPropsTableIncomeTax {
  data: IPropsInicialCustody[];
  header: IPropsHeaderTable[];
  ASC: Function;
  DESC: Function;
  onEditPM: (id: string, value: number) => void;
}

export function TableInicialCustody({
  data,
  header,
  ASC,
  DESC,
  onEditPM,
}: IPropsTableIncomeTax) {
  function formatValueForCurrency(value: number): string {
    return transformMaskCurrency(value);
  }

  return (
    <TableContainer component={Paper} style={{ maxHeight: '500px' }}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table" stickyHeader>
        <TableHead>
          <TableRow>
            {header.map((item) => (
              <StyledTableCell key={item.active}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    fontSize: '0.95rem',
                    width: '100%',
                  }}
                >
                  {item.label}
                  {item.active && (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <CaretUp
                        size={14}
                        color="black"
                        onClick={() => {
                          return ASC(
                            filterTableSorte({
                              data: data,
                              dir: 'asc',
                              //@ts-ignore
                              key: item.active,
                            })
                          );
                        }}
                      />
                      <CaretDown
                        size={14}
                        color="black"
                        onClick={() => {
                          return DESC(
                            filterTableSorte({
                              data: data,
                              dir: 'desc',
                              //@ts-ignore
                              key: item.active,
                            })
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <StyledBody>
          {data.length > 0 ? (
            data.map((item, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>{item.tickerSymbol}</StyledTableCell>
                <StyledTableCell>{item.productTypeName}</StyledTableCell>
                <StyledTableCell>{item.equitiesQuantity}</StyledTableCell>
                <StyledTableCell className="inputEditContent">
                  <span>R$</span>{' '}
                  <Input
                    mask="currency"
                    type="text"
                    value={item.preco_medio}
                    //@ts-ignore
                    onChange={(e) =>
                      onEditPM(item.tickerSymbol, e.target.value)
                    }
                  />
                </StyledTableCell>
                <StyledTableCell>
                  {formatValueForCurrency(
                    item.preco_medio * item.equitiesQuantity
                  )}
                </StyledTableCell>
              </StyledTableRow>
            ))
          ) : (
            <h1 style={{ fontSize: '1rem', margin: '1rem 0.5rem' }}>
              Ainda não há dados ou base em processamento
            </h1>
          )}
        </StyledBody>
      </Table>
    </TableContainer>
  );
}

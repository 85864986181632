import { useForm } from 'react-hook-form';
import { DeleteAccountContainer } from './styles';
import { ButtonDesign } from '../../../../../../components/ButtonDesign';
import { handleToast } from '../../../../../../components/Toast';
import axios from 'axios';
import { baseURL } from '../../../../../../utils/baseURL';

interface IPropsDeleteAccount {
  onCloseModal: Function;
}

export function DeleteAccount({ onCloseModal }: IPropsDeleteAccount) {
  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm();

  async function handleDeleteAccount(data: any) {
    if (!data.accepted) {
      return handleToast(
        true,
        'Você precisa concordar com a desativação da sua conta'
      );
    }

    await axios.post(`${baseURL}/api/deactivate-account/`);
  }

  return (
    <DeleteAccountContainer onSubmit={handleSubmit(handleDeleteAccount)}>
      <p>
        Ao desativar sua conta todos os seus dados ficaram congelados, você só
        conseguira acessar novamente quando reativa-lá{' '}
      </p>
      <label htmlFor="deleteAccount">
        <input type="checkbox" id="deleteAccount" {...register('accepted')} />{' '}
        Estou ciente que so poderei acessar novamente quando reativa-lá.
      </label>
      <div className="contentButtons">
        <button className="deleteButton" type="submit" disabled={isSubmitting}>
          Desativar conta
        </button>
        <ButtonDesign
          size="btn-small"
          type="button"
          onClick={() => onCloseModal(false)}
        >
          Voltar
        </ButtonDesign>
      </div>
    </DeleteAccountContainer>
  );
}

import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Loading from './Loading';
import * as Constants from '../Constants';
import {
  BodyContentVideo,
  Button,
  Container,
  ContainerVideos,
  ContentButton,
  ContentVideo,
  HeaderUpload,
  Thumb,
} from './UploadStyles';

const Upload = (props) => {
  const userInfo = useSelector((state) => state.auth.auth.user.username);
  const [showLoading, setShowLoading] = useState(true);
  const [uploads, setuploads] = useState([]);


  useEffect(() => {
    axios
      .get(`${Constants.api}/videoscube/api/channel/?user_id=${userInfo}`)
      .then((response) => {
        if (response.data.length) {
          axios
            .get(
              `${Constants.api}/videoscube/api/video/?channel__id=${
                props.ChannelId ? props.ChannelId : response.data[0].id
              }`
            )
            .then((videoResponse) => {
              if (videoResponse.data.data.length) {
                setuploads(videoResponse.data.data);
              } else {
                setShowLoading(false);
              }
            })
            .catch((error) => {
            });
        } else {
          setShowLoading(false);
        }
      })
      .catch((error) => {
      });
  }, [userInfo, props.ChannelId]);

  return (
    <Container>
      <HeaderUpload>
        <h3>Uploads</h3>
      </HeaderUpload>

      <ContainerVideos>
        {uploads.length ? (
          uploads.map((item, i) => {
            return (
              <ContentVideo key={item.id}>
                <Thumb>
                  <img src={item.thumbnail} />
                </Thumb>
                <BodyContentVideo>
                  <h4>{item.title}</h4>

                  <span>{item.channelTitle}</span>

                  <span>
                    <span>{item.view.length}</span> Views &nbsp;&nbsp;•&nbsp;
                    {(() => {
                      const date = new Date(`${item.date}`);
                      return Constants.timeAgo.format(date);
                    })()}
                  </span>
                </BodyContentVideo>
                <ContentButton>
                  <Button title="Assistir">Assistir</Button> |{' '}
                  <Button title="Deletar">Deletar</Button>
                </ContentButton>
              </ContentVideo>
            );
          })
        ) : (
          <>{showLoading ? <Loading /> : ''}</>
        )}
      </ContainerVideos>
    </Container>
  );
};

export default Upload;

import axios from 'axios';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { ResetTrialContainer } from './styles';
import { ButtonComponent } from '../../../../../../components/ButtonComponent';
import { administrationController } from '../../../../../../utils/RoutesApi';
import { handleToast } from '../../../../../../components/Toast';

interface IPropsResetTrialForm {
  email: string;
  onCloseModal: Function;
}

const baseUrl = process.env.REACT_APP_API_URL;

export function ResetTrialForm({ email, onCloseModal }: IPropsResetTrialForm) {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  async function handleDefinedNewTrial(data: any) {
    if (!data.daysTrial) {
      return handleToast(
        true,
        'Informe quantos dias de trial você quer adicionar'
      );
    }
    const daysTrial = data.daysTrial;
    const removeDays = 30 - daysTrial;
    const setDaysTrial = moment().subtract(removeDays, 'days').format();
    await axios
      .put(`${baseUrl}${administrationController.UPDATE_DATA_USER(email)}`, {
        date_joined: setDaysTrial,
      })
      .then((response) => {
      })
      .catch((err) => {
      });
  }

  async function handleResetSevenDaysTrial() {
    await axios
      .put(`${baseUrl}${administrationController.UPDATE_DATA_USER(email)}`, {
        date_joined: moment().subtract(23, 'days').format(),
      })
      .then((response) => {
        onCloseModal();
      })
      .catch((err) => {
      });
  }

  async function handleResetThirtyDaysTrial() {
    await axios
      .put(`${baseUrl}${administrationController.UPDATE_DATA_USER(email)}`, {
        date_joined: moment().format(),
      })
      .then((response) => {
        onCloseModal();
      })
      .catch((err) => {
      });
  }

  return (
    <ResetTrialContainer>
      <form onSubmit={handleSubmit(handleDefinedNewTrial)}>
        <label>
          Quantos dias quer adicionar de trial? de (1 - 30)
          <input type="number" {...register('daysTrial')} />
        </label>

        <div className="buttonContent">
          <ButtonComponent disabled={isSubmitting} type="submit">
            Enviar
          </ButtonComponent>
          <ButtonComponent
            disabled={isSubmitting}
            type="button"
            onClick={handleResetSevenDaysTrial}
          >
            Resetar em 7 dias
          </ButtonComponent>
          <ButtonComponent
            disabled={isSubmitting}
            type="button"
            onClick={handleResetThirtyDaysTrial}
          >
            Resetar em 30 dias
          </ButtonComponent>
        </div>
      </form>
    </ResetTrialContainer>
  );
}

import ContentLoader from 'react-content-loader';

export function LoadingCard() {
  return (
    <ContentLoader
      width="auto"
      height={121}
      style={{
        background: 'white',
        borderRadius: '4px',
        padding: '0',
        boxShadow: '0px 1px 3px rgba(96, 97, 112, 0.16)',
      }}
    >
      <rect x="28" y="29" rx="0" ry="0" width="200" height="32" />
      <rect x="28" y="71" rx="0" ry="0" width="250" height="32" />
    </ContentLoader>
  );
}

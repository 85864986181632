import styled from 'styled-components';
export const FatosContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  text-align: justify;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-height: 500px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  overflow-y: auto;
  span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
  }
`;
export const FatoContainer = styled.div`
  width: 40%;

  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
  p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
  }
  span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }
  a {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2469a8;

    /* identical to box height, or 143% */
    display: flex;
    align-items: center;
    text-transform: uppercase;
  }

  @media (max-width: 480px) {
    width: auto;
  }
`;
export const Flag = styled.p`
  background: #afd9ff;
  color: #2469a8;
  border-radius: 10px;
  border: none;
  width: auto;
  padding: 5px;
  padding-left: 7px;
  padding-right: 7px;
  font-size: 10px !important;
`;
export const ListIten = styled.li`
  color: #2469a8;
  cursor: pointer;
`;

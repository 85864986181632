import axios from 'axios';
import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import moment from 'moment';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import ReactApexChart from 'react-apexcharts';

import { useTitleAndUser } from '../../../hooks/userTitle';
import { LinkB3Page } from '../LinkB3Page';
import { NoDataB3 } from '../Components/NoDataB3';
import { Card } from '../Proventos/Components/GraficoProxProventos/styles';
import { useActives } from '../../../hooks/useActives';
import { CardDetailedReport } from './components/CardDetailedReport';
import { TableProfitability } from '../../../components/Tables/TableProfitability';
import { GraphicFilter } from './components/GraphicFilter';
import { financeRoutes } from '../../../utils/RoutesApi';
import { EarningReceivedLoader } from '../../../jsx/components/LoadingModels';
import { transformMaskCurrency } from '../../../utils/transformMaskCurrency';
import { Container, Content, Row, TabFilter } from './styles';
import { Heading } from '../../../components/DefaultStylesTypography';
import { ModalDesigner } from '../../../components/ModalDesigner';

export function FiiCalender() {
  const { user, setTitle } = useTitleAndUser();
  const { showActive } = useActives();
  const { register, setValue } = useForm();
  const [values, setValues] = useState({
    count: 0,
    current: 0,
    data: { total_periodos: [], rendimentos: [] },
    links: { next: null, previous: null },
    total_pages: 0,
    isFilter: false,
  });
  const [valuesSeparatedByType, setValuesSeparatedByType] = useState({
    jcp: [],
    div: [],
    rend: [],
  });
  const [selectDateFilter, setSelectDateFilter] = useState({
    start: '',
    end: '',
  });
  const [loading, setLoading] = useState(false);
  const [showModalDetailedReport, setShowModalDetailedReport] = useState(false);
  const [filterData, setFilterData] = useState({
    Ativos: [],
    Corretoras: [],
    TiposMovimentos: [{ label: 'Todos', value: 'todos' }],
  });
  const [filtersSelecteds, setFiltersSelecteds] = useState({});

  useEffect(() => {
    setTitle('Proventos Recebidos');
  }, []);

  useEffect(() => {
    if (
      selectDateFilter.end !== '' &&
      selectDateFilter.start !== '' &&
      selectDateFilter.end.replace(/\D/g, '').length === 6 &&
      selectDateFilter.start.replace(/\D/g, '').length === 6
    ) {
      let start = selectDateFilter.start.split('/');
      let end = selectDateFilter.end.split('/');
      if (filtersSelecteds.tipoproduto === 'todos') {
        let filter = filtersSelecteds;
        delete filter.tipoproduto;
        handleProceedsReceived({
          de: `${start[1]}${start[0]}`,
          ate: `${end[1]}${end[0]}`,
          ...filter,
        });
      } else {
        handleProceedsReceived({
          de: `${start[1]}${start[0]}`,
          ate: `${end[1]}${end[0]}`,
          ...filtersSelecteds,
        });
      }
    } else if (selectDateFilter.end === '' && selectDateFilter.start === '') {
      if (filtersSelecteds.tipoproduto === 'todos') {
        let filter = filtersSelecteds;
        delete filter.tipoproduto;
        handleProceedsReceived(filter);
      } else {
        handleProceedsReceived(filtersSelecteds);
      }
    }
  }, [selectDateFilter, filtersSelecteds]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_FILTERS_RECEBIDOS}`
      )
      .then((response) => {
        const newDataActives = response.data.Ativos.map((item) => {
          if (item.nome) {
            return {
              value: item.ativo,
              label: `${item.ativo} - ${item.nome}`,
            };
          } else {
            return {
              value: item.ativo,
              label: `${item.ativo}`,
            };
          }
        });
        const newDataBrokers = response.data.Corretoras.map((item) => {
          return {
            value: item.participantName,
            label: item.participantName,
          };
        });
        const newDataMovimentType = response.data.TiposMovimentos.map(
          (item) => {
            return {
              value: item.movimentType,
              label: item.movimentType,
            };
          }
        );
        setFilterData({
          TiposMovimentos: [
            { label: 'Todos', value: 'todos' },
            ...newDataMovimentType,
          ],
          Ativos: newDataActives,
          Corretoras: newDataBrokers,
        });
      })
      .catch((err) => {});
  }, []);

  async function handleProceedsReceived(params = null) {
    setLoading(true);
    if (params.tiposmovimento === 'todos') {
      delete params.tiposmovimento;
    }
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_PROCEEDS_RECEIVED}`,
        { params }
      )
      .then((response) => {
        let resp = { ...response.data, isFilter: false };
        setValuesSeparatedByType({
          jcp: resp.data.rendimentos.filter(
            (item) => item.movementType === 'Juros Sobre Capital Próprio'
          ),
          div: resp.data.rendimentos.filter(
            (item) => item.movementType === 'Dividendo'
          ),
          rend: resp.data.rendimentos.filter(
            (item) => item.movementType === 'Rendimento'
          ),
        });

        resp.data.total_periodos = resp.data.total_periodos.sort((a, b) => {
          if (a.period < b.period) {
            return -1;
          } else {
            return true;
          }
        });
        resp.data.rendimentos = resp.data.rendimentos.sort((a, b) => {
          if (a.referenceDate < b.referenceDate) {
            return -1;
          } else {
            return true;
          }
        });
        if (params !== null) {
          resp.isFilter = true;
        } else {
          resp.isFilter = false;
        }
        setValues(resp);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  const isValueOfThePeriodRelatedToTheAction =
    values?.data?.total_periodos?.map((item) => item.total_acao);
  const isValueOfThePeriodRelatedToTheFII = values?.data?.total_periodos?.map(
    (item) => item.total_fii
  );
  const isValueOfThePeriodRelatedToTheTotal = values?.data?.total_periodos?.map(
    (item) => item.total
  );

  const isTotalAmountReceivedInThePeriod = transformMaskCurrency(
    values.data.total_periodos.reduce((acc, value) => acc + value.total, 0)
  );

  const graphicData = {
    series: [
      {
        name: 'Ação',
        type: 'column',
        data: isValueOfThePeriodRelatedToTheAction,
      },
      {
        name: 'Fii',
        type: 'column',
        data: isValueOfThePeriodRelatedToTheFII,
      },
      {
        name: 'Total',
        type: 'line',
        data: isValueOfThePeriodRelatedToTheTotal,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        stacked: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [4, 4, 4],
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      colors: [
        'var(--graphic-action)',
        'var(--graphic-fii)',
        'var(--graphic-total)',
      ],
      legend: {
        position: 'bottom',
        horizontalAlign: 'right',
      },
      xaxis: {
        categories: values?.data?.total_periodos?.map((item) =>
          item.period.toString()
        ),
        labels: {
          formatter: function (value) {
            if (value) {
              let year = value?.substring(0, 4);
              let month = value?.substring(4, 6);
              return `${month}/${year}`;
            }
          },
        },
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            if (showActive) {
              return transformMaskCurrency(value);
            } else {
              return 'R$ *****';
            }
          },
        },
      },

      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            if (showActive) {
              return transformMaskCurrency(val);
            } else {
              return 'R$ *****';
            }
          },
        },
      },
    },
  };

  const returnSelectValue = (id, arr) => {
    if (id) {
      let obj = arr?.map((item) => {
        if (item?.value === id) {
          return {
            value: item.value,
            label: item.label,
          };
        }
      });
      return obj;
    } else {
      return null;
    }
  };

  return loading ? (
    <EarningReceivedLoader />
  ) : !user.automatico ? (
    <LinkB3Page />
  ) : values?.isFilter ? (
    <Container>
      <Row>
        <TabFilter>
          <header>
            <h6>Filtrar</h6>
            <span
              onClick={() => {
                setSelectDateFilter({
                  start: '',
                  end: '',
                });
                setValue('end', '');
                setValue('start', '');
                setFiltersSelecteds({});
              }}
            >
              Limpar filtro
            </span>
          </header>
          <div className="contentInputDate">
            <label>
              De:{' '}
              <InputMask
                mask="99/9999"
                placeholder={`Ex: ${moment()
                  .subtract(12, 'months')
                  .format('MM/YYYY')}`}
                {...register('start')}
                className="inputDate"
                defaultValue={selectDateFilter.start}
                onChange={(e) => {
                  if (e.target.value.replace(/\D/g, '').length === 6) {
                    setSelectDateFilter({
                      ...selectDateFilter,
                      start: e.target.value,
                    });
                  }
                }}
              />
            </label>
            <label>
              Até:{' '}
              <InputMask
                mask="99/9999"
                placeholder={`Ex: ${moment().format('MM/YYYY')}`}
                className="inputDate"
                {...register('end')}
                defaultValue={selectDateFilter.end}
                onChange={(e) => {
                  if (e.target.value.replace(/\D/g, '').length === 6) {
                    setSelectDateFilter({
                      ...selectDateFilter,
                      end: e.target.value,
                    });
                  }
                }}
              />
            </label>
            <Select
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? '#0176E1' : 'transparent',
                  borderRadius: 8,
                }),
                container: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: ' 2px 2px 6px 0px #0000001A',
                  borderRadius: 8,
                }),
                input: (baseStyles) => ({
                  ...baseStyles,
                  color: '#808080',
                  fontSize: 16,
                }),
                placeholder: (base) => ({
                  ...base,
                }),
                valueContainer: (base) => ({
                  ...base,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 8,
                  paddingBottom: 8,
                }),
                indicatorSeparator: () => ({
                  display: 'none',
                }),

                dropdownIndicator: (base) => ({
                  ...base,
                  color: '#808080',
                }),
              }}
              className="select"
              options={[
                { label: 'Todos', value: 'todos' },
                { label: 'Ações', value: 'Ações' },
                {
                  label: 'FII - Fundo de Investimento Imobiliário',
                  value: 'FII - Fundo de Investimento Imobiliário',
                },
              ]}
              //@ts-ignore
              onChange={(e) => {
                setFiltersSelecteds({
                  ...filtersSelecteds,
                  tipoproduto: e.value,
                });
              }}
              value={returnSelectValue(filtersSelecteds.tipoproduto, [
                { label: 'Todos', value: 'todos' },

                { label: 'Ações', value: 'Ações' },
                {
                  label: 'FII - Fundo de Investimento Imobiliário',
                  value: 'FII - Fundo de Investimento Imobiliário',
                },
              ])}
              placeholder="Tipo de ativo"
            />

            <Select
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? '#0176E1' : 'transparent',
                  borderRadius: 8,
                }),
                container: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: ' 2px 2px 6px 0px #0000001A',
                  borderRadius: 8,
                }),
                input: (baseStyles) => ({
                  ...baseStyles,
                  color: '#808080',
                  fontSize: 16,
                }),
                placeholder: (base) => ({
                  ...base,
                }),
                valueContainer: (base) => ({
                  ...base,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 8,
                  paddingBottom: 8,
                }),
                indicatorSeparator: () => ({
                  display: 'none',
                }),

                dropdownIndicator: (base) => ({
                  ...base,
                  color: '#808080',
                }),
              }}
              className="select"
              options={filterData.Ativos}
              //@ts-ignore
              onChange={(e) =>
                setFiltersSelecteds({
                  ...filtersSelecteds,
                  ativo: e.value,
                })
              }
              value={returnSelectValue(
                filtersSelecteds.ativo,
                filterData.Ativos
              )}
              placeholder="Selecione o ativo"
            />
            <Select
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? '#0176E1' : 'transparent',
                  borderRadius: 8,
                }),
                container: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: ' 2px 2px 6px 0px #0000001A',
                  borderRadius: 8,
                }),
                input: (baseStyles) => ({
                  ...baseStyles,
                  color: '#808080',
                  fontSize: 16,
                }),
                placeholder: (base) => ({
                  ...base,
                }),
                valueContainer: (base) => ({
                  ...base,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 8,
                  paddingBottom: 8,
                }),
                indicatorSeparator: () => ({
                  display: 'none',
                }),

                dropdownIndicator: (base) => ({
                  ...base,
                  color: '#808080',
                }),
              }}
              className="select"
              options={filterData.Corretoras}
              //@ts-ignore
              onChange={(e) =>
                setFiltersSelecteds({
                  ...filtersSelecteds,
                  corretora: e.value,
                })
              }
              value={returnSelectValue(
                filtersSelecteds.corretora,
                filterData.Corretoras
              )}
              placeholder="Corretora"
            />
            <Select
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? '#0176E1' : 'transparent',
                  borderRadius: 8,
                }),
                container: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: ' 2px 2px 6px 0px #0000001A',
                  borderRadius: 8,
                }),
                input: (baseStyles) => ({
                  ...baseStyles,
                  color: '#808080',
                  fontSize: 16,
                }),
                placeholder: (base) => ({
                  ...base,
                }),
                valueContainer: (base) => ({
                  ...base,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 8,
                  paddingBottom: 8,
                }),
                indicatorSeparator: () => ({
                  display: 'none',
                }),

                dropdownIndicator: (base) => ({
                  ...base,
                  color: '#808080',
                }),
              }}
              className="select"
              options={filterData.TiposMovimentos}
              //@ts-ignore
              onChange={(e) =>
                setFiltersSelecteds({
                  ...filtersSelecteds,
                  tiposmovimento: e.value,
                })
              }
              value={returnSelectValue(
                filtersSelecteds.tiposmovimento,
                filterData.TiposMovimentos
              )}
              placeholder="Tipo de proventos"
            />
          </div>
        </TabFilter>
        <Card>
          <strong>Valor recebido:</strong>
          <Heading as="h3" variant="tertiary">
            {showActive ? isTotalAmountReceivedInThePeriod : 'R$ ******'}
          </Heading>
          <a onClick={() => setShowModalDetailedReport(true)}>Ver mais</a>
        </Card>
      </Row>
      {values?.data?.rendimentos.length &&
      values?.data?.total_periodos.length > 0 ? (
        <>
          {filtersSelecteds.ativo ? (
            <Content>
              <GraphicFilter
                activeFilter={filtersSelecteds.ativo}
                dataGraphic={values.data.total_periodos}
              />
            </Content>
          ) : (
            <Content>
              <ReactApexChart
                options={graphicData.options}
                series={graphicData.series}
                type="line"
                height={350}
              />
            </Content>
          )}
          <TableProfitability
            income={values?.data?.rendimentos}
            ASC={(newValue) =>
              setValues({
                ...values,
                data: {
                  ...values.data,
                  rendimentos: newValue,
                },
              })
            }
            DESC={(newValue) =>
              setValues({
                ...values,
                data: {
                  ...values.data,
                  rendimentos: newValue,
                },
              })
            }
          />
        </>
      ) : (
        <Content>
          <h3 style={{ margin: 0 }}>Não encontramos dados com esses filtros</h3>
        </Content>
      )}

      <ModalDesigner
        size="lg"
        isOpen={showModalDetailedReport}
        setState={setShowModalDetailedReport}
        title="Detalhe dos proventos"
        id="details"
      >
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
          {
            <CardDetailedReport
              title="Dividendo"
              value={valuesSeparatedByType.div
                .reduce((acc, value) => acc + value.operationValue, 0)
                .toFixed(2)}
            />
          }
          {
            <CardDetailedReport
              title="Juros Sobre Capital Próprio"
              value={valuesSeparatedByType.jcp
                .reduce((acc, value) => acc + value.operationValue, 0)
                .toFixed(2)}
            />
          }
          {
            <CardDetailedReport
              title="Rendimento"
              value={valuesSeparatedByType.rend
                .reduce((acc, value) => acc + value.operationValue, 0)
                .toFixed(2)}
            />
          }
        </div>
      </ModalDesigner>
    </Container>
  ) : (
    <NoDataB3 />
  );
}

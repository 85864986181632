import styled from 'styled-components';

export const AdminPlansStyles = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const ContentFormCreatePlan = styled.form`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: ${(props) => props.theme['card-shadow-2']};
  background: ${(props) => props.theme.white};
  width: 100%;
  max-width: 30rem;
  align-items: center;
  gap: 1rem;
  height: 100%;
  max-height: 40rem;
  overflow-y: auto;

  label {
    margin: 0;
    font-weight: 500;
    font-size: 1rem;
    color: ${(props) => props.theme['gray-600']};
    text-align: start;
    width: 100%;
  }
`;

export const ContentInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  label {
    margin: 0;
    font-weight: 500;
    font-size: 1rem;
    color: ${(props) => props.theme['gray-600']};
  }

  select,
  textarea {
    border: 1px solid transparent;
    background: ${(props) => props.theme.white};
    width: 100%;
    padding: 1.125rem 1rem;
    box-shadow: ${(props) => props.theme['shadow-1']};
    border-radius: 8px;
    :focus {
      border-color: ${(props) => props.theme['primary-800']};
    }
    .css-26I3qy-menu {
      max-height: 20px !important;
    }
    :disabled {
      cursor: not-allowed;
      background: ${(props) => props.theme['white-300']};
      button {
        cursor: not-allowed;
      }
    }
  }
  .ant-switch {
    width: 100%;
  }
  textarea {
    min-height: 9rem;
  }
`;
export const ContentRowInput = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  width: 100%;
  label {
    margin: 0;
    font-weight: 500;
    font-size: 1rem;
    color: ${(props) => props.theme['gray-600']};
  }

  select,
  textarea {
    border: 1px solid transparent;
    background: ${(props) => props.theme.white};
    width: 100%;
    padding: 1.125rem 1rem;
    box-shadow: ${(props) => props.theme['shadow-1']};
    border-radius: 8px;
    :focus {
      border-color: ${(props) => props.theme['primary-800']};
    }
    .css-26I3qy-menu {
      max-height: 20px !important;
    }
    :disabled {
      cursor: not-allowed;
      background: ${(props) => props.theme['white-300']};
      button {
        cursor: not-allowed;
      }
    }
  }
  .ant-switch {
    width: 100%;
  }
  textarea {
    min-height: 9rem;
  }
`;

import { useForm } from 'react-hook-form';
import validator from 'validator';

import { FormRecoveryPasswordContainer } from './styles';
import { handleToast } from '../../../components/Toast';
import { authRoutes } from '../../../utils/RoutesApi';
import axios from 'axios';
import { useState } from 'react';
import { whatsAppLinkApi } from '../../../utils/apiWhatsapp';
import { InputStyle } from '../../../components/InputStyle';
import { ButtonStyle } from '../../../components/ButtonStyle';

interface IPropsRecoverPassword {
  email?: string;
  setModalRecoveryPassword: Function;
}

export function FormRecoveryPassword({
  email,
  setModalRecoveryPassword,
}: IPropsRecoverPassword) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm();
  const [typeMessageResponse, setTypeMessageResponse] = useState<
    'Error' | 'Success' | null
  >(null);

  const emailRequest = watch('email');

  async function submit(data: any) {
    if (!data.email) {
      return handleToast(true, 'Email obrigatório');
    }
    const emailValid = validator.isEmail(data.email);
    if (emailValid === false) {
      return handleToast(true, 'Informe um email valido');
    }

    data.email = data.email.toLowerCase();
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}${authRoutes.RECOVER_PASSWORD}`,
        data
      )
      .then((response) => {
        handleToast(false, 'Nova senha enviada para o seu email');
        setTypeMessageResponse('Success');
        setModalRecoveryPassword({
          open: true,
          success: true,
        });
        return;
      })
      .catch((error) => {
        setTypeMessageResponse('Error');
        return handleToast(true, 'Não existe uma conta com esse e-mail');
      });
  }

  return (
    <FormRecoveryPasswordContainer onSubmit={handleSubmit(submit)}>
      {typeMessageResponse !== 'Success' ? (
        <>
          <p>Insira o endereço de e-mail associado à sua conta iBox⁶</p>
          <InputStyle
            label={email ? 'Esse é o seu e-mail?' : 'Informe o seu e-mail'}
          >
            <input
              style={{background: '#FFF', color:'#40444C',}}
              type="email"
              defaultValue={email}
              placeholder="Insira seu e-mail"
              {...register('email')}
            />
          </InputStyle>

          <br />

          <ButtonStyle type="submit" disabled={isSubmitting}>
            continuar
          </ButtonStyle>

          {typeMessageResponse === 'Error' && (
            <p className="errorMessage">
              Não encontramos nenhuma conta cadastrada com o e-mail:{' '}
              <strong>{emailRequest}</strong>. <br />
              Caso precise de ajuda entre em contato com o nosso suporte.
            </p>
          )}

          <p className="textHelp">
            <a href={whatsAppLinkApi} target="_blank">
              Preciso de mais ajuda
            </a>
          </p>
        </>
      ) : (
        <>
          <p className="successMessage">
            Enviamos um e-mail para <strong>{emailRequest}</strong> com tudo o
            que você precisa para recuperar sua senha no iBox6.
            <br />
            <br />
            <span>
              Fique de olho na sua caixa de entrada e, se o e-mail não estiver
              lá, dê uma olhadinha no SPAM ou Lixo Eletrônico. E se surgir
              qualquer dúvida, nosso suporte está à disposição para te auxiliar.
            </span>
          </p>
          <p className="textHelp">
            <a href={whatsAppLinkApi} target="_blank">
              Preciso de mais ajuda
            </a>
          </p>
        </>
      )}

      {/* <span className="registerText">
        <a
          href="#"
          onClick={() => {
            setModalRecoveryPassword({
              open: false,
              success: false,
            });
          }}
        >
          Voltar ao login
        </a>
      </span> */}
    </FormRecoveryPasswordContainer>
  );
}

import { useForm } from 'react-hook-form';

import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { financeRoutes } from '../../../../../utils/RoutesApi';
import { handleToast } from '../../../../../components/Toast';
import { Button, Modal } from 'react-bootstrap';

import { Container, ContentButton, ContentInput } from './styles';
import { ModalDesigner } from '../../../../../components/ModalDesigner';

const PrettoSlider = styled(Slider)({
  color: 'var(--primary)',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: 'var(--primary)',
    color: 'var(--primary)',

    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: 'var(--primary)',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

interface IPropsException {
  corretora: string;
  key: string;
  type: string;
  value: number;
}

interface IPropsEditRule {
  Exception: IPropsException[];
  ValorExp: number;
  aportarQtde: number;
  closingPrice: number;
  corporationName: string;
  equitiesQuantity: number;
  novoupdateValue: number;
  percent: number;
  tickerSymbol: string;
  updateValue: number;
  edit?: boolean;
  typeEdit: 'action' | 'fii' | 'all' | 'broker';
  brokerEdit?: string;
}

interface IPropsFormCreateRule {
  rule: IPropsEditRule;
  showModalCreateRule: boolean;
  setShowModalCreateRule: Function;
  update: () => void;
}

const typeRules = [
  {
    label: 'Ação',
    value: 'action',
  },
  {
    label: 'FII',
    value: 'fii',
  },
  {
    label: 'Todos ativos',
    value: 'all',
  },
  {
    label: 'Por corretora',
    value: 'broker',
  },
];

interface IPropsBrokerage {
  corretora: string;
  sumcorretora: number;
}

export function FormCreateRule({
  rule,
  showModalCreateRule,
  setShowModalCreateRule,
  update,
}: IPropsFormCreateRule) {
  const { handleSubmit } = useForm();
  const [percent, setPercent] = useState<number>(0);
  const [selectedType, setSelectedType] = useState<string>(rule.typeEdit);
  const [brokerages, setBrokerages] = useState<IPropsBrokerage[]>([]);
  const [selectedBroker, setSelectedBroker] = useState<string>(
    rule.brokerEdit ?? ''
  );
  //@ts-ignore
  const [exception, setException] = useState<IPropsException>({});

  useEffect(() => {
    if (rule.edit) {
      if (rule.typeEdit === 'all') {
        const exceptionValue = rule.Exception.find((ex) => ex.type === 'all');
        //@ts-ignore
        setException(exceptionValue ?? {});
        setPercent(exceptionValue?.value ?? 0);
        setSelectedBroker(exceptionValue?.corretora ?? '');
        setSelectedType(exceptionValue?.type ?? 'all');
      } else if (rule.typeEdit === 'broker') {
        const exceptionValue = rule.Exception.find(
          (ex) => ex.type === 'broker'
        );
        //@ts-ignore
        setException(exceptionValue ?? {});
        setPercent(exceptionValue?.value ?? 0);
        setSelectedBroker(exceptionValue?.corretora ?? '');
        setSelectedType(exceptionValue?.type ?? 'broker');
      } else if (rule.typeEdit === 'action') {
        const exceptionValue = rule.Exception.find(
          (ex) => ex.type === 'action'
        );
        //@ts-ignore
        setException(exceptionValue ?? {});
        setPercent(exceptionValue?.value ?? 0);
        setSelectedBroker(exceptionValue?.corretora ?? '');
        setSelectedType(exceptionValue?.type ?? 'action');
      } else if (rule.typeEdit === 'fii') {
        const exceptionValue = rule.Exception.find((ex) => ex.type === 'fii');
        //@ts-ignore
        setException(exceptionValue ?? {});
        setPercent(exceptionValue?.value ?? 0);
        setSelectedBroker(exceptionValue?.corretora ?? '');
        setSelectedType(exceptionValue?.type ?? 'fii');
      }
    }
  }, [rule]);
  useEffect(() => {
    handleGetBrokerages();
  }, []);

  async function handleGetBrokerages() {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_BROKERAGES}`
      )
      .then(function (result) {
        setBrokerages(result.data.result);
      });
  }

  async function submit() {
    let newData = {
      PorcentagemAcoes: percent,
      cod_acao: rule.tickerSymbol,
      tp_acao: '',
      type: selectedType,
      corretora: selectedBroker,
    };

    if (!newData.type) {
      return handleToast(true, 'Selecione o tipo de regra');
    }

    if (newData.type === 'broker') {
      if (newData.corretora === '') {
        return handleToast(true, 'Selecione a corretora');
      }
    }

    if (rule.edit) {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_EXCEPTION_EDIT}`,
          { ...newData, key: exception.key }
        )
        .then((result) => {
          handleToast(false, 'Regra atualizada com sucesso');
          setShowModalCreateRule(false);
          update();
          return;
        })
        .catch((err) => {
          handleToast(true, err.response.data.message);

          return;
        });
      return;
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_EXCEPTION_CREATE}`,
          newData
        )
        .then((result) => {
          handleToast(false, 'Regra criada com sucesso');
          setShowModalCreateRule(false);
          update();
          return;
        })
        .catch((err) => {
          handleToast(true, err.response.data.message);

          return;
        });
      return;
    }
  }

  async function handleDeleteRuleException(code: string) {
    let data = {
      key: code,
    };

    await axios
      .delete(
        `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_EXCEPTION_DELETE}`,
        { data }
      )
      .then((result) => {
        handleToast(false, 'Regra excluida com sucesso');
        setShowModalCreateRule(false);
        update();
        return;
      })
      .catch((err) => {
        handleToast(true, 'Erro ao excluir regra');

        return;
      });
  }

  return (
    <ModalDesigner
      isOpen={showModalCreateRule}
      setState={setShowModalCreateRule}
      id="rule"
      title="Cadastrar nova regra"
    >
      <Container onSubmit={handleSubmit(submit)}>
        <ContentInput>
          <label>Porcentagem</label>

          <div>
            <PrettoSlider
              valueLabelDisplay="auto"
              aria-label="pretto slider"
              defaultValue={rule.ValorExp}
              //@ts-ignore
              onChange={(e) => setPercent(e.target.value)}
              value={percent}
              className="slider"
            />

            <input
              value={percent}
              placeholder="0"
              type="number"
              step="0.01"
              onChange={(e) => {
                //@ts-ignore
                setPercent(e.target.value);
              }}
            />
          </div>
        </ContentInput>
        <ContentInput>
          <select value={selectedType} disabled>
            {typeRules.map((type) => (
              <option value={type.value} key={type.label}>
                {type.label}
              </option>
            ))}
          </select>
        </ContentInput>
        {selectedType === 'broker' && (
          <ContentInput>
            <select value={selectedBroker} disabled>
              {brokerages.map((broker) => (
                <option value={broker.corretora} key={broker.corretora}>
                  {broker.corretora}
                </option>
              ))}
            </select>
          </ContentInput>
        )}

        <ContentButton>
          {exception.key && (
            <Button
              variant="danger light"
              onClick={() => handleDeleteRuleException(exception.key)}
            >
              Deletar regra
            </Button>
          )}
          <Button variant="primary" type="submit">
            Enviar
          </Button>
        </ContentButton>
      </Container>
    </ModalDesigner>
  );
}

import styled from 'styled-components';

export const DataInfoUsersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${(props) => props.theme['gray-600']};
  padding: 1rem;

  div {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 1rem;

    span {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      text-align: center;
      color: ${(props) => props.theme.white};
      font-size: 1rem;
      strong {
      }
    }
  }
`;

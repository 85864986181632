import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* display: flex;
  flex-direction: column; */
  background: ${(props) => props.theme.white};
  height: 100%;
  width: 100%;
  background-size: cover;
  justify-content: center;

  overflow-y: auto;
  .footerStyle {
    clip-path: polygon(
      0 97%,
      86% 82%,
      100% 90%,
      100% 100%,
      70% 100%,
      30% 100%,
      0 100%,
      0 100%
    );
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgb(220, 0, 11);
    background: linear-gradient(
      90deg,
      rgba(220, 0, 11, 1) 0%,
      rgba(1, 118, 225, 1) 100%
    );
    z-index: 1;
  }

  .loginBox {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  @media (max-width: 580px) {
    grid-template-columns: 1fr;

    .loginBox {
      width: 100%;
      max-width: 20rem;
    }
  }

  @media (max-width: 480px) {
    justify-content: center;
    align-items: center;
  }
`;

export const RegisterAside = styled.aside`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  margin: 0 auto;

  img {
    width: 10rem;
  }

  .helpMessage {
    font-size: 1rem;
    margin-bottom: 6rem;
    color: ${(props) => props.theme['gray-700']};

    a {
      font-weight: 500;
      color: ${(props) => props.theme['gray-700']};

      svg {
        color: ${(props) => props.theme['gray-700']};
        width: 1.375rem;
        height: 1.375rem;
      }
    }
  }

  @media (max-width: 720px) {
    margin-left: 1rem;
  }

  @media (max-width: 580px) {
    display: none;
  }
`;

export const ContentAside = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 4rem;
  align-items: flex-start;
  position: relative;
  z-index: 10;

  img {
    width: 14.125rem;
  }

  p {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    margin: 0;
    font-weight: 500;
    font-size: 1.375rem;
    line-height: 150%;
    color: #404040;
    width: 100%;
    max-width: 29.687rem;

    strong {
      color: #505050;
      font-size: 1.75rem;
      font-weight: 600;
    }
  }
  @media (max-width: 768px) {
    margin-top: 4rem;
    img {
      width: 13rem;
    }
    p {
      font-size: 1.5rem;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentForm = styled.div`
  width: 100%;
  background: ${(props) => props.theme.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  max-width: 23.75rem;
  position: relative;
  box-shadow: 2px 4px 4px 0px rgba(48, 48, 48, 0.25);
  border-radius: 4px;
  gap: 1rem;
  z-index: 1;

  img {
    width: 9.187rem;
    height: auto;
  }

  header {
    font-family: 'Poppins', sans-serif;
    span {
      font-size: 0.875rem;
      color: #606060;
      line-height: 150%;
    }

    p {
      margin: 0;
      margin-top: 0.875rem;
    }
    p,
    a {
      color: ${(props) => props.theme['gray-700']};
    }
    a {
      color: ${(props) => props.theme.primary};
    }
    .drop {
      color: ${(props) => props.theme['gray-700']};
    }
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;

    a {
      color: ${(props) => props.theme['gray-700']};
      text-decoration: underline;
    }
  }

  .inputTransparent {
    background: transparent;
    border: 1px solid rgba(21, 21, 21, 0.3);
    border-radius: 4px;
    input {
      background: transparent !important;
      color: ${(props) => props.theme['gray-700']} !important;
    }
    .ant-input {
      background-color: transparent !important;
    }
  }

  .checkbox {
    align-items: center;
    input:checked {
      background-color: ${(props) => props.theme.primary} !important;
    }
  }

  transition: all 1s;

  @media (max-width: 768px) {
    margin-right: 1rem;
  }

  @media (max-width: 580px) {
    margin-right: 0;
  }

  @media (max-width: 480px) {
    /* margin: 0 1rem; */
    width: 100%;
    max-width: none;
    height: 100%;
    min-height: 100vh;
  }

  /* @media (max-width: 540px) {
    padding: 1.5rem;
    max-width: 21.875rem;

    header {
      font-family: 'Poppins', sans-serif;
      h1 {
        color: ${(props) => props.theme.white};
        font-size: 1.5rem;
        font-weight: 200;
        line-height: 2rem;
      }
    }
  } */
  /* 
 

  @media (max-width: 360px) {
    padding: 1rem;
    max-width: 17.5rem;

    /* max-width: 250px; 
    overflow-y: auto;

    header {
      font-family: 'Poppins', sans-serif;
      h1 {
        color: ${(props) => props.theme.white};
        font-size: 1rem;
        font-weight: 200;
        line-height: 1.5rem;
      }
    }
  } */
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

interface IPropsContentInput {
  suffix?: boolean;
}

export const ContentInput = styled.div<IPropsContentInput>`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  width: 100%;
  input {
    background: transparent;
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #909090;
    color: ${(props) => props.theme['gray-700']};
    padding-right: ${(props) => (props.suffix ? '3rem' : '0.5rem')};
    width: 100%;
    font-size: 0.875rem;
    /* :hover,
    :focus {
      box-shadow: 0 0 0 2px ${(props) => props.theme.primary};
      border: 2px solid transparent;
    } */
  }
  input:-webkit-autofill {
    /* -webkit-box-shadow: 0 0 0 50px #343434 inset; */
    -webkit-text-fill-color: ${(props) =>
      props.theme['gray-700']}; /* Cor do texto */
    background-color: white;

    :hover,
    :focus {
      border: 1px solid ${(props) => props.theme.primary};
      /* -webkit-box-shadow: 0 0 0 50px #343434 inset,
        0 0 0 2px ${(props) => props.theme.primary}; */
    }
  }

  i {
    position: absolute;
    right: 0.5rem;
    display: flex;
  }

  .useOfTerms {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    span {
      color: rgba(131, 131, 131, 1);
      font-size: 0.625rem;

      a {
        text-decoration: none;
        color: rgba(64, 140, 209, 1);
      }
    }
  }
`;

export const RowContentInput = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
`;

interface IPropsButton {
  background: string;
}

export const Button = styled.button<IPropsButton>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.25rem 0;
  background-color: #0078e8;
  color: ${(props) => props.color};
  border: none;
  outline: none;
  box-shadow: ${(props) => props.theme['card-shadow']};
  border-radius: 8px;
  width: 100%;
  transition: filter 0.3s;
  font-size: 1rem;
  line-height: 150%;
  font-weight: 500;

  :disabled {
    background: ${(props) => props.theme['primary-800']};
    filter: brightness(0.7);
    cursor: not-allowed;
  }

  :hover:not(:disabled) {
    filter: brightness(0.9);
  }

  div {
    display: flex;
  }
`;

export const BackButton = styled.button`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 1rem;
  left: 1rem;
  color: ${(props) => props.theme['gray-700']};
  background-color: ${(props) => props.theme.white};
  border-radius: 100%;
  box-shadow: 4px 4px 4px 0px rgba(76, 76, 76, 0.05);
  font-size: 1.5rem;
  padding: 0.625rem;
  z-index: 100;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;

  overflow-y: auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 750px;
  @media (max-width: 675px) {
    flex-direction: column-reverse;
  }
`;
export const Cards = styled.div`
  min-width: 470px;
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const ContentButton = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  align-items: center;

  span {
    font-weight: 400;
    font-size: 1rem;
    color: ${(props) => props.theme.white};

    a {
      text-decoration: underline;
      color: ${(props) => props.theme.white};
    }
  }
`;

export const ValidationPassword = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  flex-wrap: wrap;
`;

interface IPropsInfoValidation {
  error: boolean;
}
export const InfoValidationPassword = styled.li<IPropsInfoValidation>`
  list-style: inside;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  color: ${(props) => (!props.error ? props.theme.black : props.theme.success)};
  align-items: center;
  font-size: 0.75rem;
  align-items: center;
  text-decoration: ${(props) => props.error && 'line-through'};

  /* ::before {
    content: '*';
    display: flex;
    justify-content: center;
    align-items: center;
  } */

  span {
    width: 0.25rem;
    height: 0.25rem;
    background-color: ${(props) =>
      !props.error ? props.theme['gray-700'] : props.theme.success};
    border-radius: 999px;
  }

  /* svg {
    background: ${(props) =>
    !props.error ? props.theme.error : props.theme.success};
    border-radius: 100%;
    color: ${(props) => props.theme.white};
    font-size: 1.1rem;
    opacity: 0.8;
  } */
`;

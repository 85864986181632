import styled from 'styled-components';

export const ContainerAsideFilters = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10.20%;
  margin-left: 45%;
  max-width: 18.75rem;
  background: transparent;
  border-radius: 8px;
  padding: 1.5rem 1rem;
  gap: 1rem;
  position: absolute;
  z-index: 1;
`;

export const ContentInput = styled.div`
  display: flex;
  flex-direction: column;

  label {
    color: var(--Gray-gray-600, #6A717E);
    position: absolute;

    /* Body/XSmall/Regular */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    color: ${(props) => props.theme['gray-500']};
    margin-bottom: 1rem;
    width: 100%;
  }
  .labelAno{
    margin-top: 10%;
    margin-left: -2%;
  }
  .labelMes{
    margin-top: 11%;
    margin-left: 13%;
  }

  .selects {
    display: flex;
    align-items: center;
    gap: 32px;
    flex: 1 0 0;
    margin-top: 10%;

    width: 100%;
    .ano{
      display: flex;
      width: 428px;
      height: 56px;
      padding: 4px 4px 4px 12px;
      justify-content: space-between;
      align-items: center;
      flex: 1 0 0;
      border: none;
      border-bottom: 1px solid #6A717E;
      background: #FFF;
      margin-left: -5%;
      margin-top: -2.5%;
    }
    .mes{
      display: flex;
      width: 428px;
      height: 56px;
      padding: 4px 4px 4px 12px;
      justify-content: space-between;
      align-items: center;
      flex: 1 0 0;
      border: none;
      border-bottom: 1px solid #6A717E;
      background: var(--Main-white, #FFF);
      margin-top: -2.5%;
      margin-left: 5%;
    }
  }
`;

import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';
import { financeRoutes, walletRoutes } from '../../../../utils/RoutesApi';
import {
  IPropsActiveWallet,
  IPropsGeneralActiveWallet,
  IPropsUserWallet,
} from '../@types';

interface IPropsActivesProvider {
  children: React.ReactNode;
}

interface IPropsActivesContextWallet {
  fullWallet: IPropsUserWallet;
  setFullWallet: (wallet: IPropsUserWallet) => void;
  setSelectedBrokerage: (brokerage: string) => void;
  selectedBrokerage: string;
  updateWallet: () => void;
  totalInvestments: string;
  totalReserve: string;
  othersFii: any[];
  othersAction: any[];
  updateStrategyRule: () => void;
  typeWallet: boolean;
}

export const ActivesContextWallet = createContext<IPropsActivesContextWallet>({
  fullWallet: {} as IPropsUserWallet,
  setFullWallet: () => {},
  selectedBrokerage: '',
  setSelectedBrokerage: () => {},
  updateWallet: () => {},
  totalInvestments: 'R$ 0',
  totalReserve: 'R$ 0',
  othersFii: [],
  othersAction: [],
  updateStrategyRule: () => {},
  typeWallet: false,
});

export const ActivesProviderWallet = ({ children }: IPropsActivesProvider) => {
  //@ts-ignore
  const getStartObjetivo = localStorage.getItem('startObjetivo');
  const [fullWallet, setFullWallet] = useState<IPropsUserWallet>(
    {} as IPropsUserWallet
  );
  const [typeWallet, setTypeWallet] = useState(() => {
    if (getStartObjetivo) {
      return JSON.parse(getStartObjetivo);
    }
    return false;
  });
  const [selectedBrokerage, setSelectedBrokerage] = useState('');
  const [activesReserve, setActivesReserve] = useState<
    IPropsGeneralActiveWallet[]
  >([]);
  const [othersFii, setOthersFii] = useState([]);
  const [othersAction, setOthersAction] = useState([]);

  useEffect(() => {
    if (selectedBrokerage !== '') {
      getUserWallet({ corretora: selectedBrokerage });
    } else {
      getUserWallet();
    }
  }, [selectedBrokerage, typeWallet]);
  useEffect(() => {
    localStorage.setItem('startObjetivo', JSON.stringify(typeWallet));
  }, [typeWallet]);

  useEffect(() => {
    if (getStartObjetivo === null) {
      updateStrategyRule();
    }
  }, []);

  async function updateStrategyRule() {
    const editRule = !typeWallet;
    const data = {
      PorcentagemRecomendada: editRule,
    };
    await axios
      .patch(
        `${process.env.REACT_APP_API_URL}${walletRoutes.WALLET_DEFINED_RULE_STRATEGY}`,
        data
      )
      .then((response) => {
        setTypeWallet(response.data.PorcentagemRecomendada);
      })
      .catch((err) => {});
  }

  async function getUserWallet(params = {}) {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_POSITION}`,
        {
          params,
        }
      )
      .then(function (result) {
        setActivesReserve(result.data.positionsAll);
        let newDataArrayAction = result.data.positionAcao.map(
          (item: IPropsActiveWallet) => {
            const isExceptionAction = item.Exception.find(
              (excp: any) => excp.type === 'action'
            );
            if (!isExceptionAction) {
              item.ValorExp = result.data.objetivoAcao;
              return item;
            }
            return item;
          }
        );

        let newDataArrayFii = result.data.positionFII.map(
          (item: IPropsActiveWallet) => {
            if (!item.Exception) {
              item.ValorExp = result.data.objetivoFII;
              return item;
            }
            return item;
          }
        );

        newDataArrayAction = newDataArrayAction.filter(
          (item: IPropsActiveWallet) => !item.reservadeemergencia
        );
        newDataArrayFii = newDataArrayFii.filter(
          (item: IPropsActiveWallet) => !item.reservadeemergencia
        );

        let allActivesPosition = result.data.positionsAll.map((item: any) => {
          const isExceptionAll = item.Exception.find(
            (excp: any) => excp.type === 'all'
          );
          const custo =
            Number(item.PrecoMedio.toFixed(2)) * item.equitiesQuantity;
          item.lucro = Number(item.updateValue.toFixed(2)) - custo;
          if (typeWallet) {
            item.ValorExp = item.porcentagem_recomendada;
            item.gap = item.ValorExp - item.percent;

            return item;
          } else if (!isExceptionAll) {
            if (item.productTypeName === 'acao') {
              item.ValorExp = result.data.objetivoAll;
              item.gap = item.ValorExp - item.percent;

              return item;
            } else if (item.productTypeName === 'fii') {
              item.ValorExp = result.data.objetivoAll;
              item.gap = item.ValorExp - item.percent;

              return item;
            } else {
              item.ValorExp = result.data.objetivoAll;
              item.gap = item.ValorExp - item.percent;

              return item;
            }
          }
          item.ValorExp = isExceptionAll.value;
          item.gap = item.ValorExp - item.percent;
          return item;
        });

        allActivesPosition = allActivesPosition.filter(
          (item: any) => !item.reservadeemergencia
        );

        setFullWallet({
          ...result.data,
          positionFII: newDataArrayFii,
          positionAcao: newDataArrayAction,
          positionsAll: allActivesPosition,
        });

        if (result.data.positionFII.length > 0) {
          let fiis = result.data.positionFII.filter(
            (item: IPropsActiveWallet) => !item.reservadeemergencia
          );
          fiis = fiis.filter((item: IPropsActiveWallet) => item.percent > 0.0);

          fiis = fiis.filter((item: IPropsActiveWallet) => item.percent < 1);

          fiis = fiis.map((item: IPropsActiveWallet) => item.percent);

          let largerThanOne = result.data.positionFII.filter(
            (item: IPropsActiveWallet) => !item.reservadeemergencia
          );

          largerThanOne = largerThanOne.filter(
            (item: IPropsActiveWallet) => item.percent >= 1
          );
          if (fiis.length > 0) {
            //@ts-ignore
            fiis = fiis.reduce((a, b) => a + b);

            fiis = [
              ...largerThanOne,
              {
                percent: fiis,
                tickerSymbol: 'Outros',
                Exception: false,
                ValorExp: 0,
                aportarQtde: 0,
                closingPrice: 0,
                corporationName: '',
                equitiesQuantity: 0,
                novoupdateValue: 0,
                updateValue: 0,
              },
            ];

            setOthersFii(fiis);
          } else {
            setOthersFii(largerThanOne);
          }
        }
        if (result.data.positionAcao.length > 0) {
          let actions = result.data.positionAcao.filter(
            (item: IPropsActiveWallet) => !item.reservadeemergencia
          );

          actions = actions.filter(
            (item: IPropsActiveWallet) => item.percent > 0.0
          );

          actions = actions.filter(
            (item: IPropsActiveWallet) => item.percent < 1
          );

          actions = actions.map((item: IPropsActiveWallet) => item.percent);
          let largerThanOne = result.data.positionAcao.filter(
            (item: IPropsActiveWallet) => !item.reservadeemergencia
          );

          largerThanOne = largerThanOne.filter(
            (item: IPropsActiveWallet) => item.percent >= 1
          );
          if (actions.length > 0) {
            //@ts-ignore
            actions = actions.reduce((a, b) => a + b);

            actions = [
              ...largerThanOne,
              {
                percent: actions,
                tickerSymbol: 'Outros',
                Exception: false,
                ValorExp: 0,
                aportarQtde: 0,
                closingPrice: 0,
                corporationName: '',
                equitiesQuantity: 0,
                novoupdateValue: 0,
                updateValue: 0,
              },
            ];

            setOthersAction(actions);
          } else {
            setOthersAction(largerThanOne);
          }
        }
      });
  }

  function updateWallet() {
    getUserWallet();
  }

  const totalInvestments = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(
    fullWallet.positionsAll
      ? fullWallet.positionsAll.reduce(
          (acc: number, position: IPropsGeneralActiveWallet) => {
            return acc + position.updateValue;
          },
          0
        )
      : 0
  );
  const totalReserve = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(
    activesReserve
      ? activesReserve
          .filter((item) => item.reservadeemergencia)
          .reduce((acc: number, position: IPropsGeneralActiveWallet) => {
            return acc + position.updateValue;
          }, 0)
      : 0
  );

  return (
    <ActivesContextWallet.Provider
      value={{
        fullWallet,
        setSelectedBrokerage,
        selectedBrokerage,
        updateWallet,
        setFullWallet,
        totalInvestments,
        totalReserve,
        othersFii,
        othersAction,
        updateStrategyRule,
        typeWallet,
      }}
    >
      {children}
    </ActivesContextWallet.Provider>
  );
};

import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  font-family: 'Poppins', sans-serif;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

export const FormStyles = styled.form`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.white};
  padding: 2rem;
  width: 100%;
  border-radius: 4px;
  box-shadow: ${(props) => props.theme['card-shadow-2']};
  flex: 1;

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h4 {
      margin: 0;
    }
  }

  main {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    .onPeriod {
      position: relative;
      input {
        padding: 0.468rem 5.5rem 0.468rem 0.468rem;
      }

      select {
        padding: 0.468rem;
        position: absolute;
        right: 0;
        bottom: 0;
        border: none;
        border-left: ${(props) => props.theme.border};
        background-color: transparent;
        color: ${(props) => props.theme.black};
      }
    }

    .maskPercent {
      position: relative;
      ::before {
        content: '%';
        position: absolute;
        right: 8px;
        bottom: 5px;
        color: ${(props) => props.theme['text-body']};
        font-family: 'Poppins', sans-serif;

        font-size: 1rem;
      }
    }
    .onPeriod.maskPercent {
      ::before {
        right: 5.6rem;
      }
    }

    div {
      display: flex;
      flex-direction: column;

      label {
        color: #2b2a2a;
      }

      input {
        border: ${(props) => props.theme.border};
        border-radius: 4px;
        padding: 0.468rem;
      }

      input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      input[type='number'] {
        -moz-appearance: textfield;
        appearance: textfield;
      }
    }
    div + div {
      margin-top: 1rem;
    }
  }

  footer {
    margin-top: 1rem;
    width: 100%;
    button {
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    /* button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${(props) => props.theme.primary};
      color: ${(props) => props.theme.white};
      border: none;
      outline: none;
      padding: 5px 1rem;
      border-radius: 4px;

      transition: filter 0.3s;

      :hover {
        filter: brightness(0.9);
      }

      :disabled {
        filter: brightness(0.7);
      }
    } */
  }
`;

export const ContentGraphic = styled.div`
  background: ${(props) => props.theme.white};
  padding: 1rem;
  box-shadow: ${(props) => props.theme['card-shadow-2']};
  border-radius: 4px;

  p {
    margin-bottom: 0.5rem;
    color: ${(props) => props.theme['gray-700']};
  }
`;

import axios from 'axios';
import { useEffect, useState } from 'react';
import { financeRoutes } from '../../../../utils/RoutesApi';
import { IPropsIndexes } from './indexesTypes';
import { CardIndex, Container, ContentIndexesCol, RowContent } from './styles';
import { ModalDesigner } from '../../../ModalDesigner';

interface IPropsCompanyIndexes {
  slug: string[];
  isShow: boolean;
  setIsShow: Function;
}

const baseURL = process.env.REACT_APP_API_URL;

export function CompanyIndexes({
  slug,
  isShow,
  setIsShow,
}: IPropsCompanyIndexes) {
  //@ts-ignore
  const [indexes, setIndexes] = useState<IPropsIndexes[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setIndexesCompany();
  }, [slug]);

  async function setIndexesCompany() {
    setLoading(true);
    //@ts-ignore
    let indexesAction = [];
    for (var tck in slug) {
      await axios
        .get(`${baseURL}${financeRoutes.FINANCE_INDEXES}`, {
          params: {
            search: `${slug[tck].replace('.SA', '')}`,
          },
        })
        .then((response) => {
          const newData = {
            ...response.data[0],
            asset: slug[tck],
          };
          //@ts-ignore
          indexesAction = [...indexesAction, newData];
        })
        .catch((err) => {});
    }
    //@ts-ignore
    setIndexes(indexesAction);
    setLoading(false);
  }

  return (
    <Container>
      <ModalDesigner
        size="xl"
        isOpen={isShow}
        setState={setIsShow}
        title="Índices de que a empresa participa"
        id="indices"
      >
        <div
          className="contentIndexes"
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '1rem',
            overflowY: 'auto',
          }}
        >
          {loading ? (
            <></>
          ) : (
            indexes?.map((item) => (
              <ContentIndexesCol key={item.asset}>
                <h5>{item.asset}</h5>
                <RowContent>
                  {!item.IBOV &&
                    !item.IBRA &&
                    !item.IBRX &&
                    !item.ICON &&
                    !item.IDIV &&
                    !item.IEEX &&
                    !item.IGCT &&
                    !item.IMAT &&
                    !item.IMOB &&
                    !item.INDX &&
                    !item.ISEE &&
                    !item.ICO2 &&
                    !item.MLCX &&
                    !item.UTIL &&
                    !item.IFNC &&
                    !item.IVBX &&
                    !item.ITAG &&
                    !item.IGCX &&
                    !item.IGNM && (
                      <p>
                        Não encontramos os indecex que essa empresa participa
                      </p>
                    )}
                  {item.IBOV && (
                    <CardIndex>
                      <h5>
                        IBOV
                        <span className="divider" />
                        <span>Índice Bovespa (Ibovespa B3)</span>
                      </h5>
                      <div className="contentCard">
                        <div>
                          <span>Teorico: </span>
                          <strong>
                            {new Intl.NumberFormat('pt-BR', {
                              style: 'decimal',
                            }).format(item.QtdTeoricaIBOV)}
                          </strong>
                        </div>
                        <div>
                          <span>Participação: </span>
                          <strong>{item.PartIBOV.toFixed(2)}%</strong>
                        </div>
                      </div>
                    </CardIndex>
                  )}
                  {item.IBRA && (
                    <CardIndex>
                      <h5>
                        IBRA
                        <span className="divider" />
                        <span>Índice Brasil Amplo BM&FBOVESPA (IBrA B3)</span>
                      </h5>
                      <div className="contentCard">
                        <div>
                          <span>Teorico: </span>
                          <span className="divider" />

                          <strong>
                            {new Intl.NumberFormat('pt-BR', {
                              style: 'decimal',
                            }).format(item.QtdTeoricaIBRA)}
                          </strong>
                        </div>
                        <div>
                          <span>Participação: </span>
                          <strong>{item.PartIBRA.toFixed(2)}%</strong>
                        </div>
                      </div>
                    </CardIndex>
                  )}
                  {item.IBRX && (
                    <CardIndex>
                      <h5>
                        IBRX
                        <span className="divider" />
                        <span>Índice Brasil 100 (IBrX 100 B3)</span>
                      </h5>
                      <div className="contentCard">
                        <div>
                          <span>Teorico: </span>
                          <strong>
                            {new Intl.NumberFormat('pt-BR', {
                              style: 'decimal',
                            }).format(item.QtdTeoricaIBRX)}
                          </strong>
                        </div>
                        <div>
                          <span>Participação: </span>
                          <strong>{item.PartIBRX.toFixed(2)}%</strong>
                        </div>
                      </div>
                    </CardIndex>
                  )}
                  {item.ICON && (
                    <CardIndex>
                      <h5>
                        ICON
                        <span className="divider" />
                        <span>Índice de Consumo (ICON B3)</span>
                      </h5>
                      <div className="contentCard">
                        <div>
                          <span>Teorico: </span>
                          <strong>
                            {new Intl.NumberFormat('pt-BR', {
                              style: 'decimal',
                            }).format(item.QtdTeoricaICON)}
                          </strong>
                        </div>
                        <div>
                          <span>Participação: </span>
                          <strong>{item.PartICON.toFixed(2)}%</strong>
                        </div>
                      </div>
                    </CardIndex>
                  )}

                  {item.IDIV && (
                    <CardIndex>
                      <h5>
                        IDIV
                        <span className="divider" />
                        <span>Índice Dividendos BM&FBOVESPA (IDIV B3)</span>
                      </h5>
                      <div className="contentCard">
                        <div>
                          <span>Teorico: </span>
                          <strong>
                            {new Intl.NumberFormat('pt-BR', {
                              style: 'decimal',
                            }).format(item.QtdTeoricaIDIV)}
                          </strong>
                        </div>
                        <div>
                          <span>Participação: </span>
                          <strong>{item.PartIDIV.toFixed(2)}%</strong>
                        </div>
                      </div>
                    </CardIndex>
                  )}
                  {item.IEEX && (
                    <CardIndex>
                      <h5>
                        IEEX
                        <span className="divider" />
                        <span>Índice de Energia Elétrica (IEE B3)</span>
                      </h5>
                      <div className="contentCard">
                        <div>
                          <span>Teorico: </span>
                          <strong>
                            {new Intl.NumberFormat('pt-BR', {
                              style: 'decimal',
                            }).format(item.QtdTeoricaIEEX)}
                          </strong>
                        </div>
                        <div>
                          <span>Participação: </span>
                          <strong>{item.PartIEEX.toFixed(2)}%</strong>
                        </div>
                      </div>
                    </CardIndex>
                  )}

                  {item.IGCT && (
                    <CardIndex>
                      <h5>
                        IGCT
                        <span className="divider" />
                        <span>
                          Índice de Governança Corporativa Trade (IGCT B3)
                        </span>
                      </h5>
                      <div className="contentCard">
                        <div>
                          <span>Teorico: </span>
                          <strong>
                            {new Intl.NumberFormat('pt-BR', {
                              style: 'decimal',
                            }).format(item.QtdTeoricaIGCT)}
                          </strong>
                        </div>
                        <div>
                          <span>Participação: </span>
                          <strong>{item.PartIGCT.toFixed(2)}%</strong>
                        </div>
                      </div>
                    </CardIndex>
                  )}
                  {item.IMAT && (
                    <CardIndex>
                      <h5>
                        IMAT
                        <span className="divider" />
                        <span>
                          Índice de Materiais Básicos BM&FBOVESPA (IMAT B3)
                        </span>
                      </h5>
                      <div className="contentCard">
                        <div>
                          <span>Teorico: </span>
                          <strong>
                            {new Intl.NumberFormat('pt-BR', {
                              style: 'decimal',
                            }).format(item.QtdTeoricaIMAT)}
                          </strong>
                        </div>
                        <div>
                          <span>Participação: </span>
                          <strong>{item.PartIMAT.toFixed(2)}%</strong>
                        </div>
                      </div>
                    </CardIndex>
                  )}
                  {item.IMOB && (
                    <CardIndex>
                      <h5>
                        IMOB
                        <span className="divider" />
                        <span>Índice Imobiliário (IMOB B3)</span>
                      </h5>
                      <div className="contentCard">
                        <div>
                          <span>Teorico: </span>
                          <strong>
                            {new Intl.NumberFormat('pt-BR', {
                              style: 'decimal',
                            }).format(item.QtdTeoricaIMOB)}
                          </strong>
                        </div>
                        <div>
                          <span>Participação: </span>
                          <strong>{item.PartIMOB.toFixed(2)}%</strong>
                        </div>
                      </div>
                    </CardIndex>
                  )}
                  {item.INDX && (
                    <CardIndex>
                      <h5>
                        INDX
                        <span className="divider" />
                        <span>Índice do Setor Industrial (INDX B3)</span>
                      </h5>
                      <div className="contentCard">
                        <div>
                          <span>Teorico: </span>
                          <strong>
                            {new Intl.NumberFormat('pt-BR', {
                              style: 'decimal',
                            }).format(item.QtdTeoricaINDX)}
                          </strong>
                        </div>
                        <div>
                          <span>Participação: </span>
                          <strong>{item.PartINDX.toFixed(2)}%</strong>
                        </div>
                      </div>
                    </CardIndex>
                  )}

                  {item.ISEE && (
                    <CardIndex>
                      <h5>
                        ISEE
                        <span className="divider" />
                        <span>
                          Índice de Sustentabilidade Empresarial (ISE B3)
                        </span>
                      </h5>
                      <div className="contentCard">
                        <div>
                          <span>Teorico: </span>
                          <strong>
                            {new Intl.NumberFormat('pt-BR', {
                              style: 'decimal',
                            }).format(item.QtdTeoricaISEE)}
                          </strong>
                        </div>
                        <div>
                          <span>Participação: </span>
                          <strong>{item.PartISEE.toFixed(2)}%</strong>
                        </div>
                      </div>
                    </CardIndex>
                  )}
                  {item.SMLL && (
                    <CardIndex>
                      <h5>
                        SMLL
                        <span className="divider" />
                        <span>Índice Small Cap (SMLL B3)</span>
                      </h5>
                      <div className="contentCard">
                        <div>
                          <span>Teorico: </span>
                          <strong>
                            {new Intl.NumberFormat('pt-BR', {
                              style: 'decimal',
                            }).format(item.QtdTeoricaSMLL)}
                          </strong>
                        </div>
                        <div>
                          <span>Participação: </span>
                          <strong>{item.PartSMLL.toFixed(2)}%</strong>
                        </div>
                      </div>
                    </CardIndex>
                  )}
                  {item.ICO2 && (
                    <CardIndex>
                      <h5>
                        ICO2
                        <span className="divider" />
                        <span>Índice Carbono Eficiente (ICO2 B3)</span>
                      </h5>
                      <div className="contentCard">
                        <div>
                          <span>Teorico: </span>
                          <strong>
                            {new Intl.NumberFormat('pt-BR', {
                              style: 'decimal',
                            }).format(item.QtdTeoricaICO2)}
                          </strong>
                        </div>
                        <div>
                          <span>Participação: </span>
                          <strong>{item.PartICO2.toFixed(2)}%</strong>
                        </div>
                      </div>
                    </CardIndex>
                  )}
                  {item.MLCX && (
                    <CardIndex>
                      <h5>
                        MLCX
                        <span className="divider" />
                        <span>Índice MidLarge Cap (MLCX B3)</span>
                      </h5>
                      <div className="contentCard">
                        <div>
                          <span>Teorico: </span>
                          <strong>
                            {new Intl.NumberFormat('pt-BR', {
                              style: 'decimal',
                            }).format(item.QtdTeoricaMLCX)}
                          </strong>
                        </div>
                        <div>
                          <span>Participação: </span>
                          <strong>{item.PartMLCX.toFixed(2)}%</strong>
                        </div>
                      </div>
                    </CardIndex>
                  )}
                  {item.UTIL && (
                    <CardIndex>
                      <h5>
                        UTIL
                        <span className="divider" />
                        <span>Índice Utilidade Pública (UTIL B3)</span>
                      </h5>
                      <div className="contentCard">
                        <div>
                          <span>Teorico: </span>
                          <strong>
                            {new Intl.NumberFormat('pt-BR', {
                              style: 'decimal',
                            }).format(item.QtdTeoricaUTIL)}
                          </strong>
                        </div>
                        <div>
                          <span>Participação: </span>
                          <strong>{item.PartUTIL.toFixed(2)}%</strong>
                        </div>
                      </div>
                    </CardIndex>
                  )}
                  {item.IFNC && (
                    <CardIndex>
                      <h5>
                        IFNC
                        <span className="divider" />
                        <span>Índice Financeiro (IFNC B3)</span>
                      </h5>
                      <div className="contentCard">
                        <div>
                          <span>Teorico: </span>
                          <strong>
                            {new Intl.NumberFormat('pt-BR', {
                              style: 'decimal',
                            }).format(item.QtdTeoricaIFNC)}
                          </strong>
                        </div>
                        <div>
                          <span>Participação: </span>
                          <strong>{item.PartIFNC.toFixed(2)}%</strong>
                        </div>
                      </div>
                    </CardIndex>
                  )}
                  {item.IVBX && (
                    <CardIndex>
                      <h5>
                        IVBX
                        <span className="divider" />
                        <span>Índice Valor (IVBX B3)</span>
                      </h5>
                      <div className="contentCard">
                        <div>
                          <span>Teorico: </span>
                          <strong>
                            {new Intl.NumberFormat('pt-BR', {
                              style: 'decimal',
                            }).format(item.QtdTeoricaIVBX)}
                          </strong>
                        </div>
                        <div>
                          <span>Participação: </span>
                          <strong>{item.PartIVBX.toFixed(2)}%</strong>
                        </div>
                      </div>
                    </CardIndex>
                  )}
                  {item.ITAG && (
                    <CardIndex>
                      <h5>
                        ITAG
                        <span className="divider" />
                        <span>
                          Índice de Ações com Tag Along Diferenciado (ITAG B3)
                        </span>
                      </h5>
                      <div className="contentCard">
                        <div>
                          <span>Teorico: </span>
                          <strong>
                            {new Intl.NumberFormat('pt-BR', {
                              style: 'decimal',
                            }).format(item.QtdTeoricaITAG)}
                          </strong>
                        </div>
                        <div>
                          <span>Participação: </span>
                          <strong>{item.PartITAG.toFixed(2)}%</strong>
                        </div>
                      </div>
                    </CardIndex>
                  )}
                  {item.IGCX && (
                    <CardIndex>
                      <h5>
                        IGCX
                        <span className="divider" />
                        <span>
                          Índice de Ações com Governança Corporativa
                          Diferenciada (IGCX B3)
                        </span>
                      </h5>
                      <div className="contentCard">
                        <div>
                          <span>Teorico: </span>
                          <strong>
                            {new Intl.NumberFormat('pt-BR', {
                              style: 'decimal',
                            }).format(item.QtdTeoricaIGCX)}
                          </strong>
                        </div>
                        <div>
                          <span>Participação: </span>
                          <strong>{item.PartIGCX.toFixed(2)}%</strong>
                        </div>
                      </div>
                    </CardIndex>
                  )}
                  {item.IGNM && (
                    <CardIndex>
                      <h5>
                        IGNM
                        <span className="divider" />
                        <span>
                          Índice de Governança Corporativa Novo Mercado (IGNM
                          B3)
                        </span>
                      </h5>
                      <div className="contentCard">
                        <div>
                          <span>Teorico: </span>
                          <strong>
                            {new Intl.NumberFormat('pt-BR', {
                              style: 'decimal',
                            }).format(item.QtdTeoricaIGNM)}
                          </strong>
                        </div>
                        <div>
                          <span>Participação: </span>
                          <strong>{item.PartIGNM.toFixed(2)}%</strong>
                        </div>
                      </div>
                    </CardIndex>
                  )}
                </RowContent>
              </ContentIndexesCol>
            ))
          )}
        </div>
      </ModalDesigner>
    </Container>
  );
}

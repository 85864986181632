import axios from 'axios';
import { useEffect, useState } from 'react';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { useTitleAndUser } from '../../../../../hooks/userTitle';
import { financeRoutes } from '../../../../../utils/RoutesApi';
import { Col, Container, ContainerBrokerages, ContentTab, Row } from './styles';
import { CardCorretora } from '../../../CardCorretora';
import { GraphicWalletActives } from '../Graphic';
import { FormAporte } from '../FormAporte';
import { FormCreateRule } from '../FormCreateRule';
import {
  IPropsPosition,
  IWalletProps,
} from '../../../../../utils/@types/wallet';

import { ActionsTable } from '../components/Tables/ActionsTable';
import {
  ContentItemsFilterColumnTable,
  ContentTabCards,
} from '../MinhaCarteiraStyles';
import { TableLoader } from '../../../LoadingModels';
import { TabComponent } from '../../../../../components/TabComponent';
import { ChartLineUp, Scales } from '@phosphor-icons/react';
import { ButtonComponent } from '../../../../../components/ButtonComponent';
import { ModalDesigner } from '../../../../../components/ModalDesigner';

interface IPropsBrokerage {
  corretora: string;
  sumcorretora: number;
}

export function WalletAction() {
  const filterTable = localStorage.getItem('filterTableAction-v1.0.0');
  const filterTableJson: string[] = filterTable ? JSON.parse(filterTable) : [];
  //@ts-ignore
  const { setTitle, user } = useTitleAndUser();
  const [tab, setTab] = useState(1);
  const [filterBrokerage, setFilterBrokerage] = useState('');
  const [showBrokerages, setShowBrokerages] = useState(false);

  const [walletValues, setWalletValues] = useState<IWalletProps>({
    novoSumAcao: 0,
    novoSumFII: 0,
    positionAcao: [],
    positionFII: [],
    positionsAll: [],
    sumAcao: 0,
    sumsEFT: 0,
    sumsFII: 0,
    automatico: false,
    objetivoFII: 0,
    objetivoAcao: 0,
    rentabilidade: [],
    objetivoAll: 0,
    objetivoAllAcao: 0,
    objetivoAllFII: 0,
    objetivoAllRenda: 0,
  });
  const [loadingWallet, setLoadingWallet] = useState(false);

  const [showGraphic, setShowGraphic] = useState<boolean>(false);
  const [brokerages, setBrokerages] = useState<IPropsBrokerage[]>([]);
  const [showModalCreateRule, setShowModalCreateRule] = useState(false);
  const [rule, setRule] = useState({});
  const [othersAction, setOthersAction] = useState([]);
  const [showModalFilterColumnTable, setShowModalFilterColumnTable] =
    useState(false);
  const [filterShowColumnTable, setFilterShowColumnTable] = useState<string[]>(
    () => {
      if (filterTableJson.length <= 0) {
        return [
          'Ativo',
          'Tipo',
          'Nota',
          'Quant.',
          'Patrimônio',
          'Objetivo',
          'Atual',
          'Diferença',
        ];
      } else {
        return filterTableJson;
      }
    }
  );
  const arrColumnTableData = [
    'Ativo',
    'Cotação',
    'Nota',
    'Quant.',
    'Patrimônio',
    'Objetivo',
    'Atual',
    'Diferença',
    'Lucro/Prejuízo',
    'Rentabilidade',
    'Preço médio',
  ];

  useEffect(() => {
    setShowBrokerages(false);
    setShowGraphic(false);
  }, [tab]);

  useEffect(() => {
    if (filterBrokerage !== '') {
      componentDidMount({ corretora: filterBrokerage });
    } else {
      componentDidMount();
    }
  }, [user.automatico, filterBrokerage]);

  useEffect(() => {
    setTitle('Ações');
    handleGetBrokerages();
  }, [user.automatico]);

  useEffect(() => {
    localStorage.setItem(
      'filterTableAction-v1.0.0',
      JSON.stringify(filterShowColumnTable)
    );
  }, [filterShowColumnTable]);

  async function componentDidMount(params: {} = {}) {
    setLoadingWallet(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_POSITION}`,
        { params }
      )
      .then(function (result) {
        let newDataArrayAction = result.data.positionAcao.filter(
          (item: IPropsPosition) => !item.reservadeemergencia
        );

        newDataArrayAction = newDataArrayAction.map((item: IPropsPosition) => {
          const isExceptionExist = item.Exception.find(
            (item) => item.type === 'action'
          );
          const custo =
            Number(item.PrecoMedio.toFixed(2)) * item.equitiesQuantity;
          item.lucro = Number(item.updateValue.toFixed(2)) - custo;

          if (!isExceptionExist) {
            item.ValorExp = result.data.objetivoAcao;
            item.gap = item.ValorExp - item.percent;

            return item;
          }

          item.ValorExp = isExceptionExist.value;
          item.gap = item.ValorExp - item.percent;

          return item;
        });

        setWalletValues({
          ...result.data,

          positionAcao: newDataArrayAction,
        });

        if (result.data.positionAcao.length > 0) {
          let actions = result.data.positionAcao.filter(
            (item: IPropsPosition) => !item.reservadeemergencia
          );

          actions = actions.filter(
            (item: IPropsPosition) => item.percent > 0.0
          );

          actions = actions.filter((item: IPropsPosition) => item.percent < 1);

          actions = actions.map((item: IPropsPosition) => item.percent);
          let largerThanOne = result.data.positionAcao.filter(
            (item: IPropsPosition) => !item.reservadeemergencia
          );

          largerThanOne = largerThanOne.filter(
            (item: IPropsPosition) => item.percent >= 1
          );
          if (actions.length > 0) {
            //@ts-ignore
            actions = actions.reduce((a, b) => a + b);

            actions = [
              ...largerThanOne,
              {
                percent: actions,
                tickerSymbol: 'Outros',
                Exception: false,
                ValorExp: 0,
                aportarQtde: 0,
                closingPrice: 0,
                corporationName: '',
                equitiesQuantity: 0,
                novoupdateValue: 0,
                updateValue: 0,
              },
            ];

            setOthersAction(actions);
          } else {
            setOthersAction(largerThanOne);
          }
        }
      });
    setLoadingWallet(false);
  }
  function handleGetBrokerages() {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_BROKERAGES}`,
        {
          params: {
            tipo_ativo: 'acao',
          },
        }
      )
      .then(function (result) {
        let newResult = result.data.result.filter(
          (item: { corretora: string; sumcorretora: number }) =>
            item.corretora !== ''
        );
        newResult = newResult.filter(
          (item: { corretora: string; sumcorretora: number }) =>
            item.sumcorretora > 0
        );
        setBrokerages(newResult);
      })
      .catch((err) => {});
  }

  const totalValueBrokerages = brokerages.reduce(
    (acc, value) => acc + value.sumcorretora,
    0
  );

  function handleSelectedColumnTable(value: string) {
    const isValueAllReadyExist = filterShowColumnTable.find(
      (item: string) => item === value
    );
    if (isValueAllReadyExist) {
      setFilterShowColumnTable((state) => {
        return state.filter((item: string) => item !== value);
      });
    } else {
      setFilterShowColumnTable((state) => {
        return [...state, value];
      });
    }
  }

  return (
    <>
      <Container wallet={brokerages.length > 0}>
        <ContentTabCards>
          <TabComponent
            title="Ativos"
            icon={<ChartLineUp />}
            active={tab === 1}
            onClick={() => setTab(1)}
          />

          <TabComponent
            title="Simular aporte"
            icon={<Scales />}
            onClick={() => setTab(3)}
            active={tab === 3}
          />
        </ContentTabCards>

        <ContentTab tab={tab}>
          <ButtonComponent
            variant="link"
            onClick={() => setShowBrokerages(!showBrokerages)}
          >
            {showBrokerages ? 'Esconder corretoras' : 'Exibir corretoras'}
          </ButtonComponent>
          <ButtonComponent
            variant="link"
            onClick={() => setShowGraphic(!showGraphic)}
          >
            Visualizar gráficos
          </ButtonComponent>
        </ContentTab>
        {brokerages.length > 0 && (
          <ContainerBrokerages show={showBrokerages}>
            {brokerages.length > 1 && (
              <CardCorretora
                nameBroken="Todas as corretoras"
                patrimony={Number(totalValueBrokerages.toFixed(2))}
                onClick={() => setFilterBrokerage('')}
                active={filterBrokerage === ''}
              />
            )}
            {brokerages.map((item) => (
              <CardCorretora
                key={item.corretora}
                onClick={() =>
                  brokerages.length > 1 && setFilterBrokerage(item.corretora)
                }
                nameBroken={item.corretora}
                patrimony={item.sumcorretora}
                active={filterBrokerage === item.corretora}
              />
            ))}
          </ContainerBrokerages>
        )}

        {tab === 1 && (
          <Col>
            {loadingWallet ? (
              <TableLoader />
            ) : (
              <Row>
                <ActionsTable
                  setRule={setRule}
                  setShowModalCreateRule={setShowModalCreateRule}
                  setWalletValues={setWalletValues}
                  //@ts-ignore
                  walletValues={walletValues}
                  filterBrokerage={filterBrokerage}
                  filterShowColumnTable={filterShowColumnTable}
                  setShowModalFilterColumnTable={setShowModalFilterColumnTable}
                  filterTextSearchActive=""
                />
                {showGraphic && (
                  <GraphicWalletActives
                    active="action"
                    othersAction={othersAction}
                    title="Distribuição por ativo"
                  />
                )}
              </Row>
            )}
          </Col>
        )}

        {tab === 3 && (
          <FormAporte
            type="action"
            //@ts-ignore
            wallet={walletValues}
            showGraphic={showGraphic}
            othersAction={othersAction}
            filterBroker={filterBrokerage}
          />
        )}
      </Container>
      {showModalCreateRule && (
        <FormCreateRule
          //@ts-ignore
          rule={rule}
          setShowModalCreateRule={setShowModalCreateRule}
          showModalCreateRule={showModalCreateRule}
          update={() => componentDidMount()}
        />
      )}

      <ModalDesigner
        id="editarTabela"
        title="Editar campos da tabela"
        isOpen={showModalFilterColumnTable}
        setState={setShowModalFilterColumnTable}
        size="sm"
      >
        <ContentItemsFilterColumnTable>
          {arrColumnTableData.map((item) => (
            <label key={item}>
              <input
                type="checkbox"
                value={item}
                defaultChecked={filterShowColumnTable.includes(item)}
                onChange={(e) => handleSelectedColumnTable(e.target.value)}
              />
              {item}
            </label>
          ))}
        </ContentItemsFilterColumnTable>
      </ModalDesigner>
    </>
  );
}

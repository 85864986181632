import { useState } from 'react';
import { useTitleAndUser } from '../../hooks/userTitle';

import { IPropsComment } from '../../utils/@types/comment';
import { ChatCircle, ThumbsUp, Trash, User } from '@phosphor-icons/react';
import { Container, UserContent } from './styles';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { socialRoutes } from '../../utils/RoutesApi';
import { handleToast } from '../Toast';
import { ButtonComponent } from '../ButtonComponent';
import { ResponseComment } from './ResponseComment';
import moment from 'moment';

import { Markup } from 'interweave';

interface IComment {
  comment: IPropsComment;
  handleLikeComment: (id: string | number) => void;

  deleteComment: (id: string | number) => void;
}

interface IPropsResponseComment {
  count: number;
  next: string;
  previous: string;
  results: IPropsComment[];
}

export function Comment({
  comment,
  handleLikeComment,

  deleteComment,
}: IComment) {
  //@ts-ignore
  const { user, permissions } = useTitleAndUser();
  const { handleSubmit, register, setValue } = useForm();
  const history = useHistory();
  const [fullString, setFullString] = useState<boolean>(false);
  const [showResponse, setShowResponse] = useState<boolean>(false);
  const [responseComments, setResponseComments] =
    useState<IPropsResponseComment>({
      count: 0,
      next: '',
      previous: '',
      results: [],
    });
  const isAdmin = permissions.includes('Admin');

  async function handleResponseComment(data: any) {
    // setLoading(true);
    if (!data.body) {
      return handleToast(true, 'Você não pode enviar uma postagem em branco');
    }

    let searchHashTags = data.body.split(' ');
    searchHashTags = searchHashTags.filter((hashtag: string) =>
      hashtag.includes('#')
    );

    searchHashTags = searchHashTags.toString();

    if (searchHashTags) {
      data.hashtag = searchHashTags;
    } else {
      data.hashtag = '';
    }

    data.is_reply = true;
    data.parent_id = comment.id;

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}${socialRoutes.SOCIAL_CREATE_POST}`,
        data
      )
      .then((response) => {
        setResponseComments({
          ...responseComments,
          results: [response.data, ...responseComments.results],
        });
        setValue('body', '');
      })
      .catch((err) => {
        // setLoading(false);
        // handleToast(true, 'Erro ao publicar');
      });
    // setLoading(false);
  }

  async function handleDeleteComment(id: number | string) {
    await axios
      .delete(
        `${process.env.REACT_APP_API_URL}${socialRoutes.SOCIAL_CREATE_POST}${id}`
      )
      .then((response) => {
        handleToast(false, 'Publicação apagada');
        deleteComment(id);
      })
      .catch((err) => {
        // setLoading(false);
        // handleToast(true, 'Erro ao publicar');
      });
    // setLoading(false);
  }
  async function handleDeleteResponseCommentComment(id: number | string) {
    await axios
      .delete(
        `${process.env.REACT_APP_API_URL}${socialRoutes.SOCIAL_CREATE_POST}${id}`
      )
      .then((response) => {
        handleToast(false, 'Resposta apagada');
        let newResponseComments = responseComments.results.filter(
          (comment) => comment.id !== id
        );
        setResponseComments({
          ...responseComments,
          results: newResponseComments,
        });
      })
      .catch((err) => {
        // setLoading(false);
        // handleToast(true, 'Erro ao publicar');
      });
    // setLoading(false);
  }

  async function handleGetResponseComments() {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/social/api/posts/comments/${comment.id}/posts/`
      )
      .then((response) => {
        setResponseComments(response.data);
      })
      .catch((err) => {});
  }
  async function handleNextComment(link: string) {
    await axios
      .get(link)
      .then((response) => {
        setResponseComments({
          ...response.data,
          results: [...responseComments.results, ...response.data.results],
        });
      })
      .catch((err) => {});
  }

  function handleCreateComment() {
    let newState = showResponse;
    newState = !newState;
    setShowResponse(newState);
    if (newState) {
      handleGetResponseComments();
    }
  }
  function handleShowComments() {
    let newState = showResponse;
    newState = !newState;
    setShowResponse(newState);
    if (newState) {
      handleGetResponseComments();
    }
  }

  async function handleLikeResponseComment(id: string | number) {
    const comment = responseComments.results.find(
      (comment) => comment.id === id
    );
    if (comment?.liked.includes(user.id)) {
      await axios
        .delete(
          `${process.env.REACT_APP_API_URL}/social/api/posts/${id}/likes/`
        )
        .then((response) => {
          const liked = responseComments.results.findIndex(
            (comment) => comment.id === id
          );
          let newComments = responseComments.results;
          newComments[liked].liked = newComments[liked].liked.splice(
            user.id,
            1
          );
          setResponseComments({
            ...responseComments,
            results: newComments,
          });
        })
        .catch((err) => {});
      return;
    }
    await axios
      .post(`${process.env.REACT_APP_API_URL}/social/api/posts/${id}/likes/`)
      .then((response) => {
        const liked = responseComments.results.findIndex(
          (comment) => comment.id === id
        );
        let newComments = responseComments.results;
        newComments[liked].liked = [...newComments[liked].liked, user.id];
        setResponseComments({
          ...responseComments,
          results: newComments,
        });
      })
      .catch((err) => {});
  }

  return (
    <Container>
      <header>
        <UserContent
          onClick={() => history.push(`/user-posts/${comment.author.slug}`)}
        >
          <div className="contentAvatar">
            {comment.author.profile.image ? (
              <img src={comment.author.profile.image} />
            ) : (
              <User />
            )}
          </div>

          <div>
            <h5>
              {comment.author.Name.split(' ')[0]}{' '}
              {comment.author.Name.split(' ')[1]}
            </h5>
          </div>
        </UserContent>
        <div>
          <div className="infoContent">
            <span title={`${comment.liked.length} curtidas`}>
              {comment.liked.length}
              <ThumbsUp size={16} color="#5E666C" />
            </span>
            <span title={`${comment.reply_ids.length} comentários`}>
              {comment.reply_ids.length}
              <ChatCircle size={16} color="#5E666C" />
            </span>
            {comment.author.id === user.id || isAdmin ? (
              <Trash
                size={20}
                className="iconDelete"
                onClick={() => handleDeleteComment(comment.id)}
              />
            ) : (
              <></>
            )}
          </div>
          <span>{moment(comment.created_at).fromNow()}</span>
        </div>
      </header>

      <main>
        <p>
          {!fullString && comment.body.length > 600 ? (
            <>
              {
                <>
                  <Markup content={comment.body.substring(0, 600)} />
                  ... <br />
                  <span className="more" onClick={() => setFullString(true)}>
                    Ver mais
                  </span>
                </>
              }
            </>
          ) : (
            <Markup content={comment.body} />

            // commentBreakLine.map((item) => (
            //   <React.Fragment key={item}>
            //     {item} <br />
            //   </React.Fragment>
            // ))
          )}
        </p>

        <div className="buttonContent">
          <div>
            <ButtonComponent
              variant="link"
              onClick={() => {
                handleLikeComment(comment.id);
              }}
            >
              {comment.liked.includes(user.id) ? (
                <>
                  <ThumbsUp weight="fill" size={20} color="#EC6643" />{' '}
                  Curtiu
                </>
              ) : (
                <>
                  <ThumbsUp size={20} color="#EC6643" /> Curtir
                </>
              )}
            </ButtonComponent>
            <ButtonComponent
              variant="link"
              type="button"
              onClick={handleCreateComment}
            >
              <ChatCircle size={20} color="#EC6643" />
              Comentar
            </ButtonComponent>
          </div>
          <span onClick={handleShowComments}>
            {showResponse ? 'Ocultar comentários' : 'Ver comentários'}
          </span>
        </div>

        {showResponse && (
          <>
            <div className="dividerResponse" />

            <footer>
              <div className="contentAvatar">
                {user.profile.image ? (
                  <img src={user.profile.image} />
                ) : (
                  <User />
                )}
              </div>
              <form onSubmit={handleSubmit(handleResponseComment)}>
                <textarea
                  placeholder={`Responder ${comment.author.Name.split(' ')[0]}`}
                  {...register('body')}
                />

                <ButtonComponent type="submit">Responder</ButtonComponent>
              </form>
            </footer>

            <div className="responseCommentContainer">
              {responseComments.results.map((resComment) => (
                <ResponseComment
                  key={resComment.id}
                  responseComment={resComment}
                  onDeleteResponseComment={(id) =>
                    handleDeleteResponseCommentComment(id)
                  }
                  onLikeResponseComment={handleLikeResponseComment}
                />
              ))}
              {responseComments.next && (
                <footer>
                  <ButtonComponent
                    variant="link"
                    onClick={() => handleNextComment(responseComments.next)}
                  >
                    Ver mais
                  </ButtonComponent>
                </footer>
              )}
            </div>
          </>
        )}
        {/* {comment.responseComment.map((resComment) => (
          <div className="commentAnswers" key={resComment.id}>
            <div className="contentAvatar">
              {comment.author.profile.image ? (
                <img src={comment.author.profile.image} />
              ) : (
                <User />
              )}
            </div>

            <div className="contentResComment">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <h6>{resComment.author.Name}</h6>
                {resComment.author.Name === user.Name && (
                  <>
                    {' '}
                    <Trash
                      size={20}
                      className="iconDelete"
                      onClick={() => handleDeleteComment(resComment.id)}
                    />
                  </>
                )}
              </div>

              <p>{resComment.body}</p>
            </div>
          </div>
        ))} */}
      </main>
    </Container>
  );
}

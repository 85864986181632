import styled from 'styled-components';

export const UserAdminContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
`;

export const SearchContent = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 30rem;
  gap: 0.5rem;
  background: ${(props) => props.theme.white};
  padding: 1rem;
  border-radius: 4px;
  box-shadow: ${(props) => props.theme['card-shadow-2']};

  label {
    display: flex;
    flex-direction: column;
    margin: 0;
    color: ${(props) => props.theme['gray-700']};

    input {
      border-radius: 4px;
      border: ${(props) => props.theme.border};
      padding: 0.5rem;
      font-size: 1rem;
      margin-top: 0.5rem;
    }
  }
`;

export const ContentCardsDataUser = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12.5rem, 1fr));
  gap: 1rem;
  width: 100%;
  align-items: self-start;
`;

export const ContentDataUser = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
  background: ${(props) => props.theme.white};
  padding: 1rem;
  border-radius: 4px;
  box-shadow: ${(props) => props.theme['card-shadow-2']};

  h1 {
    margin: 0;
    font-size: 1.5rem;
    line-height: 160%;
    color: ${(props) => props.theme['gray-700']};
    font-weight: 700;
  }

  p {
    color: ${(props) => props.theme['gray-700']};
    font-size: 1rem;
    line-height: 130%;
    margin: 0;

    a {
      color: ${(props) => props.theme.primary};
      font-weight: 700;
      :hover {
        filter: brightness(0.9);
        transition: filter 0.3s;
      }
    }
  }

  .buttonContent {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .inputUserData {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    position: relative;
    gap: 0.25rem;
    border: 1px solid transparent;
    background: ${(props) => props.theme.white};
    width: 100%;
    padding: 1.125rem 1rem;
    box-shadow: ${(props) => props.theme['shadow-1']};
    border-color: ${(props) => props.theme['gray-200']};

    :focus {
      border-color: ${(props) => props.theme['primary-800']};
    }

    :disabled {
      cursor: not-allowed;
      background: ${(props) => props.theme['white-300']};
      button {
        cursor: not-allowed;
      }
    }
  }
`;

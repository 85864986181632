import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  .row {
    display: flex;
    gap: 1rem;
  }
`;

export const ContentFilters = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  p {
    cursor: pointer;
  }
  p + p {
    margin-left: 10px;
  }
  .selected {
    font-weight: bold;
    color: ${(props) => props.theme.primary};
  }
`;

export const ContentList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify: center;
  gap: 1.5rem;
  margin-top: 5.75%;
  width: 1616px;
  height: 100%;
  background-color: #fff;
  border-radius: var(--Spacing-8, 8px);
  border: 1px solid var(--Gray-gray-100, #d4d6db);
`;

export const RowDiv = styled.div`
  margin-left: 4%;
  margin-top: 7%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Spacing-32, 32px);
  align-self: stretch;

  @media (max-width: 1100px) {
    grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
  }
`;

export const LoadingContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const SelectedFilterContent = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme['gray-700']};
  h5,
  span {
    font-size: 0.875rem;
  }
`;

export const ContentFiltersAndSearch = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;

  .buttonFilter {
    all: unset;
    border-radius: 100%;
    box-shadow: 1px 1px 6px 0px #0000001a;
    background-color: white;
    padding: 0.75rem;
    width: 47;
    height: 47;
  }
`;

export const WarningNewMessage = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: start;
  background: #ecf0f3;
  border-radius: 11px;
  margin-left: 5%;
  padding: 0.5rem 0.75rem;
  margin-bottom: -3.5rem;
  margin-top: 3rem;
  margin-left: 7rem;

  p {
    margin: 0;
    font-size: 0.675rem;
    color: #606060;
    font-weight: 400;
    a {
      color: #606060;
      font-weight: 500;
      font-size: 0.75rem;
      text-decoration: underline;
    }
  }
`;

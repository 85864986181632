import { useEffect } from 'react';
import { CheckoutContainer } from './styles';
import { useTitleAndUser } from '../../../../hooks/userTitle';

export function Checkout() {
  //@ts-ignore
  const { setTitle } = useTitleAndUser();
  useEffect(() => {
    //@ts-ignore
    const elements = checkoutElements.init('inlineCheckout', {
      offer: '21qcodpk',
      email: 'teste@teste.com',
      name: 'teste',
    });
    elements.mount('#inline_checkout');
    var imported = document.createElement('script');
    imported.src = 'https://static.hotmart.com/checkout/widget.min.js';
    document.head.appendChild(imported);
    var link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = 'https://static.hotmart.com/css/hotmart-fb.min.css';
    document.head.appendChild(link);
    setTitle('Checkout');
  });

  return (
    <CheckoutContainer>
      <a
        // @ts-ignore
        onclick="return false;"
        href="https://pay.hotmart.com/R85734558A?email=teste@teste.com&name=teste"
        class="hotmart-fb hotmart__button-checkout"
      >
        Comprar Agora
      </a>
      <a
        href="https://pay.hotmart.com/R85734558A?email=teste@teste.com&name=teste"
        target="_blank"
      >
        teste
      </a>
      <div
        id="inline_checkout"
        style={{ height: '100vh', borderRadius: '16px' }}
      ></div>
    </CheckoutContainer>
  );
}

import styled from 'styled-components';

export const FooterContainer = styled.footer`
  width: 100vw;
  height: 100%;
  background: #191819;
  overflow: hidden;
  position: relative;
  padding: 4rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 480px) {
    padding-bottom: 2rem;
  }
`;

export const FooterContent = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  max-width: 66.25rem;
  justify-content: space-between;
  margin: 0 auto;
  gap: 1rem;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const FooterCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  max-width: 35rem;

  img {
    margin-bottom: 2.25rem;
    width: 80%;
  }

  h2 {
    color: ${(props) => props.theme.white};
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 150%;
    margin-bottom: 1.5rem;
  }
  p {
    color: ${(props) => props.theme.white};
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 166%;
  }
  @media (max-width: 480px) {
    align-items: center;
    text-align: center;

    h2 {
      font-size: 1.2rem;
    }
  }
`;

export const FunctionalitiesFooter = styled.ul`
  display: flex;
  flex-direction: column;

  color: ${(props) => props.theme.white};
  gap: 1rem;

  li {
    font-size: 1rem;
    text-align: end;
    a {
      display: flex;
      align-items: center;
      color: ${(props) => props.theme.white};
      font-size: 1rem;
      font-weight: 400;
      gap: 0.5rem;
      justify-content: flex-end;
    }
  }
  @media (max-width: 480px) {
    align-items: center;
    margin-bottom: 1rem;
  }
`;

export const SocialMediaFooter = styled.div`
  display: flex;
  flex-direction: column;

  h6 {
    color: ${(props) => props.theme.white};
  }

  div {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    color: ${(props) => props.theme.white};
  }
`;

export const Direitos = styled.span`
  color: ${(props) => props.theme.white};
  font-size: 0.875rem;
  font-weight: 400;

  @media (max-width: 480px) {
    font-size: 0.75rem;
    margin-top: 1rem;
  }
`;

import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Popover, Tooltip } from 'antd';
import validator from 'validator';
import ReactLoading from 'react-loading';
import ReactInputMask from 'react-input-mask';
import { ArrowLeft, Eye, EyeClosed, Info } from '@phosphor-icons/react';
import { useForm } from 'react-hook-form';

import {
  BackButton,
  Button,
  Container,
  ContainerInput,
  Content,
  ContentAside,
  ContentButton,
  ContentForm,
  ContentInput,
  InfoValidationPassword,
  RegisterAside,
  ValidationPassword,
} from './styles';
import { handleToast } from '../../../../../components/Toast';
import {
  loadingToggleAction,
  loginAction,
} from '../../../../../store/actions/AuthActions';
import axios from 'axios';
import { Heading } from '../../../../../components/DefaultStylesTypography';
import { CubeLoading } from '../../../../components/Finance/CubeLoading';
import { whatsAppLinkApi } from '../../../../../utils/apiWhatsapp';
import { logoBlackImg, logoLeftImg } from '../../../../../Constants/images';

const baseURL = process.env.REACT_APP_API_URL;

interface IPropsScreen {
  onShowRegisterScreen: () => void;
}

export default function Register({ onShowRegisterScreen }: IPropsScreen) {
  const { handleSubmit, register, watch } = useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [typeLoading, setTypeLoading] = useState('Register');
  const [viewPassword, setViewPassword] = useState(false);
  const [viewPasswordConfirm, setViewPasswordConfirm] = useState(false);

  const dispatch = useDispatch();

  const watchPasswords = watch('password');
  const watchEmail = watch('email');
  const watchName = watch('Name');
  const watchPasswordConfirms = watch('passwordConfirm');
  const watchCPF = watch('cpf');
  const watchCel = watch('cel');
  const validateLettersInPassword = !!watchPasswords?.match(/[A-z]/);
  const validateNumberInPassword = !!watchPasswords?.match(/[0-9]/);
  const validateSpecialCharactersInPassword = !!watchPasswords?.match(
    /[!|@|#|$|%|^|&|*|(|)|-|_]/
  );

  const isValidCPF = watchCPF && watchCPF.length === 14 ? false : true;
  const isValidEmail = watchEmail && watchEmail.length > 5 ? false : true;
  const isValidName = watchName && watchName.length > 3 ? false : true;
  const isValidCel =
    watchCel && watchCel.replace('_', '').length === 15 ? false : true;
  const isValidPassword =
    watchPasswordConfirms &&
    watchPasswords &&
    watchPasswordConfirms === watchPasswords
      ? false
      : true;

  const allFormRegister: boolean =
    !isValidCPF &&
    !isValidEmail &&
    !isValidName &&
    !isValidCel &&
    !isValidPassword
      ? false
      : true;

  const params = location.search.length < 1 ? null : location.search;

  const isDisabledButtonOrLoading =
    allFormRegister && !loading
      ? true
      : !allFormRegister && !loading
      ? false
      : true;

  const paramsTraffic = window.location.search
    .substr(1)
    .split('&')
    .reduce((queryParams, param) => {
      const [key, value] = param.split('=');
      //@ts-ignore
      queryParams[key] = value;
      return queryParams;
    }, {});

  //@ts-ignore
  function handleValidate(_data) {
    const emailValid = validator.isEmail(_data.email);
    if (!_data.Name) {
      return handleToast(true, 'Informe o nome completo');
    }

    if (!_data.cpf) {
      return handleToast(true, 'Informe o cpf');
    }
    if (!_data.cel) {
      return handleToast(true, 'Informe o celular');
    }
    if (emailValid === false) {
      return handleToast(true, 'Informe um email valido');
    }
    if (!_data.password) {
      return handleToast(true, 'Informe uma senha');
    }
    if (_data.password.length < 8) {
      return handleToast(true, 'a senha deve conter pelo menos 8 caracteres');
    }
    if (!_data.passwordConfirm) {
      return handleToast(true, 'Confirme a senha');
    }
    if (_data.passwordConfirm !== _data.password) {
      return handleToast(true, 'As senhas são diferentes');
    }
    if (!_data.termsofuse) {
      return handleToast(
        true,
        'Você precisa aceitar os termos de uso da plataforma'
      );
    }
  }
  //@ts-ignore
  async function onSignUp(data) {
    setLoading(true);
    setTypeLoading('Register');
    data.email = data.email.replace(/ /g, '');

    data.termsofuse = true;

    if (handleValidate(data)) {
      setLoading(false);
      return;
    }

    data.username = data.email;
    data.cel = data.cel.replace(/\D/g, '');
    data.ddd = data.cel.toString().substring(0, 2);
    data.telefone = data.cel.toString().substring(2);
    data.cpf = data.cpf.toString();
    data.email = data.email.toLowerCase();
    data.step = 1;
    //@ts-ignore
    if (paramsTraffic.utm_campaign) {
      //@ts-ignore
      data.utm_campaign = paramsTraffic.utm_campaign;
    } else {
      data.utm_campaign = '';
    }
    //@ts-ignore
    if (paramsTraffic.utm_medium) {
      //@ts-ignore
      data.utm_medium = paramsTraffic.utm_medium;
    } else {
      data.utm_medium = '';
    }
    //@ts-ignore
    if (paramsTraffic.utm_source) {
      //@ts-ignore
      data.utm_source = paramsTraffic.utm_source;
    } else {
      data.utm_source = '';
    }

    await axios
      .post(`${baseURL}/api/auth/register/`, data)
      .then(() => {
        setTypeLoading('Login');
        handleToast(false, 'Criado com sucesso');

        dispatch(loadingToggleAction(true));
        dispatch(
          // @ts-ignore
          loginAction({
            email: data.email,
            password: data.password,
            history: history,
            setLoading: true,
            registerBusiness: true,
            //@ts-ignore
            params: params,
          })
        );
        return;
      })
      .catch((err) => {
        setLoading(false);
        return handleToast(true, err.response.data.message);
      });
  }

  return (
    <Container>
      <BackButton
        title="Fechar tela de cadastro"
        onClick={onShowRegisterScreen}
      >
        <ArrowLeft weight="bold" />
      </BackButton>
      <div className="footerStyle" />

      <img src={logoLeftImg} alt="Logo Ibox" className="loginBox" />
      {loading ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            background: '#FFF',
          }}
        >
          <Heading as="h1" style={{ fontSize: '2rem' }}>
            {typeLoading === 'Register'
              ? 'Aguarde um momento. O seu Box já está sendo criado…'
              : 'Sua conta foi criada com sucesso estamos te conectando...'}
          </Heading>
          <br />
          <br />
          <br />
          <br />

          <CubeLoading />
        </div>
      ) : (
        <>
          <RegisterAside>
            <ContentAside>
              <img src={logoBlackImg} alt="logo" />
              <p>
                <strong> Agora investir ficou fácil demais!</strong>O iBox6 foi
                idealizado pensando em tudo o que você precisa para cuidar dos
                seus investimentos de uma forma fácil e segura, mesmo que ainda
                não saiba nada sobre o assunto.
              </p>
            </ContentAside>
            <span className="helpMessage">
              Precisa de ajuda?{' '}
              <a href={whatsAppLinkApi} target="_blank">
                Clique aqui!
              </a>
            </span>
          </RegisterAside>
          <Content>
            <ContentForm>
              <img src={logoBlackImg} alt="logo" />
              <header>
                <span>
                  Preencha o formulário e faça parte da nossa comunidade de
                  investidores!
                </span>
              </header>

              <form onSubmit={handleSubmit(onSignUp)} autoComplete="off">
                <ContainerInput>
                  <ContentInput>
                    <input
                      type="text"
                      placeholder="Nome"
                      {...register('Name')}
                    />
                  </ContentInput>

                  <ContentInput>
                    <input
                      type="text"
                      placeholder="Email ex: exemplo@exemplo.com"
                      {...register('email')}
                    />
                  </ContentInput>
                  <Popover
                    content={
                      <ValidationPassword>
                        <InfoValidationPassword
                          error={watchPasswords?.length >= 8}
                        >
                          <span />
                          Deve ter no mínimo 8 caracteres{' '}
                        </InfoValidationPassword>
                        <InfoValidationPassword
                          error={validateNumberInPassword}
                        >
                          <span />
                          Deve ter no mínimo 1 número
                        </InfoValidationPassword>
                        <InfoValidationPassword
                          error={validateLettersInPassword}
                        >
                          <span />
                          Deve ter no mínimo 1 letra
                        </InfoValidationPassword>

                        <InfoValidationPassword
                          error={validateSpecialCharactersInPassword}
                        >
                          <span />
                          Deve ter no mínimo 1 símbolo ex: @#$!{' '}
                        </InfoValidationPassword>
                      </ValidationPassword>
                    }
                    title="Crie uma senha segura"
                    trigger="focus"
                  >
                    <ContentInput suffix>
                      <i>
                        {viewPassword ? (
                          <Eye
                            onClick={() => setViewPassword(!viewPassword)}
                            size={20}
                            style={{ cursor: 'pointer' }}
                          />
                        ) : (
                          <EyeClosed
                            onClick={() => setViewPassword(!viewPassword)}
                            size={20}
                            style={{ cursor: 'pointer' }}
                          />
                        )}
                      </i>
                      <input
                        type={viewPassword ? 'text' : 'password'}
                        placeholder="Crie uma senha"
                        {...register('password')}
                      />
                    </ContentInput>
                  </Popover>

                  <ContentInput suffix>
                    <i>
                      {viewPasswordConfirm ? (
                        <Eye
                          onClick={() =>
                            setViewPasswordConfirm(!viewPasswordConfirm)
                          }
                          size={20}
                          style={{ cursor: 'pointer' }}
                        />
                      ) : (
                        <EyeClosed
                          onClick={() =>
                            setViewPasswordConfirm(!viewPasswordConfirm)
                          }
                          size={20}
                          style={{ cursor: 'pointer' }}
                        />
                      )}
                    </i>
                    <input
                      type={viewPasswordConfirm ? 'text' : 'password'}
                      placeholder="Confirme sua senha"
                      {...register('passwordConfirm')}
                    />
                  </ContentInput>

                  <ContentInput suffix>
                    <ReactInputMask
                      type="text"
                      mask="999.999.999-99"
                      placeholder="CPF ex: 999.999.999-99"
                      {...register('cpf')}
                    />
                    <Tooltip
                      placement="top"
                      title={
                        <strong>
                          O cpf cadastrado nessa conta deverá ser o mesmo
                          cadastrado em sua conta da b3
                        </strong>
                      }
                    >
                      <i>
                        <Info size={20} color="#fff" />
                      </i>
                    </Tooltip>
                  </ContentInput>
                  <ContentInput>
                    <ReactInputMask
                      type="text"
                      mask="(99) 99999-9999"
                      placeholder="Celular ex: (99) 99999-9999"
                      {...register('cel')}
                    />
                  </ContentInput>

                  <ContentInput>
                    <div className="useOfTerms">
                      <span>
                        Ao criar sua conta você concorda com os{' '}
                        <a
                          href="https://ibox6.com.br/politica-de-privacidade/"
                          target="_blank"
                        >
                          termos de uso
                        </a>{' '}
                        do iBox6.
                      </span>
                    </div>
                  </ContentInput>
                </ContainerInput>

                <ContentButton>
                  {loading ? (
                    <Button
                      disabled={loading}
                      background="var(--primary)"
                      color="#fff"
                      type="submit"
                    >
                      <ReactLoading
                        type="balls"
                        color="#FFF"
                        width="25px"
                        height="25px"
                      />
                    </Button>
                  ) : (
                    <Button
                      disabled={isDisabledButtonOrLoading}
                      background="var(--primary)"
                      color="#fff"
                      type="submit"
                    >
                      Cadastrar
                    </Button>
                  )}
                </ContentButton>
              </form>
            </ContentForm>
          </Content>
        </>
      )}
    </Container>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme['card-shadow-2']};
  border-radius: 4px;
  padding: 1rem;
  gap: 0.5rem;

  header {
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      flex-direction: column;
      color: ${(props) => props.theme['gray-700']};
      span,
      strong {
        font-size: 1rem;
      }
      strong {
        font-weight: 700;
      }
    }
  }
  .warning {
    display: flex;
    flex-direction: column;

    header {
      display: flex;
      flex-direction: column;

      h1 {
        font-size: 1.5rem;
        font-weight: bold;
        line-height: 160%;
        color: ${(props) => props.theme['gray-700']};
        margin: 0;
      }
      span {
        color: ${(props) => props.theme['gray-700']};
        font-size: 1rem;
      }
    }
    p {
      color: ${(props) => props.theme['gray-700']};
      font-size: 1rem;
      margin: 0;
      margin-top: 1rem;
      line-height: 130%;

      a {
        color: ${(props) => props.theme.primary};
        font-weight: 500;
        :hover {
          filter: brightness(0.9);
          transition: filter 0.3s;
        }
      }
    }
  }
`;

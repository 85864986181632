import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Input } from '../../Input';
import { useActives } from '../../../hooks/useActives';
import { AuthContext } from '../../../context/UserContext';
import { financeRoutes } from '../../../utils/RoutesApi';
import { handleToast } from '../../Toast';
import Select from 'react-select';
import { Container } from './styles';
import { useActivesWallet } from '../../../jsx/pages/Wallet/hooks/ActivesHooks';

export function SimpleActive(props) {
  const { updateWallet } = useActivesWallet();
  var { setCancel, edit, update } = props;
  const { setPercentage, setActives } = useActives();
  const { register, handleSubmit, setValue } = useForm();
  const { user } = useContext(AuthContext);
  const [activeSelect, setActiveSelect] = useState('');
  const [selectValues, setSelectValues] = useState([]);
  const [typeActive, setTypeActive] = useState('');
  const [amountOrPrice, setAmountOrPrice] = useState();
  const [values, setValues] = useState([]);

  useEffect(() => {
    if (edit) {
      setValue('participantName', edit.participantName);
      setValue('tickerSymbol', edit.tickerSymbol);
      setActiveSelect(edit.tickerSymbol);
      setAmountOrPrice(edit.equitiesQuantity);
      setValue('productTypeName', edit.productTypeName);
      handleGetListActive(edit.productTypeName);
      setValue('agregarAutomatico', edit.agregarAutomatico);
    }
  }, [edit]);

  async function handleGetListActive(Active) {
    setTypeActive(Active);
    if (Active === 'acao') {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_ALL_ACTIVES}`,
          {
            params: {
              search: 'ACAO',
            },
          }
        )
        .then((response) => {
          let actions = response.data;

          actions = actions.map((action) => {
            let newObjSelect = {
              value: action.Asset,
              label: `${action.Asset} - ${action.NomeAtivo}`,
            };
            return newObjSelect;
          });
          setValues(response.data);
          setSelectValues(actions);
        })
        .catch((error) => {});
    } else if (Active === 'FII') {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_ALL_ACTIVES}`,
          {
            params: {
              search: 'FII',
            },
          }
        )
        .then((response) => {
          let actions = response.data;
          actions = actions.map((action) => {
            let newObjSelect = {
              value: action.Asset,
              label: `${action.Asset} - ${action.NomeAtivo}`,
            };
            return newObjSelect;
          });
          setSelectValues(actions);
          setValues(response.data);
        })
        .catch((error) => {});
    }
    if (Active === 'rendaFixa') {
      setSelectValues([]);
    }
  }

  const returnSelectValue = (id, arr) => {
    if (id) {
      let obj = arr?.map((item) => {
        if (item?.value === id) {
          return {
            value: item.value,
            label: item.label,
          };
        }
      });
      return obj;
    } else {
      return null;
    }
  };

  function handleValidate(_data) {
    if (!_data.participantName) {
      return handleToast(true, 'O campo instituição é obrigatório');
    }
    if (!_data.tickerSymbol) {
      return handleToast(true, 'O campo ativo é obrigatório');
    }
    if (!amountOrPrice) {
      if (typeActive === 'rendaFixa') {
        return handleToast(true, 'O campo valor é obrigatório');
      } else {
        return handleToast(true, 'O campo quantidade é obrigatório');
      }
    }
    if (!_data.productTypeName) {
      return handleToast(true, 'O campo tipo de ativo é obrigatório');
    } else if (
      _data.productTypeName === 'acao' ||
      _data.productTypeName === 'FII'
    ) {
      if (!activeSelect) {
        return handleToast(true, 'O campo ativo é obrigatório');
      }
    }
  }

  async function submit(data) {
    if (activeSelect) {
      data.tickerSymbol = activeSelect;

      let nameActive = values.find((item) => item.Asset === activeSelect);
      if (nameActive) {
        data.corporationName = nameActive.NomeAtivo;
      }
    }
    if (handleValidate(data)) {
      return;
    }
    data.equitiesQuantity = amountOrPrice;
    if (typeActive === 'rendaFixa') {
      data.equitiesQuantity = data.equitiesQuantity.replace(/\D/g, '');
      data.equitiesQuantity = data.equitiesQuantity.replace(
        /(\d)(\d{2})$/,
        '$1.$2'
      );
    }

    data.tickerSymbol = data.tickerSymbol.toUpperCase();
    data.key = `${user.username}-${data.tickerSymbol
      .replace('%', '')
      .replace('/', '')}-${data.participantName.replace(/\s+/g, '_')}`;
    data.participantName;
    data.active = true;
    data.username = user.username;

    if (edit) {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/Finance/carteira/update/${edit.key}/`,
          data
        )
        .then(async (response) => {
          handleToast(false, 'Ativo atualizado com sucesso');
          update();
          await axios
            .get(
              `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_POSITION}`
            )
            .then(function (result) {
              setActives(result.data);
              let act = result.data.sumAcao.toFixed(2);
              let fii = result.data.sumsFII.toFixed(2);
              let rf = result.data.sumsEFT.toFixed(2);
              const total = Number(act) + Number(fii) + Number(rf);

              let actionPercentage =
                result.data.sumAcao === 0
                  ? 0
                  : Number((result.data.sumAcao / total) * 100);
              actionPercentage = Number(actionPercentage.toFixed(2));

              let fiiPercentage =
                result.data.sumsFII === 0
                  ? 0
                  : (result.data.sumsFII / total) * 100;

              fiiPercentage = Number(fiiPercentage.toFixed(2));
              let rfPercentage =
                result.data.sumsEFT === 0
                  ? 0
                  : (result.data.sumsEFT / total) * 100;

              rfPercentage = Number(rfPercentage.toFixed(2));

              let newPercentage = {
                fii: fiiPercentage !== NaN ? fiiPercentage : 0,
                action: actionPercentage !== NaN ? actionPercentage : 0,
                rendaFixa: rfPercentage !== NaN ? rfPercentage : 0,
              };
              setPercentage(newPercentage);
            })
            .catch(function (error) {});
          updateWallet();
          return;
        })
        .catch((error) => {
          handleToast(true, 'Erro ao cadastrar');
          return;
        });
    } else {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/Finance/carteira/create/`, data)
        .then((response) => {
          handleToast(false, 'Ativo cadastrado com sucesso');
          update();
          axios
            .get(
              `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_POSITION}`
            )
            .then(function (result) {
              setActives(result.data);
              let act = result.data.sumAcao.toFixed(2);
              let fii = result.data.sumsFII.toFixed(2);
              let rf = result.data.sumsEFT.toFixed(2);
              const total = Number(act) + Number(fii) + Number(rf);

              let actionPercentage =
                result.data.sumAcao === 0
                  ? 0
                  : Number((result.data.sumAcao / total) * 100);
              actionPercentage = Number(actionPercentage.toFixed(2));

              let fiiPercentage =
                result.data.sumsFII === 0
                  ? 0
                  : (result.data.sumsFII / total) * 100;

              fiiPercentage = Number(fiiPercentage.toFixed(2));
              let rfPercentage =
                result.data.sumsEFT === 0
                  ? 0
                  : (result.data.sumsEFT / total) * 100;

              rfPercentage = Number(rfPercentage.toFixed(2));

              let newPercentage = {
                fii: fiiPercentage !== NaN ? fiiPercentage : 0,
                action: actionPercentage !== NaN ? actionPercentage : 0,
                rendaFixa: rfPercentage !== NaN ? rfPercentage : 0,
              };
              setPercentage(newPercentage);
              updateWallet();
            })
            .catch(function (error) {});
          return;
        })
        .catch((error) => {
          handleToast(true, 'Erro ao cadastrar');
          return;
        });
    }
  }
  return (
    <Container>
      <form onSubmit={handleSubmit(submit)}>
        <div className="form-group mb-3">
          <label className="mb-1 ">
            <strong>Instituição</strong>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="ex: Corretora Rico"
            {...register('participantName')}
          />
        </div>
        <div className="form-group mb-3">
          <label className="mb-1 ">
            <strong>Tipo de ativo</strong>
          </label>
          <select
            className="form-control"
            {...register('productTypeName')}
            defaultValue=""
            onChange={(e) => handleGetListActive(e.target.value)}
          >
            <option value="" disabled>
              Selecione o tipo de ativo
            </option>
            <option value="acao">Ação</option>
            <option value="FII">FIIs</option>
            <option value="rendaFixa">Renda Fixa</option>
          </select>
        </div>
        <div className="form-group mb-3">
          <label className="mb-1 ">
            <strong>Ativo</strong>
          </label>
          {selectValues.length >= 1 && typeActive !== 'rendaFixa' ? (
            <Select
              className="select"
              options={selectValues}
              onChange={(e) => setActiveSelect(e.value)}
              value={returnSelectValue(activeSelect, selectValues)}
              placeholder="Selecione o tipo de filtro"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? '#0176E1' : 'transparent',
                  borderRadius: 8,
                }),
                container: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: ' 2px 2px 6px 0px #0000001A',
                  borderRadius: 8,
                }),
                input: (baseStyles) => ({
                  ...baseStyles,
                  color: '#808080',
                  fontSize: 16,
                }),
                placeholder: (base) => ({
                  ...base,
                }),
                valueContainer: (base) => ({
                  ...base,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 8,
                  paddingBottom: 8,
                }),
                indicatorSeparator: () => ({
                  display: 'none',
                }),
    
                dropdownIndicator: (base) => ({
                  ...base,
                  color: '#808080',
                }),
              }}
            />
          ) : (
            <input
              type="text"
              className="form-control"
              placeholder="ex: WEGE3"
              {...register('tickerSymbol')}
            />
          )}
        </div>

        <div className="form-group mb-3">
          <label className="mb-1 ">
            <strong>
              {typeActive === 'rendaFixa' ? 'Valor investido' : 'Quantidade'}
            </strong>
          </label>
          {typeActive === 'rendaFixa' ? (
            <Input
              mask="currency"
              className="form-control"
              placeholder="ex: 10"
              onChange={(e) => setAmountOrPrice(e.target.value)}
              value={amountOrPrice}
            />
          ) : (
            <input
              type="number"
              step="0.01"
              className="form-control"
              placeholder="ex: 10"
              onChange={(e) => setAmountOrPrice(e.target.value)}
              value={amountOrPrice}
            />
          )}
        </div>
        <div className="form-group mb-3 checkbox">
          <input
            {...register('agregarAutomatico')}
            id="agregarAutomatico"
            type="checkbox"
            defaultChecked={user.automatico}
          />
          <label className="mb-1 " htmlFor="agregarAutomatico">
            <strong>Adicionar a carteira automática</strong>
          </label>
        </div>

        <div className="text-center mt-4 row-content">
          <button
            className="btn btn-danger btn-block"
            type="button"
            onClick={() => setCancel(false)}
          >
            Cancelar
          </button>
          <br />
          <button type="submit" className="btn btn-primary btn-block ">
            {edit ? 'Editar' : 'Cadastrar'}
          </button>
        </div>
      </form>
    </Container>
  );
}

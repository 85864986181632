import styled from 'styled-components';

interface IPropsAvatar {
  width?: string;
  height?: string;
}

export const Avatar = styled.div<IPropsAvatar>`
  position: relative;
  img {
    border-radius: 100%;
    object-fit: cover;
    width: ${(props) => (props.width ? props.width : '50px')};
    height: ${(props) => (props.height ? props.height : '50px')};
  }

  ::before {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    background: linear-gradient(240deg, #2469a8, #f8b700, #c61722);
    z-index: -1;
    margin: -2px;
    border-radius: 100%;
    transition: display 5s;
    background-size: 300% 300%;
    animation: colors 8s ease infinite;
  }
  @keyframes colors {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

export const NoAvatarComment = styled.div<IPropsAvatar>`
  display: flex;
  position: relative;
  background: ${(props) => props.theme.white};
  border-radius: 100%;
  width: ${(props) => (props.width !== undefined ? props.width : '50px')};
  height: ${(props) => (props.height !== undefined ? props.height : '50px')};
  z-index: 1;
  align-items: center;
  justify-content: center;
  strong {
    font-size: 1.5rem;
    color: ${(props) => props.theme.primary};
    background: ${(props) => props.theme.white};
    width: 100%;
    height: 100%;
    border-radius: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ::before {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    background: linear-gradient(240deg, #2469a8, #f8b700, #c61722);
    z-index: -1;
    margin: -2px;
    border-radius: 100%;
    transition: display 5s;
    background-size: 300% 300%;
    animation: colors 8s ease infinite;
  }
  @keyframes colors {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

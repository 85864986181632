import { FiX } from 'react-icons/fi';
import styled from 'styled-components';

export const OutModalStyled = styled.div`
  position: fixed;
  display: flex;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  color: ${(props) => props.theme.black};

  top: 0;
  right: 0;
  z-index: 100;
  justify-content: center;
  align-items: center;
  h1 {
    padding: 0;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;

  h1 {
    margin: 0;
    padding: 0 !important;
  }
`;
export const Close = styled(FiX)`
  font-size: 1.5rem;
  cursor: pointer;
`;

export const ModalStyled = styled.div`
  align-items: center;
  justify-content: center;

  padding: 10px 5px;
  background: ${(props) => props.theme.white};
  border-radius: 10px;
`;

interface IPropsContentModal {
  width: string;
  height: string;
}

export const Content = styled.div<IPropsContentModal>`
  padding: 16px;
  width: ${(props) => (props.width ? props.width : '500px')};
  height: ${(props) => (props.height ? props.height : '500px')};
  max-height: 500px;

  overflow-y: auto;
  ::-webkit-scrollbar {
    /* display: none; */
  }

  @media (max-width: 515px) {
    width: ${(props) => (props.width ? props.width : '300px')};
  }
`;

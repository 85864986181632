import { styled } from 'styled-components';

export const AsideContentFilters = styled.aside`
  display: flex;
  max-width: 1416px;
  width: 1416px;
  height: 188px;
  padding: 16px 24px;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: var(--Spacing-8, 8px);
  border: 1px solid var(--Gray-gray-100, #D4D6DB);
  background: var(--Main-white, #FFF);
  margin-top: 2%;
  margin-left: 4%;
  position: relative;
  z-index: 2;

  @media (max-width: 1100px) {
    margin-bottom: 5rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const AsideHeader = styled.div`
  display: flex;
  max-width: 1216px;
  padding: 16px 24px;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  margin-left: -2%;
  margin-top: -1%;

  span {
    width: 100%;
    max-width: 18.75rem;
    color: ${(props) => props.theme['gray-400']};
    font-weight: 500;
    font-size: 1rem;
  }
`;

export const ContentInputFilters = styled.div`
  display: flex;
  flex-direction: column;
  background: transparent;
  height: 56px;
  padding: 4px 4px 4px 12px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  gap: 0.5rem;
  margin-top: 4.5%;

  
  label {
    color: var(--Gray-gray-600, #6A717E);
    margin-top: 12%;
    margin-left: -200%;
    position: relative;
    /* Body/XSmall/Regular */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
  }
  span {
    text-align: center;
    font-weight: 500;
    color: ${(props) => props.theme['gray-500']};
    font-size: 0.75rem;
  }
`;

export const ContentInputShowFilters = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  height: 108px;
  margin-right: 10px;
  padding: 8px 8px 8px 24px;
  flex-wrap: wrap;

  label {
    text-align: center;
    color: var(--Gray-gray-600, #6A717E);
    /* Body/XSmall/Regular */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
  }
    span {
    text-align: center;
    font-weight: 500;
    color: ${(props) => props.theme['gray-500']};
    font-size: 0.75rem;
  }
`;

export const ButtonShowMoreFilters = styled.button`
  display: flex;
  padding: var(--Spacing-8, 8px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  all: unset;
  background: #fff;
  border-radius: 8px;
  text-align: center;
  font-size: 1rem;
  color: #6A717E;
`;

export interface IPropsUserData {
  Name: string;
  automatico: boolean;
  cpf: string;
  ddd: string;
  email: string;
  last_password_failed: Date;
  status_pagamento: string;
  status_pagamento_irpf: string;
  telefone: string;
  try_atempts: 0;
  username: string;
  created: Date;
  utm_campaign: string;
  utm_medium: string;
  utm_source: string;
}

export const userDefault: IPropsUserData = {
  Name: '',
  automatico: false,
  cpf: '',
  ddd: '',
  email: '',
  last_password_failed: new Date(),
  status_pagamento: '',
  status_pagamento_irpf: '',
  telefone: '',
  try_atempts: 0,
  username: '',
  created: new Date(),
  utm_campaign: '',
  utm_medium: '',
  utm_source: '',
};

export interface IPropsPlanData {
  NomePlano: string;
  Status: string;
  StatusPagamentoCorrente: string;
  SubscriptionId: string;
  UserName: string;
  Valor: number;
  cardLastDigitsCard: null | string;
  card_brand: null | string;
  currentPeriodEnd: null | Date;
  currentPeriodStart: null | Date;
  manageUrl: string;
  paymentMethod: string;
}

export const defaultValuePlanData: IPropsPlanData = {
  NomePlano: '',
  Status: '',
  StatusPagamentoCorrente: '',
  SubscriptionId: '',
  UserName: '',
  Valor: 0,
  cardLastDigitsCard: null,
  card_brand: null,
  currentPeriodEnd: null,
  currentPeriodStart: null,
  manageUrl: '',
  paymentMethod: '',
};

export interface IPropsPlanIRData {
  NomePlano: string;
  Status: string;
  StatusPagamentoCorrente: string;
  SubscriptionId: string;
  UserName: string;
  Valor: number;
  cardLastDigitsCard: string;
  card_brand: string;
  currentPeriodEnd: Date | null;
  currentPeriodStart: Date | null;
  manageUrl: string;
  paymentMethod: string;
}

export const defaultValuePlanIr: IPropsPlanIRData = {
  NomePlano: '',
  Status: '',
  StatusPagamentoCorrente: '',
  SubscriptionId: '',
  UserName: '',
  Valor: 0,
  cardLastDigitsCard: '',
  card_brand: '',
  currentPeriodEnd: null,
  currentPeriodStart: null,
  manageUrl: '',
  paymentMethod: '',
};

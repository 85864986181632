import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CaretDown, CaretUp, Eye, EyeSlash, Info } from '@phosphor-icons/react';
import { Container, ContentItemsFilterColumnTable } from './styles';
import moment from 'moment';
import { filterTableSorte } from '../../../utils/filterTableSort';
import { useActives } from '../../../hooks/useActives';
import { useEffect, useState } from 'react';
import { transformMaskCurrency } from '../../../utils/transformMaskCurrency';
import { ModalDesigner } from '../../ModalDesigner';

interface IPropsTableProfitabillity {
  income: {
    Quantity: number;
    corporationName: string;
    movementType: string;
    operationValue: number;
    participantName: string;
    referenceDate: string;
    tickerSymbol: string;
    unitPrice: number;
  }[];
  ASC: Function;
  DESC: Function;
}

export function TableProfitability({
  income,
  ASC,
  DESC,
}: IPropsTableProfitabillity) {
  let filterTable = localStorage.getItem('filterTableRP-v1.0.0');
  // @ts-ignore
  filterTable = JSON.parse(filterTable);

  //@ts-ignore
  const { showActive } = useActives();
  const [showAmount, setShowAmount] = useState<boolean>(false);
  const [showActiveName, setShowActiveName] = useState<boolean>(false);
  const [showModalSelectedDateTable, setShowModalSelectedDateTable] =
    useState(false);
  const [filterShowColumnTable, setFilterShowColumnTable] = useState(() => {
    if (filterTable === null) {
      return ['Ativo', 'Data', 'Tipo', 'Preço', 'Total da operação'];
    } else {
      return filterTable;
    }
  });
  const arrColumnTableData = [
    'Ativo',
    'Data',
    'Tipo',
    'Corretora',
    'Quantidade',
    'Preço',
    'Total da operação',
  ];

  const arrColumnTableRecommended = [
    'Ativo',
    'Data',
    'Tipo',
    'Preço',
    'Total da operação',
  ];

  useEffect(() => {
    localStorage.setItem(
      'filterTableRP-v1.0.0',
      JSON.stringify(filterShowColumnTable)
    );
  }, [filterShowColumnTable]);
  const showActiveColumnTable = filterShowColumnTable.includes('Ativo');
  const showDataColumnTable = filterShowColumnTable.includes('Data');
  const showTypeColumnTable = filterShowColumnTable.includes('Tipo');
  const showPriceColumnTable = filterShowColumnTable.includes('Preço');
  const showTotalOperationColumnTable =
    filterShowColumnTable.includes('Total da operação');
  const showBrokerageColumnTable = filterShowColumnTable.includes('Corretora');
  const showAmountColumnTable = filterShowColumnTable.includes('Quantidade');

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledBody = styled(TableBody)(
    ({ theme }) => `
    overflow-y: auto;
    max-height:500px;

  `
  );

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const header = [
    { label: 'Ativo', active: 'tickerSymbol' },
    { label: 'Data', active: 'referenceDate' },
    { label: 'Tipo', active: 'movementType' },

    { label: 'Corretora', active: 'participantName' },
    { label: 'Quantidade', active: 'Quantity' },
    { label: 'Preço', active: 'unitPrice' },
    { label: 'Total da operação', active: 'operationValue' },
  ];

  function handleSelectedColumnTable(value: any) {
    // @ts-ignore
    const isValueAllReadyExist = filterShowColumnTable.find(
      (item: any) => item === value
    );
    if (isValueAllReadyExist) {
      setFilterShowColumnTable((state: any) => {
        return state.filter((item: any) => item !== value);
      });
    } else {
      setFilterShowColumnTable((state: any) => {
        return [...state, value];
      });
    }
  }

  return (
    <Container>
      <button onClick={() => setShowModalSelectedDateTable(true)}>
        Editar colunas
      </button>
      <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
        <Table aria-label="customized table" stickyHeader>
          <TableHead>
            <TableRow>
              {header.map((item) => {
                if (filterShowColumnTable.includes(item.label)) {
                  return (
                    <StyledTableCell key={item.active}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                          fontSize: '0.95rem',
                        }}
                      >
                        {item.label}
                        {item.active && (
                          <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <CaretUp
                              size={14}
                              color="black"
                              onClick={() => {
                                return ASC(
                                  filterTableSorte({
                                    data: income,
                                    dir: 'asc',
                                    //@ts-ignore
                                    key: item.active,
                                  })
                                );
                              }}
                            />
                            <CaretDown
                              size={14}
                              color="black"
                              onClick={() => {
                                return DESC(
                                  filterTableSorte({
                                    data: income,
                                    dir: 'desc',
                                    //@ts-ignore
                                    key: item.active,
                                  })
                                );
                              }}
                            />
                          </div>
                        )}
                        {item.label === 'Quantidade' &&
                          (!showAmount ? (
                            <EyeSlash
                              size={20}
                              color="black"
                              onClick={() => setShowAmount(!showAmount)}
                            />
                          ) : (
                            <Eye
                              size={20}
                              color="black"
                              onClick={() => setShowAmount(!showAmount)}
                            />
                          ))}
                        {item.label === 'Ativo' &&
                          (!showActiveName ? (
                            <EyeSlash
                              size={20}
                              color="black"
                              onClick={() => setShowActiveName(!showActiveName)}
                            />
                          ) : (
                            <Eye
                              size={20}
                              color="black"
                              onClick={() => setShowActiveName(!showActiveName)}
                            />
                          ))}
                      </div>
                    </StyledTableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <StyledBody>
            {income?.map((item, index) => (
              <StyledTableRow key={index}>
                {showActive || showActiveName
                  ? showActiveColumnTable && (
                      <StyledTableCell>
                        {item.tickerSymbol}
                        <br />
                        <strong>{item.corporationName}</strong>
                      </StyledTableCell>
                    )
                  : showActiveColumnTable && (
                      <StyledTableCell>*******</StyledTableCell>
                    )}
                {showDataColumnTable && (
                  <StyledTableCell>
                    {moment(item.referenceDate).format('L')}
                  </StyledTableCell>
                )}
                {showTypeColumnTable && (
                  <StyledTableCell>{item.movementType}</StyledTableCell>
                )}
                {showBrokerageColumnTable && (
                  <StyledTableCell>{item.participantName}</StyledTableCell>
                )}
                {showAmountColumnTable && (
                  <StyledTableCell>
                    {showAmount || showActive ? item.Quantity : '****'}
                  </StyledTableCell>
                )}
                {showPriceColumnTable && (
                  <StyledTableCell>
                    {transformMaskCurrency(item.unitPrice)}
                  </StyledTableCell>
                )}
                {showTotalOperationColumnTable && (
                  <StyledTableCell>
                    {showActive
                      ? transformMaskCurrency(item.operationValue)
                      : 'R$ *****'}
                  </StyledTableCell>
                )}
              </StyledTableRow>
            ))}
          </StyledBody>
        </Table>
      </TableContainer>

      <ModalDesigner
        id="selecionarColunas"
        isOpen={showModalSelectedDateTable}
        setState={setShowModalSelectedDateTable}
        title="Adicionar campos"
        size="sm"
      >
        <ContentItemsFilterColumnTable>
          <span className="recommendText"> Recomendado</span>
          <div className="contentInput">
            {arrColumnTableRecommended.map((item) => (
              <label key={item}>
                <input
                  type="checkbox"
                  value={item}
                  defaultChecked={filterShowColumnTable.includes(item)}
                  onChange={(e) => handleSelectedColumnTable(e.target.value)}
                />
                {item}
              </label>
            ))}
          </div>

          <span className="addMore">CAMPOS ADICIONAIS</span>
          <div className="contentInput">
            {arrColumnTableData
              .filter(
                (item) =>
                  item !== 'Total da operação' &&
                  item !== 'Ativo' &&
                  item !== 'Tipo' &&
                  item !== 'Data' &&
                  item !== 'Preço'
              )
              .map((item) => (
                <label key={item}>
                  <input
                    type="checkbox"
                    value={item}
                    defaultChecked={filterShowColumnTable.includes(item)}
                    onChange={(e) => handleSelectedColumnTable(e.target.value)}
                  />
                  {item}
                </label>
              ))}
          </div>
          <span className="important">
            <Info size={16} color="#505050" weight="bold" /> IMPORTANTE
          </span>
          <p className="warning">
            Alterar a estrutura da tabela de ativos padrão pode afetar sua
            experiência, recomendamos que sempre que possível, utilize a tabela
            padrão.
          </p>
        </ContentItemsFilterColumnTable>
      </ModalDesigner>
    </Container>
  );
}

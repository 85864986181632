import styled from 'styled-components';

export const HeaderContainer = styled.header`
  padding: 1rem 0;
  background: rgb(122, 27, 33);
  background: linear-gradient(
    35deg,
    rgba(122, 27, 33, 1) 0%,
    rgba(42, 42, 42, 1) 26%,
    rgba(42, 42, 42, 1) 71%,
    rgba(31, 72, 107, 1) 100%
  );
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10;
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 66.25rem;
  align-items: center;
  margin: 0 1rem;
  .navContent {
    display: flex;
    align-items: center;

    img {
      width: 8.375rem;
      margin-right: 2rem;
    }
  }

  .listNav {
    display: flex;
    gap: 1rem;
    align-items: center;
    li {
      a {
        color: ${(props) => props.theme.white};
        font-size: 1rem;
        cursor: pointer;
        font-weight: 400;
        line-height: 240%;
      }

      + li {
        margin-left: 1rem;
      }
    }
  }

  @media (max-width: 768px) {
    .navContent {
      width: 100%;
      justify-content: space-between;
    }

    .listNav {
      display: none;
    }
  }
`;

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: 768px) {
    display: none !important;
  }
`;

interface IPropsButton {
  isLoginButton?: boolean;
}

export const Button = styled.button<IPropsButton>`
  all: unset;
  background-color: ${(props) =>
    props.isLoginButton ? '#1F262C' : props.theme.primary};
  color: ${(props) => props.theme.white};
  font-weight: 500;
  cursor: pointer;
  font-size: 1.125rem;
  line-height: 150%;
  padding: 0.75rem 1.75rem;
  border-radius: 8px;
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

`;

export const ContentThumbnail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  label {
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 4px;
    padding: 8px 5px;
    color: ${(props) => props.theme.white};
    background: ${(props) => props.theme.primary};

    transition: filter 0.3s;

    :hover {
      filter: brightness(0.9);
    }
  }

  img {
    width: 100%;
    max-width: 400px;
    height: auto;
  }
`;

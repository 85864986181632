import styled from 'styled-components';

export const ContainerCalculate = styled.div`
  display: flex;

  gap: 1rem;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: ${(props) => props.theme.white};
  width: 100%;
  flex: 1;

  span {
    font-size: 1.3rem;
    font-weight: 400;

    strong {
      font-weight: bold;
      text-decoration: underline;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .maskPercent {
      position: relative;
      ::before {
        content: '%';
        position: absolute;
        right: 5.6rem;
        bottom: 5px;
        color: ${(props) => props.theme['text-body']};
        font-family: 'Poppins', sans-serif;
        font-size: 1rem;
      }
    }
    .onPeriod {
      position: relative;
      input {
        padding: 0.468rem 5.5rem 0.468rem 0.468rem;
      }

      select {
        padding: 0.468rem;
        position: absolute;
        right: 0;
        bottom: 0;
        border: none;
        border-left: ${(props) => props.theme.border};
        background-color: transparent;
        color: ${(props) => props.theme.black};
      }
    }

    div {
      display: flex;
      flex-direction: column;
      input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      input[type='number'] {
        -moz-appearance: textfield;
        appearance: textfield;
      }

      input {
        border: ${(props) => props.theme.border};
        border-radius: 8px;
        padding: 0.468rem;
      }
    }
  }
`;

import { ArrowLeft } from '@phosphor-icons/react';
import DonoutPercentage from '../../../../DonoutPercentage';
import { IPropsDataFundoFii } from '../@types';
import {
  ContentText,
  HeaderCardAnalysisFiiMobileContainer,
  Percent,
} from './styles';
import { GraphicNoteFii, IPropsFiiRankActive } from '../../GraphicNoteFii';

interface IPropsHeaderCardAnalysisFiiMobile {
  dataFundo: IPropsDataFundoFii;
  onBackAllFii: Function;
  fii: IPropsFiiRankActive;
}

export function HeaderCardAnalysisFiiMobile({
  dataFundo,
  onBackAllFii,
  fii,
}: IPropsHeaderCardAnalysisFiiMobile) {
  const defineDonutColor = (value: number) => {
    const ok = '#2469A8';
    const danger = '#C61722';
    const warning = '#F8B700';
    if (value >= 70) {
      return ok;
    } else if (value >= 30) {
      return warning;
    } else {
      return danger;
    }
  };

  return (
    <HeaderCardAnalysisFiiMobileContainer>
      <ContentText>
        <button onClick={() => onBackAllFii('', '')}>
          <ArrowLeft /> {dataFundo.codigo_fii}
        </button>
        <span title={dataFundo.nome_fundo}>{dataFundo.nome_fundo}</span>
      </ContentText>
      <GraphicNoteFii graphicWidth={80} fii={fii} />
      {/* <Percent>
        <DonoutPercentage
          donutColor={defineDonutColor(dataFundo.total_chart)}
          textColor={defineDonutColor(dataFundo.total_chart)}
          size={'60px'}
          value={dataFundo.total_chart}
          infoGraphic="O percentual apresentado no gráfico é uma
          avaliação automatizada de alguns indicadores.
          Entretanto, não constitui uma avaliação completa
          do ativo e não se trata de recomendação de compra
          ou venda. Antes de tomar sua decisão, é importante
          estudar os demais indicadores e considerar fatores
          subjetivos"
        />
      </Percent> */}
    </HeaderCardAnalysisFiiMobileContainer>
  );
}

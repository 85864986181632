import styled from 'styled-components';

export const FormOfPayment = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  p {
    margin: 0;
    color: ${(props) => props.theme['gray-700']};
    font-size: 1rem;
  }

  input,
  select {
    border: ${(props) => props.theme.border};
    border-radius: 4px;
    padding: 0.5rem;
    color: ${(props) => props.theme['gray-700']};
    width: 100%;
  }

  strong {
    color: ${(props) => props.theme['gray-700']};
  }
`;

export const RowContentInput = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const ContentButtonForm = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
  justify-content: center;
`;

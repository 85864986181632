export const defaultValueCompany = {
  Assets: [],
  CD_CVM: '',
  DT_INI_SIT: '',
  DividaLiquida: 0,
  DividaLiquidaEbitida: 0,
  Dividendos: 0,
  EBITDA: 0,
  Governanca: 0,
  Indices: 0,
  LOGO_BRAND: '',
  LucroLiquido: 0,
  NomeEmpresa: '',
  Ordinárias: 0,
  PorcentagemON: 0,
  PorcentagemPN: 0,
  PorcentagemTotal: 0,
  Preferenciais: 0,
  QtdeAcoesON: 0,
  QtdeAcoesPN: 0,
  QtdeAcoesTotal: 0,
  ROE: 0,
  Resiliencia: 0,
  SegmentoMercado: '',
  UltDividaLiquida: 0,
  UltDividaLiquidaEbitda: 0,
  UltDividendos: 0,
  UltEBITDA: 0,
  UltLucroLiquido: 0,
  UltRoe: 0,
  cnpj: '',
  ri: '',
  segmento: '',
  setor: '',
  site: '',
  situacao: '',
  sub_setor: '',
  total_chart: 0,
  txCNPJ: '',
};

import ReactApexChart from 'react-apexcharts';
import { GraphicNewUsersContainer } from './styles';
import { IPropsTrafficNewUsers } from '../../@types';
import { Heading } from '../../../../../../components/DefaultStylesTypography';
import ReactLoading from 'react-loading';

interface IPropsGraphicNewUsers {
  data: IPropsTrafficNewUsers;
  loading: boolean;
}

export function GraphicNewUsers({ data, loading }: IPropsGraphicNewUsers) {
  const arrData = data.data.slice(0, 10);
  const dataGraphic = {
    series: [
      {
        name: 'Amount',
        data: arrData.map((item) => item.value),
      },
    ],
    options: {
      chart: {
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        categories: arrData.map((item) => item.title),
        labels: {
          style: {
            colors: ['#fff'],
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: ['#fff'],
          },
        },
      },
    },
  };

  return (
    <GraphicNewUsersContainer>
      <Heading as="h4" variant="quaternary" color="white">
        Fonte do tráfego
      </Heading>
      {loading ? (
        <ReactLoading
          type="balls"
          width={200}
          height={200}
          color="var(--primary)"
        />
      ) : (
        <ReactApexChart
          //@ts-ignore
          options={dataGraphic.options}
          series={dataGraphic.series}
          type="bar"
          height={400}
          width={700}
        />
      )}
    </GraphicNewUsersContainer>
  );
}

import { useEffect } from 'react';
import { CDI } from './components/CDI';
import { EditIndicesContainer } from './styles';
import { useTitleAndUser } from '../../../../hooks/userTitle';

export function EditIndices() {
  // @ts-ignore
  const { setTitle } = useTitleAndUser();

  useEffect(() => {
    setTitle('Editar indices');
  }, []);

  return (
    <EditIndicesContainer>
      <CDI />
    </EditIndicesContainer>
  );
}

import styled from 'styled-components';

export const FormFeedbackUserContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ContentInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  label {
    font-size: 0.875rem;
    color: ${(props) => props.theme['gray-700']};
    margin: 0;
  }

  input,
  select,
  textarea {
    padding: 0.5rem;
    font-size: 1rem;
    color: ${(props) => props.theme['gray-700']};
    border: ${(props) => props.theme.border};
    border-radius: 4px;
  }
  .starContent {
    display: flex;
    gap: 0.25rem;

    button {
      border: 0;
      background: transparent;
      font-size: 1.5rem;
      line-height: 0;
      color: ${(props) => props.theme['brand-secondary']};

      :hover {
        svg {
          fill: ${(props) => props.theme['brand-secondary']};
        }
      }
    }
  }
`;

import { Newspaper, Wall } from '@phosphor-icons/react';

import {
  Container,
  ContentGraphic,
  HeaderCardActive,
  ColDiv,
} from './FiiCardStyle';
import ReactApexChart from 'react-apexcharts';
import { Tooltip } from 'antd';
import { cores } from '../../../utils/coresGraficos';

const FiiGrid = ({
  asset: {
    codigo_fii,
    data_funcionamento_numerico,
    dividendo_yeld,
    nome_fundo,
    nota_final,
    qtde_ativos,
    vacancia_fisica,
    valor_patrimonial_por_cota,
    tipo_fii,
  },
  onSelectedActive = ({ slug, type }) => {},
  isComponent,
}) => {
  const values = [
    data_funcionamento_numerico === 3
      ? 100
      : data_funcionamento_numerico === 2
      ? 67
      : 33,
    dividendo_yeld === 3 ? 100 : dividendo_yeld === 2 ? 67 : 33,
    qtde_ativos === 3 ? 100 : qtde_ativos === 2 ? 67 : 33,
    vacancia_fisica === 3 ? 100 : vacancia_fisica === 2 ? 67 : 33,
    valor_patrimonial_por_cota === 3
      ? 100
      : valor_patrimonial_por_cota === 2
      ? 67
      : 33,
  ];
  const labels = [
    'Tempo em bolsa',
    'DY',
    'Quant. Ativos',
    'Vacância física',
    'Valor patrimonial',
    '',
  ];
  const total = values.reduce((sum, value) => sum + (value || 0), 0);
  const restante = (total - 100 * values.length) * -1;
  const compare = {
    series: [...values, restante],
    options: {
      chart: {
        height: 150,
        type: 'donut',
      },
      stroke: { show: false },
      grid: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },
      legend: {
        show: false,
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          donut: {
            size: '65%',
            labels: {
              show: false,
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        onDatasetHover: {
          highlightDataSeries: false,
        },
        y: {
          formatter: (val) => {
            if (val > 100) return '';
            return `${val.toFixed(0)}%`;
          },
        },
      },
      labels,
      colors: [
        '#38486B',
        '#635185',
        '#99538E',
        '#F45F6A',
        '#FFA600',
        '#E4E5E7',
      ],
    },
  };

  return (
    <Container
      onClick={() => {
        onSelectedActive({
          slug: codigo_fii,
          type: 'fii',
        });
      }}
    >
      <div style={{ zIndex: '1' }}>
        <HeaderCardActive>
          {tipo_fii === 'Tijolo' ? (
            <Wall weight="bold" size={24} color="#303F4B" />
          ) : (
            <Newspaper weight="bold" size={24} color="#303F4B" />
          )}
          <span
            onClick={() => {
              onSelectedActive({
                slug: codigo_fii,
                type: 'fii',
              });
            }}
          >
            {codigo_fii}
          </span>
        </HeaderCardActive>
        <ColDiv>
          <ContentGraphic value={nota_final.length}>
            <div className="contentGraphicValue">
              <ReactApexChart
                //@ts-ignore
                options={compare.options}
                series={compare.series}
                type="donut"
                height={200}
                width={250}
              />
              <Tooltip title="Essa é a nota da empresa com base na média das nossas avaliações">
                <h6>{nota_final.toFixed(0)}%</h6>
              </Tooltip>
            </div>
          </ContentGraphic>
          <div style={{ color: 'black' }}>
            <h6
              onClick={() => {
                onSelectedActive({
                  slug: codigo_fii,
                  type: 'fii',
                });
              }}
              title={nome_fundo
                .replace('FUNDO DE INVESTIMENTO IMOBILIÁRIO', '')
                .replace('FUNDO DE INVESTIMENTO IMOBILIARIO', '')
                .replace('-', '')
                .replace(' - ', '')
                .replace('FII', '')}
            >
              {nome_fundo
                .replace('FUNDO DE INVESTIMENTO IMOBILIÁRIO', '')
                .replace('FUNDO DE INVESTIMENTO IMOBILIARIO', '')
                .replace('-', '')
                .replace(' - ', '')
                .replace('FII', '')}
            </h6>
          </div>
        </ColDiv>
      </div>
    </Container>
  );
};

export default FiiGrid;

import { useState } from 'react';
import { useTitleAndUser } from '../../../../../../../hooks/userTitle';
import { AddressFormIR } from './forms/Address';
import { PlansFormIR } from './forms/PlansForm';
import { Container } from './styles';

interface IPropsScreenToSignIR {
  updatePlan: () => void;
}

export function ScreenToSignIR({ updatePlan }: IPropsScreenToSignIR) {
  //@ts-ignore
  const { user } = useTitleAndUser();
  const [tabForm, setTabForm] = useState(() => {
    if (user.zipcode) {
      return 2;
    }
    return 1;
  });

  return (
    <Container>
      {tabForm === 1 && (
        <AddressFormIR
          //@ts-ignore
          setTabForm={setTabForm}
        />
      )}
      {tabForm === 2 && <PlansFormIR updatePlan={updatePlan} />}
    </Container>
  );
}

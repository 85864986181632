import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 3.375rem 1fr;
  width: 100%;
  max-width: 270px;
  gap: 0.5rem;
`;

export const ImgContainer = styled.div`
  width: 3.375rem;
  height: 3.375rem;
  font-size: 5rem;

  background: ${(props) => props.theme['gray-100']};
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 1rem;
  img {
    object-fit: cover;
    border-radius: 100%;
    width: 100%;
    max-width: 3.375rem;
    min-width: 3.375rem;
    height: 100%;
    max-height: 3.375rem;
    background: ${(props) => props.theme.black};
  }

  .iconContent {
    position: absolute;
    bottom: -0.25rem;
    right: -0.25rem;
    background: ${(props) => props.theme.primary};
    border-radius: 100%;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const ContentBodyNotification = styled.p`
  margin: 0;
`;

import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import { Container } from './styles';
import { financeRoutes } from '../../../../utils/RoutesApi';
import { maskValueCards } from '../maskValueCards';
import { transformMaskCurrency } from '../../../../utils/transformMaskCurrency';
import { ModalDesigner } from '../../../ModalDesigner';

interface IPropsAnnualAssetClose {
  assets: string[];
  lucroLiquidoData: {
    Ano: number;
    value: number;
  }[];
  isShow: boolean;
  setIsShow: Function;
}

interface IPropsDataGraphic {
  asset: string;
  prices: {
    data: string;
    preco: number;
  }[];
}

export function AnnualAssetClose({
  assets,
  lucroLiquidoData,
  isShow,
  setIsShow,
}: IPropsAnnualAssetClose) {
  const [dataGraphic, setDataGraphic] = useState<IPropsDataGraphic[]>([]);
  const [selectedTicket, setSelectedTicket] = useState<string>(assets[0]);

  useEffect(() => {
    setClosePriceForActiveAnnual();
  }, [assets]);

  async function setClosePriceForActiveAnnual() {
    let newArray: IPropsDataGraphic[] = [];

    for (var tck in assets) {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_ANNUAL_PRICES}`,
          {
            params: { ativo: assets[tck], tipopreco: 'anual' },
          }
        )
        .then((response) => {
          let newData = response.data.prices;

          //@ts-ignore
          newData = newData.sort((a, b) => a.data - b.data);

          //@ts-ignore
          let netProfitArray = [];

          for (var data in newData) {
            const findLucroIsExistData = lucroLiquidoData.find(
              (item) => item.Ano === newData[data].data
            );
            if (findLucroIsExistData) {
              netProfitArray = [
                //@ts-ignore
                ...netProfitArray,
                findLucroIsExistData,
              ];
            }
          }
          //@ts-ignore

          const newItem = {
            asset: assets[tck],
            prices: newData,
            //@ts-ignore
            lucroLiquidoData: netProfitArray,
          };
          if (newArray.length > 0) {
            newArray = [...newArray, newItem];
          } else {
            newArray = [newItem];
          }
          setDataGraphic(newArray);
        })
        .catch((err) => {});
    }
    // setClosePrice(closePriceForActive);
  }


  const findIndexActiveTicket = dataGraphic.findIndex(
    (item) => item.asset === selectedTicket
  );

  const graphic = {
    series: [
      {
        name: selectedTicket,
        data:
          dataGraphic[findIndexActiveTicket]?.prices.map((item) => {
            return item.preco;
          }) ?? [],
        type: 'line',
        color:'#1C2D38',
        marker: { 
          enabled: true, // Habilita os marcadores 
          radius: 4, // Tamanho dos marcadores 
          symbol: 'circle' // Forma dos marcadores
        },
      },
      {
        name: 'Lucro Líquido',
        //@ts-ignore
        data: dataGraphic[findIndexActiveTicket]?.lucroLiquidoData.map(
          //@ts-ignore
          (item) => item.value
        ),
        type: 'column',
        color:'#FF7D45',
        
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        stacked: false,
      },

      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [4, 4, 4],
      },
      labels:
        dataGraphic[findIndexActiveTicket]?.prices.map((item) => item.data) ??
        [],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },

      yaxis: [
        {
          labels: {
            formatter: (val: number) => {
              return transformMaskCurrency(val);
            },
          },
        },
        {
          opposite: true,
          title: {
            text: 'Valor do ativo',
          },

          labels: {
            formatter: (val: number) => {
              return maskValueCards(val);
            },
          },
        },
      ],
      noData: {
        text: 'Não encontramos os valores desse ativo',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: '14px',
          fontFamily: undefined,
        },
      },
      responsive: [
        {
          breakpoint: 998,
          options: {
            chart: {
              width: 600,
            },
          },
        },
        {
          breakpoint: 690,
          options: {
            chart: {
              width: 400,
            },
          },
        },
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
            },
          },
        },
        {
          breakpoint: 340,
          options: {
            chart: {
              width: 250,
            },
          },
        },
      ],
    },
  };
  return (
    <Container>
      <ModalDesigner
        title="Lucro x Cotação"
        id="lucroxcotacao"
        isOpen={isShow}
        setState={setIsShow}
        size="xl"
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <select
            defaultValue={assets[0]}
            onChange={(e) => setSelectedTicket(e.target.value)}
            style={{
              border: '1px solid rgba(21,21,21,0.4)',
              width: '100%',
              maxWidth: '200px',
              padding: '0.5rem',
              borderRadius: '4px',
              color: 'black',
              marginLeft: '1rem',
            }}
          >
            {assets.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </select>
          <ReactApexChart
            //@ts-ignore
            options={graphic.options}
            series={graphic.series}
            type="line"
            height={350}
            width={900}
          />
        </div>
      </ModalDesigner>
    </Container>
  );
}

import ReactApexChart from 'react-apexcharts';
import { useActives } from '../../../../../../../hooks/useActives';
import { transformMaskCurrency } from '../../../../../../../utils/transformMaskCurrency';
import { IPropsTotalPeriodos } from '../../@types';

interface IPropsGraphicFilter {
  activeFilter: string;
  dataGraphic: {
    period: number;
    total: number;
    total_acao: number;
    total_fii: number;
  }[];
  typeGraphic: 'month' | 'year';
}

export function GraphicFilter({
  activeFilter,
  dataGraphic,
  typeGraphic,
}: IPropsGraphicFilter) {
  //@ts-ignore
  const { showActive } = useActives();

  const reducer = (acc: IPropsTotalPeriodos[], curr: IPropsTotalPeriodos) => {
    const ano = Number(curr.period.toString().substring(0, 4));
    const valorAction = curr.total_acao;
    const valorFii = curr.total_fii;
    const total = curr.total;

    const existingYear = acc.find(
      (item) => Number(item.period.toString().substring(0, 4)) === ano
    );
    if (existingYear) {
      existingYear.total_acao += valorAction;
      existingYear.total_fii += valorFii;
      existingYear.total += total;
    } else {
      acc.push({
        ...curr,
        period: ano,
        total_acao: valorAction,
        total_fii: valorFii,
        total,
      });
    }

    return acc;
  };

  const activesForYear = dataGraphic.reduce(reducer, []);

  const graphicDataYear = {
    series: [
      {
        name: activeFilter,
        type: 'column',
        data: activesForYear.map((item) => {
          if (item.total_acao !== 0) {
            return item.total_acao;
          } else if (item.total_fii !== 0) {
            return item.total_fii;
          }
        }),
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        stacked: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [4, 4, 4],
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      colors: [
        'var(--graphic-action)',
        'var(--graphic-fii)',
        'var(--graphic-total)',
      ],
      legend: {
        position: 'bottom',
        horizontalAlign: 'right',
      },
      xaxis: {
        categories: activesForYear.map((item) => item.period.toString()),
        // labels: {
        //   //@ts-ignore
        //   formatter: function (value) {
        //     if (value) {
        //       let year = value?.substring(0, 4);
        //       let month = value?.substring(4, 6);
        //       return `${month}/${year}`;
        //     }
        //   },
        // },
      },
      yaxis: {
        labels: {
          //@ts-ignore
          formatter: function (value) {
            if (showActive) {
              return transformMaskCurrency(value);
            } else {
              return 'R$ *****';
            }
          },
        },
      },

      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          //@ts-ignore
          formatter: function (val) {
            if (showActive) {
              return transformMaskCurrency(val);
            } else {
              return 'R$ *****';
            }
          },
        },
      },
    },
  };
  const graphicData = {
    series: [
      {
        name: activeFilter,
        type: 'column',
        data: dataGraphic.map((item) => {
          if (item.total_acao !== 0) {
            return item.total_acao;
          } else if (item.total_fii !== 0) {
            return item.total_fii;
          }
        }),
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        stacked: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [4, 4, 4],
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      colors: [
        'var(--graphic-action)',
        'var(--graphic-fii)',
        'var(--graphic-total)',
      ],
      legend: {
        position: 'bottom',
        horizontalAlign: 'right',
      },
      xaxis: {
        categories: dataGraphic.map((item) => item.period.toString()),
        labels: {
          //@ts-ignore
          formatter: function (value) {
            if (value) {
              let year = value?.substring(0, 4);
              let month = value?.substring(4, 6);
              return `${month}/${year}`;
            }
          },
        },
      },
      yaxis: {
        labels: {
          //@ts-ignore
          formatter: function (value) {
            if (showActive) {
              return transformMaskCurrency(value);
            } else {
              return 'R$ *****';
            }
          },
        },
      },

      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          //@ts-ignore
          formatter: function (val) {
            if (showActive) {
              return transformMaskCurrency(val);
            } else {
              return 'R$ *****';
            }
          },
        },
      },
    },
  };
  return typeGraphic === 'month' ? (
    // @ts-ignore
    <ReactApexChart
      options={graphicData.options}
      series={graphicData.series}
      type="line"
      height={350}
    />
  ) : (
    // @ts-ignore
    <ReactApexChart
      options={graphicDataYear.options}
      series={graphicDataYear.series}
      type="line"
      height={350}
    />
  );
}

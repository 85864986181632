import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContentPlanMessage = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
  border-radius: 4px;
  box-shadow: ${(props) => props.theme['card-shadow-2']};
  padding: 1rem;
  max-width: 23rem;

  p {
    font-size: 1rem;
    color: ${(props) => props.theme['gray-700']};
    margin: 0;

    span {
      color: ${(props) => props.theme.primary};
      cursor: pointer;
      font-size: 1rem;
    }
    a {
      cursor: pointer;
      color: ${(props) => props.theme.primary};
      transition: filter 0.3s;
      font-weight: 600;
      :hover {
        filter: brightness(0.9);
      }
    }
  }
`;

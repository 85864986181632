import styled from 'styled-components';

export const ContentForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: start;
  width: 100%;

  h1 {
    margin: 0;
    font-weight: 500;
    font-size: 1.375rem;
    color: black;
  }

  .contentInput {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    label {
      font-weight: 400;
      font-size: 0.875rem;
      color: ${(props) => props.theme['gray-200']};
    }
    input {
      border-radius: 12px;
      border: 1px solid ${(props) => props.theme['gray-200']};
      padding: 0.75rem;
      padding-left: 1.75rem;
      font-size: 0.875rem;
      font-weight: 400;
      color: ${(props) => props.theme['gray-200']};
    }
    small {
      position: absolute;
      bottom: 0.857rem;
      left: 0.5rem;
      font-size: 0.875rem;
      font-weight: 400;
      color: ${(props) => props.theme['gray-200']};
    }
  }
`;

import { Check } from '@phosphor-icons/react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTitleAndUser } from '../../../hooks/userTitle';
import { Container, Content } from './styles';

export function SpecialThanksRegister() {
  //@ts-ignore
  const { setTitle } = useTitleAndUser();

  useEffect(() => {
    setTitle('Cadastro realizado');
  }, []);

  return (
    <Container>
      <Content>
        <Check />
        <h1>
          Que legal! <br />
          Sua conta foi criada com sucesso e agora você faz parte da comunidade
          iBox6!
        </h1>
        <p>
          Você tem 30 dias de acesso grátis para testar todas as nossas
          funcionalidades{' '}
          <span>
            (exceto a funcionalidade do imposto de renda, que necessita ser
            contratada à parte)
          </span>
          .
          <br />
          <br />
          Se tiver alguma dúvida, nossa equipe de suporte está sempre
          pronta para ajudá-lo.
        </p>

        <Link to="/dashboard">Página inicial</Link>
      </Content>
    </Container>
  );
}

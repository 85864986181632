import { useEffect, useState } from 'react';
import {
  addDays,
  differenceInCalendarDays,
  endOfDay,
  isSameDay,
  startOfDay,
  subDays,
} from 'date-fns';
import { pt } from 'date-fns/locale';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useTitleAndUser } from '../../../../hooks/userTitle';
import {
  Card,
  ContentCards,
  ContentFilterDate,
  DashboardAdminContainer,
  FilterExhibition,
} from './styles';
import { Heading, Text } from '../../../../components/DefaultStylesTypography';
import moment from 'moment';
import axios from 'axios';
import { administrationController } from '../../../../utils/RoutesApi';
import { Funnel } from '@phosphor-icons/react';
import { transformMaskCurrency } from '../../../../utils/transformMaskCurrency';
import {
  AdminDashLoader,
  LoadingDashAdminInfos,
} from '../../../components/LoadingModels';
import {
  IPropsCountTypePlatform,
  IPropsDataInfoUser,
  IPropsTrafficNewUsers,
} from './@types';
import { GraphicTypePlatform } from './components/GraphicTypePlatform';
import { GraphicNewUsers } from './components/GraphicNewUsers';
import { DataInfoUsers } from './components/DataInfoUsers';
import { TableCampaign } from './components/TableCampaign';
import { GraphicAccessForPage } from './components/GraphicAccessForPage';

interface IPropsGetAnalyticsParams {
  startDate: Date;
  endDate: Date;
}

interface IPropsUtmSource {
  utm_source: string | null;
  count: number;
}
interface IPropsPlanName {
  NomePlano: string;
  count: number;
}

interface IPropsFacebookData {
  CPA: number;
  CPC: number;
  CPM: number;
  CTR: number;
  Clicks: number;
  Gasto: number;
  Impressoes: number;
}

export interface IPropsCampaignFacebook {
  CPA: number;
  CPC: number;
  CPM: number;
  CTR: number;
  Campanha: string;
  Clicks: number;
  Gasto: number;
  Impressoes: number;
}

interface IPropsCheckoutSource {
  source_name: string;
  value: number;
}
interface IPropsUserByPlan {
  userplan_name: string;
  value: number;
}
interface IPropsAccessByPage {
  page_name: string;
  value: number;
}

interface IPropsSections {
  title: string;
  cards: {
    display_name: string;
    value: number;
  }[];
}

interface IPropsGetAnalyticsResponse {
  active_users_count: number;
  connected_users_count: number;
  new_subscriptions_count: number;
  plan_name_count: IPropsPlanName[];
  subscription_days_avg: number;
  user_count: number;
  users_still_in_trial: number;
  utm_source_count: IPropsUtmSource[];
  facebook: IPropsFacebookData[];
  new_subscription_count: number;
  renewal_count: number;
  total_amount: number;
  total_leads: number;
  transacoes: number;
  avg_days_num_b3: number;
  cont_b3: number;
  campanhas_array: IPropsCampaignFacebook[];
  total_users_checkout_source: IPropsCheckoutSource[];
  total_paid_users: number;
  total_users_by_userplan: IPropsUserByPlan[];
  access_by_page: IPropsAccessByPage[];
  total_canceled_users: number;
  total_bonus_users_expired: number;
  sections: IPropsSections[];
}

const defineds = {
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
};

export function DashboardAdmin() {
  //@ts-ignore
  const { setTitle } = useTitleAndUser();
  const [dataAnalytics, setDataAnalytics] =
    useState<IPropsGetAnalyticsResponse>({
      active_users_count: 0,
      connected_users_count: 0,
      new_subscriptions_count: 0,
      plan_name_count: [],
      subscription_days_avg: 0,
      user_count: 0,
      users_still_in_trial: 0,
      utm_source_count: [],
      campanhas_array: [],
      facebook: [],
      new_subscription_count: 0,
      renewal_count: 0,
      total_amount: 0,
      total_leads: 0,
      transacoes: 0,
      avg_days_num_b3: 0,
      cont_b3: 0,
      total_users_checkout_source: [],
      total_paid_users: 0,
      total_users_by_userplan: [],
      access_by_page: [],
      total_canceled_users: 0,
      total_bonus_users_expired: 0,
      sections: [],
    });
  const [totals, setTotals] = useState<IPropsSections[]>([]);
  const [dataPlatform, setDataPlatform] = useState<IPropsCountTypePlatform>({
    data: {
      desktop: 0,
      mobile: 0,
      tablet: 0,
    },
    type: '',
  });
  const [dataNewUsersAccess, setDataNewUsersAccess] =
    useState<IPropsTrafficNewUsers>({
      data: [],
      type: '',
    });
  const [dataInfoUser, setDataInfoUser] = useState<IPropsDataInfoUser>({
    avg_session_duration: 0,
    bounce_rate: 0,
    sessions: 0,
    type: '',
    users: 0,
  });
  const [state, setState] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    setTitle('Dashboard Admin');
    getTotalDatas();
    getAnalyticsUser({
      startDate: state[0].startDate,
      endDate: state[0].endDate,
    });
  }, []);

  async function getAnalyticsUser({
    startDate,
    endDate,
  }: IPropsGetAnalyticsParams) {
    setLoadingSearch(true);
    const startDateFormat = moment(startDate).format('YYYY-MM-DD');
    const endDateFormat = moment(endDate).format('YYYY-MM-DD');

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}${administrationController.GET_ANALYTICS_USER}`,
      {
        params: {
          start_date: startDateFormat,
          end_date: endDateFormat,
        },
      }
    );
    const getPlatformData = response.data.google.find(
      (data: IPropsCountTypePlatform) => data.type === 'Sessions by Device'
    );
    const getUserAccessPlatform = response.data.google.find(
      (data: IPropsTrafficNewUsers) => data.type === 'Sessions by Source Medium'
    );
    const getDataInfoUser = response.data.google.find(
      (data: IPropsDataInfoUser) => data.type === 'Totals'
    );

    setDataInfoUser(getDataInfoUser);
    setDataNewUsersAccess(getUserAccessPlatform);
    setDataPlatform(getPlatformData);
    setDataAnalytics(response.data);
    setLoadingSearch(false);
  }
  async function getTotalDatas() {
    setLoadingSearch(true);

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}${administrationController.GET_TOTAL_ANALYTICS_USER}`
    );
    setTotals(response.data.sections);
  }

  function RenderTotals() {
    if (totals && totals.length > 0) {
      return totals.map((total) => (
        <div key={total.title}>
          <Heading as="h4" variant="quaternary" color="white">
            {total.title}
          </Heading>
          <br />

          <ContentCards>
            {total.cards.map((card) => (
              <Card key={card.display_name}>
                <header>
                  <Heading as="h6" variant="sextiary" color="white">
                    {card.display_name}
                  </Heading>
                </header>
                <Text color="white">
                  {' '}
                  {loadingSearch ? <LoadingDashAdminInfos /> : card.value}
                </Text>
              </Card>
            ))}
          </ContentCards>
          <br />
        </div>
      ));
    } else {
      return <></>;
    }
  }

  return (
    <DashboardAdminContainer>
      {loadingSearch ? (
        <AdminDashLoader />
      ) : (
        <>
          {RenderTotals()}
          <ContentFilterDate showFilter={showFilter}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <FilterExhibition>
                {moment(state[0].startDate).format('LL')} até{' '}
                {moment(state[0].endDate).format('LL')}
              </FilterExhibition>
              <button
                type="button"
                className="buttonFilter"
                title="Exibir filtro"
                onClick={() => setShowFilter(!showFilter)}
              >
                <Funnel weight="bold" />
              </button>
            </div>
            <div className="contentFilter">
              <DateRangePicker
                //@ts-ignore
                onChange={(item) => setState([item.selection])}
                //@ts-ignore
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={state}
                direction="horizontal"
                locale={pt}
                minDate={new Date('2023-05-16T14:04:25.392Z')}
                maxDate={new Date()}
                className="testeColor"
                staticRanges={[
                  {
                    label: 'Ontem',

                    range: () => ({
                      startDate: subDays(new Date(), 1),
                      endDate: new Date(),
                    }),
                    isSelected() {
                      const startDate = moment(subDays(new Date(), 1)).format(
                        'YYYY-MM-DD'
                      );
                      const endDate = moment(new Date()).format('YYYY-MM-DD');
                      if (
                        startDate ===
                          moment(new Date(state[0].startDate)).format(
                            'YYYY-MM-DD'
                          ) &&
                        endDate ===
                          moment(new Date(state[0].endDate)).format(
                            'YYYY-MM-DD'
                          )
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    },
                  },
                  {
                    label: '7 dias',

                    range: () => ({
                      startDate: subDays(new Date(), 7),
                      endDate: new Date(),
                    }),
                    isSelected() {
                      const startDate = moment(subDays(new Date(), 7)).format(
                        'YYYY-MM-DD'
                      );
                      const endDate = moment(new Date()).format('YYYY-MM-DD');
                      if (
                        startDate ===
                          moment(new Date(state[0].startDate)).format(
                            'YYYY-MM-DD'
                          ) &&
                        endDate ===
                          moment(new Date(state[0].endDate)).format(
                            'YYYY-MM-DD'
                          )
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    },
                  },
                  {
                    label: '15 dias',

                    range: () => ({
                      startDate: subDays(new Date(), 15),
                      endDate: new Date(),
                    }),
                    isSelected() {
                      const endDate = moment(new Date()).format('YYYY-MM-DD');
                      const startDate = moment(subDays(new Date(), 15)).format(
                        'YYYY-MM-DD'
                      );
                      if (
                        startDate ===
                          moment(new Date(state[0].startDate)).format(
                            'YYYY-MM-DD'
                          ) &&
                        endDate ===
                          moment(new Date(state[0].endDate)).format(
                            'YYYY-MM-DD'
                          )
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    },
                  },
                  {
                    label: '30 dias',

                    range: () => ({
                      startDate: subDays(new Date(), 30),
                      endDate: new Date(),
                    }),
                    isSelected() {
                      const endDate = moment(new Date()).format('YYYY-MM-DD');
                      const startDate = moment(subDays(new Date(), 30)).format(
                        'YYYY-MM-DD'
                      );
                      if (
                        startDate ===
                          moment(new Date(state[0].startDate)).format(
                            'YYYY-MM-DD'
                          ) &&
                        endDate ===
                          moment(new Date(state[0].endDate)).format(
                            'YYYY-MM-DD'
                          )
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    },
                  },
                  {
                    label: '60 dias',

                    range: () => ({
                      startDate: subDays(new Date(), 60),
                      endDate: new Date(),
                    }),
                    isSelected() {
                      const startDate = moment(subDays(new Date(), 60)).format(
                        'YYYY-MM-DD'
                      );
                      const endDate = moment(new Date()).format('YYYY-MM-DD');
                      if (
                        startDate ===
                          moment(new Date(state[0].startDate)).format(
                            'YYYY-MM-DD'
                          ) &&
                        endDate ===
                          moment(new Date(state[0].endDate)).format(
                            'YYYY-MM-DD'
                          )
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    },
                  },
                  {
                    label: '90 dias',

                    range: () => ({
                      startDate: subDays(new Date(), 90),
                      endDate: new Date(),
                    }),
                    isSelected() {
                      const startDate = moment(subDays(new Date(), 90)).format(
                        'YYYY-MM-DD'
                      );
                      const endDate = moment(new Date()).format('YYYY-MM-DD');
                      if (
                        startDate ===
                          moment(new Date(state[0].startDate)).format(
                            'YYYY-MM-DD'
                          ) &&
                        endDate ===
                          moment(new Date(state[0].endDate)).format(
                            'YYYY-MM-DD'
                          )
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    },
                  },
                  // {
                  //   label: '1 ano',

                  //   range: () => ({
                  //     startDate: subDays(new Date(), 365),
                  //     endDate: new Date(),
                  //   }),
                  //   isSelected() {
                  //     const startDate = moment(subDays(new Date(), 365)).format(
                  //       'YYYY-MM-DD'
                  //     );
                  //     const endDate = moment(new Date()).format('YYYY-MM-DD');
                  //     if (
                  //       startDate ===
                  //         moment(new Date(state[0].startDate)).format(
                  //           'YYYY-MM-DD'
                  //         ) &&
                  //       endDate ===
                  //         moment(new Date(state[0].endDate)).format('YYYY-MM-DD')
                  //     ) {
                  //       return true;
                  //     } else {
                  //       return false;
                  //     }
                  //   },
                  // },
                ]}
                inputRanges={[
                  {
                    label: 'Quantos dias até hoje?',
                    range(value) {
                      return {
                        startDate: addDays(
                          defineds.startOfToday,
                          (Math.max(Number(value), 1) - 1) * -1
                        ),
                        endDate: defineds.endOfToday,
                      };
                    },
                    getCurrentValue(range) {
                      //@ts-ignore
                      if (!isSameDay(range.endDate, defineds.endOfToday))
                        return '-';
                      if (!range.startDate) return '∞';
                      return (
                        differenceInCalendarDays(
                          defineds.endOfToday,
                          range.startDate
                        ) + 1
                      );
                    },
                  },
                ]}
              />
              <button
                className="searchButton"
                onClick={() => {
                  getAnalyticsUser({
                    startDate: state[0].startDate,
                    endDate: state[0].endDate,
                  });
                }}
              >
                Filtrar
              </button>
            </div>
          </ContentFilterDate>

          {dataAnalytics &&
            dataAnalytics.sections.length > 0 &&
            dataAnalytics.sections.map((section) => (
              <div key={section.title}>
                <Heading as="h4" variant="quaternary" color="white">
                  {section.title}
                </Heading>
                <br />

                <ContentCards>
                  {section.cards.map((card) => (
                    <Card key={card.display_name}>
                      <header>
                        <Heading as="h6" variant="sextiary" color="white">
                          {card.display_name}
                        </Heading>
                      </header>
                      <Text color="white">
                        {' '}
                        {loadingSearch ? (
                          <LoadingDashAdminInfos />
                        ) : card.display_name === 'Investimento' ? (
                          transformMaskCurrency(card.value)
                        ) : card.value.toString().includes('.') ? (
                          card.value.toFixed(2)
                        ) : (
                          card.value
                        )}
                      </Text>
                    </Card>
                  ))}
                </ContentCards>
                <br />
              </div>
            ))}

          <br />

          <TableCampaign
            body={dataAnalytics.campanhas_array}
            header={[
              'Campanhas',
              'Gasto',
              'Impressões',
              'Clicks',
              'CPM',
              'CTR',
              'CPC',
              'CPA',
            ]}
          />

          <br />
          <Heading as="h4" variant="quaternary" color="white">
            Google Analytics{' '}
          </Heading>

          {dataAnalytics.access_by_page &&
            dataAnalytics.access_by_page.length > 0 && (
              <>
                <br />
                <GraphicAccessForPage
                  data={dataAnalytics.access_by_page}
                  loading={loadingSearch}
                />
              </>
            )}

          {dataInfoUser.users && (
            <>
              <br />
              <DataInfoUsers data={dataInfoUser} loading={loadingSearch} />
            </>
          )}
          {dataPlatform.data.desktop && (
            <>
              <br />
              <GraphicTypePlatform
                data={dataPlatform}
                loading={loadingSearch}
              />
            </>
          )}
          {dataNewUsersAccess.data.length > 0 && (
            <>
              <br />
              <GraphicNewUsers
                data={dataNewUsersAccess}
                loading={loadingSearch}
              />
            </>
          )}
        </>
      )}
    </DashboardAdminContainer>
  );
}

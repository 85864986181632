import ScrollContainer from 'react-indiana-drag-scroll';
import styled from 'styled-components';

export const AnalysisContainer = styled.div`
  display: flex;
  width: 150%;
  margin-left: -25%;
  padding: 16px 0px 24px 0px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: none;
  border: none;
`;

export const HeaderPageContent = styled.div`
  display: flex;
  padding: var(--Spacing-32, 32px) 16px 0px 16px;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  margin-top: 1%;
  
  .backPage{
    border-radius:  none; //var(--Spacing-8, 8px);
    border: none; //1px solid var(--Gray-gray-100, #D4D6DB);
    background: none;
    width: 100%;
    height: 100%;
    z-index: 2;
    position: relative;
  }
  span {
    width: 100%;
    max-width: 18.75rem;
    color: ${(props) => props.theme['gray-400']};
    font-weight: 500;
    font-size: 1rem;
  }
  .comparador {
    margin-top: 7%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
    align-self: center; 
    width: 100%; 
    max-width: 200px; 
    margin-left: auto; 
    margin-right: auto; 
  }
`;

export const TabContent = styled(ScrollContainer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  background-color: none;
  margin-top: -0.1%;
  margin-bottom: 0;
  margin-left: 25%;
  border-radius: none;
  border: none;
  position: absolute;


  @media (max-width: 480px) {
    gap: 0;
  }
`;

interface IPropsButton {
  isActive: boolean;
}

export const ButtonTab = styled.button<IPropsButton>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  all: unset;
  background: ${(props) =>
    props.isActive ? '#fff' : '#F7F7F8'};
  padding: 0.675rem 0.875rem;
  border-top: ${(props) =>
    props.isActive ? '3px solid #EC6643' : '1px solid #D4D6DB'};
  border-bottom: ${(props) =>
    props.isActive ? 'transparent' : '1px solid #D4D6DB'};
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-right: 1px solid var(--Gray-gray-100, #D4D6DB);
  border-left: 1px solid var(--Gray-gray-100, #D4D6DB);
  width: 100%;
  max-width: auto;
  white-space: nowrap;
  font-weight: 500;
  font-size: 1.125rem;
  text-align: center;
  color: ${(props) =>
    props.isActive ? '#EC6643' : props.theme['gray-300']};
  margin-bottom: 0%;

  @media (max-width: 480px) {
    /* padding: 1rem 0.5rem; */
    font-size: 0.75rem;
  }
`;

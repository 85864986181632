import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 4px;
  box-shadow: ${(props) => props.theme['card-shadow-2']};
  padding: 1rem;
  background: ${(props) => props.theme.white};
  height: 100%;
  border-radius: var(--Spacing-8, 8px);
  border: 1px solid var(--Gray-gray-100, #D4D6DB);
  background: #FFF;

  > header {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: ${(props) => props.theme.border};
    padding-bottom: 1rem;

    h1 {
      font-size: 1.7rem;
      margin: 0;
      margin-bottom: 5px;
      color: #303F4B;
    }
    h6 {
      color: #303F4B;
      font-size: 1.3rem;
      font-weight: 500;
    }
  }

  main {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    header {
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        font-size: 1.5rem;
      }
    }
    span {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 5px;
      font-size: 1rem;
      line-height: 1.8;
      strong {
        color: #3f3e40;
      }
    }
  }

  footer {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    button {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    p {
      max-width: 300px;
      a {
        color: ${(props) => props.theme.primary};
      }
    }
  }
`;

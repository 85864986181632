import styled from 'styled-components';

export const ContainerFormQuestion = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ContentInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  label {
    font-size: 0.875rem;
    margin: 0;
    color: ${(props) => props.theme['gray-700']};
  }
  input,
  textarea,
  select {
    padding: 0.5rem;
    border: ${(props) => props.theme.border};
    border-radius: 4px;
    color: ${(props) => props.theme['gray-700']};
  }
`;

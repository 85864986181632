//ROTAS MIGRADAS
import store from '../store/store';

import axios from 'axios';
import * as Constants from '../Constants';

//const API_URL = ${process.env.REACT_APP_API_URL};
const API_URL = Constants.api;

export default class CarteiraConsolidadaService {
  constructor() {}

  //getAssets(nomeAsset){
  //      const url = `${API_URL}/DashFinance/?search=` + nomeAsset;
  //	return axios.get(url).then(response => response.data);

  //  }

  getCarteiraConsolidada() {
    const url = `${API_URL}/api/carteiraconsolidada/`;
    return axios.get(url).then((response) => response.data);
  }

  addCarteira(carteira) {
    const url = `${API_URL}/api/carteira/`;
    return axios.post(url, carteira);
  }

  getCarteira() {
    const url = `${API_URL}/api/carteira/`;

    return axios.get(url).then((response) => response.data);
  }

  async getAllAssetsBase(query) {
    const tokenDetailsString = localStorage.getItem(
      process.env.REACT_APP_USER_VERSION
    );

    const state = store.getState();
    const token = state.auth.auth.access;

    //  const data = await axios.get('http://localhost:8000/Finance/todosativos/',
    const data = await axios.get(
      API_URL + '/Finance/todosativos?search=' + query,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return data.data;
  }

  async getFIIs(codfii) {
    const tokenDetailsString = localStorage.getItem(
      process.env.REACT_APP_USER_VERSION
    );

    const state = store.getState();
    const token = state.auth.auth.access;

    const data = await axios.get(API_URL + '/Finance/fiis?search=' + codfii, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return data.data;
  }

  async getAllAssets() {
    let assets = [];
    const tokenDetailsString = localStorage.getItem(
      process.env.REACT_APP_USER_VERSION
    );

    const state = store.getState();
    const token = state.auth.auth.access;

    const data = await axios.get(API_URL + '/Finance/fiis', {
      headers: { Authorization: `Bearer ${token}` },
    });

    return data.data;
  }

  async getDividendos(codfii) {
    const state = store.getState();
    const token = state.auth.auth.access;

    const data = await axios.get(
      API_URL + '/Finance/historico_rendimento_fii?search=' + codfii,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return data.data;
  }

  async getDistratos(codfii) {
    const state = store.getState();
    const token = state.auth.auth.access;

    const data = await axios.get(
      API_URL + '/Finance/distratos?search=' + codfii,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return data.data;
  }

  async getImoveis(codfii) {
    const state = store.getState();
    const token = state.auth.auth.access;

    const data = await axios.get(
      API_URL + '/Finance/imoveis?codigo_fii=' + codfii,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return data.data;
  }

  async getFatosRelevantes(ativo) {
    const tokenDetailsString = localStorage.getItem(
      process.env.REACT_APP_USER_VERSION
    );
    const state = store.getState();
    const token = state.auth.auth.access;

    const data = await axios.get(
      API_URL + '/Finance/fatosrelevantes?search=' + ativo.replace('.SA', ''),
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return data.data;
  }
  async getFatosRelevantesParams(params = null) {
    const tokenDetailsString = localStorage.getItem(
      process.env.REACT_APP_USER_VERSION
    );
    const state = store.getState();
    const token = state.auth.auth.access;

    const data = await axios.get(
      API_URL + '/Finance/fatosrelevantes',
      { params },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    return data.data;
  }

  async getFatosRelevantesFII(codigo_fii) {
    const tokenDetailsString = localStorage.getItem(
      process.env.REACT_APP_USER_VERSION
    );
    const state = store.getState();
    const token = state.auth.auth.access;

    const data = await axios.get(
      API_URL + '/Finance/fatosrelaventesfii?search=' + codigo_fii,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return data.data;
  }

  async getVacanciaConsolidada(codfii) {
    const state = store.getState();
    const token = state.auth.auth.access;

    const data = await axios.get(
      API_URL + '/Finance/vacanciaconsolidada?search=' + codfii,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return data.data;
  }

  async getResultadosFIIs(codfii) {
    const state = store.getState();
    const token = state.auth.auth.access;

    const data = await axios.get(
      API_URL + '/Finance/fiiresultados?search=' + codfii,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return data.data;
  }

  async getResultadosSetorFIIs(params = null) {
    const state = store.getState();
    const token = state.auth.auth.access;

    const data = await axios.get(
      API_URL + '/Finance/fiiresultadosetor',
      { params },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    return data.data;
  }

  async getConfiguracaoCarteira() {
    const state = store.getState();
    const token = state.auth.auth.access;
    const cpf = state.auth.auth.user.cpf;

    const data = await axios.get(
      API_URL + '/Finance/getConfCarteira?search=' + cpf,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return data.data;
  }

  setConfiguracaoCarteira() {
    const state = store.getState();
    const token = state.auth.auth.access;
    const cpf = state.auth.auth.user.cpf;

    const postData = {
      cpf,
    };
    return axios.post(API_URL + '/Finance/updateConfCarteira/', postData, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  async getPosicaoCarteira() {
    const state = store.getState();
    const token = state.auth.auth.access;
    const cpf = state.auth.auth.user.cpf;

    const data = await axios.get(
      API_URL + '/Finance/posicaocarteira?search=' + cpf,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return data.data;
  }

  async getPosicao() {
    const state = store.getState();
    const token = state.auth.auth.access;
    const cpf = state.auth.auth.user.cpf;

    const data = await axios.get(API_URL + '/Finance/posicao?search=' + cpf, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data.data;
  }

  async getClosePriceFIIs(codfii) {
    const state = store.getState();
    const token = state.auth.auth.access;

    const data = await axios.get(
      API_URL + '/Finance/precosFII?search=' + codfii,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return data.data;
  }

  async getPrecosAtivo(codfii) {
    const state = store.getState();
    const token = state.auth.auth.access;

    const data = await axios.get(
      API_URL + '/Finance/precosAtivo?search=' + codfii,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return data.data;
  }

  async getFundamentalista(codfii) {
    const state = store.getState();
    const token = state.auth.auth.access;
    const data = await axios.get(
      API_URL + '/Finance/fundamentalista?ativo=' + codfii + '.SA',
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return data.data;
  }

  async getAllDividendos(codfii) {
    const state = store.getState();
    const token = state.auth.auth.access;

    const data = await axios.get(API_URL + '/Finance/dividendo', {
      headers: { Authorization: `Bearer ${token}` },
    });

    return data.data;
  }

  async getDividendo(codfii) {
    const state = store.getState();
    const token = state.auth.auth.access;

    const data = await axios.get(
      API_URL + '/Finance/dividendo?search=' + codfii,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return data.data;
  }

  async getAssets(codfii) {
    const state = store.getState();
    const token = state.auth.auth.access;

    const data = await axios.get(API_URL + '/Finance/assets?search=' + codfii, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return data.data;
  }

  async getSetoresAtuacaoFII(codigo_fii) {
    const state = store.getState();
    const token = state.auth.auth.access;

    const data = await axios.get(
      API_URL + '/Finance/setoresfii?codigo_fii=' + codigo_fii,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return data.data;
  }

  async getSetores() {
    const state = store.getState();
    const token = state.auth.auth.access;

    const data = await axios.get(API_URL + '/Finance/setores', {
      headers: { Authorization: `Bearer ${token}` },
    });

    return data.data;
  }
  async getSegmentos() {
    const state = store.getState();
    const token = state.auth.auth.access;

    const data = await axios.get(API_URL + '/Finance/segmentos', {
      headers: { Authorization: `Bearer ${token}` },
    });

    return data.data;
  }

  async getEmpresas(codfii) {
    const state = store.getState();
    const token = state.auth.auth.access;

    if (codfii.length < 14) {
      codfii = codfii.padStart(14, '0');
    }
    const data = await axios.get(
      API_URL + '/Finance/empresas?search=' + codfii,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return data.data;
  }

  async getCnpjasset(codfii) {
    const state = store.getState();
    const token = state.auth.auth.access;

    codfii = codfii.substring(0, 4);
    const data = await axios.get(
      API_URL + '/Finance/cnpjasset?search=' + codfii,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return data.data;
  }

  async getChartConsolidado(codacao) {
    const state = store.getState();
    const token = state.auth.auth.access;

    const data = await axios.get(
      API_URL + '/Finance/chartconsolidado?search=' + codacao,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return data.data;
  }

  async getconsolidadosegmento(params) {
    const state = store.getState();
    const token = state.auth.auth.access;

    const data = await axios.get(
      API_URL + '/Finance/consolidadosegmento',
      { params },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return data.data;
  }
  async getconsolidadosegmentoText(text) {
    const state = store.getState();
    const token = state.auth.auth.access;

    const data = await axios.get(
      API_URL + `/Finance/consolidadotextsearch?search=${text}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return data.data;
  }
  async getconsolidadoFiiText(text) {
    const state = store.getState();
    const token = state.auth.auth.access;

    const data = await axios.get(
      API_URL + `/Finance/fiiresultadotextsearch?search=${text}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return data.data;
  }
}

import axios from 'axios';
import { useEffect, useState } from 'react';
import logo from '../../../../../../../assets/Feedback.png';
import { ContainerFeedbackUser, Content } from './styles';
import { administrationController } from '../../../../../../../utils/RoutesApi';
import { IPropsQuestionsFeedback } from '../../../../../../../utils/@types/questionsFeedback';

import { FormFeedbackUser } from './components/FormFeedbackUser';
import { ModalDesigner } from '../../../../../../../components/ModalDesigner';

const baseUrl = process.env.REACT_APP_API_URL;

export function FeedbackUser() {
  const [questions, setQuestions] = useState<IPropsQuestionsFeedback[]>([]);
  const [showModalFeedback, setShowModalFeedback] = useState(false);

  useEffect(() => {
    getQuestionsFeedback();
  }, []);

  async function getQuestionsFeedback() {
    try {
      const response = await axios.get(
        `${baseUrl}${administrationController.QUESTIONS_FEEDBACK}/`
      );
      setQuestions(
        response.data.filter(
          (question: IPropsQuestionsFeedback) => question.active
        )
      );
    } catch (err) {}
  }

  return (
    <>
      <ContainerFeedbackUser onClick={() => setShowModalFeedback(true)}>
        <img src={logo} alt="" width={'100%'} />
      </ContainerFeedbackUser>
      <ModalDesigner
        size="sm"
        id="feedback"
        isOpen={showModalFeedback}
        setState={setShowModalFeedback}
        title="Feedback"
      >
        <FormFeedbackUser
          questions={questions}
          onCloseFeedback={() => setShowModalFeedback(false)}
        />
      </ModalDesigner>
    </>
  );
}

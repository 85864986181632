import { ButtonHTMLAttributes } from 'react';
import { Container } from './styles';

interface IPropsButtonComponent
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'link';
}

export function ButtonComponent({
  variant = 'primary',
  children,
  ...props
}: IPropsButtonComponent) {
  return (
    <Container variant={variant} {...props}>
      {children}
    </Container>
  );
}

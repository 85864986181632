import styled from 'styled-components';

interface IPropsContainerBannerLastVideo {
  urlImg?: string;
  video?: {};
}

export const Container = styled.div<IPropsContainerBannerLastVideo>`
  display: ${(props) => (props.video ? 'grid' : 'none')};
  grid-template-columns: 1fr;
  z-index: 1;
  height: 300px;
  margin-bottom: 1rem;

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column-reverse;
    height: 200px;
  }
`;

interface IPropsContent {
  isShortBar: boolean;
}

export const Content = styled.div<IPropsContent>`
  padding: 1rem 0 0 6rem;
  display: flex;
  flex-direction: column;
  background: #212121;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  border-radius: 4px;
  position: relative;
  box-shadow: ${(props) => props.theme['card-shadow-2']};
  h1 {
    color: white;
  }
  span {
    background-color: red;
    color: #fff;
    padding: 0.1rem;
  }
  a {
    background-color: #f8b700;
    font-size: 1rem;
    color: #fff;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    align-items: center;

    strong {
      font-weight: 400;
    }
  }
  .descriptionText {
    max-width: 80ch;
    overflow-y: auto;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    max-height: 100px;
  }
  transition: all 1s;

  .contentLogo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: absolute;
    right: 5rem;
    z-index: 1;

    img {
      width: 300px;
    }
  }

  .logo {
    right: 0;
    bottom: 0;
    position: absolute;
    width: 250px;
    opacity: 0.3;
  }

  @media (max-width: 1000px) {
    .contentLogo {
      height: 260px;
      justify-content: ${(props) => (props.isShortBar ? 'flex-end' : 'center')};
      right: ${(props) => props.isShortBar && '2rem'};
    }
  }

  @media (max-width: 800px) {
    padding: 1rem;
    height: 200px;

    .logo {
      width: 150px;
    }

    .contentLogo {
      right: 1rem;
      justify-content: center;
      height: 100%;
      img {
        width: ${(props) => props.isShortBar && '200px'};
      }
    }
  }

  @media (max-width: 675px) {
    .contentLogo {
      right: 1rem;

      img {
        width: 200px;
      }
    }

    .logo {
      /* display: none; */
    }
  }
  @media (max-width: 560px) {
    .contentLogo img {
      width: 150px;
    }
  }
  @media (max-width: 450px) {
    .contentLogo {
      height: 170px;
      align-items: end;
      justify-content: flex-end;

      img {
        width: 150px;
      }
    }
  }
  @media (max-width: 375px) {
    .contentLogo {
      right: 0;

      img {
        width: 130px;
      }
    }
  }
`;

// export const Thumb = styled.div<IPropsContainerBannerLastVideo>`
//   width: 100%;
//   height: 100%;
//   border-radius: 0 8px 8px 0;
//   background: ${(props) =>
//     props.urlImg ? `black url(${props.urlImg})` : '#212121'}
//     no-repeat center;
//   -webkit-box-shadow: inset 80px 0 100px 0 rgba(33, 33, 33, 1),
//     inset 80px 0 50px 0 rgba(33, 33, 33, 0.9);

//   box-shadow: inset 80px 0 100px 0 rgba(33, 33, 33, 1),
//     inset 80px 0 50px 0 rgba(33, 33, 33, 0.9);
//   max-height: 300px;
//   object-fit: cover;
//   position: relative;

//   h1 {
//     display: none;
//     color: white;
//   }
//   span {
//     display: none;
//     background-color: red;
//     color: #fff;
//     padding: 0.1rem;
//   }

//   transition: all 1s;

//   @media (max-width: 800px) {
//     border-radius: 8px 8px 0 0;
//     height: 100%;
//     max-height: 300px;
//     -webkit-box-shadow: inset 0 0 100px 10px rgba(33, 33, 33, 1),
//       inset 0px -80px 100px 10px rgba(33, 33, 33, 0.9);

//     box-shadow: inset 0 0 100px 10px rgba(33, 33, 33, 1),
//       inset 0px -80px 100px 10px rgba(33, 33, 33, 0.9);

//     span {
//       position: absolute;
//       top: 100px;
//       z-index: 10;
//       display: flex;
//       margin-left: 1rem;
//     }

//     h1 {
//       margin-left: 1rem;
//       margin-right: 1rem;
//       display: flex;
//       position: absolute;
//       top: 150px;
//       z-index: 1;
//     }
//   }
// `;

import moment from 'moment';
import ReactApexChart from 'react-apexcharts';
import { Modal } from '../../../Modal';
import { IPropsVacancia } from '../../@types/VacanciaProps';

interface IPropsGraphicVacancia {
  dataGraphic: IPropsVacancia[];
  state: boolean;
  setState: Function;
  title: string;
  slug: string;
}

export function GraphicVacancia({
  dataGraphic,
  setState,
  state,
  title,
  slug,
}: IPropsGraphicVacancia) {
  const graphicData = {
    series: [
      {
        name: 'Vacância Física',
        data: dataGraphic.map((item) => item.ValorPercVacanciaFisica),
        type: 'column',
      },

      {
        name: 'Ocupaçao Física',
        data: dataGraphic.map((item) => item.ValorPercOcupacao),
        type: 'column',
      },
      {
        name: 'Vacância Financeira',
        data: dataGraphic.map((item) => item.ValorPercVacanciaFinanceira),
        type: 'column',
      },
    ],
    options: {
      chart: {
        type: 'bar',
      },
      stroke: {
        width: [1, 1, 4],
      },
      dataLabels: {
        enabled: false,
      },

      xaxis: {
        categories: dataGraphic.map((item) =>
          moment(item.DataVancancia).format('MM/YYYY')
        ),
      },

      yaxis: {
        labels: {
          formatter: function (val: any) {
            if (!val) {
              return 'N/D';
            }
            return val ? val.toFixed(2) + '%' : '';
          },
        },
      },

      responsive: [
        {
          breakpoint: 1200,
          options: {
            chart: {
              width: '700px',
              height: '400px',
            },
          },
        },
        {
          breakpoint: 992,
          options: {
            chart: {
              width: '400px',
              height: '400px',
            },
          },
        },
        {
          breakpoint: 480,
          options: {
            chart: {
              width: '300px',
              height: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  };

  return (
    state && (
      <Modal id="vacancia" title={title} setState={setState} width="auto">
        <ReactApexChart
          //@ts-ignore
          options={graphicData.options}
          series={graphicData.series}
          type="line"
          height={400}
          width={1000}
        />
      </Modal>
    )
  );
}

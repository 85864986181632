import styled from 'styled-components';

export const ResetTrialContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    label {
      display: flex;
      flex-direction: column;
      margin: 0;
      gap: 0.25rem;
      color: ${(props) => props.theme['gray-700']};
      input {
        border: ${(props) => props.theme.border};
        border-radius: 4px;
        padding: 0.5rem;
      }
    }

    .buttonContent {
      display: flex;
      flex-wrap: wrap;
      gap: 0.875rem;
    }
  }
`;

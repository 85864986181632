import axios from 'axios';
import { useState } from 'react';
import { useActives } from '../../hooks/useActives';
import { useTitleAndUser } from '../../hooks/userTitle';
import { B3, financeRoutes } from '../../utils/RoutesApi';
import { handleToast } from '../Toast';
import { Container } from './styles';
import { Switch } from 'antd';

export function SwitchAutoImport() {
  //@ts-ignore
  const getDataUser = JSON.parse(
    //@ts-ignore
    localStorage.getItem(process.env.REACT_APP_USER_VERSION)
  );
  //@ts-ignore
  const { user, setUser } = useTitleAndUser();
  //@ts-ignore
  const { setActives, setPercentage } = useActives();

  const [checked, setChecked] = useState<boolean>(user.automatico);

  async function handleChangeTheStateOfTheWallet(isAutomatic: boolean) {
    let data = {
      automatico: isAutomatic,
    };

    await axios
      .post(`${process.env.REACT_APP_API_URL}${B3.WALLET_AUTOMATIC}`, data)
      .then((response) => {
        setChecked(isAutomatic);

        let res = {
          ...response.data.user,
          data_trial: getDataUser.dias_trial,
          step: 3,
        };
        if (res) {
          let isIncludeImage = false;
          if (res.profile.image) {
            const includeImage = res.profile.image.match(
              'https://storage.googleapis.com/ibox6-wordpress.appspot.com/'
            );

            isIncludeImage = !!includeImage;
          }

          let isIncludeBanner = false;

          if (res.profile.bannet) {
            const includeBanner = res.profile.banner.match(
              'https://storage.googleapis.com/ibox6-wordpress.appspot.com/'
            );

            isIncludeBanner = !!includeBanner;
          }

          if (isIncludeImage || isIncludeBanner) {
            let obj = {
              ...getDataUser,

              user: res,
            };
            localStorage.setItem(
              //@ts-ignore
              process.env.REACT_APP_USER_VERSION,
              JSON.stringify(obj)
            );
            setUser(obj.user);
            handleToast(false, response.data.message);

            axios
              .get(
                `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_POSITION}`
              )
              .then(function (result) {
                setActives(result.data);
                let act = result.data.sumAcao.toFixed(2);
                let fii = result.data.sumsFII.toFixed(2);
                let rf = result.data.sumsEFT.toFixed(2);
                const total = Number(act) + Number(fii) + Number(rf);

                let actionPercentage =
                  result.data.sumAcao === 0
                    ? 0
                    : Number((result.data.sumAcao / total) * 100);
                actionPercentage = Number(actionPercentage.toFixed(2));

                let fiiPercentage =
                  result.data.sumsFII === 0
                    ? 0
                    : (result.data.sumsFII / total) * 100;

                fiiPercentage = Number(fiiPercentage.toFixed(2));
                let rfPercentage =
                  result.data.sumsEFT === 0
                    ? 0
                    : (result.data.sumsEFT / total) * 100;

                rfPercentage = Number(rfPercentage.toFixed(2));

                let newPercentage = {
                  fii: fiiPercentage,
                  action: actionPercentage,
                  rendaFixa: rfPercentage,
                };
                setPercentage(newPercentage);
              })
              .catch(function (error) {});

            return;
          }

          if (res.profile.image) {
            res.profile.image =
              process.env.REACT_APP_API_URL +
              res.profile.image.replace(`${process.env.REACT_APP_API_URL}`, '');
          }
          if (res.profile.banner) {
            res.profile.banner =
              process.env.REACT_APP_API_URL +
              res.profile.banner.replace(
                `${process.env.REACT_APP_API_URL}`,
                ''
              );
          }

          let obj = {
            ...getDataUser,

            user: res,
          };
          localStorage.setItem(
            //@ts-ignore
            process.env.REACT_APP_USER_VERSION,
            JSON.stringify(obj)
          );
          setUser(obj.user);
          handleToast(false, response.data.message);

          axios
            .get(
              `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_POSITION}`
            )
            .then(function (result) {
              setActives(result.data);
              let act = result.data.sumAcao.toFixed(2);
              let fii = result.data.sumsFII.toFixed(2);
              let rf = result.data.sumsEFT.toFixed(2);
              const total = Number(act) + Number(fii) + Number(rf);

              let actionPercentage =
                result.data.sumAcao === 0
                  ? 0
                  : Number((result.data.sumAcao / total) * 100);
              actionPercentage = Number(actionPercentage.toFixed(2));

              let fiiPercentage =
                result.data.sumsFII === 0
                  ? 0
                  : (result.data.sumsFII / total) * 100;

              fiiPercentage = Number(fiiPercentage.toFixed(2));
              let rfPercentage =
                result.data.sumsEFT === 0
                  ? 0
                  : (result.data.sumsEFT / total) * 100;

              rfPercentage = Number(rfPercentage.toFixed(2));

              let newPercentage = {
                fii: fiiPercentage,
                action: actionPercentage,
                rendaFixa: rfPercentage,
              };

              setPercentage(newPercentage);
            })
            .catch(function (error) {});

          return;
        }
      })
      .catch((err) => {
      });
  }

  return (
    <Container>
      <p>Importação automática</p>
      <Switch
        checked={checked}
        onClick={() => handleChangeTheStateOfTheWallet(!checked)}
        checkedChildren="ON"
        unCheckedChildren="OFF"
      />
    </Container>
  );
}

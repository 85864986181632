import { List, X } from '@phosphor-icons/react';
import {
  MenuMobileContainer,
  MenuMobileContent,
  Button,
  NavContent,
} from './mobileMenuStyles';
import { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { logoWhiteImg } from '../../../../../Constants/images';

interface IPropsScreen {
  onShowRegisterScreen: () => void;
}

export function MenuMobile({ onShowRegisterScreen }: IPropsScreen) {
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  return (
    <MenuMobileContainer>
      <button
        className="menuButton"
        type="button"
        onClick={() => setShowMenu(!showMenu)}
      >
        <List />
      </button>
      <MenuMobileContent isShowMenu={showMenu}>
        <div className="logoAndClose">
          <img src={logoWhiteImg} alt="" />
          <button
            className="menuButton"
            type="button"
            onClick={() => setShowMenu(!showMenu)}
          >
            <X />
          </button>
        </div>
        <NavContent>
          <header>
            <Button isLoginButton onClick={() => history.push('/login')}>
              Login
            </Button>
            <Button type="button" onClick={onShowRegisterScreen}>
              Acesse gratis
            </Button>
          </header>
          <ul>
            <li>
              <a href="#comunidade">Comunidade</a>
            </li>
            <li>
              <a href="#educacao">Educação</a>
            </li>
            <li>
              <a href="#plataforma">Plataforma</a>
            </li>
          </ul>
        </NavContent>
      </MenuMobileContent>
    </MenuMobileContainer>
  );
}

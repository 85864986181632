import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  height: 100%;
  transition: all 0.3s;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    strong {
      color: ${(props) => props.theme.white};
    }
  }

  .ant-menu-submenu-arrow {
    color: #fff;
  }
  .ant-menu-sub {
    background: transparent;
    color: #fff;
  }

  .searchContainer {
    position: relative;
    margin-top: 1rem;
    svg {
      position: absolute;
      top: 7px;
      left: 10px;
    }
  }
  .searchInput {
    width: 100%;
    border: 1px solid ${(props) => props.theme.white};
    background-color: transparent;
    padding: 0.5rem 0.5rem 0.5rem 3rem;
    border-radius: 8px;
  }
  .ant-menu-item-selected {
    color: ${(props) => props.theme.primary};
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: transparent;
  }
  .ant-menu-submenu:hover
    > .ant-menu-submenu-title
    > .ant-menu-submenu-expand-icon,
  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: ${(props) => props.theme.primary};
  }

  .containerList {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
    transition: all 1s;
    overflow-y: auto;

    .contentList {
      color: ${(props) => props.theme.white};
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: space-between;
      transition: filter 0.3s;
      cursor: pointer;
      transition: all 1s;
      span {
        color: ${(props) => props.theme.white};
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        gap: 10px;
      }

      :hover {
        filter: brightness(0.9);
      }
    }

    transition: all 1s;
  }
`;
interface IPropsSubList {
  show: boolean;
}
export const SubList = styled.ul<IPropsSubList>`
  color: ${(props) => props.theme.white};
  margin-left: 1rem;

  display: flex;
  height: auto;
  opacity: ${(props) => (props.show ? '1' : '0')};
  height: ${(props) => (props.show ? 'auto' : '0')};
  flex-direction: column;
  gap: 1rem;

  transition: all 1s;
  li {
    transition: all 0.3s;
    opacity: ${(props) => (props.show ? '1' : '0')};

    display: ${(props) => (props.show ? 'flex' : 'none')};
  }
`;

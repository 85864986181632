import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { videosRoutes } from '../../../utils/RoutesApi';
import { Container, Content } from './styles';
import logoFullWhiteImg from '../../../assets/logoWhite.svg';
import { useNewTheme } from '../../../hooks/useNewTheme';
import { boxLogoLogin } from '../../../Constants/images';

interface IPropsVideos {
  category: number;
  channel: number;
  channelThumbnail: string;
  channelTitle: string;
  comment: number[];
  date: string;
  description: string;
  dislike: number[];
  id: number;
  like: number[];
  thumbnail: string;
  title: string;
  video_id: string;
  view: number[];
}

export function BannerLastVideo() {
  //@ts-ignore
  const { shortSidebar } = useNewTheme();
  const [video, setVideo] = useState<IPropsVideos>();

  useEffect(() => {
    handleGetLastFiveVideos();
  }, []);

  function handleGetLastFiveVideos() {
    axios
      .get(`${process.env.REACT_APP_API_URL}${videosRoutes.LAST_FIVE_VIDEOS}`)
      .then((response) => {
        setVideo(response.data[0]);
      })
      .catch((error) => {});
  }

  return (
    <Container urlImg={video?.thumbnail} video={video}>
      <Content isShortBar={shortSidebar}>
        <span>Conteúdo novo</span>
        <h1>{video?.title}</h1>
        <p className="descriptionText" title={video?.description}>
          {video?.description}
        </p>
        <Link to={`watch/?v=${video?.video_id}`}>
          {' '}
          <strong>&rsaquo;</strong> Assistir à aula
        </Link>
        <div className="contentLogo">
          <img src={logoFullWhiteImg} alt="Logo Ibox" />
        </div>
        <img src={boxLogoLogin} alt="Logo Ibox" className="logo" />
      </Content>
    </Container>
  );
}

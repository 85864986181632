import ReactApexChart from 'react-apexcharts';
import { ContentPercentNota } from '../NewTableCompareAssets/styles';
import { Tooltip } from 'antd';
import { useState } from 'react';
import { ExplanationAssetNotes } from '../../../../../../components/ResumoAcao/components/ExplanationAssetNotes';
import { ModalDesigner } from '../../../../../../components/ModalDesigner';

interface IPropsGraphicNote {
  active: {
    LucroLiquido: number;
    ROE: number;
    EBITDA: number;
    Dividendos: number;
    DividaLiquida: number;
    DividaLiquidaEbitida: number;
    Resiliencia: number;
    Governanca: number;
    Indices: number;
    total_chart: number;
  };
}

export function GraphicNote({ active }: IPropsGraphicNote) {
  const [showExplanationAssetNotes, setShowExplanationAssetNotes] =
    useState<boolean>(false);

  const labels = [
    'Lucro Líquido',
    'ROE',
    'EBITDA',
    'Dividendos',
    'Divida Líquida',
    'Divida Líquida EBITDA',
    'Resiliencia',
    'Governança',
    'Índices',
  ];

  const compare = {
    options: {
      chart: {
        height: 390,
        type: 'donut',
      },
      yaxis: {
        labels: {
          //@ts-ignore
          formatter: (val) => `${val}%`,
        },
      },
      grid: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },

      legend: {
        show: false,
        position: 'bottom',
      },

      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: false,
              total: {
                show: false,
                label: 'Media',
                // formatter: () => `${active.total_chart.toFixed(0)}%`,
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
        //@ts-ignore
        formatter: (val) => `${val.toFixed(0)}%`,
      },
      tooltip: {
        fixed: {
          position: 'bottom',
        },

        y: {
          //@ts-ignore
          formatter: (val) => {
            return `${val.toFixed(0)}%`;
          },
          title: {
            //@ts-ignore
            formatter: (titleValue) => {
              return titleValue;
            },
          },
        },
        x: {
          //@ts-ignore
          formatter: (value) => {
            return 'Eixo x do gráfico';
          },
        },
      },

      labels: labels,
    },
  };

  return (
    <>
      <ContentPercentNota onClick={() => setShowExplanationAssetNotes(true)}>
        <ReactApexChart
          //@ts-ignore
          options={compare.options}
          series={[
            active.LucroLiquido,
            active.ROE,
            active.EBITDA,
            active.Dividendos,
            active.DividaLiquida,
            active.DividaLiquidaEbitida,
            active.Resiliencia,
            active.Governanca,
            active.Indices,
          ]}
          type="donut"
          width={100}
        />
        <Tooltip title="Essa é a nota da empresa com base na média das nossas avaliações. Basta clicar no gráfico para ver a explicação de cada uma das notas">
          <h6>{active.total_chart.toFixed(0)}%</h6>
        </Tooltip>
      </ContentPercentNota>
      <ModalDesigner
        id="analisenota"
        isOpen={showExplanationAssetNotes}
        setState={setShowExplanationAssetNotes}
        title="Explicação das notas"
        size="lg"
        fullSize
      >
        <ExplanationAssetNotes />
      </ModalDesigner>
    </>
  );
}

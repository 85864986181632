export const defaultTheme = {
  //colors
  white: '#fff',
  black: '#000',
  border: '1px solid rgba(21,21,21,0.3)',
  primary: '#2469a9',
  'primary-100': '#83C4FF',
  'primary-200': '#6EBAFF',
  'primary-300': '#5AB0FF',
  'primary-400': '#42A5FF',
  'primary-500': '#2497FF',
  'primary-600': '#0B8AFF',
  'primary-700': '#0382F6',
  'primary-800': '#0176E1',
  'text-body': '#697D8C',
  'gray-100': '#909090',
  'gray-200': '#808080',
  'gray-300': '#707070',
  'gray-400': '#606060',
  'gray-500': '#505050',
  'gray-600': '#404040',
  'gray-700': '#303030',
  'gray-800': '#202020',
  'gray-900': '#111111',
  'gray-n-50': '#f9f9f9',
  'gray-n-100': '#f3f3f4',
  'gray-n-200': '#e3e3e6',
  'gray-n-300': '#d3d3d7',
  'gray-n-400': '#a0a0a9',
  'gray-n-500': '#71717a',
  'gray-n-600': '#52525b',
  'gray-n-700': '#3f3f46',
  'gray-n-800': '#27272a',
  'gray-n-900': '#18181b',
  'white-100': '#FFFFFF',
  'white-200': '#EFEFEF',
  'white-300': '#E1E1E1',
  'white-400': '#CECECE',
  'white-500': '#C1C1C1',
  'white-600': '#B6B6B6',
  'white-700': '#ABABAB',
  'white-800': '#9B9B9B',
  'orange-50': '#fef8f6',
  'orange-100': '#fce4d8',
  'orange-200': '#f8c5b0',
  'orange-300': '#f39e7e',
  'orange-400': '#ec6643',
  'orange-500': '#e84727',
  'orange-600': '#d92f1d',
  'orange-700': '#b4221a',
  'orange-800': '#901d1c',
  'orange-900': '#741b1a',
  'orange-950': '#3f0b0c',
  'navy-50': '#F5F8FA',
  'navy-100': '#EAEFF4',
  'navy-200': '#D0DDE7',
  'navy-300': '#A7C1D2',
  'navy-400': '#77A0B9',
  'navy-500': '#5684A1',
  'navy-600': '#436A86',
  'navy-700': '#37566D',
  'navy-800': '#31495B',
  'navy-900': '#2C3E4E',
  'navy-950': '#1C2731',
  'brand-primary': '#bf1924',
  'brand-secondary': '#F8B700',
  'brand-tertiary': '#2469a9',
  'brand-quaternary': '#2b2a2a',
  'danger-100': '#FE8188',
  'danger-200': '#FF5B65',
  'danger-300': '#FF3440',
  'warning-100': '#FFDB7F',
  'warning-200': '#FFCF53',
  'warning-300': '#FFAD00',
  'success-100': '#46FA8D',
  'success-200': '#23E06E',
  'success-300': '#02CD53',
  'gradient-01':
    'linear-gradient(242.41deg, #CE131C -5.07%, #251819 23.38%, #1A1818 35.69%, #181818 62.96%, #152635 75.8%, #0176E1 105.14%)',
  'gradient-02': 'linear-gradient(77.2deg, #DC000B 13.75%, #0176E1 95.62%)',
  error: '#ed1c24',
  success: '#44bd32',
  link: '#1979ff',

  //text
  title: '700 1.375rem "Poppins", sans-serif',
  subTitle: '700 1rem "Poppins", sans-serif',
  'text-body-regular': '400 1rem "Poppins",sans-serif',
  'text-body-bold': '700 1rem "Poppins",sans-serif',
  'small-body-text-regular': '400 0.875rem "Poppins",sans-serif',
  'small-body-text-bold': '700 0.875rem "Poppins",sans-serif',
  'smaller-body-text-regular': '400 0.75rem "Poppins",sans-serif',
  'smaller-body-text-bold': '700 0.75rem "Poppins",sans-serif',
  'link-text-bold': '700 0.875rem "Poppins",sans-serif',
  'link-text-regular': '400 0.875rem "Poppins",sans-serif',

  //others
  'card-shadow': '5px 5px 8px 3px rgba(16, 24, 40, 0.2)',
  'card-shadow-2': '0px 1px 3px rgba(96, 97, 112, 0.16)',
  'shadow-1': '2px 2px 6px 0px rgba(0, 0, 0, 0.1)',
  'shadow-2': '4px 4px 12px 0px rgba(0, 0, 0, 0.1)',
  'shadow-3': '8px 8px 18px 0px rgba(0, 0, 0, 0.12)',
  'shadow-4': '12px 12px 24px 0px rgba(0, 0, 0, 0.12)',
  'shadow-5': '14px 14px 24px 0px rgba(0, 0, 0, 0.12)',
  'shadow-6': '14px 14px 38px 0px rgba(24, 24, 24, 0.14)',

  spacing: {
    8: '0.5rem',
    10: '0.625rem',
    12: '0.75rem',
    14: '0.875rem',
    16: '1rem',
    18: '1.125rem',
    20: '1.25rem',
    24: '1.5rem',
    28: '1.75rem',
    32: '2rem',
    36: '2.25rem',
    40: '2.5rem',
    44: '2.75rem',
    48: '3rem',
    56: '3.5rem',
    64: '4rem',
    72: '4.5rem',
    80: '5rem',
    96: '6rem',
    112: '7rem',
    128: '8rem',
    144: '9rem',
    160: '10rem',
    178: '11rem',
  },
} as const;

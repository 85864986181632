import PropTypes from 'prop-types';

// Local
import useStyles from './styles';
import { ReactNode } from 'react';

interface IPropsNoData {
  children: ReactNode;
}

const NoData = ({ children }: IPropsNoData) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};

NoData.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NoData;

export const checkoutImg =
  'https://storage.googleapis.com/stateless-ibox6-com-br/2023/12/2f50302d-checkout.svg';

export const curseImg =
  'https://storage.googleapis.com/stateless-ibox6-com-br/2023/12/67c17a5c-curse.png';
export const brasaoImg =
  'https://storage.googleapis.com/stateless-ibox6-com-br/2023/12/34c8a143-brasaobrasil.png';
export const boxLogoLogin =
  'https://storage.googleapis.com/stateless-ibox6-com-br/2023/12/0bfacb8b-boxlogologin.png';
export const smallLogoHeaderImg =
  'https://storage.googleapis.com/stateless-ibox6-com-br/2023/12/47965f79-smalllogoheader.png';
export const logoBlackImg =
  'https://storage.googleapis.com/stateless-ibox6-com-br/2023/12/2e76c841-logoblack.png';
export const logoWhiteImg =
  'https://storage.googleapis.com/stateless-ibox6-com-br/2023/12/68eb3573-logofullwhite.png';
export const logoLeftImg =
  'https://storage.googleapis.com/stateless-ibox6-com-br/2023/12/3ba3636e-logoleft.png';
export const previewClass =
  'https://storage.googleapis.com/stateless-ibox6-com-br/2023/12/12c63972-preview-class.png';
export const previewDashboard =
  'https://storage.googleapis.com/stateless-ibox6-com-br/2023/12/f6546a80-previewdashboard.png';
export const previewSimulation =
  'https://storage.googleapis.com/stateless-ibox6-com-br/2023/12/d486587e-preview-simulation.png';
export const previewWallet =
  'https://storage.googleapis.com/stateless-ibox6-com-br/2023/12/d874ddfd-preview-wallet.png';
export const previewWallet2 =
  'https://storage.googleapis.com/stateless-ibox6-com-br/2023/12/d874ddfd-preview-wallet.png';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  header {
    margin: 0;
  }
`;

export const ContentUser = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;

  .dataUser {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    strong {
      font: 500 1.125rem 'Poppins', sans-serif;
      color: #5e666c;
    }
    span {
      font: 400 1rem 'Poppins', sans-serif;
      color: #5e666c;
    }
  }
`;

export const ContainerResponseComments = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 1rem;
  padding-right: 1rem;
  gap: 1rem;

  form {
    display: flex;
    flex-direction: column;
    width: 100%;

    div {
      display: flex;
      width: 100%;
      gap: 1rem;
    }
    textarea {
      resize: none;
      width: 100%;
      height: 5rem;
      border-radius: 8px;
      border: ${(props) => props.theme.border};
      padding: 0.5rem;
    }
    .contentButton {
      width: 100%;
      justify-content: flex-end;
      margin-top: 0.5rem;
    }
  }
  strong {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;

    :hover {
      color: ${(props) => props.theme.primary};
    }
  }
`;
interface IPropsContentShowComment {
  showResponse: boolean;
}
export const ContentShowComment = styled.div<IPropsContentShowComment>`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  border-bottom: ${(props) =>
    props.showResponse ? `${props.theme.border}` : 'none'};

  span,
  label {
    font-size: 0.875rem;
    color: ${(props) => props.theme['text-body']};
    cursor: pointer;
    transition: color 0.3s;
    :hover {
      color: ${(props) => props.theme.primary};
    }
  }
`;

import ReactApexChart from 'react-apexcharts';
import { useActives } from '../../../../../hooks/useActives';
import { transformMaskCurrency } from '../../../../../utils/transformMaskCurrency';

interface IPropsGraphicFilter {
  activeFilter: string;
  dataGraphic: {
    period: number;
    total: number;
    total_acao: number;
    total_fii: number;
  }[];
}

export function GraphicFilter({
  activeFilter,
  dataGraphic,
}: IPropsGraphicFilter) {
  //@ts-ignore
  const { showActive } = useActives();
  const graphicData = {
    series: [
      {
        name: activeFilter,
        type: 'column',
        data: dataGraphic.map((item) => {
          if (item.total_acao !== 0) {
            return item.total_acao;
          } else if (item.total_fii !== 0) {
            return item.total_fii;
          }
        }),
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        stacked: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [4, 4, 4],
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      colors: [
        'var(--graphic-action)',
        'var(--graphic-fii)',
        'var(--graphic-total)',
      ],
      legend: {
        position: 'bottom',
        horizontalAlign: 'right',
      },
      xaxis: {
        categories: dataGraphic.map((item) => item.period.toString()),
        labels: {
          //@ts-ignore
          formatter: function (value) {
            if (value) {
              let year = value?.substring(0, 4);
              let month = value?.substring(4, 6);
              return `${month}/${year}`;
            }
          },
        },
      },
      yaxis: {
        labels: {
          //@ts-ignore
          formatter: function (value) {
            if (showActive) {
              return transformMaskCurrency(value);
            } else {
              return 'R$ *****';
            }
          },
        },
      },

      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          //@ts-ignore
          formatter: function (val) {
            if (showActive) {
              return transformMaskCurrency(val);
            } else {
              return 'R$ *****';
            }
          },
        },
      },
    },
  };
  return (
    //@ts-ignore
    <ReactApexChart
      options={graphicData.options}
      series={graphicData.series}
      type="line"
      height={350}
    />
  );
}

import {
  SimulationCol,
  SimulationContainer,
  SimulationContent,
} from './styles';
import { Heading } from '../../../../../components/DefaultStylesTypography';
import { previewSimulation } from '../../../../../Constants/images';

interface IPropsScreen {
  onShowRegisterScreen: () => void;
}

export function Simulation({ onShowRegisterScreen }: IPropsScreen) {
  return (
    <SimulationContainer>
      <div className="blueColor" />
      <div className="redColor" />
      <SimulationContent>
        <SimulationCol>
          <header>
            <span>
              Transforme sua vida financeira, simule e calcule sua liberdade com
              eficácia!
            </span>
            <Heading>
              Calcule os passos para a sua Liberdade Financeira!
            </Heading>
          </header>
          <p>
            Para chegarmos a qualquer lugar, é muito importante vislumbrarmos
            antes o destino e calcular o caminho. Quanto você vai precisar
            poupar a cada mês? Por quanto tempo? O iBox6 calcula de forma
            automática para você.
          </p>
          <button type="button" onClick={onShowRegisterScreen}>
            Acesse agora
          </button>
        </SimulationCol>
        <img src={previewSimulation} alt="" className="previewImg" />
      </SimulationContent>
    </SimulationContainer>
  );
}

import moment from 'moment';
import axios from 'axios';
import React, { useState } from 'react';

// Material UI
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import CameraAltIcon from '@material-ui/icons/CameraAlt';
import EventNoteIcon from '@material-ui/icons/EventNote';
import LinkIcon from '@material-ui/icons/Link';
import LocationIcon from '@material-ui/icons/LocationOnOutlined';

import EditProfile from '../EditProfile';
import FollowButton from '../FollowButton';
import UserSlug from '../UserSlug';

import useStyles from './styles';
import { userRoutes } from '../../../utils/RoutesApi';
import { useTitleAndUser } from '../../../hooks/userTitle';

const ProfileInfo = ({ loading, profileUser }) => {
  const getDataUser = JSON.parse(
    localStorage.getItem(process.env.REACT_APP_USER_VERSION)
  );
  const { user, setUser } = useTitleAndUser();
  const [attUser, setAttUser] = useState(false);

  const classes = useStyles(profileUser);

  const bannerRef = React.useRef();
  const imageRef = React.useRef();

  const handleChangeBannerProfile = (event, field) => {
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append('banner', image, image.name);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}${userRoutes.USER_UPDATE_IMAGE}`,
        formData
      )
      .then((response) => {
        let res = response.data;
        if (res) {
          const includeImage =
            res.image &&
            res.image.match(
              'https://storage.googleapis.com/ibox6-wordpress.appspot.com/'
            );

          const isIncludeImage = !!includeImage;

          const includeBanner =
            res.banner &&
            res.banner.match(
              'https://storage.googleapis.com/ibox6-wordpress.appspot.com/'
            );

          const isIncludeBanner = !!includeBanner;

          if (isIncludeImage) {
            let obj = getDataUser;
            obj.user.profile = res;
            localStorage.setItem(
              process.env.REACT_APP_USER_VERSION,
              JSON.stringify(obj)
            );
            setUser(obj.user);
          }

          if (isIncludeBanner) {
            let obj = getDataUser;
            obj.user.profile = res;
            localStorage.setItem(
              process.env.REACT_APP_USER_VERSION,
              JSON.stringify(obj)
            );
            setUser(obj.user);
          }

          if (isIncludeBanner || isIncludeImage) {
            return;
          }

          let obj = getDataUser;
          obj.user.profile = res;
          localStorage.setItem(
            process.env.REACT_APP_USER_VERSION,
            JSON.stringify(obj)
          );
          setUser(obj.user);
        }
      })
      .catch((err) => {});
  };

  const handleChangeImageProfile = (event) => {
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append('image', image, image.name);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}${userRoutes.USER_UPDATE_IMAGE}`,
        formData
      )
      .then((response) => {
        let res = response.data;
        if (res) {
          const includeImage =
            res.image &&
            res.image.match(
              'https://storage.googleapis.com/ibox6-wordpress.appspot.com/'
            );

          const isIncludeImage = !!includeImage;

          const includeBanner =
            res.banner &&
            res.banner.match(
              'https://storage.googleapis.com/ibox6-wordpress.appspot.com/'
            );

          const isIncludeBanner = !!includeBanner;

          if (isIncludeImage) {
            let obj = getDataUser;
            obj.user.profile = res;
            localStorage.setItem(
              process.env.REACT_APP_USER_VERSION,
              JSON.stringify(obj)
            );
            setUser(obj.user);
          }

          if (isIncludeBanner) {
            let obj = getDataUser;
            obj.user.profile = res;
            localStorage.setItem(
              process.env.REACT_APP_USER_VERSION,
              JSON.stringify(obj)
            );
            setUser(obj.user);
          }

          if (isIncludeBanner || isIncludeImage) {
            return;
          }

          let obj = getDataUser;
          obj.user.profile = res;

          localStorage.setItem(
            process.env.REACT_APP_USER_VERSION,
            JSON.stringify(obj)
          );
          setUser(obj.user);
        }
      })
      .catch((err) => {});
  };

  const handleEditAvatar = () => {
    imageRef.current.click();
  };

  const handleEditBanner = () => {
    bannerRef.current.click();
  };

  const renderProfile = () => {
    let rendered;

    if (profileUser.slug) {
      rendered = (
        <>
          <div className={classes.banner}>
            {user.slug === profileUser.slug && (
              <>
                <input
                  hidden="hidden"
                  onChange={(event) => handleChangeBannerProfile(event)}
                  ref={bannerRef}
                  type="file"
                />
                <IconButton
                  classes={{ root: classes.rootEditImage }}
                  className={classes.editBannerButton}
                  onClick={handleEditBanner}
                >
                  <CameraAltIcon />
                </IconButton>
              </>
            )}
          </div>
          <div className={classes.avatarAndFollow}>
            <div className={classes.avatar}>
              {user.slug === profileUser.slug && (
                <>
                  <input
                    hidden="hidden"
                    onChange={(event) => handleChangeImageProfile(event)}
                    ref={imageRef}
                    type="file"
                  />
                  <IconButton
                    classes={{ root: classes.rootEditImage }}
                    className={classes.editAvatarButton}
                    onClick={handleEditAvatar}
                  >
                    <CameraAltIcon />
                  </IconButton>
                </>
              )}
            </div>
            <div className={classes.followButton}>
              {user.slug !== profileUser.slug ? (
                <>
                  <FollowButton
                    size="large"
                    userSlug={profileUser}
                    setAttUser={setAttUser}
                    attUser={attUser}
                  />
                </>
              ) : (
                <EditProfile />
              )}
            </div>
          </div>

          <div className={classes.infoContainer}>
            <Typography variant="h6">{profileUser.Name}</Typography>
            <UserSlug profileUser={profileUser} />
            {profileUser.profile.bio && (
              <Typography className={classes.bio}>
                {profileUser.profile.bio}
              </Typography>
            )}
            <div className={classes.extraInfoContainer}>
              <div className={classes.extraInfo}>
                <EventNoteIcon className={classes.extraInfoIcon} />
                <Typography className={classes.extraInfoText}>
                  Entrou em {moment(profileUser.created).format('DD MMMM YYYY')}
                </Typography>
              </div>
              {profileUser.profile.location && (
                <div className={classes.extraInfo}>
                  <LocationIcon className={classes.extraInfoIcon} />
                  <Typography className={classes.extraInfoText}>
                    {profileUser.profile.location}
                  </Typography>
                </div>
              )}
              {profileUser.profile.website && (
                <div className={classes.extraInfo}>
                  <LinkIcon className={classes.extraInfoIcon} />
                  <Link href={profileUser.profile.website} target="_blank">
                    {profileUser.profile.website}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </>
      );
    } else {
      rendered = (
        <>
          <div className={classes.banner} />
          <div className={classes.avatarAndFollow}>
            <div className={classes.avatar} />
          </div>
          {!loading && <p>Usuário não encontrado.</p>}
        </>
      );
    }
    return rendered;
  };

  return renderProfile();
};

export default ProfileInfo;

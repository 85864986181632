import axios from 'axios';
import swal from 'sweetalert';
import { loginConfirmedAction, logout } from '../store/actions/AuthActions';
import * as Constants from '../Constants';

export function signUp(email, password, username, cpf) {
  //axios call
  const postData = {
    email,
    password,
    username,
    cpf,
  };
  return axios.post(
    `${process.env.REACT_APP_API_URL}/auth/register/`,
    postData
  );
}

export async function login({ username, password, token = null }) {
  if (token !== null) {
    const postData = {
      existing_token: token,
      username: 'token',
      password: 'token',
    };

    const API_URL = Constants.api;
    //const API_URL = ${process.env.REACT_APP_API_URL};

    const data_return = await axios.request({
      // `url` is the server URL that will be used for the request
      url: '/api/auth/login/',
      method: 'post', // default
      baseURL: API_URL,
      data: postData,
    });

    return data_return;
  } else {
    const postData = {
      username,
      password,
    };

    const API_URL = Constants.api;
    //const API_URL = ${process.env.REACT_APP_API_URL};

    const data_return = await axios.request({
      // `url` is the server URL that will be used for the request
      url: '/api/auth/login/',
      method: 'post', // default
      baseURL: API_URL,
      data: postData,
    });

    return data_return;
  }
}

export function formatError(errorResponse) {
  switch (errorResponse.error.message) {
    case 'EMAIL_EXISTS':
      //return 'Email already exists';
      swal('Oops', 'Email already exists', 'error');
      break;
    case 'EMAIL_NOT_FOUND':
      //return 'Email not found';
      swal('Oops', 'Email not found', 'error', { button: 'Try Again!' });
      break;
    case 'INVALID_PASSWORD':
      //return 'Invalid Password';
      swal('Oops', 'Invalid Password', 'error', { button: 'Try Again!' });
      break;
    case 'USER_DISABLED':
      return 'User Disabled';

    default:
      return '';
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  tokenDetails.expireDate = new Date(new Date().getDate() + 1);

  localStorage.setItem(
    process.env.REACT_APP_USER_VERSION,
    JSON.stringify(tokenDetails)
  );
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

export function checkAutoLogin(dispatch, history) {
  const tokenDetailsString = localStorage.getItem(
    process.env.REACT_APP_USER_VERSION
  );
  let token = JSON.parse(tokenDetailsString);

  let tokenDetails = '';
  if (!token) {
    dispatch(logout(history));
    return;
  }

  tokenDetails = token;
  let expireDate = new Date();
  let todaysDate = new Date();

  expireDate.setDate(expireDate.getDate() + 1);

  if (todaysDate > expireDate) {
    dispatch(logout(history));
    return;
  }
  dispatch(loginConfirmedAction(tokenDetails));

  // Logout timer disabled because user will be disconnected with invalid token
  // const timer = expireDate.getTime() - todaysDate.getTime();
  // runLogoutTimer(dispatch, timer, history);
}

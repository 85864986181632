import axios from 'axios';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { userRoutes } from '../../../../../utils/RoutesApi';
import { ColContent, Container, Divider, RowContent, Button } from './styles';
import LoadingSpinner from '../../../Finance/LoadingSpinner';
import { useTitleAndUser } from '../../../../../hooks/userTitle';
// import { Button } from '../../../../pages/RegistrationStyles';
import { ButtonComponent } from '../../../../../components/ButtonComponent';
import { CardPlanIR } from './SelectedPlan/components/CardPlanIR';
import { transformMaskCurrency } from '../../../../../utils/transformMaskCurrency';
import { SignPlan } from './components/SignPlan';
import { ModalDesigner } from '../../../../../components/ModalDesigner';
import { whatsAppLinkApi } from '../../../../../utils/apiWhatsapp';

interface IPropsPlan {
  NomePlano: string;
  Status: string;
  StatusPagamentoCorrente: string;
  SubscriptionId: string;
  UserName: string;
  Valor: number;
  cardLastDigitsCard: number | null;
  card_brand: number | null;
  currentPeriodEnd: string;
  currentPeriodStart: string;
  manageUrl: string;
  paymentMethod: string;
}

export function Pagamentos() {
  //@ts-ignore
  const { setTitle } = useTitleAndUser();
  //@ts-ignore
  const [plan, setPlan] = useState<IPropsPlan | null>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [modalConfirmCancellationPlan, setModalConfirmCancellationPlan] =
    useState<boolean>(false);
  const [changeMyPlan, setChangeMyPlan] = useState<boolean>(false);

  useEffect(() => {
    handlePayment();
    setTitle('Configuração de Pagamento');
  }, []);

  async function handlePayment() {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}${userRoutes.STATUS_PAYMENT}`)
      .then((response) => {
        if (
          !!response.data.NomePlano === false ||
          response.data.Status === 'canceled' ||
          response.data.Status === 'bonus'
        ) {
          setChangeMyPlan(true);
        }
        setPlan(response.data);
      })
      .catch((err) => {
        //@ts-ignore
        setLoading(false);
        setPlan(null);
      });
    setLoading(false);
  }

  function redirectPaymentAPI(link: string) {
    if (link.includes('pay.hotmart')) return window.open(`https://${link}`);
    window.open(`${link.replace('www.', '')}`);
  }

  return loading ? (
    <LoadingSpinner />
  ) : (
    <Container>
      <RowContent>
        {plan ? (
          <>
            <ColContent>
              <header>
                <h1>Configurações de assinatura</h1>
                <h2>IBox 6</h2>
              </header>
              <Divider />
              <main>
                <header>
                  <h3>{plan.NomePlano}</h3>
                  <span
                    style={
                      plan.Status === 'paid' ||
                      plan.Status === 'pago' ||
                      plan.Status === 'bonus'
                        ? { color: 'green' }
                        : { color: 'red' }
                    }
                  >
                    {plan.paymentMethod === 'hotmart'
                      ? 'Hotmart'
                      : plan.Status === 'paid' || plan.Status === 'pago'
                      ? 'Pago'
                      : plan.Status === 'canceled'
                      ? 'Cancelado'
                      : plan.Status === 'bonus'
                      ? 'Bonus'
                      : 'Pendente'}
                  </span>
                </header>

                <p>
                  Valor do plano:{' '}
                  <strong>{transformMaskCurrency(plan.Valor)}</strong>
                </p>

                <p>
                  Tipo de pagamento:{' '}
                  <strong>
                    {plan.paymentMethod === 'credit_card'
                      ? 'Cartão'
                      : plan.paymentMethod === 'hotmart'
                      ? 'Hotmart'
                      : plan.Status === 'bonus'
                      ? 'Bonus'
                      : 'Boleto'}
                  </strong>
                </p>
                {plan.currentPeriodStart && (
                  <p>
                    Ínicio:{' '}
                    <strong>
                      {moment(plan.currentPeriodStart).format('LL')}
                    </strong>
                  </p>
                )}
                {plan.currentPeriodEnd && (
                  <p>
                    Término:{' '}
                    <strong>
                      {moment(plan.currentPeriodEnd).format('LL')}
                    </strong>
                  </p>
                )}
              </main>
              <footer>
                {plan.Status !== 'bonus' && (
                  <Button
                    className="gestãoPagamento"
                    background="#EC6643"
                    color="#FFF"
                    onClick={() => {
                      if (plan.paymentMethod === 'InscricaoCurso') {
                        setChangeMyPlan(true);
                      } else {
                        redirectPaymentAPI(
                          plan.paymentMethod === 'hotmart'
                            ? 'https://hotmart.com/pt-br'
                            : plan.manageUrl
                        );
                      }
                    }}
                  >
                    Gestão de pagamento
                  </Button>
                )}
                {plan.Status !== 'paid' && plan.Status !== 'unpaid' && (
                  <>
                    <Button
                      background="#EC6643"
                      color="#FFF"
                      onClick={() => setChangeMyPlan(true)}
                    >
                      Escolher plano
                    </Button>
                  </>
                )}
                {plan.Status !== 'canceled' &&
                  plan.paymentMethod !== 'hotmart' &&
                  plan.Status !== 'bonus' && (
                    <>
                      <ButtonComponent
                        variant="link"
                        onClick={() => setModalConfirmCancellationPlan(true)}
                      >
                        Cancelar plano
                      </ButtonComponent>
                    </>
                  )}
                <p>
                  Qualquer situação de problemas de pagamento e/ou mudança de
                  plano, entrar em contato com{' '}
                  <a href={whatsAppLinkApi}>nosso suporte</a>.
                </p>
              </footer>
            </ColContent>
            <CardPlanIR />
          </>
        ) : (
          <>
            <ColContent>
              <header>
                <h1>Configurações de assinatura</h1>
                <h2>IBox 6</h2>
              </header>
              <Divider />
              <main>
                <header>
                  <h3>iBox6 Free</h3>
                  <span>Gratuíto</span>
                </header>
              </main>
              <footer>
                <Button
                  background="#EC6643"
                  color="#FFF"
                  onClick={() => setChangeMyPlan(true)}
                >
                  Escolher plano
                </Button>

                <p>
                  Qualquer situação de problemas de pagamento e/ou mudança de
                  plano, entrar em contato com{' '}
                  <a href={whatsAppLinkApi}>nosso suporte</a>.
                </p>
              </footer>
            </ColContent>
            <CardPlanIR />
          </>
        )}
      </RowContent>

      {changeMyPlan && (
        <SignPlan
          onBackPaymentScreen={() => setChangeMyPlan(false)}
          currentPlanId={plan !== null ? Number(plan.SubscriptionId) : 0}
          onFinishPlan={handlePayment}
        />
      )}

      <ModalDesigner
        title="Realmente deseja cancelar o plano?"
        id="cancelar"
        isOpen={modalConfirmCancellationPlan}
        setState={setModalConfirmCancellationPlan}
        size="sm"
      >
        <div>
          <p style={{ fontSize: '1rem', color: 'black' }}>
            {/* <strong style={{ fontSize: '1rem', color: 'black' }}>
              Ahh... tem certeza?
            </strong> 
            <br />
            <br />
            */}
            <p>
              Clicar em <strong>Mostrar Detalhes</strong>
            </p>
            <p>
              Depois clicar em <strong>Configurar pagamento</strong>
            </p>
            <p>
              Depois clicar em <strong>Deseja cancelar sua assinatura?</strong>
            </p>
          </p>
          <br />
          <footer
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '1rem',
              flexWrap: 'wrap',
            }}
          >
            <ButtonComponent
              onClick={() => {
                if (plan) {
                  redirectPaymentAPI(
                    plan.paymentMethod === 'hotmart'
                      ? 'https://hotmart.com/pt-br'
                      : plan.manageUrl
                  );
                } else {
                  window.open('https://consumer.hotmart.com/main');
                }
                setModalConfirmCancellationPlan(false);
              }}
              variant="link"
            >
              Quero cancelar
            </ButtonComponent>
            <ButtonComponent
              onClick={() => setModalConfirmCancellationPlan(false)}
            >
              Quero continuar no iBox6
            </ButtonComponent>
          </footer>
        </div>
      </ModalDesigner>
    </Container>
  );
}

import { transformMaskCurrency } from '../../../../utils/transformMaskCurrency';

export function maskValueCards(value: number = 0) {
  let maskValue = value ? value.toString().replace('-', '') : '0';

  if (maskValue.length < 7) {
    maskValue = transformMaskCurrency(Number(maskValue));
    if (value && value.toString().match('-')) {
      return `-${maskValue}`;
    }
    return maskValue;
  }
  if (maskValue.length === 7) {
    maskValue = `${maskValue.substring(0, 1)}.${maskValue.substring(1, 3)} Mi`;
    if (value && value.toString().match('-')) {
      return `-${maskValue}`;
    }
    return maskValue;
  }
  if (maskValue.length === 8) {
    maskValue = `${maskValue.substring(0, 2)}.${maskValue.substring(2, 4)} Mi`;
    if (value && value.toString().match('-')) {
      return `-${maskValue}`;
    }
    return maskValue;
  }
  if (maskValue.length === 9) {
    maskValue = `${maskValue.substring(0, 3)} Mi`;
    if (value && value.toString().match('-')) {
      return `-${maskValue}`;
    }
    return maskValue;
  }

  if (maskValue.length === 10) {
    maskValue = `${maskValue.substring(0, 1)}.${maskValue.substring(1, 3)} Bi`;
    if (value && value.toString().match('-')) {
      return `-${maskValue}`;
    }
    return maskValue;
  }
  if (maskValue.length === 11) {
    maskValue = `${maskValue.substring(0, 2)}.${maskValue.substring(2, 4)} Bi`;
    if (value && value.toString().match('-')) {
      return `-${maskValue}`;
    }
    return maskValue;
  }
  if (maskValue.length === 12) {
    maskValue = `${maskValue.substring(0, 3)} Bi`;
    if (value && value.toString().match('-')) {
      return `-${maskValue}`;
    }
    return maskValue;
  }
  if (value && value.toString().match('-')) {
    return `-${maskValue}`;
  }
  return maskValue;
}

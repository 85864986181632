import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';

const Chart = ({ data, fillcolor, height = 50 }) => {
  let newData = data.filter((item) => Number(item) !== 0);

  return (
    <Sparklines data={newData} height={height}>
      <SparklinesLine color={fillcolor} style={{ color: fillcolor }} />
      <SparklinesSpots />
    </Sparklines>
  );
};

export default Chart;
import { useEffect, useState } from 'react';
import { useTitleAndUser } from '../../../hooks/userTitle';
import { ButtonTab, SimuladoresContainer, TabPagesContent, BannerContent } from './styles';
import { JurosCompostos } from './JurosCompostos';
import { CalculadoraJurosEquivalente } from './CalculadoraJurosEquivalente';
import { CalculatorCDI } from './CalculatorCDI';
import BFBanner from '../../../assets/business/BannerFimdeAno.png';

export function Simulators() {
  //@ts-ignore
  const { setTitle } = useTitleAndUser();
  const [tabDefine, setTabDefine] = useState<
    'compostos' | 'equivalentes' | 'cdi'
  >('compostos');

  useEffect(() => {
    if (!location.pathname.includes('un-logged')) setTitle('Simuladores');
  }, []);

  return (
    <SimuladoresContainer>
      <BannerContent
        src={BFBanner}
        onClick={() => {
          window.open('/pagamentos');
        }}
      />
      <TabPagesContent>
        <ButtonTab
          isActive={tabDefine === 'compostos'}
          onClick={() => setTabDefine('compostos')}
        >
          JUROS COMPOSTOS
        </ButtonTab>
        <ButtonTab
          isActive={tabDefine === 'equivalentes'}
          onClick={() => setTabDefine('equivalentes')}
        >
          JUROS EQUIVALENTES
        </ButtonTab>
        <ButtonTab
          isActive={tabDefine === 'cdi'}
          onClick={() => setTabDefine('cdi')}
        >
          CALCULAR CDI
        </ButtonTab>
      </TabPagesContent>
      {tabDefine === 'compostos' && <JurosCompostos />}
      {tabDefine === 'equivalentes' && <CalculadoraJurosEquivalente />}
      {tabDefine === 'cdi' && <CalculatorCDI />}
    </SimuladoresContainer>
  );
}

import './LoadingSpinner.css';


export function CubeLoading() {
  return (
    <div className="cube">
      <div>Educação</div>
      <div>Carteira</div>
      <div>Renda</div>
      <div>Investimento</div>
      <div>Comunidade</div>
      <div>Simuladores</div>
    </div>
  );
}

import styled from 'styled-components';

export const ClearFilterButton = styled.button`
  all: unset;
  white-space: nowrap;
  background: rgba(131, 196, 255, 0.3);
  padding: 0.25rem 0.75rem;
  border-radius: 39px;
  font-weight: 500;
  font-size: 0.75rem;
  color: ${(props) => props.theme['primary-800']};
  cursor: pointer;
`;

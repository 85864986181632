import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Container, Content, ContentData, HeaderDate } from './styles';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import { CaretDown, CaretUp, Funnel } from '@phosphor-icons/react';
import { useNewTheme } from '../../../../../hooks/useNewTheme';
import { useTitleAndUser } from '../../../../../hooks/userTitle';
import { EarningLoader } from '../../../../components/LoadingModels';
import { Heading } from '../../../../../components/DefaultStylesTypography';
import { transformMaskCurrency } from '../../../../../utils/transformMaskCurrency';
import { InfinityScroll } from '../../../../../components/InfinityScroll';
import { IPropsDataRequest } from './@types';
import { AsideFilters } from './components/AsideFilters';
import { SideModal } from '../../../../../components/SideModal';
import { InputComponent } from '../../../../../components/InputComponent';

export interface IPropsParams {
  mes_datacom: string;
  ano_datacom: string;
  asset: string;
}

export function CalendarioDeDividendos() {
  // @ts-ignore
  const { isMobile } = useNewTheme();
  // @ts-ignore
  const { setTitle } = useTitleAndUser();
  const [values, setValues] = useState<IPropsDataRequest[]>([]);
  const [dataCom, setDataCom] = useState<Date[]>([]);
  const [payday, setPayDay] = useState<Date[]>([]);
  const [total, setTotal] = useState(0);
  const [newLoading, setNewLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showSelected, setShowSelected] = useState<Date[]>([]);
  const [isValidatSrollWindow, setIsValidatSrollWindow] = useState(false);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [params, setParams] = useState<IPropsParams>({
    ano_datacom: '',
    asset: '',
    mes_datacom: '',
  });

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      handleGetListDividendsActions();
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [params]);
  useEffect(() => {
    setTitle('Calendário de Dividendos');
  }, []);
  useEffect(() => {
    function scrollPosition() {
      setIsValidatSrollWindow(window.scrollY >= 200);
    }

    window.addEventListener('scroll', scrollPosition);
    return () => {
      window.removeEventListener('scroll', scrollPosition);
    };
  }, [window.scroll]);

  async function handleGetListDividendsActions() {
    setNewLoading(false);
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/Finance/dividendo`, {
        params,
      })
      .then((response) => {
        setTotal(response.data.count);
        let ordValue = response.data.data.sort(function (
          a: IPropsDataRequest,
          b: IPropsDataRequest
        ) {
          if (a.DataCom < b.DataCom) {
            return -1;
          } else {
            return true;
          }
        });

        let newDateOrdem = [];
        let newPayDay = [];
        if (ordValue.length > 0) {
          newDateOrdem = ordValue.map((item: IPropsDataRequest) => {
            return item.DataCom;
          });
        }

        if (newDateOrdem.length > 0) {
          // @ts-ignore
          let RemoveDuplicateItems = [...new Set(newDateOrdem)];

          setDataCom(RemoveDuplicateItems);
        }
        if (ordValue.length > 0) {
          newPayDay = ordValue.map((item: IPropsDataRequest) => {
            return item.Pagamento;
          });
        }
        if (newPayDay.length > 0) {
          // @ts-ignore
          let RemoveDuplicateItemsPayday = [...new Set(newPayDay)];
          setPayDay(RemoveDuplicateItemsPayday);
        }

        setValues(ordValue);
        setNewLoading(true);
      });
    setLoading(false);
  }
  async function handleGetListDividendsActionsInfinity() {
    setNewLoading(false);
    let count = page + 1;
    setPage(count);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/Finance/dividendo/`, {
        params: {
          ...params,
          page: count,
        },
      })
      .then((response) => {
        let ordValue = response.data.data.sort(function (
          a: IPropsDataRequest,
          b: IPropsDataRequest
        ) {
          if (a.DataCom < b.DataCom) {
            return -1;
          } else {
            return true;
          }
        });

        let newDateOrdem = [];
        let newPayDay = [];
        if (ordValue.length > 0) {
          newDateOrdem = ordValue.map((item: IPropsDataRequest) => {
            return item.DataCom;
          });
        }
        if (newDateOrdem.length > 0) {
          // @ts-ignore
          let RemoveDuplicateItems = [...new Set(newDateOrdem)];
          setDataCom([...dataCom, ...RemoveDuplicateItems]);
        }
        if (ordValue.length > 0) {
          newPayDay = ordValue.map((item: IPropsDataRequest) => {
            return item.Pagamento;
          });
        }
        if (newPayDay.length > 0) {
          // @ts-ignore
          let RemoveDuplicateItemsPayday = [...new Set(newPayDay)];
          setPayDay([...payday, ...RemoveDuplicateItemsPayday]);
        }

        setValues([...values, ...ordValue]);
        setNewLoading(true);
      });
  }

  const handleChange = (value: any) => {
    const valueIsAlreadyExist = showSelected.findIndex((val) => val === value);

    if (valueIsAlreadyExist === -1) {
      setShowSelected([...showSelected, value]);
    } else {
      const removeValueArray = showSelected.filter((item) => item !== value);

      setShowSelected(removeValueArray);
    }
  };
  function handleBackToTop() {
    window.scroll(0, 0);
  }

  return (
    <Container>
      <Content>
        {window.screen.width > 760 && (
          <AsideFilters params={params} setParams={setParams} />
        )}
        <ContentData>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <InputComponent
              roundedFull
              placeholder="Pesquisar pelo código ou nome da empresa "
              value={params.asset}
              onChange={(e) =>
                setParams({
                  ...params,
                  asset: e.target.value,
                })
              }
            />
            {window.screen.width <= 760 && (
              <button
                onClick={() => setShowModalFilter(true)}
                className="filterButton"
              >
                <Funnel weight="bold" color="#505050" />
              </button>
            )}
          </div>
          {loading ? (
            <EarningLoader />
          ) : (
            dataCom.map((data, i) => (
              <React.Fragment key={data.toString()}>
                <Box
                  sx={
                    // @ts-ignore
                    showSelected.includes(data)
                      ? {
                          height: 'auto',
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '1rem',
                        }
                      : {
                          height: '40px',
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '1rem',
                        }
                  }
                  key={i}
                  component="div"
                >
                  <HeaderDate onClick={() => handleChange(data)}>
                    <h3>{moment(data).format('LL')}</h3>
                    <div>
                      {/*  @ts-ignore */}
                      {showSelected.includes(data) ? (
                        <CaretUp size={24} />
                      ) : (
                        <CaretDown size={24} />
                      )}
                    </div>
                  </HeaderDate>
                  {/*  @ts-ignore */}
                  {showSelected.includes(data) && (
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns:
                          'repeat(auto-fit, minmax(20rem, 1fr))',
                        gap: '1rem',
                        width: '100%',
                      }}
                    >
                      {values.length > 0 &&
                        values.map(
                          (item, index) =>
                            data === item.DataCom &&
                            (index === 1 ? (
                              <Grow
                                in={showSelected.includes(data)}
                                key={index}
                                className="cardShowValue"
                              >
                                <div key={index}>
                                  <Heading
                                    as="h4"
                                    variant="quaternary"
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {item.Asset.replace('.SA', '')}
                                  </Heading>

                                  <p>
                                    Tipo: <strong>{item.Tipo}</strong>
                                  </p>
                                  <p>
                                    Data de pagamento:{' '}
                                    <strong>
                                      {moment(item.Pagamento).format('L')}
                                    </strong>
                                  </p>
                                  <p>
                                    Rendimento:{' '}
                                    <strong>
                                      {(item.Rendimento * 100).toFixed(2)}%
                                    </strong>
                                  </p>

                                  <p>
                                    Total:{' '}
                                    <strong>
                                      {transformMaskCurrency(item.Valor)}
                                    </strong>
                                  </p>
                                </div>
                              </Grow>
                            ) : (
                              <Grow
                                in={showSelected.includes(data)}
                                style={{ transformOrigin: '0 0 0' }}
                                {...(showSelected ? { timeout: 1000 } : {})}
                                key={index}
                                className="cardShowValue"
                              >
                                <div key={index}>
                                  <Heading
                                    as="h4"
                                    variant="quaternary"
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {item.Asset.replace('.SA', '')}
                                  </Heading>

                                  <p>
                                    Tipo: <strong>{item.Tipo}</strong>
                                  </p>
                                  <p>
                                    Data de pagamento:{' '}
                                    <strong>
                                      {moment(item.Pagamento).format('L')}
                                    </strong>
                                  </p>
                                  <p>
                                    Rendimento:{' '}
                                    <strong>
                                      {(item.Rendimento * 100).toFixed(2)}%
                                    </strong>
                                  </p>

                                  <p>
                                    Total:{' '}
                                    <strong>
                                      {transformMaskCurrency(item.Valor)}
                                    </strong>
                                  </p>
                                </div>
                              </Grow>
                            ))
                        )}
                    </Box>
                  )}
                </Box>
                {!showSelected.includes(data) && <div />}
              </React.Fragment>
            ))
          )}

          {values.length < total && (
            <div>
              <h3>Carregando...</h3>
            </div>
          )}
          {values.length === total && isValidatSrollWindow && (
            <>
              {isMobile && (
                <>
                  <br />
                </>
              )}
              <div className="finalList" onClick={handleBackToTop}>
                <h3>Voltar ao topo</h3>
              </div>
            </>
          )}
          {newLoading && values.length < total && (
            <InfinityScroll
              fetchMore={() => handleGetListDividendsActionsInfinity()}
            />
          )}
        </ContentData>
      </Content>
      <SideModal
        id="filtros"
        setState={setShowModalFilter}
        isOpen={showModalFilter}
      >
        <AsideFilters params={params} setParams={setParams} />
      </SideModal>
    </Container>
  );
}

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Input } from '../../../../components/Input';
import { Info } from '@phosphor-icons/react';
import ReactLoading from 'react-loading';
import { Button } from 'react-bootstrap';
import { handleToast } from '../../../../components/Toast';
import { GraphicCalc } from './GraphicCalc';
import { formatedAllValueCurrency } from '../../../../utils/formatedAllvalueCurrency';
import { CalculateFinancialIndependence } from './components/CalculateFinancialIndependence';
import { Container, ContentGraphic, FormStyles } from './styles';
import { transformMaskCurrency } from '../../../../utils/transformMaskCurrency';
import { ButtonDesign } from '../../../../components/ButtonDesign';
import { ModalStyled } from '../../Dashboard/components/TutorialIbox/styles';
import { ModalDesigner } from '../../../../components/ModalDesigner';

interface IPropsSubmitForm {
  initial: number;
  monthlyInvestment: number;
  period: number;
  interest: number;
  typePeriod: 'monthly' | 'year';
  typePeriodInterest: 'monthly' | 'year';
}

interface IPropsResultValues {
  interest: number[];
  mount: number[];
  totalInvest: number[];
  isGraphic: boolean;
  total: number;
}

export function CalculatorCompoundInterest() {
  const { handleSubmit, register, watch } = useForm();
  const [initialInvestment, setInitialInvestment] = useState(0);
  const [monthlyInvestment, setMonthlyInvestment] = useState(0);
  const [resultValues, setResultValues] = useState<IPropsResultValues>({
    interest: [],
    mount: [],
    totalInvest: [],
    isGraphic: false,
    total: 0,
  });
  const [modalFinancialInterest, setModalFinancialInterest] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  async function loadingSubmit(newLoading: boolean) {
    setLoading(() => newLoading);
  }

  async function submit(data: IPropsSubmitForm) {
    loadingSubmit(true);
    setResultValues({
      total: 0,
      interest: [],
      mount: [],
      totalInvest: [],
      isGraphic: true,
    });
    if (initialInvestment) {
      data.initial =
        Number(
          initialInvestment //@ts-ignore
            .replace(/\D/g, '')
            .replace(/(\d)(\d{2})$/, '$1.$2')
        ) ?? 0;
    } else {
      data.initial = 0;
    }

    data.monthlyInvestment = Number(
      monthlyInvestment
        ? monthlyInvestment //@ts-ignore
            .replace(/\D/g, '')
            .replace(/(\d)(\d{2})$/, '$1.$2')
        : 0
    );

    if (!data.period) {
      loadingSubmit(false);

      return handleToast(true, 'Informe o periodo a ser calculado');
    }
    if (!data.interest) {
      loadingSubmit(false);

      return handleToast(true, 'Informe a taxa de juros');
    }

    let period = data.period;
    if (data.typePeriod === 'year') {
      period = period * 12;
    }

    setResultValues((state) => {
      return {
        ...state,
        total: Number(period),
      };
    });

    let juros = data.interest / 100;
    juros = 1 + juros;
    if (data.typePeriodInterest === 'year') {
      juros = Math.pow(juros, 1 / 12);
    }
    let total = data.initial;
    let mount = data.initial;
    for (let i = 1; i <= period; i++) {
      let totalJuros = mount * juros - total;
      if (i === 1) {
        mount = Number((mount * juros).toFixed(2)) + data.monthlyInvestment;
      } else {
        mount = Number((mount * juros).toFixed(2)) + data.monthlyInvestment;
      }
      total = total + data.monthlyInvestment;
      setResultValues((state) => {
        return {
          ...state,
          interest: [...state.interest, totalJuros],
          totalInvest: [...state.totalInvest, total],
          isGraphic: true,
          mount: [...state.mount, mount],
        };
      });
      if (i === period) {
        loadingSubmit(false);
      }
    }
  }
  const interest = watch('interest');
  const typeInterest = watch('typePeriodInterest');

  const totalAccumulated = {
    showFormated: formatedAllValueCurrency(
      resultValues.mount[resultValues.mount.length - 1]?.toFixed(2) ?? 0
    ),
    realValue: transformMaskCurrency(
      resultValues.mount[resultValues.mount.length - 1] ?? 0
    ),
  };
  const totalInvested = {
    showFormated: formatedAllValueCurrency(
      resultValues.totalInvest[resultValues.totalInvest.length - 1]?.toFixed(
        2
      ) ?? 0
    ),
    realValue: transformMaskCurrency(
      resultValues.totalInvest[resultValues.totalInvest.length - 1] ?? 0
    ),
  };
  const totalInterest = {
    showFormated: formatedAllValueCurrency(
      Number(
        resultValues.interest[resultValues.interest.length - 1]?.toFixed(2)
      ) ?? 0
    ),
    realValue: transformMaskCurrency(
      resultValues.interest[resultValues.interest.length - 1] ?? 0
    ),
  };

  return (
    <Container>
      {/* @ts-ignore */}
      <FormStyles onSubmit={handleSubmit(submit)}>
        <br />
        <header>
          <h4>Calcular juros</h4>
          <Info size={20} color="#000000" />
        </header>
        <main>
          <div>
            <label>Montante inicial</label>
            <Input
              mask="currency"
              onChange={(e: any) => setInitialInvestment(e.target.value)}
            />
          </div>
          <div className="onPeriod step2">
            <label>Período</label>

            <input type="number" {...register('period')} />
            <select defaultValue="year" {...register('typePeriod')}>
              <option value="year">Ano(s)</option>
              <option value="monthly">Mes(es)</option>
            </select>
          </div>
          <div>
            <label>Investimento mensal</label>
            <Input
              mask="currency"
              onChange={(e: any) => setMonthlyInvestment(e.target.value)}
            />
          </div>
          <div className="onPeriod maskPercent step5">
            <label>Juros %</label>
            <input type="number" step="0.0001" {...register('interest')} />
            <select defaultValue="year" {...register('typePeriodInterest')}>
              <option value="year">Anual</option>
              <option value="monthly">Mensal</option>
            </select>
          </div>
        </main>
        <footer>
          <ButtonDesign
            style={{ maxWidth: 'none', width: 'calc(100% - 2rem - 4px)' }}
            type="submit"
          >
            {loading ? (
              <ReactLoading
                color="#fff"
                width="25px"
                height="25px"
                type="balls"
              />
            ) : (
              'Calcular'
            )}
          </ButtonDesign>
        </footer>
      </FormStyles>
      {resultValues.isGraphic &&
        resultValues.mount.length === resultValues.total && (
          <ContentGraphic>
            <div>
              <p title={totalAccumulated.realValue}>
                Total Acumulado: <strong>{totalAccumulated.realValue}</strong>
              </p>
              <p title={totalInvested.realValue}>
                Total investido: <strong>{totalInvested.realValue}</strong>
              </p>
              <p title={totalInterest.realValue}>
                Total juros: <strong>{totalInterest.realValue}</strong>
              </p>
              <ButtonDesign
                style={{ maxWidth: 'none', width: 'calc(100% - 2rem - 4px)' }}
                type="button"
                onClick={() => setModalFinancialInterest(true)}
              >
                Calcular sua renda passiva
              </ButtonDesign>
            </div>
            <GraphicCalc valuesGraphic={resultValues} />
          </ContentGraphic>
        )}

      <ModalDesigner
        id="independencia"
        isOpen={modalFinancialInterest}
        setState={setModalFinancialInterest}
        title="Independência financeira"
        size="sm"
      >
        <CalculateFinancialIndependence
          initialValue={Number(totalAccumulated.realValue.replace(/\D/g, ''))}
          initialPercent={interest}
          interestType={typeInterest}
        />
      </ModalDesigner>

    </Container>
  );
}

import styled from 'styled-components';

export const ContainerFeedback = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ContentTable = styled.div`
  display: flex;
  flex-direction: column;

  header {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;

    align-items: center;
    h2 {
      font-size: 1rem;
      color: ${(props) => props.theme['gray-700']};
      margin: 0;
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  background: white;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
`;
export const AsideContent = styled.aside`
  height: 100vh;
  width: 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  img {
    width: 9.187rem;
  }
  h2 {
    color: #40444c;
    text-align: center;
    font-family: Poppins;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 2.25rem */
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  .registerText {
    margin-top: 1.5rem;
    color: #6a717e;

    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    a {
      color: ${(props) => props.theme['orange-500']};
    }
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 28.125rem;
    gap: 1rem;

    @media (max-width: 480px) {
      padding: 1rem;
    }
  }
`;
export const ContentButton = styled.div`
  display: flex;
  width: 100%;
`;
export const ContentNewAccount = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  a {
    color: ${(props) => props.theme['gray-700']};
  }
`;

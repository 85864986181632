import { IPropsUser } from '../../utils/@types/user';
import { Avatar, NoAvatarComment } from './styles';

interface INewPropsUser extends IPropsUser {
  display_name?: string;
}

interface IPropsUserAvatar {
  user: INewPropsUser;
  width?: string;
  height?: string;
  onClick?: () => void;
}

export function UserAvatar({ user, width, height, onClick }: IPropsUserAvatar) {
  return (
    <Avatar width={width} height={height} onClick={onClick}>
      {user?.profile.image !== null ? (
        <img src={user.profile.image} />
      ) : (
        <NoAvatarComment width={width} height={height}>
          <strong>
            {user.display_name
              ? user.display_name.substring(0, 1)
              : user.Name.substring(0, 1)}
          </strong>
        </NoAvatarComment>
      )}
    </Avatar>
  );
}

import styled from 'styled-components';

interface IPropsContainerHeader {
  titleHeader: string;
  shortSidebar: boolean;
  fixed?: boolean;
  screenWidth?: number;
  isMobile: boolean;
  carteira?: string;
  automatico?: boolean;
  isFullScreen?: boolean;
  isBlackScreen?: boolean;
}

export const Container = styled.div<IPropsContainerHeader>`
  width: 100%;
  padding: 0 1rem;
  padding-left: ${(props) =>
    props.isMobile ? '1rem' : props.shortSidebar ? '17rem' : '5rem'};
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  position: ${(props) => (props.fixed ? 'fixed' : 'absolute')} !important;
  z-index: ${(props) => (props.fixed ? '100' : '0')};
  background: #303F4B;
  z-index: 100;

  height: ${(props) =>
    props.screenWidth && props.screenWidth < 624 && !props.fixed
      ? '550px'
      : !props.fixed
      ? '397px'
      : '65px'};
  transition: padding 1s;

  nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    height: 100%;
    max-height: 65px;
    width: 100%;
    max-width: ${(props) => (props.isFullScreen ? 'none' : '66.25rem')};

    .imageLogo {
      width: 35px;
      height: 40px;
      stroke-width: 3.712px;
      stroke: var(--Brand-brand-500, #EC6643);
      margin-left: 0; /* Alinha a logo à esquerda */ 
      position: absolute; 
      left: 2rem; /* Ajuste conforme necessário */
    }

    margin: 0 auto;
    h3 {
      margin: 0;
    }

    .userContent {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      /* position: relative; */
      /* z-index: 100; */
      /* gap: ${(props) => (props.isMobile ? '0.5rem' : '1rem')}; */
      gap: 1rem;
      position: absolute; 
      right: 4rem; /* Alinha os botões à direita */
    }

    .contentIcon {
      display: flex;
      flex-direction: row;
      gap: 10px;
      position: relative;
      z-index: 100;
    }

    .backTitle {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      left: 50%;
      transform: translateX(25%);

      button {
        
        border: none;
        background: transparent;
        outline: none;
        cursor: pointer;
        width: 30px;
        height: 30px;
      }
    }
  }

  .inputSearch {
    padding: 0.5rem 1rem;
    background-color: transparent;
    margin-right: 20px;
  }
  .navbar {
    margin-top: 1rem;
  }
  input.ant-input {
    background-color: transparent;
    color: ${(props) => props.theme.white};
  }
  a.nav-link.ai-icon.i-false.c-pointer.dropdown-toggle {
    background: transparent;
    color: ${(props) => props.theme.white};
  }

  .header-right .notification_dropdown .nav-link .badge {
    border: none;
    padding: 0 8px;
    width: 30px;
    height: 20px;
    border-radius: 10px;
    text-align: center;
  }

  a.nav-link.ai-icon.i-false.c-pointer.dropdown-toggle {
    padding: ${(props) =>
      props.titleHeader === 'Página inicial' || props.titleHeader === ''
        ? '0'
        : '5px'};
  }

  .jf-f-e {
    width: 100%;
    justify-content: flex-end;
    align-items: ${(props) =>
      props.titleHeader === 'Página inicial' || props.titleHeader === ''
        ? 'start'
        : 'center'};
  }

  h3 {
    font-size: ${(props) => (props.isMobile ? '0.875rem' : '1.5rem')};
    color: ${(props) => props.theme.white};
  }

  .header-left {
    align-items: ${(props) =>
      props.titleHeader === 'Página inicial' || props.titleHeader === ''
        ? 'start'
        : ' center'};
    margin-top: ${(props) =>
      (props.titleHeader === 'Página inicial' && !props.fixed) ||
      (props.titleHeader === '' && !props.fixed)
        ? '1.312rem'
        : '0'};
  }
  .header-right .nav-item {
    align-items: ${(props) =>
      props.titleHeader === 'Página inicial' || props.titleHeader === ''
        ? 'start'
        : ' center'};
    margin-top: ${(props) =>
      (props.titleHeader === 'Página inicial' && !props.fixed) ||
      (props.titleHeader === '' && !props.fixed)
        ? '1.312rem'
        : '0'};
  }
  .navbar {
    justify-content: ${(props) =>
      props.titleHeader === 'Página inicial' || props.titleHeader === ''
        ? 'start'
        : 'space-between'};
    align-items: ${(props) =>
      props.titleHeader === 'Página inicial' || props.titleHeader === ''
        ? 'start'
        : ' center'};
  }
  a.nav-link.ai-icon.i-false.c-pointer.dropdown-toggle {
    display: ${(props) => (props.fixed ? 'flex' : 'block')};
  }

  .switch {
    position: absolute;
    border-radius: 8px;
    top: 20px !important;
    width: 240px;
    padding: 1rem;
    z-index: 1000 !important;
    background-color: ${(props) => props.theme.white};
  }
  ul {
    align-items: center;
    display: flex;
    gap: ${(props) => (props.isMobile ? '0.75rem' : '1rem')};

    .linkB3 {
      position: relative;
      gap: 0;
      .auto {
        background: ${(props) =>
          props.carteira !== 'automatico'
            ? 'red'
            : props.automatico
            ? '#02CD53'
            : 'yellow'};
        position: absolute;
        top: 0;

        width: 8px;
        height: 8px;
        border-radius: 100%;
      }
    }
  }

  .icon {
    margin-top: ${(props) =>
      props.titleHeader === 'Página inicial' && !props.fixed
        ? '10px'
        : props.titleHeader === 'Página inicial' && props.fixed && '0'};
  }

  .notify {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 2rem !important;
      height: 2rem !important;
    }
  }

  .iconMenu {
    display: flex;
    align-items: flex-end;
    height: 100%;
  }

  .contentIcons {
    display: flex;

    align-items: center;
    margin-right: 0.875rem;

    a {
      padding: 0;
    }
  }

  .z-100 {
    position: relative;
    z-index: 100 !important;
  }
  .newNotify.dropdown-menu.show.dropdown-menu-right {
    top: 20px !important;
    /* z-index: 100 !important; */
  }

  .newNotify {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .searchContainer {
    display: flex;
    flex-direction: row;
    align-self: end;

    border: 2px solid ${(props) => props.theme.white};
    border-radius: 8px;
    height: 40px;
    padding: 5px;
    align-items: center;
    width: 100%;
  }
  .searchInput {
    font-size: 14px;
    color: ${(props) => props.theme.white};
    border: none;
    background: none;
    width: 100%;
  }

  @media (max-width: 820px) {
    padding-left: ${(props) => (props.isMobile ? '1rem' : '5rem')};
  }

  @media (max-width: 448px) {
    .welcome {
      font-size: 1.5rem;
      max-width: 370px;
    }
  }
  @media (max-width: 335px) {
    .welcome {
      max-width: 250px;
    }
    .searchContainer {
      font-size: 12px;
    }
  }

  .notifyContainer {
    position: relative;
    .countNotify {
      position: absolute;
      bottom: 0;
      right: -5px;
      border: none;

      width: 18px;
      height: 18px;
      border-radius: 10px;
      text-align: center;
      background: #ff4d4f;
      color: ${(props) => props.theme.white};
      font-size: 0.75rem;
    }
  }
`;

export const ContainerOptionSearch = styled.a`
  display: flex;
  flex-direction: row;
  padding: 0px 10px;
  align-items: center;

  .searchOptionText {
    font-size: 14px;
    margin-left: 5px;
    color: ${(props) => props.theme.black};
  }

  &:hover .searchOptionText {
    color: #004f92;
  }
`;

export const ContentUser = styled.div`
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
  align-items: center;

  h4 {
    margin: 0;
    color: ${(props) => props.theme.white};
    font-size: 0.9rem;
    width: 100%;
  }
  img {
    /* width: 100%; */
    width: 100vw;
    max-width: 3rem;
    height: 100vh;
    max-height: 3rem;
    border-radius: 100%;
    object-fit: cover;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
  position: absolute;
  top: 10rem;
  width: 100%;

  max-width: 1360px;
  @media (max-width: 1071px) {
    gap: 1rem;
  }

  @media (max-width: 351px) {
    justify-content: center;
    top: 9rem;
  }
`;
interface IPropsContainerShowMenu {
  show: boolean;
}

export const ContainerShowMenu = styled.div<IPropsContainerShowMenu>`
  display: flex;
  flex-direction: column;
  background: #2a2a2a;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1000;
  transform: ${(props) =>
    props.show ? 'translateY(0)' : 'translateY(-120vh)'};

  transition: all 1s;

  background: linear-gradient(
    50deg,
    #253d52,
    #253d52,
    #2a2a2a,
    #2a2a2a,
    #2a2a2a,
    #2a2a2a,
    #7a1b21,
    #7a1b21
  );
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: 'Poppins', sans-serif;
  justify-content: center;
  align-items: center;
`;

export const RowContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  align-items: start;
  justify-content: center;

  iframe {
    width: 100%;
    height: 100vw;
  }
`;

export const ColContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.white};
  padding: 1rem;
  box-shadow: ${(props) => props.theme['card-shadow-2']};
  min-width: 300px;
  gap: 1rem;
  border-radius: var(--Spacing-8, 8px);
  border: 1px solid var(--Gray-gray-100, #D4D6DB);
  background: #FFF;

  header {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      font-size: 1.7rem;
      margin: 0;
      margin-bottom: 5px;
      color: #303F4B;
    }
    h2 {
      color: #303F4B;
      font-size: 1.3rem;
      font-weight: 500;
    }
  }
  main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    header {
      margin-bottom: 10px;
      margin: 0;
    }
    p {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 5px;
      font-size: 1rem;
      margin: 0;

      strong {
        color: #3f3e40;
      }
    }
  }

  footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    p {
      max-width: 300px;

      a {
        color: ${(props) => props.theme.primary};
      }
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(21, 21, 21, 0.3);
`;

interface IPropsButton {
  background: string;
}

export const Button = styled.button<IPropsButton>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.813rem 0;
  border: none;
  outline: none;
  box-shadow: 4px 4px 12px 0px #4c4c4c26;
  border-radius: 4px;
  width: 100%;
  transition: filter 0.3s;
  font-size: 1rem;
  line-height: 150%;
  font-weight: 500;
  background-color:#EC6643;
  color: #FFF;
  
  .gestãoPagamento{
    background-color:#EC6643;
    color: #FFF;
  }

  :disabled {
    cursor: not-allowed;
  }

  :hover {
    filter: brightness(0.9);
  }

  div {
    display: flex;
  }
`;

import { useEffect, useState } from 'react';

import { useNewTheme } from '../../hooks/useNewTheme';
import { useTitleAndUser } from '../../hooks/userTitle';
import { Container } from './FooterStyles';

import { ModalTermsOfUse } from '../../components/ModalTermsOfUse';
import { useClearCacheCtx } from 'react-clear-cache';
import { whatsAppLinkApi } from '../../utils/apiWhatsapp';

const Footer = () => {
  const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();
  const { title } = useTitleAndUser();
  const { shortSidebar, isMobile } = useNewTheme();
  var d = new Date();
  const version = process.env.REACT_APP_VERSION;

  const [modalPrivacyPolicy, setModalPrivacyPolicy] = useState(false);

  useEffect(() => {
    if (!isLatestVersion && window.location.pathname !== '/dashboard/led') {
      emptyCacheStorage();
    }
  }, []);

  return (
    <Container
      shortSideBar={shortSidebar}
      title={title}
      isMobile={isMobile}
      isBlackScreen={window.location.pathname === '/admin-painel/dashboard'}
    >
      <p>Todos os direitos reservados © {d.getFullYear()} | iBox6 </p>
      <p>
        <button onClick={() => setModalPrivacyPolicy(true)}>
          Termos de uso e Política de privacidade
        </button>
      </p>
      <a href={whatsAppLinkApi}>Fale conosco</a>
      <span style={{ fontSize: '0.75rem' }}>
        Versão: <strong>{version}</strong>
        {/* {!isLatestVersion && (
          <button
            style={{
              border: 'none',
              background: 'none',
              padding: '0 0.5rem',
            }}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              emptyCacheStorage();
              window.location.reload();
            }}
          >
            atualizar
          </button>
        )} */}
      </span>

      {modalPrivacyPolicy && (
        <ModalTermsOfUse
          id="privacyPolicy"
          title="Política de privacidade"
          setState={setModalPrivacyPolicy}
          width={
            window.screen.width < 420
              ? '250px'
              : window.screen.width < 660
              ? '400px'
              : '600px'
          }
        >
          <iframe src="https://ibox6.com.br/politica-de-privacidade/"></iframe>
          <h1 className="impostoTitle">Imposto de renda</h1>
          <iframe src="https://ibox6.com.br/termos_de_uso_imposto_de_renda/"></iframe>
        </ModalTermsOfUse>
      )}
    </Container>
  );
};

export default Footer;

import { Dropdown as DropdownAntd, Menu } from 'antd';
import axios from 'axios';
import { Bell } from '@phosphor-icons/react';
import { ReactNode, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { useNewTheme } from '../../../../../../hooks/useNewTheme';
import { useTitleAndUser } from '../../../../../../hooks/userTitle';
import NoData from '../../../../../../social/components/NoData';
import { Notify } from '../../../../../../utils/RoutesApi';
import { IPropsNotification } from '../../@types/notifications';
import Typography from '@material-ui/core/Typography';
import { Notification } from '../../../../../../components/Notification';

interface INotifications {
  results: IPropsNotification[];
  count: number;
  next: string;
  previous: string;
}
const ONE_MINUTE_COUNTER = 1000 * 60 * 1;

export function Notifications() {
  //@ts-ignore
  const { isMobile } = useNewTheme();
  //@ts-ignore
  const { setNotifyCount, notifyCount } = useTitleAndUser();
  const [showNotifications, setShowNotifications] = useState<boolean>(false);
  const [loadingNotifications, setLoadingNotifications] = useState(false);

  const [notifications, setNotifications] = useState<INotifications>({
    results: [],
    count: 0,
    next: '',
    previous: '',
  });
  useEffect(() => {
    if (showNotifications) {
      getListNotifications();
    }
  }, [showNotifications]);
  const newNotificationCount = notifyCount;

  useEffect(() => {
    const interval = setInterval(async () => {
      await axios
        .get(`${process.env.REACT_APP_API_URL}${Notify.COUNT_NOTIFY}`)
        .then((response) => {
          const countnotif = response.data;

          setNotifyCount((state: number) => (state = response.data));
          // if (countnotif != newNotificationCount && countnotif > 0) {
          //   new window.Notification('IBox 6', {
          //     body:
          //       response.data === 1
          //         ? 'Você tem uma nova notificação'
          //         : 'Você tem novas notificações',
          //     icon: iBoxLogoNotification,
          //   });
          // }
        })
        .catch((err) => {});
    }, ONE_MINUTE_COUNTER);

    return () => {
      clearInterval(interval);
    };
  }, [newNotificationCount]);

  async function getListNotifications() {
    setLoadingNotifications(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}${Notify.LIST_NOTIFY}`)
      .then((response) => {
        setNotifications(response.data);

        // setLoadingNotifications(false);
        setNotifyCount(0);
      })
      .catch((err) => {});
    setLoadingNotifications(false);
  }
  async function getListNotificationsNextPage(link: string) {
    await axios
      .get(link)
      .then((response) => {
        setNotifications({
          ...response.data,
          results: [...notifications.results, ...response.data.results],
        });
      })
      .catch((err) => {});
  }

  function RenderNotifications() {
    let RenderedNotifications;

    if (notifications.results.length > 0) {
      RenderedNotifications = notifications.results.map(
        (notification: IPropsNotification) => (
          <Notification key={notification.id} notification={notification} />
        )
      );
    } else {
      RenderedNotifications = (
        <NoData>
          <Typography paragraph variant="h6">
            Você não tem nenhuma notificação
          </Typography>
          <Typography color="textSecondary" paragraph variant="body2">
            Quando receber uma notificação, ela aparecerá aqui.
          </Typography>
        </NoData>
      );
    }

    return RenderedNotifications;
  }

  const menuNotify = (
    <Menu
      onClick={(e) => {
        if (e.key === '0') {
          setShowNotifications(false);
        }
      }}
      style={{
        top: '10px',
        // right:'150px',
        overflowY: 'auto',
        maxHeight: '350px',
        width: '300px',
        // position:'fixed'
      }}
      items={
        loadingNotifications
          ? [
              {
                label: (
                  <ReactLoading
                    type="spokes"
                    color="var(--primary)"
                    width="50px"
                    height="50px"
                  />
                ),
                key: '0',
              },
            ]
          : notifications.results.length === notifications.count
          ? [
              {
                label: RenderNotifications(),
                key: '0',
              },
            ]
          : [
              {
                label: RenderNotifications(),
                key: '0',
              },
              {
                label: (
                  <span
                    style={{
                      color: 'var(--primary)',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                    onClick={() =>
                      getListNotificationsNextPage(notifications.next)
                    }
                  >
                    Mostrar mais
                  </span>
                ),
                key: '1',
              },
            ]
      }
    />
  );

  const handleOpenChange = (flag: boolean) => {
    setShowNotifications((state: boolean) => (state = flag));
  };

  return (
    <DropdownAntd
      overlay={menuNotify}
      placement="bottomLeft"
      trigger={isMobile ? ['click'] : undefined}
      className="notifyContainer"
      open={showNotifications}
      onOpenChange={handleOpenChange}
    >
      <a>
        <Bell size={isMobile ? '1.5rem' : '2rem'} color="#ffffff" />
        {notifyCount > 0 && (
          <span className="countNotify">
            {notifyCount >= 10 ? '+9' : notifyCount}
          </span>
        )}
      </a>
    </DropdownAntd>
  );
}

import { useEffect, useState } from 'react';
import { useTitleAndUser } from '../../../../hooks/userTitle';
import { CreateNewPlan } from './CreateNewPlan';
import { AdminPlansContainer, ButtonTab, TabContent } from './styles';
import { EditPlan } from './EditPlan';

export function AdminPlans() {
  //@ts-ignore
  const { setTitle } = useTitleAndUser();
  const [tabSelected, setTabSelected] = useState<1 | 2>(1);

  useEffect(() => {
    setTitle('Gestão de planos');
  }, []);
  return (
    <AdminPlansContainer>
      <TabContent>
        <ButtonTab
          isActive={tabSelected === 1}
          onClick={() => setTabSelected(1)}
        >
          Criar plano
        </ButtonTab>
        <ButtonTab
          isActive={tabSelected === 2}
          onClick={() => setTabSelected(2)}
        >
          Editar plano
        </ButtonTab>
      </TabContent>
      {tabSelected === 1 && <CreateNewPlan />}
      {tabSelected === 2 && <EditPlan />}
    </AdminPlansContainer>
  );
}

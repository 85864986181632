import ReactApexChart from 'react-apexcharts';

import { IPropsContract } from '../../../@types/ContractProps';

import { Container } from './styles';

interface IPropsGraphicContract {
  contracts: IPropsContract[];
  typeGraphic?: 'donut' | 'bar';
}

export function GraphicContract({
  contracts,
  typeGraphic = 'donut',
}: IPropsGraphicContract) {
  const graphicData = {
    series: contracts ? contracts.map((item) => Number(item.valor)) : [0],
    options: {
      labels: contracts.map((item) => item.periodo),
      yaxis: {
        labels: {
          formatter: (value: number) => {
            return `${value.toFixed(2)}%`;
          },
        },
      },

      plotOptions: {
        donut: {
          total: {
            show: true,
            formatter: function () {
              return 100;
            },
          },
        },
      },

      responsive: [
        {
          breakpoint: 1200,
          options: {
            chart: {
              width: 700,
            },
          },
        },
        {
          breakpoint: 992,
          options: {
            chart: {
              width: 500,
              height: 210,
            },
          },
        },
      ],
    },
  };

  return (
    <Container>
      <ReactApexChart
        // @ts-ignore
        options={graphicData.options}
        series={graphicData.series}
        type={typeGraphic === 'donut' ? 'donut' : 'bar'}
        height={400}
        width={800}
      />
    </Container>
  );
}

import styled from 'styled-components';

export const FormPlanStepContainer = styled.div`
  width: 100%;
  max-width: 800px;
  height: 100%;
  overflow-y: auto;
  margin-bottom: 1rem;
  border-radius: 4px;

  .css-qivjh0-MuiStepLabel-label {
    font-family: 'Poppins', sans-serif !important;
  }
`;
export const FormPlanStepContent = styled.div`
  padding: 1rem;
  border-radius: 4px;
  background: ${(props) => props.theme.white};
  margin-top: 4rem;

  @media (max-width: 480px) {
    background: transparent;
  }
`;

import styled from 'styled-components';

interface IPropsColDiv {
  value: number;
}

export const Container = styled.div`
  justify-content: end;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.white};
  border-radius: 4px;
  height: 100%;
  box-shadow: ${(props) => props.theme['card-shadow-2']};
  /* @media (max-width: 775px) {
    max-width: 400px;
    min-width: 400px;
  }
  @media (max-width: 482px) {
    min-width: 200px;
  } */
`;

export const DivRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap !important;
  justify-content: space-between;

  @media (max-width: 482px) {
    /* flex-direction: column; */
    flex-wrap: wrap !important;
    align-items: center;
    justify-content: center;
  }
`;
export const ColDiv = styled.div<IPropsColDiv>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  gap: 1rem;
  /* margin-bottom: 3.7rem; */
  div {
    margin-bottom: 0.25rem;
  }

  small {
    position: absolute;
    top: 2.5rem;
    left: ${(props) =>
      props.value < 2
        ? '3rem'
        : props.value < 3
        ? '2.5rem'
        : props.value < 4
        ? '2.2rem'
        : props.value < 5
        ? '2rem'
        : '1.5rem'};
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-weight: bold;
  }

  @media (max-width: 482px) {
    small {
      position: absolute;
      top: 2.5rem;
      left: ${(props) =>
        props.value < 2
          ? '10rem'
          : props.value < 3
          ? '10rem'
          : props.value < 4
          ? '10rem'
          : props.value < 5
          ? '7rem'
          : props.value < 6
          ? '6.55rem'
          : '6.3rem'};
      font-family: 'Poppins', sans-serif;
      font-size: 1rem;
      font-weight: bold;
    }
  }
`;

export const HeaderCardContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icon {
    position: absolute;
    right: -110%;
    cursor: pointer;
  }

  h4 {
    margin: 0;
    /* Heading/H5/SemiBold */
    font-family: Poppins;
    font-size: 22px;
    color: #535862;

    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
  }

  @media (max-width: 576px) {
    .icon {
      right: 0;
    }
  }
`;

import ContentLoader from 'react-content-loader';

export function LoadingBanner() {
  const rects = [
    { width: 200, height: 32 },
    { width: 250, height: 32 },
    { width: 250, height: 32 },
    { width: 250, height: 32 },
    { width: 250, height: 32 },
    { width: 250, height: 32 },
  ];

  const baseX = 28; // Posição inicial em X
  const baseY = 29; // Posição inicial em Y
  const gapX = 30; // Espaçamento horizontal entre os retângulos
  const gapY = 10; // Espaçamento vertical entre os retângulos
  return (
    <ContentLoader
      width="117%"
      height={200}
      style={{
        background: 'white',
        borderRadius: '4px',
        padding: '0',
        boxShadow: '0px 1px 3px rgba(96, 97, 112, 0.16)',
        marginRight: '30px',
      }}
    >
      {rects.map((rect, index) => {
        const row = Math.floor(index / 3); // Calcula a linha (3 elementos por linha)
        const col = index % 3; // Calcula a coluna
        return (
          <rect
            key={index}
            x={baseX + col * (rect.width + gapX)}
            y={baseY + row * (rect.height + gapY)}
            rx="4"
            ry="4"
            width={rect.width}
            height={rect.height}
          />
        );
      })}
    </ContentLoader>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 1rem;
  width: 100%;
  align-items: start;

  .ant-input-number.editInput {
    border: none;
    border-bottom: 1px solid #d9d9d9;
  }

  span {
    color: ${(props) => props.theme.white};
  }
  .slider {
    color: ${(props) => props.theme.primary};
    span {
      background: ${(props) => props.theme.primary};
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
    width: 100% !important;
    .contentSlider {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  .titleStrategy {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    h2 {
      margin: 0;
      font-size: 1.25rem;
      font-weight: 700;
      color: ${(props) => props.theme['gray-700']};
    }
  }

  @media (max-width: 1060px) {
    display: flex;
    flex-direction: column;

    .card {
      width: 100% !important;
    }
  }
`;

export const ContentInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  i {
    position: relative;
    top: -1.8rem;
    left: 8.7rem;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

interface IPropsCardGraphic {
  balanced: boolean;
}

export const CardGraphic = styled.div<IPropsCardGraphic>`
  display: flex;
  padding: 1.5rem;
  flex-direction: row;
  flex-wrap: wrap;
  height: auto;
  position: relative;
  z-index: 1;
  /* (props.balanced ? '#c3fad0' : '#F9C6C5') */
  background-color: ${(props) => props.theme.white};
  border-radius: 4px;
  border-top: 10px solid ${(props) => (props.balanced ? 'green' : 'red')};
  box-shadow: ${(props) => props.theme['card-shadow-2']};
  width: 100%;

  h2 {
    font-size: 1.5rem;
    font-weight: 700;
    color: ${(props) => props.theme['gray-700']};
    display: flex;
    flex-direction: row;
    gap: 5px;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    span {
      font-size: 1.5rem;
    }
  }
`;

export const TooltipText = styled.div`
  h3 {
    color: ${(props) => props.theme.primary};
    font-size: 16px;
  }
  width: 200px;
  text-align: end;

  cursor: pointer;

  @media (max-width: 390px) {
    width: 150px;
  }
  @media (max-width: 340px) {
    width: 100px;
  }
`;
export const TooltipBox = styled.div`
  position: absolute;
  top: calc(100% + 15px);
  z-index: 10;

  left: -50px;
  visibility: hidden;
  color: transparent;
  background-color: transparent;
  width: 300px;
  padding: 5px 5px;
  border-radius: 4px;
  transition: visibility 0.5s, color 0.5s, background-color 0.5s, width 0.5s,
    padding 0.5s ease-in-out;
  &:before {
    content: '';
    width: 0;
    height: 0;
    right: 55px;
    top: -10px;
    position: absolute;
    border: 10px solid transparent;
    transform: rotate(135deg);
    transition: border 0.3s ease-in-out;
    @media (max-width: 675px) {
      /* top: -5px; */
      left: 200px;
    }
  }
  @media (max-width: 675px) {
    left: -100px;
    /* top: calc(100% + 5px); */
  }
`;
export const TooltipCard = styled.div`
  position: relative;
  & ${TooltipText}:hover + ${TooltipBox} {
    visibility: visible;
    color: ${(props) => props.theme.white};
    background-color: rgba(0, 0, 0, 0.8);
    width: 300px;
    padding: 8px 8px;
    &:before {
      border-color: transparent transparent rgba(0, 0, 0, 0.8)
        rgba(0, 0, 0, 0.8);
    }
  }

  @media (max-width: 675px) {
    width: 200px;
  }
`;

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  width: 100%;
  color: ${(props) => props.theme.white};
  padding: 0.5rem 0.2rem;
  border: none;
  outline: none;
  justify-content: center;
  border-radius: 4px;

  background: linear-gradient(
    45deg,
    rgba(34, 92, 176, 1) 0%,
    rgba(60, 173, 196, 1) 52%,
    rgba(34, 92, 176, 1) 100%
  );
  background-size: 300% 300%;

  animation: colors 4s ease infinite;
  @keyframes colors {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

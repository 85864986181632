export const cores = [
  '#ff4560',
  '#00e396',
  '#008ffb',
  '#feb019',
  '#775DD0',
  '#FF1493',
  '#00FFFF',
  '#FFD700',
  '#FF69B4',
  '#FFA500',
  '#32CD32',
  '#FF4500',
  '#9400D3',
  '#FF8C00',
  '#FF00FF',
  '#FFFF00',
];

import styled from 'styled-components';

export const FeaturesInformationContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    font-weight: 500;
    font-size: 2rem;
    text-align: center;
  }
  button {
    all: unset;
    background: ${(props) => props.theme.primary};
    padding: 1.25rem;
    text-align: center;
    width: 100%;
    max-width: 13.125rem;
    border-radius: 8px;
    color: ${(props) => props.theme.white};
    font-weight: 500;
    font-size: 1.125rem;

    margin-top: 2rem;
  }
`;

export const ContentBoxInformation = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2.25rem;
  width: 100vw;
  max-width: 70rem;
  justify-content: center;
  margin-top: 4.25rem;

  .blueLogo {
    width: 100%;
    max-width: 20.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 480px) {
    padding: 0 1rem;
  }
`;

export const CardDataContent = styled.div`
  width: 100%;
  max-width: 20.625rem;
  border-radius: 16px;
  overflow: hidden;
  padding: 1rem;
  background-color: ${(props) => props.theme.white};
  box-shadow: 4px 4px 12px 0px rgba(76, 76, 76, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;

  header {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 0.75rem;
    span {
      color: ${(props) => props.theme.primary};
      font-size: 0.625rem;
    }
    h5 {
      font-size: 1rem;
      color: ${(props) => props.theme['gray-700']};
      font-weight: 500;
    }

    i {
      position: absolute;
      right: -2.75rem;
      top: -2.75rem;

      color: ${(props) => props.theme.white};
      background-color: ${(props) => props.theme.primary};
      width: 4.875rem;
      height: 4.875rem;
      padding: 0.75rem;
      border-radius: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      font-size: 2rem;
    }
  }

  p {
    font-size: 0.6875rem;
    color: #606060;
  }
`;

import ReactApexChart from 'react-apexcharts';
import { IPropsDataCotation } from '../../GraphicCotation';
import {
  CardHeaderValue,
  FilterSelect,
  FiltersDate,
  GraphicCotationHistoryContainer,
  HeaderGraphic,
  PercentValue,
} from './styles';
import moment from 'moment';
import { transformMaskCurrency } from '../../../../../utils/transformMaskCurrency';
import { ArrowDown, ArrowUp } from '@phosphor-icons/react';
import ReactLoading from 'react-loading';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';

interface IPropsGraphicCotationHistory {
  headerValues: {
    annualized_return: number;
    five_year_return: number | null;
    total_return: number;
    variation: number;
  };
  data: IPropsDataCotation[];
  color: string;
  filtersHistoryCotation: {
    period: '1M' | '3M' | '6M' | '1A' | '5A' | 'MAX';
  };
  setFiltersHistoryCotation: Function;
  loading: boolean;
}

export function GraphicCotationHistory({
  data,
  headerValues,
  color,
  filtersHistoryCotation,
  setFiltersHistoryCotation,
  loading,
}: IPropsGraphicCotationHistory) {
  const newData = data.map((item) => {
    const date = item.date;

    return date;
  });

  const option = {
    legend: {
      bottom: 17,
    },
    tooltip: {
      trigger: 'axis',
      valueFormatter: (val: any) => transformMaskCurrency(val),

      axisPointer: {
        axis: 'x',
        label: {
          show: true,
          formatter: (val: any) => {
            return moment(val.value).format('L');
          },
        },
      },
    },
    grid: {
      // containLabel: true,
      height: 'auto',
      width: 'auto',
    },
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: 'none',
        },
        restore: {},
        saveAsImage: {},
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: newData,

      axisLabel: {
        formatter: function (value: any, index: number) {
          if (filtersHistoryCotation.period === '5A') {
            return moment(value).format('MMM/YYYY');
          } else if (filtersHistoryCotation.period === 'MAX') {
            return moment(value).format('MMM/YYYY');
          } else if (
            filtersHistoryCotation.period === '1A' ||
            filtersHistoryCotation.period === '6M' ||
            filtersHistoryCotation.period === '3M'
          ) {
            return moment(value).format('MMM/YYYY');
          } else {
            return moment(value).format('DD MMM');
          }
        },
      },
      label: {
        show: true,
      },
    },
    yAxis: {
      type: 'value',

      min: function (value: any) {
        return value.min - 5;
      },
      axisLabel: {
        formatter: function (value: any, index: number) {
          return transformMaskCurrency(value);
        },
      },
    },

    series: [
      {
        name: 'Fechamento',
        type: 'line',
        symbol: 'none',
        sampling: 'lttb',
        itemStyle: {
          color: '#3cd2f7',
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#5adbfb',
            },
            {
              offset: 1,
              color: '#3cd2f7',
            },
          ]),
        },

        data: data.map((item) => item?.close),
      },
    ],
  };

  return (
    <GraphicCotationHistoryContainer>
      <HeaderGraphic>
        <h2>
          {transformMaskCurrency(data[data.length - 1]?.close ?? 0)}{' '}
          <PercentValue upValue={headerValues.variation > 0}>
            {headerValues.variation > 0 ? <ArrowUp /> : <ArrowDown />}
            {headerValues?.variation ? headerValues?.variation.toFixed(2) : 0}%
          </PercentValue>
        </h2>
        <CardHeaderValue style={{ marginRight: '1rem' }}>
          <h6>Percentual acumulado</h6>
          <strong>{headerValues.annualized_return.toFixed(2)}%</strong>
          <span>Ult. 12 meses</span>
        </CardHeaderValue>
        <CardHeaderValue>
          <h6>Percentual acumulado</h6>
          <strong>
            {headerValues.five_year_return
              ? headerValues.five_year_return.toFixed(2)
              : 0}
            %
          </strong>
          <span>Ult. 5 anos</span>
        </CardHeaderValue>
      </HeaderGraphic>
      <FiltersDate>
        <FilterSelect
          isSelected={filtersHistoryCotation.period === '1M'}
          onClick={() => setFiltersHistoryCotation({ period: '1M' })}
        >
          1 Mês
        </FilterSelect>
        <FilterSelect
          isSelected={filtersHistoryCotation.period === '3M'}
          onClick={() => setFiltersHistoryCotation({ period: '3M' })}
        >
          3 Meses
        </FilterSelect>
        <FilterSelect
          isSelected={filtersHistoryCotation.period === '6M'}
          onClick={() => setFiltersHistoryCotation({ period: '6M' })}
        >
          6 Meses
        </FilterSelect>
        <FilterSelect
          isSelected={filtersHistoryCotation.period === '1A'}
          onClick={() => setFiltersHistoryCotation({ period: '1A' })}
        >
          1 Ano
        </FilterSelect>
        <FilterSelect
          isSelected={filtersHistoryCotation.period === '5A'}
          onClick={() => setFiltersHistoryCotation({ period: '5A' })}
        >
          5 Anos
        </FilterSelect>
        <FilterSelect
          isSelected={filtersHistoryCotation.period === 'MAX'}
          onClick={() => setFiltersHistoryCotation({ period: 'MAX' })}
        >
          Max
        </FilterSelect>
      </FiltersDate>

      {loading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '2rem',
          }}
        >
          <ReactLoading
            color="var(--primary)"
            type="spinningBubbles"
            width={120}
            height={120}
          />
        </div>
      ) : (
        <ReactECharts option={option} notMerge={true} />
      )}
    </GraphicCotationHistoryContainer>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  width: 100%;

  flex-direction: column;
  background: ${(props) => props.theme.white};
  padding: 1rem;
  border-radius: 8px;
`;

export const RowCards = styled.div`
  display: grid;
  grid-template-columns: 1fr 15.625rem;
  width: 100%;
  gap: 1rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Card = styled.div`
  width: 100%;
  background: ${(props) => props.theme.white};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  box-shadow: ${(props) => props.theme['card-shadow-2']};

  p {
    margin: 0;
    font-weight: 400;
    font-size: 1rem;
    color: ${(props) => props.theme['gray-200']};
  }

  strong {
    font: 500 1rem 'Poppins', sans-serif;
    color: #000000;
    line-height: 1.8;
  }
  h3 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 2.8;
  }

  a {
    justify-content: flex-end;
    display: flex;
    color: ${(props) => props.theme.primary}!important;
    transition: filter 0.3s;

    :hover {
      filter: brightness(0.9);
    }
  }
`;

export const CardMoney = styled.div`
  width: 100%;
  background: ${(props) => props.theme.white};
  box-shadow: 1px 1px 6px 0px #0000001a;
  border-bottom: 1px solid #EC6643;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.75rem;

  span {
    font-weight: 400;
    font-size: 0.75rem;
    color: ${(props) => props.theme['gray-500']};
  }
  strong {
    font-size: 1.25rem;
    font-weight: 500;
    color: ${(props) => props.theme['gray-400']};
  }
`;

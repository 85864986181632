import { Check, MinusCircle, X } from '@phosphor-icons/react';
import { transformMaskCurrency } from '../../../../../../../../../utils/transformMaskCurrency';
import {
  ButtonContent,
  ButtonSelect,
  CardPlanContainer,
  ContentTextModal,
  FunctionalityContent,
  HeaderCard,
  IconModalContent,
  InformationText,
  ListFunctionalitiesContent,
  PriceContent,
  PromotionCard,
  PromotionIcon,
  TypePlan,
} from './styles';
import { useTitleAndUser } from '../../../../../../../../../hooks/userTitle';
import { useState } from 'react';
import { ModalDesigner } from '../../../../../../../../../components/ModalDesigner';
import { ButtonDesign } from '../../../../../../../../../components/ButtonDesign';
import promotionImg from '../../../../../../../../../assets/promotion.png';

interface IPropsPlan {
  id: string;
  title: string;
  value: number;
  type: 'Anual' | 'Mensal' | 'Free';
  information: string | null;
  is_active: boolean;
  discount: string;
  days: string;
  manageUrl: string;
  description: string;

  funcionalities: Array<{
    label: string;
    disabled: boolean;
  }>;
}

interface IPropsCardPlan {
  plan: IPropsPlan;
  currentPlanId: number | string;
  setSelectedPlan: (id: IPropsPlan) => void;
  onClose: () => void;
  idPremiumPlan: string;
}

export function CardPlan({
  plan,
  currentPlanId,
  onClose,
  idPremiumPlan,

  setSelectedPlan,
}: IPropsCardPlan) {
  // @ts-ignore
  const { user } = useTitleAndUser();
  const [showModalWarning, setShowModalWarning] = useState(false);
  const isPromotion = plan.description === 'Promoção';

  const valueYear = isPromotion ? plan.value * 36 : plan.value * 12;
  const valueMonth: any =
    (isPromotion ? plan.value / 36 : plan.value / 12) -
    ((isPromotion ? plan.value / 36 : plan.value / 12) !== 0 ? 0.01 : 0);

  const valueForYearIfPromotion = plan.value / 2;
  const totalForThreeYears = valueForYearIfPromotion * 3;

  const originalValuePro = 286.8;
  const originalValuePremium = 334.8;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: !isPromotion ? '60px' : 0,
      }}
    >
      {isPromotion && (
        <PromotionCard>
          <span>Aniversário iBox6</span>
        </PromotionCard>
      )}
      <CardPlanContainer
        className={`${window.screen.width <= 1100 && 'keen-slider__slide '}`}
        typePlan={plan.type}
      >
        {!isPromotion && (
          <TypePlan typePlan={plan.type}>
            <span>{plan.type}</span>
          </TypePlan>
        )}
        {isPromotion && (
          <PromotionIcon>
            <img src={promotionImg} alt="" />
          </PromotionIcon>
        )}
        <HeaderCard>
          <h3>
            iBoxer <span>{plan.title}</span>
          </h3>

          <PriceContent>
            {isPromotion && (
              <p className="promotionText">
                de <span>{transformMaskCurrency(totalForThreeYears)}</span> por{' '}
                <strong>{transformMaskCurrency(plan.value)}</strong> <br /> o
                equivalente em 3 anos a
              </p>
            )}
            {plan.type !== 'Free' && (
              <p
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  color: '#6A717E',
                }}
              >
                De{' '}
                <span
                  style={{
                    textDecorationColor: '#EC6643',
                    textDecoration: 'line-through',
                    marginLeft: '0.5rem',
                    marginRight: '0.5rem',
                  }}
                >
                  {plan.title === 'Pro'
                    ? transformMaskCurrency(originalValuePro)
                    : transformMaskCurrency(originalValuePremium)}
                </span>
                Por{' '}
              </p>
            )}
            {plan.type === 'Free' ? (
              'Esse plano é 100% gratuito '
            ) : plan.type !== 'Mensal' ? (
              <span className="pricePromotion" style={{ color: '#242f38' }}>
                <span
                  style={{
                    color: '#242f38',
                    fontSize: '3rem',
                    fontWeight: 600,
                  }}
                >
                  {transformMaskCurrency(plan.value)}
                </span>{' '}
                à vista
                <br />
              </span>
            ) : (
              ` `
            )}
            {plan.type !== 'Anual' ? (
              <h4>
                {transformMaskCurrency(
                  plan.type !== 'Mensal' ? valueMonth : plan.value
                )}
                <span>/mês</span>
              </h4>
            ) : (
              <h5>
                <span>Equivalente mensal à </span>
                {transformMaskCurrency(
                  (plan.type as any) !== 'Mensal' ? valueMonth : plan.value
                )}
                <span>/mês</span>
              </h5>
            )}

            {/* {!isPromotion && (
              <p>
                {plan.type === 'Free'
                  ? 'Esse plano é 100% gratuito '
                  : plan.type !== 'Mensal'
                  ? `Escolhendo esse plano você paga ${transformMaskCurrency(
                      plan.value
                    )} à vista`
                  : `Escolhendo esse plano você paga ${transformMaskCurrency(
                      valueYear
                    )} ao ano `}
              </p>
            )} */}
          </PriceContent>
          {plan.type !== 'Free' ? (
            <InformationText>
              <h2>{plan.information}</h2>
            </InformationText>
          ) : (
            <InformationText>
              <h2>APÓS 30 DIAS</h2>
            </InformationText>
          )}
        </HeaderCard>
        <ListFunctionalitiesContent
          style={plan.type === 'Free' ? { marginTop: '1rem' } : {}}
        >
          {plan.funcionalities.map((func) => (
            <li key={func.label}>
              <FunctionalityContent disabled={func.disabled}>
                {func.disabled ? (
                  <MinusCircle weight="bold" />
                ) : (
                  <Check weight="bold" />
                )}
                <strong>{func.label}</strong>
              </FunctionalityContent>
            </li>
          ))}
        </ListFunctionalitiesContent>
        <ButtonSelect
          currentPlan={
            Number(plan.id) === currentPlanId || plan.title === 'Free'
          }
          onClick={() => {
            if (plan.id.includes('desativado')) {
              setShowModalWarning(true);
            } else if (plan.title === 'Free') {
              onClose();
            }
          }}
        >
          {!plan.id.includes('desativado') ? (
            <a
              href={
                plan.title === 'Free'
                  ? '#'
                  : `https://${plan.manageUrl.replace(
                      'www.',
                      ''
                    )}?checkoutMode=2&email=${user.email}&name=${
                      user.Name
                    }&off=${plan.id}&bid=1697046498395&src=Pagibox6`
              }
            >
              {plan.id === currentPlanId
                ? 'Plano atual'
                : plan.title === 'Free'
                ? 'Plano FREE'
                : `Quero ser ${plan.title}`}
            </a>
          ) : plan.id === currentPlanId ? (
            'Plano atual'
          ) : plan.title === 'Free' ? (
            'Plano FREE'
          ) : (
            `Quero ser ${plan.title}`
          )}
        </ButtonSelect>

        <ModalDesigner
          id="warningPlan"
          isOpen={showModalWarning}
          setState={setShowModalWarning}
          size="md"
        >
          <IconModalContent>
            <i>
              <X color="white" size={48} />
            </i>
          </IconModalContent>
          <ContentTextModal>
            <p>
              Infelizmente por questões de calculo de imposto de renda, o plano
              premium só está disponível no modelo anual.
            </p>
            <ButtonContent>
              <ButtonDesign
                isOutline
                className="outlineGray"
                type="button"
                onClick={() => setShowModalWarning(false)}
              >
                Escolher outro plano
              </ButtonDesign>
              <ButtonDesign
                type="button"
                onClick={() => {
                  window.open(
                    `https://${plan.manageUrl.replace(
                      'www.',
                      ''
                    )}?checkoutMode=2&email=${user.email}&name=${
                      user.Name
                    }&off=${idPremiumPlan}&bid=1697046498395`
                  );
                }}
              >
                Ir para o premium anual
              </ButtonDesign>
            </ButtonContent>
          </ContentTextModal>
        </ModalDesigner>
      </CardPlanContainer>
    </div>
  );
}

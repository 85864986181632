import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const TabContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  background: ${(props) => props.theme.white};
  white-space: nowrap;
  overflow-x: auto;
  width: 100%;

  select {
    border: ${(props) => props.theme.border};
    border-radius: 4px;
    color: ${(props) => props.theme.black};
    padding: 0.5rem;
  }

  .buttonContent {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    align-items: center;
    strong {
      color: ${(props) => props.theme['gray-700']};
      font-size: 1rem;
    }
  }

  ::-webkit-scrollbar {
    width: 12px;
    height: 0; /* width of the entire scrollbar */
  }

  .active {
    color: ${(props) => props.theme.primary};
    font: 500 1rem 'Poppins', sans-serif;
    background: #e7f4ff;
  }
  .active:first-child {
    border-radius: 8px 0 0 8px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  @media (max-width: 656px) {
    .buttonContent {
      flex-direction: column;
    }
  }
`;

export const ButtonTab = styled.button`
  border: none;
  background: transparent;
  transition: all 0.3s;
  font-size: 1rem;
  border-bottom: 2px solid transparent;
`;

export const ContentTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  select {
    max-width: 250px;
    padding: 0.5rem;
    border-radius: 8px;
    border: ${(props) => props.theme.border};
    color: ${(props) => props.theme.black};
  }
  .contentFinalValue {
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.black};
  }

  .linkDarf {
    cursor: pointer;
    transition: color 0.3s;

    :hover {
      color: ${(props) => props.theme.primary};
    }
  }

  a {
    color: ${(props) => props.theme.black};

    :hover {
      color: ${(props) => props.theme.primary};
    }
  }
`;

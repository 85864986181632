import React, { useEffect, useState } from 'react';
import axios from 'axios';
import copy from 'copy-to-clipboard';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  CalendarBlank,
  ChatCircle,
  ShareNetwork,
  ThumbsUp,
} from '@phosphor-icons/react';
import { useForm } from 'react-hook-form';
import * as Constants from '../Constants';
import { useTitleAndUser } from '../hooks/userTitle';
import { handleToast } from '../components/Toast';
import { socialRoutes, videosRoutes } from '../utils/RoutesApi';
import { Comment } from '../components/Comments';
import { UserAvatar } from '../components/Avatar';
import {
  Button,
  Column,
  Comments,
  Container,
  ContainerComments,
  ContentVideo,
  ContentVideoList,
  DataContentVideo,
  ListVideos,
  NewComment,
} from './watchStyles';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Watch = () => {
  const { setTitle, user } = useTitleAndUser();
  const { handleSubmit, register } = useForm();
  // query and navigate
  const navigate = useHistory();
  let query = useQuery();
  const v = query.get('v');
  const url = `https://player-vz-447732c4-8c0.tv.pandavideo.com.br/embed/?v=${v}`;

  // User Information State
  const userInfo = useSelector((state) => state.auth.auth.user.id);
  const is_authenticated = true;

  // InitialMount State
  const [isInitialMount, setIsInitialMount] = useState(true);

  const [like, setLike] = useState({
    like_id: '',
    is_liked: false,
  });
  const [dislike, setDislike] = useState({
    dislike_id: '',
    is_disliked: false,
  });

  const [isValidWidth, setIsValidWidth] = useState(false);
  const [listVideos, setListVideos] = useState({
    count: 0,
    current: 0,
    data: [],
    links: { next: null, previous: null },
    total_pages: 0,
  });
  const [selectedVideo, setSelectedVideo] = useState({
    category: 0,
    channel: 0,
    channelThumbnail: '',
    channelTitle: '',
    comment: [],
    date: '',
    description: '',
    dislike: [],
    id: 0,
    like: [],
    thumbnail: '',
    video_id: '',
    view: [],
  });
  useEffect(() => {
    handleGetAllVideos();
  }, []);
  useEffect(() => {
    setTitle(selectedVideo.title);
  }, [selectedVideo]);
  useEffect(() => {
    if (selectedVideo) {
      if (selectedVideo.like) {
        let filterLike = selectedVideo.like.filter(
          (item) => item.user === userInfo
        )[0];
        if (filterLike) {
          setLike({
            is_liked: filterLike.is_liked,
            like_id: filterLike.id,
          });
        }
      }
    }
  }, [selectedVideo]);

  useEffect(() => {
    if (window.screen.width <= 1400) {
      setIsValidWidth(true);
    } else {
      setIsValidWidth(false);
    }
  }, []);
  //get list all vídeos

  async function handleGetAllVideos() {
    let filterSelected = {};
    await axios
      .get(`${process.env.REACT_APP_API_URL}/videoscube/api/video/`, {
        params: {
          page_size: 10,
          page: 1,
        },
      })
      // 18155a76-774c-43aa-bd55-c2b59c92208d
      .then((response) => {
        let newData = {
          count: response.data.count,
          current: response.data.current,
          data: response.data.data.filter((item) => item.video_id !== v),
          links: response.data.links,
          total_pages: response.data.total_pages,
        };

        setListVideos(newData);
      })
      .catch((err) => {});
    await axios
      .get(`${process.env.REACT_APP_API_URL}/videoscube/api/video/`, {
        params: {
          page_size: 10,
          page: 1,
          search: v,
        },
      })
      // 18155a76-774c-43aa-bd55-c2b59c92208d
      .then((response) => {
        filterSelected = response.data.data.filter(
          (item) => item.video_id === v
        )[0];
      })
      .catch((err) => {});

    await axios
      .get(
        `${process.env.REACT_APP_API_URL}${videosRoutes.LIST_COMMENTS_VIDEO}`,
        {
          params: {
            video__id: filterSelected.id,
          },
        }
      )
      .then((comment) => {
        filterSelected.comment = comment.data
          .reverse()
          .filter((com) => !com.reply);
        setSelectedVideo(filterSelected);
      })
      .catch((err) => {});
  }

  // Handling Like Event
  const HandleLike = () => {
    // Setting DisLike to False if there is Like
    if (dislike.is_disliked === true) {
      setDislike((prevState) => ({
        ...prevState,
        is_disliked: false,
      }));
      const x = document.getElementById('dislike-count');
      x.innerText = parseInt(x.innerText) - 1;
      axios
        .delete(
          `${Constants.api}/videoscube/api/dislike/${dislike.dislike_id}/`
        )
        .then((response) => {
          setDislike((prevState) => ({
            ...prevState,
            dislike_id: '',
            is_disliked: false,
          }));
        })
        .catch((error) => {});
    }
    // If Video is not Liked
    if (is_authenticated === true && like.is_liked === false) {
      setLike((prevState) => ({
        ...prevState,
        is_liked: true,
      }));

      axios
        .post(`${Constants.api}/videoscube/api/like/`, {
          is_liked: true,
          video: selectedVideo.id,
          user: userInfo,
        })
        .then((response) => {
          setLike((prevState) => ({
            ...prevState,
            like_id: response.data.id,
            is_liked: true,
          }));
        })
        .catch((error) => {});
    }
    // If Video is Already Liked
    else if (is_authenticated === true && like.is_liked === true) {
      setLike((prevState) => ({
        ...prevState,
        is_liked: false,
      }));

      axios
        .delete(`${Constants.api}/videoscube/api/like/${like.like_id}/`)
        .then((response) => {
          setLike((prevState) => ({
            ...prevState,
            like_id: '',
            is_liked: false,
          }));
        })
        .catch((error) => {});
    } else {
      navigate.push('/authorization/credentials/login');
    }
  };

  function createLinkShare() {
    copy(location.href);
    handleToast(false, 'Link copiado com sucesso');
  }

  useEffect(() => {
    if (v != null) {
      axios
        .get(`${Constants.api}/videoscube/api/video/?video_id=${v}`)
        .then((videoResponse) => {
          if (is_authenticated) {
            axios
              .get(
                `${Constants.api}/videoscube/api/like/?video__id=${videoResponse.data[0].id}&user__id=${userInfo}`
              )
              .then((likeResponse) => {
                if (likeResponse.data.length) {
                  setLike((prevState) => ({
                    ...prevState,
                    like_id: likeResponse.data[0].id,
                    is_liked: true,
                  }));
                } else {
                  setLike((prevState) => ({
                    ...prevState,
                    like_id: '',
                    is_liked: false,
                  }));
                }
              })
              .catch((error) => {});
            axios
              .get(
                `${Constants.api}/videoscube/api/dislike/?video__id=${videoResponse.data[0].id}&user__id=${userInfo}`
              )
              .then((dislikeResponse) => {
                if (dislikeResponse.data.length) {
                  setDislike((prevState) => ({
                    ...prevState,
                    dislike_id: dislikeResponse.data[0].id,
                    is_disliked: true,
                  }));
                } else {
                  setDislike((prevState) => ({
                    ...prevState,
                    dislike_id: '',
                    is_disliked: false,
                  }));
                }
              })
              .catch((error) => {});
          }
        })
        .catch((error) => {});
      setIsInitialMount(true);
    }
  }, [v, isInitialMount, is_authenticated, userInfo]);

  function submitComment(data) {
    let newData = {
      describe: data.comment,
      video: selectedVideo.id,
      user: userInfo,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}${videosRoutes.NEW_COMMENT}`,
        newData
      )
      .then((response) => {
        handleToast(false, 'Comentário publicado com sucesso');

        let newCommentUser = {
          describe: response.data.describe,
          id: response.data.id,
          user: {
            display_name: user.Name,
            username: user.username,
            id: user.id,
            profile: {
              banner: user.profile.banner,
              bio: user.profile.bio,
              image: user.profile.image,
              location: user.profile.location,
              website: user.profile.website,
            },
          },
          video: response.data.video,
        };
        setSelectedVideo({
          ...selectedVideo,
          comment: [newCommentUser, ...selectedVideo.comment],
        });
      })
      .catch((err) => {
        handleToast(true, 'Erro ao publicar comentário');
      });
  }

  async function deleteComment(id) {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}${socialRoutes.DELETE_COMMENT_VIDEO(
          id
        )}`
      )
      .then((response) => {
        const newArrayComment = selectedVideo.comment.filter(
          (comment) => comment.id !== id
        );
        setSelectedVideo({
          ...selectedVideo,
          comment: newArrayComment,
        });

        handleToast(false, 'Comentário deletado');
      })
      .catch((err) => {});
  }

  return (
    <Container>
      <Column>
        <ContentVideo>
          <iframe
            width="100%"
            height={isValidWidth ? '400px' : '400px'}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            src={url}
          ></iframe>
        </ContentVideo>
        <DataContentVideo>
          <header className="title">
            <h1>{selectedVideo.title}</h1>
          </header>
          <section>
            <header>
              <div className="userContent">
                <img src="https://storage.googleapis.com/ibox6-wordpress.appspot.com/video/thumbnail/Prancheta_4.png" />
                <h4>{selectedVideo.channelTitle}</h4>
              </div>
              <div className="contentButton">
                <Button
                  color="#FFF"
                  background="var(--primary)"
                  onClick={HandleLike}
                >
                  <ThumbsUp
                    size={24}
                    color="#FFF"
                    weight={like.is_liked ? 'fill' : 'regular'}
                  />
                  Recomendar
                </Button>
                <Button
                  color="#FFF"
                  background="#F8B700"
                  onClick={() => createLinkShare()}
                >
                  <ShareNetwork size={24} color="#e3e3e3" />
                  Compartilhar
                </Button>
              </div>
            </header>
            <br />
            <div className="content">
              <span>{selectedVideo.description}</span>
              <div>
                <p>
                  <ThumbsUp size={24} color="var(--primary)" />{' '}
                  {selectedVideo?.like?.length}{' '}
                  {selectedVideo?.like?.length === 1
                    ? 'Recomendação'
                    : 'Recomendações'}
                </p>
                <p>
                  <CalendarBlank size={24} color="var(--primary)" />
                  {selectedVideo.date &&
                    new Intl.DateTimeFormat('pt-BR').format(
                      new Date(selectedVideo?.date)
                    )}
                </p>
              </div>
            </div>
          </section>
          <ContainerComments>
            <NewComment>
              <UserAvatar user={user} />
              <form onSubmit={handleSubmit(submitComment)}>
                <textarea
                  placeholder="O que você achou do vídeo?"
                  {...register('comment')}
                />

                <Button color="#FFF" background="var(--primary)" type="submit">
                  <ChatCircle size={24} color="#fff" /> Adicionar comentário
                </Button>
              </form>
            </NewComment>
            <Comments>
              {selectedVideo.comment.reverse().map((item) => {
                return (
                  <Comment
                    key={item.id}
                    userProfile={item.user}
                    comment={item.describe}
                    onDeleteComment={deleteComment}
                    commentId={item.id}
                    videoId={selectedVideo.id}
                  />
                );
              })}
            </Comments>
          </ContainerComments>
        </DataContentVideo>
      </Column>
      <Column>
        <ListVideos>
          <header>
            <h3>Veja também:</h3>
          </header>
          {listVideos.data.length > 0 &&
            listVideos.data.map((item, index) => (
              <ContentVideoList key={index} href={`?v=${item.video_id}`}>
                <img src={item.thumbnail} />
                <main>
                  <strong className="description" title={item.title}>
                    {item.title}
                  </strong>
                  <span className="description" title={item.description}>
                    {item.description}
                  </span>

                  <small>
                    {' '}
                    {(() => {
                      const date = new Date(`${item.date}`);
                      return Constants.timeAgo.format(date);
                    })()}
                  </small>
                </main>
              </ContentVideoList>
            ))}
        </ListVideos>
      </Column>
    </Container>
  );
};

export default Watch;

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Switch } from 'antd';
import { useState } from 'react';
import { Container } from './styles';
import { GraphicNote } from '../GraphicNote';
import { transformMaskCurrency } from '../../../../../../utils/transformMaskCurrency';
import { maskValueCards } from '../../../../../../components/ResumoAcao/components/maskValueCards';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontFamily: 'Poppins, sans-serif',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: 'Poppins, sans-serif',
  },
}));

const StyledBody = styled(TableBody)(
  ({ theme }) => `
  overflow-y: auto;
  max-height:500px;

`
);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface IPropsTableCompareAssets {
  actives: {
    nota: {
      codigo_fii: string;
      nome_fundo: string;
      nota_final: number;
      data_funcionamento_numerico: number;
      qtde_ativos: number;
      vacancia_fisica: number;
      dividendo_yeld: number;
      valor_patrimonial_por_cota: number;
      tipo_fii: string;
    };
    comparar: {
      ultimo_dy: number;
      ultimo_valor_patrimonial: number;
      ultimo_patrimonio_liquido: number;
      ultimo_p_vp: number;
      ultimo_vacancia_fisica: number;
      ultimo_qtde_ativos: number;
    };
  }[];
}

export function NewTableCompareAssets({ actives }: IPropsTableCompareAssets) {
  const assets = actives.map((active) => active.nota.codigo_fii);
  const header = ['Visualizar notas', ...assets];
  const [showNote, setShowNote] = useState<boolean>(false);

  return (
    <Container>
      <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
        <Table
          sx={{ minWidth: 700 }}
          aria-label="customized table"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              {header.map((item) => (
                <StyledTableCell key={item}>
                  {item === 'Visualizar notas' ? (
                    <>
                      {item}{' '}
                      <Switch
                        checked={showNote}
                        onClick={() => setShowNote(!showNote)}
                        size="small"
                      />
                    </>
                  ) : (
                    item
                  )}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <StyledBody>
            <StyledTableRow>
              <StyledTableCell>Gráfico das notas</StyledTableCell>
              {actives.map((active) => (
                <StyledTableCell key={active.nota.codigo_fii}>
                  <GraphicNote active={active.nota} />
                </StyledTableCell>
              ))}
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell>Dividendo (DY)</StyledTableCell>
              {showNote
                ? actives.map((active) => (
                    <StyledTableCell key={active.nota.codigo_fii}>
                      {active.nota.dividendo_yeld === 3
                        ? 100
                        : active.nota.dividendo_yeld === 2
                        ? 67
                        : 33}
                      %
                    </StyledTableCell>
                  ))
                : actives.map((data) => (
                    <StyledTableCell key={data.nota.codigo_fii}>
                      {data.comparar.ultimo_dy.toFixed(2)}%
                    </StyledTableCell>
                  ))}
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell>Valor patrimonial por cota</StyledTableCell>
              {showNote
                ? actives.map((active) => (
                    <StyledTableCell key={active.nota.codigo_fii}>
                      {active.nota.valor_patrimonial_por_cota === 3
                        ? 100
                        : active.nota.valor_patrimonial_por_cota === 2
                        ? 67
                        : 33}
                      %
                    </StyledTableCell>
                  ))
                : actives.map((data) => (
                    <StyledTableCell key={data.nota.codigo_fii}>
                      {transformMaskCurrency(
                        data.comparar.ultimo_valor_patrimonial
                      )}
                    </StyledTableCell>
                  ))}
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell>
                {showNote ? 'Data de funcionamento' : 'Valor patrimonial'}
              </StyledTableCell>
              {showNote
                ? actives.map((active) => (
                    <StyledTableCell key={active.nota.codigo_fii}>
                      {active.nota.data_funcionamento_numerico === 3
                        ? 100
                        : active.nota.data_funcionamento_numerico === 2
                        ? 67
                        : 33}
                      %
                    </StyledTableCell>
                  ))
                : actives.map((data) => (
                    <StyledTableCell key={data.nota.codigo_fii}>
                      {maskValueCards(
                        Number(
                          data.comparar.ultimo_patrimonio_liquido.toFixed(0)
                        )
                      )}
                    </StyledTableCell>
                  ))}
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell>Quant. ativos</StyledTableCell>
              {showNote
                ? actives.map((active) => (
                    <StyledTableCell key={active.nota.codigo_fii}>
                      {active.nota.qtde_ativos === 3
                        ? 100
                        : active.nota.qtde_ativos === 2
                        ? 67
                        : 33}
                      %
                    </StyledTableCell>
                  ))
                : actives.map((data) => (
                    <StyledTableCell key={data.nota.codigo_fii}>
                      {data.comparar.ultimo_qtde_ativos}
                    </StyledTableCell>
                  ))}
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell>Vacância física</StyledTableCell>
              {showNote
                ? actives.map((active) => (
                    <StyledTableCell key={active.nota.codigo_fii}>
                      {active.nota.vacancia_fisica === 3
                        ? 100
                        : active.nota.vacancia_fisica === 2
                        ? 67
                        : 33}
                      %
                    </StyledTableCell>
                  ))
                : actives.map((data) => (
                    <StyledTableCell key={data.nota.codigo_fii}>
                      {data.comparar.ultimo_vacancia_fisica.toFixed(2)}%
                    </StyledTableCell>
                  ))}
            </StyledTableRow>
            {!showNote && (
              <StyledTableRow>
                <StyledTableCell>P/VP</StyledTableCell>

                {actives.map((data) => (
                  <StyledTableCell key={data.nota.codigo_fii}>
                    {data.comparar.ultimo_p_vp.toFixed(2)}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            )}
          </StyledBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

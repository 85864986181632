import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  text-align: center;

  border-radius: 8px;
  justify-content: center;
  font-size: 1.2rem;
  box-shadow: ${(props) => props.theme['card-shadow-2']};
  overflow-y: auto;

  ::-webkit-scrollbar-track {
    background: rgba(60, 60, 60, 0.78);
    display: flex;
    border-radius: 100px;
    height: 5px;
  }
  ::-webkit-scrollbar {
    display: flex;
    width: 5px !important;
    background: #f4f4f4;
    border-radius: 100px;
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    display: flex;
    background: rgba(60, 60, 60, 1);
    width: 5px !important;
    border-radius: 100px;
    height: 5px;
  }
`;

export const ColTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  header {
    display: flex;
    width: 200px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    img {
      height: 1.5rem;
    }

    h6 {
      font-size: 1.5rem;
      width: 100%;
    }
  }
  strong {
    width: 100%;
  }
`;

export const Divider = styled.div`
  display: flex;
  width: 1px;
  height: auto;
  background: rgba(21, 21, 21, 0.3);
`;

export const ContentPercentNota = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  h6 {
    position: absolute;
    margin: 0 !important;
  }
`;

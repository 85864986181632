import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme['card-shadow-2']};
  border-radius: 4px;
  padding: 0.75rem 1.5rem;
  width: 100%;
  
`;

import styled from 'styled-components';

export const DashboardAdminContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ContentCards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  gap: 1rem;
  width: 100%;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: ${(props) => props.theme['gray-600']};
  box-shadow: 4px 4px 12px 0px rgba(255, 255, 255, 0.05);
  padding: 1rem;
  width: 100%;
  border-radius: 8px;
  justify-content: space-between;
`;

interface IPropsContentFilterDate {
  showFilter: boolean;
}

export const ContentFilterDate = styled.div<IPropsContentFilterDate>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  right: 0;
  /* .rdrDateRangePickerWrapper,
  .rdrDefinedRangesWrapper,
  .rdrStaticRanges,
  .rdrStaticRange,
  .rdrDateInput,
  .rdrMonthAndYearWrapper,
  .rdrDateInput > input,
  .rdrMonth {
    background: #404040 !important;
    color: white;
  }
  .rdrDateDisplayWrapper,
  .rdrDateDisplay,
  .rdrNextPrevButton,
  .rdrPprevButton,
  .rdrNextPrevButton > i,
  .rdrPprevButton > i {
    background: #606060;
    color: white;
  } */
  .buttonFilter {
    all: unset;
    line-height: 0;
    color: ${(props) => props.theme.white};
    padding: 0.25rem;
    background-color: ${(props) => props.theme['gray-600']};
    border-radius: 100%;
    width: 1.25rem;
    height: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      width: 1rem;
      height: 1rem;
    }
  }

  .contentFilter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    width: ${(props) => (props.showFilter ? 'auto' : 0)};
    height: ${(props) => (props.showFilter ? 'auto' : 0)};
    opacity: ${(props) => (props.showFilter ? 1 : 0)};
    display: ${(props) => (props.showFilter ? 'flex' : 'none')};

    transition: all 0.3s;

    .searchButton {
      all: unset;
      padding: 0.5rem;
      border-radius: 4px;
      background: ${(props) => props.theme['gray-500']};
      color: ${(props) => props.theme.white};
    }
  }
`;

export const FilterExhibition = styled.span`
  display: flex;
  align-items: center;
  background-color: rgba(48, 48, 48, 0.5);
  border-radius: 12px;
  padding: 0.25rem;
  width: 18rem;
  text-align: center;
  justify-content: center;
  margin: 0;
  color: ${(props) => props.theme.white};
  font-size: 0.75rem;
`;

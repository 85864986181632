import styled from 'styled-components';

// export const Header = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: 100%;
// `;


export const BannerContent = styled.img`
  width: 118%;
  max-width: 1235px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-left: -3%;
`;

export const BannerContent2 = styled.img`
  width: 120%;
  max-width: 1268px;
  cursor: pointer;
  margin-top: 50px;
  margin-bottom: 10px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0;
  padding: 0;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;

  .slick-track {
    display: flex;
    align-items: center;
  }

  .graphicCard {
    padding-bottom: 0;
    span {
      margin: 0;
    }

    strong {
      color: ${(props) => props.theme['gray-700']};
    }
  }
  .containerTutorial {
    margin-left: 0%;
    display: relative;

    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: var(--Spacing-8, 8px);
    border: 1px solid var(--Gray-gray-100, #d4d6db);
    background-color: #fff;
  }

  .tutorial {
    font-size: 18px;
    /* margin: 0; */
    margin-bottom: 1rem;
  }

  .fade .welcomeModal .modal .show {
    background: blue;
  }

  @media (min-width: 480px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

interface IPropsRowDiv {
  isShort: boolean;
}

export const RowDiv1 = styled.div<IPropsRowDiv>`
  display: grid;
  grid-template-columns: 1fr 21.375rem;

  justify-content: space-between;
  width: 100%;
  height: 100%;
  min-height: 250px;
  position: relative;
  gap: 1rem;
  transition: all 0.3s ease-in-out;
  z-index: 10;

  @media (max-width: 1280px) {
    grid-template-columns: 1fr 19.8125rem;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;
export const RowDiv = styled.div<IPropsRowDiv>`
  display: grid;
  grid-template-columns: 1fr 21.375rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 250px;
  position: relative;
  gap: 1rem;
  transition: all 0.3s ease-in-out;
  z-index: 10;

  .ant-carousel .slick-prev {
    left: -18px !important;
    z-index: 10;
    /* height: 150%; */
  }
  .ant-carousel .slick-next {
    right: 0 !important;
  }

  @media (max-width: 1280px) {
    grid-template-columns: 1fr 19.8125rem;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ColDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const ContentCards = styled.div`
  grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
  width: 100%;
  display: flex;
  max-width: 384px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Spacing-8, 8px);

  flex: 1 0 0;

  margin-left: 0;
`;

import { Heading } from '../../../../../../components/DefaultStylesTypography';
import { IPropsDataInfoUser } from '../../@types';
import { DataInfoUsersContainer } from './styles';
import ReactLoading from 'react-loading';

interface IPropsDataInfoUsers {
  data: IPropsDataInfoUser;
  loading: boolean;
}

export function DataInfoUsers({ data, loading }: IPropsDataInfoUsers) {
  const { avg_session_duration, bounce_rate, sessions, users } = data;

  function convertTimeSecondForMinutes(totalSegundos: number) {
    const minutos = Math.floor(totalSegundos / 60);
    const segundosRestantes = totalSegundos % 60;
    return `${minutos}m ${segundosRestantes}s`;
  }

  return (
    <DataInfoUsersContainer>
      <Heading as="h4" variant="quaternary" color="white">
        Indicadores agregados
      </Heading>
      {loading ? (
        <ReactLoading
          type="balls"
          width={100}
          height={100}
          color="var(--primary)"
        />
      ) : (
        <div>
          <span>
            Total de acessos <strong>{users}</strong>
          </span>
          <span>
            Uso em media{' '}
            <strong>
              {convertTimeSecondForMinutes(
                Number(avg_session_duration.toFixed(0))
              )}
            </strong>
          </span>
          <span>
            Taxa de rejeição <strong>{bounce_rate.toFixed(2)}%</strong>
          </span>
          <span>
            Número de sessões <strong>{sessions}</strong>
          </span>
        </div>
      )}
    </DataInfoUsersContainer>
  );
}

import ReactInputMask from 'react-input-mask';
import { InputStyle } from '../../../../components/InputStyle';
import { FormContainer } from './styles';
import { ButtonStyle } from '../../../../components/ButtonStyle';
import { useTitleAndUser } from '../../../../hooks/userTitle';
import { handleToast } from '../../../../components/Toast';
import axios from 'axios';
import { userRoutes } from '../../../../utils/RoutesApi';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { showZodErrorToast } from '../../../../utils/zodToast';
import { useEffect } from 'react';

const validateCPF = (cpf: string) => {
  // Remover caracteres não numéricos
  cpf = cpf.replace(/[^\d]/g, '');

  // Checar se o CPF tem 11 dígitos
  if (cpf.length !== 11) return false;

  // Verificar se todos os dígitos são iguais
  if (/^(\d)\1+$/.test(cpf)) return false;

  // Calcular os dígitos verificadores
  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf[i]) * (10 - i);
  }

  let firstVerifier = (sum * 10) % 11;
  if (firstVerifier === 10 || firstVerifier === 11) {
    firstVerifier = 0;
  }

  if (firstVerifier !== parseInt(cpf[9])) return false;

  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf[i]) * (11 - i);
  }

  let secondVerifier = (sum * 10) % 11;
  if (secondVerifier === 10 || secondVerifier === 11) {
    secondVerifier = 0;
  }

  if (secondVerifier !== parseInt(cpf[10])) return false;

  return true;
};

const schema = z.object({
  cpf: z
    .string()
    .transform((val) => val.replace(/_/g, ''))
    .refine((val) => validateCPF(val), {
      message: 'CPF inválido',
    }),
});

interface IPropsFinishAccount {
  isB3Link?: boolean;
}

export function FinishAccountForm({ isB3Link = false }: IPropsFinishAccount) {
  // @ts-ignore
  const { user, setUser } = useTitleAndUser();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });
  useEffect(() => {
    showZodErrorToast(errors);
  }, [errors]);
  async function submit(data: any) {
    const { cpf } = data;
    if (!cpf) {
      return handleToast(true, 'Informe seu cpf');
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}${userRoutes.USER_FINISHED_REGISTRATION_HOTMART}/${user.id}/`,
        {
          cpf: cpf.toString(),
        }
      );
      setUser({
        ...user,
        cpf,
      });
    } catch (error) {
      const err: any = error;
      if (err.response.data.cpf) {
        return handleToast(true, err.response.data.cpf[0]);
      }
    } finally {
    }
  }
  return (
    <FormContainer onSubmit={handleSubmit(submit)}>
      <p>
        {isB3Link
          ? 'Conecte sua conta à B3 inserindo o seu CPF para completar o cadastro.'
          : 'Para ter acesso a todas as funcionalidades do iBox⁶, por favor insira seu CPF.'}
      </p>
      <InputStyle label="CPF *" isError={errors.cpf ? true : false}>
        <ReactInputMask mask="999.999.999-99" {...register('cpf')} />
      </InputStyle>
      <ButtonStyle type="submit">Cadastrar</ButtonStyle>
    </FormContainer>
  );
}

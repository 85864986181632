import {
  LOADING_TOGGLE_ACTION,
  LOGIN_CONFIRMED_ACTION,
  REFRESH_CONFIRMED_ACTION,
  LOGIN_FAILED_ACTION,
  LOGOUT_ACTION,
  SIGNUP_CONFIRMED_ACTION,
  SIGNUP_FAILED_ACTION,
} from '../actions/AuthActions';

const initialState = {
  access: '',
  auth: {
    access: '',
    expireDate: '',
    refresh: '',
    user: {
      cpf: '',
      created: '',
      email: '',
      id: '',
      is_active: '',
      updated: '',
      username: '',
    },
  },

  feedNext: null,
  feedResults: [],
  postById: {},
  postLikes: {},
  profileLikes: {},
  profilePosts: {},
  repliesByPostId: {},
};

export const reduceById = (arr) =>
  arr.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});

export function AuthReducer(state = initialState, action) {

  if (action.type === SIGNUP_CONFIRMED_ACTION) {
    return {
      ...state,
      auth: action.payload,
      errorMessage: '',
      successMessage: 'Signup Successfully Completed',
      showLoading: false,
    };
  }
  if (action.type === LOGIN_CONFIRMED_ACTION) {
    return {
      ...state,
      auth: action.payload,
      errorMessage: '',
      successMessage: 'Login Successfully Completed',
      showLoading: false,
    };
  }
  if (action.type === REFRESH_CONFIRMED_ACTION) {
    return {
      ...state,
      auth:{
        ...state.auth,
        access:action.payload.access,
        refresh:action.payload.refresh
      },
      errorMessage: '',
      successMessage: 'Refresh Successfully Completed',
      showLoading: false,
    };
  }
  if (action.type === LOGOUT_ACTION) {
    return {
      ...state,
      access: '',
      auth: {
        access: '',
        expireDate: '',
        refresh: '',
        user: {
          cpf: '',
          created: '',
          email: '',
          id: '',
          is_active: '',
          updated: '',
          username: '',
        },
      },
    };
  }

  if (action.type === SIGNUP_FAILED_ACTION || action.type === LOGIN_FAILED_ACTION) {
    return {
      ...state,
      errorMessage: action.payload,
      successMessage: '',
      showLoading: false,
    };
  }

  if (action.type === LOADING_TOGGLE_ACTION) {
    return {
      ...state,
      showLoading: action.payload,
    };
  }
  return state;
}

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
    strong {
      color: black;
    }
  }
`;

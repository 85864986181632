import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
//@ts-ignore
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { IPropsIncomeTax } from '../../@types';
import { transformMaskCurrency } from '../../../../../utils/transformMaskCurrency';


interface IPropsGeneratorPDF {
  data: IPropsIncomeTax[];
  title: string;
  year: number | string;
}

export function generatorPDFTable({ title, data, year }: IPropsGeneratorPDF) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const reportTitle: any = [
    {
      text: `${title} - ${year}`,
      fontSize: 16,
      bold: true,
      margin: [15, 20, 0, 45],
    },
  ];
  const headerDefault = [
    {
      text: 'Mês ref',
      style: 'tableHeader',
      fontSize: 9,
      margin: [0, 2, 0, 2],
    },
    {
      text: 'Lucro líquido',
      style: 'tableHeader',
      fontSize: 9,
      margin: [0, 2, 0, 2],
    },
    {
      text: 'Total de vendas',
      style: 'tableHeader',
      fontSize: 9,
      margin: [0, 2, 0, 2],
    },
    {
      text: 'Imposto retido',
      style: 'tableHeader',
      fontSize: 9,
      margin: [0, 2, 0, 2],
    },
    {
      text: 'Prejuizo acumulado',
      style: 'tableHeader',
      fontSize: 9,
      margin: [0, 2, 0, 2],
    },
    {
      text: 'Base de cálculo IR',
      style: 'tableHeader',
      fontSize: 9,
      margin: [0, 2, 0, 2],
    },
    {
      text: 'Imposto à pagar',
      style: 'tableHeader',
      fontSize: 9,
      margin: [0, 2, 0, 2],
    },
  ];

  function formatCurrencyStyle(val: number) {
    return transformMaskCurrency(val);
  }

  const dataFilter = data.filter(
    (item) => moment(item.ReferenceDate).format('YYYY') === year
  );

  const newData = dataFilter.map((item) => {
    return [
      {
        text: moment(item.ReferenceDate).format('MMMM  YYYY'),
        fontSize: 10,
      },
      {
        text: formatCurrencyStyle(item.Revenue),
        fontSize: 10,
      },
      {
        text: formatCurrencyStyle(item.GrossAmount),
        fontSize: 10,
      },
      {
        text: item.Hold,
        fontSize: 10,
      },
      {
        text: formatCurrencyStyle(item.IR),
        fontSize: 10,
      },
      {
        text: formatCurrencyStyle(item.PrejuizoAcumulado),
        fontSize: 10,
      },
      {
        text: formatCurrencyStyle(item.IRCalculado),
        fontSize: 10,
      },
    ];
  });

  const details: any = [
    {
      table: {
        headerRows: 1,
        widths: ['*', '*', '*', '*', '*', '*', '*'],

        body: [headerDefault, ...newData],
      },
      layout: 'headerLineOnly',
    },
  ];
  function rodape(currentPage: number, pageCount: number) {
    return [
      {
        text: `${currentPage} / ${pageCount}`,
        alignment: 'right',

        fontSize: 9,
        margin: [0, 10, 20, 0],
      },
    ];
  }

  const buildPDF = {
    pageSize: 'A4',
    pageMargins: [15, 50, 15, 40],

    header: [reportTitle],
    content: [
      {
        text: 'As taxas apresentadas incluem somente as tarifas de liquidação, registro, termo/opções e emolumentos da B3. O Usuário é exclusivamente responsável pela conferência e validação das informações utilizadas na apuração do imposto devido, conforme Termos e Condições Gerais de Uso.',
        fontSize: 10,
        margin: [0, 0, 0, 16],
      },
      details,
    ],
    footer: rodape,
  };
  //@ts-ignore
  pdfMake.createPdf(buildPDF).download(`${title} - ${year}`);
}

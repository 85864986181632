import {
  InformationCol,
  MotivationInvestCol,
  MotivationInvestContainer,
  MotivationInvestContent,
} from './styles';
import { Heading } from '../../../../../components/DefaultStylesTypography';

export function MotivationInvest() {
  return (
    <MotivationInvestContainer id="comunidade">
      <div className="blueColor" />
      <div className="yellowColor" />
      <MotivationInvestContent>
        <MotivationInvestCol>
          <header>
            <span />
            <Heading>
              Você não precisa saber TUDO sobre investimentos para começar a
              investir!
            </Heading>
          </header>
          <p>Nós simplificamos tudo para você!</p>
        </MotivationInvestCol>
        <InformationCol>
          <header>
            <Heading as="h6" variant="sextiary">
              O iBox6 é a mais nova plataforma criada para te trazer
              tranquilidade e segurança ao investir.
            </Heading>
          </header>
          <p>
            Acreditamos que investimentos são ferramentas para te trazer MAIS
            qualidade de vida, e não menos. Se você anda estressado ou perdendo
            tempo demais acompanhando seus investimentos, isso significa que
            algo está errado.
          </p>
          <p>
            Foi exatamente por isso que nasceu o iBox6, uma plataforma criada
            com carinho, por especialistas com muita experiência com educação em
            investimentos, para te ajudar a <strong>#investirsempressão</strong>{' '}
            e de forma independente.
          </p>
        </InformationCol>
      </MotivationInvestContent>
    </MotivationInvestContainer>
  );
}

import { Container, ContentAssetNotes } from './styles';

export function ExplanationAssetNotes() {
  return (
    <Container>
      <ContentAssetNotes>
        <h3>Lucro Líquido</h3>
        <p>
          A avaliação do Lucro Líquido é realizada com base no Lucro Líquido
          Crescente e constante. Caso a empresa tenha atingido estes critérios
          em todo período analisado a nota será de 100%
        </p>
      </ContentAssetNotes>

      <ContentAssetNotes>
        <h3>EBITDA</h3>
        <p>
          Avaliação consiste em verificar se a empresa obteve em todo o período
          de dados disponibilizados EBITDA Positivo e Crescente.
          <br /> Caso a empresa tenha atingido estes critérios em todo período
          analisado a nota será de 100%
        </p>
      </ContentAssetNotes>
      <ContentAssetNotes>
        <h3>Dividendos</h3>
        <p>
          Avaliação consiste em verificar se a empresa obteve em todo o período
          de dados disponibilizados DY Positivo e Crescente.
          <br /> Em seguida compara-se o DY da empresa analisada com o DY médio
          das empresas do mesmo setor.
          <br /> Caso a empresa tenha atingido estes critérios em todo período
          analisado a nota será de 100%
        </p>
      </ContentAssetNotes>
      <ContentAssetNotes>
        <h3>Dívida líquida/EBITDA</h3>
        <p>
          Avaliação consiste em verificar se a empresa obteve em todo o período
          de dados disponibilizados DL/EBTIDA decrescente.
          <br /> Em seguida compara-se o DL/EBITDA da empresa analisada com a
          médio das empresas do mesmo setor.
          <br /> Caso a empresa tenha atingido estes critérios em todo período
          analisado a nota será de 100%
        </p>
      </ContentAssetNotes>
      <ContentAssetNotes>
        <h3>Dívida líquida</h3>
        <p>
          Avaliação consiste em verificar se a empresa obteve em todo o período
          de dados disponibilizados Dívida liquida decrescente.
          <br /> Caso a empresa tenha atingido estes critérios em todo período
          analisado a nota será de 100%
        </p>
      </ContentAssetNotes>
      <ContentAssetNotes>
        <h3>Resiliência</h3>
        <p>
          Avaliação consiste em verificar se a empresa obteve em todo o período
          de dados disponibilizados tempo de sobrevivência, para este indicador
          a referência é período superior a 50 anos.
          <br /> Em seguida compara-se o tempo em bolsa da empresa analisada com
          a média das empresas do mesmo setor.
          <br /> Caso a empresa tenha atingido estes critérios em todo período
          analisado a nota será de 100%
        </p>
      </ContentAssetNotes>
      <ContentAssetNotes>
        <h3>Governança</h3>
        <p>
          Esta não é uma avaliação da gestão mas dos indicadores de Governança
          publicados pela B3.
          <br />
          Avaliação consiste em verificar se a empresas possui:
        </p>
        <ul>
          <li>
            {' '}
            Índice de Ações com Governança Corporativa Diferenciada (IGC B3)
          </li>
          <li>Índice de Ações com Tag Along Diferenciado (ITAG B3)</li>
          <li>Índice de Governança Corporativa Trade (IGCT B3)</li>
          <li>Índice de Governança Corporativa – Novo Mercado (IGC-NM B3)</li>
          <li>Índice Carbono Eficiente (ICO2 B3)</li>
          <li>Índice GPTW B3 (IGPTW B3)</li>
          <li>Índice de Sustentabilidade Empresarial (ISE B3)</li>
        </ul>
        <p>
          Caso a empresa tenha atingido estes critérios em todo período
          analisado a nota será de 100%
        </p>
      </ContentAssetNotes>
      <ContentAssetNotes>
        <h3>Indices</h3>
        <p>
          A B3, há mais de 50 anos, desenvolve, calcula e administra uma ampla
          variedade de índices. Estes índices são um importante termômetro do
          mercado financeiro e servem como referência para investidores
          nacionais e internacionais.
          <br /> Nossa avaliação consiste em verificar quais os índices
          (excluindo os de preço) que que a empresa participa, quanto maior a
          quantidade de índices maior a nota.
        </p>
      </ContentAssetNotes>
    </Container>
  );
}

import axios from 'axios';

const api = async (descriptor, nextUrl = null) => {
  const baseURL = process.env.REACT_APP_API_URL;

  descriptor.url = baseURL + descriptor.url;

  instance.interceptors.request.use((request) => {
    return request;
  });
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      const access_token = localStorage.getItem(
        process.env.REACT_APP_USER_VERSION
      );
      if (error.response.status === 401 && access_token) {
        const response = await refreshToken(error);
        return response;
      }
      return Promise.reject(error);
    }
  );

  async function refreshToken(error) {
    return new Promise((resolve, reject) => {
      try {
        const refresh_token = localStorage.getItem(
          process.env.REACT_APP_USER_VERSION
        );
        let token = JSON.parse(refresh_token);
        const header = {
          'Content-Type': 'application/json',
          Authorization: process.env.AUTHORIZATION,
        };
        const parameters = {
          method: 'POST',
          headers: header,
        };
        const body = {
          grant_type: process.env.REACT_APP_USER_VERSION,
          refresh: token.refresh,
        };
        instance
          .post('/api/auth/refresh/', body, parameters)
          .then(async (res) => {
            let obj = {
              access: res.data.access,
              refresh: token.refresh,
              user: token.user,
              expireDate: token.expireDate,
            };
            localStorage.setItem(
              process.env.REACT_APP_USER_VERSION,
              JSON.stringify(obj)
            );
            // localStorage.setItem(process.env.REACT_APP_USER_VERSION, res.data.refresh);
            // Fazer algo caso seja feito o refresh token
            return resolve(res);
          })
          .catch((err) => {
            // Fazer algo caso não seja feito o refresh token
            return reject(error);
          });
      } catch (err) {
        return reject(err);
      }
    });
  }

  const desc = descriptor;
  if (nextUrl) {
    desc.url = nextUrl;
  }

  const { data } = await axios(desc);
  return data;
};

export default api;

export const descriptor = {
  //
  // Post descriptors
  //
  createLike: (postId) => ({
    method: 'post',
    url: `/social/api/posts/${postId}/likes/`,
  }),

  createPost: (data) => ({
    data,
    method: 'post',
    url: '/social/api/posts/',
  }),
  createPostFeed: (data) => ({
    data,
    method: 'post',
    url: '/social/api/posts/',
  }),

  createRepost: (data) => ({
    data,
    method: 'post',
    url: '/social/api/posts/repost/',
  }),

  editPost: (postId, body) => ({
    data: {
      body,
    },
    method: 'patch',
    url: `/social/api/posts/${postId}/`,
  }),

  getFeed: {
    method: 'get',
    url: '/social/api/posts/feed/',
  },
  getFeedParams: (params) => ({
    method: 'get',
    url: `/social/api/posts/feed?hashtag=${params}`,
  }),

  getLikes: (postId) => ({
    method: 'get',
    url: `/social/api/posts/${postId}/likes/`,
  }),

  getPost: (postId) => ({
    method: 'get',
    url: `/social/api/posts/${postId}/`,
  }),

  getPostLikes: (postId) => ({
    method: 'get',
    url: `/social/api/posts/${postId}/likes/`,
  }),

  getPosts: {
    method: 'get',
    url: '/social/api/posts/',
  },

  getProfileLikes: (slug) => ({
    method: 'get',
    url: `/social/api/posts/profile/${slug}/likes/`,
  }),

  getProfilePosts: (slug) => ({
    method: 'get',
    url: `/social/api/posts/profile/${slug}/posts/`,
  }),

  getLongRecommendedPosts: {
    method: 'get',
    url: '/social/api/posts/long-recommended-posts/',
  },

  getRecommendedPosts: {
    method: 'get',
    url: '/social/api/posts/recommended-posts/',
  },

  getReplies: (postId) => ({
    method: 'get',
    url: `/social/api/posts/${postId}/replies/`,
  }),

  removeLike: (postId) => ({
    method: 'delete',
    url: `/social/api/posts/${postId}/likes/`,
  }),

  removePost: (postId) => ({
    method: 'delete',
    url: `/social/api/posts/${postId}/`,
  }),

  //
  // User descriptors
  //
  createFollow: (slug) => ({
    method: 'post',
    url: `/api/${slug}/following/`,
  }),

  createUser: (data) => ({
    data,
    method: 'post',
    url: '/social/api/users/auth/register/',
  }),

  editPassword: (data) => ({
    data,
    method: 'put',
    url: '/social/api/users/edit-password/',
  }),

  editProfile: (data) => ({
    data,
    method: 'patch',
    url: '/api/edit-profile/',
  }),

  editUser: (data) => ({
    data,
    method: 'patch',
    url: '/social/api/users/edit-user/',
  }),

  getCurrentUser: {
    method: 'get',
    url: '/social/api/users/current-user/',
  },

  getFollowers: (slug) => ({
    method: 'get',
    url: `/api/${slug}/followers/`,
  }),

  getFollowing: (slug) => ({
    method: 'get',
    url: `/api/${slug}/following/`,
  }),

  getLongRecommendedUsers: {
    method: 'get',
    url: '/api/ong-recommended-users/',
  },

  getRecommendedUsers: {
    method: 'get',
    url: '/api/ecommended-users/',
  },

  getUser: (slug) => ({
    method: 'get',
    url: `/${slug}`,
  }),

  loginUser: (data) => ({
    data,
    method: 'post',
    url: '/social/api/users/auth/login/',
  }),

  logoutUser: {
    method: 'post',
    url: '/social/api/users/auth/logout/',
  },

  removeFollow: (slug) => ({
    method: 'delete',
    url: `/api/${slug}/following/`,
  }),

  //
  // Search descriptors
  //
  getSearch: (searchString) => ({
    method: 'get',
    url: `/social/api/search/?search=${searchString}`,
  }),

  //
  // Notifications descriptors
  //
  getNotifications: {
    method: 'get',
    url: '/social/api/notifications/',
  },

  getUnreadNotificationsCount: {
    method: 'get',
    url: '/social/api/notifications/unread-count/',
  },

  removeNotification: (notificationId) => ({
    method: 'delete',
    url: `/social/api/notifications/${notificationId}/`,
  }),
};

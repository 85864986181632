import { useEffect, useState } from 'react';
import {
  ButtonTab,
  ContentPages,
  ContentTab,
  IncomeTaxPagesContainer,
} from './styles';
import { useTitleAndUser } from '../../../hooks/userTitle';
import { Dashboard } from './pages/Dashboard';
import { AnualStatement } from './pages/AnnualStatement';
import { StatementTables } from './pages/StatementTables';
import { Transactions } from './pages/Transactions';
import { InicialCustody } from './pages/InitialCustody';

export function IncomeTaxPages() {
  // @ts-ignore
  const { setTitle } = useTitleAndUser();
  const [tab, setTab] = useState(1);

  useEffect(() => {
    setTitle('Imposto de renda');
  }, []);

  return (
    <IncomeTaxPagesContainer>
      <ContentTab>
        <ButtonTab isActive={tab === 1} onClick={() => setTab(1)} type="button">
          Dashboard
        </ButtonTab>
        <ButtonTab isActive={tab === 2} onClick={() => setTab(2)} type="button">
          Relatório Anual
        </ButtonTab>
        <ButtonTab isActive={tab === 3} onClick={() => setTab(3)} type="button">
          Operações Mensais
        </ButtonTab>
        <ButtonTab isActive={tab === 4} onClick={() => setTab(4)} type="button">
          Negociação de Ativos
        </ButtonTab>
        <ButtonTab isActive={tab === 5} onClick={() => setTab(5)} type="button">
          Custódia Inicial
        </ButtonTab>
      </ContentTab>
      <ContentPages>
        {tab === 1 && <Dashboard />}
        {tab === 2 && <AnualStatement />}
        {tab === 3 && <StatementTables />}
        {tab === 4 && <Transactions />}
        {tab === 5 && <InicialCustody />}
      </ContentPages>
    </IncomeTaxPagesContainer>
  );
}

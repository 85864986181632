import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Switch } from 'antd';
import { useState } from 'react';
import { maskValueCards } from '../../../../../../components/ResumoAcao/components/maskValueCards';
import { IPropsRankActive } from '../../../@types/listActiveType';
import { IPropsGraphicData } from '../..';
import { Container } from './styles';
import { GraphicNote } from '../GraphicNote';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontFamily: 'Poppins, sans-serif',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: 'Poppins, sans-serif',
  },
}));

const StyledBody = styled(TableBody)(
  ({ theme }) => `
  overflow-y: auto;
  max-height:500px;

`
);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface IPropsTableCompareAssets {
  actives: IPropsRankActive[];
  dataGraphics: IPropsGraphicData[];
}

export function NewTableCompareAssets({
  actives,
  dataGraphics,
}: IPropsTableCompareAssets) {
  const assets = actives.map((active) => active.Assets);
  const header = ['Visualizar notas', ...assets];
  const [showNote, setShowNote] = useState<boolean>(false);

  return (
    <Container>
      <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
        <Table
          sx={{ minWidth: 700 }}
          aria-label="customized table"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              {header.map((item) => (
                <StyledTableCell key={item}>
                  {item === 'Visualizar notas' ? (
                    <>
                      {item}{' '}
                      <Switch
                        checked={showNote}
                        onClick={() => setShowNote(!showNote)}
                        size="small"
                      />
                    </>
                  ) : (
                    item
                  )}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <StyledBody>
            <StyledTableRow>
              <StyledTableCell>Gráfico das notas</StyledTableCell>
              {actives.map((active) => (
                <StyledTableCell key={active.Assets}>
                  <GraphicNote active={active} />
                </StyledTableCell>
              ))}
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>Lucro líquido</StyledTableCell>
              {showNote
                ? actives.map((active) => (
                    <StyledTableCell key={active.Assets}>
                      {active.LucroLiquido.toFixed(0)}%
                    </StyledTableCell>
                  ))
                : dataGraphics.map((data, index) => (
                    <StyledTableCell key={index}>
                      {maskValueCards(
                        data.lucroLiquidoArr[data.lucroLiquidoArr.length - 1]
                          ?.value ?? 0
                      )}
                    </StyledTableCell>
                  ))}
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>EBITDA</StyledTableCell>
              {showNote
                ? actives.map((active) => (
                    <StyledTableCell key={active.Assets}>
                      {active.segmento.toUpperCase() !== 'BANCOS'
                        ? `${active.EBITDA.toFixed(0)}%`
                        : '-'}
                    </StyledTableCell>
                  ))
                : dataGraphics.map((data, index) => (
                    <StyledTableCell key={index}>
                      {data.segmento.toUpperCase() !== 'BANCOS'
                        ? maskValueCards(
                            data.ebitdaArr[data.ebitdaArr.length - 1]?.value ??
                              0
                          )
                        : '-'}
                    </StyledTableCell>
                  ))}
            </StyledTableRow>
            {/* <StyledTableRow>
              <StyledTableCell>ROE</StyledTableCell>
              {showNote
                ? actives.map((active) => (
                    <StyledTableCell key={active.Assets}>
                      {active.ROE.toFixed(0)}%
                    </StyledTableCell>
                  ))
                : dataGraphics.map((data, index) => (
                    <StyledTableCell key={index}>
                      {data.roeArr[data.roeArr.length - 1]?.value.toFixed(2) +
                        '%' ?? 0}
                    </StyledTableCell>
                  ))}
            </StyledTableRow> */}
            <StyledTableRow>
              <StyledTableCell>Dívida líquida</StyledTableCell>
              {showNote
                ? actives.map((active) => (
                    <StyledTableCell key={active.Assets}>
                      {active.segmento.toUpperCase() !== 'BANCOS'
                        ? `${active.DividaLiquida.toFixed(0)}%`
                        : '-'}
                    </StyledTableCell>
                  ))
                : dataGraphics.map((data, index) => (
                    <StyledTableCell key={index}>
                      {data.segmento.toUpperCase() !== 'BANCOS'
                        ? maskValueCards(
                            data.dividaLiquida[data.dividaLiquida.length - 1]
                              ?.value ?? 0
                          )
                        : '-'}
                    </StyledTableCell>
                  ))}
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>Dívida líquida/EBITDA</StyledTableCell>
              {showNote
                ? actives.map((active) => (
                    <StyledTableCell key={active.Assets}>
                      {active.segmento.toUpperCase() !== 'BANCOS'
                        ? `${active.DividaLiquidaEbitida.toFixed(2)}`
                        : '-'}
                    </StyledTableCell>
                  ))
                : dataGraphics.map((data, index) => (
                    <StyledTableCell key={index}>
                      {data.segmento.toUpperCase() !== 'BANCOS'
                        ? data.dividaLIquidaEbitdaArr[
                            data.dividaLIquidaEbitdaArr.length - 1
                          ]?.value.toFixed(2) ?? 0
                        : '-'}
                    </StyledTableCell>
                  ))}
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>DY</StyledTableCell>
              {showNote
                ? actives.map((active) => (
                    <StyledTableCell key={active.Assets}>
                      {active.Dividendos.toFixed(0)}%
                    </StyledTableCell>
                  ))
                : dataGraphics.map((data, index) => (
                    <StyledTableCell key={index}>
                      {data.dyArr[data.dyArr.length - 1]?.value.toFixed(2) + '%' ?? 0}
                    </StyledTableCell>
                  ))}
            </StyledTableRow>
          </StyledBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

import React from 'react';
import styled from 'styled-components';

const HeaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 1;
  
`;

// const Header = ({ children }: React.PropsWithChildren<{}>) => {
//   return <HeaderContainer>{children}</HeaderContainer>;
// };

export default HeaderContainer;
import axios from 'axios';
import { BrokerageContent, BrokerageItem, BrokeragesContainer } from './styles';
import { baseURL } from '../../../../../utils/baseURL';
import { financeRoutes } from '../../../../../utils/RoutesApi';
import { useEffect, useRef, useState } from 'react';
import { IPropsBrokerage } from '../../@types';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useActivesWallet } from '../../hooks/ActivesHooks';
import logoColorido from '../../../../../assets/logo/logoColorido.jpg';

export function Brokerages() {
  const { setSelectedBrokerage } = useActivesWallet();
  const [brokerages, setBrokerages] = useState<IPropsBrokerage[]>([]);
  const container = useRef(null);

  useEffect(() => {
    handleGetBrokerages();
  }, []);

  async function handleGetBrokerages() {
    await axios
      .get(`${baseURL}${financeRoutes.FINANCE_BROKERAGES}`)
      .then(function (result) {
        let newResult = result.data.result.filter(
          (item: IPropsBrokerage) => item.corretora !== ''
        );
        newResult = newResult.filter(
          (item: IPropsBrokerage) => item.sumcorretora > 0
        );
        setBrokerages(newResult);
      })
      .catch((err) => {});
  }

  return (
    <BrokeragesContainer>
      <span>Corretoras</span>
      <ScrollContainer
        style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}
        horizontal
        innerRef={container}
      >
        <BrokerageItem onClick={() => setSelectedBrokerage('')}>
          <img src={logoColorido} alt="" />
          <span>Todas</span>
        </BrokerageItem>
        {brokerages.map((brokerage) => (
          <BrokerageItem
            key={brokerage.corretora}
            onClick={() => setSelectedBrokerage(brokerage.corretora)}
          >
            <img src={brokerage.logo ? brokerage.logo : logoColorido} alt="" />
            <span>{brokerage.corretora.toUpperCase()}</span>
          </BrokerageItem>
        ))}
      </ScrollContainer>
    </BrokeragesContainer>
  );
}

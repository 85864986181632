import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { useTitleAndUser } from '../../../hooks/userTitle';
import { userRoutes } from '../../../utils/RoutesApi';
import { ButtonComponent } from '../../ButtonComponent';
import { handleToast } from '../../Toast';
import {
  InfoValidationPassword,
  InputContent,
  RegisterFinishContainer,
  ValidationPassword,
} from './styles';
import { Eye, EyeSlash } from '@phosphor-icons/react';
import { Popover } from 'antd';

interface IPropsRegisterFinish {
  onCloseModal: Function;
}

export function RegisterFinish({ onCloseModal }: IPropsRegisterFinish) {
  //@ts-ignore
  const { user, setUser } = useTitleAndUser();

  const {
    handleSubmit,
    setValue,
    register,
    watch,
    formState: { isSubmitting },
  } = useForm();
  const [viewPassword, setViewPassword] = useState(false);
  const [viewPasswordConfirm, setViewPasswordConfirm] = useState(false);

  useEffect(() => {
    if (user.ddd && user.telefone) {
      setValue('cel', user.ddd + user.telefone);
    }
  }, []);

  const watchPasswords = watch('password');
  const validateLettersInPassword = !!watchPasswords?.match(/[A-z]/);
  const validateNumberInPassword = !!watchPasswords?.match(/[0-9]/);
  const validateSpecialCharactersInPassword = !!watchPasswords?.match(
    /[!|@|#|$|%|^|&|*|(|)|-|_]/
  );

  async function submit(data: any) {
    const { cel, cpf, password, confirmPassword } = data;

    if (!cpf) {
      return handleToast(true, 'Informe seu cpf');
    }
    if (!cel) {
      return handleToast(true, 'Informe o celular');
    }
    if (cel.replace(/\D/g, '').length < 11) {
      return handleToast(true, 'Informe um número válido');
    }
    if (!password) {
      return handleToast(true, 'Você precisa criar uma nova senha');
    }
    if (!confirmPassword) {
      return handleToast(true, 'Você precisa confirmar a nova senha');
    }
    if (password !== confirmPassword) {
      return handleToast(true, 'As senhas devem ser iguais');
    }
    if (password.length < 8) {
      return handleToast(true, 'A senha deve ter no mínimo 8 caracteres');
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}${userRoutes.USER_FINISHED_REGISTRATION_HOTMART}/${user.id}/`,
        {
          cpf: cpf.toString(),
          ddd: cel.toString().replace(/\D/g, '').substring(0, 2),
          telefone: cel.toString().replace(/\D/g, '').substring(2),
          password,
        }
      );

      setUser({
        ...user,
        cpf,
        ddd: response.data.ddd,
        telefone: response.data.telefone,
      });
      onCloseModal();
    } catch (error) {
      const err: any = error;

      if (err.response.data.cpf) {
        return handleToast(true, err.response.data.cpf[0]);
      }
    } finally {
    }
  }

  return (
    <RegisterFinishContainer onSubmit={handleSubmit(submit)}>
      <InputContent isShort>
        <label htmlFor="cpf">CPF *</label>
        <ReactInputMask
          mask="999.999.999-99"
          {...register('cpf')}
          id="cpf"
          placeholder="ex: 999.999.999-99"
        />
      </InputContent>
      <InputContent isShort>
        <label htmlFor="cel">Celular *</label>
        <ReactInputMask
          mask="(99) 99999-9999"
          {...register('cel')}
          id="cel"
          placeholder="ex: (99) 99999-9999"
        />
      </InputContent>
      <Popover
        content={
          <ValidationPassword>
            <InfoValidationPassword error={watchPasswords?.length >= 8}>
              <span />
              Deve ter no mínimo 8 caracteres{' '}
            </InfoValidationPassword>
            <InfoValidationPassword error={validateNumberInPassword}>
              <span />
              Deve ter no mínimo 1 número
            </InfoValidationPassword>
            <InfoValidationPassword error={validateLettersInPassword}>
              <span />
              Deve ter no mínimo 1 letra
            </InfoValidationPassword>

            <InfoValidationPassword error={validateSpecialCharactersInPassword}>
              <span />
              Deve ter no mínimo 1 símbolo ex: @#$!{' '}
            </InfoValidationPassword>
          </ValidationPassword>
        }
        title="Crie uma senha segura"
        trigger="focus"
        zIndex={999999}
      >
        <InputContent isShort suffix>
          <label htmlFor="password">Senha *</label>
          <div>
            <input
              type={viewPassword ? 'text' : 'password'}
              {...register('password')}
              id="password"
              placeholder="Crie uma senha"
            />

            {viewPassword ? (
              <Eye
                onClick={() => setViewPassword(!viewPassword)}
                size={20}
                style={{ cursor: 'pointer' }}
              />
            ) : (
              <EyeSlash
                onClick={() => setViewPassword(!viewPassword)}
                size={20}
                style={{ cursor: 'pointer' }}
              />
            )}
          </div>
        </InputContent>
      </Popover>

      <InputContent isShort suffix>
        <label htmlFor="confirmPassword">Confirmar senha *</label>
        <div>
          <input
            type={viewPasswordConfirm ? 'text' : 'password'}
            {...register('confirmPassword')}
            id="confirmPassword"
            placeholder="Confirme sua senha"
          />
          {viewPasswordConfirm ? (
            <Eye
              onClick={() => setViewPasswordConfirm(!viewPasswordConfirm)}
              size={20}
              style={{ cursor: 'pointer' }}
            />
          ) : (
            <EyeSlash
              onClick={() => setViewPasswordConfirm(!viewPasswordConfirm)}
              size={20}
              style={{ cursor: 'pointer' }}
            />
          )}
        </div>
      </InputContent>

      <ButtonComponent
        type="submit"
        disabled={isSubmitting}
        className="funciona"
      >
        Enviar
      </ButtonComponent>
      <p>
        Pedimos o seu CPF para que possamos integrar sua carteira de
        investimentos automaticamente com a B3. Fique tranquilo, pois seus dados
        estão protegidos.
      </p>
    </RegisterFinishContainer>
  );
}

import { useCallback } from 'react';

function currency(e) {
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d)(\d{2})$/, '$1,$2');
  value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');

  e.currentTarget.value = value;
  return e;
}
function number(e) {
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, '');

  e.currentTarget.value = value;
  return e;
}

export const Input = ({ mask, ...props }) => {
  const handleKeyUp = useCallback(
    (e) => {
      if (mask === 'currency') {
        currency(e);
      }

      if (mask === 'number') {
        number(e);
      }
    },

    [mask]
  );

  return <input {...props} onKeyUp={handleKeyUp} />;
};

import { FormPlanStepContainer, FormPlanStepContent } from './styles';
import { useEffect, useState } from 'react';
import { Step, StepLabel, Stepper } from '@mui/material';
import { IPropsNewPlan } from '../..';
import { FormProvider, useForm } from 'react-hook-form';
import { AddressFormPlan } from './forms/AddressFormPlan';
import { handleToast } from '../../../../../../../../../components/Toast';
import { PaymentFormPlan } from './forms/PaymentFormPlan';
import { Checkout } from './forms/Checkout';
import { userRoutes } from '../../../../../../../../../utils/RoutesApi';
import axios from 'axios';
import { useTitleAndUser } from '../../../../../../../../../hooks/userTitle';
import { FinishedPlan } from './FinishedPlan';

interface IPropsFormPlanStep {
  selectedPlan: IPropsNewPlan;
  onClearPlan: Function;
  setStep: (step: number) => void;
  step: number;
  onFinishPlan: () => void;
  onClose: () => void;
}

export interface IDataForm {
  bairro: string;
  card_expiration_date: string;
  card_holder_name: string;
  card_number: string;
  cidade: string;
  complemento: string;
  cpf_card: string;
  estado: string;

  payment_method: 'boleto' | 'credit_card';
  numero: string;
  rua: string;
  security_code: string;
  zipcode: string;
  assinatura_id: string;
  username: string;
  step: any;
}

export function FormPlanStep({
  selectedPlan,
  onClearPlan,
  setStep,
  onClose,
  step,
  onFinishPlan,
}: IPropsFormPlanStep) {
  //@ts-ignore
  const { user, setUser } = useTitleAndUser();
  const defaultValueFormForStatedRegister = localStorage.getItem(
    'startFormSelectedPlan-1.0.0'
  );
  const methods = useForm<IDataForm>();
  const { handleSubmit } = methods;

  const [dataFinishedPlan, setDataFinishedPlan] = useState<IDataForm>(() => {
    if (defaultValueFormForStatedRegister !== null) {
      return JSON.parse(defaultValueFormForStatedRegister);
    }
    return {
      assinatura_id: '',
      bairro: '',
      card_expiration_date: '',
      card_holder_name: '',
      card_number: '',
      cidade: '',
      complemento: '',
      cpf_card: '',
      estado: '',
      numero: '',
      payment_method: 'credit_card',
      rua: '',
      security_code: '',
      step: 1,
      username: '',
      zipcode: '',
    };
  });

  useEffect(() => {
    localStorage.setItem(
      'startFormSelectedPlan-1.0.0',
      JSON.stringify(dataFinishedPlan)
    );
  }, [dataFinishedPlan]);

  async function submitForm(data: IDataForm) {
    if (step === 0) {
      if (!data.zipcode) {
        return handleToast(true, 'Informe seu cep');
      }
      if (!data.rua) {
        return handleToast(true, 'Informe sua rua');
      }
      if (!data.estado) {
        return handleToast(true, 'Informe seu estado');
      }
      if (!data.bairro) {
        return handleToast(true, 'Informe seu bairro');
      }
      if (!data.numero) {
        return handleToast(true, 'Informe o numero da casa');
      }

      setDataFinishedPlan({
        ...dataFinishedPlan,
        ...data,
      });
      setStep(1);
      return;
    }
    if (step === 1) {
      if (data.payment_method === 'credit_card') {
        if (!data.card_number) {
          return handleToast(true, 'Informe o numero do cartão');
        }
        if (!data.card_holder_name) {
          return handleToast(true, 'Informe o nome no verso do cartão');
        }
        if (!data.cpf_card) {
          return handleToast(true, 'Informe o cpf do titular do cartão');
        }
        if (!data.card_expiration_date) {
          return handleToast(true, 'Informe a data de validade do cartão');
        }
        if (!data.security_code) {
          return handleToast(true, 'Informe o CVV do cartão');
        }
      }
      setDataFinishedPlan({
        ...dataFinishedPlan,
        ...data,
      });
      setStep(2);
      return;
    }

    if (step === 2) {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}${userRoutes.USER_FINISHED_REGISTRATION}`,
          {
            ...dataFinishedPlan,
            assinatura_id: selectedPlan.id,
            username: user.username,
          }
        )
        .then((response) => {
          setUser({
            ...user,
            ...response.data.user,
          });
          localStorage.removeItem('startFormSelectedPlan-1.0.0');
          if (dataFinishedPlan.payment_method === 'boleto') {
            window.open(response.data.boleto_url);
          }

          setStep(3);
        })
        .catch((err) => {
          handleToast(true, err.response.data.message);
        });
    }
  }

  return (
    <FormPlanStepContainer>
      <FormPlanStepContent>
        <Stepper activeStep={step} alternativeLabel>
          <Step>
            <StepLabel>Cadastro de endereço</StepLabel>
          </Step>
          <Step>
            <StepLabel>Forma de pagamento</StepLabel>
          </Step>
          <Step>
            <StepLabel>Revise seus dados</StepLabel>
          </Step>
        </Stepper>
      </FormPlanStepContent>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submitForm)}>
          {step === 0 && (
            <AddressFormPlan
              selectedPlan={selectedPlan}
              onClearPlan={() => onClearPlan()}
              defaultValueBackToScreen={dataFinishedPlan}
            />
          )}
          {step === 1 && (
            <PaymentFormPlan
              defaultValueBackToScreen={dataFinishedPlan}
              onClearPlan={() => onClearPlan()}
              selectedPlan={selectedPlan}
            />
          )}
          {step === 2 && (
            <Checkout
              defaultValueBackToScreen={dataFinishedPlan}
              onClearPlan={() => onClearPlan()}
              selectedPlan={selectedPlan}
              backPaymentMethod={(stepNumber) => setStep(stepNumber)}
            />
          )}
        </form>
      </FormProvider>
      {step === 3 && <FinishedPlan finished={() => window.location.reload()} />}
    </FormPlanStepContainer>
  );
}

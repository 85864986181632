import styled from 'styled-components';

interface IPropsTabComponent {
  active: boolean;
}

export const Container = styled.div<IPropsTabComponent>`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 0 1.5rem;
  cursor: pointer;
  background: linear-gradient(
    259.65deg,
    ${(props) =>
      props.active
        ? '#7A1B21 6.41%, #1B1B1B 45.41%, #2A2A2A 68.3%, #B68E1D 105.7%'
        : '#7A1B21 6.41%, #1B1B1B 45.41%, #2A2A2A 68.3%, #1C578A 105.7%'}
  );
  transition: all 0.3s;
  width: 100%;
  /* max-width: 21.375rem; */
  height: 3.562rem;
  border-radius: 4px;
  align-items: center;

  box-shadow: ${(props) => props.theme['card-shadow-2']};

  p {
    margin: 0;
    color: ${(props) => props.theme.white};
    font-size: 1rem;
    font-weight: 400;
  }

  svg {
    color: ${(props) => props.theme.white};
    font-size: 1.5rem;
    font-weight: 400;
  }
`;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  root: {
    display: 'flex',
    borderBottomWidth: 2,
  },
}));

export default useStyles;

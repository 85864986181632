import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

import { Card, CardMoney, Container, Content, RowCards } from './styles';
import { useActives } from '../../../../../../../hooks/useActives';
import { transformMaskCurrency } from '../../../../../../../utils/transformMaskCurrency';

interface IPropsProceeds {
  approvalDate: string;
  corporateActionType: string;
  eventValue: number;
  paymentDate: string | Date | null;
  periodPaymentDate: string | Date;
  quantidade: number;
  referenceDate: string | Date;
  tickerSymbol: string;
  total: number;
}

interface IPropsGraphicNextProceeds {
  proceeds: IPropsProceeds[];
}

interface IPropsActive {
  month: Date;
  value: number;
}

interface IPropsValues {
  data: {
    data: string | Date;
    value: number;
    type: string;
  }[];
  headers: string[] | Date[];
  total: IPropsActive[];
  totalFII: IPropsActive[];
  totalAction: IPropsActive[];
}

export function GraficoProxProventos({ proceeds }: IPropsGraphicNextProceeds) {
  //@ts-ignore
  const { showActive } = useActives();
  const [values, setValues] = useState<IPropsValues>({
    data: [],
    headers: [],
    total: [],
    totalFII: [],
    totalAction: [],
  });
  useEffect(() => {
    handleFutureEarningsToReceive(proceeds);
  }, [proceeds]);

  function handleFutureEarningsToReceive(data: IPropsProceeds[]) {
    let dataTable = [];
    let fiiTableValue = [];
    let actionTableValue = [];
    let totalAction;
    let totalMonthy;
    let headerTable = [];
    let totalFii;

    if (data.length > 0) {
      dataTable = data.map((date) => {
        return {
          data: date.periodPaymentDate,
          value: date.total,
          type: date.corporateActionType,
        };
      });
      headerTable = dataTable.map((date) => date.data);
      //@ts-ignore
      headerTable = [...new Set(headerTable)];
      fiiTableValue = dataTable.filter((date) => {
        if (date.type === 'RENDIMENTO') {
          return date;
        }
      });

      const groupByDataFII = fiiTableValue.reduce((group, day) => {
        const { data } = day;
        //@ts-ignore
        group[data] = group[data] ?? [];
        //@ts-ignore
        group[data].push(day);
        return group;
      }, {});

      totalFii = headerTable.map((date: Date) => {
        //@ts-ignore
        let newTotal = groupByDataFII[date]
          //@ts-ignore
          ?.map((item) => item.value)
          //@ts-ignore
          ?.reduce((sum, item) => sum + item);
        return { month: date, value: newTotal ?? 0 };
      });

      //************************************************************************ */

      actionTableValue = dataTable.filter((date) => {
        if (date.type !== 'RENDIMENTO') {
          return date;
        }
      });

      const groupByDataActionI = actionTableValue.reduce((group, day) => {
        const { data } = day;
        //@ts-ignore
        group[data] = group[data] ?? [];
        //@ts-ignore
        group[data].push(day);
        return group;
      }, {});

      totalAction = headerTable.map((date: Date) => {
        //@ts-ignore
        let newTotal = groupByDataActionI[date]
          //@ts-ignore
          ?.map((item) => item.value)
          //@ts-ignore
          ?.reduce((sum, item) => sum + item);
        return { month: date, value: newTotal ?? 0 };
      });

      //************************************************************************ */
      const groupByDataTotal = dataTable.reduce((group, day) => {
        const { data } = day;
        //@ts-ignore
        group[data] = group[data] ?? [];
        //@ts-ignore
        group[data].push(day);
        return group;
      }, {});
      totalMonthy = headerTable.map((date: Date) => {
        //@ts-ignore
        let newTotal = groupByDataTotal[date]
          //@ts-ignore
          .map((item) => item.value)
          //@ts-ignore
          .reduce((sum, item) => sum + item);
        return { month: date, value: newTotal };
      });

      setValues({
        data: dataTable,
        headers: headerTable,
        total: totalMonthy,
        totalFII: totalFii,
        totalAction,
      });
    }
  }

  const isValueOfThePeriodRelatedToTheAction = values?.totalAction.map(
    (item) => item.value
  );
  const isValueOfThePeriodRelatedToTheFII = values?.totalFII.map(
    (item) => item.value
  );
  const isValueOfThePeriodRelatedToTheTotal = values?.total.map(
    (item) => item.value
  );

  const graphicData = {
    series: [
      {
        name: 'Ação',
        type: 'column',
        data: isValueOfThePeriodRelatedToTheAction ?? [],
      },
      {
        name: 'FII',
        type: 'column',
        data: isValueOfThePeriodRelatedToTheFII ?? [],
      },
      {
        name: 'Total',
        type: 'line',
        data: isValueOfThePeriodRelatedToTheTotal ?? [],
      },
    ],
    options: {
      chart: {
        // height: 350,
        type: 'line',
        stacked: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [4, 4, 4],
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      colors: [
        'var(--graphic-action)',
        'var(--graphic-fii)',
        'var(--graphic-total)',
      ],
      legend: {
        position: 'bottom',
        horizontalAlign: 'right',
      },
      labels: values?.total.map((item) => item.month) ?? [''],
      xaxis: {
        // categories: values?.total.map((item) => item.month) ?? [''],
        labels: {
          formatter: function (value: any) {
            if (value) {
              if (value === 'None') {
                return 'Sem data';
              }
              let year = value?.substring(0, 4);
              let month = value?.substring(5, 7);
              return `${month}/${year}`;
            }
          },
        },
      },
      yaxis: {
        labels: {
          formatter: function (value: any) {
            if (showActive) {
              return transformMaskCurrency(value);
            } else {
              return 'R$ *****';
            }
          },
        },
      },

      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val: any) {
            if (showActive) {
              return transformMaskCurrency(val);
            } else {
              return 'R$ *****';
            }
          },
        },
      },
    },
  };

  const isPlannedTotalValue = transformMaskCurrency(
    values.total.reduce((acc, value) => acc + value.value, 0)
  );

  return (
    <Container>
      <RowCards>
        <Card>
          <p>
            Proventos futuros: Projeção dos proventos que ainda serão pagos,
            distribuídos ao longo dos meses.
          </p>
        </Card>
        <CardMoney>
          <span>Valor total planejado:</span>
          <strong>{showActive ? isPlannedTotalValue : 'R$ ******'}</strong>
        </CardMoney>
      </RowCards>
      <Content>
        <ReactApexChart
          //@ts-ignore
          options={graphicData.options}
          series={graphicData.series}
          type="line"
          height={350}
        />
      </Content>
    </Container>
  );
}

import ReactLoading from 'react-loading';
import ReactApexChart from 'react-apexcharts';
import { GraphicAccessForPageContainer } from './styles';

interface IPropsGraphicAccessForPage {
  data: {
    page_name: string;
    value: number;
  }[];
  loading: boolean;
}

export function GraphicAccessForPage({
  data,
  loading,
}: IPropsGraphicAccessForPage) {
  const graphicData = {
    series: data.map((item) => {
      return item.value;
    }),
    options: {
      chart: {
        type: 'donut',
      },
      legend: {
        show: true,
        position: 'bottom',
        labels: {
          color: ['#ffff'],
          useSeriesColors: true,
        },
      },
      labels: data.map((item) => item.page_name),
      colors: ['#f43f5e', '#ec4899', '#d946ef','#a855f7','#0ea5e9','#14b8a6','#eab308'],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  };

  return (
    <GraphicAccessForPageContainer>
      {loading ? (
        <ReactLoading
          type="balls"
          width={200}
          height={200}
          color="var(--primary)"
        />
      ) : (
        <ReactApexChart
          //@ts-ignore
          options={graphicData.options}
          series={graphicData.series}
          type="donut"
          width={400}
        />
      )}
    </GraphicAccessForPageContainer>
  );
}

import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import ReactLoading from 'react-loading';
import { Tooltip } from 'antd';
import { ContentForm } from './styles';
import { Input } from '../../../../../../../components/Input';
import { ButtonDesign } from '../../../../../../../components/ButtonDesign';
import { useSimulation } from '../../../../hooks/SimulationHooks';

export function FormSimulation() {
  const { seValueAporte, submitSimulator, loading } = useSimulation();
  useEffect(() => {
    seValueAporte(0);
  }, []);

  const { handleSubmit } = useForm();

  return (
    <ContentForm onSubmit={handleSubmit(submitSimulator)}>
      <Tooltip
        placement="bottom"
        title={
          <p style={{ margin: 0 }}>
            A função simular aporte serve para balancear sua carteira conforme
            sua estratégia.
            <br />
            Para simular aporte basta clicar nos ativos que deseja na tabela
            abaixo, depois inserir o valor do aporte desejado e clicar em
            simular
          </p>
        }
      >
        <h1>Simular aporte</h1>
      </Tooltip>

      <div className="contentInput">
        <label>Valor do aporte</label>
        <Input
          mask="currency"
          onChange={(e: any) => seValueAporte(e.target.value)}
        />
        <small>R$</small>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ButtonDesign
          type="submit"
          disabled={loading}
          size="btn-medium"
          style={{ maxWidth: 'none' }}
        >
          {loading ? (
            <ReactLoading type="balls" width={25} height={25} />
          ) : (
            <>Simular</>
          )}
        </ButtonDesign>
      </div>
    </ContentForm>
  );
}

export const authRoutes = {
  RECOVER_PASSWORD: '/api/password_reset/',
};

export const userRoutes = {
  USER_UPDATE_ADDRESS: '/updateaddress/',
  USER_UPDATE_PLAN: '/updateplano/',
  USER_UPDATE_IMAGE: '/api/edit-profile/',
  USER_UPDATE_PROFILE: '/api/edit-user/',
  USER_UPDATE_PASSWORD: '/api/edit-password/',
  TRIAL_SKIP_PLAN: '/api/updatetrial/',
  STATUS_PAYMENT: '/api/get_plan_user',
  USER_FINISHED_REGISTRATION: '/api/register_user_plan/',
  REMOVE_FOLLOW: (slug: string) => `/api/${slug}/following/`,
  ADD_NEW_FOLLOW: (slug: string) => `/api/${slug}/following/`,
  PLANS_IR: '/api/todosplanosIRPF',
  PLANS_IR_REGISTER: '/api/assinatura_irpf',
  USER_PLAN_IR_SELECTED: '/api/get_plan_user_irpf',
  USER_CANCEL_PLAN_IR: '/api/cancel_plan_irpf',
  USER_REFRESH_PERMISSIONS: '/api/getUserRefresh',
  USER_FINISHED_REGISTRATION_HOTMART: '/api/updateexternaluser',
  NEW_PLAN_ROUTER: '/Finance/v2/planos/',
  PLAN_ROUTER_V3: '/api/planosV3/',
};

export const administrationController = {
  QUESTIONS_FEEDBACK: '/api/questions',
  RESPONSE_USER_FEEDBACK: '/api/responses',
  GET_DATA_USER: (email: string) => `/api/admin/user/${email}/`,
  GET_DATA_USER_HISTORY: (email: string) => `/api/admin/user/${email}/history/`,
  UPDATE_PASSWORD_USER: (email: string) =>
    `/api/admin/user/${email}/update-password/`,
  UPDATE_DATA_USER: (email: string) =>
    `/api/admin/user/${email}/update-date-joined/`,
  UPDATE_EMAIL_CPF_USER: (email: string) =>
    `/api/admin/user/${email}/update-email-cpf/`,
  GET_DATA_PLAN_USER: (email: string) => `/api/admin/userplan/${email}/`,
  GET_DATA_PLAN_IR_USER: (email: string) => `/api/admin/userplanirpf/${email}/`,
  GET_ANALYTICS_USER: '/api/user-analytics/',
  GET_TOTAL_ANALYTICS_USER: '/api/user-analytics/totals',
  INDICES: '/Finance/indice/',
};

export const B3 = {
  LINK_B3: '/updatetipocarteira/',
  CHECK_BIND_WITH_B3: '/optininvestor/',
  VALIDATE_LINK_B3: '/api/getoptin',
  WALLET_AUTOMATIC: '/api/updateautomatico/',
};

export const Notify = {
  COUNT_NOTIFY: '/social/api/notifications/unread-count/',
  LIST_NOTIFY: '/social/api/notifications',
};

export const financeRoutes = {
  FINANCE_RANK_ACTIVES_LIST: '/Finance/empresas_consolidadas',
  FILTERS_SECTORS_ACTIONS: '/Finance/filtros_empresas',
  FINANCE_CDI: '/Finance/get_cdi',
  FINANCE_ALL: '/Finance/carteira/all',
  FINANCE_ALL_ACTIVES: '/Finance/todosativos',
  FINANCE_ALL_ACTIVES_COMPARE: '/Finance/ativoscomparar',
  FINANCE_ASSETS: '/Finance/assets',
  FINANCE_SECTOR_RESULT: '/Finance/fiiresultadosetor',
  FINANCE_SECTOR_FII: '/Finance/setoresfii',
  FINANCE_SECTOR: '/Finance/setores',
  FINANCE_FII_RESULT: '/Finance/fiiresultados',
  FINANCE_FIIS: '/Finance/fiis',
  FINANCE_HISTORY_YIELD_FII: '/Finance/historico_rendimento_fii',
  FINANCE_DISTRATOS: '/Finance/distratos',
  FINANCE_IMOVEIS: '/Finance/imoveis',
  FINANCE_RELEVANT_FACTS_ACTION: '/Finance/fatosrelevantes',
  FINANCE_RELEVANT_FACTS_FII: '/Finance/fatosrelaventesfii',
  FINANCE_VACANCIA_CONSOLIDATED: '/Finance/vacanciaconsolidada',
  FINANCE_VACANCIA_CONSOLIDATED_UPDATE: (val: string) =>
    `/Finance/vacanciaconsolidada/${val}/`,
  FINANCE_PAYOUT: (val: string) => `/Finance/calculo_payout/${val}/`,
  FINANCE_POSITION: '/Finance/posicao',
  FINANCE_PRICE_FII: '/Finance/precosFII',
  FINANCE_PRICE_ACTION: '/Finance/precosAtivo',
  FINANCE_FUNDAMENTALIST: '/Finance/fundamentalista',
  FINANCE_DIVIDENDOS: '/Finance/dividendo',
  FINANCE_SEGMENT: '/Finance/segmentos',
  FINANCE_COMPANY: '/Finance/empresas',
  FINANCE_CNPJ_ASSET: '/Finance/cnpjasset',
  FINANCE_CHART_CONSOLIDATED: '/Finance/chartconsolidado',
  FINANCE_CONSOLIDATED_SEGMENT: '/Finance/consolidadosegmento',
  FINANCE_PLANS: '/Finance/todosplanos',
  FINANCE_EXCEPTION_ALL: '/Finance/carteira/excecao/all/',
  FINANCE_EXCEPTION_CREATE: '/Finance/carteira/excecao/create/',
  FINANCE_EXCEPTION_DELETE: '/Finance/carteira/excecao/item/delete/',
  FINANCE_EXCEPTION_EDIT: '/Finance/carteira/excecao/update/',
  FINANCE_BROKERAGES: '/Finance/carteira/corretoras',
  FINANCE_PROCEEDS: '/Finance/get_eventos_provisionados',
  FINANCE_PROCEEDS_RECEIVED: '/Finance/get_proventos_recebidos',
  FINANCE_INDEXES: '/Finance/indices',
  FINANCE_ANNUAL_PRICES: '/Finance/getPrecosAtivos',
  FINANCE_EMERGENCY_RESERVE: '/Finance/carteira/get_emergency_reserve',
  FINANCE_EMERGENCY_RESERVE_ADD: '/Finance/carteira/add_emergency_reserve/',
  FINANCE_EMERGENCY_RESERVE_REMOVE: (val: string) =>
    `/Finance/carteira/${val}/remove_emergency_reserve/`,
  FINANCE_FILTERS_RECEBIDOS: '/Finance/get_filtros_movements',
  FINANCE_INCOME_TAX: '/Finance/imposto/get_imposto_renda',
  FINANCE_INCOME_TAX_CONSOLIDATED: '/Finance/get_proventos_consolidados',
  FINANCE_TRANSACTIONS_ACTIVES: '/Finance/carteira/get_negociacao_ativos',
  FINANCE_TRANSACTIONS_ACTIVES_FILTERS:
    '/Finance/carteira/get_filtros_negociacao',
  FINANCE_ASSETS_AND_RIGHTS: '/Finance/imposto/bens_e_direitos',
  FINANCE_PROFITABILITY: '/Finance/get_evolucao_patrimonio',
  FINANCE_HISTORY_FII: '/Finance/historico_fii',
  FINANCE_GET_TEXT_ACTIVE: '/Finance/getTextoTicker',
  FINANCE_UPDATE_WALLET_DATE: '/api/user/update-info/',
  FINANCE_LIST_FIIS_WITH_RANK: '/Finance/fundos_filter/',
  FINANCE_COTATION_LONG_TIME: '/Finance/dadosdemercado/quotes/',
  LAST_PROFIT: '/Finance/carteira/ultimo_aporte',
};

export const socialRoutes = {
  SOCIAL_MOST_WORLDS: '/social/api/posts/mostwords',
  SOCIAL_CREATE_POST: '/social/api/posts/',
  SOCIAL_REPOST: '/social/api/posts/repost/',
  SOCIAL_GET_COMMENTS_HASHTAG: '/social/api/posts/feed',
  SOCIAL_GET_RECOMMENDED_POSTS: '/social/api/posts/long-recommended-posts/',
  SOCIAL_GET_RECOMMENDED_USERS: '/api/ong-recommended-users/',
  SOCIAL_GET_ONE_PUBLISHED: '/social/api/posts/',
  DELETE_COMMENT_VIDEO: (id: string) => `/videoscube/api/comment/${id}`,
};

export const walletRoutes = {
  WALLET_POSITION: '/Finance/posicaocarteira',
  WALLET_CONSOLIDATED: '/api/carteiraconsolidada/',
  WALLET_ADD_OR_GET: '/api/carteira/',
  WALLET_CONFIG: '/Finance/getConfCarteira',
  WALLET_CONFIG_UPDATE: '/Finance/updateConfCarteira',
  WALLET_CONFIG_STRATEGY: '/update_conf_wallet/',
  WALLET_GET_CONFIG_STRATEGY: '/get_conf_wallet/',
  WALLET_SIMULATOR_SUBMISSION: '/Finance/carteira/simulacao_aporte',
  WALLET_SIMULATOR_ALL_SUBMISSION: '/Finance/carteira/simulacao_aporte_todos',
  WALLET_DELETE_ALL_IMPORTS: '/Finance/carteira/delete_all_items/',
  WALLET_DEFINED_RULE_STRATEGY: '/api/configuracao_usuario/ ',
};

export const videosRoutes = {
  LAST_FIVE_VIDEOS: '/videoscube/api/lastvideo',
  NEW_COMMENT: '/videoscube/api/comment/',
  LIST_COMMENTS_VIDEO: '/videoscube/api/comment',
};

export const achievementRoutes = {
  ACHIEVEMENTE: '/api/gamification/getwards/',
};

export const IRPFRoutes = {
  INICIAL_CUSTODY: '/Finance/imposto/get_custodia_inicial',
  UPDATE_CUSTODY: '/Finance/imposto/set_custodia_inicial',
};

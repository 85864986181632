import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  width: 100%;

  flex-direction: column;
  background: ${(props) => props.theme.white};
  padding: 1rem;
  border-radius: 8px;
`;

export const RowCards = styled.div`
  display: grid;
  grid-template-columns: 1fr 400px;
  width: 100%;
  gap: 1rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Card = styled.div`
  width: 100%;
  background: ${(props) => props.theme.white};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  box-shadow: ${(props) => props.theme['card-shadow-2']};

  p {
    margin: 0;
  }

  strong {
    font: 500 1rem 'Poppins', sans-serif;
    color: #000000;
    line-height: 1.8;
  }
  h3 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 2.8;
  }

  a {
    justify-content: flex-end;
    display: flex;
    color: ${(props) => props.theme.primary}!important;
    transition: filter 0.3s;

    :hover {
      filter: brightness(0.9);
    }
  }
`;

import styled from 'styled-components';

export const DeleteAccountContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  p {
    margin: 0;
    font-weight: 400;
    font-size: 1rem;
    color: ${(props) => props.theme['gray-200']};
  }
  label {
    font-weight: 400;
    font-size: 0.875rem;
    color: ${(props) => props.theme['gray-200']};
    margin: 0;
  }

  .contentButtons {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: center;

    .deleteButton {
      all: unset;
      padding: 0.75rem 1rem;
      border: 1px solid ${(props) => props.theme['gray-300']};
      color: ${(props) => props.theme['gray-300']};
      font-weight: 500;
      font-size: 1rem;
      border-radius: 12px;
      text-align: center;
    }

    @media (max-width: 480px) {
      flex-direction: column;
      button {
        width: calc(100% - 32px) !important;
        max-width: none;
      }
    }
  }
`;

import styled from 'styled-components';
import backgroundImg from '../../../assets/background/backgroundCardCorretora.svg';

interface IPropsCardCorretora {
  value: number;
  active: boolean;
}

export const Container = styled.div<IPropsCardCorretora>`
  display: flex;
  flex-direction: column;

  /* width: 231px; */
  border-radius: 4px;
  cursor: pointer;
  padding: 0.75rem;
  background: ${(props) =>
    props.active
      ? 'linear-gradient(45deg, #f8b700, #c61722)'
      : 'linear-gradient(45deg, #1bb4e3, #f8b700)'};

  position: relative;
  z-index: 10;
  font-family: 'Poppins', sans-serif;

  background-size: 120%;
  transition: background-color 0.3s;

  header {
    display: flex;
    flex-direction: row;
    align-items: center;
    h3 {
      color: #fff;
      font: 500 1rem 'Poppins', sans-serif;
      max-width: 20ch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0;
    }
  }
  main {
    display: flex;
    height: 100%;
    align-items: center;
    span {
      color: #fff;
    }
    h2 {
      color: #fff;
      z-index: 10;
      margin: 0;
      font: 700 1.1rem 'Poppins', sans-serif;
    }
  }

  .logo {
    position: absolute;
    object-fit: fill;
    height: 150px;
    right: 0;
    top: 0;
    opacity: 0.4;
  }
  @media (max-width: 480px) {
    /* max-width: 400px; */
    width: 100%;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  background: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme['card-shadow-2']};
  padding: 1rem;
  border-radius: 4px;

  p {
    padding: 0;
    margin: 0;
    color: ${(props) => props.theme['gray-700']};
  }

  span {
    color: ${(props) => props.theme['gray-700']};
  }
`;

import { useEffect, useState } from 'react';
import { Comment } from '../../../components/Comment';
import { Container } from './styles';
import { IPropsComment } from '../../../utils/@types/comment';
import { useTitleAndUser } from '../../../hooks/userTitle';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { socialRoutes } from '../../../utils/RoutesApi';
import LoadingSpinner from '../../../jsx/components/Finance/LoadingSpinner';
import { defaultValueComment } from './defaultValueComment';

const baseURL = process.env.REACT_APP_API_URL;

export function ViewPublication() {
  //@ts-ignore
  const { user, setTitle } = useTitleAndUser();
  const history = useHistory();
  const idComment = location.pathname.replace('/user-post/', '');
  const [comment, setComment] = useState<IPropsComment>(defaultValueComment);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    takeThePublicationOfAUser();
    setTitle('Publicação');
  }, [location.pathname]);

  async function takeThePublicationOfAUser() {
    setLoading(true);
    await axios
      .get(`${baseURL}${socialRoutes.SOCIAL_GET_ONE_PUBLISHED}${idComment}`)
      .then((response) => {
        setComment(response.data);
      })
      .catch((err) => {});
    setLoading(false);
  }

  async function handleLikeComment(id: string | number) {
    if (comment?.liked.includes(user.id)) {
      await axios
        .delete(`${baseURL}/social/api/posts/${id}/likes/`)
        .then((response) => {
          let newComment = comment;
          newComment.liked = newComment.liked.filter(
            (like) => like !== user.id
          );
          setComment({ ...comment, liked: newComment.liked });
        })
        .catch((err) => {
        });
      return;
    }
    await axios
      .post(`${baseURL}/social/api/posts/${id}/likes/`)
      .then((response) => {
        let newComment = comment;
        newComment.liked = [...newComment.liked, user.id];
        setComment({ ...comment, liked: newComment.liked });
      })
      .catch((err) => {
      });
  }

  function handleDeleteComment(id: number | string) {
    history.push('/recommended-posts');
  }

  return loading ? (
    <LoadingSpinner />
  ) : (
    <Container>
      <Comment
        //@ts-ignore
        comment={comment}
        handleLikeComment={handleLikeComment}
        deleteComment={(id) => handleDeleteComment(id)}
      />
    </Container>
  );
}

import ReactApexChart from 'react-apexcharts';
import { IPropsDataCompany } from '../../@types';
import { Percent } from '../../styles';
import { ContentCompanyName, HeaderMobileContainer } from './styles';
import { Tooltip } from 'antd';
import { ArrowLeft } from '@phosphor-icons/react';
import { cores } from '../../../../../../utils/coresGraficos';

interface IPropsHeaderMobile {
  companyInfos: IPropsDataCompany;
  setShowExplanationAssetNotes: (isShow: boolean) => void;
  clearActive: ({ slug, type }: { slug: string; type: string }) => void;
}

export function HeaderMobile({
  companyInfos,
  setShowExplanationAssetNotes,
  clearActive,
}: IPropsHeaderMobile) {
  const labels = [
    'Lucro Liquido',
    'ROE',
    'EBITDA',
    'Dividendos',
    'Divida Líquida',
    'Divida Líquida EBITDA',
    'Resiliencia',
    'Governança',
    'Índices',
  ];

  const compare = {
    series: [
      companyInfos.LucroLiquido,
      companyInfos.ROE,
      companyInfos.EBITDA,
      companyInfos.Dividendos,
      companyInfos.DividaLiquida,
      companyInfos.DividaLiquidaEbitida,
      companyInfos.Resiliencia,
      companyInfos.Governanca,
      companyInfos.Indices,
    ],
    options: {
      chart: {
        height: 390,
        type: 'donut',
      },
      yaxis: {
        labels: {
          //@ts-ignore
          formatter: (val) => `${val}%`,
        },
      },
      grid: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },

      legend: {
        show: false,
        position: 'bottom',
      },

      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: false,
              total: {
                show: false,
                label: 'Media',
                // formatter: () => `${active.total_chart.toFixed(0)}%`,
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
        //@ts-ignore
        formatter: (val) => `${val.toFixed(0)}%`,
      },
      tooltip: {
        fixed: {
          position: 'bottom',
        },

        y: {
          //@ts-ignore
          formatter: (val) => {
            return `${val.toFixed(0)}%`;
          },
          title: {
            formatter: (titleValue: any) => {
              return titleValue;
            },
          },
        },
        x: {
          formatter: (value: any) => {
            return 'Eixo x do gráfico';
          },
        },
      },

      labels: labels,
      colors: cores,

    },
  };

  return (
    <HeaderMobileContainer>
      <ContentCompanyName>
        <h2
          onClick={() =>
            clearActive({
              slug: '',
              type: '',
            })
          }
        >
          <ArrowLeft /> {companyInfos.NomeEmpresa}
        </h2>
        <span>{companyInfos.Assets[0]}</span>
      </ContentCompanyName>
      <Percent
        onClick={() => setShowExplanationAssetNotes(true)}
        style={{ margin: 0 }}
      >
        <ReactApexChart
          //@ts-ignore
          options={compare.options}
          series={compare.series}
          type="donut"
          width={80}
          height={80}
        />
        <Tooltip title="Essa é a nota da empresa com base na média das nossas avaliações. Basta clicar no gráfico para ver a explicação de cada uma das notas">
          <h6>{companyInfos.total_chart.toFixed(0)}%</h6>
        </Tooltip>
      </Percent>
    </HeaderMobileContainer>
  );
}

import { WalletCol, WalletContainer } from './styles';
import { Heading } from '../../../../../components/DefaultStylesTypography';
import { previewWallet2 } from '../../../../../Constants/images';

interface IPropsScreen {
  onShowRegisterScreen: () => void;
}

export function Wallet({ onShowRegisterScreen }: IPropsScreen) {
  return (
    <WalletContainer>
      <WalletCol>
        <header>
          <span>
            Faça o balanceamento da sua carteira de investimentos em 1 minuto
          </span>
          <Heading>
            Uma carteira de investimentos que torna tudo muito fácil!
          </Heading>
        </header>
        <p>
          Você sabia que um estudo demonstrou que 90% do sucesso nos
          investimentos vem de ter uma estratégia bem definida para a carteira,
          e de seguir fielmente essa estratégia? Pensando nisso, criamos uma
          carteira inteligente focada em estratégia. Ela te ajuda a ser fiel à
          estratégia, com funcionalidades que chegam a ser divertidas!
        </p>
        <button type="button" onClick={onShowRegisterScreen}>
          Acesse agora
        </button>
      </WalletCol>
      <img src={previewWallet2} alt="" className="previewImg" />
    </WalletContainer>
  );
}

import { Header } from './components/Header';
import { Home } from './components/Home';

import {
  BusinessContainer,
  BusinessContent,
  ContentRegisterOverLay,
} from './styles';
import { KnowPlatform } from './components/KnowPlatform';
import { AssetReviews } from './components/AssetReviews';
import { FeaturesInformation } from './components/FeaturesInformation';
import { DividendInformation } from './components/DividendInformation';
import { Education } from './components/Education';
import { Simulation } from './components/Simulation';
import { Wallet } from './components/Wallet';

import { useState } from 'react';
import Register from './components/Register';
import { MotivationInvest } from './components/MotivationInvest';
import { Learn } from './components/Learn';
import { Footer } from './components/Footer';

export function Business() {
  const [showRegisterScreen, setShowRegisterScreen] = useState(false);

  function handleViewScreenRegister() {
    setShowRegisterScreen(!showRegisterScreen);
  }

  return (
    <BusinessContainer>
      {/* <img src={leftIconImg} alt="" className="leftLogo" />
      <img src={rightIconImg} alt="" className="rightLogo" />
      <img src={rightIconImg} alt="" className="rightLogoBottom" /> */}
      <Header onShowRegisterScreen={handleViewScreenRegister} />
      <BusinessContent>
        <Home onShowRegisterScreen={handleViewScreenRegister} />
        <KnowPlatform onShowRegisterScreen={handleViewScreenRegister} />
      </BusinessContent>
      <AssetReviews onShowRegisterScreen={handleViewScreenRegister} />
      <BusinessContent>
        <FeaturesInformation onShowRegisterScreen={handleViewScreenRegister} />
      </BusinessContent>
      <DividendInformation onShowRegisterScreen={handleViewScreenRegister} />
      <BusinessContent>
        <Education onShowRegisterScreen={handleViewScreenRegister} />
      </BusinessContent>
      <Simulation onShowRegisterScreen={handleViewScreenRegister} />
      <BusinessContent>
        <Wallet onShowRegisterScreen={handleViewScreenRegister} />
        {/*  */}
      </BusinessContent>

      <MotivationInvest />

      <BusinessContent>
        <Learn onShowRegisterScreen={handleViewScreenRegister} />
      </BusinessContent>

      <ContentRegisterOverLay isShow={showRegisterScreen}>
        <Register onShowRegisterScreen={handleViewScreenRegister} />
      </ContentRegisterOverLay>

      <Footer />
    </BusinessContainer>
  );
}

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IPropsContracts } from '../../utils/@types';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontFamily: 'Poppins, sans-serif',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: 'Poppins, sans-serif',
  },
}));

const StyledBody = styled(TableBody)(
  ({ theme }) => `
  overflow-y: auto;
  max-height:500px;

`
);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const header = [
  { label: 'Período' },
  { label: 'Porcentagem' },
  // { label: 'Vacância Financeira' },
  // { label: 'Vacância Fisica' },
];

interface IPropsTableVacancia {
  data: IPropsContracts[];
  editContract: (date: string, value: number) => void;
}

export function TableContracts({ data, editContract }: IPropsTableVacancia) {
  return (
    <TableContainer component={Paper} style={{ maxHeight: '500px' }}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table" stickyHeader>
        <TableHead>
          <TableRow>
            {header.map((item) => (
              <StyledTableCell key={item.label}>{item.label}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <StyledBody>
          {data.map((item, index) => (
            <StyledTableRow key={item.periodo}>
              <StyledTableCell>{item.periodo}</StyledTableCell>
              <StyledTableCell>
                <input
                  type="number"
                  step="0.01"
                  className="inputTable"
                  defaultValue={item.valor}
                  onChange={(e) =>
                    editContract(item.periodo, Number(e.target.value))
                  }
                />
                %
              </StyledTableCell>
              {/* <StyledTableCell>{item.tipo}</StyledTableCell>
              <StyledTableCell>{item.competencia}</StyledTableCell> */}
            </StyledTableRow>
          ))}
        </StyledBody>
      </Table>
    </TableContainer>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 0 1rem;
  img {
    width: auto;
    border-radius: 4px;
  }

  h1 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0;
    margin-top: 1rem;
    color: ${(props) => props.theme['gray-700']};
    padding: 0 1rem;
  }
  p {
    font-size: 0.75rem;
    font-weight: 400;
    color: ${(props) => props.theme['gray-700']};
    margin: 0;
    padding: 0 1rem;
  }

  footer {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-end;
    padding: 0.5rem;

    a {
      font-size: 1rem;
      color: ${(props) => props.theme.primary};
      transition: filter 0.3s;
      &:hover {
        filter: brightness(0.8);
      }
    }
  }
`;

import { X } from '@phosphor-icons/react';
import { GraphicNote } from '../GraphicNote';
import { CardSelectedActiveContainer, RemoveActiveButton } from './styles';

interface IPropsCardSelectedGraphicActive {
  active: {
    nota: {
      codigo_fii: string;
      nome_fundo: string;
      nota_final: number;
      data_funcionamento_numerico: number;
      qtde_ativos: number;
      vacancia_fisica: number;
      dividendo_yeld: number;
      valor_patrimonial_por_cota: number;
      tipo_fii: string;
    };
    comparar: {
      ultimo_dy: number;
      ultimo_valor_patrimonial: number;
      ultimo_patrimonio_liquido: number;
      ultimo_p_vp: number;
      ultimo_vacancia_fisica: number;
      ultimo_qtde_ativos: number;
    };
  };
  onRemoveItem: () => void;
}

export function CardSelectedGraphicActive({
  active,
  onRemoveItem,
}: IPropsCardSelectedGraphicActive) {
  return (
    <CardSelectedActiveContainer>
      <div
        style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
      >
        <RemoveActiveButton title="Remover ativo" onClick={onRemoveItem}>
          {' '}
          <X weight="bold" />
        </RemoveActiveButton>
      </div>
      <GraphicNote active={active.nota} />
      <span>{active.nota.codigo_fii}</span>
    </CardSelectedActiveContainer>
  );
}

import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { videosRoutes } from '../../../../../../../utils/RoutesApi';
import { Container } from './styles';

interface IPropsVideos {
  category: number;
  channel: number;
  channelThumbnail: string;
  channelTitle: string;
  comment: number[];
  date: string;
  description: string;
  dislike: number[];
  id: number;
  like: number[];
  thumbnail: string;
  title: string;
  video_id: string;
  view: number[];
}

interface IPropsFeatureVideo {
  video: IPropsVideos;
}

export function FeaturedVideo({ video }: IPropsFeatureVideo) {
  return (
    <Container>
      <img src={video.thumbnail} />
      {/* <h1>{video.title}</h1>
      <p>{video.description}</p> */}
      <footer>
        <Link to={`watch/?v=${video.video_id}`}>Assistir à aula</Link>
      </footer>
    </Container>
  );
}

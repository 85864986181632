import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import { IPropsDividendos } from '../../utils/@types';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontFamily: 'Poppins, sans-serif',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: 'Poppins, sans-serif',
  },
}));

const StyledBody = styled(TableBody)(
  ({ theme }) => `
  overflow-y: auto;
  max-height:500px;

`
);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const header = [
  { label: 'Data pagamento' },
  { label: 'Data base' },
  { label: 'DY' },
  { label: 'rendimento' },
  // { label: 'Vacância Financeira' },
  // { label: 'Vacância Fisica' },
];

interface IPropsTableDividendo {
  data: IPropsDividendos[];
  editDividendo: (date: string, value: number, type: string) => void;
}

export function TableDividendos({ data, editDividendo }: IPropsTableDividendo) {
  return (
    <TableContainer component={Paper} style={{ maxHeight: '500px' }}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table" stickyHeader>
        <TableHead>
          <TableRow>
            {header.map((item) => (
              <StyledTableCell key={item.label}>{item.label}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <StyledBody>
          {data.map((item, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell>
                {moment(item.data_pagamento).format('l')}
              </StyledTableCell>
              <StyledTableCell>
                {moment(item.data_base).format('l')}
              </StyledTableCell>
              <StyledTableCell>
                <input
                  type="number"
                  step="0.01"
                  className="inputTable"
                  defaultValue={item.dy}
                  onChange={(e) =>
                    editDividendo(
                      item.data_pagamento,
                      Number(e.target.value),
                      'dy'
                    )
                  }
                />
                R$
              </StyledTableCell>
              <StyledTableCell>
                <input
                  type="number"
                  step="0.01"
                  className="inputTable"
                  defaultValue={item.rendimento}
                  onChange={(e) =>
                    editDividendo(
                      item.data_pagamento,
                      Number(e.target.value),
                      'rendimento'
                    )
                  }
                />
              </StyledTableCell>

              {/* <StyledTableCell>{item.tipo}</StyledTableCell>
              <StyledTableCell>{item.competencia}</StyledTableCell> */}
            </StyledTableRow>
          ))}
        </StyledBody>
      </Table>
    </TableContainer>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

interface IPropsTabs {
  tab: number;
}
export const Tabs = styled.div<IPropsTabs>`
  display: flex;
  flex-direction: row;

  background-color: ${(props) => props.theme.white};

  width: 100%;
  border-radius: 8px;

  p {
    margin: 0;
    cursor: pointer;
    padding: 0.625rem 1.5rem;
  }
  .activeTab {
    font-weight: bold;
    border-bottom: 2px solid ${(props) => props.theme.primary};
    color: ${(props) => props.theme.primary};
    background: #e7f4ff;
    border-radius: ${(props) => (props.tab === 1 ? '8px 0 0 8px' : '0')};
  }
  margin-bottom: 1rem;
`;

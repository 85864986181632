import styled from 'styled-components';

export const HeaderMobileContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

export const ContentCompanyName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 0.5rem;

  h2 {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 600;
    color: ${(props) => props.theme['gray-600']};
  }
  span {
    font-weight: 500;
    font-size: 1rem;
    color: ${(props) => props.theme['gray-600']};
  }
`;

import { useEffect, useState } from 'react';
import { ChartBar, Scales } from '@phosphor-icons/react';
import { useTitleAndUser } from '../../../hooks/userTitle';
import { AllActive } from './Tabs/AllActive';
import { CarouselDashInfos } from './components/Carousel';
import { CompositionGraphicWallet } from './components/CompositionGraphicWallet';
import { Brokerages } from './components/Brokerages/intex';
import { ActionActives } from './Tabs/ActionAcitves';
import { FiiActives } from './Tabs/FiiActives';
import { ActivesProviderWallet } from './Context/ActivesContext';
import { ManualImport } from './Tabs/ManualImport';
import { EmergenceReserve } from './Tabs/EmergenceReserve';
import { Strategy } from './Tabs/Strategy';
import { StrategyContextProvider } from './Context/StrategyContext';
import { AdjustStrategy } from './Tabs/Strategy/AdjustStrategy';
import { Simulation } from './Tabs/Simulation';
import { SimulationProvider } from './Context/SimulationContext';
import { FormSimulation } from './Tabs/Simulation/components/FormSimulation';
import {
  ButtonTab,
  ButtonTabContentMobile,
  Content,
  ContentButtonValues,
  ContentTab,
  ContentTabMobile,
  ContentTabNavigateMobile,
  SelectedTabNavigateMobile,
  TabValuesContent,
  ValuesContent,
  WalletContainer,
} from './styles';
import { useSimulation } from './hooks/SimulationHooks';

export function Wallet() {
  // @ts-ignore
  const { setTitle } = useTitleAndUser();
  const { setType } = useSimulation();
  const [tab, setTab] = useState(1);
  const [screens, setScreens] = useState<'wallet' | 'strategy' | 'simulators'>(
    'wallet'
  );

  useEffect(() => {
    setTitle('Carteira');
  }, []);

  return (
    <ActivesProviderWallet>
      <StrategyContextProvider>
        <SimulationProvider>
          <WalletContainer>
            {window.screen.width > 768 && (
              <ContentTab>
                <ButtonTab
                  onClick={() => {
                    setScreens('wallet');
                    setTab(1);
                    setType('all');
                  }}
                  isActive={tab === 1}
                >
                  Todos os ativos
                </ButtonTab>
                <ButtonTab
                  onClick={() => {
                    setScreens('wallet');
                    setTab(2);
                    setType('action');
                  }}
                  isActive={tab === 2}
                >
                  Ações
                </ButtonTab>
                <ButtonTab
                  onClick={() => {
                    setScreens('wallet');
                    setTab(3);
                    setType('fii');
                  }}
                  isActive={tab === 3}
                >
                  FIIs
                </ButtonTab>
                <ButtonTab
                  onClick={() => {
                    setScreens('wallet');
                    setTab(4);
                  }}
                  isActive={tab === 4}
                >
                  Importação manual
                </ButtonTab>
                <ButtonTab
                  onClick={() => {
                    setScreens('wallet');
                    setTab(5);
                  }}
                  isActive={tab === 5}
                >
                  Reserva de emergência
                </ButtonTab>
              </ContentTab>
            )}
            <Content>
              {window.screen.width <= 768 && (
                <>
                  {screens !== 'simulators' && (
                    <div className="contentCarousel">
                      <CarouselDashInfos />
                    </div>
                  )}
                  <ContentTabMobile>
                    <ButtonTabContentMobile
                      onClick={() => {
                        if (screens === 'simulators') {
                          setScreens('wallet');
                        } else {
                          setScreens('simulators');
                        }
                      }}
                      active={screens === 'simulators'}
                    >
                      <Scales /> Simulador
                    </ButtonTabContentMobile>
                    <ButtonTabContentMobile
                      onClick={() => {
                        if (screens === 'strategy') {
                          setScreens('wallet');
                        } else {
                          setScreens('strategy');
                        }
                      }}
                      active={screens === 'strategy'}
                    >
                      <ChartBar />
                      Estratégia
                    </ButtonTabContentMobile>
                  </ContentTabMobile>
                  <ContentTabNavigateMobile>
                    <SelectedTabNavigateMobile
                      onClick={() => {
                        setScreens('wallet');
                        setTab(1);
                      }}
                      isActive={tab === 1}
                    >
                      Todos os ativos
                    </SelectedTabNavigateMobile>
                    <SelectedTabNavigateMobile
                      onClick={() => {
                        setScreens('wallet');
                        setTab(2);
                      }}
                      isActive={tab === 2}
                    >
                      Ações
                    </SelectedTabNavigateMobile>
                    <SelectedTabNavigateMobile
                      onClick={() => {
                        setScreens('wallet');
                        setTab(3);
                      }}
                      isActive={tab === 3}
                    >
                      FIIs
                    </SelectedTabNavigateMobile>
                    <SelectedTabNavigateMobile
                      onClick={() => {
                        setScreens('wallet');
                        setTab(4);
                      }}
                      isActive={tab === 4}
                    >
                      Importação manual
                    </SelectedTabNavigateMobile>
                    <SelectedTabNavigateMobile
                      onClick={() => {
                        setScreens('wallet');
                        setTab(5);
                      }}
                      isActive={tab === 5}
                    >
                      Reserva de emergência
                    </SelectedTabNavigateMobile>
                  </ContentTabNavigateMobile>
                </>
              )}
              {window.screen.width <= 768 && (
                <>
                  <Brokerages />
                  <br />
                  {screens === 'strategy' && <AdjustStrategy />}
                  {screens === 'simulators' && <FormSimulation />}
                </>
              )}
              <ValuesContent>
                {window.screen.width > 768 && (
                  <TabValuesContent>
                    <ContentButtonValues
                      onClick={() => {
                        if (screens === 'simulators') {
                          setScreens('wallet');
                        } else {
                          setScreens('simulators');
                        }
                      }}
                      active={screens === 'simulators'}
                    >
                      <Scales /> Simulador
                    </ContentButtonValues>
                    <ContentButtonValues
                      onClick={() => {
                        if (screens === 'strategy') {
                          setScreens('wallet');
                        } else {
                          setScreens('strategy');
                        }
                      }}
                      active={screens === 'strategy'}
                    >
                      <ChartBar />
                      Estratégia
                    </ContentButtonValues>
                  </TabValuesContent>
                )}
                {window.screen.width > 768 &&
                  (screens === 'strategy' ? (
                    <AdjustStrategy />
                  ) : screens === 'simulators' ? (
                    <FormSimulation />
                  ) : (
                    <div className="contentCarousel">
                      <CarouselDashInfos />
                    </div>
                  ))}
                {screens !== 'strategy' && screens !== 'simulators' && (
                  <CompositionGraphicWallet tab={tab} />
                )}
              </ValuesContent>

              <div style={{ background: '#efefef', borderRadius: 16 }}></div>
              {screens === 'strategy' && <Strategy />}
              {screens === 'simulators' && (
                <Simulation
                  inicialTypeAporte={
                    tab === 1 ? 'all' : tab === 2 ? 'action' : 'fii'
                  }
                />
              )}
              {screens === 'wallet' && tab === 1 && <AllActive />}
              {screens === 'wallet' && tab === 2 && <ActionActives />}
              {screens === 'wallet' && tab === 3 && <FiiActives />}
              {screens === 'wallet' && tab === 4 && <ManualImport />}
              {screens === 'wallet' && tab === 5 && <EmergenceReserve />}
            </Content>
          </WalletContainer>
        </SimulationProvider>
      </StrategyContextProvider>
    </ActivesProviderWallet>
  );
}

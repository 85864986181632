import { IPropsImovel } from '../../@types/ImovelProps';
import { Container } from './styles';

interface IPropsCardImovel {
  imovel: IPropsImovel;
}

export function CardImovel({ imovel }: IPropsCardImovel) {
  const maskArea = new Intl.NumberFormat('pt-BR', {
    style: 'decimal',
  }).format(imovel.Area);
  const maskVacanciaPercent = new Intl.NumberFormat('pt-BR', {
    style: 'percent',
  }).format(imovel.PercentVacancia);
  const maskInadimplenciaPercent = new Intl.NumberFormat('pt-BR', {
    style: 'percent',
  }).format(imovel.PercentInadimplencia);

  return (
    <Container>
      <header>
        <div>
          {imovel.Estado && <span className="estado">{imovel.Estado}</span>}
          <span>
            UN: <strong>{imovel.NumUnidades}</strong>
          </span>
        </div>
        <div>
          <span>
            Área: <strong>{maskArea}m²</strong>
          </span>
        </div>
      </header>
      <main>
        <a
          href={`https://www.google.com.br/maps/place/${imovel.Endereco.split(
            ' '
          )
            .map((address) => address)
            .join('+')}`}
          target="_blank"
        >
          Ver no mapa
        </a>
        <h3>{imovel.Nome}</h3>

        <footer>
          <span>
            VACÂNCIA
            <strong>{maskVacanciaPercent}</strong>
          </span>

          <span className="inadimplencia">
            Inadimplência
            <strong>{maskInadimplenciaPercent}</strong>
          </span>
        </footer>
      </main>
    </Container>
  );
}

import styled from 'styled-components';

export const ContentForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: ${(props) => props.theme.white};
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid var(--Gray-gray-100, #D4D6DB);
  background: var(--Main-white, #FFF);
`;

export const ContentInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0rem;
  
  background: var(--Main-white, #FFF);
  label {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 400;
    color: #303F4B;
  }

  input {
    all: unset;
    padding: 1rem;
    outline: 1px solid transparent;

    font-weight: 400;
    font-size: 0.875rem;
    padding: 0.5rem;
    color: #9196A1;
    border-bottom: 1px solid #9196A1;
    background: var(--Main-white, #FFF);

    &:focus {
      border-bottom: 3px solid var(--Gray-gray-600, #EC6643);
    }

    &:disabled {
      cursor: not-allowed;
      background: ${(props) => props.theme['white-300']};
    }
  }
`;

export function formatNomeFundo(nome: string) {
  if (
    nome ===
    'FUNDO DE INVESTIMENTO IMOBILIÁRIO - FII BTG PACTUAL CORPORATE OFFICE FUND'
  ) {
    nome = nome.replace('FUNDO DE INVESTIMENTO IMOBILIÁRIO - ', '');

    return nome;
  }
  if (nome === 'FUNDO DE INVESTIMENTO IMOBILIARIO - FII EDIFICIO GALERIA') {
    nome = nome.replace('FUNDO DE INVESTIMENTO IMOBILIARIO - ', '');

    return nome;
  }
  nome = nome.replace('FUNDO DE INVESTIMENTO IMOBILIARIO', '');
  nome = nome.replace('FUNDO DE INVESTIMENTO IMOBILIÁRIO', '');
  nome = nome.replace(' - FII', '');
  nome = nome.replace('-', '');

  return nome;
}

export function formatNomeFundoAdministradora(nome: string) {
  if (
    nome ===
    'FUNDO DE INVESTIMENTO IMOBILIÁRIO - FII BTG PACTUAL CORPORATE OFFICE FUND'
  ) {
    nome = nome.replace('FUNDO DE INVESTIMENTO IMOBILIÁRIO - ', '');

    return nome;
  }
  if (nome === 'FUNDO DE INVESTIMENTO IMOBILIARIO - FII EDIFICIO GALERIA') {
    nome = nome.replace('FUNDO DE INVESTIMENTO IMOBILIARIO - ', '');

    return nome;
  }
  nome = nome.replace('FUNDO DE INVESTIMENTO IMOBILIARIO', '');
  nome = nome.replace('FUNDO DE INVESTIMENTO IMOBILIÁRIO', '');
  nome = nome.replace(' - FII', '');
  nome = nome.replace('-', '');

  let newName: string | string[] = nome.split(' ');
  newName = `${newName[0]} ${newName[1]}`;
  return newName;
}

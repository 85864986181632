import { Heading } from '../../../../../components/DefaultStylesTypography';
import { InfoBoxData } from './dataCards';
import {
  CardDataContent,
  ContentBoxInformation,
  FeaturesInformationContainer,
} from './styles';
import blueLogo from '../../../../../assets/business/blueLogo.svg';

interface IPropsScreen {
  onShowRegisterScreen: () => void;
}

export function FeaturesInformation({ onShowRegisterScreen }: IPropsScreen) {
  return (
    <FeaturesInformationContainer>
      <Heading>Tudo que você precisa, dentro do mesmo Box!</Heading>
      <ContentBoxInformation>
        {InfoBoxData.map((item) =>
          item.isCenter && window.screen.width >= 1088 ? (
            <div className="blueLogo" key={item.id}>
              <img src={blueLogo} alt="" />
            </div>
          ) : (
            <CardDataContent key={item.id}>
              <header>
                <span>{item.infoBox}</span>
                <Heading as="h5" variant="quintiary">
                  {item.title}
                </Heading>
                <i>{item.icon}</i>
              </header>
              <p>{item.description}</p>
            </CardDataContent>
          )
        )}
      </ContentBoxInformation>
      <button type="button" onClick={onShowRegisterScreen}>
        Seja um iBoxer
      </button>
    </FeaturesInformationContainer>
  );
}

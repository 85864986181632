import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 1rem;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 100%;

  header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    h1 {
      font: 700 1.5rem 'Poppins', sans-serif;
    }
  }
`;

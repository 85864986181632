import styled from 'styled-components';
import { css } from 'styled-components';

interface IPropsHeading {
  variant?: 'secondary' | 'tertiary' | 'quaternary' | 'quintiary' | 'sextiary';
  color?: string;
}
/*
 * calcular line height
 *  (line-height em px / tamanho de fonte em px) * 100
 *
 */

export const Heading = styled.h2<IPropsHeading>`
  font: 500 2.5rem 'Poppins', sans-serif;
  color: ${(props) => (props.color ? props.color : props.theme['gray-700'])};
  line-height: 135%;
  margin: 0;

  ${(props) =>
    props.variant === 'secondary' &&
    css`
      font-size: 2rem;
      line-height: 137.5%;
    `}
  ${(props) =>
    props.variant === 'tertiary' &&
    css`
      font-size: 1.75rem;
      line-height: 135.71%;
      font-weight: 600;
    `}

    ${(props) =>
    props.variant === 'quaternary' &&
    css`
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 133.33%;
    `}
    ${(props) =>
    props.variant === 'quintiary' &&
    css`
      font-size: 1.25rem;
      font-weight: 700;
      line-height: 140%;
    `}
    ${(props) =>
    props.variant === 'sextiary' &&
    css`
      font-size: 1.125rem;
      font-weight: 700;
      line-height: 122.22%;
    `}
`;

interface IPropsText {
  bold?: boolean;
  variant?: 'md' | 'sm' | 'xs';
  color?: string;
}

export const Text = styled.p<IPropsText>`
  margin: 0;
  font-weight: ${(props) => (props.bold ? '700' : '400')};
  font-size: 1rem;
  color: ${(props) => (props.color ? props.color : props.theme['gray-700'])};
  line-height: 150%;

  ${(props) =>
    props.variant === 'md' &&
    css`
      font-size: 0.875rem;
      line-height: 171%;
    `}
  ${(props) =>
    props.variant === 'sm' &&
    css`
      font-size: 0.75rem;
      line-height: 150%;
    `}
  ${(props) =>
    props.variant === 'xs' &&
    css`
      font-size: 0.625rem;
      line-height: 150%;
    `}
`;

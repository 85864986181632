import moment from 'moment';
import InputMask from 'react-input-mask';
import Select from 'react-select';

import {
  AsideFiltersProventosRecebidosContainer,
  ContentInput,
} from './styles';
import { IPropsFilterData } from '../..';
import { ChangeEvent } from 'react';

interface IPropsAsideFilters {
  setSelectDateFilter: ({ start, end }: { start: string; end: string }) => void;
  selectDateFilter: { start: string; end: string };
  setFiltersSelecteds: Function;
  returnSelectValue: Function;
  filtersSelecteds: any;
  filterData: IPropsFilterData;
  setTypeGraphic: Function;
  typeGraphic: 'month' | 'year';
}

export function AsideFiltersProventosRecebidos({
  setSelectDateFilter,
  selectDateFilter,
  setFiltersSelecteds,
  returnSelectValue,
  filtersSelecteds,
  filterData,
  setTypeGraphic,
  typeGraphic,
}: IPropsAsideFilters) {
  const handleOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTypeGraphic(event.target.value);
    setSelectDateFilter({ start: '', end: '' });
  };

  const isMonth = typeGraphic === 'month';
  const isYear = typeGraphic === 'year';


  return (
    <AsideFiltersProventosRecebidosContainer>
      <div className='filtros'><span>Filtros</span></div>
      <ContentInput>
        <label className="radioContentLabel">Exibição do gráfico</label>
        <div className="radioContent">
          <label className="inputRadio" htmlFor="month">
            <input
              type="checkbox"
              checked={isMonth}
              value="month"
              name="typeGraphic"
              onChange={handleOptionChange}
              id="month"
            />{' '}
            Mês
          </label>
          <label className="inputRadio" htmlFor="year">
            <input
              type="checkbox"
              value="year"
              name="typeGraphic"
              onChange={handleOptionChange}
              id="year"
              checked={isYear}
            />{' '}
            Ano
          </label>
        </div>
      </ContentInput>
      <ContentInput>
        <label className="deLabel">De</label>
        {isYear && (
          <InputMask
            mask="9999"
            placeholder={`Ex: ${moment()
              .subtract(12, 'months')
              .format('YYYY')}`}
            className="inputDate"
            defaultValue={moment(selectDateFilter.end, 'MM/YYYY').format(
              'YYYY'
            )}
            onChange={(e) => {
              if (e.target.value.replace(/\D/g, '').length === 4) {
                setSelectDateFilter({
                  ...selectDateFilter,
                  start: `01/${e.target.value}`,
                });
              }
            }}
          />
        )}
        {isMonth && (
          <InputMask
            mask="99/9999"
            placeholder={`Ex: ${moment()
              .subtract(12, 'months')
              .format('MM/YYYY')}`}
            className="inputDate"
            defaultValue={selectDateFilter.start}
            onChange={(e) => {
              if (e.target.value.replace(/\D/g, '').length === 6) {
                setSelectDateFilter({
                  ...selectDateFilter,
                  start: e.target.value,
                });
              }
            }}
          />
        )}
      </ContentInput>
      <ContentInput>
        <label className="deLabel">Até</label>
        {isYear && (
          <InputMask
            mask="9999"
            placeholder={`Ex: ${moment().format('YYYY')}`}
            className="inputDate"
            defaultValue={moment(selectDateFilter.end, 'MM/YYYY').format(
              'YYYY'
            )}
            onChange={(e) => {
              if (e.target.value.replace(/\D/g, '').length === 4) {
                setSelectDateFilter({
                  ...selectDateFilter,
                  end: `12/${e.target.value}`,
                });
              }
            }}
          />
        )}

        {isMonth && (
          <InputMask
            mask="99/9999"
            placeholder={`Ex: ${moment().format('MM/YYYY')}`}
            className="inputDate"
            defaultValue={selectDateFilter.end}
            onChange={(e) => {
              if (e.target.value.replace(/\D/g, '').length === 6) {
                setSelectDateFilter({
                  ...selectDateFilter,
                  end: e.target.value,
                });
              }
            }}
          />
        )}
      </ContentInput>
      <ContentInput>
        <label className='tipoLabel'>Tipo de ativo</label>
        <Select
          className="selectTipo"
          options={[
            { label: 'Todos', value: 'todos' },
            { label: 'Ações', value: 'Ações' },
            {
              label: 'FII - Fundo de Investimento Imobiliário',
              value: 'FII - Fundo de Investimento Imobiliário',
            },
          ]}
          //@ts-ignore
          onChange={(e) => {
            setFiltersSelecteds({
              ...filtersSelecteds,
              // @ts-ignore
              tipoproduto: e.value,
            });
          }}
          value={returnSelectValue(filtersSelecteds.tipoproduto, [
            { label: 'Todos', value: 'todos' },

            { label: 'Ações', value: 'Ações' },
            {
              label: 'FII - Fundo de Investimento Imobiliário',
              value: 'FII - Fundo de Investimento Imobiliário',
            },
          ])}
          placeholder="Tipo de ativo"
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              boxShadow: '#6A717E',
              borderRadius: 2,
              backgroundColor:'transparent',
              border: 'transparent',
              width: '180px',
            }),
            container: (baseStyles) => ({
              ...baseStyles,
              borderRadius: 2,
              backgroundColor:'transparent',
              border: 'transparent',
              width: '180px',
            }),
            placeholder: (base) => ({
              ...base,
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),            
          }}
        />
      </ContentInput>
      <ContentInput>
        <label className='ativoLabel'>Ativo</label>
        <Select
          className="selectAtivo"
          options={filterData.Ativos}
          //@ts-ignore
          onChange={(e) =>
            setFiltersSelecteds({
              ...filtersSelecteds,
              // @ts-ignore
              ativo: e.value,
            })
          }
          value={returnSelectValue(filtersSelecteds.ativo, filterData.Ativos)}
          placeholder="Selecione o ativo"
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              boxShadow: '#6A717E',
              borderRadius: 2,
              backgroundColor:'transparent',
              border: 'transparent',
              width: '180px',
            }),
            container: (baseStyles) => ({
              ...baseStyles,
              borderRadius: 2,
              backgroundColor:'transparent',
              border: 'transparent',
              width: '180px',
            }),
            placeholder: (base) => ({
              ...base,
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
          }}
        />
      </ContentInput>
      <ContentInput>
        <label className="corretoraLabel">Corretora</label>
        <Select
          className="selectCorretora"
          options={filterData.Corretoras}
          //@ts-ignore
          onChange={(e) =>
            setFiltersSelecteds({
              ...filtersSelecteds,
              // @ts-ignore
              corretora: e.value,
            })
          }
          value={returnSelectValue(
            filtersSelecteds.corretora,
            filterData.Corretoras
          )}
          placeholder="Seleciona corretora"
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              boxShadow: '#6A717E',
              borderRadius: 2,
              backgroundColor:'transparent',
              border: 'transparent',
              width: '185px',
            }),
            container: (baseStyles) => ({
              ...baseStyles,
              borderRadius: 2,
              backgroundColor:'transparent',
              border: 'transparent',
              width: '185px',
            }),
            placeholder: (base) => ({ 
              ...base,
              padding:'4px 4px 0px 0px', 
              zIndex: 3, // ou 2, conforme necessário 
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
          }}
        />
      </ContentInput>
      <ContentInput>
        <label className='proventosLabel'>Proventos</label>
        <Select
          className="selectProventos"
          options={filterData.TiposMovimentos}
          //@ts-ignore
          onChange={(e) =>
            setFiltersSelecteds({
              ...filtersSelecteds,
              // @ts-ignore
              tiposmovimento: e.value,
            })
          }
          value={returnSelectValue(
            filtersSelecteds.tiposmovimento,
            filterData.TiposMovimentos
          )}
          placeholder="Tipo de proventos"
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              boxShadow: '#6A717E',
              borderRadius: 2,
              backgroundColor:'transparent',
              border: 'transparent',
              width: '180px',
            }),
            container: (baseStyles) => ({
              ...baseStyles,
              borderRadius: 2,
              backgroundColor:'#fff',
              border: 'transparent',
              width: '180px',
            }),
            placeholder: (base) => ({
              ...base,
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
          }}
        />
      </ContentInput>
    </AsideFiltersProventosRecebidosContainer>
  );
}

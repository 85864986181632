import styled from 'styled-components';

export const SimuladoresContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5%;
`;

export const BannerContent = styled.img`
  width: 118%;
  max-width: 1235px;
  cursor: pointer;
  margin-bottom: 32px;
  margin-left: -10%;
`;

export const TabPagesContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  

  
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;

`;

interface IPropsButton {
  isActive: boolean;
}

export const ButtonTab = styled.button<IPropsButton>`
  display: flex;
  align-items: center;
  gap: 8px;
  all: unset;
  padding: 0.675rem 0.875rem;
  width: 100%;
  font-weight: ${(props) => (props.isActive ? 'bold' : 'normal')};
  font-size: 1.125rem;
  text-align: center;
  color: ${props => (props.isActive ? '#EC6643' : '#3f3f46')};
  border-top: ${(props) =>
    props.isActive ? '4px solid #EC6643' : '1px solid #D4D6DB'};
  border-radius: var(--Spacing-8, 8px) var(--Spacing-8, 8px) 0px 0px;
  border-right: 1px solid var(--Gray-gray-100, #D4D6DB);
  border-left: 1px solid var(--Gray-gray-100, #D4D6DB);
  border-bottom: ${(props) =>
    props.isActive ? 'none' : '1px solid #D4D6DB'};
  background: ${(props) =>
    props.isActive ? '#fff' : '#FCFCFD'};

  @media (max-width: 480px) {
    /* padding: 1rem 0.5rem; */
    font-size: 0.75rem;
  }
  

`;

import axios from 'axios';
import store from '../store/store';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
});



export default api;

import styled from 'styled-components';

export const FormEditJurosContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  p {
    font-size: 1.125rem;
    font-weight: 400;
    color: ${(props) => props.theme['gray-400']};
    margin: 0;
    strong {
      font-weight: 600;
      background: ${(props) => props.theme['primary-800']};
      color: ${(props) => props.theme.white};
      border-radius: 32px;
      padding: 0.25rem 0.5rem;
    }
  }

  .contentValue {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    h4 {
      font-size: 0.875rem;
      color: ${(props) => props.theme['navy-600']};
      font-weight: 500;
    }
    span {
      font-size: 1rem;
      font-weight: 400;
      color: ${(props) => props.theme['gray-500']};

      strong {
        color: ${(props) => props.theme['gray-700']};
      }
    }
  }
`;
export const ContentInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  label {
    font-weight: 500;
    font-size: 1.125rem;
    color: ${(props) => props.theme['gray-600']};
  }

  select {
    border: none;
    border-left: 1px solid ${(props) => props.theme['gray-100']};
    padding-left: 0.5rem;
  }
`;

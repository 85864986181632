import styled from 'styled-components';

export const GraphicContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  position: relative;
  z-index: 1;
`;

import { FiEdit, FiPlus } from 'react-icons/fi';
import { MdDelete } from 'react-icons/md';
import styled from 'styled-components';

export const Container = styled.div`
  font-family: 'Poppins', sans-serif;

  .contentRight {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  .filterContent {
    display: flex;

    select {
      border: none;
      border-bottom: 1px solid;
      padding: 5px;
      background-color: ${(props) => props.theme.primary};
      color: ${(props) => props.theme.white};
    }
    input {
      border: none;
      border-bottom: 1px solid;
      padding: 4px;
    }
  }

  .reduce {
    max-width: 15ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const DeleteActive = styled(MdDelete)`
  font-size: 15px;
  cursor: pointer;
`;
export const EditActive = styled(FiEdit)`
  font-size: 15px;
  cursor: pointer;
`;
export const AddActive = styled(FiPlus)`
  font-size: 15px;
  cursor: pointer;
  color: ${(props) => props.theme.black};
`;

export const HeaderStyle = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.white};
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  justify-content: space-between;
  border-radius: 4px;
  box-shadow: ${(props) => props.theme['card-shadow-2']};

  .contentFilter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-left: 1rem;
  }
  div {
    display: flex;
    gap: 1rem;
    align-items: center;

    .divider {
      height: 30px;
      width: 1px;
      background: rgba(21, 21, 21, 0.3);
    }
    input {
      border: ${(props) => props.theme.border};
      border-radius: 4px;
      padding: 0.2rem 0.5rem;
    }

    p {
      font-size: 1rem;
      margin: 0;
      cursor: pointer;
      transition: color 0.3s;
      :hover {
        color: ${(props) => props.theme.primary};
      }
    }

    .activeFilter {
      color: ${(props) => props.theme.primary};
      font-weight: 700;
      border-bottom: 1px solid ${(props) => props.theme.primary};
    }

    button {
      border: none;
      outline: none;
      background-color: transparent;
      font-size: 1rem;
      transition: color 0.3s;

      :hover {
        color: ${(props) => props.theme.primary};
      }
    }
    button.delete {
      color: #ff6363;
    }
    button.delete:hover {
      color: red;
    }
  }

  @media (max-width: 577px) {
    flex-direction: column;
    align-items: start;

    .contentFilter {
      margin-left: 0;
      margin-top: 10px;
    }

    div {
      width: 100%;
      input {
        width: 100%;
      }

      button {
        display: flex;
        justify-content: flex-end;
        width: 120px;
      }
    }
  }
`;

export const DeleteAllActives = styled.div`
  display: flex;
  flex-direction: column;
  p {
    margin: 0;
  }
  .buttonContent {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
`;

const backgroundColorForType = {
  acao: '#FFB900',
  FII: '#303F4B',
  rendaFixa: '#FF8B4B',
};

export const TypeActiveContent = styled.span`
  padding: 0.25rem 0.5rem;
  border-radius: 41px;
  color: ${(props) => props.theme.white};
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  white-space: nowrap;
  background: ${(props) => backgroundColorForType[props.typeActive]};
`;

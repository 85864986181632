import { Check, Warning } from '@phosphor-icons/react';
import { toast } from 'react-toastify';

const fiveSeconds = 1000 * 5;

export function handleToast(error, message) {
  if (error)
    return toast.error(message, {
      position: 'top-right',
      autoClose: fiveSeconds,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      icon: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#F94361',
            color: '#F94361',
            width: '48px',
          }}
        >
          <div
            style={{
              borderRadius: '100%',
              background: '#fff',
              width: '24px',
              height: '24px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Warning weight="bold" width={16} height={16} />
          </div>
        </div>
      ),
    });

  return toast.success(message, {
    position: 'top-right',
    autoClose: fiveSeconds,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
    icon: (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: '#73CE61',
          width: '48px',

          color: '#73CE61',
        }}
      >
        <div
          style={{
            borderRadius: '100%',
            background: '#fff',
            width: '24px',
            height: '24px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Check weight="bold" width={16} height={16} />
        </div>
      </div>
    ),
  });
}

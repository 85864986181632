import axios from 'axios';
import { Switch } from 'antd';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Heading } from '../../../../../components/DefaultStylesTypography';
import { InputComponent } from '../../../../../components/InputComponent';
import { CardPlan, IPropsPlan } from './components/CardPreviewPlan';
import { ButtonDesign } from '../../../../../components/ButtonDesign';
import { userRoutes } from '../../../../../utils/RoutesApi';
import { handleToast } from '../../../../../components/Toast';
import {
  AdminPlansStyles,
  ContentFormCreatePlan,
  ContentInput,
  ContentRowInput,
} from './styles';

const baseURL = process.env.REACT_APP_API_URL;

interface IPropsCreateNewPlan {
  editPlan?: {
    plan: IPropsPlan;
    isEdit: boolean;
  };
  onUpdate?: Function;
}

export function CreateNewPlan({ editPlan, onUpdate }: IPropsCreateNewPlan) {
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm();
  //@ts-ignore
  const [valuePlan, setValuePlan] = useState('290.00');
  const [funcionalizes, setFuncionalizes] = useState([
    {
      label: 'Acesso total a todas funcionalidades*',
      disabled: false,
    },
    {
      label: 'Suporte via Whatsapp e e-mail',
      disabled: false,
    },
    {
      label: 'Possibilidade de assinar a funcionalidade do IR.',
      disabled: false,
    },
    {
      label: 'Renovação automática! Cancela quando quiser',
      disabled: false,
    },
    {
      label: 'funcionalidade do IR inclusa!',
      disabled: true,
    },
    {
      label: '50% de desconto na funcionalidade IR!',
      disabled: true,
    },
  ]);

  useEffect(() => {
    if (editPlan?.isEdit) {
      setValue('id', editPlan.plan.id);
      setValue('title', editPlan.plan.title);
      setValuePlan(editPlan.plan.value.toString());
      setValue('type', editPlan.plan.type);
      setValue('discount', editPlan.plan.discount);
      setValue('information', editPlan.plan.information);
      setValue('days', editPlan.plan.days);
      setValue('irpf', editPlan.plan.irpf);
      if (editPlan.plan.manageUrl) {
        setValue(
          'manageUrl',
          editPlan.plan.manageUrl.replace('www.pay.hotmart.com/', '')
        );
      }
      setFuncionalizes(editPlan.plan.funcionalities);
    }
  }, []);

  const plan: IPropsPlan = {
    id: watch('id'),
    title: watch('title'),
    value: Number(
      valuePlan
        ? valuePlan.replace(/\D/g, '').replace(/(\d)(\d{2})$/, '$1.$2')
        : 0.0
    ),
    type: watch('type'),
    discount: watch('discount'),
    information: watch('information'),
    days: watch('days'),
    manageUrl: `www.pay.hotmart.com/${watch('manageUrl')}`,
    is_active: true,
    irpf: watch('irpf'),

    funcionalities: funcionalizes,
  };

  async function submitCreatePlan(data: IPropsPlan) {
    data = plan;
    try {
      if (editPlan?.isEdit) {
        const response = await axios.put(
          `${baseURL}/api/planosV3/${editPlan.plan.id}/`,
          data
        );
        handleToast(false, 'Plano atualizado com sucesso');
        if (onUpdate) {
          onUpdate(response.data);
        }
      } else {
        const response = await axios.post(
          `${baseURL}${userRoutes.PLAN_ROUTER_V3}`,
          data
        );

        handleToast(false, 'Plano cadastrado com sucesso');
      }
    } catch (err) {
      // @ts-ignore
      handleToast(true, 'Erro ao cadastrar plano');
    }
  }

  function handleChangeTitleFuncionalize({
    value,
    index,
  }: {
    value: string;
    index: number;
  }) {
    const newFuncionalize = funcionalizes.map((func, i) => {
      if (i === index) {
        return {
          label: value,
          disabled: func.disabled,
        };
      }
      return func;
    });
    setFuncionalizes(newFuncionalize);
  }
  function handleChangeDisabledFuncionalize({
    value,
    index,
  }: {
    value: boolean;
    index: number;
  }) {
    const newFuncionalize = funcionalizes.map((func, i) => {
      if (i === index) {
        return {
          label: func.label,
          disabled: value,
        };
      }
      return func;
    });
    setFuncionalizes(newFuncionalize);
  }

  return (
    <AdminPlansStyles>
      {/* @ts-ignore */}
      <ContentFormCreatePlan onSubmit={handleSubmit(submitCreatePlan)}>
        <Heading as="h6" variant="sextiary">
          Cadastrar plano
        </Heading>
        <ContentInput>
          <label htmlFor="">
            <input type="checkbox" {...register('irpf')} /> Incluir IRPF
          </label>
        </ContentInput>
        <ContentInput>
          <label>Nome do plano</label>
          <InputComponent
            placeholder="iBoxer Anual"
            preFix={<span className="prefix">iBoxer </span>}
            defaultValue="Anual"
            {...register('title')}
          />
        </ContentInput>
        <ContentInput>
          <label>Valor do plano</label>
          <InputComponent
            preFix={<span className="prefix">R$ </span>}
            value={valuePlan}
            onChange={(e: any) => {
              let value = e.currentTarget.value;
              value = value.replace(/\D/g, '');
              value = value.replace(/(\d)(\d{2})$/, '$1,$2');
              value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');
              setValuePlan(value);
            }}
          />
        </ContentInput>
        <ContentRowInput>
          <ContentInput>
            <label>Desconto</label>
            <InputComponent
              suffix={<span className="prefix">%</span>}
              value={plan.discount}
              defaultValue={0}
              {...register('discount')}
              type="number"
            />
          </ContentInput>
          <ContentInput>
            <label>Dias</label>
            <InputComponent
              value={plan.days}
              defaultValue={30}
              {...register('days')}
              type="number"
            />
          </ContentInput>
        </ContentRowInput>
        <ContentInput>
          <label>Informação do plano</label>
          <InputComponent
            // @ts-ignore
            value={plan.information}
            {...register('information')}
            defaultValue={'Cancele quando quiser'}
          />
        </ContentInput>

        <ContentInput>
          <label>Link do plano</label>
          <InputComponent
            preFix={<span className="prefix">pay.hotmart.com/</span>}
            defaultValue="R85734558A"
            {...register('manageUrl')}
          />
        </ContentInput>
        <ContentInput>
          <label>Tipo de plano</label>
          <select defaultValue="Anual" {...register('type')}>
            <option value="Anual">Anual</option>
            <option value="Mensal">Mensal</option>
          </select>
        </ContentInput>
        <ContentInput>
          <label>Código da oferta</label>
          <InputComponent {...register('id')} defaultValue="jm7z6ors" />
        </ContentInput>

        {/* <ContentInput>
          <label>Descrição</label>
          <textarea
            placeholder="Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora explicabo laudantium numquam, veritatis nostrum quia deserunt ratione maiores. Tempore suscipit corporis nisi ullam officia accusamus hic, ipsa est veritatis quibusdam.
"
          />
        </ContentInput> */}
        <label>Funcionalidades</label>
        {funcionalizes.map((funcionalize, index) => (
          <ContentInput
            style={{ flexDirection: 'row', alignItems: 'center' }}
            key={index}
          >
            <InputComponent
              value={funcionalize.label}
              onChange={(e: any) =>
                handleChangeTitleFuncionalize({
                  value: e.target.value,
                  index,
                })
              }
            />

            <div style={{ width: '100%', maxWidth: '7rem' }}>
              <Switch
                checked={!funcionalize.disabled}
                onClick={() =>
                  handleChangeDisabledFuncionalize({
                    value: !funcionalize.disabled,
                    index,
                  })
                }
                checkedChildren="Desabilitar"
                unCheckedChildren="Habilitar "
              />
            </div>
          </ContentInput>
        ))}
        <ButtonDesign
          type="button"
          onClick={() =>
            setFuncionalizes((state) => {
              return [
                ...state,
                {
                  disabled: false,
                  label: 'Nova funcionalidade',
                },
              ];
            })
          }
          size="btn-small"
        >
          Adicionar funcionalidade
        </ButtonDesign>
        <ButtonDesign type="submit" disabled={isSubmitting}>
          {editPlan?.isEdit ? 'Atualizar plano' : 'Criar novo plano'}
        </ButtonDesign>
      </ContentFormCreatePlan>

      <CardPlan plan={plan} />
    </AdminPlansStyles>
  );
}

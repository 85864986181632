import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .contentB3Card {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const CardBase = styled.div`
  background: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme['card-shadow-2']};
  padding: 1rem;
  border-radius: 4px;
`;

export const CardInfo = styled(CardBase)`
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    margin: 0;
    color: ${(props) => props.theme['gray-700']};
    font-size: 1rem;
  }
`;

export const CardFormAndTable = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme['card-shadow-2']};
  padding: 1rem;
  border-radius: 4px;
  .rowContentInput {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    flex-wrap: wrap;

    label {
      display: flex;
      flex-direction: column;
      margin: 0;
      font-size: 1rem;
      color: ${(props) => props.theme['gray-700']};
      position: relative;
      input {
        border: ${(props) => props.theme.border};
        padding: 0.5rem;
        padding-left: 2.1rem;
        font-size: 1rem;
        color: ${(props) => props.theme['gray-700']};
        border-radius: 4px;
      }
      span {
        position: absolute;
        bottom: 0.6rem;
        font-size: 1rem;
        left: 0.25rem;
      }
    }
  }

  .inputEditContent {
    input {
      border: none;
      border-bottom: ${(props) => props.theme.border};
      background: transparent;
    }
    span {
      left: 0;
    }
  }
`;

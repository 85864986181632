import { useState } from 'react';

import { ButtonComponent } from '../../../../../../../components/ButtonComponent';
import { IPropsPlans } from '../@types';
import { Container, ContentButton } from './styles';
import { transformMaskCurrency } from '../../../../../../../utils/transformMaskCurrency';

import { FormRegisterPlan } from './components/FormRegisterPlan';
import { TrialMessage } from './components/TrialMessage';
import { MonthlyMessage } from './components/MonthlyMessage';
import { ModalDesigner } from '../../../../../../../components/ModalDesigner';
import { useTitleAndUser } from '../../../../../../../hooks/userTitle';

interface IPropsCardPlan {
  plan: IPropsPlans;
  updatePlan: () => void;
}

const incomeTaxBenefits = [
  'Integração com a B3',
  'Apuração do imposto de renda',
  'Relatórios de auxílio na revisão das transações',
  'Posição da carteira',
  'Auxílio na geração de DARF',
  'Relatório de suporte à declaração anual',
  'Inclusão da custódia inicial',

  'Demonstrativos de dividendos',
];

export function CardPlan({ plan, updatePlan }: IPropsCardPlan) {
  //@ts-ignore
  const { permissions } = useTitleAndUser();
  const [modalSelectedPlan, setModalSelectedPlan] = useState<boolean>(false);

  const isTrial = permissions.includes('Trial');
  const isMonthly = permissions.includes('Mensal');

  function formatValueForCurrency(value: number): string {
    return transformMaskCurrency(value);
  }

  return (
    <Container>
      <h3>{plan.name}</h3>
      <h1>
        {formatValueForCurrency(plan.amount)}{' '}
        {plan.name === 'Plano Anual - 12 Parcelas' && <span>x12</span>}
      </h1>
      <ul>
        {incomeTaxBenefits.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
      <ContentButton>
        <ButtonComponent
          type="button"
          onClick={() => setModalSelectedPlan(true)}
        >
          Eu quero!
        </ButtonComponent>
      </ContentButton>

      <ModalDesigner
        isOpen={modalSelectedPlan}
        setState={setModalSelectedPlan}
        id="selectedPlan"
        title={
          isTrial
            ? 'Ops! Sua conta está com a versão gratuita'
            : isMonthly
            ? 'Ops! Sua assinatura não é anual'
            : 'Forma de pagamento'
        }
        size="lg"
      >
        {isTrial ? (
          <TrialMessage />
        ) : isMonthly ? (
          <MonthlyMessage />
        ) : (
          <FormRegisterPlan
            planId={plan.id}
            setModalSelectedPlan={setModalSelectedPlan}
            updatePlan={updatePlan}
          />
        )}
      </ModalDesigner>
    </Container>
  );
}

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { IPropsResponseFeedback } from '../..';
import { Star } from '@phosphor-icons/react';
import moment from 'moment';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontFamily: 'Poppins, sans-serif',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: 'Poppins, sans-serif',
  },
}));

const StyledBody = styled(TableBody)(
  ({ theme }) => `
  overflow-y: auto;
  max-height:500px;

`
);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const header = [
  'Data da resposta',
  'User',
  'Telefone',
  'Pergunta',
  'Tipo',
  'Resposta',
];
const stars = [1, 2, 3, 4, 5];

interface IPropsTableResponses {
  data: IPropsResponseFeedback[];
}

export function TableResponses({ data }: IPropsTableResponses) {
  return (
    <TableContainer component={Paper} sx={{ maxHeight: 700 }}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {header.map((item) => (
              <StyledTableCell key={item}>{item}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <StyledBody>
          {data?.map((item) => (
            <StyledTableRow key={item.id}>
              <StyledTableCell>
                {moment(item.question.pub_date).format('L')}
              </StyledTableCell>
              <StyledTableCell>
                {item.user.Name}
                <br />
                <strong>{item.user.email}</strong>
              </StyledTableCell>
              <StyledTableCell>
                ({item.user.ddd}) {item.user.telefone}
              </StyledTableCell>
              <StyledTableCell>{item.question.question_text}</StyledTableCell>
              <StyledTableCell>
                {item.question.question_type === 'rating'
                  ? 'Estrelas'
                  : 'Escrita'}{' '}
              </StyledTableCell>
              <StyledTableCell>
                {item.question.question_type === 'rating'
                  ? stars.map((star) => (
                      <Star
                        //@ts-ignore
                        weight={
                          item?.rating_response && star <= item.rating_response
                            ? 'fill'
                            : 'regular'
                        }
                        color="#F8B700"
                      />
                    ))
                  : item.text_response}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </StyledBody>
      </Table>
    </TableContainer>
  );
}

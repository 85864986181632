import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { ButtonComponent } from '../../../../../../../../../../components/ButtonComponent';
import { useTitleAndUser } from '../../../../../../../../../../hooks/userTitle';
import { checkoutImg } from '../../../../../../../../../../Constants/images';
import { FinishedPlanContainer } from './styles';

interface IPropsFinishedPlan {
  finished: () => void;
}

export function FinishedPlan({ finished }: IPropsFinishedPlan) {
  // @ts-ignore
  const { setTitle } = useTitleAndUser();
  const history = useHistory();

  useEffect(() => {
    setTitle('Pagamento em processamento');
  }, []);
  return (
    <FinishedPlanContainer>
      <img src={checkoutImg} alt="" />
      <h1>Pagamento em processamento</h1>
      <p>
        Você receberá um e-mail com informações atualizadas sobre o status do
        seu pagamento. Para acompanhar o andamento do seu pedido,{' '}
        <button onClick={finished}>clique aqui.</button>
      </p>{' '}
      <ButtonComponent onClick={() => history.push('/')}>
        Voltar a Página inicial
      </ButtonComponent>
    </FinishedPlanContainer>
  );
}

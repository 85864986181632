import styled from 'styled-components';

export const AsideFiltersProventosRecebidosContainer = styled.div`
  display: flex;
  border-radius: 8px;
  width: 100%;
  max-width: 1416px;
  background: #fff;
  height: 132px;
  padding: 16px 24px;
  border: 1px solid var(--Gray-gray-100, #D4D6DB);
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  margin-top: 2%;
  margin-left: 2%; //Margin GBL
  position: absolute;
  z-index: 2;
  .filtros{
    margin-left: 0%;
    margin-top: -1%;
    display: flex;
    height: 56px;
    padding: 4px 4px 4px 12px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    gap: 16px;
    border-radius: var(--Spacing-8, 8px);
    border: 1px solid transparent;
    background: transparent;
    span {
      margin-left: -25%;
      margin-top: -10%;
      color: var(--Gray-gray-800, #40444C);

      /* Body/Large/SemiBold */
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 27px */
      width: 100%;
  }
  }

`;

export const ContentInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1%;

  label {
    margin: 0;
    font-weight: 400;
    font-size: 0.875rem;
  }
  .deLabel{
    margin-left: -33%;
    margin-top: 16%;
    color: #6A717E;

    /* Body/XSmall/Regular */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
  }
  .inputDate {
    margin-top: -5%;
    margin-left: -40%;
    display: flex;
    height: 56px;
    width: 180px;
    gap: 24px;
    padding: 4px 4px 4px 12px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    border: none;
    border-bottom: 1px solid #6A717E;
    background: transparent;
    
    font-weight: 400;
    font-size: 1rem;
    
  }

  .radioContent {
    margin-left: -65%;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
  }
  .radioContentLabel{
    margin-left: -65%;
    margin-top: 25%;
    flex-direction: row;
    color: #6A717E;

    /* Body/XSmall/Regular */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    white-space: nowrap;
  }

  .inputRadio {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
  }

  .tipoLabel{
    margin-top: 15%;
    margin-left: -27%;
    color: #6A717E;

    /* Body/XSmall/Regular */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
  }
  .selectTipo{
    margin-top: -15%;
    margin-left: -35%;
    display: flex;
    height: 56px;
    width: 180px;
    padding: 12px 4px 0px 4px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;

    border-bottom: 1px solid #6A717E;
    background: transparent;
    white-space: nowrap;
  }

  .ativoLabel{
    margin-top: 15%;
    margin-left: -20%;
    color: #6A717E;

    /* Body/XSmall/Regular */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
  }
  .selectAtivo{
    margin-top: -15%;
    margin-left: -28%;
    display: flex;
    height: 56px;
    width: 180px;
    padding: 12px 4px 0px 4px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;

    border-bottom: 1px solid #6A717E;
    background: transparent;
    white-space: nowrap;
  }

  .corretoraLabel{
    margin-top: 15%;
    margin-left: -15%;
    color: #6A717E;

    /* Body/XSmall/Regular */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    white-space: nowrap;
  }
  .selectCorretora{
    margin-top: -15%;
    margin-left: -20%;
    display: flex;
    height: 56px;
    width: 185px;
    padding: 12px 0px 0px 0px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;

    border-bottom: 1px solid #6A717E;
    background: transparent;
    white-space: nowrap;
  }
  
  .proventosLabel{
    margin-top: 15%;
    margin-left: 2%;
    color: #6A717E;

    /* Body/XSmall/Regular */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    white-space: nowrap;
  }
  .selectProventos{
    margin-top: -15%;
    margin-left: -10%;
    display: flex;
    height: 56px;
    width: 180px;
    padding: 12px 4px 0px 12px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;

    border-bottom: 1px solid #6A717E;
    background: transparent;
    white-space: nowrap;
  }
`;

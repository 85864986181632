import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import ReactDOM from 'react-dom';
import SimpleReactLightbox from 'simple-react-lightbox';
import { ClearCacheProvider } from 'react-clear-cache';
import App from './App';
import store from './store/store';
import reportWebVitals from './reportWebVitals';
import ThemeContext from './context/ThemeContext';
import { useInterceptor } from './services/useInterceptor';
import { defaultTheme } from './styles/themes/defaultTheme';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
  <React.StrictMode>
    <ClearCacheProvider duration={5000}>
      <Provider store={store}>
        <SimpleReactLightbox>
          <BrowserRouter basename="/">
            <ThemeProvider theme={defaultTheme}>
              <ThemeContext>
                <App />
              </ThemeContext>
            </ThemeProvider>
          </BrowserRouter>
        </SimpleReactLightbox>
      </Provider>
    </ClearCacheProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
useInterceptor(store);
// serviceWorkerRegistration.register();

reportWebVitals();

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
  border-radius: 4px;
  padding: 1rem;
  max-width: 400px;
  box-shadow: ${(props) => props.theme['card-shadow-2']};
  width: 100%;

  header {
    display: flex;
    h1 {
      color: #1C2D38;
      font: 700 1.2rem 'Poppins', sans-serif;
      margin: 0;
    }
    margin-bottom: 1rem;
  }

  main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;

    img {
      width: 8rem;
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      li {
        display: flex;
        flex-direction: row;
        gap: 5.5px;
        align-items: center;
        font-size: 0.8rem;
      }
    }

    .buttonContent {
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IPropsQuestionsFeedback } from '../../../../../../utils/@types/questionsFeedback';
import { Switch } from 'antd';
import axios from 'axios';
import { administrationController } from '../../../../../../utils/RoutesApi';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontFamily: 'Poppins, sans-serif',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: 'Poppins, sans-serif',
  },
}));

const StyledBody = styled(TableBody)(
  ({ theme }) => `
  overflow-y: auto;
  max-height:500px;

`
);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const header = ['Campo', 'Tipo', 'Status'];

interface IPropsTableQuestions {
  data: IPropsQuestionsFeedback[];
  onAttStatusQuestion: (questionId: number) => void;
}

export function TableQuestions({
  data,
  onAttStatusQuestion,
}: IPropsTableQuestions) {
  async function handleAttStatusQuestion(
    questionId: number,
    activated: boolean
  ) {
    await axios
      .patch(
        `${process.env.REACT_APP_API_URL}${administrationController.QUESTIONS_FEEDBACK}/${questionId}/`,
        {
          active: activated,
        }
      )
      .then((response) => {
        onAttStatusQuestion(questionId);
      })
      .catch((err) => {});
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {header.map((item) => (
              <StyledTableCell key={item}>{item}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <StyledBody>
          {data?.map((item) => (
            <StyledTableRow key={item.id}>
              <StyledTableCell>{item.question_text}</StyledTableCell>
              <StyledTableCell>
                {item.question_type === 'rating' ? 'Estrelas' : 'Escrita'}{' '}
              </StyledTableCell>
              <StyledTableCell>
                <Switch
                  checked={item.active}
                  onClick={() => handleAttStatusQuestion(item.id, !item.active)}
                  checkedChildren="ON"
                  unCheckedChildren="OFF"
                />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </StyledBody>
      </Table>
    </TableContainer>
  );
}

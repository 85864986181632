//@ts-ignore
import ReactDom from 'react-dom';
import { ReactNode } from 'react';
import { Close, Content, Header, ModalStyled, OutModalStyled } from './styles';
import { Heading } from '../DefaultStylesTypography';

interface IPropsModalDesigner {
  id: string;
  children: ReactNode;
  setState: Function;
  isOpen: boolean;
  title?: string;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  fullSize?: boolean;
}

export function SideModal({
  children,
  id,
  setState,
  isOpen,
  title = '',
  size = 'lg',
  fullSize = false,
}: IPropsModalDesigner) {
  //@ts-ignore
  function handleOutSideClick(e) {
    if (e.target.id === id) setState(false);
  }

  return ReactDom.createPortal(
    <>
      <OutModalStyled id={id} onClick={handleOutSideClick} isShow={isOpen}>
        <ModalStyled size={size} fullSize={fullSize}>
          <Header>
            <Heading as="h4" variant="quaternary">
              {title}
            </Heading>
            <Close onClick={() => setState(false)} />
          </Header>
          <Content>{children}</Content>
        </ModalStyled>
      </OutModalStyled>
    </>,

    // @ts-ignore
    document.querySelector('.portalModalDiv')
  );
}

import { Check, MinusCircle } from '@phosphor-icons/react';
import {
  ButtonSelect,
  CardPlanContainer,
  FunctionalityContent,
  HeaderCard,
  InformationText,
  ListFunctionalitiesContent,
  PriceContent,
  TypePlan,
} from './styles';
import { transformMaskCurrency } from '../../../../../../../utils/transformMaskCurrency';

export interface IPropsPlan {
  id: string;
  title: string;
  value: number;
  type: 'Anual' | 'Mensal' | 'Free';
  information: string | null;
  is_active: boolean;
  discount: string;
  days: string;
  manageUrl: string;
  irpf: boolean;
  funcionalities: Array<{
    label: string;
    disabled: boolean;
  }>;
}

interface IPropsCardPlan {
  plan: IPropsPlan;
}

export function CardPlan({ plan }: IPropsCardPlan) {
  const valueYear = plan.value * 12;
  const valueMonth = plan.value / 12;
  return (
    <CardPlanContainer typePlan={plan.type}>
      <TypePlan typePlan={plan.type}>
        <span>{plan.type}</span>
      </TypePlan>
      <HeaderCard>
        <h3>
          iBoxer <span>{plan.title}</span>
        </h3>
        <PriceContent>
          <h4>
            {transformMaskCurrency(
              plan.type !== 'Mensal' ? valueMonth : plan.value
            )}
            <span>/mês</span>
          </h4>
          <p>
            {plan.type === 'Free'
              ? 'Esse plano é 100% gratuito '
              : plan.type !== 'Mensal'
              ? `Escolhendo esse plano você paga ${transformMaskCurrency(
                  plan.value
                )} à vista`
              : `Escolhendo esse plano você paga ${transformMaskCurrency(
                  valueYear
                )} ao ano `}
          </p>
        </PriceContent>
        {plan.type !== 'Free' ? (
          <InformationText>
            <h2>{plan.information}</h2>
          </InformationText>
        ) : (
          <InformationText>
            <h2>APÓS 30 DIAS</h2>
          </InformationText>
        )}
      </HeaderCard>
      <ListFunctionalitiesContent
        style={plan.type === 'Free' ? { marginTop: '1rem' } : {}}
      >
        {plan.funcionalities.map((func) => (
          <li key={func.label}>
            <FunctionalityContent disabled={func.disabled}>
              {func.disabled ? (
                <MinusCircle weight="bold" />
              ) : (
                <Check weight="bold" />
              )}
              <strong>{func.label}</strong>
            </FunctionalityContent>
          </li>
        ))}
      </ListFunctionalitiesContent>
      <ButtonSelect currentPlan={false}>Quero ser {plan.title}</ButtonSelect>
    </CardPlanContainer>
  );
}

import { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { GraphicContainer } from './styles';
import { ModalDesigner } from '../../../../../components/ModalDesigner';

interface IPropsOthers {
  Exception: boolean;
  ValorExp: number;
  aportarQtde: number;
  closingPrice: number;
  corporationName: string;
  equitiesQuantity: number;
  novoupdateValue: number;
  percent: number;
  tickerSymbol: string;
  updateValue: number;
}

interface IPropsTotalPercent {
  action: number;
  fii: number;
  rendaFixa: number;
}

interface IPropsGraphic {
  active: 'action' | 'fii' | 'all' | 'broker';
  title: string;
  othersAction?: IPropsOthers[];
  othersFii?: IPropsOthers[];
  totalPercent?: IPropsTotalPercent;
}

export function GraphicWalletActives({
  active,
  title,
  othersAction,
  othersFii,
  totalPercent,
}: IPropsGraphic) {
  const [showModalGraphic, setShowModalGraphic] = useState<boolean>(false);

  let compare = {
    series:
      active === 'action'
        ? othersAction?.map((item) => item.percent)
        : othersFii?.map((item) => item.percent) ?? [0],
    options: {
      chart: {
        type: 'pie',
      },
      legend: {
        position: 'bottom',
      },
      dataLabels: {
        style: {
          colors: ['#fff'],
        },
      },
      colors: [
        '#2469A8',
        '#C61722',
        '#F8B700',
        '#14c91d',
        '#1433cc',
        '#6413cf',
        '#13cf71',
        '#d47a13',
        '#4612c9',
        '#12c3c9',
        '#9d16c9',
      ],
      labels:
        active === 'action'
          ? othersAction?.map((item) => item.tickerSymbol)
          : othersFii?.map((item) => item.tickerSymbol) ?? ['Ações'],
    },
  };
  let totalSettings = {
    series: [totalPercent?.action, totalPercent?.fii, totalPercent?.rendaFixa],

    options: {
      chart: {
        type: 'pie',
      },
      legend: {
        position: 'bottom',
      },
      dataLabels: {
        style: {
          colors: ['#fff'],
        },
      },
      colors: [
        '#2469A8',
        '#C61722',
        '#F8B700',
        '#14c91d',
        '#1433cc',
        '#6413cf',
        '#13cf71',
        '#d47a13',
        '#4612c9',
        '#12c3c9',
        '#9d16c9',
      ],
      labels: ['Ações', 'Fiis', 'Renda Fixa'],
    },
  };

  return (
    <>
      <GraphicContainer onClick={() => setShowModalGraphic(true)}>
        {/* @ts-ignore */}
        <ReactApexChart
          //@ts-ignore
          options={totalPercent ? totalSettings.options : compare.options}
          series={totalPercent ? totalSettings.series : compare.series}
          type="pie"
          height="270px"
        />
      </GraphicContainer>
      <ModalDesigner
        isOpen={showModalGraphic}
        setState={setShowModalGraphic}
        id="distribuicao"
        title="Distribuição por ativos"
        size="lg"
      >
        {/* @ts-ignore */}
        <ReactApexChart
          //@ts-ignore
          options={totalPercent ? totalSettings.options : compare.options}
          series={totalPercent ? totalSettings.series : compare.series}
          type="pie"
        />
      </ModalDesigner>
     
    </>
  );
}

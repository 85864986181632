import { IPropsContract } from '../@types/ContractProps';

export function FormatLabelContract(contract: IPropsContract) {
  if (contract.periodo === 'AteTresMeses') {
    contract.periodo = '0 a 3 meses';

    return contract;
  } else if (contract.periodo === 'TresAteSeisMeses') {
    contract.periodo = '3 a 6 meses';
    return contract;
  } else if (contract.periodo === 'SeisAteNoveMeses') {
    contract.periodo = '6 a 9 meses';
    return contract;
  } else if (contract.periodo === 'NoveAteDozeMeses') {
    contract.periodo = '9 a 12 meses';

    return contract;
  } else if (contract.periodo === 'DozeAteQuinzeMeses') {
    contract.periodo = '12 a 15 meses';

    return contract;
  } else if (contract.periodo === 'QuinzeAteDezoitoMeses') {
    contract.periodo = '15 a 18 meses';

    return contract;
  } else if (contract.periodo === 'DezoitoAteVinteUmMeses') {
    contract.periodo = '18 a 21 meses';

    return contract;
  } else if (contract.periodo === 'VinteUmAteVinteQuatroMeses') {
    contract.periodo = '21 a 24 meses';

    return contract;
  } else if (contract.periodo === 'VinteQuatroAteVinteSeteMeses') {
    contract.periodo = '24 a 27 meses';

    return contract;
  } else if (contract.periodo === 'VinteSeteAteTrintaMeses') {
    contract.periodo = '27 a 30 meses';

    return contract;
  } else if (contract.periodo === 'TrintaAteTrintaTresMeses') {
    contract.periodo = '30 a 33 meses';

    return contract;
  } else if (contract.periodo === 'TrintaTresAteTrintaSeisMeses') {
    contract.periodo = '33 a 36 meses';

    return contract;
  } else if (contract.periodo === 'AcimaTrintaSeisMeses') {
    contract.periodo = 'acima de 36 meses';

    return contract;
  } else if (contract.periodo === 'PrazoIndeterminado') {
    contract.periodo = 'Prazo indefinido';
  }
  return contract;
}

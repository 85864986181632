import ReactApexChart from 'react-apexcharts';
import { Container, HeaderCardContent } from './styles';
import { ApexOptions } from 'apexcharts';

interface IPropsPortfolioBalanceSheetStatistics {
  percentage: {
    fii: number | 'NaN';
    action: number | 'NaN';
    rendaFixa: number | 'NaN';
  };
  strategyPercentage: {
    fii: number | 'NaN';
    action: number | 'NaN';
    rendaFixa: number | 'NaN';
  };
}

export function PortfolioBalanceSheetStatistics({
  percentage,
  strategyPercentage,
}: IPropsPortfolioBalanceSheetStatistics) {
  const graphicDataAction: {
    options: ApexOptions;
    series: ApexAxisChartSeries;
  } = {
    series: [
      {
        name: 'Carteira Atual',
        color: '#EC6643',
        data: [
          {
            x: '% Ações',
            y: percentage.action || 0,
            fillColor: '#FFA600',
          },
          {
            x: '% FIIs',
            y: percentage.fii || 0,
            fillColor: '#EC6643',
          },
          {
            x: '% Renda Fixa',
            y: percentage.rendaFixa || 0,
            fillColor: '#99538E',
          },
        ],
      },
      {
        name: 'Objetivo global',
        color: '#38486B',
        data: [
          {
            x: 'Objetvo',
            y: strategyPercentage.action,
            fillColor: '#38486B',
            columnWidthOffset: -15,
          },
          {
            x: 'Objetvo',
            y: strategyPercentage.fii,
            fillColor: '#38486B',
            columnWidthOffset: -15,
          },
          {
            x: 'Objetvo',
            y: strategyPercentage.rendaFixa,
            fillColor: '#38486B',
            columnWidthOffset: -15,
          },
        ],
      },
    ],
    options: {
      chart: {
        type: 'bar',
      },
      grid: {
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false, // Use true para barras horizontais, se necessário
          columnWidth: '60%',
          borderRadius: 5, // Ajuste o raio para bordas arredondadas
        },
      },
      // fill: { colors: ['#EC6643', '#2469a9', '#02CD53'] },
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        categories: ['% Ações', '% FIIs', '% Renda Fixa'], // Altere conforme os dados
      },
      labels: ['Ações', 'FIIs', 'Renda Fixa'],
    },
  };

  return (
    <Container>
      <HeaderCardContent>
        <h4>Carteira - Análise Geral</h4>
      </HeaderCardContent>
      <p>Distribuição da carteira</p>

      {/* @ts-ignore */}
      <ReactApexChart
        options={graphicDataAction.options}
        series={graphicDataAction.series}
        type="bar"
        width={'100%'}
        height={250}
      />
    </Container>
  );
}

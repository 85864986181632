import axios from 'axios';
import moment from 'moment';
import { Trash, User } from '@phosphor-icons/react';

import { useTitleAndUser } from '../../../hooks/userTitle';
import { IPropsComment } from '../../../utils/@types/comment';
import { socialRoutes } from '../../../utils/RoutesApi';

import { handleToast } from '../../Toast';
import { Container, ContentUser } from './styles';

interface IPropsResponseComment {
  deleteResponseComment: (id: string | number) => void;
  comment: IPropsComment;
  onLinkedResponseComment: (id: number) => void;
}

const baseURL = process.env.REACT_APP_API_URL;

export function NewResponseComment({
  comment,
  onLinkedResponseComment,
  deleteResponseComment,
}: IPropsResponseComment) {
  //@ts-ignore
  const { user } = useTitleAndUser();

  async function handleDeleteResponseCommentComment(id: number | string) {
    await axios
      .delete(`${baseURL}${socialRoutes.SOCIAL_CREATE_POST}${id}`)
      .then((response) => {
        handleToast(false, 'Resposta apagada');
        deleteResponseComment(id);
      })
      .catch((err) => {
        // setLoading(false);
        // handleToast(true, 'Erro ao publicar');
      });
    // setLoading(false);
  }

  return (
    <Container>
      <header>
        <ContentUser>
          <div className="contentAvatar">
            {comment.author.profile.image ? (
              <img src={comment.author.profile.image} />
            ) : (
              <User />
            )}
          </div>
          <div className="dataUser">
            <strong>
              {comment.author.Name.split(' ')[0]}{' '}
              {comment.author.Name.split(' ')[1]}
            </strong>
          </div>
        </ContentUser>
        <div>
          <span>{moment(comment.created_at).fromNow()}</span>
          {comment.author.Name === user.Name && (
            <>
              {' '}
              <Trash
                size={20}
                className="iconDelete"
                onClick={() => handleDeleteResponseCommentComment(comment.id)}
              />
            </>
          )}
        </div>
      </header>
      <p>{comment.body}</p>

      <label
        onClick={() => onLinkedResponseComment(comment.id)}
        style={{ margin: 0, cursor: 'pointer' }}
      >
        {comment.liked.length}{' '}
        {comment.liked.includes(user.id) ? 'Curtiu' : 'Curtir'}{' '}
      </label>
    </Container>
  );
}

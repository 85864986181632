import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  p {
    margin: 0;
    color: ${(props) => props.theme.primary};
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 0.2rem;
    cursor: pointer;
    transition: filter 0.3s;
    :hover {
      filter: brightness(0.9);
    }
  }
`;

export const CardIndex = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 2px solid ${(props) => props.theme.success};
  padding-left: 0.5rem;
  max-width: 300px;
  width: 100%;

  h5 {
    display: flex;
    gap: 0.25rem;
    align-items: center;

    .divider {
      margin: 0;
      height: 100%;
      width: 2px;
      background: rgba(21, 21, 21, 0.3);
    }
  }
  .contentCard {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;

    span {
      color: ${(props) => props.theme['gray-700']};
    }
    strong {
      color: ${(props) => props.theme['gray-700']};
    }
  }
`;

export const ContentIndexesCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const RowContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 1rem;
  /* align-items: center; */

  /* justify-content: center; */
`;

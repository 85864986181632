import { Carousel } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useActives } from '../../../../../hooks/useActives';
import { useNewTheme } from '../../../../../hooks/useNewTheme';
import { financeRoutes } from '../../../../../utils/RoutesApi';
import { CardHomeData } from '../../../CardHomeData';
import { LoadingCard } from './LoadingCard';
import { Container, ContentCards } from './styles';
import styles from './styles.module.css';
import { useTitleAndUser } from '../../../../../hooks/userTitle';
import { baseURL } from '../../../../../utils/baseURL';
import image1 from '../../../../../assets/home/patrimonio.svg'; 
import image2 from '../../../../../assets/home/CurrencyDollar.svg'; 
import image3 from '../../../../../assets/home/PiggyBank.svg'; 
import image4 from '../../../../../assets/home/Calendar.svg'; 
import image5 from '../../../../../assets/home/CalendarDot.svg'; 
import image6 from '../../../../../assets/home/ClockCounterClockwise.svg';

interface IPropsLoadingData {
  earningsReceived: boolean;
  nextEarningsReceived: boolean;
  profitability: boolean;
}

export function DataHomeResume() {
  //@ts-ignore
  const { permissions } = useTitleAndUser();
  const isBlocked =
    permissions.includes('Grátis') && !permissions.includes('Admin');
  const isAdmin = permissions.includes('Admin');

  const history = useHistory();
  //@ts-ignore
  const { isMobile } = useNewTheme();
  //@ts-ignore
  const { actives, loadingActive } = useActives();
  const [earningsReceived, setEarningsReceived] = useState(0);
  const [nextEarningsReceived, setNextEarningsReceived] = useState(0);
  const [dateLastInvestment, setDateLastInvested] = useState({
    diffDate: 0,
    lastInvestment: '',
  });
  const [loadingData, setLoadingData] = useState<IPropsLoadingData>({
    earningsReceived: false,
    nextEarningsReceived: false,
    profitability: false,
  });

  useEffect(() => {
    getNextEarningReceived();
    getEarningReceived();
    getLastProfit();
  }, []);

  async function getNextEarningReceived() {
    setLoadingData({
      ...loadingData,
      nextEarningsReceived: true,
    });
    await axios
      .get(`${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_PROCEEDS}`)
      .then((response) => {
        //@ts-ignore
        let reduceTotal = response.data.data.reduce(
          //@ts-ignore
          (acc, item) => item.total + acc,
          0
        );

        setNextEarningsReceived(reduceTotal);
      })
      .catch((err) => {});
    setLoadingData({ ...loadingData, nextEarningsReceived: false });
  }
  async function getEarningReceived() {
    setLoadingData({ ...loadingData, earningsReceived: true });
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_PROCEEDS_RECEIVED}`
      )
      .then((response) => {
        const totalReceived = response.data.data.total_periodos.reduce(
          //@ts-ignore
          (acc, item) => {
            return acc + item.total;
          },
          0
        );
        setEarningsReceived(totalReceived);
      })
      .catch((err) => {});
    setLoadingData({ ...loadingData, earningsReceived: false });
  }

  async function getLastProfit() {
    try {
      const response = await axios.get(
        `${baseURL}${financeRoutes.LAST_PROFIT}`
      );
      const diffDay = moment().diff(
        moment(response.data.ultimo_aporte_date),
        'days'
      );
      setDateLastInvested({
        diffDate: diffDay,
        lastInvestment: response.data.ultimo_aporte_date,
      });
    } catch (error) {}
  }

  //@ts-ignore
  const totalMattress = actives?.positionsAll.reduce((a, b) => {
    if (b.reservadeemergencia) {
      return a + b.updateValue;
    } else {
      return a;
    }
  }, 0);

  const totalInvestment =
    //@ts-ignore
    actives.sumAcao + actives.sumsEFT + actives.sumsFII + totalMattress;
  const totalInvestmentWithoutReserve =
    actives.sumAcao + actives.sumsEFT + actives.sumsFII;

  return (
    <Container>
      {isMobile ? (
        <Carousel
          arrows
          nextArrow={<CaretRight size={32} color="black" alt="Próximo slide" />}
          prevArrow={<CaretLeft size={32} color="black" alt="Slide anterior" />}
          autoplay
          className={styles.Container}
        >
          {loadingActive ? (
            <LoadingCard />
          ) : (
            <CardHomeData value={totalInvestment} title="Patrimônio total"  image={image1}/>
          )}
          {loadingActive ? (
            <LoadingCard />
          ) : (
            <CardHomeData
              value={totalInvestmentWithoutReserve}
              title="Carteira de investimentos"
              image={image2}
              onClick={() => {
                if (isBlocked) {
                  return;
                }
                history.push('wallet');
              }}
              style={
                !isAdmin && isBlocked
                  ? { cursor: 'not-allowed' }
                  : { cursor: 'pointer' }
              }
            />
          )}
          {loadingActive ? (
            <LoadingCard />
          ) : (
            <CardHomeData
              value={totalMattress}
              title="Reserva de emergência"
              image={image3}
              onClick={() => {
                if (isBlocked) {
                  return;
                }
                history.push('wallet');
              }}
              style={
                !isAdmin && isBlocked
                  ? { cursor: 'not-allowed' }
                  : { cursor: 'pointer' }
              }
            />
          )}
          {loadingActive ? (
            <LoadingCard />
          ) : (
            <CardHomeData
              // value={earningsReceived}
              title="Proventos recebidos - últimos 12 meses"
              image={image4}
              onClick={() => {
                if (isBlocked) {
                  return;
                }
                history.push('renda-passiva');
              }}
              style={
                !isAdmin && isBlocked
                  ? { cursor: 'not-allowed' }
                  : { cursor: 'pointer' }
              }
              value={earningsReceived}
            />
          )}
          {loadingActive ? (
            <LoadingCard />
          ) : (
            <CardHomeData
              value={nextEarningsReceived}
              title="Próximos proventos"
              image={image5}
              onClick={() => {
                if (isBlocked) {
                  return;
                }
                history.push('renda-passiva');
              }}
              style={
                !isAdmin && isBlocked
                  ? { cursor: 'not-allowed' }
                  : { cursor: 'pointer' }
              }
            />
          )}
          {loadingActive ? (
            <LoadingCard />
          ) : (
            <CardHomeData
              value={
                dateLastInvestment.diffDate === 1
                  ? `${dateLastInvestment.diffDate} dia`
                  : `${dateLastInvestment.diffDate} dias`
              }
              // bigger={profitability.bigger}
              diff={dateLastInvestment.diffDate}
              title="Último aporte"
              image={image6}
              isVideo
            />
          )}
        </Carousel>
      ) : (
        <ContentCards>
          {loadingActive ? (
            <LoadingCard />
          ) : (
            <CardHomeData value={totalInvestment} title="Patrimônio total" image={image1} />
          )}
          {loadingActive ? (
            <LoadingCard />
          ) : (
            <CardHomeData
              value={totalInvestmentWithoutReserve}
              title="Carteira de investimentos"
              image={image2}
              onClick={() => {
                if (isBlocked) {
                  return;
                }
                history.push('wallet');
              }}
              style={
                !isAdmin && isBlocked
                  ? { cursor: 'not-allowed' }
                  : { cursor: 'pointer' }
              }
            />
          )}
          {loadingActive ? (
            <LoadingCard />
          ) : (
            <CardHomeData
              value={totalMattress}
              title="Reserva de emergência"
              image={image3}
              onClick={() => {
                if (isBlocked) {
                  return;
                }
                history.push('wallet');
              }}
              style={
                !isAdmin && isBlocked
                  ? { cursor: 'not-allowed' }
                  : { cursor: 'pointer' }
              }
            />
          )}
          {loadingActive ? (
            <LoadingCard />
          ) : (
            <CardHomeData
              // value={earningsReceived}
              title="Proventos recebidos - últimos 12 meses"
              image={image4}
              onClick={() => {
                if (isBlocked) {
                  return;
                }
                history.push('renda-passiva');
              }}
              style={
                !isAdmin && isBlocked
                  ? { cursor: 'not-allowed' }
                  : { cursor: 'pointer' }
              }
              value={earningsReceived}
            />
          )}
          {loadingActive ? (
            <LoadingCard />
          ) : (
            <CardHomeData
              value={nextEarningsReceived}
              title="Próximos proventos"
              image={image5}
              onClick={() => {
                if (isBlocked) {
                  return;
                }
                history.push('renda-passiva');
              }}
              style={
                !isAdmin && isBlocked
                  ? { cursor: 'not-allowed' }
                  : { cursor: 'pointer' }
              }
            />
          )}
          {loadingActive ? (
            <LoadingCard />
          ) : (
            <CardHomeData
              value={
                dateLastInvestment.diffDate === 1
                  ? `${dateLastInvestment.diffDate} dia`
                  : `${dateLastInvestment.diffDate} dias`
              }
              // bigger={profitability.bigger}
              diff={dateLastInvestment.diffDate}
              title="Último aporte"
              image={image6}
              isVideo
            />
          )}
        </ContentCards>
      )}
    </Container>
  );
}

import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    gap: 1rem;

    input {
      border-radius: 4px;
      border: 1px solid rgba(21, 21, 21, 0.5);
      max-width: 60px;
      padding: 0 0.5rem;
    }
  }

  span {
    color: ${(props) => props.theme.white};
  }
  .slider {
    color: ${(props) => props.theme.primary};
    span {
      background: ${(props) => props.theme.primary};
    }
  }
`;

export const ContentButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  align-items: center;
  margin-top: 2rem;
`;

export const ContentInput = styled.div`
  display: flex;
  flex-direction: column;

  select {
    padding: 0.5rem;
    border-radius: 4px;
    border: ${(props) => props.theme.border};

    :disabled {
      background-color: rgba(21, 21, 21, 0.1);
      color: #4d4d4d;
    }
  }
  + div {
    margin-top: 0.5rem;
  }
`;

import { useHistory } from 'react-router-dom';
import { ButtonComponent } from '../../../components/ButtonComponent';
import { Container } from './styles';

export function RedirectTextPremiumPlan() {
  const history = useHistory();
  return (
    <Container>
      <p>
        Essa é uma funcionalidade premium. Para ter acesso, clique no botão
        abaixo e escolha um de nosso planos.
      </p>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '1rem',
        }}
      >
        <ButtonComponent
          onClick={() => history.push('pagamentos')}
          style={{ fontSize: '1.2rem', padding: '0.5rem 5rem' }}
        >
          VER PLANOS!
        </ButtonComponent>
      </div>
    </Container>
  );
}

import styled from 'styled-components';

export const HeaderCardAnalysisFiiMobileContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const ContentText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: start;

  button {
    all: unset;
    margin: 0;
    font-weight: 600;
    font-size: 1.25rem;
    color: ${(props) => props.theme['gray-600']};
  }
  span {
    font-weight: 500;
    font-size: 1rem;

    color: ${(props) => props.theme['gray-600']};
    max-width: 200px; /* Substitua 300px pelo valor desejado */
    overflow: hidden;
    text-overflow: ellipsis; /* Adiciona reticências (...) quando o texto ultrapassa a largura máxima */
    white-space: nowrap; /* Impede a quebra de linha */
  }
`;

export const Percent = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
`;

import styled from 'styled-components';

interface IPropsInputStyleContainer {
  isError?: boolean;
}

export const InputStyleContainer = styled.div<IPropsInputStyleContainer>`
  display: flex;
  flex-direction: column;
  padding: 0.25rem 1rem;
  align-items: flex-start;
  align-self: stretch;
  border-bottom: 1px solid ${(props) => (props.isError ? '#f94361' : '#6a717e')};
  border-radius: 0.25rem 0.25rem 0rem 0rem;
  position: relative;
  margin-bottom: ${(props) => (props.isError ? '.5rem' : 0)};
  label {
    color: ${(props) => (props.isError ? '#F94361' : '#6a717e')};
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
  }

  .errorMessage {
    color: #f94361;
    /* Body/XSmall/Regular */
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    position: absolute;
    bottom: -1.25rem;
    left: 0;
  }

  &:focus-within {
    border-bottom: 2px solid
      ${(props) => (props.isError ? '#F94361' : props.theme['orange-500'])};

    label {
      color: ${(props) =>
        props.isError
          ? '#F94361'
          : props.theme['orange-500']}; // Exemplo de alteração de cor do label
      font-weight: 400;
    }
  }
`;

export const InputStyleContent = styled.div`
  display: flex;
  gap: 0.5rem;
  width: 100%;

  input {
    all: unset;
    width: 100%;
    background-color: transparent;
    color: #9196a1;
    /* Body/Base/Regular */
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 1.5rem */
  }

  .suffix {
    margin-top: -0.5rem;
  }
`;

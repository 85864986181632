import styled from 'styled-components';

interface IPropsContainer {
  wallet: boolean;
}

export const Container = styled.div<IPropsContainer>`
  display: flex;
  flex-direction: column;

  position: relative;
  z-index: 1;

  .alice-carousel__dots {
    margin: 15px 3px 5px;
  }
  .alice-carousel__prev-btn,
  .alice-carousel__next-btn {
    padding: 0;
  }

  .link {
    cursor: pointer;
    span,
    strong {
      transition: color 0.3s;
    }

    :hover {
      strong,
      span {
        color: ${(props) => props.theme.primary};
      }
    }
  }

  .reduce {
    max-width: 15ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .modal-content {
    width: 550px !important;
  }

  .modal-dialog {
    max-width: 550px !important;
  }

  .footer-table {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 10px;
    border-top: ${(props) => props.theme.border};
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: flex-start;

  .columnTableDefined {
    border: none;
    background: transparent;
    color: ${(props) => props.theme.primary};
    font-size: 1rem;
    padding: 0.5rem 0 0 0.5rem;
    margin: 0;
  }

  @media (max-width: 910px) {
    flex-direction: column;
  }
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;
export const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Graphic = styled.div`
  background-color: ${(props) => props.theme.white};
  border-radius: 8px;
  padding: 10px;

  position: relative;
  z-index: 1;
`;

interface IPropsTab {
  tab: number;
}

export const ContentTab = styled.div<IPropsTab>`
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  border-radius: 4px;
  box-shadow: ${(props) => props.theme['card-shadow-2']};
  padding: 0.5rem;
  background: ${(props) => props.theme.white};
  margin-bottom: 1rem;
  gap: 1rem;
`;

export const ContentSlider = styled.div`
  display: flex;
  flex-direction: row;
  width: 800px;
`;
interface IPropsContainerBrokerage {
  show: boolean;
}

export const ContainerBrokerages = styled.div<IPropsContainerBrokerage>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12.5rem, 1fr));
  gap: 1rem;
  width: 100%;
  overflow-y: auto;
  max-height: 250px;

  margin-bottom: ${(props) => (props.show ? '1rem' : 0)};
  opacity: ${(props) => (props.show ? 1 : 0)};
  height: ${(props) => (props.show ? 'auto' : 0)};
  transition: all 1s;

  ::-webkit-scrollbar {
    width: 0;
    height: 0; /* width of the entire scrollbar */
  }
`;

import styled from 'styled-components';

export const AllActiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  overflow: hidden;
  padding-right: 0.25rem;
  touch-action: pan-y;
`;

export const ContentTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-weight: 400;
      font-size: 1rem;
      color: #303F4B;
    }
    .addColumnsButtons {
      all: unset;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      font-weight: 400;
      font-size: 0.875rem;
      color: #303F4B;
      cursor: pointer;
    }
    @media (max-width: 480px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;

export const ContentItemsFilterColumnTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .recommendText {
    font-weight: 400;
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
    color: #07bd12;
    background: #def7ec;
    width: 100%;
    max-width: 7.5rem;
    border-radius: 29px;
  }

  .addMore {
    font-size: 0.875rem;
    font-weight: 400;
    color: ${(props) => props.theme['gray-300']};
  }

  .contentInput {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    label {
      display: flex;
      gap: 0.5rem;
      font-size: 0.875rem;
      color: ${(props) => props.theme['gray-500']};
      margin: 0;
      font-weight: 400;
    }
  }

  .important {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
    color: ${(props) => props.theme['gray-500']};
    line-height: 100%;
  }

  .warning {
    font-weight: 400;
    font-size: 0.75rem;
    color: ${(props) => props.theme['white-800']};
    padding: 0.75rem;
    border: 1px solid ${(props) => props.theme['white-800']};
    border-radius: 12px;
    margin: 0;
  }
`;

export const FooterTable = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;

  p {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 400;
    color: ${(props) => props.theme['gray-600']};
  }
`;

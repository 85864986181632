import styled from 'styled-components';

interface IPropsUserContent {
  banner: boolean;
}

export const Container = styled.div<IPropsUserContent>`
  display: flex;
  width: 100%;
  background: #fff;

  border-radius: 8px;
  height: 12.216rem;
  position: relative;
  z-index: 1;
  padding: 1rem;

  .bannerDefault {
    position: absolute;
    right: 0;
    top: 0;
    width: 12rem;
    height: 12rem;
    z-index: -1;
  }

  .editBanner {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #EC6643;
    transition: all 0.3s;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 100%;
    padding: 0.3rem;
    z-index: 1;
    :hover {
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
    }
  }

  .follow {
    position: absolute;
    bottom: 5px;
    right: 5px;
    color: ${(props) =>
      props.banner ? props.theme.white : props.theme['text-body']};
    background: ${(props) =>
      props.banner ? `rgba(0,0,0,0.5)` : 'transparent'};
    border-radius: ${(props) => (props.banner ? '4px' : '0')};
    padding: ${(props) => (props.banner ? '0 0.5rem' : '0')};
  }

  @media (max-width: 480px) {
    margin-bottom: 8rem;
  }
`;

export const UserContainer = styled.div`
  display: flex;
  gap: 1.187rem;
  position: relative;

  .editProfile {
    position: absolute;
    bottom: 10px;
    left: 120px;
    color: #697d8c;
    transition: all 0.3s;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 100%;
    padding: 0.3rem;

    :hover {
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
    }
  }
  img {
    border-radius: 100%;
    width: 100vw;
    max-width: 10rem;
    height: 100vh;
    max-height: 10rem;
    object-fit: cover;
    background: ${(props) => props.theme.black};
  }
  .defaultUser {
    font-size: 6.25rem;
    width: 100%;
    max-width: 10rem;
    height: 10rem;
    border-radius: 100%;
    background: ${(props) => props.theme['gray-100']};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
  }

  @media (max-width: 480px) {
    display: grid;
    grid-template-columns: 1fr;
    img,
    .defaultUser {
      margin-bottom: 1rem;
    }
  }
`;

export const UserContent = styled.div<IPropsUserContent>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  main {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    h2 {
      font-size: 1.5rem;
      font-weight: 500;
      color: #303F4B;
      background: ${(props) =>
        props.banner ? `rgba(0,0,0,0.5)` : 'transparent'};
      border-radius: ${(props) => (props.banner ? '4px' : '0')};
      padding: ${(props) => (props.banner ? '0 0.5rem' : '0')};
      margin: 0;
    }
    span {
      font-size: 1rem;
      font-weight: 400;
      color: ${(props) =>
        props.banner ? props.theme.white : props.theme['text-body']};
      background: ${(props) =>
        props.banner ? `rgba(0,0,0,0.5)` : 'transparent'};
      border-radius: ${(props) => (props.banner ? '4px' : '0')};
      padding: ${(props) => (props.banner ? '0 0.5rem' : '0')};
    }
    a {
      font-size: 1rem;
      font-weight: 400;
      color: ${(props) =>
        props.banner ? props.theme.white : props.theme['text-body']};
      background: ${(props) =>
        props.banner ? `rgba(0,0,0,0.5)` : 'transparent'};
      border-radius: ${(props) => (props.banner ? '4px' : '0')};
      padding: ${(props) => (props.banner ? '0 0.5rem' : '0')};

      transition: filter 0.3s;

      :hover {
        filter: brightness(0.9);
      }
    }
  }

  time {
    font-size: 0.75rem;
    font-weight: 400;
    color: #303F4B;

    background: ${(props) =>
      props.banner ? `rgba(0,0,0,0.5)` : 'transparent'};
    border-radius: ${(props) => (props.banner ? '4px' : '0')};
    padding: ${(props) => (props.banner ? '0 0.5rem' : '0')};
  }

  @media (max-width: 480px) {
    gap: 0.5rem;
    main {
      width: 100%;
      h2,
      span,
      a {
        width: 100%;
        color: ${(props) => props.theme.white};
        background: rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        padding: 0 0.5rem;
      }
    }
    time {
      width: 100%;
      color: ${(props) => props.theme.white};
      background: rgba(0, 0, 0, 0.5);
      border-radius: 4px;
      padding: 0 0.5rem;
    }
  }
`;

export const BannerUser = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  right: 0;
  height: 12.216rem;
  object-fit: cover;
  border-radius: 8px;
  z-index: -1;
`;

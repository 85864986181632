import axios from 'axios';
import { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { financeRoutes } from '../../../../utils/RoutesApi';
import {
  AboutCompanyContainer,
  LoadingContent,
  TextAboutCompany,
} from './styles';
import { Markup } from 'interweave';

interface IPropsAboutCompany {
  slug: string;
}

export function AboutCompany({ slug }: IPropsAboutCompany) {
  const [showTextAbout, setShowTextAbout] = useState(true);
  const [textActive, setTextActive] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCompanyInfo();
  }, [slug]);

  async function getCompanyInfo() {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_GET_TEXT_ACTIVE}`,
        {
          params: {
            ticker: slug.substring(0, 4),
          },
        }
      )
      .then((response) => {
        setTextActive(response.data.message);
      })
      .catch((err) => {
      });
    setLoading(false);
  }

  return (
    <AboutCompanyContainer>
  
      <TextAboutCompany showText={showTextAbout}>
        {loading && showTextAbout ? (
          <LoadingContent>
            <ReactLoading
              type="balls"
              width={100}
             
              color="linear-gradient(#e66465, #9198e5)"
            />
            <strong>
              Estamos analisando a empresa para você, isso pode demorar alguns
              minutos...
            </strong>
          </LoadingContent>
        ) : (
          <Markup content={textActive} />
        )}
      </TextAboutCompany>
    </AboutCompanyContainer>
  );
}

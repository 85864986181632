import { Link } from 'react-router-dom';
import { MonthlyMessageContainer } from './styles';

export function MonthlyMessage() {
  return (
    <MonthlyMessageContainer>
      <p>
        Para assinar um plano do imposto de renda você precisa ter a assinatura
        anual da plataforma Ibox. <br />
        <Link to="pagamentos">
          Você pode trocar sua assinatura clicando aqui!
        </Link>
      </p>
    </MonthlyMessageContainer>
  );
}

import moment from 'moment';
import { ColContent, Container } from './styles';

import { transformMaskCurrency } from '../../../../../utils/transformMaskCurrency';
import { useTitleAndUser } from '../../../../../hooks/userTitle';
import { IPropsIncomeTax } from '../../@types';
import { brasaoImg } from '../../../../../Constants/images';

interface IPropsDarf {
  transaction: IPropsIncomeTax;
}

export function DarfGeneration({ transaction }: IPropsDarf) {
  //@ts-ignore
  const { user } = useTitleAndUser();

  const date = new Date(transaction?.ReferenceDate);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const data = {
    start: date,
    end: lastDay,
  };

  function formatValueForCurrency(value: number): string {
    return transformMaskCurrency(value);
  }

  return (
    <Container>
      <h1 className="molde">MODELO</h1>
      <ColContent>
        <div className="contentGov">
          <img src={brasaoImg} alt="Brasão do Brasil" />
          <div className="data">
            <h2>MINISTÉRIO DA FAZENDA</h2>
            <h6>SECRETARIA DA RECEITA FEDERAL DO BRASIL</h6>
            <strong>Documento de Arrecadação de Receitas Federais</strong>
            <h1>DARF</h1>
          </div>
        </div>
        <div className="userNameContent">
          <header>
            <h3>01</h3>
            <span>NOME/TELEFONE</span>
          </header>
          <div className="inputContent">
            <input type="text" defaultValue={user.Name} />
            <input
              type="text"
              defaultValue={`(${user.ddd}) ${user.telefone}`}
            />
          </div>
        </div>
        <div className="instructions">
          <br />
          <br />
          <br />
        </div>
        <div className="warning">
          <h3>ATENÇÃO</h3>
          <p>
            É vedado o recolhimento de tributos administrados pela Secretaria da
            Receita Federal do Brasil (RFB) cujo valor total seja inferior a R$
            10,00. Ocorrendo tal situação acione esse valor ao tributo de mesmo
            código de períodos subsequentes, até que o total seja igual ou
            superior a R$ 10,00.
          </p>
        </div>
      </ColContent>
      <ColContent>
        <div className="contentInfo">
          <p>
            <h1>02</h1>PERÍODO DE APURAÇÃO
          </p>
          <input type="text" defaultValue={moment(data.start).format('L')} />
        </div>
        <div className="contentInfo">
          <p>
            <h1>03</h1>NÚMERO DO CPF OU CNPJ
          </p>
          <input type="text" defaultValue={user.cpf} />
        </div>
        <div className="contentInfo">
          <p>
            <h1>04</h1>CÓDIGO DA RECEITA
          </p>
          <input type="text" defaultValue="6015" />
        </div>
        <div className="contentInfo">
          <p>
            <h1>05</h1>NÚMERO DE REFERÊNCIA
          </p>
          <input type="text" />
        </div>
        <div className="contentInfo">
          <p>
            <h1>06</h1>DATA DE VENCIMENTO
          </p>
          <input type="text" defaultValue={moment(data.end).format('L')} />
        </div>
        <div className="contentInfo">
          <p>
            <h1>07</h1>VALOR DO PRINCIPAL
          </p>
          <input type="text" />
        </div>
        <div className="contentInfo">
          <p>
            <h1>08</h1>VALOR DA MULTA
          </p>
          <input type="text" />
        </div>
        <div className="contentInfo">
          <p>
            <h1>09</h1>VALOR DOS JUROS E/OU ENCARGOS DL - 1.025/69
          </p>
          <input type="text" />
        </div>
        <div className="contentInfo">
          <p>
            <h1>10</h1>VALOR TOTAL
          </p>
          <input
            type="text"
            defaultValue={formatValueForCurrency(transaction.IRCalculado)}
          />
        </div>
        <div className="contentInfoFinish">
          <p>
            <h1>11</h1>AUTENTICAÇÃO BANCÁRIA (Somente nas 1ª e 2ª vias)
          </p>
          <input type="text" />
        </div>
      </ColContent>
    </Container>
  );
}

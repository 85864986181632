import axios from 'axios';
import { logout, refreshConfirmed } from '../store/actions/AuthActions';

export const useInterceptor = (store) => {
  const { dispatch } = store;

  //Add token in application
  axios.interceptors.request.use((config) => {
    const state = store.getState();
    const token = state.auth.auth.access;
    if (token && !config.headers.Authorization) {
      config.params = config.params || {};
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  });

  //Refresh Token logic
  axios.interceptors.response.use(undefined, (error) => {
    const status = error.response ? error.response.status : null;
    const baseUrlError = error.config.url || '';
    const urlRefreshToken = '/api/auth/refresh/';
    if (status === 401 && !baseUrlError.includes(urlRefreshToken)) {
      const refresh_token = localStorage.userDetails;
      if (refresh_token) {
        let token = JSON.parse(refresh_token);
        return axios
          .post(`${process.env.REACT_APP_API_URL}${urlRefreshToken}`, {
            refresh: token.refresh,
          })
          .then((res) => {
            let obj = {
              access: res.data.access,
              refresh: token.refresh,
              user: token.user,
              expireDate: token.expireDate,
            };
            localStorage.setItem(
              process.env.REACT_APP_USER_VERSION,
              JSON.stringify(obj)
            );
            dispatch(
              refreshConfirmed({
                access: res.data.access,
                refresh: token.refresh,
              })
            );

            if (res.data.access) {
              error.config.headers.Authorization = `Bearer ${res.data.access}`;
              error.config.baseURL = undefined;
              return axios.request(error.config);
            }
          })
          .catch((err) => {
            if (!err.config.url || err.config.url.includes(urlRefreshToken)) {
              return dispatch(logout());
            }
          });
      } else {
        return dispatch(logout());
      }
    }
    return Promise.reject(error);
  });
};

import { useEffect, useState } from 'react';
import { useTitleAndUser } from '../../../../hooks/userTitle';
import {
  AnalysisContainer,
  ButtonTab,
  HeaderPageContent,
  TabContent,
} from './styles';
import FiiPainel from '../FIIPainel';
import AcoesPainel from '../AcoesPainel';

import { CompareAssets } from '../CompareAssets';
import { ResumoAcao } from '../../../../components/ResumoAcao';
import ResumoFII from '../../../../components/ResumoFII';
import { CompareAssetsFiis } from '../CompareAssetsFii';

interface IPropsSelectedActive {
  slug: string;
  type: '' | 'action' | 'fii';
}

export function Analysis() {
  // @ts-ignore
  const { setTitle } = useTitleAndUser();
  const [tab, setTab] = useState(1);
  const [subTab, setSubTab] = useState(4); // Adicionando subTab para gerenciar as sub-abas
  const [selectedActive, setSelectedActive] = useState<IPropsSelectedActive>({
    slug: '',
    type: '',
  });

  useEffect(() => {
    setTitle('Analisar ativos');
  }, []);

  return (
    <AnalysisContainer>
      <HeaderPageContent>
        <div className='backPage'>
        <TabContent>
          <ButtonTab
            onClick={() => {
              setTab(1);
              setSelectedActive({
                slug: '',
                type: '',
              });
            }}
            isActive={tab === 1}
          >
            Ações
          </ButtonTab>
          <ButtonTab
            onClick={() => {
              setTab(2);
              setSelectedActive({
                slug: '',
                type: '',
              });
            }}
            isActive={tab === 2}
          >
            FIIs
          </ButtonTab>
          <ButtonTab
            onClick={() => {
              setTab(3);
              setSubTab(4); // Definindo subTab padrão
              setSelectedActive({
                slug: '',
                type: '',
              });
            }}
            isActive={tab === 3}
          >
            Comparador
          </ButtonTab>
          </TabContent>
          {tab === 3 && (
            <div className='comparador'>
              <ButtonTab
                onClick={() => {
                  setSubTab(4);
                  setSelectedActive({
                    slug: '',
                    type: '',
                  });
                }}
                isActive={subTab === 4}
              >
                FIIs
              </ButtonTab>
              <ButtonTab
                onClick={() => {
                  setSubTab(5);
                  setSelectedActive({
                    slug: '',
                    type: '',
                  });
                }}
                isActive={subTab === 5}
              >
                Ações
              </ButtonTab>
            </div>
          )}
        </div>
      </HeaderPageContent>
      {selectedActive.type === '' ? (
        <>
          {tab === 1 && <AcoesPainel selectedActive={setSelectedActive} />}
          {tab === 2 && (
            <FiiPainel
              onSelectedActive={({ slug, type }) => {
                setSelectedActive({
                  slug,
                  type,
                });
              }}
            />
          )}
          {tab === 3 && subTab === 4 && <CompareAssetsFiis />}
          {tab === 3 && subTab === 5 && <CompareAssets />}
        </>
      ) : selectedActive.type === 'action' ? (
        <ResumoAcao
          slug={selectedActive.slug}
          clearActive={setSelectedActive}
          setSelectedActive={setSelectedActive}
        />
      ) : (
        <ResumoFII
          slug={selectedActive.slug}
          setSelectedActive={setSelectedActive}
          onBackAllFii={(slug, type) => {
            setSelectedActive({
              slug,
              type,
            });
          }}
        />
      )}
    </AnalysisContainer>
  );
}

import styled from 'styled-components';

export const ContainerAll = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  width: 100%;
  height: 100%;
`;

export const ContentData = styled.div`
  width: 1590px;
  height: 100vh;
  margin-top: 5.7%;
  margin-left: -25%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: #FCFCFD;
  padding: 1rem;
  position: absolute;
  border-radius: 8px;
  // border: 1px solid var(--Gray-gray-100, #D4D6DB);
  border-right: 1px solid var(--Gray-gray-100, #D4D6DB);
  border-left: 1px solid var(--Gray-gray-100, #D4D6DB);
  border-bottom: 1px solid var(--Gray-gray-100, #D4D6DB);
`;

export const ContainerProventosRecebidos = styled.div`
  background: #FFF;
  border-radius: 0px 0px 8px 8px;
  padding: 1rem;
  width: 100%;
  height: auto;
  margin-top: 15%;
  margin-left: -1%; //margin GBL
  position: absolute;

  .filterButton {
    all: unset;
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem 1rem;
    background: #f1f8ff;
    fill-opacity: 0.3;
    border-radius: 39px;
    color: ${(props) => props.theme['primary-800']};
    font-weight: 500;
    font-size: 1rem;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: 1rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
  border-radius: 4px;
  box-shadow: ${(props) => props.theme['card-shadow-2']};
`;

export const ContentCalendar = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

export const TabFilter = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  background-color: ${(props) => props.theme.white};
  border-radius: 4px;
  box-shadow: ${(props) => props.theme['card-shadow-2']};

  header {
    display: flex;
    justify-content: space-between;
    span {
      transition: 0.3s;
      cursor: pointer;
      :hover {
        color: red;
      }
    }
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.white};
  border-radius: 8px;
  padding: 1rem;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(9rem, 1fr));
  gap: 1.5rem;
  width: 100%;
`;

export const CardValue = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-bottom: 2px solid #EC6643;
  border-top: 1px solid #D4D6DB;
  border-left: 1px solid #D4D6DB;
  border-right: 1px solid #D4D6DB;
  // box-shadow: 1px 1px 6px 0px #0000001a;
  padding: 1rem;
  border-radius: 12px;

  width: 100%;

  span {
    font-weight: 0.75rem;
    font-weight: 400;
    color: ${(props) => props.theme['gray-500']};
    margin: 0;
  }

  h5 {
    margin: 0;
    font-weight: 500;
    font-size: 1.25rem;
    color: ${(props) => props.theme['gray-400']};
  }
`;

export const themes = {
  light: {
    texts: {
      title: '700 1.5rem Poppins, sans-serif',
      body: '400 0.875rem Poppins, sans-serif',
    },
    colors: {
      blue: '#2469A8',
      yellow: '#F8B700',
      black: '#2B2A2A',
      red: '#C61722',
      white: '#FFF',
    },
    background: {
      primary: 'black',
    },
  },
  dark: {
    colors: {
      primary: '#9096B4',
      white: '#FFF',
    },
    background: {
      primary: 'green',
    },
  },
};

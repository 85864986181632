import axios from 'axios';
import moment from 'moment';
import { FilePdf } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';

import { AssetsAndRightsInformationActions } from '../../components/AssetsAndRightsInformationActions';
import { AssetsAndRightsInformationFIIs } from '../../components/AssetsAndRightsInformationFIIs';
import { generatorPDFIncomeTable } from '../../components/generatorPDFIncomeTable';
import { IncomeTable } from '../../components/IncomeTable';
import {
  Container,
  ContentAssetsAndRights,
  ContentTab,
  ContentWarning,
} from './styles';
import { IPropsIncomeAnnual, IPropsIncomeData } from '../../@types';
import { financeRoutes } from '../../../../../utils/RoutesApi';
import { IncomeTaxReportLoader } from '../../../../components/LoadingModels';
import { TabFilter } from '../../../../../components/TabFilter';
import { ButtonComponent } from '../../../../../components/ButtonComponent';

const baseURL = process.env.REACT_APP_API_URL;

interface IPropsDataIncome {
  Dividendos: IPropsIncomeData[];
  JCP: IPropsIncomeData[];

  Bonificacoes: IPropsIncomeData[];
  Rendimentos: IPropsIncomeData[];

  TotalDividendos: number;
  TotalJCP: number;
  TotalRendimento: number;
}

const headerIncome = [
  {
    label: 'CNPJ',
    active: 'CNPJ',
  },
  {
    label: 'Fonte pagadora',
    active: 'tickerSymbol',
  },
  {
    label: 'Valor',
    active: 'operationValue',
  },
];
const headerIncomeDescription = [
  {
    label: 'CNPJ',
    active: 'CNPJ',
  },
  {
    label: 'Fonte pagadora',
    active: 'tickerSymbol',
  },
  {
    label: 'Descrição',
    active: 'tickerSymbol',
  },
  {
    label: 'Valor',
    active: 'operationValue',
  },
];

export function AnualStatement() {
  const [loading, setLoading] = useState(false);
  const [dataIncome, setDataIncome] = useState<IPropsDataIncome>({
    Dividendos: [],
    JCP: [],
    Rendimentos: [],
    Bonificacoes: [],
    TotalDividendos: 0,
    TotalJCP: 0,
    TotalRendimento: 0,
  });

  const [dataIncomeAnnual, setDataIncomeAnnual] = useState<
    IPropsIncomeAnnual[]
  >([]);
  const [yearFilter, setYearFilter] = useState(
    moment().subtract(1, 'year').format('YYYY')
  );

  useEffect(() => {
    getConsolidatedAnnualEarnings({ ano: yearFilter });
    getAssetsAndRights({ ano: yearFilter });
  }, [yearFilter]);

  async function getConsolidatedAnnualEarnings(params: { ano: string }) {
    setLoading(true);
    await axios
      .get(`${baseURL}${financeRoutes.FINANCE_INCOME_TAX_CONSOLIDATED}`, {
        params,
      })
      .then((response) => {
        setDataIncome(response.data);
      })
      .catch((err) => {
        console.error(err);
        console.error(err.response);
      });
    setLoading(false);
  }

  async function getAssetsAndRights(params: { ano: string }) {
    await axios
      .get(`${baseURL}${financeRoutes.FINANCE_ASSETS_AND_RIGHTS}`, {
        params,
      })
      .then((response) => {
        setDataIncomeAnnual(response.data.BenseDireitos);
      })
      .catch((err) => {
        console.error(err);
        console.error(err.response);
      });
  }

  return loading ? (
    <IncomeTaxReportLoader />
  ) : (
    <Container>
      <TabFilter>
        <ContentTab>
          <div className="contentFilter">
            <strong>Ano: </strong>
            <select
              value={yearFilter}
              onChange={(e) => setYearFilter(e.target.value)}
            >
              {/* <option value={moment().format('YYYY')}>
                {moment().format('YYYY')}
              </option> */}
              <option value={moment().subtract(1, 'year').format('YYYY')}>
                {moment().subtract(1, 'year').format('YYYY')}
              </option>
            </select>
          </div>
          <ButtonComponent
            onClick={() =>
              generatorPDFIncomeTable({
                data1: dataIncome.Dividendos,
                data2: dataIncome.JCP,
                data3: dataIncome.Rendimentos,
                data4: dataIncome.Bonificacoes,
                data: dataIncomeAnnual,
              })
            }
            style={{
              borderRadius: '48px',
              padding: '.5rem 3rem',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <FilePdf size="1.2rem" color="#FFF" /> Gerar PDF
          </ButtonComponent>
        </ContentTab>
      </TabFilter>

      <div className="titleContent">
        <h5>Bens e direitos</h5>
        <h5>Grupo 03 - Participações societárias</h5>
        <h6>Código 01 - Ações (inclusive as listadas na bolsa Ações)</h6>
      </div>
      <ContentAssetsAndRights>
        {dataIncomeAnnual.filter((item) => item.Tipo === 'Ações').length > 0 ? (
          dataIncomeAnnual
            .filter((item) => item.Tipo === 'Ações')
            .map((item, index) => (
              <AssetsAndRightsInformationActions key={index} data={item} />
            ))
        ) : (
          <h1 style={{ fontSize: '1rem', margin: '1rem 0.5rem' }}>
            Ainda não há dados ou base em processamento
          </h1>
        )}
      </ContentAssetsAndRights>

      <div className="titleContent">
        <h5>Bens e direitos</h5>

        <h5>Grupo 07 - Fundos</h5>
        <h6>Código 03 - Fundo de Investimento Imobiliário FII</h6>
      </div>
      <ContentAssetsAndRights>
        {dataIncomeAnnual.filter((item) => item.Tipo === 'FII').length > 0 ? (
          dataIncomeAnnual
            .filter((item) => item.Tipo === 'FII')
            .map((item, index) => (
              <AssetsAndRightsInformationFIIs key={index} data={item} />
            ))
        ) : (
          <h1 style={{ fontSize: '1rem', margin: '1rem 0.5rem' }}>
            Ainda não há dados ou base em processamento
          </h1>
        )}
      </ContentAssetsAndRights>

      <div className="titleContent">
        <h5>Código 09 – Lucros e dividendos recebidos (Ações)</h5>
      </div>
      <IncomeTable
        ASC={(newData: IPropsIncomeData[]) =>
          setDataIncome({
            ...dataIncome,
            Dividendos: newData,
          })
        }
        DESC={(newData: IPropsIncomeData[]) =>
          setDataIncome({
            ...dataIncome,
            Dividendos: newData,
          })
        }
        data={dataIncome.Dividendos}
        header={headerIncome}
      />
      <div className="titleContent">
        <h5>Código 26 - Outros - (Rendimentos Fundo Imobiliário - FII)</h5>
      </div>
      <IncomeTable
        ASC={(newData: IPropsIncomeData[]) =>
          setDataIncome({
            ...dataIncome,
            Rendimentos: newData,
          })
        }
        DESC={(newData: IPropsIncomeData[]) =>
          setDataIncome({
            ...dataIncome,
            Rendimentos: newData,
          })
        }
        data={dataIncome.Rendimentos}
        header={headerIncomeDescription}
        description
      />
      <div className="titleContent">
        <h5>Código 10 – Juros sobre capital próprio</h5>
      </div>
      <IncomeTable
        ASC={(newData: IPropsIncomeData[]) =>
          setDataIncome({
            ...dataIncome,
            JCP: newData,
          })
        }
        DESC={(newData: IPropsIncomeData[]) =>
          setDataIncome({
            ...dataIncome,
            JCP: newData,
          })
        }
        data={dataIncome.JCP}
        header={headerIncome}
      />
      <div className="titleContent">
        <h5>
          Código 18 – Incorporação de reservas ao capital / bonificações em
          ações
        </h5>
      </div>
      <IncomeTable
        ASC={(newData: IPropsIncomeData[]) =>
          setDataIncome({
            ...dataIncome,
            Bonificacoes: newData,
          })
        }
        DESC={(newData: IPropsIncomeData[]) =>
          setDataIncome({
            ...dataIncome,
            Bonificacoes: newData,
          })
        }
        data={dataIncome.Bonificacoes}
        header={headerIncome}
      />
      <ContentWarning>
        As taxas apresentadas incluem somente as tarifas de liquidação,
        registro, termo/opções e emolumentos da B3. O Usuário é exclusivamente
        responsável pela conferência e validação das informações utilizadas na
        apuração do imposto devido, conforme Termos e Condições Gerais de Uso.
      </ContentWarning>
    </Container>
  );
}

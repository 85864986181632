import { Buildings } from '@phosphor-icons/react';
import ReactApexChart from 'react-apexcharts';
import { IPropsRankActive } from '../../@types/listActiveType';
import { Container, Content, ImageContent } from './styles';
import { Tooltip } from 'antd';
import { ApexOptions } from 'apexcharts';

interface IPropsCardActiveRank {
  active: IPropsRankActive;
  selectedActive: ({
    slug,
    type,
  }: {
    slug: string;
    type: '' | 'action' | 'fii';
  }) => void;
}

export function CardActiveRank({
  active,
  selectedActive,
}: IPropsCardActiveRank) {
  const values = active?.LucroLiquido
    ? [
        active.LucroLiquido || 0,
        active.EBITDA || 0,
        active.Dividendos || 0,
        active.DividaLiquida || 0,
        active.DividaLiquidaEbitida || 0,
        active.Resiliencia || 0,
        active.Governanca || 0,
        active.Indices || 0,
      ]
    : [];
  const labels = [
    'Lucro Líquido',
    'EBITDA',
    'Dividendos',
    'Dívida Líquida',
    'Dívida Líquida EBITDA',
    'Resiliência',
    'Governança',
    'Índices',
    '',
  ];
  const total = values && values?.reduce((sum, value) => sum + (value || 0), 0);
  const restante = (total - 100 * values?.length) * -1;
  const series = [...values, restante];
  const compare = {
    series: [...values, restante],
    options: {
      chart: {
        height: 150,
        type: 'donut',
      },
      stroke: { show: false },
      grid: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },
      legend: {
        show: false,
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          donut: {
            size: '65%',
            labels: {
              show: false,
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        onDatasetHover: {
          highlightDataSeries: false,
        },
        y: {
          formatter: (val: any) => {
            if (val > 100) return '';
            return `${val.toFixed(0)}%`;
          },
        },
      },
      labels,
      colors: [
        '#38486B',
        '#635185',
        '#99538E',
        '#CD5485',
        '#F45F6A',
        '#FF7D45',
        '#FFA600',
        '#52C41A',
        '#E4E5E7',
      ],
    },
  };

  //@ts-ignore
  const tickerArray = active?.Assets?.split('|')?.sort((a: any, b: any) => {
    if (a > b) {
      return 1;
    } else {
      return -1;
    }
  });

  const mainAsset = active?.Assets?.split('|')
    ?.sort(
      (a: string, b: string) =>
        Number(a.replace(/[a-zA-Z]/g, '')) - Number(b.replace(/[a-zA-Z]/g, ''))
    )[0]
    ?.replace('.SA', '');

  return (
    <Container
      onClick={() =>
        selectedActive({
          slug: mainAsset,
          type: 'action',
        })
      }
    >
      <header>
        <ImageContent>
          {active.LOGO_BRAND ? (
            <img src={active.LOGO_BRAND} />
          ) : (
            <Buildings size="2rem" weight="fill" />
          )}
        </ImageContent>
        <button
          onClick={() =>
            selectedActive({
              slug: mainAsset,
              type: 'action',
            })
          }
          title={tickerArray.map((item) => item).join(', ')}
        >
          {mainAsset}
        </button>
      </header>
      <Content>
        <div className="contentGraphicValue">
          <ReactApexChart
            //@ts-ignore
            options={compare.options}
            series={compare.series || []}
            type="donut"
            height={200}
            width={250}
          />
          <Tooltip title="Essa é a nota da empresa com base na média das nossas avaliações">
            <h6>{active.total_chart.toFixed(0)}%</h6>
          </Tooltip>
        </div>
        <div>
          <h5 title={active.NomeEmpresa}>{active.NomeEmpresa}</h5>
        </div>
      </Content>
    </Container>
  );
}

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { CaretDown, CaretUp, Eye, EyeSlash } from '@phosphor-icons/react';
import { IPropsUserWallet } from '../../../../@types';
import { filterTableSorte } from '../../../../../../../utils/filterTableSort';
import { Tooltip } from 'antd';
import { TypeActiveContent } from '../../../../../../components/AppsMenu/AppProfile/stylePostDetails';
import { transformMaskCurrency } from '../../../../../../../utils/transformMaskCurrency';
import { validateFloatNumber } from '../../../../../../components/Boltz/ValidateFloatNumber';
import { defineDisplayColor } from '../../../../../../components/Boltz/DefineDisplayColor';
import { useState } from 'react';
import { useActives } from '../../../../../../../hooks/useActives';
import { CompanyNameContent } from './styles';
import { GraphicNote } from './GraphicNote';
import { RenderConditionalObjective } from '../../../../../../components/RenderConditionalObjective';
import { useActivesWallet } from '../../../../hooks/ActivesHooks';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#FFF',
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const header = [
  { label: 'Ativo', active: 'tickerSymbol' },
  { label: 'Tipo', active: 'productTypeName' },
  { label: 'Nota', active: 'nota' },
  { label: 'Cotação', active: 'closingPrice' },
  { label: 'Quant.', active: 'equitiesQuantity' },
  { label: 'Patrimônio', active: 'updateValue' },
  { label: 'Objetivo' },
  { label: 'Atual', active: 'percent' },
  { label: 'Diferença', active: 'gap' },
  { label: 'Preço médio', active: 'PrecoMedio' },
  { label: 'Rentabilidade', active: 'Rentabilidade' },
  { label: 'Lucro/Prejuízo', active: 'lucro' },
];

interface IPropsAllTable {
  handleOrientationTable: Function;
  fullWallet: IPropsUserWallet;
  filterShowColumnTable: string[];
  selectedBrokerage: string;
  setRule: Function;
  setShowModalCreateRule: Function;
  filterTextSearchActive: string;
}

export function AllTable({
  handleOrientationTable,
  fullWallet,
  filterShowColumnTable,
  selectedBrokerage,
  setRule,
  setShowModalCreateRule,
  filterTextSearchActive,
}: IPropsAllTable) {
  //@ts-ignore
  const { showActive } = useActives();
  const { typeWallet } = useActivesWallet();
  const [showAmountActives, setShowAmountActives] = useState<boolean>(false);
  const [showActiveName, setShowActiveName] = useState<boolean>(false);
  const showActiveColumnTable = filterShowColumnTable.includes('Ativo');
  const showTypeColumnTable = filterShowColumnTable.includes('Tipo');
  const showNoteColumnTable = filterShowColumnTable.includes('Nota');
  const showClosePriceColumnTable = filterShowColumnTable.includes('Cotação');
  const showAmountColumnTable = filterShowColumnTable.includes('Quant.');
  const showPatrimonyColumnTable = filterShowColumnTable.includes('Patrimônio');
  const showGoalColumnTable = filterShowColumnTable.includes('Objetivo');
  const showCurrentColumnTable = filterShowColumnTable.includes('Atual');
  const showGapColumnTable = filterShowColumnTable.includes('Diferença');
  const showProfitColumnTable =
    filterShowColumnTable.includes('Lucro/Prejuízo');
  const showProfitabilityColumnTable =
    filterShowColumnTable.includes('Rentabilidade');
  const showAveragePriceColumnTable =
    filterShowColumnTable.includes('Preço médio');

  const ArrayFilteredAllTabe = fullWallet?.positionsAll?.filter((item) => {
    if (
      item.tickerSymbol
        .toLowerCase()
        .includes(filterTextSearchActive.toLocaleLowerCase()) ||
      item.corporationName
        .toLowerCase()
        .includes(filterTextSearchActive.toLocaleLowerCase())
    ) {
      return item;
    }
  });

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
      <Table aria-label="customized table" stickyHeader>
        <TableHead>
          <TableRow>
            {header.map((item) => {
              if (filterShowColumnTable.includes(item.label)) {
                return (
                  <StyledTableCell key={item.label}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.625rem',
                        fontSize: '0.95rem',
                      }}
                    >
                      {item.label}
                      {item.active && (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <CaretUp
                            size={14}
                            color="black"
                            onClick={() => {
                              handleOrientationTable({
                                ...fullWallet,
                                positionAcao: filterTableSorte({
                                  data: fullWallet.positionsAll,
                                  dir: 'asc',
                                  //@ts-ignore
                                  key: item.active,
                                }),
                              });
                            }}
                          />
                          <CaretDown
                            size={14}
                            color="black"
                            onClick={() => {
                              handleOrientationTable({
                                ...fullWallet,
                                positionAcao: filterTableSorte({
                                  data: fullWallet.positionsAll,
                                  dir: 'desc',
                                  //@ts-ignore
                                  key: item.active,
                                }),
                              });
                            }}
                          />
                        </div>
                      )}
                      {item.label === 'Quant.' &&
                        (showAmountActives ? (
                          <Eye
                            size={20}
                            color="black"
                            onClick={() =>
                              setShowAmountActives(!showAmountActives)
                            }
                          />
                        ) : (
                          <EyeSlash
                            size={20}
                            color="black"
                            onClick={() =>
                              setShowAmountActives(!showAmountActives)
                            }
                          />
                        ))}
                      {item.label === 'Ativo' &&
                        (showActiveName ? (
                          <Eye
                            size={20}
                            color="black"
                            onClick={() => setShowActiveName(!showActiveName)}
                          />
                        ) : (
                          <EyeSlash
                            size={20}
                            color="black"
                            onClick={() => setShowActiveName(!showActiveName)}
                          />
                        ))}
                    </div>
                  </StyledTableCell>
                );
              }
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {ArrayFilteredAllTabe && ArrayFilteredAllTabe.length > 0 ? (
            ArrayFilteredAllTabe.map((item, index) => (
              <StyledTableRow key={index}>
                {showActiveColumnTable && (
                  <StyledTableCell>
                    <CompanyNameContent>
                      {showActiveName || showActive ? (
                        <>
                          <strong title={item.tickerSymbol}>
                            {item.tickerSymbol}
                          </strong>
                          <span title={item.corporationName}>
                            {' '}
                            {item.corporationName}
                          </span>
                        </>
                      ) : (
                        '*****'
                      )}
                    </CompanyNameContent>
                  </StyledTableCell>
                )}
                {showTypeColumnTable && (
                  <StyledTableCell>
                    <TypeActiveContent
                      //@ts-ignore
                      typeActive={
                        item.productTypeName === 'acao'
                          ? 'acao'
                          : item.productTypeName !== 'acao' &&
                            item.productTypeName !== 'tesourodireto' &&
                            item.productTypeName !== 'rendafixa'
                          ? 'FII'
                          : 'rendaFixa'
                      }
                    >
                      {item.productTypeName === 'acao'
                        ? 'Ação'
                        : item.productTypeName !== 'acao' &&
                          item.productTypeName !== 'tesourodireto' &&
                          item.productTypeName !== 'rendafixa'
                        ? 'FII'
                        : 'Renda Fixa'}
                    </TypeActiveContent>
                  </StyledTableCell>
                )}
                {showNoteColumnTable && (
                  <StyledTableCell>
                    <Tooltip
                      title={
                        item.nota <= 0
                          ? 'Nota não aplicável a esse ativo ou não encontrada'
                          : `${item.nota.toFixed(0)}%`
                      }
                    >
                      {item.nota <= 0 ? (
                        <>N/A</>
                      ) : (
                        <GraphicNote
                          noteValue={item.nota}
                          color={
                            item.nota <= 50
                              ? '#ff0000'
                              : item.nota <= 60
                              ? '#ffff00'
                              : '#0000ff'
                          }
                        />
                      )}
                    </Tooltip>
                  </StyledTableCell>
                )}
                {showClosePriceColumnTable && (
                  <StyledTableCell>
                    {transformMaskCurrency(item.closingPrice)}
                  </StyledTableCell>
                )}
                {showAmountColumnTable && (
                  <StyledTableCell>
                    {showActive || showAmountActives
                      ? validateFloatNumber(
                          item.equitiesQuantity.toFixed(2).replace('.', ',')
                        )
                      : '****'}
                  </StyledTableCell>
                )}
                {showPatrimonyColumnTable && (
                  <StyledTableCell
                    className="reduce"
                    title={
                      showActive
                        ? transformMaskCurrency(item.updateValue)
                        : 'R$ ******'
                    }
                  >
                    {showActive
                      ? transformMaskCurrency(item.updateValue)
                      : 'R$ ******'}
                  </StyledTableCell>
                )}

                {showGoalColumnTable && (
                  // @ts-ignore
                  // <StyledTableCell>{item.ValorExp.toFixed(2)}%</StyledTableCell>

                  <RenderConditionalObjective
                    //@ts-ignore
                    active={item}
                    exceptions={item.Exception}
                    filterBrokerage={selectedBrokerage}
                    setRule={setRule}
                    setShowModalCreateRule={setShowModalCreateRule}
                    typeWallet={typeWallet}
                    //@ts-ignore
                    walletValues={fullWallet}
                  />
                )}
                {showCurrentColumnTable && (
                  <StyledTableCell
                  // style={{
                  //   color: defineDisplayColor({

                  //     item,
                  //     exceptions: item.Exception,
                  //     type: filterBrokerage === '' ? 'all' : 'broker',
                  //     wallet: fullWallet,
                  //     //@ts-ignore
                  //     filterBrokerage:
                  //       filterBrokerage === '' ? null : filterBrokerage,
                  //     typeWallet: typeWallet,
                  //   }),
                  // }}
                  >
                    {item?.percent?.toFixed(2)}%
                  </StyledTableCell>
                )}
                {showGapColumnTable && (
                  <StyledTableCell>
                    {(item.porcentagem_recomendada - item.percent).toFixed(2)}%
                  </StyledTableCell>
                )}
                {showAveragePriceColumnTable && (
                  <StyledTableCell
                    className="reduce"
                    title={
                      showActive
                        ? transformMaskCurrency(item.PrecoMedio)
                        : 'R$ ******'
                    }
                  >
                    {showActive
                      ? transformMaskCurrency(item.PrecoMedio)
                      : 'R$ ******'}
                  </StyledTableCell>
                )}

                {showProfitabilityColumnTable && (
                  <StyledTableCell
                    style={{
                      color: item.Rentabilidade < 0 ? 'red' : '#44bd32',
                    }}
                  >
                    {item?.Rentabilidade?.toFixed(2)}%
                  </StyledTableCell>
                )}

                {showProfitColumnTable && (
                  <StyledTableCell
                    className="reduce"
                    title={
                      showActive
                        ? transformMaskCurrency(item.lucro)
                        : 'R$ ******'
                    }
                    style={{
                      color: item.lucro < 0 ? 'red' : '#44bd32',
                    }}
                  >
                    {showActive
                      ? transformMaskCurrency(item.lucro)
                      : 'R$ ******'}
                  </StyledTableCell>
                )}
              </StyledTableRow>
            ))
          ) : (
            <h1 style={{ fontSize: '1rem', margin: '1rem 0.5rem' }}>
              Ainda não há dados
            </h1>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import { Close, Content, Header, ModalStyled, OutModalStyled } from './styles';

export function Modal(props) {
  var { id, children, title, setState, width } = props;

  function handleOutSideClick(e) {
    if (e.target.id === id) setState(false);
  }
  return (
    <OutModalStyled id={id} onClick={handleOutSideClick}>
      <ModalStyled>
        <Header>
          <h3>{title}</h3>
          <Close onClick={() => setState(false)} />
        </Header>
        <Content width={width}>{children}</Content>
      </ModalStyled>
    </OutModalStyled>
  );
}

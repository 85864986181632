import { useEffect, useState } from 'react';
import {
  Container,
  DeleteActive,
  EditActive,
  DeleteAllActives,
  HeaderStyle,
  TypeActiveContent,
} from './stylePostDetails';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { Modal } from '../../../../components/Modal';
import { SimpleActive } from '../../../../components/Forms/SimpleActive';

import axios from 'axios';

import { handleToast } from '../../../../components/Toast';
import { filterTableSorte } from '../../../../utils/filterTableSort';
import { CaretDown, CaretUp, Check, X } from '@phosphor-icons/react';

import { walletRoutes } from '../../../../utils/RoutesApi';
import { Button } from '../../../pages/RegistrationStyles';

import { useTitleAndUser } from '../../../../hooks/userTitle';
import { ButtonComponent } from '../../../../components/ButtonComponent';
import { TableLoader } from '../../LoadingModels';
import { transformMaskCurrency } from '../../../../utils/transformMaskCurrency';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontFamily: 'Poppins, sans-serif',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: 'Poppins, sans-serif',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const header = [
  { label: 'Ativo', active: 'tickerSymbol' },
  { label: 'Corretora', active: 'participantName' },
  { label: 'Tipo de investimento', active: 'productTypeName' },
  { label: 'Quantidade', active: 'equitiesQuantity' },
  { label: 'Auto.' },
  { label: 'Ações' },
];

const PostDetails = () => {
  const { user, setTitle } = useTitleAndUser();

  const [modalCreateActive, setModalCreateActive] = useState(false);
  const idCreate = 'modalCreate';
  const idEdit = 'modalEdit';
  const [modalEditActive, setModalEditActive] = useState(false);
  const [modalDeleteAll, setModalDeleteAll] = useState(false);
  const [loadingActives, setLoadingActives] = useState(false);

  const [edit, setEdit] = useState();
  const [values, setValues] = useState({
    links: {
      next: null,
      previuos: null,
    },
    count: 0,
    current: 0,
    total_pages: 1,
    data: [],
  });
  const [filter, setFilter] = useState();
  const [valuesFilter, setValuesFilter] = useState(null);

  useEffect(() => {
    handleGetListActives({ page: 1, page_size: 100 });
    setTitle('Importação Manual');
  }, []);

  useEffect(() => {
    if (filter) {
      setValuesFilter(
        values.data.filter(
          (item) =>
            item.tickerSymbol.toLowerCase().includes(filter.toLowerCase()) ||
            item.participantName.toLowerCase().includes(filter.toLowerCase()) ||
            item.corporationName.toLowerCase().includes(filter.toLowerCase())
        )
      );
    } else {
      setValuesFilter(null);
    }
  }, [filter]);

  async function handleGetListActives(params = null) {
    setLoadingActives(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/Finance/carteira/all`, { params })
      .then((response) => {
        setValues(response.data);
      })
      .catch((error) => {});
    setLoadingActives(false);
  }
  async function handleDeleteActive(key) {
    let data = {
      username: user.username,
    };
    await axios
      .delete(
        `${process.env.REACT_APP_API_URL}/Finance/carteira/item/${key}/delete/`,
        { data }
      )
      .then((response) => {
        handleToast(false, 'Ativo deletado com sucesso');
        handleGetListActives({ page: 1, page_size: 100 });
        return;
      })
      .catch((error) => {
        return;
      });
  }

  function handleOutSideClick(e) {
    if (e.target.id === idCreate) setModalCreateActive(false);
    if (e.target.id === idEdit) setModalEditActive(false);
  }

  async function handleDeleteAllImports() {
    await axios
      .delete(
        `${process.env.REACT_APP_API_URL}${walletRoutes.WALLET_DELETE_ALL_IMPORTS}`
      )
      .then((response) => {
        setModalDeleteAll(false);
        handleGetListActives({ page: 1, page_size: 100 });
        handleToast(false, 'Todos os ativos foram deletados');
        return;
      })
      .catch((err) => {
        handleToast(true, 'Erro ao deletar ativos');

        return;
      });
  }

  return (
    <Container>
      <HeaderStyle>
        <div>
          <input
            type="text"
            onChange={(e) => {
              setFilter(e.target.value);
            }}
            placeholder="Buscar..."
          />
        </div>
        <div>
          <ButtonComponent
            variant="link"
            onClick={() => setModalCreateActive(true)}
          >
            Novo ativo
          </ButtonComponent>
          <div className="divider" />
          <ButtonComponent
            variant="link"
            className="delete"
            onClick={() => {
              setModalDeleteAll(true);
            }}
          >
            Excluir tudo
          </ButtonComponent>
        </div>
      </HeaderStyle>
      {loadingActives ? (
        <TableLoader />
      ) : (
        <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
          <Table
            sx={{ minWidth: 700 }}
            stickyHeader
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                {header.map((item) => (
                  <StyledTableCell key={item.label}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        fontSize: '0.95rem',
                      }}
                    >
                      {item.label}
                      {item.active && (
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <CaretUp
                            size={14}
                            color="black"
                            onClick={() => {
                              setValues({
                                ...values,
                                data: filterTableSorte({
                                  data: values.data,
                                  dir: 'asc',
                                  key: item.active,
                                }),
                              });
                            }}
                          />
                          <CaretDown
                            size={14}
                            color="black"
                            onClick={() => {
                              setValues({
                                ...values,
                                data: filterTableSorte({
                                  data: values.data,
                                  dir: 'desc',
                                  key: item.active,
                                }),
                              });
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {valuesFilter !== null ? (
                valuesFilter.map((item, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" className="reduce">
                      {item.tickerSymbol}
                      <br />
                      <strong>{item.corporationName}</strong>
                    </StyledTableCell>
                    <StyledTableCell>{item.participantName}</StyledTableCell>
                    <StyledTableCell>
                      <TypeActiveContent typeActive={item.productTypeName}>
                        {item.productTypeName === 'acao'
                          ? 'Ação'
                          : item.productTypeName === 'FII'
                          ? 'FII'
                          : item.productTypeName === 'rendaFixa'
                          ? 'Renda fixa'
                          : null}
                      </TypeActiveContent>
                    </StyledTableCell>
                    <StyledTableCell>
                      {item.productTypeName === 'rendaFixa'
                        ? transformMaskCurrency(item.equitiesQuantity)
                        : item.equitiesQuantity}
                    </StyledTableCell>

                    <StyledTableCell>
                      <EditActive
                        onClick={() => {
                          setEdit(item);
                          setModalEditActive(true);
                        }}
                      />{' '}
                      <DeleteActive
                        onClick={() => handleDeleteActive(item.key)}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : values.data.length > 0 ? (
                values.data.map((item, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" className="reduce">
                      {item.tickerSymbol}
                      <br />
                      <strong>{item.corporationName}</strong>
                    </StyledTableCell>
                    <StyledTableCell>{item.participantName}</StyledTableCell>
                    <StyledTableCell>
                      <TypeActiveContent typeActive={item.productTypeName}>
                        {item.productTypeName === 'acao'
                          ? 'Ação'
                          : item.productTypeName === 'FII'
                          ? 'FII'
                          : item.productTypeName === 'rendaFixa'
                          ? 'Renda fixa'
                          : null}
                      </TypeActiveContent>
                    </StyledTableCell>
                    <StyledTableCell>
                      {item.productTypeName === 'rendaFixa'
                        ? transformMaskCurrency(item.equitiesQuantity)
                        : item.equitiesQuantity}
                    </StyledTableCell>

                    <StyledTableCell>
                      {item.agregarAutomatico ? (
                        <Check size={24} />
                      ) : (
                        <X size={24} />
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      <EditActive
                        onClick={() => {
                          setEdit(item);
                          setModalEditActive(true);
                        }}
                      />{' '}
                      <DeleteActive
                        onClick={() => handleDeleteActive(item.key)}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <h1 style={{ fontSize: '1rem', margin: '1rem 0.5rem' }}>
                  Ainda não há dados
                </h1>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {modalCreateActive && (
        <Modal
          id={idCreate}
          title="Cadastrar novo ativo"
          setState={setModalCreateActive}
          onClick={handleOutSideClick}
        >
          <SimpleActive
            setCancel={() => setModalCreateActive(false)}
            update={() => {
              setModalCreateActive(false);
              handleGetListActives({ page: 1, page_size: 100 });
            }}
          />
        </Modal>
      )}
      {modalEditActive && (
        <Modal
          id={idEdit}
          title="Editar ativo"
          setState={setModalEditActive}
          onClick={handleOutSideClick}
        >
          <SimpleActive
            setCancel={() => setModalEditActive(false)}
            edit={edit}
            update={() => {
              setModalEditActive(false);
              handleGetListActives({ page: 1, page_size: 100 });
            }}
          />
        </Modal>
      )}
      {modalDeleteAll && (
        <Modal
          id="Delete"
          title="Deletar todos os ativos"
          setState={setModalDeleteAll}
          onClick={handleOutSideClick}
        >
          <DeleteAllActives>
            <p>
              Ao confirmar você aceita que todos os ativos cadastrados na conta
              serão excluídos
            </p>
            <div className="buttonContent">
              <Button
                background="red"
                type="button"
                color="#FFF"
                onClick={() => setModalDeleteAll(false)}
              >
                Cancelar
              </Button>
              <Button
                background="var(--primary)"
                type="button"
                typeLoading="balls"
                color="#FFF"
                onClick={handleDeleteAllImports}
              >
                Confirmar
              </Button>
            </div>
          </DeleteAllActives>
        </Modal>
      )}
    </Container>
  );
};

export default PostDetails;

import { Link } from 'react-router-dom';

import { IPropsPlanIR } from '../../../../../Dashboard/Settings/Pagamentos/SelectedPlan/components/@types';
import { Container, ContentPlanMessage } from './styles';

interface IPropsPlanMessage {
  plan: IPropsPlanIR;
}

export function PlanMessageStatus({ plan }: IPropsPlanMessage) {
  return (
    <Container>
      <ContentPlanMessage>
        <p>
          Seu pagamento foi processado e estamos aguardando a confirmação para
          liberar sua assinatura. Você pode acompanhar tudo clicando em{' '}
          <Link to="/pagamentos">Configurações — Pagamentos.</Link>
        </p>
      </ContentPlanMessage>
    </Container>
  );
}

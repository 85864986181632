import styled from 'styled-components';

export const CongratulationsCreatingAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  align-items: center;
  text-align: center;
  height: 100%;

  svg {
    background: ${(props) => props.theme.primary};
    color: ${(props) => props.theme.white};
    width: 5.75rem;
    height: 5.75rem;
    border-radius: 100%;
  }
  header {
    p {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      gap: 1.5rem;
      font-weight: 500;
      font-size: 1.375rem;
      text-align: center;
    }
    h2 {
      font-weight: 600;
      font-size: 1.5rem;
      margin-top: 1.5rem;
      width: 100%;
      max-width: 32.625rem;
    }
  }

  p {
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 150%;
    color: ${(props) => props.theme['gray-700']};
    margin-top: 2rem;
    width: 100%;
    max-width: 34.0625rem;
    margin-bottom: 2.25rem;
  }
  span {
    font-weight: 400;
    font-size: 0.875rem;
    color: #606060;
    width: 100%;
    max-width: 32.625rem;
  }
`;

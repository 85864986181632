import React from 'react';
import { Container } from './styles';

interface IPropsCardComponent {
  children?: React.ReactNode;
}

export function CardComponent({ children }: IPropsCardComponent) {
  return <Container>{children}</Container>;
}

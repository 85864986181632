import styled from 'styled-components';

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  p {
    color: #6a717e;
    /* Body/Small/Regular */
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
    margin: 0;
  }
  button {
    margin-top: 1.5rem;
  }
`;

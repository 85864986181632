import axios from 'axios';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { GraphicFilter } from './components/GraphicFilter';
import {
  CardValue,
  Container,
  ContainerAll,
  ContentData,
  ContainerProventosRecebidos,
  Content,
  Row,
} from './styles';
import { useTitleAndUser } from '../../../../../hooks/userTitle';
import { useActives } from '../../../../../hooks/useActives';
import { financeRoutes } from '../../../../../utils/RoutesApi';
import { transformMaskCurrency } from '../../../../../utils/transformMaskCurrency';
import { EarningReceivedLoader } from '../../../../components/LoadingModels';
import { LinkB3Page } from '../../../../../social/pages/LinkB3Page';
import { TableProfitability } from '../../../../../components/Tables/TableProfitability';
import { NoDataB3 } from '../../../../../social/pages/Components/NoDataB3';
import {
  IPropsAtivosProventosRecebidos,
  IPropsCorretoras,
  IPropsRendimentos,
  IPropsTiposMovimentos,
  IPropsTotalPeriodos,
  IPropsValuesProventosRecebidos,
} from './@types';
import { AsideFiltersProventosRecebidos } from './components/AsideFiltersProventosRecebidos';
import { SideModal } from '../../../../../components/SideModal';
import moment from 'moment';

export interface IPropsFilterData {
  Ativos: {
    label: string;
    value: string;
  }[];
  Corretoras: {
    label: string;
    value: string;
  }[];
  TiposMovimentos: {
    label: string;
    value: string;
  }[];
}

export function ProventosRecebidos() {
  // @ts-ignore
  const { user, setTitle } = useTitleAndUser();
  // @ts-ignore
  const { showActive } = useActives();
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [values, setValues] = useState<IPropsValuesProventosRecebidos>({
    count: 0,
    current: 0,
    data: { total_periodos: [], rendimentos: [] },
    links: { next: null, previous: null },
    total_pages: 0,
    isFilter: false,
  });
  const [valuesSeparatedByType, setValuesSeparatedByType] = useState({
    jcp: [],
    div: [],
    rend: [],
  });
  const [selectDateFilter, setSelectDateFilter] = useState({
    start: '',
    end: '',
  });
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState<IPropsFilterData>({
    Ativos: [{ label: 'Todos', value: 'todos' }],
    Corretoras: [],
    TiposMovimentos: [{ label: 'Todos', value: 'todos' }],
  });
  const [filtersSelecteds, setFiltersSelecteds] = useState<any>({});
  const [typeGraphic, setTypeGraphic] = useState<'year' | 'month'>('month');

  useEffect(() => {
    setTitle('Proventos Recebidos');
  }, []);

  useEffect(() => {
    if (
      selectDateFilter.end !== '' &&
      selectDateFilter.start !== '' &&
      selectDateFilter.end.replace(/\D/g, '').length === 6 &&
      selectDateFilter.start.replace(/\D/g, '').length === 6
    ) {
      let start = selectDateFilter.start.split('/');
      let end = selectDateFilter.end.split('/');

      let filter = filtersSelecteds;

      if (filter.tipoproduto === 'todos') {
        delete filter.tipoproduto;
      }
      if (filter.ativo === 'todos') {
        delete filter.ativo;
      }
      if (filter.corretora === 'todos') {
        delete filter.corretora;
      }
      // @ts-ignore
      handleProceedsReceived({
        de: `${start[1]}${start[0]}`,
        ate: `${end[1]}${end[0]}`,
        ...filter,
      });
    } else if (selectDateFilter.end === '' && selectDateFilter.start === '') {
      let filter = filtersSelecteds;

      if (filter.tipoproduto === 'todos') {
        delete filter.tipoproduto;
      }
      if (filter.ativo === 'todos') {
        delete filter.ativo;
      }
      if (filter.corretora === 'todos') {
        delete filter.corretora;
      }

      handleProceedsReceived(filter);
    }
  }, [selectDateFilter, filtersSelecteds]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_FILTERS_RECEBIDOS}`
      )
      .then((response) => {
        const newDataActives = response.data.Ativos.map(
          (item: IPropsAtivosProventosRecebidos) => {
            if (item.nome) {
              return {
                value: item.ativo,
                label: `${item.ativo} - ${item.nome}`,
              };
            } else {
              return {
                value: item.ativo,
                label: `${item.ativo}`,
              };
            }
          }
        );
        const newDataBrokers = response.data.Corretoras.map(
          (item: IPropsCorretoras) => {
            return {
              value: item.participantName,
              label: item.participantName,
            };
          }
        );
        const newDataMovimentType = response.data.TiposMovimentos.map(
          (item: IPropsTiposMovimentos) => {
            return {
              value: item.movimentType,
              label: item.movimentType,
            };
          }
        );
        setFilterData({
          TiposMovimentos: [
            { label: 'Todos', value: 'todos' },
            ...newDataMovimentType,
          ],
          Ativos: [{ label: 'Todos', value: 'todos' }, ...newDataActives],
          Corretoras: [
            {
              label: 'Todos',
              value: 'todos',
            },
            ...newDataBrokers,
          ],
        });
      })
      .catch((err) => {});
  }, []);

  async function handleProceedsReceived(params: any = {}) {
    // setLoading(true);
    if (params.tiposmovimento === 'todos') {
      delete params.tiposmovimento;
    }
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_PROCEEDS_RECEIVED}`,
        { params }
      )
      .then((response) => {
        let resp = { ...response.data, isFilter: false };
        setValuesSeparatedByType({
          jcp: resp.data.rendimentos.filter(
            (item: IPropsRendimentos) =>
              item.movementType === 'Juros Sobre Capital Próprio'
          ),
          div: resp.data.rendimentos.filter(
            (item: IPropsRendimentos) => item.movementType === 'Dividendo'
          ),
          rend: resp.data.rendimentos.filter(
            (item: IPropsRendimentos) => item.movementType === 'Rendimento'
          ),
        });

        resp.data.total_periodos = resp.data.total_periodos.sort(
          (a: IPropsTotalPeriodos, b: IPropsTotalPeriodos) => {
            if (a.period < b.period) {
              return -1;
            } else {
              return true;
            }
          }
        );
        resp.data.rendimentos = resp.data.rendimentos.sort(
          (a: IPropsRendimentos, b: IPropsRendimentos) => {
            if (a.referenceDate < b.referenceDate) {
              return -1;
            } else {
              return true;
            }
          }
        );
        if (params !== null) {
          resp.isFilter = true;
        } else {
          resp.isFilter = false;
        }
        setValues(resp);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  const isValueOfThePeriodRelatedToTheAction =
    values?.data?.total_periodos?.map((item) => item.total_acao);
  const isValueOfThePeriodRelatedToTheFII = values?.data?.total_periodos?.map(
    (item) => item.total_fii
  );
  const isValueOfThePeriodRelatedToTheTotal = values?.data?.total_periodos?.map(
    (item) => item.total
  );

  const isTotalAmountReceivedInThePeriod = transformMaskCurrency(
    values.data.total_periodos.reduce((acc, value) => acc + value.total, 0)
  );

  const reducer = (acc: IPropsTotalPeriodos[], curr: IPropsTotalPeriodos) => {
    const ano = Number(curr.period.toString().substring(0, 4));
    const valorAction = curr.total_acao;
    const valorFii = curr.total_fii;
    const total = curr.total;

    const existingYear = acc.find(
      (item) => Number(item.period.toString().substring(0, 4)) === ano
    );
    if (existingYear) {
      existingYear.total_acao += valorAction;
      existingYear.total_fii += valorFii;
      existingYear.total += total;
    } else {
      acc.push({
        ...curr,
        period: ano,
        total_acao: valorAction,
        total_fii: valorFii,
        total,
      });
    }

    return acc;
  };

  const totalAnnual = values?.data?.total_periodos?.reduce(reducer, []);

  const graphicData = {
    series: [
      {
        name: 'Ação',
        type: 'column',
        data: isValueOfThePeriodRelatedToTheAction,
      },
      {
        name: 'Fii',
        type: 'column',
        data: isValueOfThePeriodRelatedToTheFII,
      },
      {
        name: 'Total',
        type: 'line',
        data: isValueOfThePeriodRelatedToTheTotal,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        stacked: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [4, 4, 4],
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      colors: [
        'var(--graphic-action)',
        'var(--graphic-fii)',
        'var(--graphic-total)',
      ],
      legend: {
        position: 'bottom',
        horizontalAlign: 'right',
      },
      xaxis: {
        categories: values?.data?.total_periodos?.map((item) =>
          item.period.toString()
        ),
        labels: {
          formatter: function (value: string) {
            if (value) {
              let year = value?.substring(0, 4);
              let month = value?.substring(4, 6);
              return `${month}/${year}`;
            }
          },
        },
      },
      yaxis: {
        labels: {
          formatter: function (value: number) {
            if (showActive) {
              return transformMaskCurrency(value);
            } else {
              return 'R$ *****';
            }
          },
        },
      },

      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val: number) {
            if (showActive) {
              return transformMaskCurrency(val);
            } else {
              return 'R$ *****';
            }
          },
        },
      },
    },
  };
  const graphicDataYear = {
    series: [
      {
        name: 'Ação',
        type: 'column',
        data: totalAnnual.map((item) => item.total_acao),
      },
      {
        name: 'Fii',
        type: 'column',
        data: totalAnnual.map((item) => item.total_fii),
      },
      {
        name: 'Total',
        type: 'line',
        data: totalAnnual.map((item) => item.total),
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        stacked: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [4, 4, 4],
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      colors: [
        'var(--graphic-action)',
        'var(--graphic-fii)',
        'var(--graphic-total)',
      ],
      legend: {
        position: 'bottom',
        horizontalAlign: 'right',
      },
      xaxis: {
        categories: totalAnnual.map((item) => item.period),
        // labels: {
        //   formatter: function (value: string) {
        //     if (value) {
        //       let year = value?.substring(0, 4);
        //       let month = value?.substring(4, 6);
        //       return `${month}/${year}`;
        //     }
        //   },
        // },
      },
      yaxis: {
        labels: {
          formatter: function (value: number) {
            if (showActive) {
              return transformMaskCurrency(value);
            } else {
              return 'R$ *****';
            }
          },
        },
      },

      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val: number) {
            if (showActive) {
              return transformMaskCurrency(val);
            } else {
              return 'R$ *****';
            }
          },
        },
      },
    },
  };

  const returnSelectValue = (
    id: string,
    arr: {
      value: string;
      label: string;
    }[]
  ) => {
    if (id) {
      let obj = arr?.map((item) => {
        if (item?.value === id) {
          return {
            value: item.value,
            label: item.label,
          };
        }
      });
      return obj;
    } else {
      return null;
    }
  };


  return (
    <ContainerAll>
      <ContentData>
        {window.screen.width > 760 && (
          <AsideFiltersProventosRecebidos
            setTypeGraphic={setTypeGraphic}
            typeGraphic={typeGraphic}
            filterData={filterData}
            filtersSelecteds={filtersSelecteds}
            returnSelectValue={returnSelectValue}
            selectDateFilter={selectDateFilter}
            setFiltersSelecteds={setFiltersSelecteds}
            setSelectDateFilter={setSelectDateFilter}
          />
        )}

        <ContainerProventosRecebidos>
          {loading ? (
            <EarningReceivedLoader />
          ) : !user.automatico ? (
            <LinkB3Page />
          ) : values?.isFilter ? (
            <Container>
              <Row>
                <CardValue>
                  <span>Valor recebido:</span>
                  <h5>
                    {showActive ? isTotalAmountReceivedInThePeriod : 'R$ ******'}
                  </h5>
                </CardValue>
                <CardValue>
                  <span>Dividendo:</span>
                  <h5>
                    {showActive
                      ? transformMaskCurrency(
                          Number(
                            valuesSeparatedByType.div
                              .reduce(
                                (acc, value: any) => acc + value.operationValue,
                                0
                              )
                              .toFixed(2)
                          )
                        )
                      : 'R$ ******'}
                  </h5>
                </CardValue>
                <CardValue>
                  <span>JCP:</span>
                  <h5>
                    {showActive
                      ? transformMaskCurrency(
                          Number(
                            valuesSeparatedByType.jcp
                              .reduce(
                                (acc, value: any) => acc + value.operationValue,
                                0
                              )
                              .toFixed(2)
                          )
                        )
                      : 'R$ ******'}
                  </h5>
                </CardValue>
                <CardValue>
                  <span>Rendimento:</span>
                  <h5>
                    {showActive
                      ? transformMaskCurrency(
                          Number(
                            valuesSeparatedByType.rend
                              .reduce(
                                (acc, value: any) => acc + value.operationValue,
                                0
                              )
                              .toFixed(2)
                          )
                        )
                      : 'R$ ******'}
                  </h5>
                </CardValue>
              </Row>
              {window.screen.width <= 760 && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: '1rem',
                  }}
                >
                  <button
                    onClick={() => setShowModalFilter(true)}
                    className="filterButton"
                  >
                    Filtros
                  </button>
                </div>
              )}
              {values?.data?.rendimentos.length &&
              values?.data?.total_periodos.length > 0 ? (
                <>
                  {filtersSelecteds.ativo ? (
                    <Content>
                      <GraphicFilter
                        activeFilter={filtersSelecteds.ativo}
                        dataGraphic={values.data.total_periodos}
                        typeGraphic={typeGraphic}
                      />
                    </Content>
                  ) : (
                    <Content>
                      {typeGraphic === 'month' ? (
                        <ReactApexChart
                          // @ts-ignore
                          options={graphicData.options}
                          series={graphicData.series}
                          type="line"
                          height={350}
                        />
                      ) : (
                        <ReactApexChart
                          // @ts-ignore
                          options={graphicDataYear.options}
                          series={graphicDataYear.series}
                          type="line"
                          height={350}
                        />
                      )}
                    </Content>
                  )}
                  <TableProfitability
                    // @ts-ignore
                    income={values?.data?.rendimentos}
                    ASC={(newValue: any) =>
                      setValues({
                        ...values,
                        data: {
                          ...values.data,
                          rendimentos: newValue,
                        },
                      })
                    }
                    // @ts-ignore
                    DESC={(newValue) =>
                      setValues({
                        ...values,
                        data: {
                          ...values.data,
                          rendimentos: newValue,
                        },
                      })
                    }
                  />
                </>
              ) : (
                <Content style={{ padding: '1rem' }}>
                  <h3 style={{ margin: 0 }}>
                    Não encontramos dados com esses filtros
                  </h3>
                </Content>
              )}
              
              <SideModal
                id="filtros"
                setState={setShowModalFilter}
                isOpen={showModalFilter}
              >
                <AsideFiltersProventosRecebidos
                  setTypeGraphic={setTypeGraphic}
                  typeGraphic={typeGraphic}
                  filterData={filterData}
                  filtersSelecteds={filtersSelecteds}
                  returnSelectValue={returnSelectValue}
                  selectDateFilter={selectDateFilter}
                  setFiltersSelecteds={setFiltersSelecteds}
                  setSelectDateFilter={setSelectDateFilter}
                />
                <br />
                <br />
                <br />
                <br />
                <br />
              </SideModal>
            </Container>
          ) : (
            <NoDataB3 />
          )}
        </ContainerProventosRecebidos>
      </ContentData>
    </ContainerAll>
  );
}

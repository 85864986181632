import styled from 'styled-components';

export const BusinessContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
  width: 100%;
  align-items: center;

  .leftLogo {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .rightLogo {
    position: absolute;
    right: 0;
    top: -3rem;
  }
  .rightLogoBottom {
    position: absolute;
    right: 0;
    bottom: 0;
  }
`;

export const BusinessContent = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 66.25rem;
  scroll-behavior: smooth;
  margin: 0 1rem;
  height: 100%;
  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`;

interface IPropsRegisterContent {
  isShow: boolean;
}
export const ContentRegisterOverLay = styled.div<IPropsRegisterContent>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;

  transform: ${(props) =>
    props.isShow ? 'translateX(0)' : 'translateX(120vw)'};
  transition: transform 0.5s;
`;

import styled from 'styled-components';

export const CDIContainer = styled.form`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme['shadow-2']};
  padding: 1rem;
  border-radius: 4px;
  gap: 1rem;

  label {
    margin: 0;
    color: ${props => props.theme['gray-800']};
    font-size: .875rem;
    font-weight: 500;
  }
`;

import { useClearCacheCtx } from 'react-clear-cache';
import { ButtonComponent } from '../../../../../components/ButtonComponent';
import {
  NewContainer,
  NewContent,
  NewList,
  NewParagraph,
  NewSubTitle,
  NewTitle,
} from './styles';
import { whatsAppLinkApi } from '../../../../../utils/apiWhatsapp';

interface IPropsNews {
  isComponent?: boolean;
}

export function NewsComponent({ isComponent = false }: IPropsNews) {
  const { emptyCacheStorage } = useClearCacheCtx();

  return (
    <NewContainer>
      <NewContent>
        {/* <NewTitle>
          ⚠️ Último dia! Talvez essa oportunidade nunca mais aconteça ⚠️
        </NewTitle> */}
        <br />
        <NewParagraph>
          O servidor da B³ passou por uma instabilidade. Se a sua carteira não
          estiver sendo exibida corretamente, refaça a integração com a B³ e
          aguarde alguns minutos.
        </NewParagraph>
        <br />
        <NewParagraph>
          Estamos à disposição para ajudar! 
          <br /> <strong>Equipe iBox6</strong>
        </NewParagraph>
      </NewContent>
      {/* <NewContent>
        <NewTitle>Bugfix</NewTitle>

        <NewSubTitle>Carteira</NewSubTitle>
        <NewList>
          <li>Agora, o cálculo de Lucro/Prejuízo foi corrigido</li>
        </NewList>
      </NewContent> */}

      {/* {!isComponent && (
        <>
          <br />
          <div>
            <ButtonComponent
              onClick={() => {
                emptyCacheStorage();
                window.location.reload();
              }}
            >
              QUERO ATUALIZAR!
            </ButtonComponent>
          </div>
        </>
      )} */}
    </NewContainer>
  );
}

import axios from 'axios';
import { CaretRight, Check } from '@phosphor-icons/react';
import { useState } from 'react';
import { Button } from '../../../../../components/styles';

import { useTitleAndUser } from '../../../../../hooks/userTitle';
import { B3 } from '../../../../../utils/RoutesApi';
import { Container, Content } from './styles';
import { FinishAccountForm } from '../../../../authRoutes/components/FinishAccountForm';

interface IPropsLinkAccountB3 {
  onCloseModal: Function;
}

export function LinkAccountB3({ onCloseModal }: IPropsLinkAccountB3) {
  const getDataUser = JSON.parse(
    //@ts-ignore
    localStorage.getItem(process.env.REACT_APP_USER_VERSION)
  );
  //@ts-ignore
  const { setUser, user } = useTitleAndUser();

  const benefitsOfConnectingTithB3 = [
    'Movimentações à partir de 11/2019',
    'Evolução da carteira patrimonial',
    'Calendário de Proventos da Carteira',
    'Acompanhamento das posições diariamente',
  ];

  function handleAutomaticImports() {
    let obj = {
      carteira: 'requisitado',
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/api${B3.LINK_B3}`, obj)
      .then((response) => {
        // handleToast(false, response.data.message);

        let newUser = {
          ...response.data.user,
          data_trial: getDataUser.dias_trial,
          step: 3,
        };

        if (newUser.profile.image) {
          const isValidProfileImage = !!newUser.profile.image.match(
            'https://storage.googleapis.com/ibox6-wordpress.appspot.com/'
          );
          if (!isValidProfileImage) {
            newUser.profile.image =
              process.env.REACT_APP_API_URL + newUser.profile.image;
          }
        }
        if (newUser.profile.banner) {
          const isValidProfileBanner = !!newUser.profile.banner.match(
            'https://storage.googleapis.com/ibox6-wordpress.appspot.com/'
          );
          if (!isValidProfileBanner) {
            newUser.profile.banner =
              process.env.REACT_APP_API_URL + newUser.profile.banner;
          }
        }

        let obj = {
          ...getDataUser,
          user: newUser,
        };

        localStorage.setItem(
          //@ts-ignore
          process.env.REACT_APP_USER_VERSION,
          JSON.stringify(obj)
        );
        setUser(obj.user);

        window.open(
          'https://b3Investidor.b2clogin.com/b3Investidor.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_FINTECH&client_id=a23f01ef-4a25-4f39-8b37-2de0bf18d4a7&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fwww.investidor.b3.com.br&scope=openid&response_type=code&prompt=login '
        );
        onCloseModal();
        return;
      })
      .catch((err) => {
        return;
      });
  }

  const needCPF = user.cpf === 'new cpf';

  return needCPF ? (
    <FinishAccountForm isB3Link />
  ) : (
    <Container>
      <Content>
        <header>
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/d/d7/B3_logo.png"
            alt="logo b3"
          />
        </header>

        <main>
          <strong>Benefícios da conexão:</strong>
          <ul>
            {benefitsOfConnectingTithB3.map((item, index) => (
              <li key={index}>
                <Check size={20} color="var(--primary)" weight="bold" /> {item}
              </li>
            ))}
          </ul>
        </main>

        <main style={{ marginTop: '1.5rem' }}>
          <strong>Importante:</strong>

          <p>
            Após concluir a vinculação com a sua conta B3, a validação do nosso
            servidor pode levar cerca de 10 minutos. Você pode continuar
            utilizando a plataforma normalmente. Assim que for validado, uma
            notificação aparecerá informando que o processo foi concluído.
          </p>
          <a
            href="https://ibox6.com.br/conexao-com-a-b3/"
            target="_blank"
            className="guia"
          >
            Guia para vincular conta <CaretRight weight="bold" />
          </a>
        </main>
        <footer>
          <a
            href="https://b3investidor.b2clogin.com/b3Investidor.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_SUSI&client_id=711b0677-3672-4464-b183-76734fb21907&response_type=code%20id_token&redirect_uri=https://www.investidor.b3.com.br&response_mode=query&scope=openid%20https://b3Investidor.onmicrosoft.com/802348e6-6f23-4d99-9445-45836861cdf4/Read.All&prompt=login&state=CZrC6CIM82fjH3ca4jVjv2vscix9uvTsg39OwBkL&nonce=UNP6DWMpUbGB2TcHZVR1prGsJqDPd4ypRhBpXZOf&code_challenge=726Hvj2FzTTRG5zCO2TwJVh_VNSEHYm4Rchocm1R9uU&code_challenge_method=S256&generationTime=2022-07-19-14-18-03"
            target="_blank"
          >
            <Button
              type="button"
              //@ts-ignore
              background="#333333"
              color="#FFF"
            >
              Cadastrar conta B3
            </Button>
          </a>

          <Button
            //@ts-ignore
            background="var(--primary)"
            color="#FFF"
            onClick={handleAutomaticImports}
          >
            Vincular agora
          </Button>
        </footer>
      </Content>
    </Container>
  );
}

import styled from 'styled-components';

interface IPropsStyledButton {
  variant: 'primary' | 'secondary' | 'link';
}

export const Container = styled.button<IPropsStyledButton>`
  display: flex;
  padding: 0.875rem 1rem;
  gap: 0.25rem;
  color: ${(props) =>
    props.variant === 'primary'
      ? props.theme.white
      : '#EC6643'};
  background: ${(props) =>
    props.variant === 'primary' ? '#EC6643' : '#fff'};
  border: ${(props) =>
    props.variant !== 'secondary'
      ? 'none'
      : `1px solid ${props.theme['primary-800']}`};
  border-radius: 4px;
  font: 500 1rem 'Poppins', sans-serif;
  cursor: pointer;
  transition: filter 0.3s;
  text-decoration: none;

  :hover {
    filter: brightness(0.9);
  }

  :disabled {
    filter: brightness(0.7);
    cursor: not-allowed;
  }
`;

import { useForm } from 'react-hook-form';
import { ContainerFormQuestion, ContentInput } from './styles';
import { ButtonComponent } from '../../../../../../components/ButtonComponent';
import { handleToast } from '../../../../../../components/Toast';
import axios from 'axios';
import { administrationController } from '../../../../../../utils/RoutesApi';

const baseUrl = process.env.REACT_APP_API_URL;

interface IPropsFormQuestion {
  onAddNewQuestion: Function;
}

export function FormQuest({ onAddNewQuestion }: IPropsFormQuestion) {
  const {
    handleSubmit,
    register,
    watch,
    formState: { isSubmitting },
  } = useForm();

  const amountLetters = watch('question_text');

  async function handleCreateNewQuestion(data: any) {
    data.pub_date = new Date();

    const { question_text, question_type, pub_date } = data;

    if (!question_text) {
      return handleToast(true, 'Escreva a pergunta');
    }

    await axios
      .post(`${baseUrl}${administrationController.QUESTIONS_FEEDBACK}/`, {
        question_text,
        question_type,
        pub_date,
      })
      .then((response) => {
        onAddNewQuestion(response.data);
        handleToast(false, 'Pergunta cadastrada com sucesso');
      })
      .catch((err) => {
      
      });
  }

  return (
    <ContainerFormQuestion onSubmit={handleSubmit(handleCreateNewQuestion)}>
      <ContentInput>
        <label>Qual é a pergunta?</label>
        <textarea {...register('question_text')} />
        <span>{amountLetters ? amountLetters.length + '/200' : '0/200'}</span>
      </ContentInput>
      <ContentInput>
        <label>Qual é o tipo da pergunta?</label>
        <select defaultValue="text" {...register('question_type')}>
          <option value="text">Texto</option>
          <option value="rating">Estrela</option>
        </select>
      </ContentInput>
      <div>
        <ButtonComponent type="submit" disabled={isSubmitting}>
          Criar
        </ButtonComponent>
      </div>
    </ContainerFormQuestion>
  );
}

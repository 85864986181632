import styled from 'styled-components';

export const NewContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme['gray-700']};
`;

export const NewContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

export const NewTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 160%;
`;
export const NewSubTitle = styled.h2`
  font-size: 1.2rem;
  font-weight: 600;
`;

export const NewParagraph = styled.p`
  font-size: 1rem;
  margin-bottom: 0.5rem;
  line-height: 130%;

  a {
    color: ${(props) => props.theme['primary-800']};
    cursor: pointer;
  }
`;

export const NewList = styled.ul`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  li {
    list-style: disc;
    font-size: 1rem;
  }
`;

import ScrollContainer from 'react-indiana-drag-scroll';
import styled from 'styled-components';

export const IncomeTaxPagesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentTab = styled(ScrollContainer)`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: space-between;
  overflow: auto;
  white-space: nowrap;
`;

interface IPropsButtonTab {
  isActive: boolean;
}

export const ButtonTab = styled.button<IPropsButtonTab>`
  display: flex;
  align-items: center;
  gap: 0;


  all: unset;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 8px 8px 0px 0px;
  border-top: ${(props) =>
    props.isActive ? '4px solid #EC6643' : '1px solid #D4D6DB'};
  border-right: 1px solid var(--Gray-gray-100, #D4D6DB);
  border-left: 1px solid var(--Gray-gray-100, #D4D6DB);
  border-bottom: 1px solid var(--Gray-gray-100, #D4D6DB);


  background: ${(props) =>
    props.isActive ? '#FFF' : '#FCFCFD'};
  color: ${(props) =>
    props.isActive ? '#EC6643' : '#6A717E'};
  font-weight: 500;
  font-size: 1.125rem;
`;

export const ContentPages = styled.div`
  background: ${(props) => props.theme.white};
  padding: 1rem;
`;

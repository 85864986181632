import { HTMLAttributes } from 'react';
import { Container } from './styles';

interface IPropsTabComponent extends HTMLAttributes<HTMLDivElement> {
  title: string;
  icon: JSX.Element;
  active: boolean;
}

export function TabComponent({
  title,
  icon,
  active = false,
  ...props
}: IPropsTabComponent) {
  return (
    <Container {...props} active={active}>
      {icon}
      <p>{title}</p>
    </Container>
  );
}

import {
  Button,
  ButtonContent,
  HeaderContainer,
  HeaderContent,
} from './styles';

import { MenuMobile } from './MenuMobile';
import { useHistory } from 'react-router-dom';
import { logoWhiteImg } from '../../../../../Constants/images';

interface IPropsScreen {
  onShowRegisterScreen: () => void;
}

export function Header({ onShowRegisterScreen }: IPropsScreen) {
  const history = useHistory();

  return (
    <HeaderContainer>
      <HeaderContent>
        <div className="navContent">
          <img src={logoWhiteImg} alt="" />
          <nav>
            {window.screen.width <= 768 && (
              <MenuMobile onShowRegisterScreen={onShowRegisterScreen} />
            )}

            <ul className="listNav">
              <li>
                <a href="#comunidade">Comunidade</a>
              </li>
              <li>
                <a href="#educacao">Educação</a>
              </li>
              <li>
                <a href="#plataforma">Plataforma</a>
              </li>
            </ul>
          </nav>
        </div>
        <ButtonContent>
          <Button isLoginButton onClick={() => history.push('/login')}>
            Login
          </Button>
          <Button type="button" onClick={onShowRegisterScreen}>
            Acesse gratis
          </Button>
        </ButtonContent>
      </HeaderContent>
    </HeaderContainer>
  );
}

import styled from 'styled-components';

export const UseOfTermsContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

interface IPropsInputContent {
  isShort?: boolean;
}

export const InputContent = styled.div<IPropsInputContent>`
  display: flex;
  gap: 0.5rem;
  align-items: baseline;
  /* flex-direction: column; */

  label {
    color: ${(props) => props.theme['gray-700']};
    font-weight: 500;
    margin: 0;
  }
  input {
    padding: 0.5rem;
    border-radius: 4px;
    color: ${(props) => props.theme['gray-700']};
    border: ${(props) => props.theme.border};

    max-width: ${(props) => (props.isShort ? '30rem' : 'none')};
  }
`;

import { useEffect, useState } from 'react';
import { InputComponent } from '../../../../../../components/InputComponent';
import { ContentInput, FormEditJurosContainer } from './styles';
import { ContentFilterDateAndTitle, MonthlyInputContent } from '../../styles';

interface IPropsFormEditJuros {
  amount: string;
  editTotalAmount: Function;
  jurosRendaPassiva: number;
  setJurosRendaPassiva: Function;
  typeJurosRenda: 'year' | 'monthly';
  setTypeJurosRenda: Function;
  rendaPassivaValue: string;
}

export function FormEditJuros({
  amount,
  editTotalAmount,
  jurosRendaPassiva,
  setJurosRendaPassiva,
  setTypeJurosRenda,
  typeJurosRenda,
  rendaPassivaValue,
}: IPropsFormEditJuros) {
  const newAmount = amount.replace(/\D/g, '').replace(/(\d)(\d{2})$/, '$1.$2');
  const [totalAcumulado, setTotalAcumulado] = useState<string>(
    newAmount
      .toString()
      .replace(/\D/g, '')
      .replace(/(\d)(\d{2})$/, '$1,$2')
      .replace(/(?=(\d{3})+(\D))\B/g, '.')
  );

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      editTotalAmount(
        totalAcumulado.replace(/\D/g, '').replace(/(\d)(\d{2})$/, '$1.$2')
      );
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [totalAcumulado]);

  const handleInputChange = (event: any) => {
    const rawValue = event.target.value;

    // Remover caracteres não numéricos
    const numericValue = rawValue.replace(/[^\d]/g, '');

    // Adicionar ponto decimal da direita para a esquerda
    let formattedValue = numericValue;
    if (numericValue.length > 2) {
      const lastTwoDigits = numericValue.slice(-2);
      const remainingDigits = numericValue.slice(0, -2);
      if (remainingDigits === '00') {
        formattedValue = '0.' + lastTwoDigits;
      } else {
        formattedValue =
          remainingDigits.replace(/^0+/, '') + '.' + lastTwoDigits;
      }
    } else if (numericValue.length === 2 && numericValue[0] !== '0') {
      formattedValue = '0.' + numericValue;
    } else if (numericValue.length === 1) {
      formattedValue = '0.0' + numericValue;
    } else {
      formattedValue = '0.00';
    }

    // Atualizar o estado
    setJurosRendaPassiva(formattedValue);
  };

  return (
    <FormEditJurosContainer>
      <p>Qual o valor da taxa de juros dos seus investimentos?</p>
      {/* <ContentInput>
        <label htmlFor="">Montante</label>
        <InputComponent
          value={totalAcumulado
            .replace(/\D/g, '')
            .replace(/(\d)(\d{2})$/, '$1,$2')
            .replace(/(?=(\d{3})+(\D))\B/g, '.')}
          onChange={(e) =>
            setTotalAcumulado(
              e.target.value
                .replace(/\D/g, '')
                .replace(/(\d)(\d{2})$/, '$1,$2')
                .replace(/(?=(\d{3})+(\D))\B/g, '.')
            )
          }
        />
      </ContentInput> */}
      {/* <ContentInput> */}
      <ContentFilterDateAndTitle>
        <MonthlyInputContent>
          <input
            value={jurosRendaPassiva}
            type="number"
            onChange={handleInputChange}
          />

          <span>%</span>
        </MonthlyInputContent>
        <select
          value={typeJurosRenda}
          onChange={(e) => setTypeJurosRenda(e.target.value)}
        >
          <option value="monthly">Mensal</option>
          <option value="year">Anual</option>
        </select>
      </ContentFilterDateAndTitle>

      <div className="contentValue">
        <h4>Montante</h4>
        <span>
          R$ <strong>{totalAcumulado.replace('R$', '')}</strong>
        </span>
      </div>
      <div className="contentValue">
        <h4>Renda mensal</h4>
        <span>
          R$ <strong>{rendaPassivaValue.replace('R$', '')}</strong>
        </span>
      </div>

      {/* <input
          type="text"
          onChange={handleInputChange}
          placeholder="0.8"
          type
        />
        <InputComponent
          value={jurosRendaPassiva}
          onChange={handleInputChange}
          suffix={
            <div
              style={{ display: 'flex', alignItems: 'center', gap: '.25rem' }}
            >
              %
              <select
                value={typeJurosRenda}
                onChange={(e) => setTypeJurosRenda(e.target.value)}
              >
                <option value="year">Ano</option>
                <option value="monthly">Mês</option>
              </select>
            </div>
          }
        />

      </ContentInput> */}
    </FormEditJurosContainer>
  );
}

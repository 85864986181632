import axios from 'axios';
import { createContext, useState, useEffect } from 'react';
// import { defaultUserData } from '../utils/defaultDataUser';
import { userRoutes } from '../utils/RoutesApi';

const ONE_MINUTE_COUNTER = 1000 * 60 * 1;

export const AuthContext = createContext({});
export const AuthProvider = (props) => {
  const getDataUser = JSON.parse(
    localStorage.getItem(process.env.REACT_APP_USER_VERSION)
  );
  const [permissions, setPermissions] = useState(getDataUser.UserType ?? []);

  const [user, setUser] = useState(
    // defaultUserData

    {
      ...getDataUser.user,
      data_trial: getDataUser.dias_trial,
    }
  );

  const [title, setTitle] = useState('');
  const [notifyCount, setNotifyCount] = useState(0);

  // useEffect(() => {
  //   getDataUSer();
  // }, []);
  // async function getDataUSer() {
  //   await axios
  //     .get(
  //       `${process.env.REACT_APP_API_URL}${userRoutes.USER_REFRESH_PERMISSIONS}`
  //     )
  //     .then((response) => {

  //       setUser(response.data.user);
  //     })
  //     .catch((err) => {
  //     });
  // }

  useEffect(() => {
    let obj = {
      ...getDataUser,
      user: user,
    };
    if (obj) {
      localStorage.setItem(
        process.env.REACT_APP_USER_VERSION,
        JSON.stringify(obj)
      );
    }
  }, [user]);
  useEffect(() => {
    const interval = setInterval(async () => {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}${userRoutes.USER_REFRESH_PERMISSIONS}`
        )
        .then((response) => {
          if (
            getDataUser.user.status_pagamento !==
            response.data.user.status_pagamento
          ) {
            const newUser = {
              ...getDataUser.user,
              status_pagamento: response.data.user.status_pagamento,
            };
            localStorage.setItem(
              process.env.REACT_APP_USER_VERSION,
              JSON.stringify({ ...getDataUser, user: newUser })
            );
          }
          if (permissions.length !== response.data.UserType.length) {
            setPermissions(response.data.UserType);
            localStorage.setItem(
              process.env.REACT_APP_USER_VERSION,
              JSON.stringify({
                ...getDataUser,
                UserType: response.data.UserType,
              })
            );
          }
        })
        .catch((err) => {
        });
    }, ONE_MINUTE_COUNTER);

    return () => {
      clearInterval(interval);
    };
  }, [user]);

  async function updateUser(newDataUser) {
    let newUser = newDataUser;
    if (newUser) {
      if (newUser.profile.image) {
        const isValidProfileImage = !!newUser.profile.image.match(
          'https://storage.googleapis.com/ibox6-wordpress.appspot.com/'
        );
        if (!isValidProfileImage) {
          newUser.profile.image =
            process.env.REACT_APP_API_URL + newUser.profile.image;
        }
      }
      if (newUser.profile.banner) {
        const isValidProfileBanner = !!newUser.profile.banner.match(
          'https://storage.googleapis.com/ibox6-wordpress.appspot.com/'
        );
        if (!isValidProfileBanner) {
          newUser.profile.banner =
            process.env.REACT_APP_API_URL + newUser.profile.banner;
        }
      }

      return newUser;
    }
    return newUser;
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        title,
        setTitle,
        setNotifyCount,
        notifyCount,
        updateUser,
        permissions,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

import ReactApexChart from 'react-apexcharts';
import { IPropsCountTypePlatform } from '../../@types';
import { ContentDataLabel, GraphicTypePlatformContainer } from './styles';
import { Desktop, DeviceMobile, DeviceTablet } from '@phosphor-icons/react';
import { Heading } from '../../../../../../components/DefaultStylesTypography';
import ReactLoading from 'react-loading';

interface IPropsGraphicTypePlatform {
  data: IPropsCountTypePlatform;
  loading: boolean;
}

export function GraphicTypePlatform({
  data,
  loading,
}: IPropsGraphicTypePlatform) {
  const { desktop, mobile, tablet } = data.data;
  const graphicData = {
    series: [desktop, mobile, tablet],
    options: {
      chart: {
        type: 'donut',
      },
      legend: {
        show: false,
      },
      labels: ['Desktop', 'Mobile', 'Tablet'],
      colors: ['#0176E1', '#23E06E', '#FFC225'],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  };
  const total = desktop + mobile + tablet;

  return (
    <GraphicTypePlatformContainer>
      <Heading as="h4" variant="quaternary" color="white">
        Acesso por dispositivo
      </Heading>
      {loading ? (
        <ReactLoading
          type="balls"
          width={200}
          height={200}
          color="var(--primary)"
        />
      ) : (
        <>
          <ReactApexChart
            //@ts-ignore
            options={graphicData.options}
            series={graphicData.series}
            type="donut"
          />
          <ContentDataLabel>
            <span style={{ color: '#0176E1' }}>
              Desktop {`(${((desktop * 100) / total).toFixed(2)}%)`}{' '}
              <Desktop weight="bold" />
            </span>
            <span style={{ color: '#23E06E' }}>
              Mobile {`(${((mobile * 100) / total).toFixed(2)}%)`}{' '}
              <DeviceMobile weight="bold" />
            </span>
            <span style={{ color: '#FFC225' }}>
              Tablet {`(${((tablet * 100) / total).toFixed(2)}%)`}{' '}
              <DeviceTablet weight="bold" />
            </span>
          </ContentDataLabel>
        </>
      )}
    </GraphicTypePlatformContainer>
  );
}

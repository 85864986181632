import { Tooltip } from 'antd';

import { BiInfoCircle } from 'react-icons/bi';

const HelpText = ({ children }) => {
  return (
    <Tooltip title={children} placement="bottom">
      <BiInfoCircle size={20} color="black" />
    </Tooltip>
  );
};

export default HelpText;

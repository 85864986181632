import axios from 'axios';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useTitleAndUser } from '../../../hooks/userTitle';
import { userRoutes } from '../../../utils/RoutesApi';
import { ButtonComponent } from '../../ButtonComponent';
import { handleToast } from '../../Toast';
import { InputContent, UseOfTermsContainer } from './styles';

interface IPropsUseOfTerms {
  onCloseModal: Function;
}

export function UseOfTerms({ onCloseModal }: IPropsUseOfTerms) {
  //@ts-ignore
  const { user, setUser } = useTitleAndUser();
  const {
    handleSubmit,
    setValue,
    register,
    formState: { isSubmitting },
  } = useForm();

  useEffect(() => {
    if (user.ddd && user.telefone) {
      setValue('cel', user.ddd + user.telefone);
    }
  }, []);

  async function submit(data: any) {
    const { termsofuse } = data;
    if (!termsofuse) {
      return handleToast(true, 'Você precisa aceitar os termos de uso');
    }

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}${userRoutes.USER_UPDATE_PROFILE}`,
        {
          termsofuse,
        }
      );

      setUser({
        ...user,
        termsofuse,
      });
      onCloseModal();
    } catch (error) {
      const err: any = error;
      //@ts-ignore
      setLoading(false);
    } finally {
    }
  }

  return (
    <UseOfTermsContainer onSubmit={handleSubmit(submit)}>
      <iframe
        src="https://ibox6.com.br/politica-de-privacidade/"
        style={{ width: '60vw' }}
        height="400px"
      ></iframe>
      <InputContent isShort>
        <input type="checkbox" {...register('termsofuse')} />
        <label>Declaro que li e aceito os termos de uso</label>
      </InputContent>
      <div>
        <ButtonComponent type="submit" disabled={isSubmitting}>
          Enviar
        </ButtonComponent>
      </div>
    </UseOfTermsContainer>
  );
}

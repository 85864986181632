import styled, { css } from 'styled-components';

export const AboutCompanyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* padding: 0.5rem;
  border-radius: 4px;
  box-shadow: ${(props) => props.theme['card-shadow-2']};
  background: ${(props) => props.theme.white}; */
`;

interface IPropsTextAboutCompany {
  showText?: boolean;
}

export const TextAboutCompany = styled.div<IPropsTextAboutCompany>`
  margin: 0;
  color: ${(props) => props.theme['gray-700']};
  font-size: ${(props) => (props.showText ? '1rem' : 0)};
  height: ${(props) => (props.showText ? 'auto' : 0)};
  margin-top: ${(props) => (props.showText ? '0.5rem' : 0)};
  transition: all 0.5s;

  div {
    height: 0;
    margin: 0;
  }

  span {
    font-size: 0;
    margin: 0;
  }

  h1 {
    font-size: 0;
    margin: 0;
  }
  h2,
  h3 {
    font-size: 0;
    margin: 0;
  }
  p {
    font-size: 0;
    margin: 0;
  }

  table {
    font-size: 0;
    margin: 0;
  }

  ${(props) =>
    props.showText &&
    css`
      div {
        height: auto;
      }

      span {
        font-size: 1rem;
      }
      h1 {
        font-size: 1.5rem;
        font-weight: bold;
        color: ${(props) => props.theme['gray-700']};
        margin-bottom: 0.5rem;
      }
      h2,
      h3 {
        font-size: 1.2rem;
        font-weight: bold;
        color: ${(props) => props.theme['gray-700']};
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
      }
      p {
        font-size: 1rem;
      }

      table {
        font-size: 1rem;
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 0.5rem;
        overflow: auto;
        min-width: 500px;

        thead,
        tbody {
          align: start;
        }
        th {
          padding: 0.25rem;
        }

        td {
          padding: 0.25rem;
        }
      }
    `}
`;

export const LoadingContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;

  strong {
    color: ${(props) => props.theme['gray-700']};
  }
`;

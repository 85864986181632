import { Dropdown } from 'react-bootstrap';
import { FiMoreVertical } from 'react-icons/fi';

const DropDown = ({ children }) => {
  return (
    <Dropdown as="li" className="nav-item dropdown header-profile">
      <Dropdown.Toggle
        variant=""
        as="a"
        className="nav-link i-false c-pointer"
        href="#"
        role="button"
        data-toggle="dropdown"
      >
        <FiMoreVertical size="1.5rem" />
      </Dropdown.Toggle>

      <Dropdown.Menu
        align="right"
        style={{ width: '200px', borderRadius: '8px' }}
      >
        {children}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropDown;

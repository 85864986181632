import axios from 'axios';
import ReactInputMask from 'react-input-mask';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import cep from 'cep-promise';
import { ContentForm, ContentInput } from './styles';
import { handleToast } from '../../../../../../../components/Toast';
import { baseURL } from '../../../../../../../utils/baseURL';
import { userRoutes } from '../../../../../../../utils/RoutesApi';
import { ButtonDesign } from '../../../../../../../components/ButtonDesign';
import { useTitleAndUser } from '../../../../../../../hooks/userTitle';

export function EditAddressForm() {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { isSubmitting },
  } = useForm();
  // @ts-ignore
  const { user, setUser } = useTitleAndUser();
  //@ts-ignore
  const getDataUser = JSON.parse(
    //@ts-ignore
    localStorage.getItem(process.env.REACT_APP_USER_VERSION)
  );
  useEffect(() => {
    if (user) {
      if (user.zipcode) {
        setValue('zipcode', user.zipcode);
      }
      setValue('cidade', user.cidade);
      setValue('bairro', user.bairro);
      setValue('estado', user.estado);
      setValue('complemento', user.complemento);
      setValue('numero', user.numero);
      setValue('rua', user.rua);
    }
  }, []);

  function handleValidadeAddress(_data: any) {
    if (!_data.zipcode) {
      return handleToast(true, 'Informe o cep');
    }
    if (!_data.estado) {
      return handleToast(true, 'Informe o estado');
    }
    if (!_data.cidade) {
      return handleToast(true, 'Informe a cidade');
    }
    if (!_data.bairro) {
      return handleToast(true, 'Informe o bairro');
    }
    if (!_data.rua) {
      return handleToast(true, 'Informe a rua');
    }
    if (!_data.numero) {
      return handleToast(true, 'Informe o numero');
    }
  }

  //@ts-ignore
  async function submitAddress(data) {
    if (handleValidadeAddress(data)) {
      return;
    }
    const newData = {
      estado: data.estado,
      zipcode: data.zipcode,
      bairro: data.bairro,
      cidade: data.cidade,
      rua: data.rua,
      complemento: data.complemento,
      numero: data.numero,
    };
    await axios
      .patch(`${baseURL}${userRoutes.USER_UPDATE_PROFILE}`, newData)
      .then((response) => {
        handleToast(false, 'Atualizado com sucesso');
        let res = {
          ...response.data,
          data_trial: getDataUser.dias_trial,
          step: 3,
        };
        if (res) {
          let isIncludeImage = false;
          if (res.profile.image) {
            const includeImage = res.profile.image.match(
              'https://storage.googleapis.com/ibox6-wordpress.appspot.com/'
            );

            isIncludeImage = !!includeImage;
          }

          let isIncludeBanner = false;

          if (res.profile.bannet) {
            const includeBanner = res.profile.banner.match(
              'https://storage.googleapis.com/ibox6-wordpress.appspot.com/'
            );

            isIncludeBanner = !!includeBanner;
          }

          if (isIncludeImage || isIncludeBanner) {
            let obj = {
              ...getDataUser,

              user: res,
            };
            localStorage.setItem(
              //@ts-ignore
              process.env.REACT_APP_USER_VERSION,
              JSON.stringify(obj)
            );
            setUser(obj.user);

            return;
          }

          if (res.profile.image) {
            res.profile.image =
              process.env.REACT_APP_API_URL +
              res.profile.image.replace(`${process.env.REACT_APP_API_URL}`, '');
          }
          if (res.profile.banner) {
            res.profile.banner =
              process.env.REACT_APP_API_URL +
              res.profile.banner.replace(
                `${process.env.REACT_APP_API_URL}`,
                ''
              );
          }

          let obj = {
            ...getDataUser,

            user: res,
          };
          localStorage.setItem(
            //@ts-ignore
            process.env.REACT_APP_USER_VERSION,
            JSON.stringify(obj)
          );
          setUser(obj.user);

          return;
        }
      })
      .catch((err) => {
        return handleToast(true, err.response.data.response);
      });
  }

  //@ts-ignore
  async function onBlurCep(e) {
    const value = e.target.value;

    const replaceCep = value.replace(/[^0-9]/g, '');
    if (replaceCep?.length !== 8) {
      return;
    } else {
      cep(replaceCep)
        .then((res) => {
          setValue('bairro', res.neighborhood);
          setValue('cidade', res.city);
          setValue('rua', res.street);
          setValue('estado', res.state);
        })
        .catch((err) => {});
    }
  }

  return (
    <ContentForm onSubmit={handleSubmit(submitAddress)}>
      <ContentInput>
        <label>CEP</label>
        <ReactInputMask
          mask="99999-999"
          type="text"
          placeholder="12345-678"
          {...register('zipcode')}
          onBlur={onBlurCep}
        />
      </ContentInput>
      <ContentInput>
        <label>Estado</label>
        <input type="text" {...register('estado')} />
      </ContentInput>
      <ContentInput>
        <label>Cidade</label>
        <input type="text" {...register('cidade')} />
      </ContentInput>
      <ContentInput>
        <label>Bairro</label>
        <input type="text" {...register('bairro')} />
      </ContentInput>
      <ContentInput>
        <label>Rua</label>
        <input type="text" {...register('rua')} />
      </ContentInput>
      <ContentInput>
        <label>Complemento</label>
        <input type="text" {...register('complemento')} />
      </ContentInput>
      <ContentInput>
        <label>Número</label>
        <input type="text" {...register('numero')} />
      </ContentInput>
      <ButtonDesign
        style={{ width: 'calc(100% - 2rem - 4px)', maxWidth: 'none' }}
        disabled={isSubmitting}
      >
        Atualizar
      </ButtonDesign>
    </ContentForm>
  );
}

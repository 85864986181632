import axios from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Check, Eye, EyeSlash, X } from '@phosphor-icons/react';
import { Tooltip } from 'antd';
import {
  ContainerNewPasswordForm,
  ContentInput,
  InfoValidationPassword,
  ValidationPassword,
} from './styles';
import { ButtonComponent } from '../../../../../../components/ButtonComponent';
import { administrationController } from '../../../../../../utils/RoutesApi';
import { handleToast } from '../../../../../../components/Toast';

interface IPropsNewPasswordForm {
  email: string;
  onCloseModal: Function;
}

export function NewPasswordForm({
  email,
  onCloseModal,
}: IPropsNewPasswordForm) {
  const {
    handleSubmit,
    register,
    watch,
    formState: { isSubmitting },
  } = useForm();
  const [viewPassword, setViewPassword] = useState(false);
  const [viewPasswordConfirm, setViewPasswordConfirm] = useState(false);

  const watchPasswords = watch('password');
  const validateLettersInPassword = !!watchPasswords?.match(/[A-z]/);
  const validateNumberInPassword = !!watchPasswords?.match(/[0-9]/);
  const validateSpecialCharactersInPassword = !!watchPasswords?.match(
    /[!|@|#|$|%|^|&|*|(|)|-|_]/
  );

  async function handleRegisterNewPassword(data: any) {
    const { password, confirmPassword } = data;

    if (!password) {
      return handleToast(true, 'Informe uma senha');
    }
    if (password.length < 8) {
      return handleToast(true, 'a senha deve conter pelo menos 8 caracteres');
    }
    if (!confirmPassword) {
      return handleToast(true, 'Confirme a senha');
    }
    if (password !== confirmPassword) {
      return handleToast(true, 'As senhas são diferentes');
    }
    await axios
      .put(
        `${
          process.env.REACT_APP_API_URL
        }${administrationController.UPDATE_PASSWORD_USER(email)}`,
        {
          password,
        }
      )
      .then((response) => {
        handleToast(false, response.data.detail);
        onCloseModal();
      })
      .catch((err) => {
        handleToast(true, err.response.data.detail);
      });
  }

  return (
    <ContainerNewPasswordForm
      onSubmit={handleSubmit(handleRegisterNewPassword)}
    >
      <ContentInput suffix>
        <i>
          {viewPassword ? (
            <Eye
              onClick={() => setViewPassword(!viewPassword)}
              size={20}
              style={{ cursor: 'pointer' }}
            />
          ) : (
            <EyeSlash
              onClick={() => setViewPassword(!viewPassword)}
              size={20}
              style={{ cursor: 'pointer' }}
            />
          )}
        </i>
        <input
          type={viewPassword ? 'text' : 'password'}
          placeholder="Crie uma senha"
          {...register('password')}
        />
      </ContentInput>
      <ValidationPassword>
        <InfoValidationPassword error={watchPasswords?.length >= 8}>
          8 caracteres{' '}
          {watchPasswords?.length >= 8 ? <X weight="bold" /> : <Check />}
        </InfoValidationPassword>
        <InfoValidationPassword error={validateNumberInPassword}>
          01 número {!validateNumberInPassword ? <X /> : <Check />}
        </InfoValidationPassword>
        <InfoValidationPassword error={validateLettersInPassword}>
          01 letra {!validateLettersInPassword ? <X /> : <Check />}
        </InfoValidationPassword>
        <Tooltip placement="top" title={<strong>! @ # $ % & * ( ) _ </strong>}>
          <InfoValidationPassword error={validateSpecialCharactersInPassword}>
            01 caractere especial{' '}
            {!validateSpecialCharactersInPassword ? <X /> : <Check />}
          </InfoValidationPassword>
        </Tooltip>
      </ValidationPassword>
      <ContentInput suffix>
        <i>
          {viewPasswordConfirm ? (
            <Eye
              onClick={() => setViewPasswordConfirm(!viewPasswordConfirm)}
              size={20}
              style={{ cursor: 'pointer' }}
            />
          ) : (
            <EyeSlash
              onClick={() => setViewPasswordConfirm(!viewPasswordConfirm)}
              size={20}
              style={{ cursor: 'pointer' }}
            />
          )}
        </i>
        <input
          type={viewPasswordConfirm ? 'text' : 'password'}
          placeholder="Confirme sua senha"
          {...register('confirmPassword')}
        />
      </ContentInput>
      <div>
        <ButtonComponent type="submit" disabled={isSubmitting}>
          Trocar senha
        </ButtonComponent>
      </div>
    </ContainerNewPasswordForm>
  );
}

import styled from 'styled-components';

export const GraphicTypePlatformContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${(props) => props.theme['gray-600']};
  padding: 1rem;
`;

export const ContentDataLabel = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  span {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    color: ${(props) => props.theme.primary};
    font-size: 1rem;
    font-weight: 500;
    gap: 0.5rem;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;

import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { Dropdown, Menu } from 'antd';
import { BsLock, BsUnlock } from 'react-icons/bs';
import { IWalletProps } from '../../../utils/@types/wallet';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#FFF',
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export interface IPropsException {
  corretora: string;
  key: string;
  type: string;
  value: number;
}

export interface IPropsActive {
  Exception: IPropsException[];
  ValorExp: number;
  aportarQtde: number;
  closingPrice: number;
  corporationName: string;
  equitiesQuantity: number;
  novoupdateValue: number;
  percent: number;
  tickerSymbol: string;
  updateValue: number;
  reservadeemergencia: boolean;
  productTypeName: string;
}

interface IPrpopsRenderConditionalObective {
  active: IPropsActive;
  exceptions: IPropsException[];
  filterBrokerage: string;
  setRule: Function;
  setShowModalCreateRule: Function;
  walletValues: IWalletProps;
  action?: boolean;
  fii?: boolean;
  typeWallet?: boolean;
}

export function RenderConditionalObjective({
  exceptions = [],
  active,
  filterBrokerage,
  setRule,
  setShowModalCreateRule,
  walletValues,
  action = false,
  fii = false,
  typeWallet = false,
}: IPrpopsRenderConditionalObective) {
  if (fii) {
    const allReadyExistException = exceptions.find(
      (exception) => exception.type === 'fii'
    );

    if (!allReadyExistException) {
      return (
        <StyledTableCell>
          <Dropdown
            overlay={
              <Menu
                style={{
                  maxWidth: '300px',
                }}
                items={[
                  {
                    key: '1',
                    label: <strong>Criar regra de exceção</strong>,
                  },
                  {
                    key: '2',
                    label: (
                      <strong>
                        Você poderá criar para cada ativo da sua carteira um
                        percentual especifico, que entrata como exceção na
                        distribuição automática dos ativos.
                      </strong>
                    ),
                  },
                ]}
              />
            }
            placement="bottom"
          >
            <BsUnlock
              onClick={() => {
                setShowModalCreateRule(true);
                setRule({ ...active, typeEdit: 'fii' });
              }}
              size={20}
              style={{ cursor: 'pointer' }}
            />
          </Dropdown>
          {walletValues.objetivoFII.toFixed(2)}%
        </StyledTableCell>
      );
    } else {
      return (
        <StyledTableCell>
          <Dropdown
            overlay={
              <Menu
                style={{
                  maxWidth: '300px',
                }}
                items={[
                  {
                    key: '1',
                    label: <strong>Editar regra de exceção</strong>,
                  },
                  {
                    key: '2',
                    label: (
                      <strong>
                        Você poderá criar para cada ativo da sua carteira um
                        percentual especifico, que entrata como exceção na
                        distribuição automática dos ativos.
                      </strong>
                    ),
                  },
                ]}
              />
            }
            placement="bottom"
          >
            <BsLock
              onClick={() => {
                setShowModalCreateRule(true);
                setRule({
                  ...active,
                  edit: true,
                  typeEdit: 'fii',
                });
              }}
              size={20}
              style={{ cursor: 'pointer' }}
            />
          </Dropdown>
          {allReadyExistException.value.toFixed(2)}%
        </StyledTableCell>
      );
    }
  }
  if (action) {
    const allReadyExistException = exceptions.find(
      (exception) => exception.type === 'action'
    );
    if (!allReadyExistException) {
      return (
        <StyledTableCell>
          <Dropdown
            overlay={
              <Menu
                style={{
                  maxWidth: '300px',
                }}
                items={[
                  {
                    key: '1',
                    label: <strong>Criar regra de exceção</strong>,
                  },
                  {
                    key: '2',
                    label: (
                      <strong>
                        Você poderá criar para cada ativo da sua carteira um
                        percentual especifico, que entrata como exceção na
                        distribuição automática dos ativos.
                      </strong>
                    ),
                  },
                ]}
              />
            }
            placement="bottom"
          >
            <BsUnlock
              onClick={() => {
                setShowModalCreateRule(true);
                setRule({ ...active, typeEdit: 'action' });
              }}
              size={20}
              style={{ cursor: 'pointer' }}
            />
          </Dropdown>
          {walletValues.objetivoAcao.toFixed(2)}%
        </StyledTableCell>
      );
    } else {
      return (
        <StyledTableCell>
          <Dropdown
            overlay={
              <Menu
                style={{
                  maxWidth: '300px',
                }}
                items={[
                  {
                    key: '1',
                    label: <strong>Editar regra de exceção</strong>,
                  },
                  {
                    key: '2',
                    label: (
                      <strong>
                        Você poderá criar para cada ativo da sua carteira um
                        percentual especifico, que entrata como exceção na
                        distribuição automática dos ativos.
                      </strong>
                    ),
                  },
                ]}
              />
            }
            placement="bottom"
          >
            <BsLock
              onClick={() => {
                setShowModalCreateRule(true);
                setRule({
                  ...active,
                  edit: true,
                  typeEdit: 'action',
                });
              }}
              size={20}
              style={{ cursor: 'pointer' }}
            />
          </Dropdown>
          {allReadyExistException.value.toFixed(2)}%
        </StyledTableCell>
      );
    }
  }

  if (filterBrokerage === '') {
    const allReadyExistException = exceptions.find(
      (exception) => exception.type === 'all'
    );

    if (typeWallet) {
      return (
        <StyledTableCell>
          {active.ValorExp ? active.ValorExp.toFixed(2) : active.ValorExp}%
        </StyledTableCell>
      );
    }

    if (!allReadyExistException) {
      return (
        <StyledTableCell>
          <Dropdown
            overlay={
              <Menu
                style={{
                  maxWidth: '300px',
                }}
                items={[
                  {
                    key: '1',
                    label: <strong>Criar regra de exceção</strong>,
                  },
                  {
                    key: '2',
                    label: (
                      <strong>
                        Você poderá criar para cada ativo da sua carteira um
                        percentual especifico, que entrata como exceção na
                        distribuição automática dos ativos.
                      </strong>
                    ),
                  },
                ]}
              />
            }
            placement="bottom"
          >
            <BsUnlock
              onClick={() => {
                setShowModalCreateRule(true);
                setRule({ ...active, typeEdit: 'all' });
              }}
              size={20}
              style={{ cursor: 'pointer' }}
            />
          </Dropdown>
          {active.ValorExp ? active.ValorExp.toFixed(2) : active.ValorExp}%
        </StyledTableCell>
      );
    } else {
      return (
        <StyledTableCell>
          <Dropdown
            overlay={
              <Menu
                style={{
                  maxWidth: '300px',
                }}
                items={[
                  {
                    key: '1',
                    label: <strong>Editar regra de exceção</strong>,
                  },
                  {
                    key: '2',
                    label: (
                      <strong>
                        Você poderá criar para cada ativo da sua carteira um
                        percentual especifico, que entrata como exceção na
                        distribuição automática dos ativos.
                      </strong>
                    ),
                  },
                ]}
              />
            }
            placement="bottom"
          >
            <BsLock
              onClick={() => {
                setShowModalCreateRule(true);
                setRule({
                  ...active,
                  edit: true,
                  typeEdit: 'all',
                });
              }}
              size={20}
              style={{ cursor: 'pointer' }}
            />
          </Dropdown>
          {allReadyExistException.value.toFixed(2)}%
        </StyledTableCell>
      );
    }
  }
  if (filterBrokerage !== '') {
    const allReadyExistExceptionBroker = exceptions.find(
      (exception) => exception.corretora === filterBrokerage
    );

    if (!allReadyExistExceptionBroker) {
      return (
        <StyledTableCell>
          <Dropdown
            overlay={
              <Menu
                style={{
                  maxWidth: '300px',
                }}
                items={[
                  {
                    key: '1',
                    label: <strong>Criar regra de exceção</strong>,
                  },
                  {
                    key: '2',
                    label: (
                      <strong>
                        Você poderá criar para cada ativo da sua carteira um
                        percentual especifico, que entrata como exceção na
                        distribuição automática dos ativos.
                      </strong>
                    ),
                  },
                ]}
              />
            }
            placement="bottom"
          >
            <BsUnlock
              onClick={() => {
                setShowModalCreateRule(true);
                setRule({
                  ...active,
                  brokerEdit: filterBrokerage,
                  typeEdit: 'broker',
                });
              }}
              size={20}
              style={{ cursor: 'pointer' }}
            />
          </Dropdown>
          {active.ValorExp?.toFixed(2)}%
        </StyledTableCell>
      );
    } else {
      return (
        <StyledTableCell>
          <Dropdown
            overlay={
              <Menu
                style={{
                  maxWidth: '300px',
                }}
                items={[
                  {
                    key: '1',
                    label: <strong>Editar regra de exceção</strong>,
                  },
                  {
                    key: '2',
                    label: (
                      <strong>
                        Você poderá criar para cada ativo da sua carteira um
                        percentual especifico, que entrata como exceção na
                        distribuição automática dos ativos.
                      </strong>
                    ),
                  },
                ]}
              />
            }
            placement="bottom"
          >
            <BsLock
              onClick={() => {
                setShowModalCreateRule(true);
                setRule({
                  ...active,
                  edit: true,
                  typeEdit: 'broker',
                  brokerEdit: filterBrokerage,
                });
              }}
              size={20}
              style={{ cursor: 'pointer' }}
            />
          </Dropdown>
          {allReadyExistExceptionBroker.value.toFixed(2)}%
        </StyledTableCell>
      );
    }
  }
}

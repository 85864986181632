
import { useActives } from '../../../../../hooks/useActives';
import { transformMaskCurrency } from '../../../../../utils/transformMaskCurrency';
import { IPropsIncomeAnnual } from '../../@types';
import { Container } from './styles';

interface IPropsAssets {
  data: IPropsIncomeAnnual;
}

export function AssetsAndRightsInformationActions({ data }: IPropsAssets) {
  //@ts-ignore
  const { showActive } = useActives();

  function formatValueForCurrency(value: number): string {
    return transformMaskCurrency(value);
  }
  return (
    <Container>
      <span>
        CNPJ: <strong>{showActive ? data.CNPJ : '**.***.***/****-**'}</strong>
      </span>
      <span>
        Discriminação:{' '}
        <strong>
          {showActive ? (
            <>
              {data.tickerSymbol} - {data.NomeEmpresa} - CNPJ: {data.CNPJ} -
              QUANTIDADE: {data.QuantidadeAtual} - ESCRITURADOR:{' '}
              {data.Escriturador}
            </>
          ) : (
            '*******************************************'
          )}
        </strong>
      </span>

      <span>
        Situação anterior:{' '}
        <strong>
          {showActive
            ? formatValueForCurrency(data.ValorTotalAnterior)
            : 'R$ *****'}
        </strong>
      </span>
      <span>
        Situação atual:{' '}
        <strong>
          {showActive
            ? formatValueForCurrency(data.ValorTotalAtual)
            : 'R$ *****'}
        </strong>
      </span>
    </Container>
  );
}

import styled from 'styled-components';

export const ContentVideo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  box-shadow: ${(props) => props.theme['card-shadow-2']};
  
  width: 240px;
  height: 275px;
  object-fit: cover;

  border-radius: 8px;
  border: 1px solid var(--Gray-gray-100, #D4D6DB);
  background: var(--Main-white, #FFF);

  h4 {
    max-width: 10ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  span {
    max-width: 15ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const ThumbContent = styled.div`
  display: flex;

  width: 100%;
  width: 240px;
  height: 139.98px;
  img {
    object-fit: cover;
    border-radius: 4px 4px 0 0;

    width: 100%;
    height: 100%;
  }
`;
export const ContentChannelInfos = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem;
  margin-top: 10px;
  width: 100%;
  svg {
    width: 1rem;
    height: 1rem;
  }

  a {
    padding: 0;
    display: flex;
    justify-content: center;
    font-size: 1rem;
    align-items: center;
    gap: 10px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: 700;
    color: ${(props) => props.theme.primary};
  }

  button {
    all: unset;
    padding: 0;
    display: flex;
    justify-content: center;
    font-size: 1rem;
    align-items: center;
    gap: 10px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: 700;
    
    cursor: pointer;
  }
`;

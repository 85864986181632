import { useEffect, useState } from 'react';
import { useTitleAndUser } from '../../../../hooks/userTitle';
import axios from 'axios';
import { administrationController } from '../../../../utils/RoutesApi';
import { ContainerFeedback, ContentTable } from './styles';
import { TableQuestions } from './components/TableQuestions';
import { IPropsQuestionsFeedback } from '../../../../utils/@types/questionsFeedback';
import { ButtonComponent } from '../../../../components/ButtonComponent';
import { FormQuest } from './components/FormQuest';
import { TableResponses } from './components/TableResponses';
import { downloadExcel } from 'react-export-table-to-excel';
import { ModalDesigner } from '../../../../components/ModalDesigner';

const baseUrl = process.env.REACT_APP_API_URL;

export interface IPropsResponseFeedback {
  id: number;
  question: {
    active: boolean;
    id: number;
    pub_date: Date;
    question_text: string;
    question_type: 'rating' | 'text';
  };
  rating_response: number | null;
  text_response: null | string;

  user: {
    Name: string;
    ddd: string;
    email: string;
    id: number;
    telefone: number;
  };
}

export function Feedback() {
  //@ts-ignore
  const { setTitle } = useTitleAndUser();
  const [questions, setQuestions] = useState<IPropsQuestionsFeedback[]>([]);
  const [attStatusQuestion, setAttStatusQuestion] = useState(false);
  const [responseFeedback, setResponseFeedback] = useState<
    IPropsResponseFeedback[]
  >([]);
  const [showModalAddNewQuestion, setShowModalAddNewQuestion] = useState(false);

  useEffect(() => {
    setTitle('Feedback');
    getQuestionsFeedback();
    getListResponseFeedback();
  }, []);

  async function getQuestionsFeedback() {
    try {
      const response = await axios.get(
        `${baseUrl}${administrationController.QUESTIONS_FEEDBACK}/`
      );
      setQuestions(response.data);
    } catch (err) {}
  }

  async function getListResponseFeedback() {
    await axios
      .get(`${baseUrl}${administrationController.RESPONSE_USER_FEEDBACK}`)
      .then((response) => {
        setResponseFeedback(response.data.reverse());
      })
      .catch((err) => {});
  }

  function handleAddNewQuestion(question: IPropsQuestionsFeedback) {
    setQuestions([...questions, question]);
    setShowModalAddNewQuestion(false);
  }

  function handleAttQuestion(questionId: number) {
    const findQuestion = questions.findIndex(
      (question) => question.id === questionId
    );

    setQuestions((state) => {
      state[findQuestion].active = !state[findQuestion].active;
      return state;
    });
    setAttStatusQuestion(!attStatusQuestion);
  }

  const headerXls = ['Usuário', 'Telefone', 'Pergunta', 'Tipo', 'Resposta'];

  const body = responseFeedback.map((item) => {
    return [
      item.user.Name,
      `(${item.user.ddd}) ${item.user.telefone}`,
      item.question.question_text,
      `${item.question.question_type === 'rating' ? 'Estrela' : 'Texto'}`,
      `${
        item.question.question_type === 'rating'
          ? item.rating_response
          : item.text_response
      }`,
    ];
  });
  function handleDownloadExcel() {
    downloadExcel({
      fileName: `Feedback dos usuários`,
      sheet: 'react-export-table-to-excel',
      tablePayload: {
        header: headerXls,
        // accept two different data structures
        body: body,
      },
    });
  }

  return (
    <ContainerFeedback>
      <ContentTable>
        <header>
          <h2>Perguntas para o feedback</h2>
          <ButtonComponent onClick={() => setShowModalAddNewQuestion(true)}>
            Adicionar pergunta
          </ButtonComponent>
        </header>
        <TableQuestions
          data={questions}
          onAttStatusQuestion={(questionId: number) =>
            handleAttQuestion(questionId)
          }
        />
      </ContentTable>
      <ContentTable>
        <header>
          <h2>Repostas do feedback</h2>
          <ButtonComponent onClick={handleDownloadExcel}>
            Baixar excel
          </ButtonComponent>
        </header>
        <TableResponses data={responseFeedback} />
      </ContentTable>

      <ModalDesigner
        id="registerNewQuest"
        isOpen={showModalAddNewQuestion}
        setState={setShowModalAddNewQuestion}
        title="Cadastrar nova pergunta para feedback"
        size="sm"
      >
        <FormQuest onAddNewQuestion={handleAddNewQuestion} />
      </ModalDesigner>
    </ContainerFeedback>
  );
}

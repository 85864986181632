import styled from 'styled-components';

export const Container = styled.div`
  background: ${(props) => props.theme.white};
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  transition: box-shadow 0.3s;
  border-radius: 8px;
  border: 3px solid var(--Gray-gray-100, #d4d6db);
  background: var(--Main-white, #fff);
  overflow: hidden;

  :hover::before {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    background: #fff; //linear-gradient(240deg, #2469a8, #f8b700, #c61722);
    z-index: -1;
    margin: -2px;
    border-radius: 5px;
    transition: display 5s;
    background-size: 300% 300%;
    animation: colors 8s ease infinite;
  }
  @keyframes colors {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  a {
    color: ${(props) => props.theme.black} !important;
  }
  small {
    color: ${(props) => props.theme.black} !important;
  }
`;

interface IPropsContentGraphic {
  value: number;
}

export const ContentGraphic = styled.div<IPropsContentGraphic>`
  display: flex;
  position: relative;
  margin-bottom: -50px;

  small {
    display: flex;
    font-weight: 700;
    font-size: 18px;
    position: absolute;
    top: 2.2rem;
    left: ${(props) =>
      props.value < 2 ? '2.7rem' : props.value < 3 ? '2.45rem' : '2.2rem'};
  }

  :hover::before {
    background: white;
  }

  .contentGraphicValue {
    margin-left: 2px;
    margin-bottom: -50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    h6 {
      position: absolute;
      margin-top: -35px;
      font-size: 1rem;
      font-weight: 500;
      color: #40444c;
      font-family: Poppins;
      font-size: 19.511px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
    }
    h5 {
      font-weight: 500;
      font-size: 1.25rem;
      color: ${(props) => props.theme['gray-400']};
      padding: 0 0.5rem;
      width: 200px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
    }
  }
`;

export const HeaderCardActive = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
  background: #e6ebef;
  height: 60px;

  img {
    width: 4rem;
  }

  p {
    margin: 0;
  }

  span {
    color: #303f4b !important;
    font-weight: 600;
    font-size: 0.875rem;
  }
`;

export const ColDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 1rem;
  padding: 1rem;
  .reduce {
    max-width: 15ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
  }

  @media (max-width: 310px) {
    h6 {
      font-size: 0.75rem;
    }
  }
`;

import styled from "styled-components";

export const GraphicCotationContainer = styled.div`
  cursor: pointer;
  background: ${(props) => props.theme.white};
  border-radius: 4px;
  padding: 1rem 1.5rem;
  height: 100%;
  max-height: 11.25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: ${(props) => props.theme['card-shadow-2']};
  gap: 1rem;
  z-index: 2;
`;

export const BottomCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  span {
    font: 700 1rem 'Poppins', sans-serif;
    color: ${(props) => props.theme['gray-700']};
  }
`;

export const BottomCardInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 32px;
`;

export const CardInfoValue = styled.h3`
  margin: 0px;
  padding: 0px;
  font: 700 1.5rem 'Poppins', sans-serif;
  color: ${(props) => props.theme['gray-700']};
`;
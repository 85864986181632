import React, { ReactNode } from 'react';
import { Close, Content, Header, ModalStyled, OutModalStyled } from './styles';

interface IPropsModal {
  id: string;
  children: ReactNode;
  title: string;
  setState: Function;
  width: string;
  height: string;
}

export function ModalTermsOfUse({
  id,
  children,
  title,
  setState,
  width,
  height,
}: IPropsModal) {
  function handleOutSideClick(e: any) {
    if (e.target.id === id) setState(false);
  }
  return (
    <OutModalStyled id={id} onClick={handleOutSideClick}>
      <ModalStyled>
        <Header>
          <h3>{title}</h3>
          <Close onClick={() => setState(false)} />
        </Header>
        <Content width={width} height={height}>
          {children}
        </Content>
      </ModalStyled>
    </OutModalStyled>
  );
}
